<template>
  <div class="form">
    <div class="titel">{{ title }}</div>
    <div class="content">
      <div class="item">
        <el-progress type="circle" :percentage="percentageList[0]" :width="90"></el-progress>
        <div class="name">1.{{ nameList[0] }}</div>
      </div>
      <div class="item">
        <el-progress type="circle" :percentage="percentageList[1]" :width="90"></el-progress>
        <div class="name">2.{{ nameList[1] }}</div>
      </div>
      <div class="item">
        <el-progress type="circle" :percentage="percentageList[2]" :width="90"></el-progress>
        <div class="name">3.{{ nameList[2] }}</div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: '',
  props: [
    "title",
    "dataLists"
  ],
  watch: {
    dataLists() {
      for (let key in this.dataLists) {
        this.nameList.push(key);
        this.percentageList.push(Number(this.dataLists[key]));
      }
      this.nameList = this.nameList.splice(1, 3)
      this.percentageList = this.percentageList.splice(1, 3)
    }
  },
  data() {
    return {
      // title: 'rtests',
      nameList: [],
      percentageList: []

    };
  },
};
</script>

<style scoped lang='less'>
.form {
  width: 350px;
  background: white;
  border-radius: 5px;
  height: 212px;
}

.titel {
  text-align: center;
  padding: 25px 0;
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.item {
  display: inline-block;
  width: 90px;
}

.name {
  text-align: center;
}
</style>
