module.exports = {
  "本科学科门类名称": "就业率",
  "教育学": "87",
  "农学": "86.6",
  "理学": "85.6",
  "管理学": "85.3",
  "医学": "85.2",
  "历史学": "84.2",
  "经济学": "83.3",
  "艺术学": "83.1",
  "文学": "82.7",
  "法学": "78.2",
  "全国本科": "86"
}