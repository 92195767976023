module.exports = [
  { "name": "河南师范大学", "is_wenli": "文科", "jihua": 1456, "luqu": 1494, "min_score": 573 },
  { "name": "河南财经政法大学", "is_wenli": "文科", "jihua": 970, "luqu": 987, "min_score": 579 },
  { "name": "河南中医药大学", "is_wenli": "文科", "jihua": 736, "luqu": 742, "min_score": 560 },
  { "name": "河南大学", "is_wenli": "文科", "jihua": 714, "luqu": 717, "min_score": 591 },
  { "name": "河南大学", "is_wenli": "文科", "jihua": 714, "luqu": 717, "min_score": 591 },
  { "name": "华北水利水电大学", "is_wenli": "文科", "jihua": 497, "luqu": 516, "min_score": 563 },
  { "name": "河南农业大学", "is_wenli": "文科", "jihua": 500, "luqu": 502, "min_score": 565 },
  { "name": "郑州大学", "is_wenli": "文科", "jihua": 485, "luqu": 486, "min_score": 606 },
  { "name": "河南理工大学", "is_wenli": "文科", "jihua": 429, "luqu": 434, "min_score": 561 },
  { "name": "河南科技大学", "is_wenli": "文科", "jihua": 396, "luqu": 408, "min_score": 569 },
  { "name": "郑州航空工业管理学院", "is_wenli": "文科", "jihua": 388, "luqu": 391, "min_score": 560 },
  { "name": "河南工业大学", "is_wenli": "文科", "jihua": 378, "luqu": 388, "min_score": 569 },
  { "name": "信阳师范学院", "is_wenli": "文科", "jihua": 335, "luqu": 342, "min_score": 563 },
  { "name": "河南大学(中外合作办学)", "is_wenli": "文科", "jihua": 284, "luqu": 286, "min_score": 566 },
  { "name": "河南财经政法大学[较高收费]", "is_wenli": "文科", "jihua": 260, "luqu": 265, "min_score": 561 },
  { "name": "郑州轻工业大学", "is_wenli": "文科", "jihua": 250, "luqu": 257, "min_score": 561 },
  { "name": "新乡医学院", "is_wenli": "文科", "jihua": 250, "luqu": 250, "min_score": 556 },
  { "name": "郑州大学(中外合作办学)", "is_wenli": "文科", "jihua": 150, "luqu": 150, "min_score": 596 },
  { "name": "海南师范大学", "is_wenli": "文科", "jihua": 110, "luqu": 110, "min_score": 570 },
  { "name": "中南财经政法大学", "is_wenli": "文科", "jihua": 105, "luqu": 105, "min_score": 620 },
  { "name": "上海师范大学", "is_wenli": "文科", "jihua": 99, "luqu": 99, "min_score": 598 },
  { "name": "河南科技学院", "is_wenli": "文科", "jihua": 90, "luqu": 95, "min_score": 556 },
  { "name": "武汉大学", "is_wenli": "文科", "jihua": 91, "luqu": 91, "min_score": 639 },
  { "name": "天津商业大学", "is_wenli": "文科", "jihua": 87, "luqu": 87, "min_score": 577 },
  { "name": "海南大学", "is_wenli": "文科", "jihua": 67, "luqu": 73, "min_score": 601 },
  { "name": "新疆大学", "is_wenli": "文科", "jihua": 72, "luqu": 72, "min_score": 583 },
  { "name": "河南农业大学(中外合作办学)", "is_wenli": "文科", "jihua": 70, "luqu": 70, "min_score": 545 },
  { "name": "吉林大学", "is_wenli": "文科", "jihua": 65, "luqu": 65, "min_score": 621 },
  { "name": "华东政法大学", "is_wenli": "文科", "jihua": 63, "luqu": 64, "min_score": 613 },
  { "name": "石河子大学", "is_wenli": "文科", "jihua": 63, "luqu": 63, "min_score": 578 },
  { "name": "江汉大学", "is_wenli": "文科", "jihua": 61, "luqu": 61, "min_score": 566 },
  { "name": "山东大学", "is_wenli": "文科", "jihua": 54, "luqu": 59, "min_score": 623 },
  { "name": "上海政法学院", "is_wenli": "文科", "jihua": 58, "luqu": 59, "min_score": 596 },
  { "name": "湘潭大学", "is_wenli": "文科", "jihua": 56, "luqu": 56, "min_score": 591 },
  { "name": "中国政法大学", "is_wenli": "文科", "jihua": 52, "luqu": 52, "min_score": 633 },
  { "name": "东北师范大学", "is_wenli": "文科", "jihua": 50, "luqu": 50, "min_score": 605 },
  { "name": "西安电子科技大学", "is_wenli": "文科", "jihua": 50, "luqu": 50, "min_score": 604 },
  { "name": "西交利物浦大学", "is_wenli": "文科", "jihua": 45, "luqu": 50, "min_score": 565 },
  { "name": "辽宁大学", "is_wenli": "文科", "jihua": 49, "luqu": 49, "min_score": 603 },
  { "name": "江西师范大学", "is_wenli": "文科", "jihua": 42, "luqu": 49, "min_score": 585 },
  { "name": "中南民族大学[仅招少数民族]", "is_wenli": "文科", "jihua": 49, "luqu": 49, "min_score": 569 },
  { "name": "华中师范大学", "is_wenli": "文科", "jihua": 47, "luqu": 47, "min_score": 617 },
  { "name": "浙大城市学院(原浙江大学城市学院)", "is_wenli": "文科", "jihua": 47, "luqu": 47, "min_score": 576 },
  { "name": "华中农业大学", "is_wenli": "文科", "jihua": 46, "luqu": 46, "min_score": 603 },
  { "name": "天津师范大学", "is_wenli": "文科", "jihua": 46, "luqu": 46, "min_score": 594 },
  { "name": "天津科技大学", "is_wenli": "文科", "jihua": 46, "luqu": 46, "min_score": 585 },
  { "name": "黑龙江大学", "is_wenli": "文科", "jihua": 46, "luqu": 46, "min_score": 582 },
  { "name": "华东师范大学", "is_wenli": "文科", "jihua": 39, "luqu": 44, "min_score": 631 },
  { "name": "西南政法大学", "is_wenli": "文科", "jihua": 43, "luqu": 44, "min_score": 617 },
  { "name": "南通大学", "is_wenli": "文科", "jihua": 44, "luqu": 44, "min_score": 583 },
  { "name": "南京工程学院", "is_wenli": "文科", "jihua": 44, "luqu": 44, "min_score": 571 },
  { "name": "陕西师范大学", "is_wenli": "文科", "jihua": 41, "luqu": 43, "min_score": 611 },
  { "name": "大连外国语大学", "is_wenli": "文科", "jihua": 43, "luqu": 43, "min_score": 583 },
  { "name": "华中科技大学", "is_wenli": "文科", "jihua": 42, "luqu": 42, "min_score": 630 },
  { "name": "东北林业大学", "is_wenli": "文科", "jihua": 42, "luqu": 42, "min_score": 598 },
  { "name": "南京审计大学", "is_wenli": "文科", "jihua": 42, "luqu": 42, "min_score": 592 },
  { "name": "天津外国语大学", "is_wenli": "文科", "jihua": 42, "luqu": 42, "min_score": 589 },
  { "name": "山东财经大学", "is_wenli": "文科", "jihua": 42, "luqu": 42, "min_score": 589 },
  { "name": "南开大学", "is_wenli": "文科", "jihua": 41, "luqu": 41, "min_score": 640 },
  { "name": "天津财经大学", "is_wenli": "文科", "jihua": 41, "luqu": 41, "min_score": 596 },
  { "name": "华东理工大学", "is_wenli": "文科", "jihua": 40, "luqu": 40, "min_score": 612 },
  { "name": "西北农林科技大学", "is_wenli": "文科", "jihua": 38, "luqu": 40, "min_score": 606 },
  { "name": "西北政法大学", "is_wenli": "文科", "jihua": 40, "luqu": 40, "min_score": 605 },
  { "name": "中南民族大学", "is_wenli": "文科", "jihua": 40, "luqu": 40, "min_score": 591 },
  { "name": "武汉科技大学", "is_wenli": "文科", "jihua": 36, "luqu": 40, "min_score": 589 },
  { "name": "武汉理工大学", "is_wenli": "文科", "jihua": 39, "luqu": 39, "min_score": 608 },
  { "name": "武汉理工大学", "is_wenli": "文科", "jihua": 39, "luqu": 39, "min_score": 608 },
  { "name": "上海对外经贸大学", "is_wenli": "文科", "jihua": 39, "luqu": 39, "min_score": 603 },
  { "name": "中国人民大学", "is_wenli": "文科", "jihua": 38, "luqu": 38, "min_score": 652 },
  { "name": "东北大学", "is_wenli": "文科", "jihua": 37, "luqu": 37, "min_score": 616 },
  { "name": "南阳师范学院", "is_wenli": "文科", "jihua": 35, "luqu": 36, "min_score": 560 },
  { "name": "湖南大学", "is_wenli": "文科", "jihua": 34, "luqu": 35, "min_score": 624 },
  { "name": "湖北大学", "is_wenli": "文科", "jihua": 34, "luqu": 35, "min_score": 590 },
  { "name": "三峡大学", "is_wenli": "文科", "jihua": 35, "luqu": 35, "min_score": 577 },
  { "name": "西华师范大学", "is_wenli": "文科", "jihua": 34, "luqu": 34, "min_score": 568 },
  { "name": "中国海洋大学", "is_wenli": "文科", "jihua": 33, "luqu": 33, "min_score": 619 },
  { "name": "南昌大学", "is_wenli": "文科", "jihua": 33, "luqu": 33, "min_score": 605 },
  { "name": "重庆工商大学", "is_wenli": "文科", "jihua": 33, "luqu": 33, "min_score": 586 },
  { "name": "安徽财经大学", "is_wenli": "文科", "jihua": 33, "luqu": 33, "min_score": 581 },
  { "name": "北京大学", "is_wenli": "文科", "jihua": 32, "luqu": 32, "min_score": 661 },
  { "name": "苏州大学", "is_wenli": "文科", "jihua": 29, "luqu": 32, "min_score": 619 },
  { "name": "福建师范大学", "is_wenli": "文科", "jihua": 32, "luqu": 32, "min_score": 589 },
  { "name": "济南大学", "is_wenli": "文科", "jihua": 32, "luqu": 32, "min_score": 588 },
  { "name": "苏州科技大学", "is_wenli": "文科", "jihua": 32, "luqu": 32, "min_score": 583 },
  { "name": "吉林财经大学", "is_wenli": "文科", "jihua": 32, "luqu": 32, "min_score": 578 },
  { "name": "南京农业大学", "is_wenli": "文科", "jihua": 31, "luqu": 31, "min_score": 604 },
  { "name": "重庆师范大学", "is_wenli": "文科", "jihua": 31, "luqu": 31, "min_score": 588 },
  { "name": "西南石油大学", "is_wenli": "文科", "jihua": 31, "luqu": 31, "min_score": 587 },
  { "name": "河北师范大学", "is_wenli": "文科", "jihua": 31, "luqu": 31, "min_score": 583 },
  { "name": "广西大学", "is_wenli": "文科", "jihua": 30, "luqu": 30, "min_score": 600 },
  { "name": "江苏大学", "is_wenli": "文科", "jihua": 30, "luqu": 30, "min_score": 597 },
  { "name": "天津工业大学", "is_wenli": "文科", "jihua": 30, "luqu": 30, "min_score": 591 },
  { "name": "天津理工大学", "is_wenli": "文科", "jihua": 30, "luqu": 30, "min_score": 580 },
  { "name": "厦门大学", "is_wenli": "文科", "jihua": 29, "luqu": 29, "min_score": 635 },
  { "name": "江西财经大学", "is_wenli": "文科", "jihua": 28, "luqu": 29, "min_score": 601 },
  { "name": "上海海洋大学", "is_wenli": "文科", "jihua": 29, "luqu": 29, "min_score": 592 },
  { "name": "山西大学", "is_wenli": "文科", "jihua": 29, "luqu": 29, "min_score": 589 },
  { "name": "山东师范大学", "is_wenli": "文科", "jihua": 29, "luqu": 29, "min_score": 589 },
  { "name": "湖北中医药大学", "is_wenli": "文科", "jihua": 29, "luqu": 29, "min_score": 575 },
  { "name": "武汉纺织大学", "is_wenli": "文科", "jihua": 29, "luqu": 29, "min_score": 567 },
  { "name": "安徽大学", "is_wenli": "文科", "jihua": 25, "luqu": 28, "min_score": 605 },
  { "name": "南京财经大学", "is_wenli": "文科", "jihua": 28, "luqu": 28, "min_score": 601 },
  { "name": "上海理工大学", "is_wenli": "文科", "jihua": 28, "luqu": 28, "min_score": 591 },
  { "name": "长江大学", "is_wenli": "文科", "jihua": 28, "luqu": 28, "min_score": 578 },
  { "name": "常州大学", "is_wenli": "文科", "jihua": 28, "luqu": 28, "min_score": 578 },
  { "name": "对外经济贸易大学", "is_wenli": "文科", "jihua": 27, "luqu": 27, "min_score": 625 },
  { "name": "西南财经大学", "is_wenli": "文科", "jihua": 27, "luqu": 27, "min_score": 619 },
  { "name": "江苏科技大学", "is_wenli": "文科", "jihua": 27, "luqu": 27, "min_score": 580 },
  { "name": "西安工程大学", "is_wenli": "文科", "jihua": 27, "luqu": 27, "min_score": 577 },
  { "name": "甘肃政法大学(原甘肃政法学院)", "is_wenli": "文科", "jihua": 27, "luqu": 27, "min_score": 562 },
  { "name": "中央财经大学", "is_wenli": "文科", "jihua": 26, "luqu": 26, "min_score": 632 },
  { "name": "江南大学", "is_wenli": "文科", "jihua": 26, "luqu": 26, "min_score": 611 },
  { "name": "江南大学", "is_wenli": "文科", "jihua": 26, "luqu": 26, "min_score": 611 },
  { "name": "中国石油大学(华东)", "is_wenli": "文科", "jihua": 26, "luqu": 26, "min_score": 604 },
  { "name": "中国矿业大学", "is_wenli": "文科", "jihua": 26, "luqu": 26, "min_score": 602 },
  { "name": "四川师范大学", "is_wenli": "文科", "jihua": 26, "luqu": 26, "min_score": 590 },
  { "name": "中南林业科技大学", "is_wenli": "文科", "jihua": 26, "luqu": 26, "min_score": 578 },
  { "name": "华东交通大学", "is_wenli": "文科", "jihua": 26, "luqu": 26, "min_score": 577 },
  { "name": "兰州大学", "is_wenli": "文科", "jihua": 25, "luqu": 25, "min_score": 618 },
  { "name": "西南大学", "is_wenli": "文科", "jihua": 25, "luqu": 25, "min_score": 614 },
  { "name": "华侨大学", "is_wenli": "文科", "jihua": 25, "luqu": 25, "min_score": 557 },
  { "name": "山东大学威海分校", "is_wenli": "文科", "jihua": 24, "luqu": 24, "min_score": 620 },
  { "name": "哈尔滨工程大学", "is_wenli": "文科", "jihua": 24, "luqu": 24, "min_score": 600 },
  { "name": "西华大学", "is_wenli": "文科", "jihua": 24, "luqu": 24, "min_score": 584 },
  { "name": "浙江农林大学", "is_wenli": "文科", "jihua": 24, "luqu": 24, "min_score": 579 },
  { "name": "湖南农业大学", "is_wenli": "文科", "jihua": 24, "luqu": 24, "min_score": 575 },
  { "name": "中国社会科学院大学", "is_wenli": "文科", "jihua": 23, "luqu": 23, "min_score": 628 },
  { "name": "华南理工大学", "is_wenli": "文科", "jihua": 23, "luqu": 23, "min_score": 623 },
  { "name": "中央民族大学", "is_wenli": "文科", "jihua": 23, "luqu": 23, "min_score": 622 },
  { "name": "北京科技大学", "is_wenli": "文科", "jihua": 23, "luqu": 23, "min_score": 618 },
  { "name": "西南交通大学", "is_wenli": "文科", "jihua": 23, "luqu": 23, "min_score": 610 },
  { "name": "合肥工业大学(宣城校区)", "is_wenli": "文科", "jihua": 23, "luqu": 23, "min_score": 597 },
  { "name": "东北农业大学", "is_wenli": "文科", "jihua": 23, "luqu": 23, "min_score": 595 },
  { "name": "河北大学", "is_wenli": "文科", "jihua": 23, "luqu": 23, "min_score": 589 },
  { "name": "湖南中医药大学", "is_wenli": "文科", "jihua": 23, "luqu": 23, "min_score": 575 },
  { "name": "哈尔滨商业大学", "is_wenli": "文科", "jihua": 23, "luqu": 23, "min_score": 573 },
  { "name": "河海大学", "is_wenli": "文科", "jihua": 22, "luqu": 22, "min_score": 607 },
  { "name": "西安财经大学(原西安财经学院)", "is_wenli": "文科", "jihua": 22, "luqu": 22, "min_score": 574 },
  { "name": "天津大学", "is_wenli": "文科", "jihua": 21, "luqu": 21, "min_score": 629 },
  { "name": "中国传媒大学", "is_wenli": "文科", "jihua": 21, "luqu": 21, "min_score": 622 },
  { "name": "燕山大学", "is_wenli": "文科", "jihua": 20, "luqu": 21, "min_score": 589 },
  { "name": "北京语言大学", "is_wenli": "文科", "jihua": 21, "luqu": 21, "min_score": 588 },
  { "name": "安徽师范大学", "is_wenli": "文科", "jihua": 20, "luqu": 21, "min_score": 586 },
  { "name": "东北石油大学", "is_wenli": "文科", "jihua": 21, "luqu": 21, "min_score": 564 },
  { "name": "河南大学[护理]", "is_wenli": "文科", "jihua": 21, "luqu": 21, "min_score": 560 },
  { "name": "中南大学", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 627 },
  { "name": "云南大学", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 605 },
  { "name": "南京邮电大学", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 601 },
  { "name": "宁夏大学", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 593 },
  { "name": "青海大学", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 585 },
  { "name": "西北师范大学", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 578 },
  { "name": "宁波诺丁汉大学", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 556 },
  { "name": "南京信息工程大学", "is_wenli": "文科", "jihua": 19, "luqu": 19, "min_score": 599 },
  { "name": "杭州师范大学", "is_wenli": "文科", "jihua": 19, "luqu": 19, "min_score": 597 },
  { "name": "集美大学", "is_wenli": "文科", "jihua": 19, "luqu": 19, "min_score": 593 },
  { "name": "延边大学", "is_wenli": "文科", "jihua": 19, "luqu": 19, "min_score": 591 },
  { "name": "青岛大学", "is_wenli": "文科", "jihua": 19, "luqu": 19, "min_score": 589 },
  { "name": "湖南科技大学", "is_wenli": "文科", "jihua": 19, "luqu": 19, "min_score": 584 },
  { "name": "天津中医药大学", "is_wenli": "文科", "jihua": 19, "luqu": 19, "min_score": 579 },
  { "name": "西南科技大学", "is_wenli": "文科", "jihua": 18, "luqu": 19, "min_score": 576 },
  { "name": "湖南工业大学", "is_wenli": "文科", "jihua": 19, "luqu": 19, "min_score": 575 },
  { "name": "重庆大学", "is_wenli": "文科", "jihua": 18, "luqu": 18, "min_score": 627 },
  { "name": "深圳大学", "is_wenli": "文科", "jihua": 18, "luqu": 18, "min_score": 613 },
  { "name": "上海海事大学", "is_wenli": "文科", "jihua": 18, "luqu": 18, "min_score": 592 },
  { "name": "上海电力大学", "is_wenli": "文科", "jihua": 18, "luqu": 18, "min_score": 587 },
  { "name": "曲阜师范大学", "is_wenli": "文科", "jihua": 18, "luqu": 18, "min_score": 580 },
  { "name": "海南大学[较高收费]", "is_wenli": "文科", "jihua": 17, "luqu": 18, "min_score": 576 },
  { "name": "山东理工大学", "is_wenli": "文科", "jihua": 18, "luqu": 18, "min_score": 570 },
  { "name": "浙江大学", "is_wenli": "文科", "jihua": 17, "luqu": 17, "min_score": 649 },
  { "name": "中国地质大学(武汉)", "is_wenli": "文科", "jihua": 16, "luqu": 17, "min_score": 611 },
  { "name": "北京化工大学", "is_wenli": "文科", "jihua": 17, "luqu": 17, "min_score": 606 },
  { "name": "贵州大学", "is_wenli": "文科", "jihua": 16, "luqu": 17, "min_score": 601 },
  { "name": "长沙理工大学", "is_wenli": "文科", "jihua": 17, "luqu": 17, "min_score": 586 },
  { "name": "上海应用技术大学", "is_wenli": "文科", "jihua": 17, "luqu": 17, "min_score": 579 },
  { "name": "南京大学", "is_wenli": "文科", "jihua": 16, "luqu": 16, "min_score": 649 },
  { "name": "南京师范大学", "is_wenli": "文科", "jihua": 16, "luqu": 16, "min_score": 618 },
  { "name": "南京航空航天大学", "is_wenli": "文科", "jihua": 16, "luqu": 16, "min_score": 612 },
  { "name": "湖南师范大学", "is_wenli": "文科", "jihua": 16, "luqu": 16, "min_score": 611 },
  { "name": "北京外国语大学", "is_wenli": "文科", "jihua": 16, "luqu": 16, "min_score": 604 },
  { "name": "广东外语外贸大学", "is_wenli": "文科", "jihua": 16, "luqu": 16, "min_score": 601 },
  { "name": "南京工业大学", "is_wenli": "文科", "jihua": 16, "luqu": 16, "min_score": 593 },
  { "name": "南京中医药大学", "is_wenli": "文科", "jihua": 16, "luqu": 16, "min_score": 591 },
  { "name": "西南民族大学", "is_wenli": "文科", "jihua": 16, "luqu": 16, "min_score": 588 },
  { "name": "北京印刷学院", "is_wenli": "文科", "jihua": 16, "luqu": 16, "min_score": 582 },
  { "name": "成都信息工程大学(原成都信息工程学院)", "is_wenli": "文科", "jihua": 16, "luqu": 16, "min_score": 575 },
  { "name": "江西科技师范大学", "is_wenli": "文科", "jihua": 10, "luqu": 16, "min_score": 564 },
  { "name": "温州肯恩大学", "is_wenli": "文科", "jihua": 11, "luqu": 16, "min_score": 556 },
  { "name": "同济大学", "is_wenli": "文科", "jihua": 15, "luqu": 15, "min_score": 635 },
  { "name": "中国农业大学", "is_wenli": "文科", "jihua": 14, "luqu": 15, "min_score": 625 },
  { "name": "哈尔滨工业大学", "is_wenli": "文科", "jihua": 15, "luqu": 15, "min_score": 623 },
  { "name": "上海大学", "is_wenli": "文科", "jihua": 15, "luqu": 15, "min_score": 620 },
  { "name": "暨南大学", "is_wenli": "文科", "jihua": 14, "luqu": 15, "min_score": 614 },
  { "name": "华北电力大学(保定)", "is_wenli": "文科", "jihua": 15, "luqu": 15, "min_score": 602 },
  { "name": "合肥工业大学", "is_wenli": "文科", "jihua": 15, "luqu": 15, "min_score": 601 },
  { "name": "南方医科大学", "is_wenli": "文科", "jihua": 15, "luqu": 15, "min_score": 592 },
  { "name": "上海师范大学[较高收费]", "is_wenli": "文科", "jihua": 15, "luqu": 15, "min_score": 589 },
  { "name": "中国石油大学(北京)克拉玛依校区", "is_wenli": "文科", "jihua": 14, "luqu": 15, "min_score": 580 },
  { "name": "汕头大学", "is_wenli": "文科", "jihua": 15, "luqu": 15, "min_score": 577 },
  { "name": "东华理工大学", "is_wenli": "文科", "jihua": 15, "luqu": 15, "min_score": 575 },
  { "name": "齐鲁工业大学", "is_wenli": "文科", "jihua": 15, "luqu": 15, "min_score": 569 },
  { "name": "浙江科技学院", "is_wenli": "文科", "jihua": 15, "luqu": 15, "min_score": 565 },
  { "name": "辽宁工程技术大学", "is_wenli": "文科", "jihua": 15, "luqu": 15, "min_score": 564 },
  { "name": "上海财经大学", "is_wenli": "文科", "jihua": 14, "luqu": 14, "min_score": 635 },
  { "name": "长安大学", "is_wenli": "文科", "jihua": 14, "luqu": 14, "min_score": 603 },
  { "name": "宁波大学", "is_wenli": "文科", "jihua": 14, "luqu": 14, "min_score": 597 },
  { "name": "大连海事大学", "is_wenli": "文科", "jihua": 14, "luqu": 14, "min_score": 596 },
  { "name": "扬州大学", "is_wenli": "文科", "jihua": 12, "luqu": 14, "min_score": 594 },
  { "name": "上海立信会计金融学院", "is_wenli": "文科", "jihua": 14, "luqu": 14, "min_score": 593 },
  { "name": "湖北工业大学", "is_wenli": "文科", "jihua": 14, "luqu": 14, "min_score": 585 },
  { "name": "武汉工程大学", "is_wenli": "文科", "jihua": 14, "luqu": 14, "min_score": 584 },
  { "name": "西安工业大学", "is_wenli": "文科", "jihua": 14, "luqu": 14, "min_score": 581 },
  { "name": "上海电机学院", "is_wenli": "文科", "jihua": 14, "luqu": 14, "min_score": 571 },
  { "name": "南阳理工学院", "is_wenli": "文科", "jihua": 14, "luqu": 14, "min_score": 559 },
  { "name": "四川大学", "is_wenli": "文科", "jihua": 13, "luqu": 13, "min_score": 632 },
  { "name": "东南大学", "is_wenli": "文科", "jihua": 13, "luqu": 13, "min_score": 630 },
  { "name": "北京林业大学", "is_wenli": "文科", "jihua": 13, "luqu": 13, "min_score": 613 },
  { "name": "西北大学", "is_wenli": "文科", "jihua": 13, "luqu": 13, "min_score": 611 },
  { "name": "华南师范大学", "is_wenli": "文科", "jihua": 13, "luqu": 13, "min_score": 610 },
  { "name": "浙江工商大学", "is_wenli": "文科", "jihua": 13, "luqu": 13, "min_score": 600 },
  { "name": "四川外国语大学", "is_wenli": "文科", "jihua": 13, "luqu": 13, "min_score": 593 },
  { "name": "中国民航大学", "is_wenli": "文科", "jihua": 13, "luqu": 13, "min_score": 591 },
  { "name": "华南农业大学", "is_wenli": "文科", "jihua": 13, "luqu": 13, "min_score": 589 },
  { "name": "山西财经大学", "is_wenli": "文科", "jihua": 13, "luqu": 13, "min_score": 587 },
  { "name": "重庆交通大学", "is_wenli": "文科", "jihua": 13, "luqu": 13, "min_score": 586 },
  { "name": "江西理工大学", "is_wenli": "文科", "jihua": 13, "luqu": 13, "min_score": 573 },
  { "name": "北京师范大学-香港浸会大学联合国际学院", "is_wenli": "文科", "jihua": 13, "luqu": 13, "min_score": 570 },
  { "name": "复旦大学", "is_wenli": "文科", "jihua": 11, "luqu": 12, "min_score": 657 },
  { "name": "西安外国语大学", "is_wenli": "文科", "jihua": 11, "luqu": 12, "min_score": 601 },
  { "name": "浙江理工大学", "is_wenli": "文科", "jihua": 12, "luqu": 12, "min_score": 593 },
  { "name": "西安邮电大学", "is_wenli": "文科", "jihua": 12, "luqu": 12, "min_score": 590 },
  { "name": "黑龙江中医药大学", "is_wenli": "文科", "jihua": 12, "luqu": 12, "min_score": 584 },
  { "name": "山东科技大学", "is_wenli": "文科", "jihua": 12, "luqu": 12, "min_score": 578 },
  { "name": "上海体育学院", "is_wenli": "文科", "jihua": 12, "luqu": 12, "min_score": 571 },
  { "name": "湖州师范学院", "is_wenli": "文科", "jihua": 12, "luqu": 12, "min_score": 570 },
  { "name": "吉首大学", "is_wenli": "文科", "jihua": 12, "luqu": 12, "min_score": 566 },
  { "name": "北京理工大学珠海学院", "is_wenli": "文科", "jihua": 13, "luqu": 12, "min_score": 556 },
  { "name": "上海外国语大学", "is_wenli": "文科", "jihua": 11, "luqu": 11, "min_score": 626 },
  { "name": "中国矿业大学(北京)", "is_wenli": "文科", "jihua": 11, "luqu": 11, "min_score": 608 },
  { "name": "东北财经大学", "is_wenli": "文科", "jihua": 11, "luqu": 11, "min_score": 607 },
  { "name": "广州中医药大学", "is_wenli": "文科", "jihua": 11, "luqu": 11, "min_score": 594 },
  { "name": "内蒙古大学", "is_wenli": "文科", "jihua": 11, "luqu": 11, "min_score": 593 },
  { "name": "广东工业大学", "is_wenli": "文科", "jihua": 10, "luqu": 11, "min_score": 588 },
  { "name": "中国石油大学(北京)", "is_wenli": "文科", "jihua": 11, "luqu": 11, "min_score": 582 },
  { "name": "湖南工商大学(原湖南商学院)", "is_wenli": "文科", "jihua": 11, "luqu": 11, "min_score": 581 },
  { "name": "东北财经大学[较高收费]", "is_wenli": "文科", "jihua": 11, "luqu": 11, "min_score": 580 },
  { "name": "西安石油大学", "is_wenli": "文科", "jihua": 11, "luqu": 11, "min_score": 579 },
  { "name": "北京理工大学", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 629 },
  { "name": "华北电力大学(北京)", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 610 },
  { "name": "福州大学", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 609 },
  { "name": "河北工业大学", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 603 },
  { "name": "北京第二外国语学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 602 },
  { "name": "太原理工大学", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 600 },
  { "name": "浙江财经大学", "is_wenli": "文科", "jihua": 7, "luqu": 10, "min_score": 597 },
  { "name": "成都理工大学", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 590 },
  { "name": "北京物资学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 587 },
  { "name": "重庆理工大学", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 583 },
  { "name": "陕西科技大学", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 582 },
  { "name": "成都中医药大学", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 581 },
  { "name": "石家庄铁道大学", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 578 },
  { "name": "浙大宁波理工学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 572 },
  { "name": "南昌工程学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 566 },
  { "name": "哈尔滨医科大学", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 564 },
  { "name": "川北医学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 561 },
  { "name": "昆明理工大学[较高收费]", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 559 },
  { "name": "北京邮电大学", "is_wenli": "文科", "jihua": 9, "luqu": 9, "min_score": 605 },
  { "name": "北方工业大学", "is_wenli": "文科", "jihua": 9, "luqu": 9, "min_score": 592 },
  { "name": "沈阳工业大学", "is_wenli": "文科", "jihua": 7, "luqu": 9, "min_score": 575 },
  { "name": "北京交通大学", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 620 },
  { "name": "首都师范大学", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 608 },
  { "name": "首都经济贸易大学", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 608 },
  { "name": "厦门大学[异地校区]", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 597 },
  { "name": "浙江师范大学", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 597 },
  { "name": "西安建筑科技大学", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 590 },
  { "name": "深圳技术大学", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 581 },
  { "name": "南华大学", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 576 },
  { "name": "宁夏大学[较高收费]", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 576 },
  { "name": "大连工业大学", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 572 },
  { "name": "安徽建筑大学", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 571 },
  { "name": "上海工程技术大学", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 567 },
  { "name": "成都理工大学[异地校区]", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 564 },
  { "name": "山东农业大学", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 561 },
  { "name": "福建师范大学[较高收费]", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 558 },
  { "name": "华中农业大学[较高收费]", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 557 },
  { "name": "北方民族大学", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 556 },
  { "name": "电子科技大学", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 621 },
  { "name": "西北工业大学", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 615 },
  { "name": "西南大学(荣昌校区)", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 600 },
  { "name": "上海理工大学[较高收费]", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 588 },
  { "name": "昆明理工大学", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 584 },
  { "name": "成都大学(原成都学院)", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 583 },
  { "name": "上海海洋大学[较高收费]", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 579 },
  { "name": "安徽工业大学", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 573 },
  { "name": "长春理工大学", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 571 },
  { "name": "贵州大学[较高收费]", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 570 },
  { "name": "南昌航空大学", "is_wenli": "文科", "jihua": 5, "luqu": 7, "min_score": 570 },
  { "name": "赣南师范大学", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 566 },
  { "name": "福建医科大学", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 563 },
  { "name": "大连民族大学[仅招少数民族]", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 560 },
  { "name": "西南政法大学[较高收费]", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 614 },
  { "name": "中国农业大学[较高收费]", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 611 },
  { "name": "上海对外经贸大学[较高收费]", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 603 },
  { "name": "广州大学", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 599 },
  { "name": "北京交通大学(威海校区)", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 597 },
  { "name": "南京林业大学", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 596 },
  { "name": "北京信息科技大学", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 588 },
  { "name": "广州医科大学", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 577 },
  { "name": "贵州大学[其他单列专业]", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 566 },
  { "name": "上海应用技术大学[较高收费]", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 562 },
  { "name": "湖南理工学院", "is_wenli": "文科", "jihua": 3, "luqu": 6, "min_score": 560 },
  { "name": "武汉大学[其他单列专业]", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 623 },
  { "name": "中国地质大学(北京)", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 613 },
  { "name": "西南大学[较高收费]", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 609 },
  { "name": "中国药科大学", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 602 },
  { "name": "吉林大学[异地校区]", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 600 },
  { "name": "上海海关学院", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 596 },
  { "name": "西南交通大学[异地校区]", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 589 },
  { "name": "南方医科大学[护理]", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 578 },
  { "name": "天津外国语大学[较高收费]", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 576 },
  { "name": "天津科技大学[较高收费]", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 576 },
  { "name": "长春中医药大学", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 563 },
  { "name": "清华大学", "is_wenli": "文科", "jihua": 2, "luqu": 4, "min_score": 669 },
  { "name": "北京外国语大学[较高收费]", "is_wenli": "文科", "jihua": 3, "luqu": 4, "min_score": 618 },
  { "name": "上海大学[较高收费]", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 617 },
  { "name": "武汉大学[护理]", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 612 },
  { "name": "中国海洋大学[较高收费]", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 609 },
  { "name": "北京建筑大学", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 590 },
  { "name": "浙江财经大学[较高收费]", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 589 },
  { "name": "北京联合大学", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 588 },
  { "name": "青岛理工大学", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 583 },
  { "name": "西安科技大学", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 583 },
  { "name": "华北理工大学", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 569 },
  { "name": "山东科技大学[较高收费]", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 568 },
  { "name": "中国民用航空飞行学院", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 566 },
  { "name": "吉林财经大学[较高收费]", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 566 },
  { "name": "四川轻化工大学(原四川理工学院)", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 562 },
  { "name": "江西中医药大学", "is_wenli": "文科", "jihua": 2, "luqu": 4, "min_score": 561 },
  { "name": "甘肃中医药大学", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 559 },
  { "name": "西安医学院", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 557 },
  { "name": "中国人民大学(苏州校区)", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 636 },
  { "name": "武汉理工大学[较高收费]", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 611 },
  { "name": "中国人民公安大学", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 609 },
  { "name": "中国计量大学", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 598 },
  { "name": "东北师范大学[其他单列专业]", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 590 },
  { "name": "西安外国语大学[较高收费]", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 586 },
  { "name": "宁波大学[较高收费]", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 577 },
  { "name": "中国戏曲学院", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 577 },
  { "name": "中北大学", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 568 },
  { "name": "安徽农业大学", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 564 },
  { "name": "西安交通大学", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 631 },
  { "name": "北京外国语大学[其他单列专业]", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 625 },
  { "name": "山东大学威海分校[较高收费]", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 619 },
  { "name": "东北师范大学[较高收费]", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 594 },
  { "name": "南京审计大学[较高收费]", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 594 },
  { "name": "重庆邮电大学", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 593 },
  { "name": "天津财经大学[较高收费]", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 592 },
  { "name": "山西中医药大学", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 588 },
  { "name": "四川外国语大学[较高收费]", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 585 },
  { "name": "延边大学[较高收费]", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 581 },
  { "name": "山东建筑大学", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 580 },
  { "name": "沈阳建筑大学", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 568 },
  { "name": "大连民族大学", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 563 },
  { "name": "江南大学[较高收费]", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 559 },
  { "name": "福建农林大学", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 554 },
  { "name": "上海交通大学", "is_wenli": "文科", "jihua": 1, "luqu": 1, "min_score": 658 },

]