module.exports = [
  { "a": "学科门类名称", "b": "2022届", "c": "2021届", "d": "2020届" },
  { "a": "装备制造大类", "b": "5256", "c": "5021", "d": "4691" },
  { "a": "能源动力与材料大类", "b": "5079", "c": "4836", "d": "4571" },
  { "a": "生物与化工大类", "b": "5041", "c": "4788", "d": "4484" },
  { "a": "交通运输大类", "b": "4972", "c": "5067", "d": "4938" },
  { "a": "电子信息大类", "b": "4921", "c": "4816", "d": "4585" },
  { "a": "资源环境与安全大类", "b": "4788", "c": "4578", "d": "4281" },
  { "a": "新闻传播大类", "b": "4718", "c": "4569", "d": "4381" },
  { "a": "土木建筑大类", "b": "4661", "c": "4504", "d": "4233" },
  { "a": "农林牧渔大类", "b": "4560", "c": "4533", "d": "4235" },
  { "a": "财经商贸大类", "b": "4559", "c": "4478", "d": "4199" },
  { "a": "食品药品与粮食大类", "b": "4527", "c": "4284", "d": "4069" },
  { "a": "文化艺术大类", "b": "4514", "c": "4386", "d": "4155" },
  { "a": "旅游大类", "b": "4410", "c": "4365", "d": "4121" },
  { "a": "公共管理与服务大类", "b": "4299", "c": "4088", "d": "4006" },
  { "a": "医药卫生大类", "b": "3870", "c": "3820", "d": "3687" },
  { "a": "教育与体育大类", "b": "3807", "c": "3889", "d": "3813" },
  { "a": "全国高职", "b": "4595", "c": "4505", "d": "4253" },
]