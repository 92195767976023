module.exports = {
  "高职专业名称": "毕业半年后的平均月收入",
  "铁道工程技术": "5781",
  "铁道交通运营管理": "5745",
  "铁道机车": "5654",
  "动车组检修技术": "5577",
  "机械制造与自动化": "5448",
  "机电一体化技术": "5360",
  "电气自动化技术": "5321",
  "工业机器人技术": "5307",
  "焊接技术与自动化": "5306",
  "应用化工技术": "5299",
  "石油化工技术": "5258",
  "数控技术": "5246",
  "机电设备维修与管理": "5240",
  "发电厂及电力系统": "5227",
  // "智能控制技术": "5227",
  // "模具设计与制造": "5225",
  // "铁道供电技术": "5203",
  // "城市轨道交通工程技术": "5196",
  // "民航运输": "5190",
  // "电子信息工程技术": "5186",
  // "空中乘务": "5181",
  // "工业过程自动化技术": "5151",
  // "机械设计与制造": "5144",
  // "社会体育": "5124",
  // "软件技术": "5119",
  // "物联网应用技术": "5110",
  // "云计算技术与应用": "5101",
  // "数控设备应用与维护": "5084",
  // "移动应用开发": "5051",
  // "新能源汽车技术": "5046",
  // "信息安全与管理": "5044",
  // "工业设计": "5008",
  // "城市轨道交通机电技术": "5001",
  // "城市轨道交通车辆技术": "4998",
  // "电力系统自动化技术": "4976",
  // "汽车营销与服务": "4973",
  // "道路桥梁工程技术": "4969",
  // "轮机工程技术": "4944",
  // "应用电子技术": "4937",
  // "通信技术": "4922",
  // "国际商务": "4891",
  // "汽车制造与装配技术": "4886",
  // "建筑智能化工程技术": "4885",
  // "市场营销": "4868",
  // "畜牧兽医": "4867",
  // "工商企业管理": "4864",
  // "建筑工程技术": "4851",
  // "国际经济与贸易": "4819",
  // "大数据技术与应用": "4815",
  // "移动互联应用技术": "4797",
  // "全国高职": "4595",
}