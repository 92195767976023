<template>
  <div class="CollegeDataDialog">
    <div class="dia_title">
      {{title}}
    </div>
    <el-row>
      <el-col :offset="13">
        <el-radio-group v-model="radio1" @change="changeBatch">
          <el-radio label="1">文科</el-radio>
          <el-radio label="2">理科</el-radio>
        </el-radio-group>
        <el-radio-group v-model="radio2" size="mini" style="margin-left:20px" @change="changeBatch">
          <el-radio-button label="1">本科一批</el-radio-button>
          <el-radio-button label="2">本科二批</el-radio-button>
        </el-radio-group>
      </el-col>
    </el-row>
    <div class="dialog_table_" style="margin-top:15px">
      <table class="dia_table_1" border>
        <tr>
          <td style="width:56%">专业名称</td>
          <td style="width:11%">科类</td>
          <td style="width:11%">计划数</td>
          <td style="width:11%">录取数</td>
          <td style="width:11%">报考</td>
        </tr>
      </table>
      <el-scrollbar>
        <table class="dia_table" border>
          <tr v-for="(item,i) in tableData" :key="i">
            <td style="width:56%;text-align:left;padding: 0 20px;">{{item['name']}}</td>
            <td style="width:11%">{{item['is_wenli']}}</td>
            <td style="width:11%">{{item['jihua']}}</td>
            <td style="width:11%"> {{item['luqu']}}</td>
            <td style="width:11%"> {{item['baokao']}}</td>
          </tr>
        </table>
      </el-scrollbar>
    </div>
    <div style="margin-top:30px">数据来源：河南省招生办公室2022年院校录取数据，按照录取数进行排序。
    </div>
  </div>
</template>

<script>
import m1 from "./mojar/m1";
import m2 from "./mojar/m2";
import m3 from "./mojar/m3";
import m4 from "./mojar/m4";

export default {
  name: "mojar-data",
  data() {
    return {
      tableData: {},
      radio1: "1",
      radio2: "1",
    };
  },
  
  created() {
    this.change2();
  },
  methods: {
    change2() {
      this.title = "在豫招生热门专业";
      this.CollegeData = true;
      this.tableData = m1;
      // this.tableData = this.tableData.sort(this.sortBankList("jihua"));
    },
    changeBatch() {
      if (this.radio1 == 1) {
        if (this.radio2 == 1) {
          this.tableData = m1;
        } else {
          this.tableData = m3;
        }
      } else {
        if (this.radio2 == 1) {
          this.tableData = m2;
        } else {
          this.tableData = m4;
        }
      }
      // this.tableData = this.tableData.sort(this.sortBankList("jihua"));
    },

    sortBankList(propertyName) {
      return function sortList(object1, object2) {
        const value1 = object1[propertyName];
        const value2 = object2[propertyName];
        if (value2 > value1) {
          return 1;
        }
        if (value2 < value1) {
          return -1;
        }
        return 0;
      };
    },
  },
};
</script>
<style lang="less" scoped>
.dia_title {
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
  color: #65a7fe;
}
.dia_table_1 {
  width: 100%;
  font-size: 14px;
  height: 40px;
  background: #f4f7fc;
  border: 1px solid #dfdfdf;
  border-collapse: collapse;
  text-align: center;
  border-bottom: 0;
  td {
    border-bottom: 0;
  }
}

.dia_table {
  width: 100%;
  font-size: 14px;
  text-align: center;
  border: 1px solid #dfdfdf;
  border-collapse: collapse;
  td {
    width: 260px;
    border: 1px solid #dfdfdf;
    line-height: 32px;
    height: 33px;
  }
  margin: 0 auto;
}
</style>


<style lang='less'>
.CollegeDataDialog {
  padding: 0 30px;
  .el-dialog__body {
    padding: 30px 50px;
  }

  .el-dialog__header {
    padding: 0;
  }

  .dialog_table_ {
    height: 460px !important;
    overflow-y: hidden;

    .el-scrollbar {
      height: 100%;
    }
    .el-scrollbar__bar {
      &.is-vertical {
        width: 10px; //滚动条宽度
      }
    }
    .el-scrollbar__wrap {
      overflow-y: auto;
      overflow-x: hidden;
    }
    .el-scrollbar__thumb {
      // 可设置滚动条颜色
      background: #dddddd;
    }
  }
}
</style>
