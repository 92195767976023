module.exports = {
  "本科专业名称": "工作与专业相关度",
  "医学影像学": "99",
  "口腔医学": "98",
  "临床医学": "97",
  "预防医学": "97",
  "医学检验技术": "96",
  "护理学": "95",
  "针灸推拿学": "95",
  "医学影像技术": "95",
  "中医学": "94",
  "麻醉学": "93",
  "药学": "90",
  "康复治疗学": "89",
  "地理科学": "89",
  // "汉语言文学": "88",
  // "工程造价": "88",
  // "药物制剂": "88",
  // "小学教育": "87",
  // "体育教育": "87",
  // "土木工程": "87",
  // "动物医学": "86",
  // "道路桥梁与渡河工程": "86",
  // "建筑学": "85",
  // "学前教育": "85",
  // "中药学": "85",
  // "电气工程及其自动化": "84",
  // "给排水科学与工程": "83",
  // "教育学": "83",
  // "水利水电工程": "83",
  // "安全工程": "82",
  // "运动训练": "82",
  // "全国本科": "74",
}