module.exports = [
  { "name": "[5101 ] 农业类", "is_wenli": "理科", "jihua": 1, "baokao": 0, "luqu": 1 },
  { "name": "[510101] 作物生产技术", "is_wenli": "理科", "jihua": 73, "baokao": 2, "luqu": 4 },
  { "name": "[510102] 种子生产与经营", "is_wenli": "理科", "jihua": 99, "baokao": 27, "luqu": 29 },
  { "name": "[510103] 设施农业与装备", "is_wenli": "理科", "jihua": 62, "baokao": 23, "luqu": 55 },
  { "name": "[510104] 现代农业技术", "is_wenli": "理科", "jihua": 125, "baokao": 42, "luqu": 78 },
  { "name": "[510105] 休闲农业", "is_wenli": "理科", "jihua": 9, "baokao": 2, "luqu": 3 },
  { "name": "[510106] 生态农业技术", "is_wenli": "理科", "jihua": 15, "baokao": 2, "luqu": 7 },
  { "name": "[510107] 园艺技术", "is_wenli": "理科", "jihua": 295, "baokao": 88, "luqu": 141 },
  { "name": "[510108] 植物保护与检疫技术", "is_wenli": "理科", "jihua": 30, "baokao": 14, "luqu": 15 },
  { "name": "[510109] 茶树栽培与茶叶加工", "is_wenli": "理科", "jihua": 8, "baokao": 0, "luqu": 2 },
  { "name": "[510110] 中草药栽培技术", "is_wenli": "理科", "jihua": 12, "baokao": 19, "luqu": 8 },
  { "name": "[510113] 农产品加工与质量检测", "is_wenli": "理科", "jihua": 51, "baokao": 7, "luqu": 17 },
  { "name": "[510114] 绿色食品生产与检验", "is_wenli": "理科", "jihua": 52, "baokao": 6, "luqu": 29 },
  { "name": "[510116] 农产品流通与管理", "is_wenli": "理科", "jihua": 10, "baokao": 0, "luqu": 2 },
  { "name": "[510117] 农业装备应用技术", "is_wenli": "理科", "jihua": 30, "baokao": 0, "luqu": 15 },
  { "name": "[510120] 食用菌生产与加工", "is_wenli": "理科", "jihua": 10, "baokao": 14, "luqu": 10 },
  { "name": "[510201] 林业技术", "is_wenli": "理科", "jihua": 43, "baokao": 21, "luqu": 27 },
  { "name": "[510202] 园林技术", "is_wenli": "理科", "jihua": 360, "baokao": 149, "luqu": 199 },
  { "name": "[510206] 野生动物资源保护与利用", "is_wenli": "理科", "jihua": 1, "baokao": 2, "luqu": 3 },
  { "name": "[510209] 自然保护区建设与管理", "is_wenli": "理科", "jihua": 30, "baokao": 11, "luqu": 19 },
  { "name": "[510210] 木工设备应用技术", "is_wenli": "理科", "jihua": 5, "baokao": 2, "luqu": 4 },
  { "name": "[510301] 畜牧兽医", "is_wenli": "理科", "jihua": 306, "baokao": 318, "luqu": 320 },
  { "name": "[510302] 动物医学", "is_wenli": "理科", "jihua": 169, "baokao": 289, "luqu": 368 },
  { "name": "[510303] 动物药学", "is_wenli": "理科", "jihua": 6, "baokao": 2, "luqu": 3 },
  { "name": "[510306] 宠物养护与驯导", "is_wenli": "理科", "jihua": 98, "baokao": 40, "luqu": 71 },
  { "name": "[510315] 宠物临床诊疗技术", "is_wenli": "理科", "jihua": 106, "baokao": 70, "luqu": 62 },
  { "name": "[510401] 水产养殖技术", "is_wenli": "理科", "jihua": 8, "baokao": 4, "luqu": 3 },
  { "name": "[520101] 国土资源调查与管理", "is_wenli": "理科", "jihua": 12, "baokao": 12, "luqu": 19 },
  { "name": "[520102] 地质调查与矿产普查", "is_wenli": "理科", "jihua": 36, "baokao": 6, "luqu": 32 },
  { "name": "[520105] 宝玉石鉴定与加工", "is_wenli": "理科", "jihua": 143, "baokao": 25, "luqu": 106 },
  { "name": "[520201] 工程地质勘查", "is_wenli": "理科", "jihua": 6, "baokao": 1, "luqu": 1 },
  { "name": "[520202] 水文与工程地质", "is_wenli": "理科", "jihua": 49, "baokao": 77, "luqu": 43 },
  { "name": "[520205] 地球物理勘探技术", "is_wenli": "理科", "jihua": 5, "baokao": 0, "luqu": 1 },
  { "name": "[520208] 岩土工程技术", "is_wenli": "理科", "jihua": 5, "baokao": 4, "luqu": 4 },
  { "name": "[5203 ] 测绘地理信息类", "is_wenli": "理科", "jihua": 3, "baokao": 3, "luqu": 3 },
  { "name": "[520301] 工程测量技术", "is_wenli": "理科", "jihua": 687, "baokao": 531, "luqu": 617 },
  { "name": "[520302] 摄影测量与遥感技术", "is_wenli": "理科", "jihua": 136, "baokao": 84, "luqu": 115 },
  { "name": "[520303] 测绘工程技术", "is_wenli": "理科", "jihua": 80, "baokao": 114, "luqu": 85 },
  { "name": "[520304] 测绘地理信息技术", "is_wenli": "理科", "jihua": 98, "baokao": 31, "luqu": 63 },
  { "name": "[520305] 地籍测绘与土地管理", "is_wenli": "理科", "jihua": 49, "baokao": 20, "luqu": 48 },
  { "name": "[520308] 导航与位置服务", "is_wenli": "理科", "jihua": 10, "baokao": 1, "luqu": 4 },
  { "name": "[520309] 地图制图与数字传播技术", "is_wenli": "理科", "jihua": 5, "baokao": 3, "luqu": 3 },
  { "name": "[52030F] 工程测量技术(中外合作办学)", "is_wenli": "理科", "jihua": 20, "baokao": 25, "luqu": 20 },
  { "name": "[520311] 国土测绘与规划", "is_wenli": "理科", "jihua": 35, "baokao": 11, "luqu": 31 },
  { "name": "[520401] 钻井技术", "is_wenli": "理科", "jihua": 3, "baokao": 0, "luqu": 2 },
  { "name": "[520402] 油气开采技术", "is_wenli": "理科", "jihua": 54, "baokao": 8, "luqu": 13 },
  { "name": "[520403] 油气储运技术", "is_wenli": "理科", "jihua": 26, "baokao": 4, "luqu": 6 },
  { "name": "[520405] 油田化学应用技术", "is_wenli": "理科", "jihua": 5, "baokao": 6, "luqu": 5 },
  { "name": "[520406] 石油工程技术", "is_wenli": "理科", "jihua": 81, "baokao": 32, "luqu": 33 },
  { "name": "[520501] 煤矿开采技术", "is_wenli": "理科", "jihua": 16, "baokao": 1, "luqu": 5 },
  { "name": "[520801] 环境监测与控制技术", "is_wenli": "理科", "jihua": 272, "baokao": 178, "luqu": 199 },
  { "name": "[520803] 室内环境检测与控制技术", "is_wenli": "理科", "jihua": 5, "baokao": 1, "luqu": 1 },
  { "name": "[520804] 环境工程技术", "is_wenli": "理科", "jihua": 580, "baokao": 406, "luqu": 456 },
  { "name": "[520807] 环境规划与管理", "is_wenli": "理科", "jihua": 5, "baokao": 1, "luqu": 3 },
  { "name": "[520808] 环境评价与咨询服务", "is_wenli": "理科", "jihua": 9, "baokao": 0, "luqu": 1 },
  { "name": "[520809] 污染修复与生态工程技术", "is_wenli": "理科", "jihua": 66, "baokao": 13, "luqu": 66 },
  { "name": "[520810] 清洁生产与减排技术", "is_wenli": "理科", "jihua": 3, "baokao": 2, "luqu": 2 },
  { "name": "[520812] 水净化与安全技术", "is_wenli": "理科", "jihua": 24, "baokao": 2, "luqu": 8 },
  { "name": "[520901] 安全健康与环保", "is_wenli": "理科", "jihua": 8, "baokao": 2, "luqu": 2 },
  { "name": "[520904] 安全技术与管理", "is_wenli": "理科", "jihua": 104, "baokao": 53, "luqu": 85 },
  { "name": "[5301 ] 电力技术类", "is_wenli": "理科", "jihua": 5, "baokao": 7, "luqu": 7 },
  { "name": "[530101] 发电厂及电力系统", "is_wenli": "理科", "jihua": 211, "baokao": 473, "luqu": 207 },
  { "name": "[530102] 供用电技术", "is_wenli": "理科", "jihua": 289, "baokao": 305, "luqu": 274 },
  { "name": "[530103] 电力系统自动化技术", "is_wenli": "理科", "jihua": 200, "baokao": 545, "luqu": 221 },
  { "name": "[530104] 高压输配电线路施工运行与维护", "is_wenli": "理科", "jihua": 108, "baokao": 84, "luqu": 100 },
  { "name": "[530105] 电力系统继电保护与自动化技术", "is_wenli": "理科", "jihua": 132, "baokao": 178, "luqu": 135 },
  { "name": "[530106] 水电站机电设备与自动化", "is_wenli": "理科", "jihua": 1, "baokao": 1, "luqu": 1 },
  { "name": "[530108] 电力客户服务与管理", "is_wenli": "理科", "jihua": 20, "baokao": 6, "luqu": 20 },
  { "name": "[53010G] 供用电技术(中外合作办学)", "is_wenli": "理科", "jihua": 15, "baokao": 44, "luqu": 15 },
  { "name": "[53010H] 电力系统自动化技术(中外合作办学)", "is_wenli": "理科", "jihua": 15, "baokao": 105, "luqu": 15 },
  { "name": "[530110] 电源变换技术与应用", "is_wenli": "理科", "jihua": 1, "baokao": 0, "luqu": 1 },
  { "name": "[530112] 分布式发电与微电网技术", "is_wenli": "理科", "jihua": 64, "baokao": 3, "luqu": 24 },
  { "name": "[530113] 机场电工技术", "is_wenli": "理科", "jihua": 38, "baokao": 2, "luqu": 8 },
  { "name": "[530201] 电厂热能动力装置", "is_wenli": "理科", "jihua": 91, "baokao": 66, "luqu": 85 },
  { "name": "[530202] 城市热能应用技术", "is_wenli": "理科", "jihua": 8, "baokao": 5, "luqu": 8 },
  { "name": "[530203] 核电站动力设备运行与维护", "is_wenli": "理科", "jihua": 5, "baokao": 3, "luqu": 3 },
  { "name": "[530204] 火电厂集控运行", "is_wenli": "理科", "jihua": 103, "baokao": 40, "luqu": 101 },
  { "name": "[530206] 电厂热工自动化技术", "is_wenli": "理科", "jihua": 4, "baokao": 0, "luqu": 3 },
  { "name": "[5303 ] 新能源发电工程类", "is_wenli": "理科", "jihua": 2, "baokao": 1, "luqu": 1 },
  { "name": "[530301] 风力发电工程技术", "is_wenli": "理科", "jihua": 14, "baokao": 0, "luqu": 2 },
  { "name": "[530302] 风电系统运行与维护", "is_wenli": "理科", "jihua": 20, "baokao": 18, "luqu": 19 },
  { "name": "[530304] 光伏发电技术与应用", "is_wenli": "理科", "jihua": 75, "baokao": 22, "luqu": 60 },
  { "name": "[530306] 节电技术与管理", "is_wenli": "理科", "jihua": 10, "baokao": 2, "luqu": 3 },
  { "name": "[530309] 氢能技术应用", "is_wenli": "理科", "jihua": 2, "baokao": 0, "luqu": 1 },
  { "name": "[530401] 黑色冶金技术", "is_wenli": "理科", "jihua": 36, "baokao": 24, "luqu": 20 },
  { "name": "[530402] 轧钢工程技术", "is_wenli": "理科", "jihua": 8, "baokao": 0, "luqu": 1 },
  { "name": "[530501] 有色冶金技术", "is_wenli": "理科", "jihua": 12, "baokao": 1, "luqu": 3 },
  { "name": "[530503] 金属压力加工", "is_wenli": "理科", "jihua": 10, "baokao": 6, "luqu": 10 },
  { "name": "[530601] 材料工程技术", "is_wenli": "理科", "jihua": 236, "baokao": 336, "luqu": 157 },
  { "name": "[530602] 高分子材料工程技术", "is_wenli": "理科", "jihua": 45, "baokao": 45, "luqu": 33 },
  { "name": "[530603] 复合材料工程技术", "is_wenli": "理科", "jihua": 6, "baokao": 12, "luqu": 6 },
  { "name": "[530608] 橡胶工程技术", "is_wenli": "理科", "jihua": 6, "baokao": 0, "luqu": 3 },
  { "name": "[530702] 建筑材料检测技术", "is_wenli": "理科", "jihua": 15, "baokao": 0, "luqu": 7 },
  { "name": "[530703] 建筑装饰材料技术", "is_wenli": "理科", "jihua": 16, "baokao": 7, "luqu": 3 },
  { "name": "[5401 ] 建筑设计类", "is_wenli": "理科", "jihua": 30, "baokao": 3, "luqu": 4 },
  { "name": "[540101] 建筑设计", "is_wenli": "理科", "jihua": 268, "baokao": 401, "luqu": 262 },
  { "name": "[540102] 建筑装饰工程技术", "is_wenli": "理科", "jihua": 1274, "baokao": 850, "luqu": 1091 },
  { "name": "[540103] 古建筑工程技术", "is_wenli": "理科", "jihua": 31, "baokao": 9, "luqu": 25 },
  { "name": "[540104] 建筑室内设计", "is_wenli": "理科", "jihua": 900, "baokao": 1413, "luqu": 1147 },
  { "name": "[540105] 风景园林设计", "is_wenli": "理科", "jihua": 168, "baokao": 85, "luqu": 101 },
  { "name": "[540106] 园林工程技术", "is_wenli": "理科", "jihua": 218, "baokao": 91, "luqu": 145 },
  { "name": "[540107] 建筑动画与模型制作", "is_wenli": "理科", "jihua": 79, "baokao": 18, "luqu": 58 },
  { "name": "[54010I] 建筑室内设计(中外合作办学)", "is_wenli": "理科", "jihua": 13, "baokao": 2, "luqu": 4 },
  { "name": "[540201] 城乡规划", "is_wenli": "理科", "jihua": 81, "baokao": 32, "luqu": 58 },
  { "name": "[540203] 城市信息化管理", "is_wenli": "理科", "jihua": 12, "baokao": 75, "luqu": 96 },
  { "name": "[5403 ] 土建施工类", "is_wenli": "理科", "jihua": 3, "baokao": 5, "luqu": 2 },
  { "name": "[540301] 建筑工程技术", "is_wenli": "理科", "jihua": 3018, "baokao": 2800, "luqu": 2946 },
  { "name": "[540302] 地下与隧道工程技术", "is_wenli": "理科", "jihua": 78, "baokao": 17, "luqu": 62 },
  { "name": "[540303] 土木工程检测技术", "is_wenli": "理科", "jihua": 80, "baokao": 84, "luqu": 75 },
  { "name": "[540304] 建筑钢结构工程技术", "is_wenli": "理科", "jihua": 30, "baokao": 6, "luqu": 27 },
  { "name": "[54030F] 建筑工程技术(中外合作办学)", "is_wenli": "理科", "jihua": 109, "baokao": 49, "luqu": 112 },
  { "name": "[54030H] 土木工程检测技术(中外合作办学)", "is_wenli": "理科", "jihua": 30, "baokao": 17, "luqu": 30 },
  { "name": "[540401] 建筑设备工程技术", "is_wenli": "理科", "jihua": 82, "baokao": 25, "luqu": 51 },
  { "name": "[540402] 供热通风与空调工程技术", "is_wenli": "理科", "jihua": 112, "baokao": 22, "luqu": 103 },
  { "name": "[540403] 建筑电气工程技术", "is_wenli": "理科", "jihua": 132, "baokao": 59, "luqu": 83 },
  { "name": "[540404] 建筑智能化工程技术", "is_wenli": "理科", "jihua": 538, "baokao": 455, "luqu": 512 },
  { "name": "[540406] 消防工程技术", "is_wenli": "理科", "jihua": 120, "baokao": 56, "luqu": 72 },
  { "name": "[5405 ] 建设工程管理类", "is_wenli": "理科", "jihua": 13, "baokao": 15, "luqu": 15 },
  { "name": "[540501] 建设工程管理", "is_wenli": "理科", "jihua": 546, "baokao": 269, "luqu": 432 },
  { "name": "[540502] 工程造价", "is_wenli": "理科", "jihua": 2852, "baokao": 4321, "luqu": 3248 },
  { "name": "[540503] 建筑经济管理", "is_wenli": "理科", "jihua": 46, "baokao": 7, "luqu": 40 },
  { "name": "[540504] 建设项目信息化管理", "is_wenli": "理科", "jihua": 153, "baokao": 18, "luqu": 134 },
  { "name": "[540505] 建设工程监理", "is_wenli": "理科", "jihua": 272, "baokao": 71, "luqu": 165 },
  { "name": "[54050F] 建设工程管理(中外合作办学)", "is_wenli": "理科", "jihua": 50, "baokao": 15, "luqu": 50 },
  { "name": "[54050G] 工程造价(中外合作办学)", "is_wenli": "理科", "jihua": 100, "baokao": 138, "luqu": 100 },
  { "name": "[540601] 市政工程技术", "is_wenli": "理科", "jihua": 262, "baokao": 53, "luqu": 145 },
  { "name": "[540602] 城市燃气工程技术", "is_wenli": "理科", "jihua": 37, "baokao": 6, "luqu": 23 },
  { "name": "[540603] 给排水工程技术", "is_wenli": "理科", "jihua": 77, "baokao": 65, "luqu": 62 },
  { "name": "[540701] 房地产经营与管理", "is_wenli": "理科", "jihua": 102, "baokao": 15, "luqu": 78 },
  { "name": "[540702] 房地产检测与估价", "is_wenli": "理科", "jihua": 16, "baokao": 2, "luqu": 1 },
  { "name": "[540703] 物业管理", "is_wenli": "理科", "jihua": 259, "baokao": 60, "luqu": 165 },
  { "name": "[550101] 水文与水资源工程", "is_wenli": "理科", "jihua": 37, "baokao": 33, "luqu": 37 },
  { "name": "[550103] 水政水资源管理", "is_wenli": "理科", "jihua": 14, "baokao": 1, "luqu": 14 },
  { "name": "[5502 ] 水利工程与管理类", "is_wenli": "理科", "jihua": 213, "baokao": 623, "luqu": 213 },
  { "name": "[550201] 水利工程", "is_wenli": "理科", "jihua": 75, "baokao": 53, "luqu": 62 },
  { "name": "[550202] 水利水电工程技术", "is_wenli": "理科", "jihua": 12, "baokao": 2, "luqu": 3 },
  { "name": "[550203] 水利水电工程管理", "is_wenli": "理科", "jihua": 35, "baokao": 12, "luqu": 21 },
  { "name": "[550204] 水利水电建筑工程", "is_wenli": "理科", "jihua": 89, "baokao": 44, "luqu": 76 },
  { "name": "[550301] 水电站动力设备", "is_wenli": "理科", "jihua": 45, "baokao": 11, "luqu": 45 },
  { "name": "[550401] 水土保持技术", "is_wenli": "理科", "jihua": 15, "baokao": 9, "luqu": 15 },
  { "name": "[5601 ] 机械设计制造类", "is_wenli": "理科", "jihua": 49, "baokao": 65, "luqu": 38 },
  { "name": "[560101] 机械设计与制造", "is_wenli": "理科", "jihua": 855, "baokao": 1065, "luqu": 727 },
  { "name": "[560102] 机械制造与自动化", "is_wenli": "理科", "jihua": 1684, "baokao": 2093, "luqu": 1699 },
  { "name": "[560103] 数控技术", "is_wenli": "理科", "jihua": 1545, "baokao": 887, "luqu": 1109 },
  { "name": "[560104] 精密机械技术", "is_wenli": "理科", "jihua": 9, "baokao": 5, "luqu": 7 },
  { "name": "[560105] 特种加工技术", "is_wenli": "理科", "jihua": 63, "baokao": 2, "luqu": 12 },
  { "name": "[560106] 材料成型与控制技术", "is_wenli": "理科", "jihua": 44, "baokao": 6, "luqu": 11 },
  { "name": "[56010F] 机械设计与制造(中外合作办学)", "is_wenli": "理科", "jihua": 185, "baokao": 76, "luqu": 128 },
  { "name": "[56010G] 机械制造与自动化(中外合作办学)", "is_wenli": "理科", "jihua": 35, "baokao": 19, "luqu": 17 },
  { "name": "[560110] 焊接技术与自动化", "is_wenli": "理科", "jihua": 156, "baokao": 43, "luqu": 64 },
  { "name": "[560111] 机械产品检测检验技术", "is_wenli": "理科", "jihua": 24, "baokao": 1, "luqu": 6 },
  { "name": "[560112] 理化测试与质检技术", "is_wenli": "理科", "jihua": 36, "baokao": 5, "luqu": 15 },
  { "name": "[560113] 模具设计与制造", "is_wenli": "理科", "jihua": 672, "baokao": 225, "luqu": 403 },
  { "name": "[560114] 电机与电器技术", "is_wenli": "理科", "jihua": 5, "baokao": 1, "luqu": 5 },
  { "name": "[560115] 电线电缆制造技术", "is_wenli": "理科", "jihua": 201, "baokao": 44, "luqu": 169 },
  { "name": "[560117] 机械装备制造技术", "is_wenli": "理科", "jihua": 5, "baokao": 1, "luqu": 9 },
  { "name": "[560118] 工业设计", "is_wenli": "理科", "jihua": 130, "baokao": 34, "luqu": 93 },
  { "name": "[560119] 工业工程技术", "is_wenli": "理科", "jihua": 6, "baokao": 1, "luqu": 4 },
  { "name": "[56011H] 模具设计与制造(中外合作办学)", "is_wenli": "理科", "jihua": 2, "baokao": 0, "luqu": 1 },
  { "name": "[560201] 自动化生产设备应用", "is_wenli": "理科", "jihua": 177, "baokao": 34, "luqu": 172 },
  { "name": "[560203] 机电设备维修与管理", "is_wenli": "理科", "jihua": 190, "baokao": 56, "luqu": 78 },
  { "name": "[560204] 数控设备应用与维护", "is_wenli": "理科", "jihua": 161, "baokao": 8, "luqu": 66 },
  { "name": "[560205] 制冷与空调技术", "is_wenli": "理科", "jihua": 114, "baokao": 16, "luqu": 48 },
  { "name": "[560206] 光电制造与应用技术", "is_wenli": "理科", "jihua": 31, "baokao": 12, "luqu": 11 },
  { "name": "[560207] 新能源装备技术", "is_wenli": "理科", "jihua": 38, "baokao": 33, "luqu": 23 },
  { "name": "[5603 ] 自动化类", "is_wenli": "理科", "jihua": 33, "baokao": 38, "luqu": 18 },
  { "name": "[560301] 机电一体化技术", "is_wenli": "理科", "jihua": 4194, "baokao": 4421, "luqu": 4611 },
  { "name": "[560302] 电气自动化技术", "is_wenli": "理科", "jihua": 2573, "baokao": 2361, "luqu": 2250 },
  { "name": "[560303] 工业过程自动化技术", "is_wenli": "理科", "jihua": 79, "baokao": 21, "luqu": 72 },
  { "name": "[560304] 智能控制技术", "is_wenli": "理科", "jihua": 828, "baokao": 337, "luqu": 674 },
  { "name": "[560305] 工业网络技术", "is_wenli": "理科", "jihua": 36, "baokao": 16, "luqu": 26 },
  { "name": "[560307] 液压与气动技术", "is_wenli": "理科", "jihua": 4, "baokao": 22, "luqu": 4 },
  { "name": "[560308] 电梯工程技术", "is_wenli": "理科", "jihua": 331, "baokao": 74, "luqu": 155 },
  { "name": "[560309] 工业机器人技术", "is_wenli": "理科", "jihua": 2470, "baokao": 1040, "luqu": 1674 },
  { "name": "[56030F] 机电一体化技术(中外合作办学)", "is_wenli": "理科", "jihua": 199, "baokao": 41, "luqu": 115 },
  { "name": "[56030G] 电气自动化技术(中外合作办学)", "is_wenli": "理科", "jihua": 57, "baokao": 35, "luqu": 54 },
  { "name": "[56030I] 智能控制技术(中外合作办学)", "is_wenli": "理科", "jihua": 15, "baokao": 2, "luqu": 3 },
  { "name": "[560401] 铁道机车车辆制造与维护", "is_wenli": "理科", "jihua": 24, "baokao": 20, "luqu": 24 },
  { "name": "[560402] 铁道通信信号设备制造与维护", "is_wenli": "理科", "jihua": 55, "baokao": 78, "luqu": 55 },
  { "name": "[560501] 船舶工程技术", "is_wenli": "理科", "jihua": 41, "baokao": 32, "luqu": 22 },
  { "name": "[560502] 船舶机械工程技术", "is_wenli": "理科", "jihua": 9, "baokao": 0, "luqu": 3 },
  { "name": "[560503] 船舶电气工程技术", "is_wenli": "理科", "jihua": 13, "baokao": 2, "luqu": 3 },
  { "name": "[560504] 船舶舾装工程技术", "is_wenli": "理科", "jihua": 6, "baokao": 0, "luqu": 4 },
  { "name": "[560506] 游艇设计与制造", "is_wenli": "理科", "jihua": 7, "baokao": 2, "luqu": 3 },
  { "name": "[560507] 海洋工程技术", "is_wenli": "理科", "jihua": 7, "baokao": 6, "luqu": 5 },
  { "name": "[560509] 船舶动力工程技术", "is_wenli": "理科", "jihua": 16, "baokao": 2, "luqu": 6 },
  { "name": "[5606 ] 航空装备类", "is_wenli": "理科", "jihua": 2, "baokao": 2, "luqu": 2 },
  { "name": "[560601] 飞行器制造技术", "is_wenli": "理科", "jihua": 64, "baokao": 73, "luqu": 63 },
  { "name": "[560602] 飞行器维修技术", "is_wenli": "理科", "jihua": 13, "baokao": 11, "luqu": 12 },
  { "name": "[560603] 航空发动机制造技术", "is_wenli": "理科", "jihua": 5, "baokao": 15, "luqu": 4 },
  { "name": "[560604] 航空发动机装试技术", "is_wenli": "理科", "jihua": 4, "baokao": 4, "luqu": 4 },
  { "name": "[560605] 航空发动机维修技术", "is_wenli": "理科", "jihua": 9, "baokao": 9, "luqu": 10 },
  { "name": "[560608] 航空电子电气技术", "is_wenli": "理科", "jihua": 12, "baokao": 1, "luqu": 5 },
  { "name": "[560609] 航空材料精密成型技术", "is_wenli": "理科", "jihua": 15, "baokao": 2, "luqu": 9 },
  { "name": "[560610] 无人机应用技术", "is_wenli": "理科", "jihua": 755, "baokao": 353, "luqu": 450 },
  { "name": "[560611] 导弹维修", "is_wenli": "理科", "jihua": 3, "baokao": 4, "luqu": 3 },
  { "name": "[5607 ] 汽车制造类", "is_wenli": "理科", "jihua": 22, "baokao": 2, "luqu": 17 },
  { "name": "[560701] 汽车制造与装配技术", "is_wenli": "理科", "jihua": 463, "baokao": 105, "luqu": 219 },
  { "name": "[560702] 汽车检测与维修技术", "is_wenli": "理科", "jihua": 2606, "baokao": 989, "luqu": 1584 },
  { "name": "[560703] 汽车电子技术", "is_wenli": "理科", "jihua": 279, "baokao": 52, "luqu": 98 },
  { "name": "[560706] 汽车改装技术", "is_wenli": "理科", "jihua": 29, "baokao": 9, "luqu": 27 },
  { "name": "[560707] 新能源汽车技术", "is_wenli": "理科", "jihua": 1902, "baokao": 923, "luqu": 1311 },
  { "name": "[56070G] 汽车检测与维修技术(中外合作办学)", "is_wenli": "理科", "jihua": 85, "baokao": 2, "luqu": 35 },
  { "name": "[570101] 食品生物技术", "is_wenli": "理科", "jihua": 184, "baokao": 91, "luqu": 108 },
  { "name": "[570102] 化工生物技术", "is_wenli": "理科", "jihua": 34, "baokao": 16, "luqu": 30 },
  { "name": "[570103] 药品生物技术", "is_wenli": "理科", "jihua": 92, "baokao": 73, "luqu": 89 },
  { "name": "[570104] 农业生物技术", "is_wenli": "理科", "jihua": 59, "baokao": 17, "luqu": 44 },
  { "name": "[570105] 生物产品检验检疫", "is_wenli": "理科", "jihua": 3, "baokao": 2, "luqu": 3 },
  { "name": "[5702 ] 化工技术类", "is_wenli": "理科", "jihua": 25, "baokao": 6, "luqu": 14 },
  { "name": "[570201] 应用化工技术", "is_wenli": "理科", "jihua": 517, "baokao": 268, "luqu": 447 },
  { "name": "[570202] 石油炼制技术", "is_wenli": "理科", "jihua": 1, "baokao": 3, "luqu": 1 },
  { "name": "[570203] 石油化工技术", "is_wenli": "理科", "jihua": 95, "baokao": 16, "luqu": 24 },
  { "name": "[570205] 精细化工技术", "is_wenli": "理科", "jihua": 52, "baokao": 11, "luqu": 49 },
  { "name": "[570206] 海洋化工技术", "is_wenli": "理科", "jihua": 4, "baokao": 0, "luqu": 1 },
  { "name": "[570207] 工业分析技术", "is_wenli": "理科", "jihua": 154, "baokao": 42, "luqu": 119 },
  { "name": "[570208] 化工装备技术", "is_wenli": "理科", "jihua": 9, "baokao": 0, "luqu": 5 },
  { "name": "[580101] 高分子材料加工技术", "is_wenli": "理科", "jihua": 30, "baokao": 1, "luqu": 30 },
  { "name": "[580105] 家具设计与制造", "is_wenli": "理科", "jihua": 17, "baokao": 1, "luqu": 1 },
  { "name": "[580106] 化妆品技术", "is_wenli": "理科", "jihua": 34, "baokao": 17, "luqu": 28 },
  { "name": "[580108] 皮具制作与工艺", "is_wenli": "理科", "jihua": 32, "baokao": 3, "luqu": 30 },
  { "name": "[580110] 乐器制造与维护", "is_wenli": "理科", "jihua": 60, "baokao": 41, "luqu": 56 },
  { "name": "[580111] 陶瓷制造工艺", "is_wenli": "理科", "jihua": 4, "baokao": 1, "luqu": 4 },
  { "name": "[580201] 包装工程技术", "is_wenli": "理科", "jihua": 11, "baokao": 2, "luqu": 6 },
  { "name": "[580202] 包装策划与设计", "is_wenli": "理科", "jihua": 69, "baokao": 27, "luqu": 54 },
  { "name": "[580204] 食品包装技术", "is_wenli": "理科", "jihua": 12, "baokao": 1, "luqu": 1 },
  { "name": "[580301] 数字图文信息技术", "is_wenli": "理科", "jihua": 24, "baokao": 27, "luqu": 18 },
  { "name": "[580304] 印刷媒体技术", "is_wenli": "理科", "jihua": 11, "baokao": 12, "luqu": 7 },
  { "name": "[580401] 现代纺织技术", "is_wenli": "理科", "jihua": 141, "baokao": 18, "luqu": 132 },
  { "name": "[580403] 染整技术", "is_wenli": "理科", "jihua": 133, "baokao": 2, "luqu": 130 },
  { "name": "[580404] 纺织机电技术", "is_wenli": "理科", "jihua": 130, "baokao": 1, "luqu": 130 },
  { "name": "[580406] 纺织品设计", "is_wenli": "理科", "jihua": 22, "baokao": 4, "luqu": 8 },
  { "name": "[580410] 服装设计与工艺", "is_wenli": "理科", "jihua": 260, "baokao": 202, "luqu": 238 },
  { "name": "[580412] 服装陈列与展示设计", "is_wenli": "理科", "jihua": 56, "baokao": 3, "luqu": 11 },
  { "name": "[590101] 食品加工技术", "is_wenli": "理科", "jihua": 534, "baokao": 98, "luqu": 186 },
  { "name": "[590102] 酿酒技术", "is_wenli": "理科", "jihua": 10, "baokao": 2, "luqu": 10 },
  { "name": "[590103] 食品质量与安全", "is_wenli": "理科", "jihua": 231, "baokao": 65, "luqu": 132 },
  { "name": "[590104] 食品贮运与营销", "is_wenli": "理科", "jihua": 46, "baokao": 1, "luqu": 25 },
  { "name": "[590105] 食品检测技术", "is_wenli": "理科", "jihua": 121, "baokao": 24, "luqu": 30 },
  { "name": "[590106] 食品营养与卫生", "is_wenli": "理科", "jihua": 1, "baokao": 5, "luqu": 1 },
  { "name": "[590107] 食品营养与检测", "is_wenli": "理科", "jihua": 703, "baokao": 341, "luqu": 470 },
  { "name": "[590201] 中药生产与加工", "is_wenli": "理科", "jihua": 13, "baokao": 3, "luqu": 6 },
  { "name": "[590202] 药品生产技术", "is_wenli": "理科", "jihua": 321, "baokao": 115, "luqu": 166 },
  { "name": "[590204] 药品质量与安全", "is_wenli": "理科", "jihua": 77, "baokao": 16, "luqu": 51 },
  { "name": "[590205] 制药设备应用技术", "is_wenli": "理科", "jihua": 20, "baokao": 1, "luqu": 12 },
  { "name": "[590206] 化学制药技术", "is_wenli": "理科", "jihua": 23, "baokao": 9, "luqu": 20 },
  { "name": "[590207] 生物制药技术", "is_wenli": "理科", "jihua": 68, "baokao": 32, "luqu": 44 },
  { "name": "[590208] 中药制药技术", "is_wenli": "理科", "jihua": 73, "baokao": 18, "luqu": 42 },
  { "name": "[590209] 药物制剂技术", "is_wenli": "理科", "jihua": 74, "baokao": 29, "luqu": 58 },
  { "name": "[590301] 药品经营与管理", "is_wenli": "理科", "jihua": 247, "baokao": 80, "luqu": 162 },
  { "name": "[590303] 保健品开发与管理", "is_wenli": "理科", "jihua": 2, "baokao": 0, "luqu": 2 },
  { "name": "[590304] 化妆品经营与管理", "is_wenli": "理科", "jihua": 32, "baokao": 13, "luqu": 30 },
  { "name": "[590305] 食品药品监督管理", "is_wenli": "理科", "jihua": 173, "baokao": 33, "luqu": 91 },
  { "name": "[590401] 粮食工程技术", "is_wenli": "理科", "jihua": 55, "baokao": 2, "luqu": 5 },
  { "name": "[590501] 粮油储藏与检测技术", "is_wenli": "理科", "jihua": 50, "baokao": 2, "luqu": 2 },
  { "name": "[600101] 铁道机车", "is_wenli": "理科", "jihua": 318, "baokao": 1202, "luqu": 328 },
  { "name": "[600102] 铁道车辆", "is_wenli": "理科", "jihua": 183, "baokao": 252, "luqu": 176 },
  { "name": "[600103] 铁道供电技术", "is_wenli": "理科", "jihua": 266, "baokao": 208, "luqu": 241 },
  { "name": "[600104] 铁道工程技术", "is_wenli": "理科", "jihua": 188, "baokao": 206, "luqu": 179 },
  { "name": "[600105] 铁道机械化维修技术", "is_wenli": "理科", "jihua": 104, "baokao": 88, "luqu": 104 },
  { "name": "[600106] 铁道信号自动控制", "is_wenli": "理科", "jihua": 108, "baokao": 222, "luqu": 106 },
  { "name": "[600107] 铁道通信与信息化技术", "is_wenli": "理科", "jihua": 219, "baokao": 151, "luqu": 156 },
  { "name": "[600108] 铁道交通运营管理", "is_wenli": "理科", "jihua": 103, "baokao": 255, "luqu": 105 },
  { "name": "[600109] 铁路物流管理", "is_wenli": "理科", "jihua": 17, "baokao": 2, "luqu": 15 },
  { "name": "[60010F] 铁道机车(中外合作办学)", "is_wenli": "理科", "jihua": 72, "baokao": 164, "luqu": 72 },
  { "name": "[60010G] 铁道车辆(中外合作办学)", "is_wenli": "理科", "jihua": 27, "baokao": 34, "luqu": 27 },
  { "name": "[60010H] 铁道供电技术(中外合作办学)", "is_wenli": "理科", "jihua": 72, "baokao": 54, "luqu": 72 },
  { "name": "[60010I] 铁道工程技术(中外合作办学)", "is_wenli": "理科", "jihua": 47, "baokao": 34, "luqu": 47 },
  { "name": "[60010J] 铁道机械化维修技术(中外合作办学)", "is_wenli": "理科", "jihua": 52, "baokao": 29, "luqu": 52 },
  { "name": "[60010K] 铁道信号自动控制(中外合作办学)", "is_wenli": "理科", "jihua": 52, "baokao": 55, "luqu": 52 },
  { "name": "[60010L] 铁道通信与信息化技术(中外合作办学)", "is_wenli": "理科", "jihua": 27, "baokao": 19, "luqu": 27 },
  { "name": "[60010M] 铁道交通运营管理(中外合作办学)", "is_wenli": "理科", "jihua": 52, "baokao": 42, "luqu": 52 },
  { "name": "[600110] 铁路桥梁与隧道工程技术", "is_wenli": "理科", "jihua": 45, "baokao": 17, "luqu": 42 },
  { "name": "[600111] 高速铁道工程技术", "is_wenli": "理科", "jihua": 152, "baokao": 129, "luqu": 133 },
  { "name": "[600112] 高速铁路客运乘务", "is_wenli": "理科", "jihua": 643, "baokao": 417, "luqu": 424 },
  { "name": "[600113] 动车组检修技术", "is_wenli": "理科", "jihua": 220, "baokao": 242, "luqu": 202 },
  { "name": "[600114] 高铁综合维修技术", "is_wenli": "理科", "jihua": 198, "baokao": 112, "luqu": 170 },
  { "name": "[60011F] 高速铁道工程技术(中外合作办学)", "is_wenli": "理科", "jihua": 25, "baokao": 14, "luqu": 25 },
  { "name": "[6002 ] 道路运输类", "is_wenli": "理科", "jihua": 2, "baokao": 1, "luqu": 2 },
  { "name": "[600201] 智能交通技术运用", "is_wenli": "理科", "jihua": 68, "baokao": 22, "luqu": 63 },
  { "name": "[600202] 道路桥梁工程技术", "is_wenli": "理科", "jihua": 891, "baokao": 498, "luqu": 653 },
  { "name": "[600203] 道路运输与路政管理", "is_wenli": "理科", "jihua": 4, "baokao": 1, "luqu": 2 },
  { "name": "[600204] 道路养护与管理", "is_wenli": "理科", "jihua": 32, "baokao": 8, "luqu": 28 },
  { "name": "[600206] 工程机械运用技术", "is_wenli": "理科", "jihua": 75, "baokao": 38, "luqu": 68 },
  { "name": "[600207] 交通运营管理", "is_wenli": "理科", "jihua": 28, "baokao": 0, "luqu": 3 },
  { "name": "[600209] 汽车运用与维修技术", "is_wenli": "理科", "jihua": 599, "baokao": 93, "luqu": 231 },
  { "name": "[60020G] 道路桥梁工程技术(中外合作办学)", "is_wenli": "理科", "jihua": 30, "baokao": 20, "luqu": 28 },
  { "name": "[600210] 汽车车身维修技术", "is_wenli": "理科", "jihua": 79, "baokao": 5, "luqu": 30 },
  { "name": "[600212] 新能源汽车运用与维修", "is_wenli": "理科", "jihua": 106, "baokao": 47, "luqu": 62 },
  { "name": "[6003 ] 水上运输类", "is_wenli": "理科", "jihua": 2, "baokao": 2, "luqu": 2 },
  { "name": "[600301] 航海技术", "is_wenli": "理科", "jihua": 113, "baokao": 169, "luqu": 106 },
  { "name": "[600302] 国际邮轮乘务管理", "is_wenli": "理科", "jihua": 146, "baokao": 61, "luqu": 57 },
  { "name": "[600303] 船舶电子电气技术", "is_wenli": "理科", "jihua": 10, "baokao": 2, "luqu": 8 },
  { "name": "[600305] 港口机械与自动控制", "is_wenli": "理科", "jihua": 8, "baokao": 0, "luqu": 5 },
  { "name": "[600308] 港口与航运管理", "is_wenli": "理科", "jihua": 21, "baokao": 2, "luqu": 10 },
  { "name": "[600309] 港口物流管理", "is_wenli": "理科", "jihua": 4, "baokao": 2, "luqu": 3 },
  { "name": "[600310] 轮机工程技术", "is_wenli": "理科", "jihua": 94, "baokao": 83, "luqu": 86 },
  { "name": "[600312] 水路运输与海事管理", "is_wenli": "理科", "jihua": 16, "baokao": 1, "luqu": 4 },
  { "name": "[600313] 集装箱运输管理", "is_wenli": "理科", "jihua": 7, "baokao": 2, "luqu": 5 },
  { "name": "[600401] 民航运输", "is_wenli": "理科", "jihua": 67, "baokao": 21, "luqu": 28 },
  { "name": "[600402] 民航通信技术", "is_wenli": "理科", "jihua": 10, "baokao": 12, "luqu": 9 },
  { "name": "[600403] 定翼机驾驶技术", "is_wenli": "理科", "jihua": 27, "baokao": 3, "luqu": 14 },
  { "name": "[600404] 直升机驾驶技术", "is_wenli": "理科", "jihua": 41, "baokao": 3, "luqu": 19 },
  { "name": "[600405] 空中乘务", "is_wenli": "理科", "jihua": 864, "baokao": 264, "luqu": 400 },
  { "name": "[600406] 民航安全技术管理", "is_wenli": "理科", "jihua": 121, "baokao": 109, "luqu": 43 },
  { "name": "[600407] 民航空中安全保卫", "is_wenli": "理科", "jihua": 47, "baokao": 9, "luqu": 17 },
  { "name": "[600408] 机场运行", "is_wenli": "理科", "jihua": 58, "baokao": 9, "luqu": 23 },
  { "name": "[600409] 飞机机电设备维修", "is_wenli": "理科", "jihua": 347, "baokao": 196, "luqu": 185 },
  { "name": "[60040N] 飞机机电设备维修(中外合作办学)", "is_wenli": "理科", "jihua": 24, "baokao": 36, "luqu": 24 },
  { "name": "[600410] 飞机电子设备维修", "is_wenli": "理科", "jihua": 91, "baokao": 42, "luqu": 37 },
  { "name": "[600411] 飞机部件修理", "is_wenli": "理科", "jihua": 35, "baokao": 8, "luqu": 7 },
  { "name": "[600412] 航空地面设备维修", "is_wenli": "理科", "jihua": 11, "baokao": 0, "luqu": 11 },
  { "name": "[600413] 机场场务技术与管理", "is_wenli": "理科", "jihua": 1, "baokao": 0, "luqu": 1 },
  { "name": "[600414] 航空油料", "is_wenli": "理科", "jihua": 5, "baokao": 1, "luqu": 3 },
  { "name": "[600415] 航空物流", "is_wenli": "理科", "jihua": 131, "baokao": 32, "luqu": 47 },
  { "name": "[600416] 通用航空器维修", "is_wenli": "理科", "jihua": 66, "baokao": 20, "luqu": 16 },
  { "name": "[600417] 通用航空航务技术", "is_wenli": "理科", "jihua": 39, "baokao": 3, "luqu": 11 },
  { "name": "[600418] 飞机结构修理", "is_wenli": "理科", "jihua": 39, "baokao": 2, "luqu": 5 },
  { "name": "[60041E] 飞机电子设备维修(中外合作办学)", "is_wenli": "理科", "jihua": 4, "baokao": 8, "luqu": 4 },
  { "name": "[60041J] 航空物流(中外合作办学)", "is_wenli": "理科", "jihua": 20, "baokao": 4, "luqu": 20 },
  { "name": "[60041M] 飞机结构修理(中外合作办学)", "is_wenli": "理科", "jihua": 6, "baokao": 0, "luqu": 6 },
  { "name": "[6006 ] 城市轨道交通类", "is_wenli": "理科", "jihua": 10, "baokao": 0, "luqu": 1 },
  { "name": "[600601] 城市轨道交通车辆技术", "is_wenli": "理科", "jihua": 704, "baokao": 477, "luqu": 493 },
  { "name": "[600602] 城市轨道交通机电技术", "is_wenli": "理科", "jihua": 390, "baokao": 231, "luqu": 318 },
  { "name": "[600603] 城市轨道交通通信信号技术", "is_wenli": "理科", "jihua": 187, "baokao": 98, "luqu": 167 },
  { "name": "[600604] 城市轨道交通供配电技术", "is_wenli": "理科", "jihua": 128, "baokao": 40, "luqu": 125 },
  { "name": "[600605] 城市轨道交通工程技术", "is_wenli": "理科", "jihua": 417, "baokao": 225, "luqu": 301 },
  { "name": "[600606] 城市轨道交通运营管理", "is_wenli": "理科", "jihua": 1202, "baokao": 566, "luqu": 608 },
  { "name": "[600701] 邮政通信管理", "is_wenli": "理科", "jihua": 12, "baokao": 25, "luqu": 12 },
  { "name": "[6101 ] 电子信息类", "is_wenli": "理科", "jihua": 14, "baokao": 76, "luqu": 20 },
  { "name": "[610101] 电子信息工程技术", "is_wenli": "理科", "jihua": 1323, "baokao": 1678, "luqu": 1379 },
  { "name": "[610102] 应用电子技术", "is_wenli": "理科", "jihua": 891, "baokao": 967, "luqu": 707 },
  { "name": "[610103] 微电子技术", "is_wenli": "理科", "jihua": 49, "baokao": 32, "luqu": 39 },
  { "name": "[610104] 智能产品开发", "is_wenli": "理科", "jihua": 46, "baokao": 22, "luqu": 32 },
  { "name": "[610105] 智能终端技术与应用", "is_wenli": "理科", "jihua": 60, "baokao": 26, "luqu": 53 },
  { "name": "[610106] 智能监控技术应用", "is_wenli": "理科", "jihua": 3, "baokao": 1, "luqu": 2 },
  { "name": "[610107] 汽车智能技术", "is_wenli": "理科", "jihua": 291, "baokao": 63, "luqu": 173 },
  { "name": "[610108] 电子产品质量检测", "is_wenli": "理科", "jihua": 10, "baokao": 3, "luqu": 10 },
  { "name": "[61010G] 应用电子技术(中外合作办学)", "is_wenli": "理科", "jihua": 2, "baokao": 1, "luqu": 3 },
  { "name": "[610111] 电子制造技术与设备", "is_wenli": "理科", "jihua": 5, "baokao": 0, "luqu": 2 },
  { "name": "[610114] 声像工程技术", "is_wenli": "理科", "jihua": 1, "baokao": 0, "luqu": 1 },
  { "name": "[610115] 移动互联应用技术", "is_wenli": "理科", "jihua": 329, "baokao": 255, "luqu": 361 },
  { "name": "[610116] 光电技术应用", "is_wenli": "理科", "jihua": 6, "baokao": 2, "luqu": 5 },
  { "name": "[610119] 物联网应用技术", "is_wenli": "理科", "jihua": 1799, "baokao": 2221, "luqu": 1811 },
  { "name": "[610120] 集成电路技术应用", "is_wenli": "理科", "jihua": 8, "baokao": 3, "luqu": 2 },
  { "name": "[6102 ] 计算机类", "is_wenli": "理科", "jihua": 518, "baokao": 797, "luqu": 501 },
  { "name": "[610201] 计算机应用技术", "is_wenli": "理科", "jihua": 5808, "baokao": 12060, "luqu": 8723 },
  { "name": "[610202] 计算机网络技术", "is_wenli": "理科", "jihua": 4007, "baokao": 6043, "luqu": 4725 },
  { "name": "[610203] 计算机信息管理", "is_wenli": "理科", "jihua": 657, "baokao": 629, "luqu": 532 },
  { "name": "[610204] 计算机系统与维护", "is_wenli": "理科", "jihua": 92, "baokao": 11, "luqu": 60 },
  { "name": "[610205] 软件技术", "is_wenli": "理科", "jihua": 3692, "baokao": 4670, "luqu": 4185 },
  { "name": "[610206] 软件与信息服务", "is_wenli": "理科", "jihua": 93, "baokao": 193, "luqu": 94 },
  { "name": "[610207] 动漫制作技术", "is_wenli": "理科", "jihua": 937, "baokao": 708, "luqu": 937 },
  { "name": "[610208] 嵌入式技术与应用", "is_wenli": "理科", "jihua": 9, "baokao": 2, "luqu": 6 },
  { "name": "[610209] 数字展示技术", "is_wenli": "理科", "jihua": 59, "baokao": 16, "luqu": 41 },
  { "name": "[61020F] 计算机应用技术(中外合作办学)", "is_wenli": "理科", "jihua": 92, "baokao": 61, "luqu": 78 },
  { "name": "[61020G] 计算机网络技术(中外合作办学)", "is_wenli": "理科", "jihua": 109, "baokao": 182, "luqu": 161 },
  { "name": "[61020H] 计算机信息管理(中外合作办学)", "is_wenli": "理科", "jihua": 118, "baokao": 77, "luqu": 80 },
  { "name": "[61020J] 软件技术(中外合作办学)", "is_wenli": "理科", "jihua": 17, "baokao": 1, "luqu": 1 },
  { "name": "[61020L] 动漫制作技术(中外合作办学)", "is_wenli": "理科", "jihua": 35, "baokao": 1, "luqu": 1 },
  { "name": "[610210] 数字媒体应用技术", "is_wenli": "理科", "jihua": 1621, "baokao": 1770, "luqu": 1565 },
  { "name": "[610211] 信息安全与管理", "is_wenli": "理科", "jihua": 564, "baokao": 349, "luqu": 476 },
  { "name": "[610212] 移动应用开发", "is_wenli": "理科", "jihua": 595, "baokao": 443, "luqu": 531 },
  { "name": "[610213] 云计算技术与应用", "is_wenli": "理科", "jihua": 509, "baokao": 236, "luqu": 371 },
  { "name": "[610214] 电子商务技术", "is_wenli": "理科", "jihua": 493, "baokao": 435, "luqu": 409 },
  { "name": "[610215] 大数据技术与应用", "is_wenli": "理科", "jihua": 2659, "baokao": 3922, "luqu": 2930 },
  { "name": "[610216] 虚拟现实应用技术", "is_wenli": "理科", "jihua": 514, "baokao": 219, "luqu": 606 },
  { "name": "[610217] 人工智能技术服务", "is_wenli": "理科", "jihua": 713, "baokao": 300, "luqu": 526 },
  { "name": "[61021E] 数字媒体应用技术(中外合作办学)", "is_wenli": "理科", "jihua": 12, "baokao": 20, "luqu": 12 },
  { "name": "[61021H] 云计算技术与应用(中外合作办学)", "is_wenli": "理科", "jihua": 13, "baokao": 1, "luqu": 2 },
  { "name": "[61021J] 大数据技术与应用(中外合作办学)", "is_wenli": "理科", "jihua": 12, "baokao": 3, "luqu": 5 },
  { "name": "[6103 ] 通信类", "is_wenli": "理科", "jihua": 5, "baokao": 1, "luqu": 5 },
  { "name": "[610301] 通信技术", "is_wenli": "理科", "jihua": 573, "baokao": 311, "luqu": 343 },
  { "name": "[610302] 移动通信技术", "is_wenli": "理科", "jihua": 351, "baokao": 141, "luqu": 255 },
  { "name": "[610304] 通信工程设计与监理", "is_wenli": "理科", "jihua": 25, "baokao": 0, "luqu": 2 },
  { "name": "[610305] 电信服务与管理", "is_wenli": "理科", "jihua": 18, "baokao": 1, "luqu": 3 },
  { "name": "[610307] 物联网工程技术", "is_wenli": "理科", "jihua": 95, "baokao": 57, "luqu": 87 },
  { "name": "[620101] 临床医学", "is_wenli": "理科", "jihua": 444, "baokao": 1468, "luqu": 567 },
  { "name": "[620102] 口腔医学", "is_wenli": "理科", "jihua": 246, "baokao": 1338, "luqu": 370 },
  { "name": "[620103] 中医学", "is_wenli": "理科", "jihua": 67, "baokao": 153, "luqu": 86 },
  { "name": "[620104] 中医骨伤", "is_wenli": "理科", "jihua": 7, "baokao": 3, "luqu": 3 },
  { "name": "[620105] 针灸推拿", "is_wenli": "理科", "jihua": 57, "baokao": 63, "luqu": 50 },
  { "name": "[62010F] 临床医学(中外合作办学)", "is_wenli": "理科", "jihua": 140, "baokao": 595, "luqu": 139 },
  { "name": "[62010G] 口腔医学(中外合作办学)", "is_wenli": "理科", "jihua": 65, "baokao": 668, "luqu": 65 },
  { "name": "[6202 ] 护理类", "is_wenli": "理科", "jihua": 129, "baokao": 138, "luqu": 129 },
  { "name": "[620201] 护理", "is_wenli": "理科", "jihua": 5983, "baokao": 7232, "luqu": 5994 },
  { "name": "[620202] 助产", "is_wenli": "理科", "jihua": 848, "baokao": 375, "luqu": 509 },
  { "name": "[62020F] 护理(中外合作办学)", "is_wenli": "理科", "jihua": 332, "baokao": 195, "luqu": 323 },
  { "name": "[620301] 药学", "is_wenli": "理科", "jihua": 1742, "baokao": 1867, "luqu": 1706 },
  { "name": "[620302] 中药学", "is_wenli": "理科", "jihua": 749, "baokao": 822, "luqu": 808 },
  { "name": "[62030F] 药学(中外合作办学)", "is_wenli": "理科", "jihua": 30, "baokao": 5, "luqu": 30 },
  { "name": "[6204 ] 医学技术类", "is_wenli": "理科", "jihua": 2, "baokao": 0, "luqu": 1 },
  { "name": "[620401] 医学检验技术", "is_wenli": "理科", "jihua": 1467, "baokao": 2038, "luqu": 1615 },
  { "name": "[620402] 医学生物技术", "is_wenli": "理科", "jihua": 9, "baokao": 2, "luqu": 4 },
  { "name": "[620403] 医学影像技术", "is_wenli": "理科", "jihua": 1355, "baokao": 2429, "luqu": 1729 },
  { "name": "[620404] 医学美容技术", "is_wenli": "理科", "jihua": 97, "baokao": 55, "luqu": 72 },
  { "name": "[620405] 口腔医学技术", "is_wenli": "理科", "jihua": 396, "baokao": 1049, "luqu": 634 },
  { "name": "[620406] 卫生检验与检疫技术", "is_wenli": "理科", "jihua": 38, "baokao": 9, "luqu": 22 },
  { "name": "[620407] 眼视光技术", "is_wenli": "理科", "jihua": 281, "baokao": 372, "luqu": 295 },
  { "name": "[620408] 放射治疗技术", "is_wenli": "理科", "jihua": 2, "baokao": 0, "luqu": 2 },
  { "name": "[62040F] 医学检验技术(中外合作办学)", "is_wenli": "理科", "jihua": 40, "baokao": 8, "luqu": 40 },
  { "name": "[62040H] 医学影像技术(中外合作办学)", "is_wenli": "理科", "jihua": 44, "baokao": 22, "luqu": 44 },
  { "name": "[6205 ] 康复治疗类", "is_wenli": "理科", "jihua": 25, "baokao": 19, "luqu": 20 },
  { "name": "[620501] 康复治疗技术", "is_wenli": "理科", "jihua": 1322, "baokao": 899, "luqu": 856 },
  { "name": "[620503] 中医康复技术", "is_wenli": "理科", "jihua": 111, "baokao": 44, "luqu": 33 },
  { "name": "[62050F] 康复治疗技术(中外合作办学)", "is_wenli": "理科", "jihua": 48, "baokao": 10, "luqu": 54 },
  { "name": "[620601] 预防医学", "is_wenli": "理科", "jihua": 163, "baokao": 101, "luqu": 82 },
  { "name": "[620602] 公共卫生管理", "is_wenli": "理科", "jihua": 5, "baokao": 0, "luqu": 1 },
  { "name": "[620603] 卫生监督", "is_wenli": "理科", "jihua": 15, "baokao": 6, "luqu": 2 },
  { "name": "[620604] 卫生信息管理", "is_wenli": "理科", "jihua": 54, "baokao": 9, "luqu": 44 },
  { "name": "[620801] 健康管理", "is_wenli": "理科", "jihua": 515, "baokao": 95, "luqu": 398 },
  { "name": "[620802] 医学营养", "is_wenli": "理科", "jihua": 101, "baokao": 50, "luqu": 61 },
  { "name": "[620803] 中医养生保健", "is_wenli": "理科", "jihua": 192, "baokao": 47, "luqu": 119 },
  { "name": "[620804] 心理咨询", "is_wenli": "理科", "jihua": 72, "baokao": 166, "luqu": 83 },
  { "name": "[620805] 医疗设备应用技术", "is_wenli": "理科", "jihua": 49, "baokao": 22, "luqu": 25 },
  { "name": "[620806] 精密医疗器械技术", "is_wenli": "理科", "jihua": 10, "baokao": 3, "luqu": 10 },
  { "name": "[620807] 医疗器械维护与管理", "is_wenli": "理科", "jihua": 64, "baokao": 24, "luqu": 29 },
  { "name": "[620808] 康复工程技术", "is_wenli": "理科", "jihua": 23, "baokao": 4, "luqu": 5 },
  { "name": "[620809] 康复辅助器具技术", "is_wenli": "理科", "jihua": 17, "baokao": 4, "luqu": 17 },
  { "name": "[62080L] 医疗器械维护与管理(中外合作办学)", "is_wenli": "理科", "jihua": 10, "baokao": 20, "luqu": 10 },
  { "name": "[620810] 假肢与矫形器技术", "is_wenli": "理科", "jihua": 2, "baokao": 2, "luqu": 2 },
  { "name": "[620811] 老年保健与管理", "is_wenli": "理科", "jihua": 111, "baokao": 9, "luqu": 60 },
  { "name": "[620812] 医疗器械经营与管理", "is_wenli": "理科", "jihua": 2, "baokao": 0, "luqu": 2 },
  { "name": "[630101] 财政", "is_wenli": "理科", "jihua": 1, "baokao": 0, "luqu": 2 },
  { "name": "[630102] 税务", "is_wenli": "理科", "jihua": 78, "baokao": 37, "luqu": 60 },
  { "name": "[630103] 资产评估与管理", "is_wenli": "理科", "jihua": 23, "baokao": 7, "luqu": 20 },
  { "name": "[630104] 政府采购管理", "is_wenli": "理科", "jihua": 4, "baokao": 0, "luqu": 2 },
  { "name": "[6302 ] 金融类", "is_wenli": "理科", "jihua": 15, "baokao": 57, "luqu": 15 },
  { "name": "[630201] 金融管理", "is_wenli": "理科", "jihua": 710, "baokao": 562, "luqu": 649 },
  { "name": "[630202] 国际金融", "is_wenli": "理科", "jihua": 42, "baokao": 23, "luqu": 25 },
  { "name": "[630203] 证券与期货", "is_wenli": "理科", "jihua": 68, "baokao": 27, "luqu": 67 },
  { "name": "[630205] 保险", "is_wenli": "理科", "jihua": 21, "baokao": 6, "luqu": 9 },
  { "name": "[630206] 投资与理财", "is_wenli": "理科", "jihua": 331, "baokao": 147, "luqu": 194 },
  { "name": "[630207] 信用管理", "is_wenli": "理科", "jihua": 3, "baokao": 1, "luqu": 3 },
  { "name": "[630209] 互联网金融", "is_wenli": "理科", "jihua": 320, "baokao": 168, "luqu": 192 },
  { "name": "[63020F] 金融管理(中外合作办学)", "is_wenli": "理科", "jihua": 45, "baokao": 69, "luqu": 45 },
  { "name": "[63020G] 国际金融(中外合作办学)", "is_wenli": "理科", "jihua": 72, "baokao": 66, "luqu": 46 },
  { "name": "[6303 ] 财务会计类", "is_wenli": "理科", "jihua": 10, "baokao": 34, "luqu": 9 },
  { "name": "[630301] 财务管理", "is_wenli": "理科", "jihua": 1221, "baokao": 832, "luqu": 980 },
  { "name": "[630302] 会计", "is_wenli": "理科", "jihua": 5272, "baokao": 7623, "luqu": 5901 },
  { "name": "[630303] 审计", "is_wenli": "理科", "jihua": 248, "baokao": 198, "luqu": 173 },
  { "name": "[630304] 会计信息管理", "is_wenli": "理科", "jihua": 144, "baokao": 89, "luqu": 114 },
  { "name": "[63030F] 财务管理(中外合作办学)", "is_wenli": "理科", "jihua": 82, "baokao": 97, "luqu": 82 },
  { "name": "[63030G] 会计(中外合作办学)", "is_wenli": "理科", "jihua": 512, "baokao": 479, "luqu": 553 },
  { "name": "[630401] 信息统计与分析", "is_wenli": "理科", "jihua": 12, "baokao": 9, "luqu": 12 },
  { "name": "[630402] 统计与会计核算", "is_wenli": "理科", "jihua": 71, "baokao": 31, "luqu": 66 },
  { "name": "[630501] 国际贸易实务", "is_wenli": "理科", "jihua": 102, "baokao": 43, "luqu": 84 },
  { "name": "[630502] 国际经济与贸易", "is_wenli": "理科", "jihua": 260, "baokao": 160, "luqu": 173 },
  { "name": "[630503] 国际商务", "is_wenli": "理科", "jihua": 192, "baokao": 107, "luqu": 105 },
  { "name": "[630505] 经济信息管理", "is_wenli": "理科", "jihua": 113, "baokao": 56, "luqu": 87 },
  { "name": "[630506] 报关与国际货运", "is_wenli": "理科", "jihua": 149, "baokao": 31, "luqu": 60 },
  { "name": "[63050G] 国际经济与贸易(中外合作办学)", "is_wenli": "理科", "jihua": 5, "baokao": 3, "luqu": 5 },
  { "name": "[6306 ] 工商管理类", "is_wenli": "理科", "jihua": 6, "baokao": 9, "luqu": 2 },
  { "name": "[630601] 工商企业管理", "is_wenli": "理科", "jihua": 773, "baokao": 560, "luqu": 528 },
  { "name": "[630602] 商务管理", "is_wenli": "理科", "jihua": 94, "baokao": 11, "luqu": 27 },
  { "name": "[630603] 商检技术", "is_wenli": "理科", "jihua": 43, "baokao": 7, "luqu": 15 },
  { "name": "[630604] 连锁经营管理", "is_wenli": "理科", "jihua": 173, "baokao": 44, "luqu": 125 },
  { "name": "[630607] 中小企业创业与经营", "is_wenli": "理科", "jihua": 24, "baokao": 4, "luqu": 9 },
  { "name": "[63060F] 工商企业管理(中外合作办学)", "is_wenli": "理科", "jihua": 94, "baokao": 46, "luqu": 38 },
  { "name": "[6307 ] 市场营销类", "is_wenli": "理科", "jihua": 15, "baokao": 1, "luqu": 3 },
  { "name": "[630701] 市场营销", "is_wenli": "理科", "jihua": 2031, "baokao": 953, "luqu": 1241 },
  { "name": "[630702] 汽车营销与服务", "is_wenli": "理科", "jihua": 608, "baokao": 28, "luqu": 114 },
  { "name": "[630703] 广告策划与营销", "is_wenli": "理科", "jihua": 10, "baokao": 4, "luqu": 5 },
  { "name": "[630704] 茶艺与茶叶营销", "is_wenli": "理科", "jihua": 9, "baokao": 1, "luqu": 1 },
  { "name": "[63070F] 市场营销(中外合作办学)", "is_wenli": "理科", "jihua": 107, "baokao": 30, "luqu": 110 },
  { "name": "[6308 ] 电子商务类", "is_wenli": "理科", "jihua": 45, "baokao": 17, "luqu": 4 },
  { "name": "[630801] 电子商务", "is_wenli": "理科", "jihua": 3882, "baokao": 3050, "luqu": 3376 },
  { "name": "[630802] 移动商务", "is_wenli": "理科", "jihua": 49, "baokao": 48, "luqu": 59 },
  { "name": "[630803] 网络营销", "is_wenli": "理科", "jihua": 453, "baokao": 675, "luqu": 428 },
  { "name": "[630804] 商务数据分析与应用", "is_wenli": "理科", "jihua": 174, "baokao": 48, "luqu": 98 },
  { "name": "[630805] 跨境电子商务", "is_wenli": "理科", "jihua": 488, "baokao": 126, "luqu": 278 },
  { "name": "[63080F] 电子商务(中外合作办学)", "is_wenli": "理科", "jihua": 270, "baokao": 74, "luqu": 255 },
  { "name": "[6309 ] 物流类", "is_wenli": "理科", "jihua": 6, "baokao": 3, "luqu": 6 },
  { "name": "[630902] 物流信息技术", "is_wenli": "理科", "jihua": 11, "baokao": 4, "luqu": 10 },
  { "name": "[630903] 物流管理", "is_wenli": "理科", "jihua": 1966, "baokao": 787, "luqu": 1268 },
  { "name": "[630904] 物流金融管理", "is_wenli": "理科", "jihua": 19, "baokao": 3, "luqu": 2 },
  { "name": "[630906] 冷链物流技术与管理", "is_wenli": "理科", "jihua": 25, "baokao": 3, "luqu": 8 },
  { "name": "[630907] 采购与供应管理", "is_wenli": "理科", "jihua": 8, "baokao": 1, "luqu": 6 },
  { "name": "[63090H] 物流管理(中外合作办学)", "is_wenli": "理科", "jihua": 190, "baokao": 25, "luqu": 166 },
  { "name": "[6401 ] 旅游类", "is_wenli": "理科", "jihua": 12, "baokao": 2, "luqu": 8 },
  { "name": "[640101] 旅游管理", "is_wenli": "理科", "jihua": 1561, "baokao": 403, "luqu": 753 },
  { "name": "[640102] 导游", "is_wenli": "理科", "jihua": 41, "baokao": 19, "luqu": 30 },
  { "name": "[640103] 旅行社经营管理", "is_wenli": "理科", "jihua": 20, "baokao": 6, "luqu": 12 },
  { "name": "[640104] 景区开发与管理", "is_wenli": "理科", "jihua": 6, "baokao": 4, "luqu": 5 },
  { "name": "[640105] 酒店管理", "is_wenli": "理科", "jihua": 1216, "baokao": 328, "luqu": 618 },
  { "name": "[640106] 休闲服务与管理", "is_wenli": "理科", "jihua": 26, "baokao": 4, "luqu": 8 },
  { "name": "[640107] 研学旅行管理与服务", "is_wenli": "理科", "jihua": 38, "baokao": 4, "luqu": 7 },
  { "name": "[640108] 葡萄酒营销与服务", "is_wenli": "理科", "jihua": 1, "baokao": 0, "luqu": 1 },
  { "name": "[64010F] 旅游管理(中外合作办学)", "is_wenli": "理科", "jihua": 9, "baokao": 5, "luqu": 7 },
  { "name": "[64010J] 酒店管理(中外合作办学)", "is_wenli": "理科", "jihua": 6, "baokao": 2, "luqu": 1 },
  { "name": "[6402 ] 餐饮类", "is_wenli": "理科", "jihua": 2, "baokao": 0, "luqu": 2 },
  { "name": "[640201] 餐饮管理", "is_wenli": "理科", "jihua": 15, "baokao": 1, "luqu": 6 },
  { "name": "[640202] 烹调工艺与营养", "is_wenli": "理科", "jihua": 360, "baokao": 111, "luqu": 213 },
  { "name": "[640203] 营养配餐", "is_wenli": "理科", "jihua": 59, "baokao": 16, "luqu": 23 },
  { "name": "[640204] 中西面点工艺", "is_wenli": "理科", "jihua": 129, "baokao": 53, "luqu": 85 },
  { "name": "[640205] 西餐工艺", "is_wenli": "理科", "jihua": 69, "baokao": 23, "luqu": 24 },
  { "name": "[640301] 会展策划与管理", "is_wenli": "理科", "jihua": 85, "baokao": 35, "luqu": 54 },
  { "name": "[650101] 艺术设计", "is_wenli": "理科", "jihua": 116, "baokao": 13, "luqu": 30 },
  { "name": "[650102] 视觉传播设计与制作", "is_wenli": "理科", "jihua": 116, "baokao": 16, "luqu": 51 },
  { "name": "[650103] 广告设计与制作", "is_wenli": "理科", "jihua": 103, "baokao": 66, "luqu": 60 },
  { "name": "[650104] 数字媒体艺术设计", "is_wenli": "理科", "jihua": 117, "baokao": 22, "luqu": 24 },
  { "name": "[650105] 产品艺术设计", "is_wenli": "理科", "jihua": 6, "baokao": 1, "luqu": 3 },
  { "name": "[650108] 服装与服饰设计", "is_wenli": "理科", "jihua": 16, "baokao": 7, "luqu": 8 },
  { "name": "[650109] 室内艺术设计", "is_wenli": "理科", "jihua": 25, "baokao": 5, "luqu": 13 },
  { "name": "[65010I] 数字媒体艺术设计(中外合作办学)", "is_wenli": "理科", "jihua": 4, "baokao": 0, "luqu": 4 },
  { "name": "[650111] 环境艺术设计", "is_wenli": "理科", "jihua": 84, "baokao": 3, "luqu": 47 },
  { "name": "[650115] 陶瓷设计与工艺", "is_wenli": "理科", "jihua": 83, "baokao": 1, "luqu": 5 },
  { "name": "[650117] 玉器设计与工艺", "is_wenli": "理科", "jihua": 30, "baokao": 3, "luqu": 13 },
  { "name": "[650118] 首饰设计与工艺", "is_wenli": "理科", "jihua": 5, "baokao": 1, "luqu": 2 },
  { "name": "[650119] 工艺美术品设计", "is_wenli": "理科", "jihua": 11, "baokao": 0, "luqu": 5 },
  { "name": "[650120] 动漫设计", "is_wenli": "理科", "jihua": 77, "baokao": 27, "luqu": 35 },
  { "name": "[650121] 游戏设计", "is_wenli": "理科", "jihua": 22, "baokao": 1, "luqu": 5 },
  { "name": "[650122] 人物形象设计", "is_wenli": "理科", "jihua": 26, "baokao": 20, "luqu": 25 },
  { "name": "[650123] 美容美体艺术", "is_wenli": "理科", "jihua": 13, "baokao": 12, "luqu": 3 },
  { "name": "[650201] 表演艺术", "is_wenli": "理科", "jihua": 9, "baokao": 0, "luqu": 1 },
  { "name": "[650202] 戏剧影视表演", "is_wenli": "理科", "jihua": 5, "baokao": 1, "luqu": 2 },
  { "name": "[650219] 音乐表演", "is_wenli": "理科", "jihua": 49, "baokao": 3, "luqu": 14 },
  { "name": "[650401] 文化创意与策划", "is_wenli": "理科", "jihua": 52, "baokao": 17, "luqu": 22 },
  { "name": "[650402] 文化市场经营管理", "is_wenli": "理科", "jihua": 35, "baokao": 5, "luqu": 31 },
  { "name": "[650404] 文物修复与保护", "is_wenli": "理科", "jihua": 43, "baokao": 25, "luqu": 36 },
  { "name": "[660101] 图文信息处理", "is_wenli": "理科", "jihua": 4, "baokao": 6, "luqu": 4 },
  { "name": "[660102] 网络新闻与传播", "is_wenli": "理科", "jihua": 9, "baokao": 6, "luqu": 4 },
  { "name": "[660104] 出版商务", "is_wenli": "理科", "jihua": 1, "baokao": 0, "luqu": 1 },
  { "name": "[660107] 数字出版", "is_wenli": "理科", "jihua": 2, "baokao": 7, "luqu": 2 },
  { "name": "[66010I] 出版商务(中外合作办学)", "is_wenli": "理科", "jihua": 4, "baokao": 1, "luqu": 4 },
  { "name": "[660201] 新闻采编与制作", "is_wenli": "理科", "jihua": 193, "baokao": 187, "luqu": 199 },
  { "name": "[660203] 广播影视节目制作", "is_wenli": "理科", "jihua": 21, "baokao": 11, "luqu": 10 },
  { "name": "[660204] 广播电视技术", "is_wenli": "理科", "jihua": 5, "baokao": 5, "luqu": 4 },
  { "name": "[660206] 影视编导", "is_wenli": "理科", "jihua": 4, "baokao": 2, "luqu": 2 },
  { "name": "[660208] 影视多媒体技术", "is_wenli": "理科", "jihua": 85, "baokao": 39, "luqu": 57 },
  { "name": "[660209] 影视动画", "is_wenli": "理科", "jihua": 4, "baokao": 5, "luqu": 2 },
  { "name": "[660213] 摄影摄像技术", "is_wenli": "理科", "jihua": 34, "baokao": 26, "luqu": 27 },
  { "name": "[660214] 传播与策划", "is_wenli": "理科", "jihua": 34, "baokao": 15, "luqu": 24 },
  { "name": "[660215] 媒体营销", "is_wenli": "理科", "jihua": 6, "baokao": 1, "luqu": 1 },
  { "name": "[6701 ] 教育类", "is_wenli": "理科", "jihua": 10, "baokao": 26, "luqu": 10 },
  { "name": "[670101] 早期教育", "is_wenli": "理科", "jihua": 385, "baokao": 204, "luqu": 270 },
  { "name": "[670102] 学前教育", "is_wenli": "理科", "jihua": 3019, "baokao": 3094, "luqu": 3146 },
  { "name": "[670103] 小学教育", "is_wenli": "理科", "jihua": 1198, "baokao": 5081, "luqu": 3488 },
  { "name": "[670104] 语文教育", "is_wenli": "理科", "jihua": 648, "baokao": 1072, "luqu": 1129 },
  { "name": "[670105] 数学教育", "is_wenli": "理科", "jihua": 775, "baokao": 3321, "luqu": 853 },
  { "name": "[670106] 英语教育", "is_wenli": "理科", "jihua": 634, "baokao": 2430, "luqu": 950 },
  { "name": "[670108] 化学教育", "is_wenli": "理科", "jihua": 2, "baokao": 0, "luqu": 2 },
  { "name": "[670109] 生物教育", "is_wenli": "理科", "jihua": 7, "baokao": 44, "luqu": 7 },
  { "name": "[67010G] 学前教育(中外合作办学)", "is_wenli": "理科", "jihua": 166, "baokao": 151, "luqu": 182 },
  { "name": "[67010H] 小学教育(中外合作办学)", "is_wenli": "理科", "jihua": 30, "baokao": 148, "luqu": 30 },
  { "name": "[67010K] 英语教育(中外合作办学)", "is_wenli": "理科", "jihua": 30, "baokao": 205, "luqu": 30 },
  { "name": "[670110] 历史教育", "is_wenli": "理科", "jihua": 5, "baokao": 0, "luqu": 2 },
  { "name": "[670112] 音乐教育", "is_wenli": "理科", "jihua": 204, "baokao": 42, "luqu": 162 },
  { "name": "[670113] 美术教育", "is_wenli": "理科", "jihua": 123, "baokao": 30, "luqu": 96 },
  { "name": "[670116] 舞蹈教育", "is_wenli": "理科", "jihua": 5, "baokao": 2, "luqu": 5 },
  { "name": "[670117] 艺术教育", "is_wenli": "理科", "jihua": 10, "baokao": 2, "luqu": 2 },
  { "name": "[670118] 特殊教育", "is_wenli": "理科", "jihua": 28, "baokao": 3, "luqu": 21 },
  { "name": "[670119] 科学教育", "is_wenli": "理科", "jihua": 91, "baokao": 24, "luqu": 160 },
  { "name": "[67011G] 音乐教育(中外合作办学)", "is_wenli": "理科", "jihua": 20, "baokao": 6, "luqu": 18 },
  { "name": "[670120] 现代教育技术", "is_wenli": "理科", "jihua": 8, "baokao": 0, "luqu": 4 },
  { "name": "[6702 ] 语言类", "is_wenli": "理科", "jihua": 5, "baokao": 10, "luqu": 5 },
  { "name": "[670201] 汉语", "is_wenli": "理科", "jihua": 45, "baokao": 55, "luqu": 40 },
  { "name": "[670202] 商务英语", "is_wenli": "理科", "jihua": 1004, "baokao": 943, "luqu": 913 },
  { "name": "[670203] 应用英语", "is_wenli": "理科", "jihua": 470, "baokao": 405, "luqu": 336 },
  { "name": "[670204] 旅游英语", "is_wenli": "理科", "jihua": 96, "baokao": 45, "luqu": 64 },
  { "name": "[670205] 商务日语", "is_wenli": "理科", "jihua": 72, "baokao": 30, "luqu": 47 },
  { "name": "[670206] 应用日语", "is_wenli": "理科", "jihua": 72, "baokao": 44, "luqu": 44 },
  { "name": "[670207] 旅游日语", "is_wenli": "理科", "jihua": 7, "baokao": 10, "luqu": 6 },
  { "name": "[670208] 应用韩语", "is_wenli": "理科", "jihua": 52, "baokao": 13, "luqu": 15 },
  { "name": "[670209] 应用俄语", "is_wenli": "理科", "jihua": 21, "baokao": 7, "luqu": 11 },
  { "name": "[67020G] 商务英语(中外合作办学)", "is_wenli": "理科", "jihua": 30, "baokao": 16, "luqu": 30 },
  { "name": "[67020M] 应用韩语(中外合作办学)", "is_wenli": "理科", "jihua": 24, "baokao": 2, "luqu": 24 },
  { "name": "[670210] 应用法语", "is_wenli": "理科", "jihua": 23, "baokao": 9, "luqu": 13 },
  { "name": "[670211] 应用德语", "is_wenli": "理科", "jihua": 26, "baokao": 5, "luqu": 18 },
  { "name": "[670212] 应用西班牙语", "is_wenli": "理科", "jihua": 14, "baokao": 12, "luqu": 17 },
  { "name": "[670214] 应用泰语", "is_wenli": "理科", "jihua": 3, "baokao": 2, "luqu": 1 },
  { "name": "[670215] 应用阿拉伯语", "is_wenli": "理科", "jihua": 6, "baokao": 1, "luqu": 2 },
  { "name": "[670301] 文秘", "is_wenli": "理科", "jihua": 146, "baokao": 112, "luqu": 103 },
  { "name": "[670302] 文秘速录", "is_wenli": "理科", "jihua": 4, "baokao": 2, "luqu": 4 },
  { "name": "[670401] 运动训练", "is_wenli": "理科", "jihua": 97, "baokao": 16, "luqu": 24 },
  { "name": "[670402] 运动防护", "is_wenli": "理科", "jihua": 2, "baokao": 0, "luqu": 2 },
  { "name": "[670403] 社会体育", "is_wenli": "理科", "jihua": 34, "baokao": 3, "luqu": 8 },
  { "name": "[670404] 休闲体育", "is_wenli": "理科", "jihua": 1, "baokao": 1, "luqu": 1 },
  { "name": "[670405] 高尔夫球运动与管理", "is_wenli": "理科", "jihua": 40, "baokao": 1, "luqu": 1 },
  { "name": "[670406] 民族传统体育", "is_wenli": "理科", "jihua": 165, "baokao": 6, "luqu": 15 },
  { "name": "[670407] 体育艺术表演", "is_wenli": "理科", "jihua": 84, "baokao": 5, "luqu": 8 },
  { "name": "[670408] 体育运营与管理", "is_wenli": "理科", "jihua": 56, "baokao": 11, "luqu": 18 },
  { "name": "[670409] 体育保健与康复", "is_wenli": "理科", "jihua": 202, "baokao": 10, "luqu": 50 },
  { "name": "[670410] 健身指导与管理", "is_wenli": "理科", "jihua": 141, "baokao": 34, "luqu": 91 },
  { "name": "[670411] 电子竞技运动与管理", "is_wenli": "理科", "jihua": 250, "baokao": 63, "luqu": 127 },
  { "name": "[680102] 交通管理", "is_wenli": "理科", "jihua": 1, "baokao": 3, "luqu": 1 },
  { "name": "[680103] 信息网络安全监察", "is_wenli": "理科", "jihua": 14, "baokao": 8, "luqu": 14 },
  { "name": "[680109] 公共安全管理", "is_wenli": "理科", "jihua": 10, "baokao": 2, "luqu": 10 },
  { "name": "[680501] 司法助理", "is_wenli": "理科", "jihua": 39, "baokao": 23, "luqu": 39 },
  { "name": "[680502] 法律文秘", "is_wenli": "理科", "jihua": 120, "baokao": 97, "luqu": 115 },
  { "name": "[680503] 法律事务", "is_wenli": "理科", "jihua": 327, "baokao": 528, "luqu": 334 },
  { "name": "[680504] 检察事务", "is_wenli": "理科", "jihua": 80, "baokao": 87, "luqu": 81 },
  { "name": "[680601] 刑事执行", "is_wenli": "理科", "jihua": 9, "baokao": 9, "luqu": 10 },
  { "name": "[680603] 行政执行", "is_wenli": "理科", "jihua": 3, "baokao": 2, "luqu": 4 },
  { "name": "[680604] 司法警务", "is_wenli": "理科", "jihua": 116, "baokao": 306, "luqu": 110 },
  { "name": "[680605] 社区矫正", "is_wenli": "理科", "jihua": 19, "baokao": 9, "luqu": 18 },
  { "name": "[680701] 刑事侦查技术", "is_wenli": "理科", "jihua": 7, "baokao": 22, "luqu": 13 },
  { "name": "[680702] 安全防范技术", "is_wenli": "理科", "jihua": 22, "baokao": 6, "luqu": 9 },
  { "name": "[680703] 司法信息技术", "is_wenli": "理科", "jihua": 50, "baokao": 42, "luqu": 50 },
  { "name": "[680704] 司法鉴定技术", "is_wenli": "理科", "jihua": 58, "baokao": 68, "luqu": 57 },
  { "name": "[680705] 司法信息安全", "is_wenli": "理科", "jihua": 4, "baokao": 2, "luqu": 1 },
  { "name": "[690101] 社会工作", "is_wenli": "理科", "jihua": 27, "baokao": 1, "luqu": 31 },
  { "name": "[690104] 社区管理与服务", "is_wenli": "理科", "jihua": 67, "baokao": 4, "luqu": 4 },
  { "name": "[690106] 人民武装", "is_wenli": "理科", "jihua": 4, "baokao": 2, "luqu": 3 },
  { "name": "[690201] 民政管理", "is_wenli": "理科", "jihua": 1, "baokao": 3, "luqu": 1 },
  { "name": "[690202] 人力资源管理", "is_wenli": "理科", "jihua": 581, "baokao": 255, "luqu": 368 },
  { "name": "[690203] 劳动与社会保障", "is_wenli": "理科", "jihua": 3, "baokao": 0, "luqu": 2 },
  { "name": "[690205] 公共事务管理", "is_wenli": "理科", "jihua": 14, "baokao": 1, "luqu": 6 },
  { "name": "[690206] 行政管理", "is_wenli": "理科", "jihua": 56, "baokao": 15, "luqu": 23 },
  { "name": "[690207] 质量管理与认证", "is_wenli": "理科", "jihua": 15, "baokao": 0, "luqu": 1 },
  { "name": "[69020G] 人力资源管理(中外合作办学)", "is_wenli": "理科", "jihua": 10, "baokao": 5, "luqu": 10 },
  { "name": "[690301] 老年服务与管理", "is_wenli": "理科", "jihua": 155, "baokao": 26, "luqu": 69 },
  { "name": "[690302] 家政服务与管理", "is_wenli": "理科", "jihua": 55, "baokao": 1, "luqu": 1 },
  { "name": "[690303] 婚庆服务与管理", "is_wenli": "理科", "jihua": 18, "baokao": 8, "luqu": 8 },
  { "name": "[690304] 社区康复", "is_wenli": "理科", "jihua": 77, "baokao": 7, "luqu": 40 },
  { "name": "[690305] 现代殡葬技术与管理", "is_wenli": "理科", "jihua": 5, "baokao": 14, "luqu": 8 },
  { "name": "[690306] 幼儿发展与健康管理", "is_wenli": "理科", "jihua": 865, "baokao": 175, "luqu": 342 },
]