module.exports = {
  "本科专业名称": "就业满意度",
  "医学影像学": "84",
  "网络工程": "83",
  "播音与主持艺术": "82",
  "电子信息工程": "82",
  "微电子科学与工程": "82",
  "运动训练": "81",
  "计算机科学与技术": "81",
  "信息工程": "81",
  "医学影像技术": "81",
  "通信工程": "81",
  "临床医学": "81",
  "舞蹈学": "80",
  "生物制药": "80",
  "口腔医学": "80",
  // "动物科学": "80",
  // "药学": "80",
  // "汉语言文学": "80",
  // "体育教育": "79",
  // "电气工程及其自动化": "79",
  // "护理学": "79",
  // "软件工程": "79",
  // "动画": "79",
  // "小学教育": "79",
  // "化学": "79",
  // "给排水科学与工程": "79",
  // "广播电视编导": "79",
  // "信息安全": "79",
  // "信息与计算科学": "79",
  // "法学": "79",
  // "建筑学": "79",
  // "全国本科": "77",
}