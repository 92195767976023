module.exports = {
  "行业名称": "就业比例",
  "幼儿园与学前教育机构": "2.6",
  "居民服务业": "2.6",
  "铁路、道路、隧道和桥梁工程建筑业": "2.2",
  "其他制造业": "2.1",
  "基层医疗卫生服务机构": "1.9",
  "综合医院": "1.9",
  "建筑基础、结构、楼房外观承建业": "1.9",
  "建筑装修业": "1.8",
  "发电、输电业": "1.6",
  "药品和医药制造业": "1.5",
  "半导体和其他电子元件制造业": "1.5",
  "综合性餐饮业": "1.5",
  "软件开发业": "1.4",
  "中小学教育机构": "1.4",
  // "\"互联网平台服务业（工业互联网平台、",
  // "电商平台等）\"": "1.3",
  // "住宅建筑施工业": "1.3",
  // "百货零售业": "1.2",
  // "其他文体娱乐和休闲产业": "1.1",
  // "其他金融投资业": "1.1",
  // "物流仓储业": "1.1",
  // "其他信息服务业": "1.1",
  // "会计、审计与税务服务业": "1.1",
  // "电气设备制造业": "1",
  // "旅客住宿业": "0.9",
  // "其他培训学校和机构": "0.9",
  // "牙医诊所": "0.9",
  // "非住宅建筑施工业": "0.9",
  // "其他零售业": "0.9",
  // "其他化工产品制造业": "0.8",
  // "保险代理、经销、其他保险相关业": "0.8",
  // "专科医院": "0.7",
  // "快餐业": "0.7",
  // "通信设备制造业": "0.7",
  // "广告及相关服务业": "0.7",
  // "计算机及外围设备制造业": "0.7",
  // "基层群众自治组织（含村委会、居委会等）": "0.7",
  // "司法、执法部门（公检法）": "0.7",
  // "数据处理、存储、计算、加工等相关服务业": "0.6",
  // "单件机器制造业": "0.6",
  // "工业成套设备制造业": "0.6",
  // "铁路运输业": "0.6",
  // "互联网零售业": "0.6",
  // "汽车保养与维修业": "0.6",
  // "其他通用机械设备制造业": "0.6",
  // "医疗设备及用品制造业": "0.6",
  // "酒水、饮料及冷饮服务业": "0.6",
  // "办公室行政服务业": "0.6",
  // "其他地产相关业": "0.6",
  // "建筑、工程及相关咨询服务业": "0.6",
  // "房地产开发业": "0.6",
}