module.exports = [
  {
      "specialty_code": "030101",
      "name": "法学",
      "is_wenli": "文科",
      "jihua": "1231",
      "baokao": "3355",
      "luqu": "1262",
      "批次": "本科一批"
  },
  {
      "specialty_code": "050101",
      "name": "汉语言文学",
      "is_wenli": "文科",
      "jihua": "1026",
      "baokao": "3248",
      "luqu": "1038",
      "批次": "本科一批"
  },
  {
      "specialty_code": "050201",
      "name": "英语",
      "is_wenli": "文科",
      "jihua": "979",
      "baokao": "1161",
      "luqu": "982",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120203",
      "name": "会计学",
      "is_wenli": "文科",
      "jihua": "651",
      "baokao": "963",
      "luqu": "658",
      "批次": "本科一批"
  },
  {
      "specialty_code": "0501",
      "name": "中国语言文学类",
      "is_wenli": "文科",
      "jihua": "311",
      "baokao": "832",
      "luqu": "322",
      "批次": "本科一批"
  },
  {
      "specialty_code": "0201",
      "name": "经济学类",
      "is_wenli": "文科",
      "jihua": "412",
      "baokao": "830",
      "luqu": "419",
      "批次": "本科一批"
  },
  {
      "specialty_code": "0503",
      "name": "新闻传播学类",
      "is_wenli": "文科",
      "jihua": "344",
      "baokao": "729",
      "luqu": "341",
      "批次": "本科一批"
  },
  {
      "specialty_code": "030503",
      "name": "思想政治教育",
      "is_wenli": "文科",
      "jihua": "535",
      "baokao": "639",
      "luqu": "543",
      "批次": "本科一批"
  },
  {
      "specialty_code": "0301",
      "name": "法学类",
      "is_wenli": "文科",
      "jihua": "270",
      "baokao": "594",
      "luqu": "283",
      "批次": "本科一批"
  },
  {
      "specialty_code": "060101",
      "name": "历史学",
      "is_wenli": "文科",
      "jihua": "522",
      "baokao": "536",
      "luqu": "531",
      "批次": "本科一批"
  },
  {
      "specialty_code": "1202",
      "name": "工商管理类",
      "is_wenli": "文科",
      "jihua": "921",
      "baokao": "499",
      "luqu": "931",
      "批次": "本科一批"
  },
  {
      "specialty_code": "100501",
      "name": "中医学",
      "is_wenli": "文科",
      "jihua": "165",
      "baokao": "440",
      "luqu": "165",
      "批次": "本科一批"
  },
  {
      "specialty_code": "020301",
      "name": "金融学",
      "is_wenli": "文科",
      "jihua": "247",
      "baokao": "367",
      "luqu": "269",
      "批次": "本科一批"
  },
  {
      "specialty_code": "020101",
      "name": "经济学",
      "is_wenli": "文科",
      "jihua": "193",
      "baokao": "364",
      "luqu": "198",
      "批次": "本科一批"
  },
  {
      "specialty_code": "0502",
      "name": "外国语言文学类",
      "is_wenli": "文科",
      "jihua": "488",
      "baokao": "264",
      "luqu": "494",
      "批次": "本科一批"
  },
  {
      "specialty_code": "101101",
      "name": "护理学",
      "is_wenli": "文科",
      "jihua": "213",
      "baokao": "252",
      "luqu": "211",
      "批次": "本科一批"
  },
  {
      "specialty_code": "0601",
      "name": "历史学类",
      "is_wenli": "文科",
      "jihua": "203",
      "baokao": "239",
      "luqu": "203",
      "批次": "本科一批"
  },
  {
      "specialty_code": "00156",
      "name": "人文科学试验班",
      "is_wenli": "文科",
      "jihua": "74",
      "baokao": "233",
      "luqu": "73",
      "批次": "本科一批"
  },
  {
      "specialty_code": "050103",
      "name": "汉语国际教育",
      "is_wenli": "文科",
      "jihua": "332",
      "baokao": "222",
      "luqu": "335",
      "批次": "本科一批"
  },
  {
      "specialty_code": "0203",
      "name": "金融学类",
      "is_wenli": "文科",
      "jihua": "157",
      "baokao": "221",
      "luqu": "159",
      "批次": "本科一批"
  },
  {
      "specialty_code": "020401",
      "name": "国际经济与贸易",
      "is_wenli": "文科",
      "jihua": "371",
      "baokao": "212",
      "luqu": "377",
      "批次": "本科一批"
  },
  {
      "specialty_code": "00150",
      "name": "社会科学试验班",
      "is_wenli": "文科",
      "jihua": "99",
      "baokao": "210",
      "luqu": "98",
      "批次": "本科一批"
  },
  {
      "specialty_code": "02010F",
      "name": "经济学(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "153",
      "baokao": "200",
      "luqu": "150",
      "批次": "本科一批"
  },
  {
      "specialty_code": "1004",
      "name": "公共卫生与预防医学类",
      "is_wenli": "文科",
      "jihua": "10",
      "baokao": "194",
      "luqu": "10",
      "批次": "本科一批"
  },
  {
      "specialty_code": "0015",
      "name": "文科试验班类",
      "is_wenli": "文科",
      "jihua": "20",
      "baokao": "174",
      "luqu": "23",
      "批次": "本科一批"
  },
  {
      "specialty_code": "050306",
      "name": "网络与新媒体",
      "is_wenli": "文科",
      "jihua": "78",
      "baokao": "146",
      "luqu": "78",
      "批次": "本科一批"
  },
  {
      "specialty_code": "0101",
      "name": "哲学类",
      "is_wenli": "文科",
      "jihua": "22",
      "baokao": "135",
      "luqu": "20",
      "批次": "本科一批"
  },
  {
      "specialty_code": "12020H",
      "name": "会计学(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "85",
      "baokao": "134",
      "luqu": "85",
      "批次": "本科一批"
  },
  {
      "specialty_code": "050301",
      "name": "新闻学",
      "is_wenli": "文科",
      "jihua": "71",
      "baokao": "123",
      "luqu": "71",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120204",
      "name": "财务管理",
      "is_wenli": "文科",
      "jihua": "156",
      "baokao": "123",
      "luqu": "155",
      "批次": "本科一批"
  },
  {
      "specialty_code": "1204",
      "name": "公共管理类",
      "is_wenli": "文科",
      "jihua": "531",
      "baokao": "120",
      "luqu": "534",
      "批次": "本科一批"
  },
  {
      "specialty_code": "02030F",
      "name": "金融学(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "21",
      "baokao": "115",
      "luqu": "21",
      "批次": "本科一批"
  },
  {
      "specialty_code": "050302",
      "name": "广播电视学",
      "is_wenli": "文科",
      "jihua": "125",
      "baokao": "109",
      "luqu": "125",
      "批次": "本科一批"
  },
  {
      "specialty_code": "0401",
      "name": "教育学类",
      "is_wenli": "文科",
      "jihua": "116",
      "baokao": "105",
      "luqu": "116",
      "批次": "本科一批"
  },
  {
      "specialty_code": "040101",
      "name": "教育学",
      "is_wenli": "文科",
      "jihua": "121",
      "baokao": "102",
      "luqu": "128",
      "批次": "本科一批"
  },
  {
      "specialty_code": "100601",
      "name": "中西医临床医学",
      "is_wenli": "文科",
      "jihua": "99",
      "baokao": "97",
      "luqu": "100",
      "批次": "本科一批"
  },
  {
      "specialty_code": "00201",
      "name": "经济管理试验班",
      "is_wenli": "文科",
      "jihua": "29",
      "baokao": "95",
      "luqu": "30",
      "批次": "本科一批"
  },
  {
      "specialty_code": "010101",
      "name": "哲学",
      "is_wenli": "文科",
      "jihua": "108",
      "baokao": "94",
      "luqu": "108",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120208",
      "name": "资产评估",
      "is_wenli": "文科",
      "jihua": "8",
      "baokao": "91",
      "luqu": "8",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120207",
      "name": "审计学",
      "is_wenli": "文科",
      "jihua": "40",
      "baokao": "87",
      "luqu": "40",
      "批次": "本科一批"
  },
  {
      "specialty_code": "0305",
      "name": "马克思主义理论类",
      "is_wenli": "文科",
      "jihua": "58",
      "baokao": "86",
      "luqu": "58",
      "批次": "本科一批"
  },
  {
      "specialty_code": "02040F",
      "name": "国际经济与贸易(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "191",
      "baokao": "83",
      "luqu": "191",
      "批次": "本科一批"
  },
  {
      "specialty_code": "0303",
      "name": "社会学类",
      "is_wenli": "文科",
      "jihua": "231",
      "baokao": "77",
      "luqu": "235",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120402",
      "name": "行政管理",
      "is_wenli": "文科",
      "jihua": "243",
      "baokao": "77",
      "luqu": "248",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120201",
      "name": "工商管理",
      "is_wenli": "文科",
      "jihua": "195",
      "baokao": "76",
      "luqu": "205",
      "批次": "本科一批"
  },
  {
      "specialty_code": "0204",
      "name": "经济与贸易类",
      "is_wenli": "文科",
      "jihua": "109",
      "baokao": "72",
      "luqu": "110",
      "批次": "本科一批"
  },
  {
      "specialty_code": "030302",
      "name": "社会工作",
      "is_wenli": "文科",
      "jihua": "116",
      "baokao": "61",
      "luqu": "117",
      "批次": "本科一批"
  },
  {
      "specialty_code": "070501",
      "name": "地理科学",
      "is_wenli": "文科",
      "jihua": "113",
      "baokao": "61",
      "luqu": "117",
      "批次": "本科一批"
  },
  {
      "specialty_code": "050303",
      "name": "广告学",
      "is_wenli": "文科",
      "jihua": "82",
      "baokao": "60",
      "luqu": "82",
      "批次": "本科一批"
  },
  {
      "specialty_code": "0202",
      "name": "财政学类",
      "is_wenli": "文科",
      "jihua": "44",
      "baokao": "59",
      "luqu": "44",
      "批次": "本科一批"
  },
  {
      "specialty_code": "050262",
      "name": "商务英语",
      "is_wenli": "文科",
      "jihua": "58",
      "baokao": "59",
      "luqu": "57",
      "批次": "本科一批"
  },
  {
      "specialty_code": "020201",
      "name": "财政学",
      "is_wenli": "文科",
      "jihua": "36",
      "baokao": "56",
      "luqu": "36",
      "批次": "本科一批"
  },
  {
      "specialty_code": "050207",
      "name": "日语",
      "is_wenli": "文科",
      "jihua": "304",
      "baokao": "56",
      "luqu": "311",
      "批次": "本科一批"
  },
  {
      "specialty_code": "100502",
      "name": "针灸推拿学",
      "is_wenli": "文科",
      "jihua": "80",
      "baokao": "56",
      "luqu": "80",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120401",
      "name": "公共事业管理",
      "is_wenli": "文科",
      "jihua": "131",
      "baokao": "52",
      "luqu": "123",
      "批次": "本科一批"
  },
  {
      "specialty_code": "100513",
      "name": "中医骨伤科学",
      "is_wenli": "文科",
      "jihua": "110",
      "baokao": "48",
      "luqu": "110",
      "批次": "本科一批"
  },
  {
      "specialty_code": "030201",
      "name": "政治学与行政学",
      "is_wenli": "文科",
      "jihua": "148",
      "baokao": "47",
      "luqu": "148",
      "批次": "本科一批"
  },
  {
      "specialty_code": "0705",
      "name": "地理科学类",
      "is_wenli": "文科",
      "jihua": "47",
      "baokao": "46",
      "luqu": "48",
      "批次": "本科一批"
  },
  {
      "specialty_code": "050261",
      "name": "翻译",
      "is_wenli": "文科",
      "jihua": "142",
      "baokao": "45",
      "luqu": "140",
      "批次": "本科一批"
  },
  {
      "specialty_code": "020202",
      "name": "税收学",
      "is_wenli": "文科",
      "jihua": "67",
      "baokao": "43",
      "luqu": "67",
      "批次": "本科一批"
  },
  {
      "specialty_code": "1201",
      "name": "管理科学与工程类",
      "is_wenli": "文科",
      "jihua": "44",
      "baokao": "39",
      "luqu": "46",
      "批次": "本科一批"
  },
  {
      "specialty_code": "12020F",
      "name": "工商管理(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "89",
      "baokao": "37",
      "luqu": "87",
      "批次": "本科一批"
  },
  {
      "specialty_code": "030401",
      "name": "民族学",
      "is_wenli": "文科",
      "jihua": "5",
      "baokao": "36",
      "luqu": "5",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120206",
      "name": "人力资源管理",
      "is_wenli": "文科",
      "jihua": "186",
      "baokao": "35",
      "luqu": "179",
      "批次": "本科一批"
  },
  {
      "specialty_code": "060103",
      "name": "考古学",
      "is_wenli": "文科",
      "jihua": "25",
      "baokao": "33",
      "luqu": "25",
      "批次": "本科一批"
  },
  {
      "specialty_code": "07110F",
      "name": "心理学(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "33",
      "luqu": "3",
      "批次": "本科一批"
  },
  {
      "specialty_code": "130401",
      "name": "美术学",
      "is_wenli": "文科",
      "jihua": "4",
      "baokao": "33",
      "luqu": "4",
      "批次": "本科一批"
  },
  {
      "specialty_code": "03010F",
      "name": "法学(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "9",
      "baokao": "31",
      "luqu": "9",
      "批次": "本科一批"
  },
  {
      "specialty_code": "050202",
      "name": "俄语",
      "is_wenli": "文科",
      "jihua": "142",
      "baokao": "30",
      "luqu": "142",
      "批次": "本科一批"
  },
  {
      "specialty_code": "02010H",
      "name": "国民经济管理(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "28",
      "luqu": "1",
      "批次": "本科一批"
  },
  {
      "specialty_code": "0302",
      "name": "政治学类",
      "is_wenli": "文科",
      "jihua": "30",
      "baokao": "28",
      "luqu": "30",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120202",
      "name": "市场营销",
      "is_wenli": "文科",
      "jihua": "134",
      "baokao": "27",
      "luqu": "132",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120801",
      "name": "电子商务",
      "is_wenli": "文科",
      "jihua": "184",
      "baokao": "27",
      "luqu": "142",
      "批次": "本科一批"
  },
  {
      "specialty_code": "130305",
      "name": "广播电视编导",
      "is_wenli": "文科",
      "jihua": "6",
      "baokao": "27",
      "luqu": "6",
      "批次": "本科一批"
  },
  {
      "specialty_code": "020102",
      "name": "经济统计学",
      "is_wenli": "文科",
      "jihua": "21",
      "baokao": "26",
      "luqu": "21",
      "批次": "本科一批"
  },
  {
      "specialty_code": "050204",
      "name": "法语",
      "is_wenli": "文科",
      "jihua": "74",
      "baokao": "25",
      "luqu": "75",
      "批次": "本科一批"
  },
  {
      "specialty_code": "05030I",
      "name": "传播学(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "10",
      "baokao": "24",
      "luqu": "10",
      "批次": "本科一批"
  },
  {
      "specialty_code": "030202",
      "name": "国际政治",
      "is_wenli": "文科",
      "jihua": "88",
      "baokao": "22",
      "luqu": "85",
      "批次": "本科一批"
  },
  {
      "specialty_code": "030203",
      "name": "外交学",
      "is_wenli": "文科",
      "jihua": "5",
      "baokao": "22",
      "luqu": "7",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120601",
      "name": "物流管理",
      "is_wenli": "文科",
      "jihua": "118",
      "baokao": "22",
      "luqu": "129",
      "批次": "本科一批"
  },
  {
      "specialty_code": "020302",
      "name": "金融工程",
      "is_wenli": "文科",
      "jihua": "15",
      "baokao": "21",
      "luqu": "15",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120102",
      "name": "信息管理与信息系统",
      "is_wenli": "文科",
      "jihua": "48",
      "baokao": "21",
      "luqu": "46",
      "批次": "本科一批"
  },
  {
      "specialty_code": "130304",
      "name": "戏剧影视文学",
      "is_wenli": "文科",
      "jihua": "12",
      "baokao": "20",
      "luqu": "12",
      "批次": "本科一批"
  },
  {
      "specialty_code": "100510",
      "name": "中医康复学",
      "is_wenli": "文科",
      "jihua": "68",
      "baokao": "19",
      "luqu": "68",
      "批次": "本科一批"
  },
  {
      "specialty_code": "100512",
      "name": "中医儿科学",
      "is_wenli": "文科",
      "jihua": "43",
      "baokao": "19",
      "luqu": "43",
      "批次": "本科一批"
  },
  {
      "specialty_code": "050205",
      "name": "西班牙语",
      "is_wenli": "文科",
      "jihua": "17",
      "baokao": "18",
      "luqu": "17",
      "批次": "本科一批"
  },
  {
      "specialty_code": "030504",
      "name": "马克思主义理论",
      "is_wenli": "文科",
      "jihua": "14",
      "baokao": "17",
      "luqu": "14",
      "批次": "本科一批"
  },
  {
      "specialty_code": "040107",
      "name": "小学教育",
      "is_wenli": "文科",
      "jihua": "48",
      "baokao": "17",
      "luqu": "48",
      "批次": "本科一批"
  },
  {
      "specialty_code": "050304",
      "name": "传播学",
      "is_wenli": "文科",
      "jihua": "24",
      "baokao": "17",
      "luqu": "27",
      "批次": "本科一批"
  },
  {
      "specialty_code": "1202H",
      "name": "工商管理类(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "26",
      "baokao": "17",
      "luqu": "32",
      "批次": "本科一批"
  },
  {
      "specialty_code": "1205",
      "name": "图书情报与档案管理类",
      "is_wenli": "文科",
      "jihua": "68",
      "baokao": "17",
      "luqu": "66",
      "批次": "本科一批"
  },
  {
      "specialty_code": "12020I",
      "name": "财务管理(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "17",
      "baokao": "16",
      "luqu": "17",
      "批次": "本科一批"
  },
  {
      "specialty_code": "030301",
      "name": "社会学",
      "is_wenli": "文科",
      "jihua": "50",
      "baokao": "15",
      "luqu": "50",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120205",
      "name": "国际商务",
      "is_wenli": "文科",
      "jihua": "90",
      "baokao": "15",
      "luqu": "70",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120210",
      "name": "文化产业管理",
      "is_wenli": "文科",
      "jihua": "39",
      "baokao": "15",
      "luqu": "39",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120901",
      "name": "旅游管理",
      "is_wenli": "文科",
      "jihua": "204",
      "baokao": "15",
      "luqu": "145",
      "批次": "本科一批"
  },
  {
      "specialty_code": "0502H",
      "name": "外国语言文学类(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "8",
      "baokao": "14",
      "luqu": "13",
      "批次": "本科一批"
  },
  {
      "specialty_code": "030102",
      "name": "知识产权",
      "is_wenli": "文科",
      "jihua": "22",
      "baokao": "13",
      "luqu": "22",
      "批次": "本科一批"
  },
  {
      "specialty_code": "04010K",
      "name": "学前教育(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "71",
      "baokao": "13",
      "luqu": "71",
      "批次": "本科一批"
  },
  {
      "specialty_code": "050305",
      "name": "编辑出版学",
      "is_wenli": "文科",
      "jihua": "7",
      "baokao": "13",
      "luqu": "7",
      "批次": "本科一批"
  },
  {
      "specialty_code": "1209",
      "name": "旅游管理类",
      "is_wenli": "文科",
      "jihua": "140",
      "baokao": "13",
      "luqu": "146",
      "批次": "本科一批"
  },
  {
      "specialty_code": "1301",
      "name": "艺术学理论类",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "13",
      "luqu": "2",
      "批次": "本科一批"
  },
  {
      "specialty_code": "020402",
      "name": "贸易经济",
      "is_wenli": "文科",
      "jihua": "64",
      "baokao": "12",
      "luqu": "64",
      "批次": "本科一批"
  },
  {
      "specialty_code": "050203",
      "name": "德语",
      "is_wenli": "文科",
      "jihua": "32",
      "baokao": "12",
      "luqu": "32",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120301",
      "name": "农林经济管理",
      "is_wenli": "文科",
      "jihua": "38",
      "baokao": "12",
      "luqu": "39",
      "批次": "本科一批"
  },
  {
      "specialty_code": "12090F",
      "name": "旅游管理(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "16",
      "baokao": "12",
      "luqu": "14",
      "批次": "本科一批"
  },
  {
      "specialty_code": "0828",
      "name": "建筑类",
      "is_wenli": "文科",
      "jihua": "25",
      "baokao": "11",
      "luqu": "24",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120403",
      "name": "劳动与社会保障",
      "is_wenli": "文科",
      "jihua": "89",
      "baokao": "11",
      "luqu": "89",
      "批次": "本科一批"
  },
  {
      "specialty_code": "12080F",
      "name": "电子商务(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "7",
      "baokao": "11",
      "luqu": "7",
      "批次": "本科一批"
  },
  {
      "specialty_code": "00600",
      "name": "工科试验班",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "10",
      "luqu": "1",
      "批次": "本科一批"
  },
  {
      "specialty_code": "060102",
      "name": "世界史",
      "is_wenli": "文科",
      "jihua": "14",
      "baokao": "10",
      "luqu": "14",
      "批次": "本科一批"
  },
  {
      "specialty_code": "100511",
      "name": "中医养生学",
      "is_wenli": "文科",
      "jihua": "52",
      "baokao": "10",
      "luqu": "52",
      "批次": "本科一批"
  },
  {
      "specialty_code": "020304",
      "name": "投资学",
      "is_wenli": "文科",
      "jihua": "28",
      "baokao": "9",
      "luqu": "28",
      "批次": "本科一批"
  },
  {
      "specialty_code": "030108",
      "name": "纪检监察",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "9",
      "luqu": "1",
      "批次": "本科一批"
  },
  {
      "specialty_code": "05020F",
      "name": "英语(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "11",
      "baokao": "9",
      "luqu": "11",
      "批次": "本科一批"
  },
  {
      "specialty_code": "071102",
      "name": "应用心理学",
      "is_wenli": "文科",
      "jihua": "14",
      "baokao": "9",
      "luqu": "14",
      "批次": "本科一批"
  },
  {
      "specialty_code": "030105",
      "name": "国际经贸规则",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "8",
      "luqu": "2",
      "批次": "本科一批"
  },
  {
      "specialty_code": "05020G",
      "name": "俄语(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "23",
      "baokao": "8",
      "luqu": "23",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120404",
      "name": "土地资源管理",
      "is_wenli": "文科",
      "jihua": "23",
      "baokao": "8",
      "luqu": "23",
      "批次": "本科一批"
  },
  {
      "specialty_code": "130102",
      "name": "艺术管理",
      "is_wenli": "文科",
      "jihua": "7",
      "baokao": "8",
      "luqu": "7",
      "批次": "本科一批"
  },
  {
      "specialty_code": "020303",
      "name": "保险学",
      "is_wenli": "文科",
      "jihua": "16",
      "baokao": "7",
      "luqu": "16",
      "批次": "本科一批"
  },
  {
      "specialty_code": "030205",
      "name": "政治学、经济学与哲学",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "7",
      "luqu": "2",
      "批次": "本科一批"
  },
  {
      "specialty_code": "071101",
      "name": "心理学",
      "is_wenli": "文科",
      "jihua": "9",
      "baokao": "7",
      "luqu": "9",
      "批次": "本科一批"
  },
  {
      "specialty_code": "0201H",
      "name": "经济学类(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "6",
      "luqu": "3",
      "批次": "本科一批"
  },
  {
      "specialty_code": "03020H",
      "name": "外交学(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "6",
      "luqu": "2",
      "批次": "本科一批"
  },
  {
      "specialty_code": "05030H",
      "name": "广告学(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "5",
      "baokao": "6",
      "luqu": "5",
      "批次": "本科一批"
  },
  {
      "specialty_code": "0503H",
      "name": "新闻传播学类(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "4",
      "baokao": "6",
      "luqu": "4",
      "批次": "本科一批"
  },
  {
      "specialty_code": "0711",
      "name": "心理学类",
      "is_wenli": "文科",
      "jihua": "9",
      "baokao": "6",
      "luqu": "9",
      "批次": "本科一批"
  },
  {
      "specialty_code": "081602",
      "name": "服装设计与工程",
      "is_wenli": "文科",
      "jihua": "11",
      "baokao": "6",
      "luqu": "11",
      "批次": "本科一批"
  },
  {
      "specialty_code": "12020L",
      "name": "审计学(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "6",
      "luqu": "2",
      "批次": "本科一批"
  },
  {
      "specialty_code": "020109",
      "name": "数字经济",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "5",
      "luqu": "2",
      "批次": "本科一批"
  },
  {
      "specialty_code": "040106",
      "name": "学前教育",
      "is_wenli": "文科",
      "jihua": "45",
      "baokao": "5",
      "luqu": "45",
      "批次": "本科一批"
  },
  {
      "specialty_code": "070502",
      "name": "自然地理与资源环境",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "5",
      "luqu": "2",
      "批次": "本科一批"
  },
  {
      "specialty_code": "082803",
      "name": "风景园林",
      "is_wenli": "文科",
      "jihua": "8",
      "baokao": "5",
      "luqu": "6",
      "批次": "本科一批"
  },
  {
      "specialty_code": "12020G",
      "name": "市场营销(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "22",
      "baokao": "5",
      "luqu": "21",
      "批次": "本科一批"
  },
  {
      "specialty_code": "030204",
      "name": "国际事务与国际关系",
      "is_wenli": "文科",
      "jihua": "7",
      "baokao": "4",
      "luqu": "7",
      "批次": "本科一批"
  },
  {
      "specialty_code": "050107",
      "name": "秘书学",
      "is_wenli": "文科",
      "jihua": "28",
      "baokao": "4",
      "luqu": "28",
      "批次": "本科一批"
  },
  {
      "specialty_code": "050237",
      "name": "匈牙利语",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "4",
      "luqu": "1",
      "批次": "本科一批"
  },
  {
      "specialty_code": "050238",
      "name": "意大利语",
      "is_wenli": "文科",
      "jihua": "4",
      "baokao": "4",
      "luqu": "4",
      "批次": "本科一批"
  },
  {
      "specialty_code": "060104",
      "name": "文物与博物馆学",
      "is_wenli": "文科",
      "jihua": "9",
      "baokao": "4",
      "luqu": "9",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120105",
      "name": "工程造价",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "4",
      "luqu": "3",
      "批次": "本科一批"
  },
  {
      "specialty_code": "12020J",
      "name": "国际商务(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "11",
      "baokao": "4",
      "luqu": "10",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120212",
      "name": "体育经济与管理",
      "is_wenli": "文科",
      "jihua": "7",
      "baokao": "4",
      "luqu": "7",
      "批次": "本科一批"
  },
  {
      "specialty_code": "12030F",
      "name": "农林经济管理(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "4",
      "luqu": "1",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120802",
      "name": "电子商务及法律",
      "is_wenli": "文科",
      "jihua": "18",
      "baokao": "4",
      "luqu": "18",
      "批次": "本科一批"
  },
  {
      "specialty_code": "130313",
      "name": "戏剧教育",
      "is_wenli": "文科",
      "jihua": "4",
      "baokao": "4",
      "luqu": "4",
      "批次": "本科一批"
  },
  {
      "specialty_code": "020307",
      "name": "经济与金融",
      "is_wenli": "文科",
      "jihua": "4",
      "baokao": "3",
      "luqu": "4",
      "批次": "本科一批"
  },
  {
      "specialty_code": "03020I",
      "name": "国际事务与国际关系(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "3",
      "luqu": "2",
      "批次": "本科一批"
  },
  {
      "specialty_code": "0828H",
      "name": "建筑类(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "8",
      "baokao": "3",
      "luqu": "7",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120111",
      "name": "应急管理",
      "is_wenli": "文科",
      "jihua": "4",
      "baokao": "3",
      "luqu": "4",
      "批次": "本科一批"
  },
  {
      "specialty_code": "130302",
      "name": "戏剧学",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "3",
      "luqu": "1",
      "批次": "本科一批"
  },
  {
      "specialty_code": "050212",
      "name": "印度尼西亚语",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "2",
      "luqu": "3",
      "批次": "本科一批"
  },
  {
      "specialty_code": "050309",
      "name": "国际新闻与传播",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "2",
      "luqu": "2",
      "批次": "本科一批"
  },
  {
      "specialty_code": "060107",
      "name": "文化遗产",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "2",
      "luqu": "3",
      "批次": "本科一批"
  },
  {
      "specialty_code": "0701H",
      "name": "数学类(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "15",
      "baokao": "2",
      "luqu": "8",
      "批次": "本科一批"
  },
  {
      "specialty_code": "070503",
      "name": "人文地理与城乡规划",
      "is_wenli": "文科",
      "jihua": "6",
      "baokao": "2",
      "luqu": "6",
      "批次": "本科一批"
  },
  {
      "specialty_code": "101005",
      "name": "康复治疗学",
      "is_wenli": "文科",
      "jihua": "5",
      "baokao": "2",
      "luqu": "5",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120104",
      "name": "房地产开发与管理",
      "is_wenli": "文科",
      "jihua": "10",
      "baokao": "2",
      "luqu": "10",
      "批次": "本科一批"
  },
  {
      "specialty_code": "1203",
      "name": "农业经济管理类",
      "is_wenli": "文科",
      "jihua": "4",
      "baokao": "2",
      "luqu": "4",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120302",
      "name": "农村区域发展",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "2",
      "luqu": "3",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120903",
      "name": "会展经济与管理",
      "is_wenli": "文科",
      "jihua": "26",
      "baokao": "2",
      "luqu": "26",
      "批次": "本科一批"
  },
  {
      "specialty_code": "030103",
      "name": "监狱学",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "1",
      "luqu": "2",
      "批次": "本科一批"
  },
  {
      "specialty_code": "030306",
      "name": "老年学",
      "is_wenli": "文科",
      "jihua": "7",
      "baokao": "1",
      "luqu": "7",
      "批次": "本科一批"
  },
  {
      "specialty_code": "030502",
      "name": "中国共产党历史",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "1",
      "luqu": "3",
      "批次": "本科一批"
  },
  {
      "specialty_code": "040206",
      "name": "运动康复",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "1",
      "luqu": "2",
      "批次": "本科一批"
  },
  {
      "specialty_code": "050209",
      "name": "朝鲜语",
      "is_wenli": "文科",
      "jihua": "52",
      "baokao": "1",
      "luqu": "51",
      "批次": "本科一批"
  },
  {
      "specialty_code": "050216",
      "name": "缅甸语",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "1",
      "luqu": "1",
      "批次": "本科一批"
  },
  {
      "specialty_code": "07050H",
      "name": "人文地理与城乡规划(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "8",
      "baokao": "1",
      "luqu": "9",
      "批次": "本科一批"
  },
  {
      "specialty_code": "082802",
      "name": "城乡规划",
      "is_wenli": "文科",
      "jihua": "4",
      "baokao": "1",
      "luqu": "4",
      "批次": "本科一批"
  },
  {
      "specialty_code": "100704",
      "name": "药事管理",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "1",
      "luqu": "1",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120405",
      "name": "城市管理",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "1",
      "luqu": "2",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120407",
      "name": "交通管理",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "1",
      "luqu": "2",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120408",
      "name": "海事管理",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "1",
      "luqu": "2",
      "批次": "本科一批"
  },
  {
      "specialty_code": "12040G",
      "name": "行政管理(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "4",
      "baokao": "1",
      "luqu": "5",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120414",
      "name": "养老服务管理",
      "is_wenli": "文科",
      "jihua": "4",
      "baokao": "1",
      "luqu": "4",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120603",
      "name": "采购管理",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "1",
      "luqu": "1",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120604",
      "name": "供应链管理",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "1",
      "luqu": "3",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120902",
      "name": "酒店管理",
      "is_wenli": "文科",
      "jihua": "6",
      "baokao": "1",
      "luqu": "5",
      "批次": "本科一批"
  },
  {
      "specialty_code": "12090H",
      "name": "会展经济与管理(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "9",
      "baokao": "1",
      "luqu": "10",
      "批次": "本科一批"
  },
  {
      "specialty_code": "020306",
      "name": "信用管理",
      "is_wenli": "文科",
      "jihua": "16",
      "baokao": "0",
      "luqu": "16",
      "批次": "本科一批"
  },
  {
      "specialty_code": "030206",
      "name": "国际组织与全球治理",
      "is_wenli": "文科",
      "jihua": "4",
      "baokao": "0",
      "luqu": "4",
      "批次": "本科一批"
  },
  {
      "specialty_code": "040104",
      "name": "教育技术学",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "0",
      "luqu": "3",
      "批次": "本科一批"
  },
  {
      "specialty_code": "040108",
      "name": "特殊教育",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "0",
      "luqu": "2",
      "批次": "本科一批"
  },
  {
      "specialty_code": "050105",
      "name": "古典文献学",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "0",
      "luqu": "2",
      "批次": "本科一批"
  },
  {
      "specialty_code": "050206",
      "name": "阿拉伯语",
      "is_wenli": "文科",
      "jihua": "10",
      "baokao": "0",
      "luqu": "9",
      "批次": "本科一批"
  },
  {
      "specialty_code": "05020J",
      "name": "西班牙语(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "0",
      "luqu": "1",
      "批次": "本科一批"
  },
  {
      "specialty_code": "050217",
      "name": "马来语",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "0",
      "luqu": "2",
      "批次": "本科一批"
  },
  {
      "specialty_code": "050220",
      "name": "泰语",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "0",
      "luqu": "1",
      "批次": "本科一批"
  },
  {
      "specialty_code": "050221",
      "name": "乌尔都语",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "0",
      "luqu": "2",
      "批次": "本科一批"
  },
  {
      "specialty_code": "050223",
      "name": "越南语",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "0",
      "luqu": "1",
      "批次": "本科一批"
  },
  {
      "specialty_code": "050232",
      "name": "葡萄牙语",
      "is_wenli": "文科",
      "jihua": "4",
      "baokao": "0",
      "luqu": "4",
      "批次": "本科一批"
  },
  {
      "specialty_code": "080205",
      "name": "工业设计",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "0",
      "luqu": "2",
      "批次": "本科一批"
  },
  {
      "specialty_code": "082801",
      "name": "建筑学",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "0",
      "luqu": "1",
      "批次": "本科一批"
  },
  {
      "specialty_code": "090102",
      "name": "园艺",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "0",
      "luqu": "1",
      "批次": "本科一批"
  },
  {
      "specialty_code": "09010G",
      "name": "园艺(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "6",
      "baokao": "0",
      "luqu": "6",
      "批次": "本科一批"
  },
  {
      "specialty_code": "090502",
      "name": "园林",
      "is_wenli": "文科",
      "jihua": "5",
      "baokao": "0",
      "luqu": "5",
      "批次": "本科一批"
  },
  {
      "specialty_code": "101102",
      "name": "助产学",
      "is_wenli": "文科",
      "jihua": "8",
      "baokao": "0",
      "luqu": "4",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120103",
      "name": "工程管理",
      "is_wenli": "文科",
      "jihua": "7",
      "baokao": "0",
      "luqu": "7",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120211",
      "name": "劳动关系",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "0",
      "luqu": "1",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120409",
      "name": "公共关系学",
      "is_wenli": "文科",
      "jihua": "4",
      "baokao": "0",
      "luqu": "4",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120410",
      "name": "健康服务与管理",
      "is_wenli": "文科",
      "jihua": "4",
      "baokao": "0",
      "luqu": "4",
      "批次": "本科一批"
  },
  {
      "specialty_code": "12041E",
      "name": "健康服务与管理(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "0",
      "luqu": "2",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120501",
      "name": "图书馆学",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "0",
      "luqu": "3",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120502",
      "name": "档案学",
      "is_wenli": "文科",
      "jihua": "5",
      "baokao": "0",
      "luqu": "5",
      "批次": "本科一批"
  },
  {
      "specialty_code": "120702",
      "name": "标准化工程",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "0",
      "luqu": "1",
      "批次": "本科一批"
  },
  {
      "specialty_code": "1208",
      "name": "电子商务类",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "0",
      "luqu": "1",
      "批次": "本科一批"
  },
  {
      "specialty_code": "12090G",
      "name": "酒店管理(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "10",
      "baokao": "0",
      "luqu": "7",
      "批次": "本科一批"
  }
]