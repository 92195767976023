module.exports = {
  "高职专业大类名称": "就业率",
  "生物与化工大类": "92.1",
  "能源动力与材料大类": "91.7",
  "装备制造大类": "91.2",
  "土木建筑大类": "90",
  "资源环境与安全大类": "89.2",
  "农林牧渔大类": "89",
  "公共管理与服务大类": "89",
  "食品药品与粮食大类": "88.7",
  "新闻传播大类": "88.5",
  "交通运输大类": "88.3",
  "财经商贸大类": "87.4",
  "旅游大类": "87.4",
  "教育与体育大类": "87.1",
  "电子信息大类": "87",
  "文化艺术大类": "86.9",
  "医药卫生大类": "85.7",
  "全国高职": "87.7",
}