module.exports = [
  // 学科门类月收入排行			
  { "a": "本科学科门类名称", "b": "2022届", "c": "2021届", "d": "2020届" },
  { "a": "工学", "b": "6610", "c": "6323", "d": "5913" },
  { "a": "经济学", "b": "6003", "c": "5841", "d": "5524" },
  { "a": "管理学", "b": "5843", "c": "5744", "d": "5381" },
  { "a": "理学", "b": "5759", "c": "5689", "d": "5458" },
  { "a": "文学", "b": "5509", "c": "5399", "d": "5197" },
  { "a": "农学", "b": "5501", "c": "5381", "d": "4984" },
  { "a": "医学", "b": "5424", "c": "5152", "d": "4960" },
  { "a": "艺术学", "b": "5404", "c": "5475", "d": "5182" },
  { "a": "法学", "b": "5339", "c": "5222", "d": "4963" },
  { "a": "历史学", "b": "5134", "c": "4848", "d": "4610" },
  { "a": "教育学", "b": "4867", "c": "4935", "d": "4846" },
  { "a": "全国本科", "b": "5990", "c": "5833", "d": "5471" },
]
