module.exports = [
  { "name": "信阳学院", "is_wenli": "文科", "jihua": 2543, "luqu": 2543, "min_score": 481 },
  { "name": "郑州西亚斯学院(原郑州大学西亚斯国际学院)", "is_wenli": "文科", "jihua": 1973, "luqu": 1973, "min_score": 487 },
  { "name": "河南师范大学新联学院", "is_wenli": "文科", "jihua": 1735, "luqu": 1745, "min_score": 506 },
  { "name": "郑州升达经贸管理学院", "is_wenli": "文科", "jihua": 1674, "luqu": 1694, "min_score": 491 },
  { "name": "郑州商学院(原郑州成功财经学院)", "is_wenli": "文科", "jihua": 1660, "luqu": 1677, "min_score": 476 },
  { "name": "安阳学院", "is_wenli": "文科", "jihua": 1641, "luqu": 1641, "min_score": 474 },
  { "name": "河南牧业经济学院", "is_wenli": "文科", "jihua": 1570, "luqu": 1610, "min_score": 524 },
  { "name": "郑州财经学院", "is_wenli": "文科", "jihua": 1415, "luqu": 1418, "min_score": 488 },
  { "name": "郑州工商学院", "is_wenli": "文科", "jihua": 1229, "luqu": 1229, "min_score": 482 },
  { "name": "郑州经贸学院(原中原工学院信息商务学院)", "is_wenli": "文科", "jihua": 1158, "luqu": 1179, "min_score": 494 },
  { "name": "商丘工学院", "is_wenli": "文科", "jihua": 1140, "luqu": 1146, "min_score": 471 },
  { "name": "郑州科技学院", "is_wenli": "文科", "jihua": 1118, "luqu": 1129, "min_score": 484 },
  { "name": "郑州工业应用技术学院(原郑州华信学院)", "is_wenli": "文科", "jihua": 1077, "luqu": 1088, "min_score": 478 },
  { "name": "洛阳师范学院", "is_wenli": "文科", "jihua": 1058, "luqu": 1081, "min_score": 548 },
  { "name": "安阳师范学院", "is_wenli": "文科", "jihua": 1041, "luqu": 1067, "min_score": 536 },
  { "name": "河南财政金融学院", "is_wenli": "文科", "jihua": 1045, "luqu": 1045, "min_score": 533 },
  { "name": "河南大学民生学院", "is_wenli": "文科", "jihua": 977, "luqu": 1010, "min_score": 511 },
  { "name": "郑州师范学院", "is_wenli": "文科", "jihua": 945, "luqu": 957, "min_score": 544 },
  { "name": "信阳师范学院", "is_wenli": "文科", "jihua": 891, "luqu": 928, "min_score": 552 },
  { "name": "商丘学院(应用科技学院,办学地点在开封)", "is_wenli": "文科", "jihua": 926, "luqu": 926, "min_score": 472 },
  { "name": "平顶山学院", "is_wenli": "文科", "jihua": 867, "luqu": 884, "min_score": 520 },
  { "name": "黄河交通学院", "is_wenli": "文科", "jihua": 867, "luqu": 867, "min_score": 469 },
  { "name": "商丘师范学院", "is_wenli": "文科", "jihua": 852, "luqu": 853, "min_score": 530 },
  { "name": "黄河科技学院", "is_wenli": "文科", "jihua": 842, "luqu": 842, "min_score": 501 },
  { "name": "信阳农林学院", "is_wenli": "文科", "jihua": 801, "luqu": 807, "min_score": 517 },
  { "name": "周口师范学院", "is_wenli": "文科", "jihua": 787, "luqu": 799, "min_score": 525 },
  { "name": "新乡学院", "is_wenli": "文科", "jihua": 761, "luqu": 779, "min_score": 525 },
  { "name": "许昌学院", "is_wenli": "文科", "jihua": 692, "luqu": 699, "min_score": 531 },
  { "name": "南阳师范学院", "is_wenli": "文科", "jihua": 664, "luqu": 675, "min_score": 536 },
  { "name": "新乡医学院三全学院", "is_wenli": "文科", "jihua": 645, "luqu": 645, "min_score": 494 },
  { "name": "河南科技学院新科学院", "is_wenli": "文科", "jihua": 630, "luqu": 639, "min_score": 506 },
  { "name": "洛阳理工学院", "is_wenli": "文科", "jihua": 604, "luqu": 625, "min_score": 539 },
  { "name": "黄淮学院", "is_wenli": "文科", "jihua": 602, "luqu": 613, "min_score": 520 },
  { "name": "河南科技职业大学(原周口科技职业学院)", "is_wenli": "文科", "jihua": 560, "luqu": 560, "min_score": 467 },
  { "name": "河南科技学院", "is_wenli": "文科", "jihua": 552, "luqu": 552, "min_score": 537 },
  { "name": "商丘学院", "is_wenli": "文科", "jihua": 508, "luqu": 520, "min_score": 475 },
  { "name": "河南工程学院", "is_wenli": "文科", "jihua": 396, "luqu": 407, "min_score": 542 },
  { "name": "河南牧业经济学院(中外合作)", "is_wenli": "文科", "jihua": 372, "luqu": 372, "min_score": 492 },
  { "name": "商丘师范学院(中外合作办学)", "is_wenli": "文科", "jihua": 345, "luqu": 358, "min_score": 496 },
  { "name": "安阳工学院", "is_wenli": "文科", "jihua": 355, "luqu": 357, "min_score": 521 },
  { "name": "南阳理工学院", "is_wenli": "文科", "jihua": 309, "luqu": 309, "min_score": 533 },
  { "name": "河南城建学院", "is_wenli": "文科", "jihua": 302, "luqu": 304, "min_score": 523 },
  { "name": "郑州西亚斯学院(中外合作办学)(原郑州大学西亚斯国际学院)", "is_wenli": "文科", "jihua": 302, "luqu": 302, "min_score": 465 },
  { "name": "洛阳师范学院(中外合作办学)", "is_wenli": "文科", "jihua": 270, "luqu": 280, "min_score": 514 },
  { "name": "郑州工程技术学院", "is_wenli": "文科", "jihua": 278, "luqu": 279, "min_score": 521 },
  { "name": "河南财经政法大学(中外合作办学)", "is_wenli": "文科", "jihua": 275, "luqu": 277, "min_score": 544 },
  { "name": "华北水利水电大学(与嵩山少林武术职业学院联办,就读地详见专业说明)", "is_wenli": "文科", "jihua": 210, "luqu": 219, "min_score": 505 },
  { "name": "郑州大学(体育学院)", "is_wenli": "文科", "jihua": 195, "luqu": 195, "min_score": 516 },
  { "name": "喀什大学", "is_wenli": "文科", "jihua": 169, "luqu": 170, "min_score": 513 },
  { "name": "宁夏理工学院", "is_wenli": "文科", "jihua": 165, "luqu": 165, "min_score": 471 },
  { "name": "南阳理工学院(中外合作办学)", "is_wenli": "文科", "jihua": 159, "luqu": 160, "min_score": 506 },
  { "name": "黄河科技学院(中外合作办学)", "is_wenli": "文科", "jihua": 160, "luqu": 160, "min_score": 468 },
  { "name": "河南工学院", "is_wenli": "文科", "jihua": 150, "luqu": 157, "min_score": 520 },
  { "name": "河南工业大学(中外合作办学)", "is_wenli": "文科", "jihua": 149, "luqu": 156, "min_score": 539 },
  { "name": "许昌学院(中外合作办学)", "is_wenli": "文科", "jihua": 138, "luqu": 148, "min_score": 500 },
  { "name": "平顶山学院(中外合作办学)", "is_wenli": "文科", "jihua": 140, "luqu": 140, "min_score": 496 },
  { "name": "河南农业大学(办学就读地点在许昌)", "is_wenli": "文科", "jihua": 125, "luqu": 125, "min_score": 531 },
  { "name": "河南中医药大学(与嵩山少林武术职业学院联合办学(前两年就读嵩山)", "is_wenli": "文科", "jihua": 120, "luqu": 124, "min_score": 506 },
  { "name": "河南师范大学(中外合作办学)", "is_wenli": "文科", "jihua": 100, "luqu": 121, "min_score": 543 },
  { "name": "信阳师范学院(中外合作办学)", "is_wenli": "文科", "jihua": 120, "luqu": 121, "min_score": 518 },
  { "name": "郑州轻工业大学(中外合作办学)", "is_wenli": "文科", "jihua": 120, "luqu": 120, "min_score": 531 },
  { "name": "上海建桥学院", "is_wenli": "文科", "jihua": 119, "luqu": 119, "min_score": 465 },
  { "name": "三亚学院", "is_wenli": "文科", "jihua": 113, "luqu": 113, "min_score": 474 },
  { "name": "新疆师范大学", "is_wenli": "文科", "jihua": 93, "luqu": 108, "min_score": 534 },
  { "name": "昌吉学院", "is_wenli": "文科", "jihua": 105, "luqu": 105, "min_score": 511 },
  { "name": "南阳师范学院(中外合作办学)", "is_wenli": "文科", "jihua": 100, "luqu": 104, "min_score": 497 },
  { "name": "伊犁师范大学(原伊犁师范学院)", "is_wenli": "文科", "jihua": 102, "luqu": 102, "min_score": 514 },
  { "name": "河南科技学院[较高收费]", "is_wenli": "文科", "jihua": 100, "luqu": 100, "min_score": 507 },
  { "name": "河南财经政法大学(与河南省人民检察院联办,就读在河南检察职业学院", "is_wenli": "文科", "jihua": 98, "luqu": 98, "min_score": 552 },
  { "name": "南开大学滨海学院", "is_wenli": "文科", "jihua": 98, "luqu": 98, "min_score": 521 },
  { "name": "无锡太湖学院", "is_wenli": "文科", "jihua": 98, "luqu": 98, "min_score": 488 },
  { "name": "长春科技学院", "is_wenli": "文科", "jihua": 90, "luqu": 95, "min_score": 473 },
  { "name": "塔里木大学", "is_wenli": "文科", "jihua": 86, "luqu": 86, "min_score": 519 },
  { "name": "天津财经大学珠江学院", "is_wenli": "文科", "jihua": 82, "luqu": 82, "min_score": 506 },
  { "name": "辽宁师范大学海华学院", "is_wenli": "文科", "jihua": 72, "luqu": 82, "min_score": 469 },
  { "name": "海南科技职业大学(原海南科技职业学院)", "is_wenli": "文科", "jihua": 82, "luqu": 82, "min_score": 466 },
  { "name": "山东协和学院", "is_wenli": "文科", "jihua": 82, "luqu": 82, "min_score": 470 },
  { "name": "新疆科技学院(原新疆财经大学商务学院)", "is_wenli": "文科", "jihua": 81, "luqu": 81, "min_score": 504 },
  { "name": "南京传媒学院(原中国传媒大学南广学院)", "is_wenli": "文科", "jihua": 80, "luqu": 81, "min_score": 519 },
  { "name": "洛阳师范学院(马来西亚城市大学河洛学院)", "is_wenli": "文科", "jihua": 80, "luqu": 80, "min_score": 471 },
  { "name": "黄河科技学院(应用技术学院)(各专业办学地点在济源市)", "is_wenli": "文科", "jihua": 80, "luqu": 80, "min_score": 470 },
  { "name": "上海杉达学院", "is_wenli": "文科", "jihua": 80, "luqu": 80, "min_score": 469 },
  { "name": "苏州大学文正学院", "is_wenli": "文科", "jihua": 70, "luqu": 74, "min_score": 535 },
  { "name": "吉林外国语大学", "is_wenli": "文科", "jihua": 74, "luqu": 74, "min_score": 514 },
  { "name": "安阳师范学院(中外合作办学)", "is_wenli": "文科", "jihua": 70, "luqu": 70, "min_score": 509 },
  { "name": "南阳师范学院[异地校区]", "is_wenli": "文科", "jihua": 70, "luqu": 70, "min_score": 512 },
  { "name": "郑州航空工业管理学院", "is_wenli": "文科", "jihua": 68, "luqu": 70, "min_score": 547 },
  { "name": "黑龙江科技大学", "is_wenli": "文科", "jihua": 67, "luqu": 67, "min_score": 545 },
  { "name": "海南热带海洋学院", "is_wenli": "文科", "jihua": 64, "luqu": 67, "min_score": 539 },
  { "name": "哈尔滨金融学院", "is_wenli": "文科", "jihua": 66, "luqu": 66, "min_score": 536 },
  { "name": "闽南师范大学", "is_wenli": "文科", "jihua": 65, "luqu": 65, "min_score": 553 },
  { "name": "华北水利水电大学(与河南经贸职业学院联办，就读在河南经贸职业学院", "is_wenli": "文科", "jihua": 60, "luqu": 65, "min_score": 542 },
  { "name": "上海师范大学天华学院", "is_wenli": "文科", "jihua": 64, "luqu": 64, "min_score": 468 },
  { "name": "南京财经大学红山学院", "is_wenli": "文科", "jihua": 64, "luqu": 64, "min_score": 513 },
  { "name": "黑龙江工商学院", "is_wenli": "文科", "jihua": 45, "luqu": 64, "min_score": 466 },
  { "name": "烟台南山学院", "is_wenli": "文科", "jihua": 64, "luqu": 64, "min_score": 500 },
  { "name": "哈尔滨师范大学", "is_wenli": "文科", "jihua": 63, "luqu": 63, "min_score": 556 },
  { "name": "大连财经学院", "is_wenli": "文科", "jihua": 63, "luqu": 63, "min_score": 465 },
  { "name": "云南大学旅游文化学院", "is_wenli": "文科", "jihua": 61, "luqu": 61, "min_score": 470 },
  { "name": "湖北经济学院法商学院", "is_wenli": "文科", "jihua": 60, "luqu": 60, "min_score": 488 },
  { "name": "南京师范大学泰州学院", "is_wenli": "文科", "jihua": 60, "luqu": 60, "min_score": 513 },
  { "name": "哈尔滨学院", "is_wenli": "文科", "jihua": 58, "luqu": 59, "min_score": 533 },
  { "name": "平顶山学院[护理]", "is_wenli": "文科", "jihua": 59, "luqu": 59, "min_score": 515 },
  { "name": "北京科技大学天津学院", "is_wenli": "文科", "jihua": 42, "luqu": 59, "min_score": 505 },
  { "name": "长春师范大学", "is_wenli": "文科", "jihua": 55, "luqu": 55, "min_score": 551 },
  { "name": "闽江学院", "is_wenli": "文科", "jihua": 55, "luqu": 55, "min_score": 546 },
  { "name": "河南财经政法大学(与俄罗斯人民友谊大学合办)", "is_wenli": "文科", "jihua": 55, "luqu": 55, "min_score": 554 },
  { "name": "三江学院", "is_wenli": "文科", "jihua": 55, "luqu": 55, "min_score": 510 },
  { "name": "大庆师范学院", "is_wenli": "文科", "jihua": 54, "luqu": 54, "min_score": 518 },
  { "name": "新疆财经大学", "is_wenli": "文科", "jihua": 54, "luqu": 54, "min_score": 536 },
  { "name": "中原工学院", "is_wenli": "文科", "jihua": 53, "luqu": 53, "min_score": 555 },
  { "name": "青岛滨海学院", "is_wenli": "文科", "jihua": 53, "luqu": 53, "min_score": 478 },
  { "name": "西安翻译学院", "is_wenli": "文科", "jihua": 53, "luqu": 53, "min_score": 487 },
  { "name": "新疆农业大学", "is_wenli": "文科", "jihua": 52, "luqu": 52, "min_score": 512 },
  { "name": "辽宁中医药大学杏林学院", "is_wenli": "文科", "jihua": 52, "luqu": 52, "min_score": 493 },
  { "name": "福建江夏学院", "is_wenli": "文科", "jihua": 52, "luqu": 52, "min_score": 543 },
  { "name": "南通理工学院", "is_wenli": "文科", "jihua": 50, "luqu": 52, "min_score": 472 },
  { "name": "渤海大学", "is_wenli": "文科", "jihua": 51, "luqu": 51, "min_score": 555 },
  { "name": "江苏师范大学科文学院", "is_wenli": "文科", "jihua": 51, "luqu": 51, "min_score": 510 },
  { "name": "长春大学旅游学院", "is_wenli": "文科", "jihua": 51, "luqu": 51, "min_score": 472 },
  { "name": "河南中医药大学[护理]", "is_wenli": "文科", "jihua": 50, "luqu": 50, "min_score": 521 },
  { "name": "新乡医学院(中外课程合作)", "is_wenli": "文科", "jihua": 50, "luqu": 50, "min_score": 527 },
  { "name": "河南警察学院", "is_wenli": "文科", "jihua": 50, "luqu": 50, "min_score": 550 },
  { "name": "天津大学仁爱学院", "is_wenli": "文科", "jihua": 50, "luqu": 50, "min_score": 508 },
  { "name": "黑龙江外国语学院", "is_wenli": "文科", "jihua": 50, "luqu": 50, "min_score": 468 },
  { "name": "黑龙江东方学院", "is_wenli": "文科", "jihua": 50, "luqu": 50, "min_score": 471 },
  { "name": "西南民族大学[仅招少数民族]", "is_wenli": "文科", "jihua": 49, "luqu": 49, "min_score": 512 },
  { "name": "白城师范学院", "is_wenli": "文科", "jihua": 49, "luqu": 49, "min_score": 519 },
  { "name": "黄淮学院(中外合作办学)", "is_wenli": "文科", "jihua": 49, "luqu": 49, "min_score": 509 },
  { "name": "长江大学工程技术学院", "is_wenli": "文科", "jihua": 49, "luqu": 49, "min_score": 484 },
  { "name": "扬州大学广陵学院", "is_wenli": "文科", "jihua": 48, "luqu": 48, "min_score": 515 },
  { "name": "沈阳城市学院(原沈阳大学科技工程学院)", "is_wenli": "文科", "jihua": 42, "luqu": 46, "min_score": 465 },
  { "name": "武汉东湖学院", "is_wenli": "文科", "jihua": 41, "luqu": 46, "min_score": 501 },
  { "name": "郑州科技学院[较高收费]", "is_wenli": "文科", "jihua": 45, "luqu": 45, "min_score": 465 },
  { "name": "长春工业大学人文信息学院", "is_wenli": "文科", "jihua": 45, "luqu": 45, "min_score": 476 },
  { "name": "上海外国语大学贤达经济人文学院", "is_wenli": "文科", "jihua": 45, "luqu": 45, "min_score": 465 },
  { "name": "浙江树人学院", "is_wenli": "文科", "jihua": 44, "luqu": 44, "min_score": 475 },
  { "name": "西安欧亚学院", "is_wenli": "文科", "jihua": 44, "luqu": 44, "min_score": 480 },
  { "name": "合肥师范学院", "is_wenli": "文科", "jihua": 43, "luqu": 43, "min_score": 550 },
  { "name": "福建工程学院", "is_wenli": "文科", "jihua": 35, "luqu": 43, "min_score": 548 },
  { "name": "青岛工学院", "is_wenli": "文科", "jihua": 43, "luqu": 43, "min_score": 470 },
  { "name": "天津外国语大学滨海外事学院", "is_wenli": "文科", "jihua": 43, "luqu": 43, "min_score": 509 },
  { "name": "哈尔滨石油学院", "is_wenli": "文科", "jihua": 29, "luqu": 43, "min_score": 467 },
  { "name": "南京特殊教育师范学院", "is_wenli": "文科", "jihua": 43, "luqu": 43, "min_score": 528 },
  { "name": "西安外事学院", "is_wenli": "文科", "jihua": 43, "luqu": 43, "min_score": 490 },
  { "name": "温州商学院", "is_wenli": "文科", "jihua": 42, "luqu": 42, "min_score": 465 },
  { "name": "南京师范大学中北学院", "is_wenli": "文科", "jihua": 41, "luqu": 41, "min_score": 520 },
  { "name": "武汉学院", "is_wenli": "文科", "jihua": 41, "luqu": 41, "min_score": 512 },
  { "name": "西安思源学院", "is_wenli": "文科", "jihua": 39, "luqu": 41, "min_score": 486 },
  { "name": "黑龙江工业学院", "is_wenli": "文科", "jihua": 41, "luqu": 41, "min_score": 516 },
  { "name": "华北科技学院", "is_wenli": "文科", "jihua": 40, "luqu": 40, "min_score": 552 },
  { "name": "北华大学", "is_wenli": "文科", "jihua": 34, "luqu": 40, "min_score": 551 },
  { "name": "齐齐哈尔大学", "is_wenli": "文科", "jihua": 40, "luqu": 40, "min_score": 545 },
  { "name": "齐齐哈尔医学院", "is_wenli": "文科", "jihua": 40, "luqu": 40, "min_score": 523 },
  { "name": "河南中医药大学", "is_wenli": "文科", "jihua": 40, "luqu": 40, "min_score": 505 },
  { "name": "河南科技大学(与三门峡市政府联办应用工程学院,就读地在三门峡市)", "is_wenli": "文科", "jihua": 40, "luqu": 40, "min_score": 535 },
  { "name": "河南工业大学(与漯河市政府合办漯河工学院)(就读地在漯河)", "is_wenli": "文科", "jihua": 40, "luqu": 40, "min_score": 517 },
  { "name": "厦门大学嘉庚学院", "is_wenli": "文科", "jihua": 40, "luqu": 40, "min_score": 539 },
  { "name": "广西中医药大学赛恩斯新医药学院", "is_wenli": "文科", "jihua": 40, "luqu": 40, "min_score": 504 },
  { "name": "浙江万里学院", "is_wenli": "文科", "jihua": 40, "luqu": 40, "min_score": 514 },
  { "name": "辽宁对外经贸学院", "is_wenli": "文科", "jihua": 40, "luqu": 40, "min_score": 467 },
  { "name": "中国劳动关系学院", "is_wenli": "文科", "jihua": 38, "luqu": 39, "min_score": 557 },
  { "name": "天津商业大学宝德学院", "is_wenli": "文科", "jihua": 39, "luqu": 39, "min_score": 499 },
  { "name": "泰州学院", "is_wenli": "文科", "jihua": 39, "luqu": 39, "min_score": 535 },
  { "name": "哈尔滨信息工程学院", "is_wenli": "文科", "jihua": 39, "luqu": 39, "min_score": 467 },
  { "name": "宁波财经学院", "is_wenli": "文科", "jihua": 39, "luqu": 39, "min_score": 473 },
  { "name": "南京晓庄学院", "is_wenli": "文科", "jihua": 38, "luqu": 38, "min_score": 558 },
  { "name": "中山大学南方学院", "is_wenli": "文科", "jihua": 34, "luqu": 38, "min_score": 499 },
  { "name": "黑龙江财经学院", "is_wenli": "文科", "jihua": 38, "luqu": 38, "min_score": 468 },
  { "name": "厦门工学院", "is_wenli": "文科", "jihua": 38, "luqu": 38, "min_score": 485 },
  { "name": "浙江财经大学东方学院", "is_wenli": "文科", "jihua": 38, "luqu": 38, "min_score": 504 },
  { "name": "江苏师范大学", "is_wenli": "文科", "jihua": 37, "luqu": 37, "min_score": 566 },
  { "name": "浙江传媒学院", "is_wenli": "文科", "jihua": 37, "luqu": 37, "min_score": 566 },
  { "name": "中国矿业大学银川学院", "is_wenli": "文科", "jihua": 37, "luqu": 37, "min_score": 466 },
  { "name": "山西应用科技学院", "is_wenli": "文科", "jihua": 37, "luqu": 37, "min_score": 465 },
  { "name": "沈阳大学", "is_wenli": "文科", "jihua": 36, "luqu": 36, "min_score": 558 },
  { "name": "吉林工程技术师范学院", "is_wenli": "文科", "jihua": 35, "luqu": 36, "min_score": 518 },
  { "name": "江苏第二师范学院", "is_wenli": "文科", "jihua": 36, "luqu": 36, "min_score": 557 },
  { "name": "云南大学滇池学院", "is_wenli": "文科", "jihua": 36, "luqu": 36, "min_score": 499 },
  { "name": "湖南涉外经济学院", "is_wenli": "文科", "jihua": 36, "luqu": 36, "min_score": 479 },
  { "name": "西安交通工程学院", "is_wenli": "文科", "jihua": 36, "luqu": 36, "min_score": 475 },
  { "name": "西藏民族大学(原西藏民族学院)", "is_wenli": "文科", "jihua": 35, "luqu": 35, "min_score": 534 },
  { "name": "防灾科技学院", "is_wenli": "文科", "jihua": 35, "luqu": 35, "min_score": 543 },
  { "name": "龙岩学院", "is_wenli": "文科", "jihua": 35, "luqu": 35, "min_score": 533 },
  { "name": "徐州工程学院", "is_wenli": "文科", "jihua": 35, "luqu": 35, "min_score": 547 },
  { "name": "吉林工商学院", "is_wenli": "文科", "jihua": 35, "luqu": 35, "min_score": 541 },
  { "name": "哈尔滨广厦学院", "is_wenli": "文科", "jihua": 35, "luqu": 35, "min_score": 465 },
  { "name": "广西大学行健文理学院", "is_wenli": "文科", "jihua": 35, "luqu": 35, "min_score": 492 },
  { "name": "苏州大学应用技术学院", "is_wenli": "文科", "jihua": 34, "luqu": 34, "min_score": 512 },
  { "name": "重庆师范大学涉外商贸学院", "is_wenli": "文科", "jihua": 34, "luqu": 34, "min_score": 504 },
  { "name": "大连东软信息学院", "is_wenli": "文科", "jihua": 34, "luqu": 34, "min_score": 465 },
  { "name": "西安汽车职业大学(原西安汽车科技职业学院)", "is_wenli": "文科", "jihua": 34, "luqu": 34, "min_score": 465 },
  { "name": "西北民族大学[仅招少数民族]", "is_wenli": "文科", "jihua": 29, "luqu": 33, "min_score": 518 },
  { "name": "辽宁师范大学", "is_wenli": "文科", "jihua": 33, "luqu": 33, "min_score": 563 },
  { "name": "沈阳师范大学", "is_wenli": "文科", "jihua": 33, "luqu": 33, "min_score": 556 },
  { "name": "吉林师范大学", "is_wenli": "文科", "jihua": 33, "luqu": 33, "min_score": 556 },
  { "name": "中原工学院(中外合作办学)[异地校区]", "is_wenli": "文科", "jihua": 33, "luqu": 33, "min_score": 469 },
  { "name": "山东财经大学燕山学院", "is_wenli": "文科", "jihua": 33, "luqu": 33, "min_score": 492 },
  { "name": "大连科技学院", "is_wenli": "文科", "jihua": 33, "luqu": 33, "min_score": 465 },
  { "name": "绍兴文理学院元培学院", "is_wenli": "文科", "jihua": 33, "luqu": 33, "min_score": 484 },
  { "name": "琼台师范学院", "is_wenli": "文科", "jihua": 32, "luqu": 33, "min_score": 526 },
  { "name": "鞍山师范学院", "is_wenli": "文科", "jihua": 32, "luqu": 32, "min_score": 531 },
  { "name": "湖北师范大学", "is_wenli": "文科", "jihua": 31, "luqu": 32, "min_score": 560 },
  { "name": "浙江外国语学院", "is_wenli": "文科", "jihua": 32, "luqu": 32, "min_score": 564 },
  { "name": "中国矿业大学徐海学院", "is_wenli": "文科", "jihua": 32, "luqu": 32, "min_score": 509 },
  { "name": "汉口学院", "is_wenli": "文科", "jihua": 20, "luqu": 32, "min_score": 499 },
  { "name": "陕西科技大学镐京学院", "is_wenli": "文科", "jihua": 32, "luqu": 32, "min_score": 469 },
  { "name": "宁德师范学院", "is_wenli": "文科", "jihua": 32, "luqu": 32, "min_score": 527 },
  { "name": "天津天狮学院", "is_wenli": "文科", "jihua": 30, "luqu": 32, "min_score": 494 },
  { "name": "山东现代学院", "is_wenli": "文科", "jihua": 32, "luqu": 32, "min_score": 474 },
  { "name": "福州外语外贸学院", "is_wenli": "文科", "jihua": 32, "luqu": 32, "min_score": 474 },
  { "name": "内蒙古师范大学", "is_wenli": "文科", "jihua": 31, "luqu": 31, "min_score": 543 },
  { "name": "长春大学", "is_wenli": "文科", "jihua": 31, "luqu": 31, "min_score": 554 },
  { "name": "莆田学院", "is_wenli": "文科", "jihua": 31, "luqu": 31, "min_score": 541 },
  { "name": "九江学院", "is_wenli": "文科", "jihua": 31, "luqu": 31, "min_score": 545 },
  { "name": "西藏大学", "is_wenli": "文科", "jihua": 31, "luqu": 31, "min_score": 552 },
  { "name": "齐鲁理工学院(原曲阜师范大学杏坛学院)", "is_wenli": "文科", "jihua": 31, "luqu": 31, "min_score": 472 },
  { "name": "山东财经大学东方学院", "is_wenli": "文科", "jihua": 31, "luqu": 31, "min_score": 498 },
  { "name": "西安工商学院(原西安工业大学北方信息工程学院)", "is_wenli": "文科", "jihua": 31, "luqu": 31, "min_score": 467 },
  { "name": "广东理工学院", "is_wenli": "文科", "jihua": 31, "luqu": 31, "min_score": 467 },
  { "name": "淮阴师范学院", "is_wenli": "文科", "jihua": 30, "luqu": 30, "min_score": 543 },
  { "name": "宜春学院", "is_wenli": "文科", "jihua": 30, "luqu": 30, "min_score": 543 },
  { "name": "山东女子学院", "is_wenli": "文科", "jihua": 30, "luqu": 30, "min_score": 530 },
  { "name": "湖北文理学院", "is_wenli": "文科", "jihua": 30, "luqu": 30, "min_score": 551 },
  { "name": "黄冈师范学院", "is_wenli": "文科", "jihua": 30, "luqu": 30, "min_score": 543 },
  { "name": "湖北经济学院", "is_wenli": "文科", "jihua": 29, "luqu": 30, "min_score": 559 },
  { "name": "安阳工学院(中外合作办学)", "is_wenli": "文科", "jihua": 30, "luqu": 30, "min_score": 487 },
  { "name": "南通大学杏林学院", "is_wenli": "文科", "jihua": 30, "luqu": 30, "min_score": 504 },
  { "name": "四川外国语大学重庆南方翻译学院", "is_wenli": "文科", "jihua": 29, "luqu": 30, "min_score": 512 },
  { "name": "武昌工学院", "is_wenli": "文科", "jihua": 30, "luqu": 30, "min_score": 490 },
  { "name": "兰州财经大学长青学院", "is_wenli": "文科", "jihua": 30, "luqu": 30, "min_score": 490 },
  { "name": "新疆理工学院(原新疆大学科学技术学院)", "is_wenli": "文科", "jihua": 30, "luqu": 30, "min_score": 508 },
  { "name": "西安培华学院", "is_wenli": "文科", "jihua": 30, "luqu": 30, "min_score": 498 },
  { "name": "广东科技学院", "is_wenli": "文科", "jihua": 30, "luqu": 30, "min_score": 466 },
  { "name": "广西外国语学院", "is_wenli": "文科", "jihua": 30, "luqu": 30, "min_score": 483 },
  { "name": "泉州师范学院", "is_wenli": "文科", "jihua": 29, "luqu": 29, "min_score": 547 },
  { "name": "电子科技大学中山学院", "is_wenli": "文科", "jihua": 27, "luqu": 29, "min_score": 500 },
  { "name": "河北师范大学汇华学院", "is_wenli": "文科", "jihua": 29, "luqu": 29, "min_score": 516 },
  { "name": "济南大学泉城学院", "is_wenli": "文科", "jihua": 29, "luqu": 29, "min_score": 492 },
  { "name": "浙江越秀外国语学院", "is_wenli": "文科", "jihua": 29, "luqu": 29, "min_score": 484 },
  { "name": "上海立信会计金融学院", "is_wenli": "文科", "jihua": 28, "luqu": 28, "min_score": 571 },
  { "name": "桂林理工大学", "is_wenli": "文科", "jihua": 28, "luqu": 28, "min_score": 549 },
  { "name": "云南财经大学", "is_wenli": "文科", "jihua": 28, "luqu": 28, "min_score": 553 },
  { "name": "三明学院", "is_wenli": "文科", "jihua": 28, "luqu": 28, "min_score": 535 },
  { "name": "山东农业工程学院", "is_wenli": "文科", "jihua": 28, "luqu": 28, "min_score": 531 },
  { "name": "河南牧业经济学院[较高收费]", "is_wenli": "文科", "jihua": 27, "luqu": 28, "min_score": 520 },
  { "name": "中国石油大学胜利学院", "is_wenli": "文科", "jihua": 28, "luqu": 28, "min_score": 509 },
  { "name": "聊城大学东昌学院", "is_wenli": "文科", "jihua": 28, "luqu": 28, "min_score": 503 },
  { "name": "阳光学院(原福州大学阳光学院)", "is_wenli": "文科", "jihua": 25, "luqu": 28, "min_score": 468 },
  { "name": "武汉文理学院(原江汉大学文理学院)", "is_wenli": "文科", "jihua": 28, "luqu": 28, "min_score": 505 },
  { "name": "桂林理工大学博文管理学院", "is_wenli": "文科", "jihua": 28, "luqu": 28, "min_score": 478 },
  { "name": "汉江师范学院", "is_wenli": "文科", "jihua": 26, "luqu": 28, "min_score": 532 },
  { "name": "上海第二工业大学", "is_wenli": "文科", "jihua": 28, "luqu": 28, "min_score": 560 },
  { "name": "海口经济学院", "is_wenli": "文科", "jihua": 14, "luqu": 28, "min_score": 466 },
  { "name": "内蒙古民族大学", "is_wenli": "文科", "jihua": 27, "luqu": 27, "min_score": 533 },
  { "name": "华北理工大学冀唐学院", "is_wenli": "文科", "jihua": 27, "luqu": 27, "min_score": 516 },
  { "name": "河北地质大学华信学院", "is_wenli": "文科", "jihua": 27, "luqu": 27, "min_score": 495 },
  { "name": "北京邮电大学世纪学院", "is_wenli": "文科", "jihua": 29, "luqu": 27, "min_score": 467 },
  { "name": "武夷学院", "is_wenli": "文科", "jihua": 27, "luqu": 27, "min_score": 529 },
  { "name": "北京城市学院", "is_wenli": "文科", "jihua": 21, "luqu": 27, "min_score": 509 },
  { "name": "青岛恒星科技学院", "is_wenli": "文科", "jihua": 25, "luqu": 27, "min_score": 466 },
  { "name": "辽东学院", "is_wenli": "文科", "jihua": 26, "luqu": 26, "min_score": 525 },
  { "name": "通化师范学院", "is_wenli": "文科", "jihua": 26, "luqu": 26, "min_score": 523 },
  { "name": "赣南师范大学", "is_wenli": "文科", "jihua": 26, "luqu": 26, "min_score": 548 },
  { "name": "中山大学新华学院", "is_wenli": "文科", "jihua": 23, "luqu": 26, "min_score": 502 },
  { "name": "大连医科大学中山学院", "is_wenli": "文科", "jihua": 26, "luqu": 26, "min_score": 482 },
  { "name": "长春财经学院", "is_wenli": "文科", "jihua": 26, "luqu": 26, "min_score": 503 },
  { "name": "四川外国语大学成都学院", "is_wenli": "文科", "jihua": 25, "luqu": 26, "min_score": 525 },
  { "name": "南昌理工学院", "is_wenli": "文科", "jihua": 26, "luqu": 26, "min_score": 483 },
  { "name": "天津商业大学", "is_wenli": "文科", "jihua": 25, "luqu": 25, "min_score": 531 },
  { "name": "河北经贸大学", "is_wenli": "文科", "jihua": 25, "luqu": 25, "min_score": 561 },
  { "name": "大连大学", "is_wenli": "文科", "jihua": 25, "luqu": 25, "min_score": 561 },
  { "name": "牡丹江师范学院", "is_wenli": "文科", "jihua": 25, "luqu": 25, "min_score": 528 },
  { "name": "武汉轻工大学[较高收费]", "is_wenli": "文科", "jihua": 25, "luqu": 25, "min_score": 524 },
  { "name": "湖南女子学院", "is_wenli": "文科", "jihua": 25, "luqu": 25, "min_score": 528 },
  { "name": "江苏大学京江学院", "is_wenli": "文科", "jihua": 25, "luqu": 25, "min_score": 513 },
  { "name": "柳州工学院(原广西科技大学鹿山学院)", "is_wenli": "文科", "jihua": 25, "luqu": 25, "min_score": 476 },
  { "name": "西安财经大学行知学院", "is_wenli": "文科", "jihua": 25, "luqu": 25, "min_score": 485 },
  { "name": "闽南理工学院", "is_wenli": "文科", "jihua": 25, "luqu": 25, "min_score": 468 },
  { "name": "安徽文达信息工程学院", "is_wenli": "文科", "jihua": 25, "luqu": 25, "min_score": 469 },
  { "name": "海南大学", "is_wenli": "文科", "jihua": 24, "luqu": 24, "min_score": 571 },
  { "name": "天津农学院", "is_wenli": "文科", "jihua": 24, "luqu": 24, "min_score": 552 },
  { "name": "运城学院", "is_wenli": "文科", "jihua": 24, "luqu": 24, "min_score": 530 },
  { "name": "常熟理工学院", "is_wenli": "文科", "jihua": 24, "luqu": 24, "min_score": 553 },
  { "name": "山东工商学院", "is_wenli": "文科", "jihua": 24, "luqu": 24, "min_score": 548 },
  { "name": "湖北科技学院", "is_wenli": "文科", "jihua": 24, "luqu": 24, "min_score": 547 },
  { "name": "重庆人文科技学院", "is_wenli": "文科", "jihua": 24, "luqu": 24, "min_score": 504 },
  { "name": "四川大学锦城学院", "is_wenli": "文科", "jihua": 23, "luqu": 24, "min_score": 516 },
  { "name": "福建师范大学协和学院", "is_wenli": "文科", "jihua": 24, "luqu": 24, "min_score": 499 },
  { "name": "广西民族大学相思湖学院", "is_wenli": "文科", "jihua": 24, "luqu": 24, "min_score": 483 },
  { "name": "南宁师范大学师园学院(原广西师范学院师园学院)", "is_wenli": "文科", "jihua": 24, "luqu": 24, "min_score": 498 },
  { "name": "北京理工大学珠海学院", "is_wenli": "文科", "jihua": 24, "luqu": 24, "min_score": 524 },
  { "name": "成都体育学院", "is_wenli": "文科", "jihua": 23, "luqu": 23, "min_score": 546 },
  { "name": "黑河学院", "is_wenli": "文科", "jihua": 23, "luqu": 23, "min_score": 518 },
  { "name": "吉林农业科技学院", "is_wenli": "文科", "jihua": 23, "luqu": 23, "min_score": 526 },
  { "name": "湖北民族大学", "is_wenli": "文科", "jihua": 23, "luqu": 23, "min_score": 547 },
  { "name": "陕西学前师范学院", "is_wenli": "文科", "jihua": 23, "luqu": 23, "min_score": 537 },
  { "name": "山西农业大学信息学院", "is_wenli": "文科", "jihua": 23, "luqu": 23, "min_score": 488 },
  { "name": "内蒙古鸿德文理学院(原内蒙古师范大学鸿德学院)", "is_wenli": "文科", "jihua": 23, "luqu": 23, "min_score": 474 },
  { "name": "北京理工大学珠海学院[较高收费]", "is_wenli": "文科", "jihua": 17, "luqu": 23, "min_score": 496 },
  { "name": "广西科技师范学院(原柳州师范高等专科学校)", "is_wenli": "文科", "jihua": 23, "luqu": 23, "min_score": 518 },
  { "name": "中华女子学院", "is_wenli": "文科", "jihua": 22, "luqu": 22, "min_score": 557 },
  { "name": "黑龙江八一农垦大学", "is_wenli": "文科", "jihua": 22, "luqu": 22, "min_score": 540 },
  { "name": "江苏海洋大学(原淮海工学院)", "is_wenli": "文科", "jihua": 22, "luqu": 22, "min_score": 556 },
  { "name": "淮阴工学院", "is_wenli": "文科", "jihua": 22, "luqu": 22, "min_score": 544 },
  { "name": "阜阳师范大学(原阜阳师范学院)", "is_wenli": "文科", "jihua": 22, "luqu": 22, "min_score": 548 },
  { "name": "广西师范大学", "is_wenli": "文科", "jihua": 22, "luqu": 22, "min_score": 560 },
  { "name": "大理大学", "is_wenli": "文科", "jihua": 22, "luqu": 22, "min_score": 551 },
  { "name": "兰州交通大学", "is_wenli": "文科", "jihua": 21, "luqu": 22, "min_score": 557 },
  { "name": "厦门理工学院", "is_wenli": "文科", "jihua": 22, "luqu": 22, "min_score": 559 },
  { "name": "中原工学院(中外合作办学)", "is_wenli": "文科", "jihua": 22, "luqu": 22, "min_score": 526 },
  { "name": "西南财经大学天府学院", "is_wenli": "文科", "jihua": 22, "luqu": 22, "min_score": 510 },
  { "name": "重庆工商大学融智学院", "is_wenli": "文科", "jihua": 20, "luqu": 22, "min_score": 506 },
  { "name": "武昌首义学院(原华中科技大学武昌分校)", "is_wenli": "文科", "jihua": 22, "luqu": 22, "min_score": 516 },
  { "name": "吉林大学珠海学院", "is_wenli": "文科", "jihua": 21, "luqu": 22, "min_score": 527 },
  { "name": "杭州师范大学钱江学院", "is_wenli": "文科", "jihua": 22, "luqu": 22, "min_score": 498 },
  { "name": "延安大学西安创新学院", "is_wenli": "文科", "jihua": 22, "luqu": 22, "min_score": 470 },
  { "name": "潍坊科技学院", "is_wenli": "文科", "jihua": 22, "luqu": 22, "min_score": 483 },
  { "name": "齐齐哈尔工程学院", "is_wenli": "文科", "jihua": 22, "luqu": 22, "min_score": 465 },
  { "name": "山西财经大学", "is_wenli": "文科", "jihua": 21, "luqu": 21, "min_score": 559 },
  { "name": "上饶师范学院", "is_wenli": "文科", "jihua": 21, "luqu": 21, "min_score": 535 },
  { "name": "金陵科技学院", "is_wenli": "文科", "jihua": 21, "luqu": 21, "min_score": 556 },
  { "name": "文华学院(原华中科技大学文华学院)", "is_wenli": "文科", "jihua": 21, "luqu": 21, "min_score": 515 },
  { "name": "黑龙江工程学院昆仑旅游学院", "is_wenli": "文科", "jihua": 21, "luqu": 21, "min_score": 465 },
  { "name": "长江大学文理学院", "is_wenli": "文科", "jihua": 21, "luqu": 21, "min_score": 504 },
  { "name": "三峡大学科技学院", "is_wenli": "文科", "jihua": 21, "luqu": 21, "min_score": 504 },
  { "name": "重庆邮电大学移通学院", "is_wenli": "文科", "jihua": 21, "luqu": 21, "min_score": 505 },
  { "name": "浙江工商大学杭州商学院", "is_wenli": "文科", "jihua": 21, "luqu": 21, "min_score": 512 },
  { "name": "华南农业大学珠江学院", "is_wenli": "文科", "jihua": 2, "luqu": 21, "min_score": 465 },
  { "name": "湖北第二师范学院", "is_wenli": "文科", "jihua": 18, "luqu": 21, "min_score": 554 },
  { "name": "山西工商学院", "is_wenli": "文科", "jihua": 21, "luqu": 21, "min_score": 483 },
  { "name": "邯郸学院", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 526 },
  { "name": "内蒙古财经大学", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 547 },
  { "name": "绥化学院", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 517 },
  { "name": "盐城师范学院", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 549 },
  { "name": "宁波工程学院", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 558 },
  { "name": "绍兴文理学院", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 557 },
  { "name": "丽水学院", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 549 },
  { "name": "福建农林大学", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 538 },
  { "name": "潍坊学院", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 546 },
  { "name": "山东管理学院", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 552 },
  { "name": "韶关学院", "is_wenli": "文科", "jihua": 17, "luqu": 20, "min_score": 527 },
  { "name": "玉林师范学院", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 520 },
  { "name": "曲靖师范学院", "is_wenli": "文科", "jihua": 18, "luqu": 20, "min_score": 533 },
  { "name": "西北师范大学", "is_wenli": "文科", "jihua": 19, "luqu": 20, "min_score": 557 },
  { "name": "红河学院", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 519 },
  { "name": "山西传媒学院", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 539 },
  { "name": "商丘师范学院(与商丘职业技术学院联办)(就读在商丘职业技术学院)", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 512 },
  { "name": "南阳理工学院(与南阳医学高等专科学校联办,就读在南阳医专)", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 547 },
  { "name": "洛阳理工学院(与河南机电职业学院联办)(就读在河南机电职业学院)", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 508 },
  { "name": "天津理工大学中环信息学院", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 502 },
  { "name": "辽宁财贸学院", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 465 },
  { "name": "苏州科技大学天平学院", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 510 },
  { "name": "大连工业大学艺术与信息工程学院", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 465 },
  { "name": "武汉晴川学院(原武汉大学珞珈学院)", "is_wenli": "文科", "jihua": 14, "luqu": 20, "min_score": 504 },
  { "name": "沈阳科技学院", "is_wenli": "文科", "jihua": 16, "luqu": 20, "min_score": 465 },
  { "name": "淮北师范大学信息学院", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 511 },
  { "name": "武汉科技大学城市学院", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 500 },
  { "name": "武汉工程科技学院(原中国地质大学江城学院)", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 493 },
  { "name": "新疆农业大学科学技术学院", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 468 },
  { "name": "山东外国语职业技术大学(原山东外国语职业学院)", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 465 },
  { "name": "陕西服装工程学院", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 466 },
  { "name": "泉州职业技术大学(原泉州理工职业学院)", "is_wenli": "文科", "jihua": 20, "luqu": 20, "min_score": 465 },
  { "name": "北方民族大学[仅招少数民族]", "is_wenli": "文科", "jihua": 18, "luqu": 19, "min_score": 514 },
  { "name": "内蒙古科技大学", "is_wenli": "文科", "jihua": 19, "luqu": 19, "min_score": 545 },
  { "name": "黑龙江大学", "is_wenli": "文科", "jihua": 19, "luqu": 19, "min_score": 560 },
  { "name": "江西中医药大学", "is_wenli": "文科", "jihua": 4, "luqu": 19, "min_score": 542 },
  { "name": "临沂大学", "is_wenli": "文科", "jihua": 19, "luqu": 19, "min_score": 554 },
  { "name": "桂林电子科技大学", "is_wenli": "文科", "jihua": 19, "luqu": 19, "min_score": 555 },
  { "name": "吉林建筑科技学院", "is_wenli": "文科", "jihua": 19, "luqu": 19, "min_score": 474 },
  { "name": "吉林师范大学博达学院", "is_wenli": "文科", "jihua": 19, "luqu": 19, "min_score": 503 },
  { "name": "湖北医药学院药护学院", "is_wenli": "文科", "jihua": 19, "luqu": 19, "min_score": 500 },
  { "name": "浙江水利水电学院(原浙江水利水电专科学校)", "is_wenli": "文科", "jihua": 19, "luqu": 19, "min_score": 552 },
  { "name": "山东英才学院", "is_wenli": "文科", "jihua": 19, "luqu": 19, "min_score": 465 },
  { "name": "辽宁科技学院", "is_wenli": "文科", "jihua": 18, "luqu": 18, "min_score": 529 },
  { "name": "长春工业大学", "is_wenli": "文科", "jihua": 18, "luqu": 18, "min_score": 552 },
  { "name": "山东政法学院", "is_wenli": "文科", "jihua": 18, "luqu": 18, "min_score": 560 },
  { "name": "延安大学", "is_wenli": "文科", "jihua": 18, "luqu": 18, "min_score": 555 },
  { "name": "兰州财经大学", "is_wenli": "文科", "jihua": 18, "luqu": 18, "min_score": 555 },
  { "name": "百色学院", "is_wenli": "文科", "jihua": 18, "luqu": 18, "min_score": 518 },
  { "name": "江西师范大学科学技术学院", "is_wenli": "文科", "jihua": 18, "luqu": 18, "min_score": 506 },
  { "name": "长春理工大学光电信息学院", "is_wenli": "文科", "jihua": 18, "luqu": 18, "min_score": 474 },
  { "name": "东北师范大学人文学院", "is_wenli": "文科", "jihua": 18, "luqu": 18, "min_score": 507 },
  { "name": "长春建筑学院", "is_wenli": "文科", "jihua": 18, "luqu": 18, "min_score": 481 },
  { "name": "湖北工业大学工程技术学院", "is_wenli": "文科", "jihua": 18, "luqu": 18, "min_score": 505 },
  { "name": "湖北工程学院新技术学院", "is_wenli": "文科", "jihua": 18, "luqu": 18, "min_score": 504 },
  { "name": "广西师范大学漓江学院", "is_wenli": "文科", "jihua": 18, "luqu": 18, "min_score": 503 },
  { "name": "浙江师范大学行知学院", "is_wenli": "文科", "jihua": 18, "luqu": 18, "min_score": 505 },
  { "name": "西京学院", "is_wenli": "文科", "jihua": 18, "luqu": 18, "min_score": 489 },
  { "name": "南昌工学院", "is_wenli": "文科", "jihua": 8, "luqu": 18, "min_score": 465 },
  { "name": "成都东软学院", "is_wenli": "文科", "jihua": 18, "luqu": 18, "min_score": 497 },
  { "name": "哈尔滨医科大学", "is_wenli": "文科", "jihua": 17, "luqu": 17, "min_score": 550 },
  { "name": "太原师范学院", "is_wenli": "文科", "jihua": 17, "luqu": 17, "min_score": 548 },
  { "name": "内蒙古科技大学包头师范学院", "is_wenli": "文科", "jihua": 17, "luqu": 17, "min_score": 518 },
  { "name": "重庆三峡学院", "is_wenli": "文科", "jihua": 17, "luqu": 17, "min_score": 550 },
  { "name": "重庆理工大学", "is_wenli": "文科", "jihua": 17, "luqu": 17, "min_score": 565 },
  { "name": "云南师范大学", "is_wenli": "文科", "jihua": 17, "luqu": 17, "min_score": 552 },
  { "name": "渭南师范学院", "is_wenli": "文科", "jihua": 17, "luqu": 17, "min_score": 531 },
  { "name": "辽宁理工学院", "is_wenli": "文科", "jihua": 14, "luqu": 17, "min_score": 465 },
  { "name": "湖北商贸学院", "is_wenli": "文科", "jihua": 10, "luqu": 17, "min_score": 490 },
  { "name": "武汉纺织大学外经贸学院", "is_wenli": "文科", "jihua": 17, "luqu": 17, "min_score": 494 },
  { "name": "浙江农林大学暨阳学院(原浙江农林大学天目学院)", "is_wenli": "文科", "jihua": 17, "luqu": 17, "min_score": 494 },
  { "name": "集美大学诚毅学院", "is_wenli": "文科", "jihua": 17, "luqu": 17, "min_score": 528 },
  { "name": "青岛理工大学琴岛学院", "is_wenli": "文科", "jihua": 17, "luqu": 17, "min_score": 497 },
  { "name": "集宁师范学院", "is_wenli": "文科", "jihua": 17, "luqu": 17, "min_score": 516 },
  { "name": "福建商学院", "is_wenli": "文科", "jihua": 17, "luqu": 17, "min_score": 545 },
  { "name": "四川旅游学院", "is_wenli": "文科", "jihua": 17, "luqu": 17, "min_score": 542 },
  { "name": "荆楚理工学院", "is_wenli": "文科", "jihua": 17, "luqu": 17, "min_score": 538 },
  { "name": "辽宁传媒学院", "is_wenli": "文科", "jihua": 17, "luqu": 17, "min_score": 465 },
  { "name": "太原学院", "is_wenli": "文科", "jihua": 17, "luqu": 17, "min_score": 533 },
  { "name": "北京联合大学", "is_wenli": "文科", "jihua": 16, "luqu": 16, "min_score": 566 },
  { "name": "黑龙江中医药大学[异地校区]", "is_wenli": "文科", "jihua": 16, "luqu": 16, "min_score": 535 },
  { "name": "太原科技大学", "is_wenli": "文科", "jihua": 16, "luqu": 16, "min_score": 556 },
  { "name": "河北地质大学", "is_wenli": "文科", "jihua": 16, "luqu": 16, "min_score": 558 },
  { "name": "河北地质大学", "is_wenli": "文科", "jihua": 16, "luqu": 16, "min_score": 558 },
  { "name": "山西中医药大学", "is_wenli": "文科", "jihua": 16, "luqu": 16, "min_score": 552 },
  { "name": "辽宁工业大学", "is_wenli": "文科", "jihua": 16, "luqu": 16, "min_score": 552 },
  { "name": "黑龙江工程学院", "is_wenli": "文科", "jihua": 16, "luqu": 16, "min_score": 534 },
  { "name": "江苏理工学院", "is_wenli": "文科", "jihua": 16, "luqu": 16, "min_score": 554 },
  { "name": "上海商学院", "is_wenli": "文科", "jihua": 16, "luqu": 16, "min_score": 563 },
  { "name": "河北金融学院", "is_wenli": "文科", "jihua": 16, "luqu": 16, "min_score": 555 },
  { "name": "闽南科技学院(原福建师范大学闽南科技学院)", "is_wenli": "文科", "jihua": 16, "luqu": 16, "min_score": 473 },
  { "name": "江西农业大学南昌商学院", "is_wenli": "文科", "jihua": 13, "luqu": 16, "min_score": 504 },
  { "name": "华东交通大学理工学院", "is_wenli": "文科", "jihua": 16, "luqu": 16, "min_score": 495 },
  { "name": "南昌大学共青学院", "is_wenli": "文科", "jihua": 6, "luqu": 16, "min_score": 496 },
  { "name": "湖北民族大学科技学院(原湖北民族学院科技学院)", "is_wenli": "文科", "jihua": 15, "luqu": 16, "min_score": 495 },
  { "name": "湖北师范大学文理学院", "is_wenli": "文科", "jihua": 16, "luqu": 16, "min_score": 511 },
  { "name": "长沙理工大学城南学院", "is_wenli": "文科", "jihua": 16, "luqu": 16, "min_score": 516 },
  { "name": "广西警察学院", "is_wenli": "文科", "jihua": 16, "luqu": 16, "min_score": 516 },
  { "name": "西安信息职业大学(原陕西电子科技职业学院)", "is_wenli": "文科", "jihua": 16, "luqu": 16, "min_score": 467 },
  { "name": "青岛黄海学院", "is_wenli": "文科", "jihua": 16, "luqu": 16, "min_score": 469 },
  { "name": "新疆天山职业技术大学(原新疆天山职业技术学院)", "is_wenli": "文科", "jihua": 16, "luqu": 16, "min_score": 466 },
  { "name": "石家庄学院", "is_wenli": "文科", "jihua": 15, "luqu": 15, "min_score": 543 },
  { "name": "巢湖学院", "is_wenli": "文科", "jihua": 15, "luqu": 15, "min_score": 540 },
  { "name": "福建农林大学[较高收费]", "is_wenli": "文科", "jihua": 15, "luqu": 15, "min_score": 540 },
  { "name": "海南医学院", "is_wenli": "文科", "jihua": 15, "luqu": 15, "min_score": 550 },
  { "name": "重庆第二师范学院", "is_wenli": "文科", "jihua": 15, "luqu": 15, "min_score": 552 },
  { "name": "西南林业大学", "is_wenli": "文科", "jihua": 15, "luqu": 15, "min_score": 553 },
  { "name": "南阳师范学院(中外合作办学)[较高收费]", "is_wenli": "文科", "jihua": 15, "luqu": 15, "min_score": 479 },
  { "name": "北京交通大学海滨学院", "is_wenli": "文科", "jihua": 15, "luqu": 15, "min_score": 509 },
  { "name": "常州大学怀德学院", "is_wenli": "文科", "jihua": 15, "luqu": 15, "min_score": 505 },
  { "name": "华北理工大学轻工学院", "is_wenli": "文科", "jihua": 15, "luqu": 15, "min_score": 499 },
  { "name": "青岛农业大学海都学院", "is_wenli": "文科", "jihua": 15, "luqu": 15, "min_score": 473 },
  { "name": "西安明德理工学院(原西北工业大学明德学院)", "is_wenli": "文科", "jihua": 9, "luqu": 15, "min_score": 488 },
  { "name": "福州大学至诚学院", "is_wenli": "文科", "jihua": 15, "luqu": 15, "min_score": 509 },
  { "name": "江西科技师范大学理工学院", "is_wenli": "文科", "jihua": 15, "luqu": 15, "min_score": 505 },
  { "name": "哈尔滨远东理工学院", "is_wenli": "文科", "jihua": 15, "luqu": 15, "min_score": 467 },
  { "name": "桂林电子科技大学信息科技学院", "is_wenli": "文科", "jihua": 15, "luqu": 15, "min_score": 484 },
  { "name": "南京理工大学紫金学院", "is_wenli": "文科", "jihua": 15, "luqu": 15, "min_score": 529 },
  { "name": "吕梁学院", "is_wenli": "文科", "jihua": 15, "luqu": 15, "min_score": 519 },
  { "name": "陕西国际商贸学院", "is_wenli": "文科", "jihua": 15, "luqu": 15, "min_score": 478 },
  { "name": "泉州信息工程学院", "is_wenli": "文科", "jihua": 15, "luqu": 15, "min_score": 470 },
  { "name": "潍坊医学院", "is_wenli": "文科", "jihua": 14, "luqu": 14, "min_score": 543 },
  { "name": "湖北理工学院", "is_wenli": "文科", "jihua": 14, "luqu": 14, "min_score": 518 },
  { "name": "南宁师范大学(原广西师范学院)", "is_wenli": "文科", "jihua": 14, "luqu": 14, "min_score": 552 },
  { "name": "贵州财经大学", "is_wenli": "文科", "jihua": 15, "luqu": 14, "min_score": 489 },
  { "name": "云南民族大学", "is_wenli": "文科", "jihua": 14, "luqu": 14, "min_score": 555 },
  { "name": "上海财经大学浙江学院", "is_wenli": "文科", "jihua": 14, "luqu": 14, "min_score": 520 },
  { "name": "南京医科大学康达学院", "is_wenli": "文科", "jihua": 14, "luqu": 14, "min_score": 465 },
  { "name": "沈阳城市建设学院(原沈阳建筑大学城市建设学院)", "is_wenli": "文科", "jihua": 14, "luqu": 14, "min_score": 465 },
  { "name": "武昌理工学院", "is_wenli": "文科", "jihua": 14, "luqu": 14, "min_score": 498 },
  { "name": "武汉华夏理工学院(原武汉理工大学华夏学院)", "is_wenli": "文科", "jihua": 14, "luqu": 14, "min_score": 498 },
  { "name": "四川传媒学院", "is_wenli": "文科", "jihua": 14, "luqu": 14, "min_score": 514 },
  { "name": "东莞理工学院城市学院", "is_wenli": "文科", "jihua": 14, "luqu": 14, "min_score": 473 },
  { "name": "云南师范大学文理学院", "is_wenli": "文科", "jihua": 14, "luqu": 14, "min_score": 494 },
  { "name": "湖南财政经济学院", "is_wenli": "文科", "jihua": 14, "luqu": 14, "min_score": 558 },
  { "name": "齐鲁医药学院", "is_wenli": "文科", "jihua": 14, "luqu": 14, "min_score": 517 },
  { "name": "北京服装学院", "is_wenli": "文科", "jihua": 12, "luqu": 13, "min_score": 557 },
  { "name": "沈阳理工大学", "is_wenli": "文科", "jihua": 13, "luqu": 13, "min_score": 554 },
  { "name": "吉林农业大学", "is_wenli": "文科", "jihua": 13, "luqu": 13, "min_score": 549 },
  { "name": "常州工学院", "is_wenli": "文科", "jihua": 13, "luqu": 13, "min_score": 550 },
  { "name": "淮北师范大学", "is_wenli": "文科", "jihua": 13, "luqu": 13, "min_score": 556 },
  { "name": "福建中医药大学", "is_wenli": "文科", "jihua": 13, "luqu": 13, "min_score": 557 },
  { "name": "江西农业大学", "is_wenli": "文科", "jihua": 13, "luqu": 13, "min_score": 534 },
  { "name": "湖南城市学院", "is_wenli": "文科", "jihua": 11, "luqu": 13, "min_score": 543 },
  { "name": "东华理工大学长江学院", "is_wenli": "文科", "jihua": 5, "luqu": 13, "min_score": 501 },
  { "name": "江西中医药大学科技学院", "is_wenli": "文科", "jihua": 12, "luqu": 13, "min_score": 517 },
  { "name": "江西财经大学现代经济管理学院", "is_wenli": "文科", "jihua": 13, "luqu": 13, "min_score": 512 },
  { "name": "内蒙古大学创业学院", "is_wenli": "文科", "jihua": 13, "luqu": 13, "min_score": 488 },
  { "name": "武汉工商学院", "is_wenli": "文科", "jihua": 13, "luqu": 13, "min_score": 497 },
  { "name": "云南艺术学院文华学院", "is_wenli": "文科", "jihua": 9, "luqu": 13, "min_score": 471 },
  { "name": "浙江工业大学之江学院", "is_wenli": "文科", "jihua": 13, "luqu": 13, "min_score": 505 },
  { "name": "西安建筑科技大学华清学院", "is_wenli": "文科", "jihua": 13, "luqu": 13, "min_score": 488 },
  { "name": "池州学院", "is_wenli": "文科", "jihua": 13, "luqu": 13, "min_score": 524 },
  { "name": "河套学院", "is_wenli": "文科", "jihua": 13, "luqu": 13, "min_score": 516 },
  { "name": "天津中德应用技术大学", "is_wenli": "文科", "jihua": 13, "luqu": 13, "min_score": 541 },
  { "name": "河北外国语学院", "is_wenli": "文科", "jihua": 13, "luqu": 13, "min_score": 500 },
  { "name": "北海艺术设计学院", "is_wenli": "文科", "jihua": 13, "luqu": 13, "min_score": 468 },
  { "name": "重庆机电职业技术大学(原重庆机电职业技术学院)", "is_wenli": "文科", "jihua": 13, "luqu": 13, "min_score": 470 },
  { "name": "天津职业技术师范大学", "is_wenli": "文科", "jihua": 12, "luqu": 12, "min_score": 554 },
  { "name": "山西师范大学", "is_wenli": "文科", "jihua": 12, "luqu": 12, "min_score": 559 },
  { "name": "嘉兴学院", "is_wenli": "文科", "jihua": 12, "luqu": 12, "min_score": 560 },
  { "name": "浙江海洋大学", "is_wenli": "文科", "jihua": 12, "luqu": 12, "min_score": 560 },
  { "name": "湖州师范学院", "is_wenli": "文科", "jihua": 12, "luqu": 12, "min_score": 554 },
  { "name": "湖北工程学院", "is_wenli": "文科", "jihua": 12, "luqu": 12, "min_score": 553 },
  { "name": "湖南人文科技学院", "is_wenli": "文科", "jihua": 12, "luqu": 12, "min_score": 536 },
  { "name": "惠州学院", "is_wenli": "文科", "jihua": 12, "luqu": 12, "min_score": 546 },
  { "name": "广东技术师范大学(原广东技术师范学院)", "is_wenli": "文科", "jihua": 10, "luqu": 12, "min_score": 555 },
  { "name": "广西民族大学", "is_wenli": "文科", "jihua": 11, "luqu": 12, "min_score": 549 },
  { "name": "绵阳师范学院", "is_wenli": "文科", "jihua": 12, "luqu": 12, "min_score": 537 },
  { "name": "河西学院", "is_wenli": "文科", "jihua": 12, "luqu": 12, "min_score": 521 },
  { "name": "昆明学院", "is_wenli": "文科", "jihua": 12, "luqu": 12, "min_score": 546 },
  { "name": "湖南科技学院", "is_wenli": "文科", "jihua": 11, "luqu": 12, "min_score": 542 },
  { "name": "榆林学院", "is_wenli": "文科", "jihua": 12, "luqu": 12, "min_score": 529 },
  { "name": "山西工程技术学院", "is_wenli": "文科", "jihua": 12, "luqu": 12, "min_score": 525 },
  { "name": "江苏科技大学苏州理工学院", "is_wenli": "文科", "jihua": 12, "luqu": 12, "min_score": 510 },
  { "name": "重庆大学城市科技学院", "is_wenli": "文科", "jihua": 12, "luqu": 12, "min_score": 508 },
  { "name": "福州工商学院(原福建农林大学东方学院)", "is_wenli": "文科", "jihua": 12, "luqu": 12, "min_score": 465 },
  { "name": "重庆工商大学派斯学院", "is_wenli": "文科", "jihua": 12, "luqu": 12, "min_score": 493 },
  { "name": "西北师范大学知行学院", "is_wenli": "文科", "jihua": 12, "luqu": 12, "min_score": 505 },
  { "name": "湖州师范学院求真学院", "is_wenli": "文科", "jihua": 12, "luqu": 12, "min_score": 493 },
  { "name": "广州大学华软软件学院", "is_wenli": "文科", "jihua": 12, "luqu": 12, "min_score": 484 },
  { "name": "运城职业技术大学(原运城职业技术学院)", "is_wenli": "文科", "jihua": 12, "luqu": 12, "min_score": 467 },
  { "name": "西北民族大学", "is_wenli": "文科", "jihua": 11, "luqu": 11, "min_score": 551 },
  { "name": "北华航天工业学院", "is_wenli": "文科", "jihua": 11, "luqu": 11, "min_score": 551 },
  { "name": "忻州师范学院", "is_wenli": "文科", "jihua": 11, "luqu": 11, "min_score": 532 },
  { "name": "沈阳化工大学", "is_wenli": "文科", "jihua": 11, "luqu": 11, "min_score": 551 },
  { "name": "嘉应学院", "is_wenli": "文科", "jihua": 11, "luqu": 11, "min_score": 531 },
  { "name": "广西财经学院", "is_wenli": "文科", "jihua": 11, "luqu": 11, "min_score": 546 },
  { "name": "咸阳师范学院", "is_wenli": "文科", "jihua": 11, "luqu": 11, "min_score": 547 },
  { "name": "长春光华学院", "is_wenli": "文科", "jihua": 11, "luqu": 11, "min_score": 500 },
  { "name": "中南林业科技大学涉外学院", "is_wenli": "文科", "jihua": 10, "luqu": 11, "min_score": 502 },
  { "name": "广东工业大学华立学院", "is_wenli": "文科", "jihua": 11, "luqu": 11, "min_score": 468 },
  { "name": "昆明理工大学津桥学院", "is_wenli": "文科", "jihua": 10, "luqu": 11, "min_score": 491 },
  { "name": "河北民族师范学院", "is_wenli": "文科", "jihua": 11, "luqu": 11, "min_score": 526 },
  { "name": "桂林航天工业学院", "is_wenli": "文科", "jihua": 11, "luqu": 11, "min_score": 537 },
  { "name": "安徽新华学院", "is_wenli": "文科", "jihua": 11, "luqu": 11, "min_score": 471 },
  { "name": "重庆工程学院", "is_wenli": "文科", "jihua": 11, "luqu": 11, "min_score": 489 },
  { "name": "广西医科大学", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 546 },
  { "name": "北方民族大学", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 550 },
  { "name": "北京农学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 558 },
  { "name": "天津城建大学", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 559 },
  { "name": "廊坊师范学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 541 },
  { "name": "唐山师范学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 533 },
  { "name": "山西农业大学", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 553 },
  { "name": "山西大同大学", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 552 },
  { "name": "辽宁师范大学[较高收费]", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 533 },
  { "name": "渤海大学[较高收费]", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 530 },
  { "name": "长春师范大学[较高收费]", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 516 },
  { "name": "长春师范大学[其他单列专业]", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 513 },
  { "name": "台州学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 553 },
  { "name": "衢州学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 541 },
  { "name": "安庆师范大学", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 550 },
  { "name": "铜陵学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 550 },
  { "name": "南昌师范学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 548 },
  { "name": "烟台大学", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 558 },
  { "name": "德州学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 532 },
  { "name": "武汉轻工大学", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 561 },
  { "name": "吉首大学", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 554 },
  { "name": "湘南学院", "is_wenli": "文科", "jihua": 8, "luqu": 10, "min_score": 543 },
  { "name": "黔南民族师范学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 517 },
  { "name": "西安文理学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 552 },
  { "name": "青海师范大学", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 546 },
  { "name": "河池学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 517 },
  { "name": "四川文理学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 528 },
  { "name": "福建技术师范学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 517 },
  { "name": "重庆科技学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 554 },
  { "name": "信阳师范学院[护理]", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 524 },
  { "name": "大连理工大学城市学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 465 },
  { "name": "东南大学成贤学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 517 },
  { "name": "南京工业大学浦江学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 512 },
  { "name": "南京邮电大学通达学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 518 },
  { "name": "广东财经大学华商学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 490 },
  { "name": "福建农林大学金山学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 484 },
  { "name": "江西理工大学应用科学学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 505 },
  { "name": "石家庄铁道大学四方学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 512 },
  { "name": "烟台大学文经学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 504 },
  { "name": "湖北大学知行学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 512 },
  { "name": "湖北汽车工业学院科技学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 479 },
  { "name": "青海大学昆仑学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 502 },
  { "name": "南京航空航天大学金城学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 511 },
  { "name": "宁波大学科学技术学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 503 },
  { "name": "温州大学瓯江学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 496 },
  { "name": "济宁学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 527 },
  { "name": "桂林旅游学院(原桂林旅游高等专科学校)", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 520 },
  { "name": "广东培正学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 481 },
  { "name": "河北东方学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 489 },
  { "name": "山东青年政治学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 515 },
  { "name": "武汉商学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 554 },
  { "name": "山东华宇工学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 486 },
  { "name": "四川工业科技学院", "is_wenli": "文科", "jihua": 10, "luqu": 10, "min_score": 478 },
  { "name": "华东交通大学", "is_wenli": "文科", "jihua": 8, "luqu": 9, "min_score": 558 },
  { "name": "太原工业学院", "is_wenli": "文科", "jihua": 9, "luqu": 9, "min_score": 544 },
  { "name": "沈阳师范大学[较高收费]", "is_wenli": "文科", "jihua": 9, "luqu": 9, "min_score": 477 },
  { "name": "安徽中医药大学", "is_wenli": "文科", "jihua": 9, "luqu": 9, "min_score": 555 },
  { "name": "景德镇陶瓷大学", "is_wenli": "文科", "jihua": 9, "luqu": 9, "min_score": 546 },
  { "name": "湖南文理学院", "is_wenli": "文科", "jihua": 9, "luqu": 9, "min_score": 552 },
  { "name": "衡阳师范学院", "is_wenli": "文科", "jihua": 9, "luqu": 9, "min_score": 550 },
  { "name": "长沙学院", "is_wenli": "文科", "jihua": 9, "luqu": 9, "min_score": 556 },
  { "name": "广东石油化工学院", "is_wenli": "文科", "jihua": 8, "luqu": 9, "min_score": 543 },
  { "name": "梧州学院", "is_wenli": "文科", "jihua": 9, "luqu": 9, "min_score": 522 },
  { "name": "海南热带海洋学院[较高收费]", "is_wenli": "文科", "jihua": 9, "luqu": 9, "min_score": 501 },
  { "name": "海南师范大学", "is_wenli": "文科", "jihua": 8, "luqu": 9, "min_score": 559 },
  { "name": "宜宾学院", "is_wenli": "文科", "jihua": 9, "luqu": 9, "min_score": 532 },
  { "name": "北京工业大学耿丹学院", "is_wenli": "文科", "jihua": 10, "luqu": 9, "min_score": 484 },
  { "name": "北京中医药大学东方学院", "is_wenli": "文科", "jihua": 9, "luqu": 9, "min_score": 535 },
  { "name": "南京审计大学金审学院", "is_wenli": "文科", "jihua": 9, "luqu": 9, "min_score": 543 },
  { "name": "西南科技大学城市学院", "is_wenli": "文科", "jihua": 7, "luqu": 9, "min_score": 497 },
  { "name": "沈阳工学院", "is_wenli": "文科", "jihua": 9, "luqu": 9, "min_score": 467 },
  { "name": "云南师范大学商学院", "is_wenli": "文科", "jihua": 10, "luqu": 9, "min_score": 486 },
  { "name": "嘉兴学院南湖学院", "is_wenli": "文科", "jihua": 9, "luqu": 9, "min_score": 501 },
  { "name": "呼和浩特民族学院", "is_wenli": "文科", "jihua": 9, "luqu": 9, "min_score": 517 },
  { "name": "武汉生物工程学院", "is_wenli": "文科", "jihua": 9, "luqu": 9, "min_score": 487 },
  { "name": "河北环境工程学院", "is_wenli": "文科", "jihua": 9, "luqu": 9, "min_score": 525 },
  { "name": "广东东软学院", "is_wenli": "文科", "jihua": 9, "luqu": 9, "min_score": 465 },
  { "name": "哈尔滨体育学院", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 517 },
  { "name": "南昌航空大学[较高收费]", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 551 },
  { "name": "江西理工大学", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 553 },
  { "name": "辽宁科技大学", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 556 },
  { "name": "西南民族大学", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 569 },
  { "name": "张家口学院", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 535 },
  { "name": "河北建筑工程学院", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 519 },
  { "name": "吉林师范大学[较高收费]", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 524 },
  { "name": "吉林师范大学[其他单列专业]", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 509 },
  { "name": "通化师范学院[其他单列专业]", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 478 },
  { "name": "温州大学", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 565 },
  { "name": "聊城大学", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 558 },
  { "name": "山东交通学院", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 553 },
  { "name": "青岛农业大学", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 558 },
  { "name": "湖北医药学院", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 543 },
  { "name": "怀化学院", "is_wenli": "文科", "jihua": 7, "luqu": 8, "min_score": 532 },
  { "name": "岭南师范学院", "is_wenli": "文科", "jihua": 4, "luqu": 8, "min_score": 548 },
  { "name": "广东财经大学", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 570 },
  { "name": "海南师范大学[较高收费]", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 484 },
  { "name": "四川轻化工大学(原四川理工学院)", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 554 },
  { "name": "玉溪师范学院", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 532 },
  { "name": "青海民族大学", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 528 },
  { "name": "呼伦贝尔学院", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 516 },
  { "name": "厦门理工学院[较高收费]", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 532 },
  { "name": "三明学院[较高收费]", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 496 },
  { "name": "河北工程大学科信学院", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 515 },
  { "name": "华北电力大学科技学院", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 515 },
  { "name": "武汉设计工程学院", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 487 },
  { "name": "华南理工大学广州学院", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 513 },
  { "name": "广东外语外贸大学南国商学院", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 495 },
  { "name": "西安科技大学高新学院", "is_wenli": "文科", "jihua": 4, "luqu": 8, "min_score": 491 },
  { "name": "武汉工程大学邮电与信息工程学院", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 502 },
  { "name": "西安交通大学城市学院", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 512 },
  { "name": "保定学院", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 543 },
  { "name": "沧州师范学院", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 529 },
  { "name": "福建警察学院", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 541 },
  { "name": "西安航空学院", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 549 },
  { "name": "兰州文理学院", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 532 },
  { "name": "长沙医学院", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 524 },
  { "name": "江西应用科技学院", "is_wenli": "文科", "jihua": 2, "luqu": 8, "min_score": 467 },
  { "name": "宿迁学院", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 523 },
  { "name": "厦门华厦学院", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 498 },
  { "name": "江西工程学院", "is_wenli": "文科", "jihua": 8, "luqu": 8, "min_score": 475 },
  { "name": "首都体育学院", "is_wenli": "文科", "jihua": 6, "luqu": 7, "min_score": 556 },
  { "name": "武汉体育学院", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 551 },
  { "name": "晋中学院", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 529 },
  { "name": "大连海洋大学", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 557 },
  { "name": "江西科技师范大学", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 553 },
  { "name": "井冈山大学", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 547 },
  { "name": "鲁东大学", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 558 },
  { "name": "广东金融学院", "is_wenli": "文科", "jihua": 5, "luqu": 7, "min_score": 566 },
  { "name": "广东医科大学", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 555 },
  { "name": "云南中医药大学(原云南中医学院)", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 549 },
  { "name": "甘肃政法大学(原甘肃政法学院)", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 556 },
  { "name": "宁夏师范学院", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 531 },
  { "name": "南京信息工程大学滨江学院", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 524 },
  { "name": "山西大学商务学院", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 512 },
  { "name": "太原理工大学现代科技学院", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 497 },
  { "name": "南昌大学科学技术学院", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 509 },
  { "name": "宁夏大学新华学院", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 482 },
  { "name": "蚌埠工商学院(原安徽财经大学商学院)", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 466 },
  { "name": "阜阳师范大学信息工程学院(原阜阳师范学院信息工程学院)", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 511 },
  { "name": "吉首大学张家界学院", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 496 },
  { "name": "广州商学院[较高收费]", "is_wenli": "文科", "jihua": 8, "luqu": 7, "min_score": 467 },
  { "name": "成都理工大学工程技术学院", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 512 },
  { "name": "兰州财经大学陇桥学院", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 488 },
  { "name": "上海外国语大学贤达经济人文学院[较高收费]", "is_wenli": "文科", "jihua": 14, "luqu": 7, "min_score": 465 },
  { "name": "湖南第一师范学院", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 558 },
  { "name": "吉利学院(原北京吉利学院)", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 465 },
  { "name": "广西艺术学院", "is_wenli": "文科", "jihua": 7, "luqu": 7, "min_score": 512 },
  { "name": "山西医科大学", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 552 },
  { "name": "重庆工商大学[较高收费]", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 551 },
  { "name": "衡水学院", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 532 },
  { "name": "河北医科大学", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 554 },
  { "name": "长治学院", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 530 },
  { "name": "吉林建筑大学", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 541 },
  { "name": "安徽农业大学", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 557 },
  { "name": "南昌工程学院", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 549 },
  { "name": "山东农业大学", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 555 },
  { "name": "邵阳学院", "is_wenli": "文科", "jihua": 5, "luqu": 6, "min_score": 529 },
  { "name": "东莞理工学院", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 555 },
  { "name": "广东第二师范学院", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 559 },
  { "name": "昆明理工大学[较高收费]", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 549 },
  { "name": "云南农业大学", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 548 },
  { "name": "宝鸡文理学院", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 543 },
  { "name": "陕西理工大学", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 551 },
  { "name": "陕西中医药大学", "is_wenli": "文科", "jihua": 2, "luqu": 6, "min_score": 558 },
  { "name": "新疆农业大学[较高收费]", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 493 },
  { "name": "厦门理工学院[其他单列专业]", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 548 },
  { "name": "安康学院", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 527 },
  { "name": "商洛学院", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 525 },
  { "name": "上海师范大学天华学院[较高收费]", "is_wenli": "文科", "jihua": 16, "luqu": 6, "min_score": 465 },
  { "name": "山东科技大学泰山科技学院", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 481 },
  { "name": "湖北文理学院理工学院", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 493 },
  { "name": "湖南科技大学潇湘学院", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 514 },
  { "name": "广州商学院", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 498 },
  { "name": "电子科技大学成都学院", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 512 },
  { "name": "兰州交通大学博文学院", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 496 },
  { "name": "三亚学院[较高收费]", "is_wenli": "文科", "jihua": 5, "luqu": 6, "min_score": 465 },
  { "name": "武汉传媒学院", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 507 },
  { "name": "成都银杏酒店管理学院(原成都信息工程大学银杏酒店管理学院)", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 487 },
  { "name": "武夷学院[较高收费]", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 495 },
  { "name": "景德镇学院", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 528 },
  { "name": "桂林旅游学院(原桂林旅游高等专科学校)[较高收费]", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 465 },
  { "name": "阿坝师范学院", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 519 },
  { "name": "新疆工程学院", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 508 },
  { "name": "江西科技学院", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 469 },
  { "name": "南京艺术学院", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 553 },
  { "name": "西安美术学院", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 528 },
  { "name": "内蒙古艺术学院", "is_wenli": "文科", "jihua": 6, "luqu": 6, "min_score": 509 },
  { "name": "天津体育学院", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 554 },
  { "name": "山西医科大学[异地校区]", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 547 },
  { "name": "重庆工商大学", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 567 },
  { "name": "河北中医学院", "is_wenli": "文科", "jihua": 4, "luqu": 5, "min_score": 560 },
  { "name": "唐山学院", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 525 },
  { "name": "赤峰学院", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 517 },
  { "name": "长春工程学院", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 531 },
  { "name": "齐鲁师范学院", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 551 },
  { "name": "山东第一医科大学", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 547 },
  { "name": "泰山学院", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 531 },
  { "name": "肇庆学院", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 539 },
  { "name": "重庆文理学院", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 555 },
  { "name": "重庆师范大学", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 565 },
  { "name": "乐山师范学院", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 540 },
  { "name": "西昌学院", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 520 },
  { "name": "贵阳学院", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 532 },
  { "name": "安顺学院", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 518 },
  { "name": "四川警察学院", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 540 },
  { "name": "河北科技大学理工学院", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 514 },
  { "name": "中北大学信息商务学院", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 476 },
  { "name": "西安理工大学高科学院", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 486 },
  { "name": "皖江工学院(原河海大学文天学院)", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 479 },
  { "name": "昆明医科大学海源学院", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 485 },
  { "name": "中国计量大学现代科技学院", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 516 },
  { "name": "西南交通大学希望学院", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 502 },
  { "name": "新余学院", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 525 },
  { "name": "文山学院", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 516 },
  { "name": "山西能源学院", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 519 },
  { "name": "安徽外国语学院", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 516 },
  { "name": "新疆艺术学院", "is_wenli": "文科", "jihua": 5, "luqu": 5, "min_score": 511 },
  { "name": "大连民族大学[仅招少数民族]", "is_wenli": "文科", "jihua": 3, "luqu": 4, "min_score": 536 },
  { "name": "南昌航空大学", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 559 },
  { "name": "河北工程大学", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 557 },
  { "name": "大连交通大学[较高收费]", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 536 },
  { "name": "长治医学院", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 536 },
  { "name": "沈阳航空航天大学", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 556 },
  { "name": "锦州医科大学", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 541 },
  { "name": "湖南人文科技学院[较高收费]", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 507 },
  { "name": "湖南工程学院", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 544 },
  { "name": "韩山师范学院", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 529 },
  { "name": "广西科技大学", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 542 },
  { "name": "广西中医药大学", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 558 },
  { "name": "长江师范学院", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 551 },
  { "name": "攀枝花学院", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 537 },
  { "name": "贵州中医药大学(原贵阳中医学院)", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 558 },
  { "name": "西藏农牧学院", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 511 },
  { "name": "甘肃中医药大学", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 506 },
  { "name": "兰州财经大学[较高收费]", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 543 },
  { "name": "贺州学院", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 517 },
  { "name": "陇东学院", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 514 },
  { "name": "西安医学院", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 544 },
  { "name": "贵州工程应用技术学院(原毕节学院)", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 516 },
  { "name": "兰州城市学院", "is_wenli": "文科", "jihua": 3, "luqu": 4, "min_score": 533 },
  { "name": "北部湾大学(原钦州学院)", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 539 },
  { "name": "滇西应用技术大学", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 521 },
  { "name": "四川文化艺术学院", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 496 },
  { "name": "福建师范大学协和学院[较高收费]", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 483 },
  { "name": "福建农林大学金山学院[较高收费]", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 468 },
  { "name": "赣南师范大学科技学院", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 509 },
  { "name": "燕山大学里仁学院", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 525 },
  { "name": "哈尔滨剑桥学院", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 466 },
  { "name": "马鞍山学院(原安徽工业大学工商学院)", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 482 },
  { "name": "安徽建筑大学城市建设学院", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 501 },
  { "name": "湖南文理学院芙蓉学院", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 504 },
  { "name": "南华大学船山学院", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 509 },
  { "name": "湘潭大学兴湘学院", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 515 },
  { "name": "贵州财经大学商务学院", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 507 },
  { "name": "北京工商大学嘉华学院", "is_wenli": "文科", "jihua": 16, "luqu": 4, "min_score": 469 },
  { "name": "南京理工大学泰州科技学院", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 512 },
  { "name": "燕京理工学院(原北京化工大学北方学院)", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 497 },
  { "name": "吉林警察学院", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 544 },
  { "name": "长沙师范学院", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 535 },
  { "name": "甘肃民族师范学院", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 517 },
  { "name": "上海杉达学院[较高收费]", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 473 },
  { "name": "鄂尔多斯应用技术学院", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 512 },
  { "name": "山东工程职业技术大学(原山东凯文科技职业学院)", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 467 },
  { "name": "南宁学院", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 486 },
  { "name": "湖南应用技术学院", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 492 },
  { "name": "广州工商学院", "is_wenli": "文科", "jihua": 4, "luqu": 4, "min_score": 467 },
  { "name": "大连交通大学", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 560 },
  { "name": "山西财经大学[较高收费]", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 555 },
  { "name": "北京石油化工学院", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 556 },
  { "name": "河北地质大学[较高收费]", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 543 },
  { "name": "盐城工学院", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 547 },
  { "name": "江西农业大学[较高收费]", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 550 },
  { "name": "山东中医药大学", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 560 },
  { "name": "内江师范学院", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 537 },
  { "name": "遵义医科大学(原遵义医学院)", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 542 },
  { "name": "楚雄师范学院", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 518 },
  { "name": "甘肃农业大学", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 545 },
  { "name": "河北金融学院[较高收费]", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 544 },
  { "name": "吉林医药学院", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 532 },
  { "name": "北京第二外国语学院中瑞酒店管理学院", "is_wenli": "文科", "jihua": 10, "luqu": 3, "min_score": 466 },
  { "name": "潍坊理工学院(原山东师范大学历山学院)", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 507 },
  { "name": "西安科技大学高新学院[较高收费]", "is_wenli": "文科", "jihua": 4, "luqu": 3, "min_score": 471 },
  { "name": "武汉体育学院体育科技学院", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 487 },
  { "name": "四川工商学院", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 509 },
  { "name": "上海视觉艺术学院(原复旦大学上海视觉艺术学院)", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 543 },
  { "name": "亳州学院", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 520 },
  { "name": "萍乡学院", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 520 },
  { "name": "兴义民族师范学院", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 515 },
  { "name": "贵州商学院", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 533 },
  { "name": "昭通学院", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 518 },
  { "name": "贵州警察学院", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 536 },
  { "name": "云南经济管理学院", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 473 },
  { "name": "四川美术学院", "is_wenli": "文科", "jihua": 3, "luqu": 3, "min_score": 560 },
  { "name": "大连民族大学", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 560 },
  { "name": "黑龙江中医药大学", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 550 },
  { "name": "承德医学院", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 552 },
  { "name": "沈阳师范大学[其他单列专业]", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 521 },
  { "name": "长春大学[较高收费]", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 537 },
  { "name": "江苏警官学院", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 562 },
  { "name": "滨州学院", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 533 },
  { "name": "菏泽学院", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 523 },
  { "name": "广东警官学院", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 559 },
  { "name": "桂林医学院", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 540 },
  { "name": "贵州师范大学[较高收费]", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 536 },
  { "name": "云南农业大学[较高收费]", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 533 },
  { "name": "西安财经大学(原西安财经学院)", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 564 },
  { "name": "天水师范学院", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 528 },
  { "name": "云南警官学院", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 548 },
  { "name": "四川大学锦江学院", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 515 },
  { "name": "景德镇陶瓷大学科技艺术学院", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 499 },
  { "name": "湖南理工学院南湖学院", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 511 },
  { "name": "湖南农业大学东方科技学院", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 497 },
  { "name": "湘潭理工学院(原湖南工商大学北津学院)", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 501 },
  { "name": "贵州师范大学求是学院", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 505 },
  { "name": "贵州大学明德学院", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 508 },
  { "name": "六盘水师范学院", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 519 },
  { "name": "贵州商学院[较高收费]", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 475 },
  { "name": "兰州工业学院", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 515 },
  { "name": "吉林外国语大学[较高收费]", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 503 },
  { "name": "河北工程技术学院", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 494 },
  { "name": "浙江越秀外国语学院[较高收费]", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 466 },
  { "name": "四川电影电视学院", "is_wenli": "文科", "jihua": 2, "luqu": 2, "min_score": 508 },
  { "name": "湖南理工学院", "is_wenli": "文科", "jihua": 1, "luqu": 1, "min_score": 556 },
  { "name": "五邑大学", "is_wenli": "文科", "jihua": 1, "luqu": 1, "min_score": 559 },
  { "name": "广西中医药大学[较高收费]", "is_wenli": "文科", "jihua": 1, "luqu": 1, "min_score": 509 },
  { "name": "浙江外国语学院[较高收费]", "is_wenli": "文科", "jihua": 1, "luqu": 1, "min_score": 554 },
  { "name": "无锡太湖学院[较高收费]", "is_wenli": "文科", "jihua": 5, "luqu": 1, "min_score": 465 },
  { "name": "贵州医科大学神奇民族医药学院", "is_wenli": "文科", "jihua": 1, "luqu": 1, "min_score": 500 },
  { "name": "上海立达学院", "is_wenli": "文科", "jihua": 7, "luqu": 1, "min_score": 477 },
]