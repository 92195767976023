module.exports = [
  { "a": "学科门类名称", "b": "2022届", "c": "2021届", "d": "2020届" },
  { "a": "自动化类", "b": "5339", "c": "4902", "d": "4525" },
  { "a": "铁道运输类", "b": "5295", "c": "5280", "d": "5149" },
  { "a": "机械设计制造类", "b": "5274", "c": "5069", "d": "4788" },
  { "a": "机电设备类", "b": "5180", "c": "5085", "d": "4753" },
  { "a": "化工技术类", "b": "5145", "c": "4907", "d": "4717" },
  { "a": "电子信息类", "b": "5097", "c": "4807", "d": "4587" },
  { "a": "航空运输类", "b": "5075", "c": "5173", "d": "4993" },
  { "a": "船舶与海洋工程装备类", "b": "5018", "c": "4686", "d": "4474" },
  { "a": "电力技术类", "b": "4959", "c": "4761", "d": "4535" },
  { "a": "汽车制造类", "b": "4932", "c": "4638", "d": "4391" },
  { "a": "城市轨道交通类", "b": "4888", "c": "4756", "d": "4555" },
  { "a": "市场营销类", "b": "4869", "c": "4833", "d": "4532" },
  { "a": "土建施工类", "b": "4851", "c": "4580", "d": "4248" },
  { "a": "通信类", "b": "4832", "c": "4902", "d": "4526" },
  { "a": "建筑设备类", "b": "4808", "c": "4565", "d": "4315" },
  // { "a": "工商管理类", "b": "4801", "c": "4621", "d": "4327" },
  // { "a": "计算机类", "b": "4789", "c": "4759", "d": "4770" },
  // { "a": "电子商务类", "b": "4780", "c": "4733", "d": "4513" },
  // { "a": "物流类", "b": "4767", "c": "4705", "d": "4450" },
  // { "a": "经济贸易类", "b": "4749", "c": "4572", "d": "4290" },
  // { "a": "道路运输类", "b": "4707", "c": "4722", "d": "4525" },
  // { "a": "广播影视类", "b": "4703", "c": "4512", "d": "4288" }
  // { "a": "环境保护类", "b": "4692", "c": "4590", "d": "4320" }
  // { "a": "水上运输类", "b": "4684", "c": "4714", "d": "4603" }
  // { "a": "市政工程类", "b": "4646", "c": "4493", "d": "4292" }
  // { "a": "表演艺术类", "b": "4632", "c": "4664", "d": "4611" }
  // { "a": "畜牧业类", "b": "4611", "c": "4537", "d": "4272" }
  // { "a": "药品制造类", "b": "4556", "c": "4272", "d": "4097" }
  // { "a": "测绘地理信息类", "b": "4543", "c": "4466", "d": "4392" }
  // { "a": "建设工程管理类", "b": "4521", "c": "4426", "d": "4230" }
  // { "a": "艺术设计类", "b": "4514", "c": "4363", "d": "4141" }
  // { "a": "金融类", "b": "4504", "c": "4464", "d": "4238" }
  // { "a": "食品工业类", "b": "4498", "c": "4259", "d": "3900" }
  // { "a": "语言类", "b": "4479", "c": "4380", "d": "4353" }
  // { "a": "旅游类", "b": "4413", "c": "4348", "d": "4062" }
  // { "a": "林业类", "b": "4394", "c": "4376", "d": "4130" }
  // { "a": "食品药品管理类", "b": "4376", "c": "4316", "d": "4193" }
  // { "a": "餐饮类", "b": "4341", "c": "4385", "d": "4151" }
  // { "a": "公共管理类", "b": "4340", "c": "4091", "d": "4016" }
  // { "a": "农业类", "b": "4315", "c": "4169", "d": "3860" }
  // { "a": "房地产类", "b": "4255", "c": "4167", "d": "3980" }
  // { "a": "财务会计类", "b": "4227", "c": "4123", "d": "3853" }
  // { "a": "公共事业类", "b": "4211", "c": "4028", "d": "3885" }
  // { "a": "药学类", "b": "4142", "c": "4082", "d": "3817" }
  // { "a": "建筑设计类", "b": "4122", "c": "4007", "d": "3846" }
  // { "a": "医学技术类", "b": "4087", "c": "4097", "d": "4035" }
  // { "a": "公共服务类", "b": "4060", "c": "3852", "d": "3758" }
  // { "a": "护理类", "b": "3898", "c": "3854", "d": "3765" }
  // { "a": "康复治疗类", "b": "3891", "c": "3974", "d": "3945" }
  // { "a": "教育类", "b": "3515", "c": "3587", "d": "3450" }
  // { "a": "临床医学类", "b": "3511", "c": "3515", "d": "3258" }
  // { "a": "全国高职", "b": "4595", "c": "4505", "d": "4253" }

]