module.exports = [
  { "name": "[1740] 延边大学", "is_wenli": "理科", "jihua": 30, "luqu": 30, "min_score": 439 },
  { "name": "[1765] 哈尔滨医科大学", "is_wenli": "理科", "jihua": 39, "luqu": 39, "min_score": 446 },
  { "name": "[2130] 石河子大学", "is_wenli": "理科", "jihua": 24, "luqu": 24, "min_score": 428 },
  { "name": "[2215] 中国民航大学", "is_wenli": "理科", "jihua": 32, "luqu": 32, "min_score": 453 },
  { "name": "[2235] 东北电力大学", "is_wenli": "理科", "jihua": 50, "luqu": 50, "min_score": 423 },
  { "name": "[2310] 南方医科大学", "is_wenli": "理科", "jihua": 30, "luqu": 30, "min_score": 432 },
  { "name": "[2375] 东北石油大学", "is_wenli": "理科", "jihua": 39, "luqu": 39, "min_score": 422 },
  { "name": "[2405] 天津师范大学", "is_wenli": "理科", "jihua": 12, "luqu": 12, "min_score": 429 },
  { "name": "[2570] 上海中医药大学", "is_wenli": "理科", "jihua": 6, "luqu": 6, "min_score": 423 },
  { "name": "[2795] 沈阳工业大学", "is_wenli": "理科", "jihua": 40, "luqu": 40, "min_score": 424 },
  { "name": "[2800] 中南林业科技大学", "is_wenli": "理科", "jihua": 20, "luqu": 20, "min_score": 426 },
  { "name": "[2810] 安徽工业大学", "is_wenli": "理科", "jihua": 20, "luqu": 20, "min_score": 350 },
  { "name": "[3035] 中国劳动关系学院", "is_wenli": "理科", "jihua": 4, "luqu": 4, "min_score": 424 },
  { "name": "[3140] 北京青年政治学院", "is_wenli": "理科", "jihua": 11, "luqu": 11, "min_score": 426 },
  { "name": "[3260] 张家口学院", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 428 },
  { "name": "[3310] 华北理工大学", "is_wenli": "理科", "jihua": 94, "luqu": 94, "min_score": 425 },
  { "name": "[3515] 辽宁科技学院", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 421 },
  { "name": "[3580] 沈阳医学院", "is_wenli": "理科", "jihua": 30, "luqu": 30, "min_score": 427 },
  { "name": "[3585] 鞍山师范学院", "is_wenli": "理科", "jihua": 4, "luqu": 4, "min_score": 316 },
  { "name": "[3615] 辽东学院", "is_wenli": "理科", "jihua": 99, "luqu": 99, "min_score": 323 },
  { "name": "[3625] 长春大学", "is_wenli": "理科", "jihua": 12, "luqu": 12, "min_score": 332 },
  { "name": "[3630] 长春工业大学", "is_wenli": "理科", "jihua": 68, "luqu": 68, "min_score": 421 },
  { "name": "[3645] 长春工程学院", "is_wenli": "理科", "jihua": 46, "luqu": 46, "min_score": 399 },
  { "name": "[3655] 吉林化工学院", "is_wenli": "理科", "jihua": 60, "luqu": 60, "min_score": 216 },
  { "name": "[3665] 长春中医药大学", "is_wenli": "理科", "jihua": 19, "luqu": 19, "min_score": 431 },
  { "name": "[3670] 白城师范学院", "is_wenli": "理科", "jihua": 11, "luqu": 11, "min_score": 409 },
  { "name": "[3675] 长春师范大学", "is_wenli": "理科", "jihua": 35, "luqu": 35, "min_score": 419 },
  { "name": "[3680] 吉林工程技术师范学院", "is_wenli": "理科", "jihua": 15, "luqu": 15, "min_score": 305 },
  { "name": "[3775] 吉林农业科技学院", "is_wenli": "理科", "jihua": 19, "luqu": 19, "min_score": 320 },
  { "name": "[4325] 江西医学高等专科学校", "is_wenli": "理科", "jihua": 18, "luqu": 18, "min_score": 416 },
  { "name": "[4445] 菏泽学院", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 411 },
  { "name": "[4735] 湖南工业大学", "is_wenli": "理科", "jihua": 4, "luqu": 4, "min_score": 416 },
  { "name": "[4920] 广西科技大学", "is_wenli": "理科", "jihua": 31, "luqu": 30, "min_score": 418 },
  { "name": "[4925] 桂林电子科技大学", "is_wenli": "理科", "jihua": 67, "luqu": 67, "min_score": 422 },
  { "name": "[4930] 桂林理工大学", "is_wenli": "理科", "jihua": 56, "luqu": 56, "min_score": 418 },
  { "name": "[4965] 广西民族大学", "is_wenli": "理科", "jihua": 7, "luqu": 7, "min_score": 413 },
  { "name": "[5000] 海南热带海洋学院", "is_wenli": "理科", "jihua": 39, "luqu": 39, "min_score": 316 },
  { "name": "[5005] 海南医学院", "is_wenli": "理科", "jihua": 24, "luqu": 24, "min_score": 442 },
  { "name": "[5105] 四川轻化工大学(原四川理工学院)", "is_wenli": "理科", "jihua": 14, "luqu": 14, "min_score": 423 },
  { "name": "[5235] 云南农业大学", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 410 },
  { "name": "[5415] 兰州交通大学", "is_wenli": "理科", "jihua": 28, "luqu": 28, "min_score": 437 },
  { "name": "[5475] 青海民族大学", "is_wenli": "理科", "jihua": 4, "luqu": 4, "min_score": 403 },
  { "name": "[5595] 上海电机学院", "is_wenli": "理科", "jihua": 34, "luqu": 34, "min_score": 425 },
  { "name": "[5710] 吉林工商学院", "is_wenli": "理科", "jihua": 45, "luqu": 45, "min_score": 403 },
  { "name": "[5850] 吉林医药学院", "is_wenli": "理科", "jihua": 35, "luqu": 35, "min_score": 427 },
  { "name": "[5999] 郑州亚欧交通职业学院", "is_wenli": "理科", "jihua": 80, "luqu": 80, "min_score": 177 },
  { "name": "[6003] 郑州西亚斯学院(原郑州大学西亚斯国际学院)", "is_wenli": "理科", "jihua": 438, "luqu": 437, "min_score": 329 },
  { "name": "[6025] 河南科技学院", "is_wenli": "理科", "jihua": 470, "luqu": 474, "min_score": 405 },
  { "name": "[6040] 许昌学院", "is_wenli": "理科", "jihua": 365, "luqu": 365, "min_score": 373 },
  { "name": "[6040] 许昌学院[较高收费]", "is_wenli": "理科", "jihua": 100, "luqu": 100, "min_score": 387 },
  { "name": "[6042] 许昌学院(中外合作办学)", "is_wenli": "理科", "jihua": 55, "luqu": 55, "min_score": 205 },
  { "name": "[6047] 河南牧业经济学院(中外合作)", "is_wenli": "理科", "jihua": 247, "luqu": 247, "min_score": 260 },
  { "name": "[6048] 河南牧业经济学院(软件类)", "is_wenli": "理科", "jihua": 400, "luqu": 400, "min_score": 357 },
  { "name": "[6050] 安阳师范学院", "is_wenli": "理科", "jihua": 30, "luqu": 30, "min_score": 404 },
  { "name": "[6052] 安阳师范学院(中外合作办学)", "is_wenli": "理科", "jihua": 80, "luqu": 80, "min_score": 355 },
  { "name": "[6053] 安阳师范学院(软件类)", "is_wenli": "理科", "jihua": 360, "luqu": 340, "min_score": 182 },
  { "name": "[6057] 商丘师范学院(中外合作办学)", "is_wenli": "理科", "jihua": 130, "luqu": 130, "min_score": 366 },
  { "name": "[6060] 周口师范学院", "is_wenli": "理科", "jihua": 80, "luqu": 80, "min_score": 427 },
  { "name": "[6065] 信阳师范学院", "is_wenli": "理科", "jihua": 260, "luqu": 260, "min_score": 419 },
  { "name": "[6070] 南阳师范学院", "is_wenli": "理科", "jihua": 90, "luqu": 90, "min_score": 346 },
  { "name": "[6103] 河南理工大学(办学就读地在焦作市解放中路)", "is_wenli": "理科", "jihua": 305, "luqu": 305, "min_score": 421 },
  { "name": "[6103] 河南理工大学(办学就读地在焦作市解放中路)[较高收费]", "is_wenli": "理科", "jihua": 300, "luqu": 300, "min_score": 408 },
  { "name": "[6105] 河南工业大学", "is_wenli": "理科", "jihua": 540, "luqu": 540, "min_score": 407 },
  { "name": "[6105] 河南工业大学[较高收费]", "is_wenli": "理科", "jihua": 400, "luqu": 400, "min_score": 392 },
  { "name": "[6106] 河南工业大学(与河南辅读中等职业学校联办)", "is_wenli": "理科", "jihua": 150, "luqu": 150, "min_score": 292 },
  { "name": "[6125] 平顶山学院", "is_wenli": "理科", "jihua": 102, "luqu": 102, "min_score": 392 },
  { "name": "[6125] 平顶山学院[护理]", "is_wenli": "理科", "jihua": 60, "luqu": 60, "min_score": 373 },
  { "name": "[6130] 黄河科技学院", "is_wenli": "理科", "jihua": 475, "luqu": 480, "min_score": 287 },
  { "name": "[6132] 黄河科技学院(中外合作办学)", "is_wenli": "理科", "jihua": 270, "luqu": 270, "min_score": 161 },
  { "name": "[6135] 安阳工学院", "is_wenli": "理科", "jihua": 584, "luqu": 576, "min_score": 382 },
  { "name": "[6145] 信阳农林学院", "is_wenli": "理科", "jihua": 70, "luqu": 70, "min_score": 347 },
  { "name": "[6150] 黄淮学院", "is_wenli": "理科", "jihua": 210, "luqu": 210, "min_score": 362 },
  { "name": "[6153] 黄淮学院(中外合作办学)", "is_wenli": "理科", "jihua": 100, "luqu": 100, "min_score": 266 },
  { "name": "[6155] 河南工程学院", "is_wenli": "理科", "jihua": 1595, "luqu": 1595, "min_score": 403 },
  { "name": "[6157] 河南工程学院(中外合作办学)", "is_wenli": "理科", "jihua": 130, "luqu": 130, "min_score": 319 },
  { "name": "[6158] 河南工程学院(软件类)", "is_wenli": "理科", "jihua": 140, "luqu": 140, "min_score": 400 },
  { "name": "[6160] 洛阳理工学院", "is_wenli": "理科", "jihua": 385, "luqu": 385, "min_score": 422 },
  { "name": "[6162] 洛阳理工学院(中外合作办学)", "is_wenli": "理科", "jihua": 80, "luqu": 80, "min_score": 289 },
  { "name": "[6164] 洛阳理工学院(软件类)", "is_wenli": "理科", "jihua": 80, "luqu": 80, "min_score": 395 },
  { "name": "[6165] 新乡学院", "is_wenli": "理科", "jihua": 560, "luqu": 560, "min_score": 411 },
  { "name": "[6170] 郑州师范学院", "is_wenli": "理科", "jihua": 490, "luqu": 490, "min_score": 427 },
  { "name": "[6172] 郑州师范学院(中外合作办学)", "is_wenli": "理科", "jihua": 20, "luqu": 20, "min_score": 416 },
  { "name": "[6175] 郑州工业应用技术学院(原郑州华信学院)", "is_wenli": "理科", "jihua": 1397, "luqu": 1109, "min_score": 181 },
  { "name": "[6180] 郑州科技学院", "is_wenli": "理科", "jihua": 1395, "luqu": 1401, "min_score": 299 },
  { "name": "[6184] 河南轻工职业学院", "is_wenli": "理科", "jihua": 1280, "luqu": 1568, "min_score": 201 },
  { "name": "[6185] 郑州财经学院", "is_wenli": "理科", "jihua": 933, "luqu": 1133, "min_score": 264 },
  { "name": "[6186] 河南信息统计职业学院", "is_wenli": "理科", "jihua": 640, "luqu": 640, "min_score": 314 },
  { "name": "[6188] 河南林业职业学院", "is_wenli": "理科", "jihua": 940, "luqu": 939, "min_score": 245 },
  { "name": "[6191] 河南工业和信息化职业学院", "is_wenli": "理科", "jihua": 495, "luqu": 528, "min_score": 211 },
  { "name": "[6192] 河南水利与环境职业学院", "is_wenli": "理科", "jihua": 1343, "luqu": 1331, "min_score": 294 },
  { "name": "[6192] 河南水利与环境职业学院[较高收费]", "is_wenli": "理科", "jihua": 17, "luqu": 17, "min_score": 181 },
  { "name": "[6195] 郑州升达经贸管理学院", "is_wenli": "理科", "jihua": 535, "luqu": 529, "min_score": 267 },
  { "name": "[6195] 郑州升达经贸管理学院[较高收费]", "is_wenli": "理科", "jihua": 50, "luqu": 30, "min_score": 180 },
  { "name": "[6200] 商丘学院", "is_wenli": "理科", "jihua": 860, "luqu": 860, "min_score": 261 },
  { "name": "[6202] 商丘学院(应用科技学院,办学地点在开封)", "is_wenli": "理科", "jihua": 699, "luqu": 699, "min_score": 237 },
  { "name": "[6205] 商丘工学院", "is_wenli": "理科", "jihua": 500, "luqu": 501, "min_score": 180 },
  { "name": "[6213] 郑州电力高等专科学校", "is_wenli": "理科", "jihua": 1286, "luqu": 1286, "min_score": 359 },
  { "name": "[6213] 郑州电力高等专科学校[较高收费]", "is_wenli": "理科", "jihua": 30, "luqu": 30, "min_score": 338 },
  { "name": "[6214] 河南工学院", "is_wenli": "理科", "jihua": 1380, "luqu": 1380, "min_score": 380 },
  { "name": "[6215] 河南财政金融学院", "is_wenli": "理科", "jihua": 901, "luqu": 901, "min_score": 417 },
  { "name": "[6216] 河南财政金融学院(中外合作办学)", "is_wenli": "理科", "jihua": 434, "luqu": 434, "min_score": 320 },
  { "name": "[6218] 河南财政金融学院(软件类)", "is_wenli": "理科", "jihua": 490, "luqu": 490, "min_score": 337 },
  { "name": "[6221] 河南财经政法大学(与爱尔兰合办)", "is_wenli": "理科", "jihua": 275, "luqu": 275, "min_score": 399 },
  { "name": "[6226] 黄河交通学院", "is_wenli": "理科", "jihua": 25, "luqu": 22, "min_score": 186 },
  { "name": "[6231] 郑州幼儿师范高等专科学校", "is_wenli": "理科", "jihua": 320, "luqu": 320, "min_score": 395 },
  { "name": "[6231] 郑州幼儿师范高等专科学校[较高收费]", "is_wenli": "理科", "jihua": 40, "luqu": 40, "min_score": 357 },
  { "name": "[6232] 驻马店幼儿师范高等专科学校", "is_wenli": "理科", "jihua": 185, "luqu": 185, "min_score": 277 },
  { "name": "[6233] 安阳幼儿师范高等专科学校", "is_wenli": "理科", "jihua": 540, "luqu": 536, "min_score": 370 },
  { "name": "[6235] 焦作师范高等专科学校", "is_wenli": "理科", "jihua": 715, "luqu": 701, "min_score": 396 },
  { "name": "[6236] 漯河医学高等专科学校(中外合作办学)", "is_wenli": "理科", "jihua": 50, "luqu": 49, "min_score": 380 },
  { "name": "[6237] 漯河医学高等专科学校", "is_wenli": "理科", "jihua": 1650, "luqu": 1645, "min_score": 320 },
  { "name": "[6238] 商丘医学高等专科学校", "is_wenli": "理科", "jihua": 1098, "luqu": 1083, "min_score": 299 },
  { "name": "[6239] 南阳医学高等专科学校", "is_wenli": "理科", "jihua": 1672, "luqu": 1672, "min_score": 316 },
  { "name": "[6243] 濮阳医学高等专科学校", "is_wenli": "理科", "jihua": 535, "luqu": 535, "min_score": 284 },
  { "name": "[6249] 郑州工程技术学院(软件类)", "is_wenli": "理科", "jihua": 120, "luqu": 120, "min_score": 357 },
  { "name": "[6250] 郑州工程技术学院", "is_wenli": "理科", "jihua": 91, "luqu": 92, "min_score": 396 },
  { "name": "[6251] 郑州工程技术学院(中外合作办学)", "is_wenli": "理科", "jihua": 55, "luqu": 55, "min_score": 190 },
  { "name": "[6252] 开封大学", "is_wenli": "理科", "jihua": 1245, "luqu": 1230, "min_score": 393 },
  { "name": "[6253] 开封大学(软件类)", "is_wenli": "理科", "jihua": 60, "luqu": 60, "min_score": 376 },
  { "name": "[6255] 开封大学(中外合作办学)", "is_wenli": "理科", "jihua": 40, "luqu": 40, "min_score": 273 },
  { "name": "[6256] 焦作大学", "is_wenli": "理科", "jihua": 1143, "luqu": 1143, "min_score": 348 },
  { "name": "[6258] 黄河水利职业技术学院", "is_wenli": "理科", "jihua": 2425, "luqu": 2427, "min_score": 362 },
  { "name": "[6259] 黄河水利职业技术学院(中外合作办学)", "is_wenli": "理科", "jihua": 150, "luqu": 149, "min_score": 224 },
  { "name": "[6261] 河南职业技术学院", "is_wenli": "理科", "jihua": 2444, "luqu": 2444, "min_score": 377 },
  { "name": "[6262] 河南工业职业技术学院", "is_wenli": "理科", "jihua": 2010, "luqu": 2010, "min_score": 316 },
  { "name": "[6262] 河南工业职业技术学院[较高收费]", "is_wenli": "理科", "jihua": 175, "luqu": 175, "min_score": 248 },
  { "name": "[6263] 河南司法警官职业学院", "is_wenli": "理科", "jihua": 590, "luqu": 590, "min_score": 319 },
  { "name": "[6264] 河南职业技术学院(软件类)[较高收费]", "is_wenli": "理科", "jihua": 89, "luqu": 89, "min_score": 293 },
  { "name": "[6265] 郑州铁路职业技术学院", "is_wenli": "理科", "jihua": 2713, "luqu": 2696, "min_score": 372 },
  { "name": "[6266] 河南应用技术职业学院", "is_wenli": "理科", "jihua": 1645, "luqu": 1617, "min_score": 344 },
  { "name": "[6267] 河南检察职业学院", "is_wenli": "理科", "jihua": 350, "luqu": 350, "min_score": 319 },
  { "name": "[6268] 河南质量工程职业学院", "is_wenli": "理科", "jihua": 1075, "luqu": 1075, "min_score": 217 },
  { "name": "[6269] 河南建筑职业技术学院", "is_wenli": "理科", "jihua": 1320, "luqu": 1320, "min_score": 286 },
  { "name": "[6270] 郑州信息科技职业学院", "is_wenli": "理科", "jihua": 1405, "luqu": 1382, "min_score": 321 },
  { "name": "[6270] 郑州信息科技职业学院[较高收费]", "is_wenli": "理科", "jihua": 25, "luqu": 25, "min_score": 249 },
  { "name": "[6271] 平顶山工业职业技术学院", "is_wenli": "理科", "jihua": 1004, "luqu": 1094, "min_score": 237 },
  { "name": "[6271] 平顶山工业职业技术学院[较高收费]", "is_wenli": "理科", "jihua": 15, "luqu": 15, "min_score": 184 },
  { "name": "[6272] 濮阳职业技术学院", "is_wenli": "理科", "jihua": 1430, "luqu": 1414, "min_score": 206 },
  { "name": "[6272] 濮阳职业技术学院[较高收费]", "is_wenli": "理科", "jihua": 30, "luqu": 7, "min_score": 197 },
  { "name": "[6273] 鹤壁职业技术学院", "is_wenli": "理科", "jihua": 708, "luqu": 727, "min_score": 258 },
  { "name": "[6273] 鹤壁职业技术学院", "is_wenli": "理科", "jihua": 708, "luqu": 727, "min_score": 258 },
  { "name": "[6273] 鹤壁职业技术学院[较高收费]", "is_wenli": "理科", "jihua": 50, "luqu": 31, "min_score": 167 },
  { "name": "[6273] 鹤壁职业技术学院[其他单列专业]", "is_wenli": "理科", "jihua": 240, "luqu": 240, "min_score": 208 },
  { "name": "[6275] 三门峡职业技术学院", "is_wenli": "理科", "jihua": 1741, "luqu": 1741, "min_score": 213 },
  { "name": "[6276] 许昌职业技术学院", "is_wenli": "理科", "jihua": 900, "luqu": 900, "min_score": 275 },
  { "name": "[6277] 漯河职业技术学院", "is_wenli": "理科", "jihua": 1240, "luqu": 1240, "min_score": 202 },
  { "name": "[6278] 商丘职业技术学院", "is_wenli": "理科", "jihua": 2860, "luqu": 2860, "min_score": 254 },
  { "name": "[6278] 商丘职业技术学院[较高收费]", "is_wenli": "理科", "jihua": 90, "luqu": 90, "min_score": 160 },
  { "name": "[6279] 周口职业技术学院", "is_wenli": "理科", "jihua": 772, "luqu": 884, "min_score": 247 },
  { "name": "[6280] 济源职业技术学院", "is_wenli": "理科", "jihua": 535, "luqu": 555, "min_score": 200 },
  { "name": "[6281] 河南经贸职业学院", "is_wenli": "理科", "jihua": 1450, "luqu": 1450, "min_score": 381 },
  { "name": "[6282] 河南农业职业学院", "is_wenli": "理科", "jihua": 1324, "luqu": 1324, "min_score": 283 },
  { "name": "[6283] 河南交通职业技术学院", "is_wenli": "理科", "jihua": 1590, "luqu": 1577, "min_score": 310 },
  { "name": "[6284] 郑州旅游职业学院", "is_wenli": "理科", "jihua": 657, "luqu": 655, "min_score": 278 },
  { "name": "[6285] 郑州职业技术学院", "is_wenli": "理科", "jihua": 1808, "luqu": 1797, "min_score": 324 },
  { "name": "[6286] 郑州工业安全职业学院", "is_wenli": "理科", "jihua": 440, "luqu": 440, "min_score": 274 },
  { "name": "[6287] 永城职业学院", "is_wenli": "理科", "jihua": 496, "luqu": 412, "min_score": 180 },
  { "name": "[6287] 永城职业学院[较高收费]", "is_wenli": "理科", "jihua": 10, "luqu": 3, "min_score": 181 },
  { "name": "[6288] 信阳职业技术学院", "is_wenli": "理科", "jihua": 1294, "luqu": 1294, "min_score": 307 },
  { "name": "[6289] 河南工业贸易职业学院", "is_wenli": "理科", "jihua": 1883, "luqu": 1883, "min_score": 258 },
  { "name": "[6290] 河南艺术职业学院", "is_wenli": "理科", "jihua": 1239, "luqu": 1277, "min_score": 213 },
  { "name": "[6290] 河南艺术职业学院[较高收费]", "is_wenli": "理科", "jihua": 40, "luqu": 49, "min_score": 172 },
  { "name": "[6291] 新乡职业技术学院", "is_wenli": "理科", "jihua": 795, "luqu": 857, "min_score": 234 },
  { "name": "[6291] 新乡职业技术学院[较高收费]", "is_wenli": "理科", "jihua": 35, "luqu": 35, "min_score": 183 },
  { "name": "[6292] 安阳职业技术学院", "is_wenli": "理科", "jihua": 715, "luqu": 724, "min_score": 250 },
  { "name": "[6293] 驻马店职业技术学院", "is_wenli": "理科", "jihua": 690, "luqu": 831, "min_score": 180 },
  { "name": "[6295] 郑州城市职业学院", "is_wenli": "理科", "jihua": 1240, "luqu": 1341, "min_score": 160 },
  { "name": "[6296] 漯河食品职业学院", "is_wenli": "理科", "jihua": 566, "luqu": 359, "min_score": 160 },
  { "name": "[6297] 郑州理工职业学院", "is_wenli": "理科", "jihua": 1340, "luqu": 1588, "min_score": 180 },
  { "name": "[6298] 郑州信息工程职业学院", "is_wenli": "理科", "jihua": 895, "luqu": 948, "min_score": 161 },
  { "name": "[6298] 郑州信息工程职业学院[较高收费]", "is_wenli": "理科", "jihua": 10, "luqu": 8, "min_score": 182 },
  { "name": "[6299] 焦作工贸职业学院", "is_wenli": "理科", "jihua": 500, "luqu": 669, "min_score": 184 },
  { "name": "[6300] 长垣烹饪职业技术学院", "is_wenli": "理科", "jihua": 725, "luqu": 117, "min_score": 161 },
  { "name": "[6301] 许昌陶瓷职业学院", "is_wenli": "理科", "jihua": 710, "luqu": 285, "min_score": 161 },
  { "name": "[6303] 郑州电力职业技术学院", "is_wenli": "理科", "jihua": 450, "luqu": 520, "min_score": 220 },
  { "name": "[6304] 河南科技职业大学(原周口科技职业学院)", "is_wenli": "理科", "jihua": 1140, "luqu": 974, "min_score": 160 },
  { "name": "[6305] 郑州澍青医学高等专科学校", "is_wenli": "理科", "jihua": 461, "luqu": 537, "min_score": 231 },
  { "name": "[6305] 郑州澍青医学高等专科学校[较高收费]", "is_wenli": "理科", "jihua": 40, "luqu": 40, "min_score": 280 },
  { "name": "[6309] 郑州电子信息职业技术学院", "is_wenli": "理科", "jihua": 1514, "luqu": 2042, "min_score": 160 },
  { "name": "[6310] 嵩山少林武术职业学院", "is_wenli": "理科", "jihua": 345, "luqu": 46, "min_score": 162 },
  { "name": "[6316] 开封文化艺术职业学院", "is_wenli": "理科", "jihua": 793, "luqu": 804, "min_score": 189 },
  { "name": "[6317] 河南医学高等专科学校", "is_wenli": "理科", "jihua": 1686, "luqu": 1686, "min_score": 375 },
  { "name": "[6317] 河南医学高等专科学校[较高收费]", "is_wenli": "理科", "jihua": 50, "luqu": 50, "min_score": 298 },
  { "name": "[6320] 河南测绘职业学院", "is_wenli": "理科", "jihua": 247, "luqu": 247, "min_score": 278 },
  { "name": "[6322] 许昌职业技术学院(软件类)", "is_wenli": "理科", "jihua": 40, "luqu": 40, "min_score": 226 },
  { "name": "[6323] 南阳医学高等专科学校(中外合作办学)", "is_wenli": "理科", "jihua": 251, "luqu": 251, "min_score": 314 },
  { "name": "[6328] 河南经贸职业学院(中外合作办学)", "is_wenli": "理科", "jihua": 38, "luqu": 38, "min_score": 310 },
  { "name": "[6330] 河南经贸职业学院(软件类)", "is_wenli": "理科", "jihua": 98, "luqu": 98, "min_score": 326 },
  { "name": "[6335] 三门峡职业技术学院(中外合作办学)", "is_wenli": "理科", "jihua": 105, "luqu": 72, "min_score": 181 },
  { "name": "[6336] 郑州铁路职业技术学院(中外合作办学)", "is_wenli": "理科", "jihua": 520, "luqu": 516, "min_score": 307 },
  { "name": "[6338] 商丘医学高等专科学校(中外合作办学)", "is_wenli": "理科", "jihua": 40, "luqu": 40, "min_score": 228 },
  { "name": "[6346] 郑州铁路职业技术学院(软件类)", "is_wenli": "理科", "jihua": 80, "luqu": 80, "min_score": 307 },
  { "name": "[6347] 商丘职业技术学院(软件类)", "is_wenli": "理科", "jihua": 180, "luqu": 120, "min_score": 181 },
  { "name": "[6349] 三门峡职业技术学院(软件类)", "is_wenli": "理科", "jihua": 70, "luqu": 56, "min_score": 190 },
  { "name": "[6353] 河南女子职业学院", "is_wenli": "理科", "jihua": 160, "luqu": 175, "min_score": 171 },
  { "name": "[6354] 河南对外经济贸易职业学院", "is_wenli": "理科", "jihua": 410, "luqu": 370, "min_score": 180 },
  { "name": "[6355] 濮阳石油化工职业技术学院", "is_wenli": "理科", "jihua": 160, "luqu": 30, "min_score": 180 },
  { "name": "[6356] 南阳科技职业学院", "is_wenli": "理科", "jihua": 320, "luqu": 140, "min_score": 161 },
  { "name": "[6357] 兰考三农职业学院", "is_wenli": "理科", "jihua": 480, "luqu": 383, "min_score": 180 },
  { "name": "[6358] 汝州职业技术学院", "is_wenli": "理科", "jihua": 310, "luqu": 99, "min_score": 162 },
  { "name": "[6359] 林州建筑职业技术学院", "is_wenli": "理科", "jihua": 430, "luqu": 54, "min_score": 160 },
  { "name": "[6360] 郑州电子商务职业学院", "is_wenli": "理科", "jihua": 400, "luqu": 177, "min_score": 160 },
  { "name": "[6361] 郑州轨道工程职业学院", "is_wenli": "理科", "jihua": 750, "luqu": 282, "min_score": 160 },
  { "name": "[6362] 郑州体育职业学院", "is_wenli": "理科", "jihua": 290, "luqu": 21, "min_score": 161 },
  { "name": "[6379] 郑州财税金融职业学院", "is_wenli": "理科", "jihua": 521, "luqu": 516, "min_score": 312 },
  { "name": "[6379] 郑州财税金融职业学院[较高收费]", "is_wenli": "理科", "jihua": 12, "luqu": 12, "min_score": 245 },
  { "name": "[6380] 南阳农业职业学院", "is_wenli": "理科", "jihua": 949, "luqu": 949, "min_score": 181 },
  { "name": "[6381] 河南推拿职业学院", "is_wenli": "理科", "jihua": 130, "luqu": 130, "min_score": 225 },
  { "name": "[6382] 河南机电职业学院", "is_wenli": "理科", "jihua": 2941, "luqu": 2956, "min_score": 222 },
  { "name": "[6383] 河南护理职业学院", "is_wenli": "理科", "jihua": 560, "luqu": 586, "min_score": 279 },
  { "name": "[6383] 河南护理职业学院[较高收费]", "is_wenli": "理科", "jihua": 40, "luqu": 16, "min_score": 184 },
  { "name": "[6384] 洛阳职业技术学院", "is_wenli": "理科", "jihua": 1127, "luqu": 1154, "min_score": 280 },
  { "name": "[6384] 洛阳职业技术学院[较高收费]", "is_wenli": "理科", "jihua": 150, "luqu": 78, "min_score": 160 },
  { "name": "[6385] 许昌电气职业学院", "is_wenli": "理科", "jihua": 1110, "luqu": 960, "min_score": 180 },
  { "name": "[6386] 郑州商贸旅游职业学院", "is_wenli": "理科", "jihua": 765, "luqu": 425, "min_score": 161 },
  { "name": "[6387] 鹤壁汽车工程职业学院", "is_wenli": "理科", "jihua": 750, "luqu": 205, "min_score": 160 },
  { "name": "[6388] 信阳涉外职业技术学院", "is_wenli": "理科", "jihua": 333, "luqu": 123, "min_score": 161 },
  { "name": "[6389] 南阳职业学院", "is_wenli": "理科", "jihua": 900, "luqu": 827, "min_score": 160 },
  { "name": "[6390] 郑州黄河护理职业学院", "is_wenli": "理科", "jihua": 940, "luqu": 893, "min_score": 180 },
  { "name": "[6391] 洛阳科技职业学院", "is_wenli": "理科", "jihua": 723, "luqu": 807, "min_score": 160 },
  { "name": "[6391] 洛阳科技职业学院", "is_wenli": "理科", "jihua": 723, "luqu": 807, "min_score": 160 },
  { "name": "[6391] 洛阳科技职业学院[较高收费]", "is_wenli": "理科", "jihua": 10, "luqu": 2, "min_score": 257 },
  { "name": "[6392] 鹤壁能源化工职业学院", "is_wenli": "理科", "jihua": 40, "luqu": 54, "min_score": 161 },
  { "name": "[6393] 三门峡社会管理职业学院", "is_wenli": "理科", "jihua": 420, "luqu": 560, "min_score": 186 },
  { "name": "[6502] 河南师范大学新联学院", "is_wenli": "理科", "jihua": 210, "luqu": 210, "min_score": 394 },
  { "name": "[6504] 郑州经贸学院(原中原工学院信息商务学院)", "is_wenli": "理科", "jihua": 510, "luqu": 400, "min_score": 181 },
  { "name": "[6505] 新乡医学院三全学院", "is_wenli": "理科", "jihua": 146, "luqu": 146, "min_score": 400 },
  { "name": "[6505] 新乡医学院三全学院[护理]", "is_wenli": "理科", "jihua": 40, "luqu": 40, "min_score": 262 },
  { "name": "[6506] 郑州工商学院", "is_wenli": "理科", "jihua": 255, "luqu": 254, "min_score": 300 },
  { "name": "[6507] 安阳学院", "is_wenli": "理科", "jihua": 430, "luqu": 430, "min_score": 236 },
  { "name": "[6510] 郑州商学院(原郑州成功财经学院)", "is_wenli": "理科", "jihua": 376, "luqu": 376, "min_score": 303 },
  { "name": "[6510] 郑州商学院(原郑州成功财经学院)[较高收费]", "is_wenli": "理科", "jihua": 70, "luqu": 70, "min_score": 217 },
  { "name": "[6538] 郑州卫生健康职业学院", "is_wenli": "理科", "jihua": 210, "luqu": 210, "min_score": 290 },
  { "name": "[6539] 河南物流职业学院", "is_wenli": "理科", "jihua": 465, "luqu": 465, "min_score": 221 },
  { "name": "[6540] 河南地矿职业学院", "is_wenli": "理科", "jihua": 440, "luqu": 440, "min_score": 199 },
  { "name": "[6541] 信阳航空职业学院", "is_wenli": "理科", "jihua": 875, "luqu": 292, "min_score": 160 },
  { "name": "[6595] 四川文化艺术学院", "is_wenli": "理科", "jihua": 12, "luqu": 5, "min_score": 214 },
  { "name": "[6609] 文华学院(原华中科技大学文华学院)", "is_wenli": "理科", "jihua": 80, "luqu": 80, "min_score": 309 },
  { "name": "[6631] 华北理工大学轻工学院", "is_wenli": "理科", "jihua": 28, "luqu": 28, "min_score": 202 },
  { "name": "[6640] 温州商学院", "is_wenli": "理科", "jihua": 50, "luqu": 4, "min_score": 239 },
  { "name": "[6647] 青岛农业大学海都学院", "is_wenli": "理科", "jihua": 80, "luqu": 29, "min_score": 185 },
  { "name": "[6648] 齐鲁理工学院(原曲阜师范大学杏坛学院)", "is_wenli": "理科", "jihua": 53, "luqu": 37, "min_score": 206 },
  { "name": "[6649] 山东财经大学东方学院", "is_wenli": "理科", "jihua": 10, "luqu": 6, "min_score": 266 },
  { "name": "[6654] 辽宁理工学院", "is_wenli": "理科", "jihua": 15, "luqu": 1, "min_score": 441 },
  { "name": "[6655] 大连财经学院", "is_wenli": "理科", "jihua": 74, "luqu": 37, "min_score": 193 },
  { "name": "[6664] 四川大学锦城学院", "is_wenli": "理科", "jihua": 62, "luqu": 62, "min_score": 182 },
  { "name": "[6665] 西安明德理工学院(原西北工业大学明德学院)", "is_wenli": "理科", "jihua": 42, "luqu": 18, "min_score": 189 },
  { "name": "[6671] 沈阳城市建设学院(原沈阳建筑大学城市建设学院)", "is_wenli": "理科", "jihua": 68, "luqu": 9, "min_score": 286 },
  { "name": "[6672] 武汉晴川学院(原武汉大学珞珈学院)", "is_wenli": "理科", "jihua": 55, "luqu": 55, "min_score": 210 },
  { "name": "[6677] 西南财经大学天府学院", "is_wenli": "理科", "jihua": 9, "luqu": 9, "min_score": 285 },
  { "name": "[6686] 西安科技大学高新学院", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 393 },
  { "name": "[6707] 中国矿业大学银川学院", "is_wenli": "理科", "jihua": 125, "luqu": 19, "min_score": 182 },
  { "name": "[6786] 沈阳工学院", "is_wenli": "理科", "jihua": 14, "luqu": 14, "min_score": 259 },
  { "name": "[6789] 辽宁何氏医学院", "is_wenli": "理科", "jihua": 63, "luqu": 8, "min_score": 214 },
  { "name": "[6832] 山东科技大学泰山科技学院", "is_wenli": "理科", "jihua": 20, "luqu": 12, "min_score": 208 },
  { "name": "[6833] 烟台大学文经学院", "is_wenli": "理科", "jihua": 6, "luqu": 6, "min_score": 213 },
  { "name": "[6836] 武汉东湖学院", "is_wenli": "理科", "jihua": 29, "luqu": 29, "min_score": 251 },
  { "name": "[6837] 汉口学院", "is_wenli": "理科", "jihua": 38, "luqu": 38, "min_score": 209 },
  { "name": "[6838] 武昌理工学院", "is_wenli": "理科", "jihua": 58, "luqu": 66, "min_score": 181 },
  { "name": "[6839] 武昌首义学院(原华中科技大学武昌分校)", "is_wenli": "理科", "jihua": 85, "luqu": 85, "min_score": 197 },
  { "name": "[6841] 长江大学工程技术学院", "is_wenli": "理科", "jihua": 73, "luqu": 40, "min_score": 182 },
  { "name": "[6842] 长江大学文理学院", "is_wenli": "理科", "jihua": 8, "luqu": 7, "min_score": 220 },
  { "name": "[6845] 湖北商贸学院", "is_wenli": "理科", "jihua": 50, "luqu": 44, "min_score": 189 },
  { "name": "[6847] 湖北民族大学科技学院(原湖北民族学院科技学院)", "is_wenli": "理科", "jihua": 65, "luqu": 65, "min_score": 253 },
  { "name": "[6849] 湖北师范大学文理学院", "is_wenli": "理科", "jihua": 40, "luqu": 40, "min_score": 328 },
  { "name": "[6851] 三峡大学科技学院", "is_wenli": "理科", "jihua": 6, "luqu": 4, "min_score": 341 },
  { "name": "[6852] 武昌工学院", "is_wenli": "理科", "jihua": 20, "luqu": 20, "min_score": 228 },
  { "name": "[6855] 武汉纺织大学外经贸学院", "is_wenli": "理科", "jihua": 43, "luqu": 31, "min_score": 184 },
  { "name": "[6858] 湖北文理学院理工学院", "is_wenli": "理科", "jihua": 112, "luqu": 112, "min_score": 225 },
  { "name": "[6859] 湖北工程学院新技术学院", "is_wenli": "理科", "jihua": 45, "luqu": 41, "min_score": 192 },
  { "name": "[6861] 武汉工程科技学院(原中国地质大学江城学院)", "is_wenli": "理科", "jihua": 63, "luqu": 63, "min_score": 207 },
  { "name": "[6862] 武汉学院", "is_wenli": "理科", "jihua": 30, "luqu": 30, "min_score": 388 },
  { "name": "[6863] 武汉工商学院", "is_wenli": "理科", "jihua": 38, "luqu": 38, "min_score": 200 },
  { "name": "[6889] 重庆邮电大学移通学院", "is_wenli": "理科", "jihua": 6, "luqu": 6, "min_score": 393 },
  { "name": "[6891] 成都理工大学工程技术学院", "is_wenli": "理科", "jihua": 3, "luqu": 3, "min_score": 378 },
  { "name": "[6897] 四川外国语大学成都学院", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 374 },
  { "name": "[6926] 保定理工学院(原中国地质大学长城学院)", "is_wenli": "理科", "jihua": 15, "luqu": 4, "min_score": 196 },
  { "name": "[6939] 大连科技学院", "is_wenli": "理科", "jihua": 50, "luqu": 11, "min_score": 296 },
  { "name": "[6943] 大连东软信息学院", "is_wenli": "理科", "jihua": 70, "luqu": 22, "min_score": 211 },
  { "name": "[6974] 青岛理工大学琴岛学院", "is_wenli": "理科", "jihua": 23, "luqu": 23, "min_score": 326 },
  { "name": "[6977] 燕京理工学院(原北京化工大学北方学院)", "is_wenli": "理科", "jihua": 46, "luqu": 21, "min_score": 197 },
  { "name": "[6979] 武汉传媒学院", "is_wenli": "理科", "jihua": 25, "luqu": 25, "min_score": 221 },
  { "name": "[6983] 西南交通大学希望学院", "is_wenli": "理科", "jihua": 13, "luqu": 12, "min_score": 216 },
  { "name": "[6985] 成都银杏酒店管理学院(原成都信息工程大学银杏酒店管理学院)", "is_wenli": "理科", "jihua": 9, "luqu": 1, "min_score": 207 },
  { "name": "[7005] 新疆兵团警官高等专科学校", "is_wenli": "理科", "jihua": 20, "luqu": 20, "min_score": 274 },
  { "name": "[7021] 天津医学高等专科学校", "is_wenli": "理科", "jihua": 149, "luqu": 149, "min_score": 404 },
  { "name": "[7032] 承德石油高等专科学校", "is_wenli": "理科", "jihua": 50, "luqu": 38, "min_score": 208 },
  { "name": "[7036] 邢台医学高等专科学校", "is_wenli": "理科", "jihua": 27, "luqu": 27, "min_score": 367 },
  { "name": "[7038] 湖北幼儿师范高等专科学校", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 364 },
  { "name": "[7057] 山西财政税务专科学校", "is_wenli": "理科", "jihua": 22, "luqu": 22, "min_score": 253 },
  { "name": "[7059] 运城幼儿师范高等专科学校", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 206 },
  { "name": "[7073] 阜新高等专科学校", "is_wenli": "理科", "jihua": 46, "luqu": 17, "min_score": 187 },
  { "name": "[7075] 辽宁省交通高等专科学校", "is_wenli": "理科", "jihua": 55, "luqu": 36, "min_score": 213 },
  { "name": "[7078] 朝阳师范高等专科学校", "is_wenli": "理科", "jihua": 42, "luqu": 26, "min_score": 214 },
  { "name": "[7079] 抚顺师范高等专科学校", "is_wenli": "理科", "jihua": 19, "luqu": 18, "min_score": 184 },
  { "name": "[7080] 锦州师范高等专科学校", "is_wenli": "理科", "jihua": 65, "luqu": 14, "min_score": 203 },
  { "name": "[7081] 铁岭师范高等专科学校", "is_wenli": "理科", "jihua": 54, "luqu": 18, "min_score": 252 },
  { "name": "[7091] 长春汽车工业高等专科学校", "is_wenli": "理科", "jihua": 90, "luqu": 32, "min_score": 183 },
  { "name": "[7092] 哈尔滨幼儿师范高等专科学校", "is_wenli": "理科", "jihua": 15, "luqu": 6, "min_score": 194 },
  { "name": "[7094] 长春医学高等专科学校", "is_wenli": "理科", "jihua": 109, "luqu": 109, "min_score": 358 },
  { "name": "[7095] 黑龙江幼儿师范高等专科学校", "is_wenli": "理科", "jihua": 26, "luqu": 8, "min_score": 274 },
  { "name": "[7096] 长春金融高等专科学校", "is_wenli": "理科", "jihua": 30, "luqu": 30, "min_score": 247 },
  { "name": "[7098] 吉林警察学院", "is_wenli": "理科", "jihua": 14, "luqu": 14, "min_score": 360 },
  { "name": "[7099] 南通师范高等专科学校", "is_wenli": "理科", "jihua": 16, "luqu": 16, "min_score": 229 },
  { "name": "[7100] 盐城幼儿师范高等专科学校", "is_wenli": "理科", "jihua": 45, "luqu": 15, "min_score": 194 },
  { "name": "[7103] 齐齐哈尔高等师范专科学校", "is_wenli": "理科", "jihua": 25, "luqu": 20, "min_score": 183 },
  { "name": "[7104] 鹤岗师范高等专科学校", "is_wenli": "理科", "jihua": 80, "luqu": 27, "min_score": 186 },
  { "name": "[7105] 大庆医学高等专科学校", "is_wenli": "理科", "jihua": 42, "luqu": 42, "min_score": 259 },
  { "name": "[7107] 黑龙江护理高等专科学校", "is_wenli": "理科", "jihua": 55, "luqu": 17, "min_score": 180 },
  { "name": "[7112] 上海出版印刷高等专科学校", "is_wenli": "理科", "jihua": 24, "luqu": 25, "min_score": 389 },
  { "name": "[7112] 上海出版印刷高等专科学校[较高收费]", "is_wenli": "理科", "jihua": 4, "luqu": 4, "min_score": 258 },
  { "name": "[7113] 上海健康医学院", "is_wenli": "理科", "jihua": 31, "luqu": 31, "min_score": 426 },
  { "name": "[7113] 上海健康医学院[较高收费]", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 384 },
  { "name": "[7113] 上海健康医学院[护理]", "is_wenli": "理科", "jihua": 3, "luqu": 1, "min_score": 394 },
  { "name": "[7117] 上海旅游高等专科学校", "is_wenli": "理科", "jihua": 31, "luqu": 31, "min_score": 411 },
  { "name": "[7117] 上海旅游高等专科学校[较高收费]", "is_wenli": "理科", "jihua": 1, "luqu": 1, "min_score": 281 },
  { "name": "[7122] 镇江市高等专科学校", "is_wenli": "理科", "jihua": 70, "luqu": 73, "min_score": 184 },
  { "name": "[7123] 江苏城市职业学院", "is_wenli": "理科", "jihua": 33, "luqu": 33, "min_score": 235 },
  { "name": "[7125] 连云港师范高等专科学校", "is_wenli": "理科", "jihua": 50, "luqu": 47, "min_score": 382 },
  { "name": "[7134] 浙江医药高等专科学校", "is_wenli": "理科", "jihua": 149, "luqu": 144, "min_score": 183 },
  { "name": "[7138] 安庆医药高等专科学校", "is_wenli": "理科", "jihua": 20, "luqu": 20, "min_score": 331 },
  { "name": "[7143] 安徽中医药高等专科学校", "is_wenli": "理科", "jihua": 22, "luqu": 22, "min_score": 380 },
  { "name": "[7145] 桐城师范高等专科学校", "is_wenli": "理科", "jihua": 2, "luqu": 2, "min_score": 267 },
  { "name": "[7157] 厦门医学院", "is_wenli": "理科", "jihua": 36, "luqu": 36, "min_score": 420 },
  { "name": "[7161] 景德镇学院", "is_wenli": "理科", "jihua": 23, "luqu": 23, "min_score": 336 },
  { "name": "[7163] 萍乡学院", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 301 },
  { "name": "[7173] 山东医学高等专科学校", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 428 },
  { "name": "[7174] 菏泽医学专科学校", "is_wenli": "理科", "jihua": 66, "luqu": 64, "min_score": 270 },
  { "name": "[7176] 济宁学院", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 349 },
  { "name": "[7193] 湖北中医药高等专科学校", "is_wenli": "理科", "jihua": 59, "luqu": 59, "min_score": 414 },
  { "name": "[7195] 汉江师范学院", "is_wenli": "理科", "jihua": 30, "luqu": 30, "min_score": 408 },
  { "name": "[7202] 湖南幼儿师范高等专科学校", "is_wenli": "理科", "jihua": 6, "luqu": 6, "min_score": 381 },
  { "name": "[7207] 益阳医学高等专科学校", "is_wenli": "理科", "jihua": 8, "luqu": 8, "min_score": 398 },
  { "name": "[7212] 广东茂名幼儿师范专科学校", "is_wenli": "理科", "jihua": 9, "luqu": 7, "min_score": 198 },
  { "name": "[7225] 闽江师范高等专科学校", "is_wenli": "理科", "jihua": 38, "luqu": 35, "min_score": 267 },
  { "name": "[7227] 桂林师范高等专科学校", "is_wenli": "理科", "jihua": 3, "luqu": 3, "min_score": 235 },
  { "name": "[7228] 广西科技师范学院(原柳州师范高等专科学校)", "is_wenli": "理科", "jihua": 6, "luqu": 6, "min_score": 335 },
  { "name": "[7229] 桂林旅游学院(原桂林旅游高等专科学校)", "is_wenli": "理科", "jihua": 8, "luqu": 4, "min_score": 272 },
  { "name": "[7232] 广西体育高等专科学校", "is_wenli": "理科", "jihua": 7, "luqu": 2, "min_score": 187 },
  { "name": "[7233] 广西警察学院", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 277 },
  { "name": "[7241] 重庆电力高等专科学校", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 351 },
  { "name": "[7242] 重庆三峡医药高等专科学校", "is_wenli": "理科", "jihua": 45, "luqu": 45, "min_score": 330 },
  { "name": "[7243] 重庆医药高等专科学校", "is_wenli": "理科", "jihua": 34, "luqu": 34, "min_score": 414 },
  { "name": "[7243] 重庆医药高等专科学校[较高收费]", "is_wenli": "理科", "jihua": 3, "luqu": 3, "min_score": 188 },
  { "name": "[7253] 成都纺织高等专科学校", "is_wenli": "理科", "jihua": 14, "luqu": 14, "min_score": 365 },
  { "name": "[7257] 川南幼儿师范高等专科学校", "is_wenli": "理科", "jihua": 2, "luqu": 1, "min_score": 230 },
  { "name": "[7271] 黔南民族医学高等专科学校", "is_wenli": "理科", "jihua": 15, "luqu": 14, "min_score": 285 },
  { "name": "[7275] 遵义医药高等专科学校", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 383 },
  { "name": "[7276] 铜仁幼儿师范高等专科学校", "is_wenli": "理科", "jihua": 2, "luqu": 1, "min_score": 288 },
  { "name": "[7281] 昆明冶金高等专科学校", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 237 },
  { "name": "[7282] 曲靖医学高等专科学校", "is_wenli": "理科", "jihua": 7, "luqu": 7, "min_score": 367 },
  { "name": "[7286] 楚雄医药高等专科学校", "is_wenli": "理科", "jihua": 7, "luqu": 7, "min_score": 184 },
  { "name": "[7291] 西安电力高等专科学校", "is_wenli": "理科", "jihua": 20, "luqu": 20, "min_score": 385 },
  { "name": "[7292] 西安航空学院", "is_wenli": "理科", "jihua": 4, "luqu": 4, "min_score": 420 },
  { "name": "[7307] 甘肃医学院", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 429 },
  { "name": "[7332] 新疆维吾尔医学专科学校", "is_wenli": "理科", "jihua": 10, "luqu": 2, "min_score": 226 },
  { "name": "[7333] 和田师范专科学校", "is_wenli": "理科", "jihua": 28, "luqu": 15, "min_score": 217 },
  { "name": "[7337] 白城医学高等专科学校", "is_wenli": "理科", "jihua": 58, "luqu": 54, "min_score": 204 },
  { "name": "[7338] 杭州医学院", "is_wenli": "理科", "jihua": 26, "luqu": 26, "min_score": 451 },
  { "name": "[7340] 泉州医学高等专科学校", "is_wenli": "理科", "jihua": 75, "luqu": 74, "min_score": 217 },
  { "name": "[7341] 豫章师范学院", "is_wenli": "理科", "jihua": 4, "luqu": 4, "min_score": 419 },
  { "name": "[7342] 江西中医药高等专科学校", "is_wenli": "理科", "jihua": 20, "luqu": 20, "min_score": 365 },
  { "name": "[7344] 山东中医药高等专科学校", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 435 },
  { "name": "[7347] 肇庆医学高等专科学校", "is_wenli": "理科", "jihua": 30, "luqu": 30, "min_score": 334 },
  { "name": "[7348] 琼台师范学院", "is_wenli": "理科", "jihua": 9, "luqu": 9, "min_score": 396 },
  { "name": "[7352] 赣州师范高等专科学校", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 367 },
  { "name": "[7357] 江西师范高等专科学校", "is_wenli": "理科", "jihua": 25, "luqu": 25, "min_score": 394 },
  { "name": "[7402] 石家庄邮电职业技术学院", "is_wenli": "理科", "jihua": 52, "luqu": 52, "min_score": 373 },
  { "name": "[7403] 石家庄幼儿师范高等专科学校", "is_wenli": "理科", "jihua": 4, "luqu": 4, "min_score": 359 },
  { "name": "[7404] 长春师范高等专科学校", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 329 },
  { "name": "[7406] 泉州幼儿师范高等专科学校", "is_wenli": "理科", "jihua": 4, "luqu": 4, "min_score": 315 },
  { "name": "[7407] 新疆师范高等专科学校", "is_wenli": "理科", "jihua": 22, "luqu": 19, "min_score": 200 },
  { "name": "[7451] 邢台职业技术学院", "is_wenli": "理科", "jihua": 23, "luqu": 14, "min_score": 234 },
  { "name": "[7452] 深圳职业技术学院", "is_wenli": "理科", "jihua": 34, "luqu": 34, "min_score": 455 },
  { "name": "[7453] 成都航空职业技术学院", "is_wenli": "理科", "jihua": 23, "luqu": 23, "min_score": 421 },
  { "name": "[7454] 四川工程职业技术学院", "is_wenli": "理科", "jihua": 6, "luqu": 6, "min_score": 346 },
  { "name": "[7455] 四川交通职业技术学院", "is_wenli": "理科", "jihua": 16, "luqu": 16, "min_score": 341 },
  { "name": "[7456] 石家庄铁路职业技术学院", "is_wenli": "理科", "jihua": 70, "luqu": 70, "min_score": 325 },
  { "name": "[7458] 河北工业职业技术学院", "is_wenli": "理科", "jihua": 11, "luqu": 11, "min_score": 253 },
  { "name": "[7459] 常州信息职业技术学院", "is_wenli": "理科", "jihua": 76, "luqu": 76, "min_score": 393 },
  { "name": "[7462] 青岛港湾职业技术学院", "is_wenli": "理科", "jihua": 37, "luqu": 37, "min_score": 191 },
  { "name": "[7463] 江苏建筑职业技术学院", "is_wenli": "理科", "jihua": 115, "luqu": 115, "min_score": 321 },
  { "name": "[7501] 上海第二工业大学", "is_wenli": "理科", "jihua": 36, "luqu": 36, "min_score": 427 },
  { "name": "[7502] 荆楚理工学院", "is_wenli": "理科", "jihua": 64, "luqu": 64, "min_score": 422 },
  { "name": "[7563] 湖北第二师范学院", "is_wenli": "理科", "jihua": 36, "luqu": 36, "min_score": 417 },
  { "name": "[7602] 西安培华学院", "is_wenli": "理科", "jihua": 27, "luqu": 27, "min_score": 283 },
  { "name": "[7608] 上海杉达学院", "is_wenli": "理科", "jihua": 15, "luqu": 8, "min_score": 194 },
  { "name": "[7609] 上海建桥学院", "is_wenli": "理科", "jihua": 12, "luqu": 12, "min_score": 405 },
  { "name": "[7611] 浙江树人学院", "is_wenli": "理科", "jihua": 30, "luqu": 17, "min_score": 250 },
  { "name": "[7614] 烟台南山学院", "is_wenli": "理科", "jihua": 20, "luqu": 20, "min_score": 274 },
  { "name": "[7615] 青岛滨海学院", "is_wenli": "理科", "jihua": 8, "luqu": 7, "min_score": 232 },
  { "name": "[7616] 武汉生物工程学院", "is_wenli": "理科", "jihua": 75, "luqu": 75, "min_score": 375 },
  { "name": "[7621] 西安翻译学院", "is_wenli": "理科", "jihua": 66, "luqu": 57, "min_score": 183 },
  { "name": "[7622] 西安欧亚学院", "is_wenli": "理科", "jihua": 120, "luqu": 63, "min_score": 190 },
  { "name": "[7623] 西安外事学院", "is_wenli": "理科", "jihua": 78, "luqu": 76, "min_score": 186 },
  { "name": "[7625] 宁夏理工学院", "is_wenli": "理科", "jihua": 37, "luqu": 37, "min_score": 209 },
  { "name": "[7626] 南昌理工学院", "is_wenli": "理科", "jihua": 58, "luqu": 58, "min_score": 220 },
  { "name": "[7627] 西安思源学院", "is_wenli": "理科", "jihua": 60, "luqu": 62, "min_score": 189 },
  { "name": "[7628] 陕西国际商贸学院", "is_wenli": "理科", "jihua": 10, "luqu": 3, "min_score": 268 },
  { "name": "[7629] 齐鲁医药学院", "is_wenli": "理科", "jihua": 56, "luqu": 56, "min_score": 228 },
  { "name": "[7634] 潍坊科技学院", "is_wenli": "理科", "jihua": 85, "luqu": 19, "min_score": 200 },
  { "name": "[7635] 天津天狮学院", "is_wenli": "理科", "jihua": 9, "luqu": 9, "min_score": 252 },
  { "name": "[7636] 海口经济学院", "is_wenli": "理科", "jihua": 167, "luqu": 38, "min_score": 182 },
  { "name": "[7701] 天津中德应用技术大学", "is_wenli": "理科", "jihua": 76, "luqu": 76, "min_score": 407 },
  { "name": "[7702] 广州民航职业技术学院", "is_wenli": "理科", "jihua": 57, "luqu": 57, "min_score": 360 },
  { "name": "[7702] 广州民航职业技术学院[较高收费]", "is_wenli": "理科", "jihua": 34, "luqu": 34, "min_score": 382 },
  { "name": "[7703] 北京农业职业学院", "is_wenli": "理科", "jihua": 15, "luqu": 15, "min_score": 394 },
  { "name": "[7704] 河北化工医药职业技术学院", "is_wenli": "理科", "jihua": 6, "luqu": 1, "min_score": 232 },
  { "name": "[7705] 南京科技职业学院", "is_wenli": "理科", "jihua": 52, "luqu": 52, "min_score": 398 },
  { "name": "[7706] 南京信息职业技术学院", "is_wenli": "理科", "jihua": 87, "luqu": 87, "min_score": 405 },
  { "name": "[7707] 广州番禺职业技术学院", "is_wenli": "理科", "jihua": 44, "luqu": 44, "min_score": 354 },
  { "name": "[7709] 江苏商贸职业学院", "is_wenli": "理科", "jihua": 94, "luqu": 94, "min_score": 244 },
  { "name": "[7710] 江苏财会职业学院", "is_wenli": "理科", "jihua": 95, "luqu": 95, "min_score": 212 },
  { "name": "[7711] 江苏城乡建设职业学院", "is_wenli": "理科", "jihua": 40, "luqu": 22, "min_score": 190 },
  { "name": "[7712] 江苏航空职业技术学院", "is_wenli": "理科", "jihua": 76, "luqu": 64, "min_score": 187 },
  { "name": "[7713] 江苏安全技术职业学院", "is_wenli": "理科", "jihua": 54, "luqu": 53, "min_score": 184 },
  { "name": "[7715] 皖西卫生职业学院", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 323 },
  { "name": "[7717] 皖北卫生职业学院", "is_wenli": "理科", "jihua": 6, "luqu": 6, "min_score": 281 },
  { "name": "[7720] 武汉海事职业学院", "is_wenli": "理科", "jihua": 15, "luqu": 3, "min_score": 192 },
  { "name": "[7721] 长江艺术工程职业学院", "is_wenli": "理科", "jihua": 50, "luqu": 13, "min_score": 187 },
  { "name": "[7722] 武汉铁路桥梁职业学院", "is_wenli": "理科", "jihua": 82, "luqu": 82, "min_score": 241 },
  { "name": "[7726] 宣化科技职业学院", "is_wenli": "理科", "jihua": 5, "luqu": 1, "min_score": 269 },
  { "name": "[7728] 包头铁道职业技术学院", "is_wenli": "理科", "jihua": 6, "luqu": 6, "min_score": 198 },
  { "name": "[7730] 黑龙江冰雪体育职业学院", "is_wenli": "理科", "jihua": 26, "luqu": 1, "min_score": 225 },
  { "name": "[7901] 石家庄医学高等专科学校", "is_wenli": "理科", "jihua": 34, "luqu": 35, "min_score": 197 },
  { "name": "[7902] 石家庄人民医学高等专科学校", "is_wenli": "理科", "jihua": 19, "luqu": 19, "min_score": 240 },
  { "name": "[7903] 西安医学高等专科学校", "is_wenli": "理科", "jihua": 26, "luqu": 26, "min_score": 385 },
  { "name": "[8001] 新疆生产建设兵团兴新职业技术学院", "is_wenli": "理科", "jihua": 120, "luqu": 4, "min_score": 251 },
  { "name": "[8003] 长沙航空职业技术学院", "is_wenli": "理科", "jihua": 21, "luqu": 21, "min_score": 399 },
  { "name": "[8007] 新疆石河子职业技术学院", "is_wenli": "理科", "jihua": 46, "luqu": 17, "min_score": 197 },
  { "name": "[8008] 天津铁道职业技术学院", "is_wenli": "理科", "jihua": 134, "luqu": 134, "min_score": 275 },
  { "name": "[8009] 天津城市建设管理职业技术学院", "is_wenli": "理科", "jihua": 67, "luqu": 17, "min_score": 203 },
  { "name": "[8010] 天津生物工程职业技术学院", "is_wenli": "理科", "jihua": 25, "luqu": 19, "min_score": 185 },
  { "name": "[8011] 北京工业职业技术学院", "is_wenli": "理科", "jihua": 13, "luqu": 13, "min_score": 421 },
  { "name": "[8012] 北京信息职业技术学院", "is_wenli": "理科", "jihua": 18, "luqu": 18, "min_score": 422 },
  { "name": "[8013] 天津海运职业学院", "is_wenli": "理科", "jihua": 47, "luqu": 14, "min_score": 195 },
  { "name": "[8016] 北京科技经营管理学院", "is_wenli": "理科", "jihua": 4, "luqu": 3, "min_score": 319 },
  { "name": "[8017] 北京劳动保障职业学院", "is_wenli": "理科", "jihua": 32, "luqu": 29, "min_score": 218 },
  { "name": "[8018] 北京网络职业学院", "is_wenli": "理科", "jihua": 20, "luqu": 18, "min_score": 281 },
  { "name": "[8044] 大连软件职业学院", "is_wenli": "理科", "jihua": 13, "luqu": 3, "min_score": 181 },
  { "name": "[8048] 北京经贸职业学院", "is_wenli": "理科", "jihua": 20, "luqu": 20, "min_score": 311 },
  { "name": "[8053] 天津滨海职业学院", "is_wenli": "理科", "jihua": 47, "luqu": 47, "min_score": 364 },
  { "name": "[8055] 天津现代职业技术学院", "is_wenli": "理科", "jihua": 54, "luqu": 54, "min_score": 357 },
  { "name": "[8056] 吉林水利电力职业学院", "is_wenli": "理科", "jihua": 74, "luqu": 10, "min_score": 198 },
  { "name": "[8057] 天津电子信息职业技术学院", "is_wenli": "理科", "jihua": 86, "luqu": 86, "min_score": 393 },
  { "name": "[8060] 天津机电职业技术学院", "is_wenli": "理科", "jihua": 50, "luqu": 50, "min_score": 312 },
  { "name": "[8061] 天津交通职业学院", "is_wenli": "理科", "jihua": 17, "luqu": 17, "min_score": 268 },
  { "name": "[8062] 天津轻工职业技术学院", "is_wenli": "理科", "jihua": 72, "luqu": 72, "min_score": 319 },
  { "name": "[8063] 天津商务职业学院", "is_wenli": "理科", "jihua": 191, "luqu": 165, "min_score": 180 },
  { "name": "[8064] 天津国土资源和房屋职业学院", "is_wenli": "理科", "jihua": 19, "luqu": 19, "min_score": 211 },
  { "name": "[8065] 天津公安警官职业学院", "is_wenli": "理科", "jihua": 24, "luqu": 24, "min_score": 273 },
  { "name": "[8067] 天津职业大学", "is_wenli": "理科", "jihua": 226, "luqu": 226, "min_score": 261 },
  { "name": "[8094] 天津工业职业学院", "is_wenli": "理科", "jihua": 77, "luqu": 77, "min_score": 181 },
  { "name": "[8095] 天津石油职业技术学院", "is_wenli": "理科", "jihua": 169, "luqu": 72, "min_score": 181 },
  { "name": "[8096] 天津城市职业学院", "is_wenli": "理科", "jihua": 27, "luqu": 25, "min_score": 203 },
  { "name": "[8097] 北京科技职业学院", "is_wenli": "理科", "jihua": 21, "luqu": 21, "min_score": 373 },
  { "name": "[8101] 张家口职业技术学院", "is_wenli": "理科", "jihua": 11, "luqu": 3, "min_score": 243 },
  { "name": "[8104] 秦皇岛职业技术学院", "is_wenli": "理科", "jihua": 4, "luqu": 4, "min_score": 382 },
  { "name": "[8108] 邯郸职业技术学院", "is_wenli": "理科", "jihua": 6, "luqu": 3, "min_score": 216 },
  { "name": "[8110] 石家庄职业技术学院", "is_wenli": "理科", "jihua": 8, "luqu": 8, "min_score": 224 },
  { "name": "[8111] 河北软件职业技术学院", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 332 },
  { "name": "[8112] 河北交通职业技术学院", "is_wenli": "理科", "jihua": 18, "luqu": 14, "min_score": 184 },
  { "name": "[8113] 河北轨道运输职业技术学院", "is_wenli": "理科", "jihua": 25, "luqu": 25, "min_score": 229 },
  { "name": "[8115] 承德护理职业学院", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 320 },
  { "name": "[8116] 河北建材职业技术学院", "is_wenli": "理科", "jihua": 6, "luqu": 3, "min_score": 306 },
  { "name": "[8119] 佳木斯职业学院", "is_wenli": "理科", "jihua": 5, "luqu": 1, "min_score": 403 },
  { "name": "[8122] 河北对外经贸职业学院", "is_wenli": "理科", "jihua": 13, "luqu": 6, "min_score": 182 },
  { "name": "[8126] 大连枫叶职业技术学院", "is_wenli": "理科", "jihua": 35, "luqu": 5, "min_score": 209 },
  { "name": "[8130] 石家庄财经职业学院", "is_wenli": "理科", "jihua": 15, "luqu": 1, "min_score": 198 },
  { "name": "[8132] 哈尔滨传媒职业学院", "is_wenli": "理科", "jihua": 25, "luqu": 2, "min_score": 200 },
  { "name": "[8139] 河北环境工程学院", "is_wenli": "理科", "jihua": 5, "luqu": 4, "min_score": 398 },
  { "name": "[8150] 苏州百年职业学院", "is_wenli": "理科", "jihua": 73, "luqu": 13, "min_score": 219 },
  { "name": "[8163] 长治职业技术学院", "is_wenli": "理科", "jihua": 20, "luqu": 3, "min_score": 240 },
  { "name": "[8166] 山西建筑职业技术学院", "is_wenli": "理科", "jihua": 19, "luqu": 17, "min_score": 197 },
  { "name": "[8167] 山西交通职业技术学院", "is_wenli": "理科", "jihua": 8, "luqu": 6, "min_score": 196 },
  { "name": "[8168] 山西水利职业技术学院", "is_wenli": "理科", "jihua": 20, "luqu": 1, "min_score": 216 },
  { "name": "[8170] 山西工程职业学院(原山西工程职业技术学院)", "is_wenli": "理科", "jihua": 7, "luqu": 1, "min_score": 226 },
  { "name": "[8176] 太原学院", "is_wenli": "理科", "jihua": 13, "luqu": 13, "min_score": 403 },
  { "name": "[8196] 太原城市职业技术学院", "is_wenli": "理科", "jihua": 16, "luqu": 6, "min_score": 235 },
  { "name": "[8198] 昆山登云科技职业学院", "is_wenli": "理科", "jihua": 40, "luqu": 25, "min_score": 186 },
  { "name": "[8200] 三明医学科技职业学院", "is_wenli": "理科", "jihua": 47, "luqu": 11, "min_score": 181 },
  { "name": "[8203] 内蒙古建筑职业技术学院", "is_wenli": "理科", "jihua": 6, "luqu": 3, "min_score": 181 },
  { "name": "[8206] 福州软件职业技术学院", "is_wenli": "理科", "jihua": 90, "luqu": 2, "min_score": 278 },
  { "name": "[8209] 厦门兴才职业技术学院", "is_wenli": "理科", "jihua": 82, "luqu": 4, "min_score": 270 },
  { "name": "[8211] 福州墨尔本理工职业学院", "is_wenli": "理科", "jihua": 21, "luqu": 6, "min_score": 248 },
  { "name": "[8216] 山东工程职业技术大学(原山东凯文科技职业学院)", "is_wenli": "理科", "jihua": 42, "luqu": 8, "min_score": 241 },
  { "name": "[8218] 山东外国语职业技术大学(原山东外国语职业学院)", "is_wenli": "理科", "jihua": 70, "luqu": 55, "min_score": 181 },
  { "name": "[8219] 潍坊工商职业学院", "is_wenli": "理科", "jihua": 27, "luqu": 1, "min_score": 258 },
  { "name": "[8223] 山东药品食品职业学院", "is_wenli": "理科", "jihua": 9, "luqu": 5, "min_score": 279 },
  { "name": "[8224] 湖南安全技术职业学院", "is_wenli": "理科", "jihua": 5, "luqu": 1, "min_score": 195 },
  { "name": "[8225] 柳州铁道职业技术学院", "is_wenli": "理科", "jihua": 24, "luqu": 24, "min_score": 262 },
  { "name": "[8228] 重庆工商职业学院", "is_wenli": "理科", "jihua": 23, "luqu": 23, "min_score": 381 },
  { "name": "[8231] 盘锦职业技术学院", "is_wenli": "理科", "jihua": 95, "luqu": 2, "min_score": 223 },
  { "name": "[8232] 四川华新现代职业学院", "is_wenli": "理科", "jihua": 14, "luqu": 5, "min_score": 214 },
  { "name": "[8233] 大连职业技术学院", "is_wenli": "理科", "jihua": 95, "luqu": 95, "min_score": 219 },
  { "name": "[8234] 辽阳职业技术学院", "is_wenli": "理科", "jihua": 55, "luqu": 1, "min_score": 327 },
  { "name": "[8235] 营口职业技术学院", "is_wenli": "理科", "jihua": 56, "luqu": 5, "min_score": 180 },
  { "name": "[8236] 辽宁石化职业技术学院", "is_wenli": "理科", "jihua": 45, "luqu": 1, "min_score": 366 },
  { "name": "[8237] 辽宁机电职业技术学院", "is_wenli": "理科", "jihua": 35, "luqu": 2, "min_score": 241 },
  { "name": "[8238] 辽宁轨道交通职业学院", "is_wenli": "理科", "jihua": 56, "luqu": 18, "min_score": 187 },
  { "name": "[8239] 渤海船舶职业学院", "is_wenli": "理科", "jihua": 89, "luqu": 12, "min_score": 219 },
  { "name": "[8240] 辽宁金融职业学院", "is_wenli": "理科", "jihua": 33, "luqu": 5, "min_score": 236 },
  { "name": "[8241] 辽宁经济职业技术学院", "is_wenli": "理科", "jihua": 43, "luqu": 8, "min_score": 192 },
  { "name": "[8246] 辽宁广告职业学院", "is_wenli": "理科", "jihua": 36, "luqu": 1, "min_score": 317 },
  { "name": "[8254] 陕西青年职业学院", "is_wenli": "理科", "jihua": 39, "luqu": 39, "min_score": 185 },
  { "name": "[8258] 西安信息职业大学(原陕西电子科技职业学院)", "is_wenli": "理科", "jihua": 40, "luqu": 27, "min_score": 187 },
  { "name": "[8269] 辽宁农业职业技术学院", "is_wenli": "理科", "jihua": 25, "luqu": 6, "min_score": 179 },
  { "name": "[8289] 抚顺职业技术学院", "is_wenli": "理科", "jihua": 28, "luqu": 3, "min_score": 396 },
  { "name": "[8301] 辽源职业技术学院", "is_wenli": "理科", "jihua": 71, "luqu": 7, "min_score": 208 },
  { "name": "[8302] 长春职业技术学院", "is_wenli": "理科", "jihua": 177, "luqu": 28, "min_score": 210 },
  { "name": "[8303] 吉林电子信息职业技术学院", "is_wenli": "理科", "jihua": 98, "luqu": 9, "min_score": 205 },
  { "name": "[8304] 吉林工业职业技术学院", "is_wenli": "理科", "jihua": 126, "luqu": 7, "min_score": 316 },
  { "name": "[8305] 吉林交通职业技术学院", "is_wenli": "理科", "jihua": 24, "luqu": 5, "min_score": 196 },
  { "name": "[8306] 吉林工程职业学院(原吉林农业工程职业技术学院)", "is_wenli": "理科", "jihua": 52, "luqu": 1, "min_score": 339 },
  { "name": "[8307] 吉林司法警官职业学院", "is_wenli": "理科", "jihua": 9, "luqu": 9, "min_score": 247 },
  { "name": "[8308] 四平职业大学", "is_wenli": "理科", "jihua": 30, "luqu": 2, "min_score": 284 },
  { "name": "[8310] 江苏医药职业学院", "is_wenli": "理科", "jihua": 11, "luqu": 10, "min_score": 354 },
  { "name": "[8311] 苏州卫生职业技术学院", "is_wenli": "理科", "jihua": 23, "luqu": 23, "min_score": 401 },
  { "name": "[8314] 广西经济职业学院", "is_wenli": "理科", "jihua": 92, "luqu": 1, "min_score": 391 },
  { "name": "[8316] 北京经济管理职业学院", "is_wenli": "理科", "jihua": 21, "luqu": 21, "min_score": 409 },
  { "name": "[8317] 烟台汽车工程职业学院", "is_wenli": "理科", "jihua": 25, "luqu": 6, "min_score": 206 },
  { "name": "[8318] 浙江横店影视职业学院", "is_wenli": "理科", "jihua": 5, "luqu": 2, "min_score": 274 },
  { "name": "[8322] 漳州理工职业学院", "is_wenli": "理科", "jihua": 46, "luqu": 1, "min_score": 281 },
  { "name": "[8325] 广西工程职业学院", "is_wenli": "理科", "jihua": 34, "luqu": 3, "min_score": 232 },
  { "name": "[8326] 重庆财经职业学院", "is_wenli": "理科", "jihua": 27, "luqu": 27, "min_score": 359 },
  { "name": "[8328] 广州城建职业学院", "is_wenli": "理科", "jihua": 23, "luqu": 8, "min_score": 217 },
  { "name": "[8329] 江西新能源科技职业学院(原江西太阳能科技职业学院)", "is_wenli": "理科", "jihua": 62, "luqu": 28, "min_score": 185 },
  { "name": "[8331] 江西泰豪动漫职业学院", "is_wenli": "理科", "jihua": 30, "luqu": 27, "min_score": 182 },
  { "name": "[8333] 重庆科创职业学院", "is_wenli": "理科", "jihua": 11, "luqu": 2, "min_score": 248 },
  { "name": "[8335] 辽宁建筑职业学院", "is_wenli": "理科", "jihua": 24, "luqu": 2, "min_score": 186 },
  { "name": "[8337] 临沂职业学院", "is_wenli": "理科", "jihua": 16, "luqu": 1, "min_score": 426 },
  { "name": "[8338] 湖北工程职业学院", "is_wenli": "理科", "jihua": 32, "luqu": 27, "min_score": 186 },
  { "name": "[8340] 吉林铁道职业技术学院", "is_wenli": "理科", "jihua": 121, "luqu": 95, "min_score": 181 },
  { "name": "[8368] 黑龙江交通职业技术学院", "is_wenli": "理科", "jihua": 90, "luqu": 15, "min_score": 181 },
  { "name": "[8369] 浙江国际海运职业技术学院", "is_wenli": "理科", "jihua": 10, "luqu": 5, "min_score": 220 },
  { "name": "[8371] 伊春职业学院", "is_wenli": "理科", "jihua": 25, "luqu": 6, "min_score": 183 },
  { "name": "[8372] 大庆职业学院", "is_wenli": "理科", "jihua": 60, "luqu": 6, "min_score": 222 },
  { "name": "[8373] 哈尔滨职业技术学院", "is_wenli": "理科", "jihua": 115, "luqu": 40, "min_score": 183 },
  { "name": "[8374] 大兴安岭职业学院", "is_wenli": "理科", "jihua": 56, "luqu": 55, "min_score": 199 },
  { "name": "[8376] 哈尔滨铁道职业技术学院", "is_wenli": "理科", "jihua": 40, "luqu": 38, "min_score": 239 },
  { "name": "[8377] 黑龙江建筑职业技术学院", "is_wenli": "理科", "jihua": 66, "luqu": 22, "min_score": 189 },
  { "name": "[8379] 黑龙江农业工程职业学院", "is_wenli": "理科", "jihua": 66, "luqu": 8, "min_score": 162 },
  { "name": "[8380] 黑龙江农业经济职业学院", "is_wenli": "理科", "jihua": 50, "luqu": 4, "min_score": 172 },
  { "name": "[8381] 黑龙江农垦职业学院", "is_wenli": "理科", "jihua": 70, "luqu": 4, "min_score": 269 },
  { "name": "[8384] 黑龙江司法警官职业学院", "is_wenli": "理科", "jihua": 30, "luqu": 11, "min_score": 191 },
  { "name": "[8387] 牡丹江大学", "is_wenli": "理科", "jihua": 114, "luqu": 63, "min_score": 182 },
  { "name": "[8389] 齐齐哈尔工程学院", "is_wenli": "理科", "jihua": 35, "luqu": 1, "min_score": 203 },
  { "name": "[8410] 福建生物工程职业技术学院", "is_wenli": "理科", "jihua": 31, "luqu": 10, "min_score": 241 },
  { "name": "[8412] 厦门城市职业学院", "is_wenli": "理科", "jihua": 61, "luqu": 62, "min_score": 359 },
  { "name": "[8416] 山东商务职业学院", "is_wenli": "理科", "jihua": 20, "luqu": 17, "min_score": 250 },
  { "name": "[8431] 三亚理工职业学院", "is_wenli": "理科", "jihua": 26, "luqu": 18, "min_score": 181 },
  { "name": "[8434] 江西冶金职业技术学院", "is_wenli": "理科", "jihua": 40, "luqu": 22, "min_score": 186 },
  { "name": "[8435] 山东理工职业学院", "is_wenli": "理科", "jihua": 1, "luqu": 1, "min_score": 274 },
  { "name": "[8442] 上海行健职业学院", "is_wenli": "理科", "jihua": 46, "luqu": 46, "min_score": 355 },
  { "name": "[8443] 大连装备制造职业技术学院", "is_wenli": "理科", "jihua": 50, "luqu": 2, "min_score": 234 },
  { "name": "[8444] 三峡电力职业学院", "is_wenli": "理科", "jihua": 21, "luqu": 21, "min_score": 230 },
  { "name": "[8445] 上海科学技术职业学院", "is_wenli": "理科", "jihua": 47, "luqu": 47, "min_score": 408 },
  { "name": "[8446] 上海电子信息职业技术学院", "is_wenli": "理科", "jihua": 86, "luqu": 86, "min_score": 401 },
  { "name": "[8447] 上海交通职业技术学院", "is_wenli": "理科", "jihua": 57, "luqu": 57, "min_score": 324 },
  { "name": "[8448] 上海城建职业学院", "is_wenli": "理科", "jihua": 55, "luqu": 55, "min_score": 370 },
  { "name": "[8450] 上海农林职业技术学院", "is_wenli": "理科", "jihua": 35, "luqu": 35, "min_score": 301 },
  { "name": "[8451] 湖南外国语职业学院", "is_wenli": "理科", "jihua": 6, "luqu": 2, "min_score": 304 },
  { "name": "[8453] 上海工商职业技术学院(原上海新侨职业技术学院)", "is_wenli": "理科", "jihua": 48, "luqu": 37, "min_score": 182 },
  { "name": "[8454] 上海中侨职业技术大学(原上海中侨职业技术学院)", "is_wenli": "理科", "jihua": 50, "luqu": 43, "min_score": 197 },
  { "name": "[8455] 上海邦德职业技术学院", "is_wenli": "理科", "jihua": 30, "luqu": 13, "min_score": 263 },
  { "name": "[8456] 哈尔滨应用职业技术学院", "is_wenli": "理科", "jihua": 76, "luqu": 3, "min_score": 416 },
  { "name": "[8457] 上海东海职业技术学院", "is_wenli": "理科", "jihua": 42, "luqu": 29, "min_score": 180 },
  { "name": "[8458] 上海工商外国语职业学院", "is_wenli": "理科", "jihua": 40, "luqu": 41, "min_score": 180 },
  { "name": "[8458] 上海工商外国语职业学院[较高收费]", "is_wenli": "理科", "jihua": 3, "luqu": 1, "min_score": 271 },
  { "name": "[8459] 上海济光职业技术学院", "is_wenli": "理科", "jihua": 48, "luqu": 43, "min_score": 182 },
  { "name": "[8471] 广州科技贸易职业学院", "is_wenli": "理科", "jihua": 20, "luqu": 20, "min_score": 256 },
  { "name": "[8480] 四川文化产业职业学院", "is_wenli": "理科", "jihua": 10, "luqu": 2, "min_score": 336 },
  { "name": "[8481] 哈尔滨科学技术职业学院", "is_wenli": "理科", "jihua": 27, "luqu": 7, "min_score": 235 },
  { "name": "[8484] 福建卫生职业技术学院", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 315 },
  { "name": "[8490] 宁德职业技术学院", "is_wenli": "理科", "jihua": 39, "luqu": 14, "min_score": 242 },
  { "name": "[8492] 厦门软件职业技术学院", "is_wenli": "理科", "jihua": 36, "luqu": 11, "min_score": 182 },
  { "name": "[8493] 漳州城市职业学院", "is_wenli": "理科", "jihua": 43, "luqu": 2, "min_score": 356 },
  { "name": "[8494] 厦门南洋职业学院", "is_wenli": "理科", "jihua": 30, "luqu": 2, "min_score": 194 },
  { "name": "[8496] 湖北科技职业学院", "is_wenli": "理科", "jihua": 44, "luqu": 44, "min_score": 295 },
  { "name": "[8497] 湖北青年职业学院", "is_wenli": "理科", "jihua": 18, "luqu": 3, "min_score": 262 },
  { "name": "[8500] 中山职业技术学院", "is_wenli": "理科", "jihua": 39, "luqu": 35, "min_score": 186 },
  { "name": "[8501] 泰州职业技术学院", "is_wenli": "理科", "jihua": 64, "luqu": 39, "min_score": 189 },
  { "name": "[8502] 无锡职业技术学院", "is_wenli": "理科", "jihua": 44, "luqu": 44, "min_score": 421 },
  { "name": "[8502] 无锡职业技术学院[较高收费]", "is_wenli": "理科", "jihua": 6, "luqu": 6, "min_score": 349 },
  { "name": "[8503] 苏州工业园区职业技术学院", "is_wenli": "理科", "jihua": 70, "luqu": 37, "min_score": 203 },
  { "name": "[8504] 江阴职业技术学院", "is_wenli": "理科", "jihua": 114, "luqu": 60, "min_score": 186 },
  { "name": "[8505] 宿迁职业技术学院", "is_wenli": "理科", "jihua": 134, "luqu": 52, "min_score": 181 },
  { "name": "[8506] 苏州工业园区服务外包职业学院", "is_wenli": "理科", "jihua": 16, "luqu": 16, "min_score": 302 },
  { "name": "[8507] 连云港职业技术学院", "is_wenli": "理科", "jihua": 94, "luqu": 79, "min_score": 182 },
  { "name": "[8507] 连云港职业技术学院[较高收费]", "is_wenli": "理科", "jihua": 4, "luqu": 1, "min_score": 216 },
  { "name": "[8508] 南京工业职业技术大学(原南京工业职业技术学院)", "is_wenli": "理科", "jihua": 68, "luqu": 68, "min_score": 428 },
  { "name": "[8509] 江苏工程职业技术学院", "is_wenli": "理科", "jihua": 73, "luqu": 73, "min_score": 331 },
  { "name": "[8510] 南京机电职业技术学院", "is_wenli": "理科", "jihua": 40, "luqu": 40, "min_score": 368 },
  { "name": "[8511] 江苏航运职业技术学院", "is_wenli": "理科", "jihua": 112, "luqu": 112, "min_score": 304 },
  { "name": "[8512] 南京交通职业技术学院", "is_wenli": "理科", "jihua": 156, "luqu": 156, "min_score": 327 },
  { "name": "[8513] 江苏电子信息职业学院(原淮安信息职业技术学院)", "is_wenli": "理科", "jihua": 170, "luqu": 170, "min_score": 295 },
  { "name": "[8514] 常州纺织服装职业技术学院", "is_wenli": "理科", "jihua": 14, "luqu": 8, "min_score": 199 },
  { "name": "[8515] 盐城工业职业技术学院", "is_wenli": "理科", "jihua": 84, "luqu": 84, "min_score": 277 },
  { "name": "[8516] 渤海理工职业学院", "is_wenli": "理科", "jihua": 15, "luqu": 3, "min_score": 183 },
  { "name": "[8517] 常州工程职业技术学院", "is_wenli": "理科", "jihua": 110, "luqu": 107, "min_score": 197 },
  { "name": "[8518] 常州工业职业技术学院(原常州轻工职业技术学院)", "is_wenli": "理科", "jihua": 25, "luqu": 25, "min_score": 232 },
  { "name": "[8519] 常州机电职业技术学院", "is_wenli": "理科", "jihua": 40, "luqu": 40, "min_score": 302 },
  { "name": "[8520] 徐州工业职业技术学院", "is_wenli": "理科", "jihua": 286, "luqu": 286, "min_score": 288 },
  { "name": "[8521] 江苏食品药品职业技术学院", "is_wenli": "理科", "jihua": 19, "luqu": 19, "min_score": 215 },
  { "name": "[8522] 江苏信息职业技术学院", "is_wenli": "理科", "jihua": 50, "luqu": 51, "min_score": 300 },
  { "name": "[8524] 江苏农牧科技职业学院", "is_wenli": "理科", "jihua": 75, "luqu": 75, "min_score": 253 },
  { "name": "[8525] 苏州农业职业技术学院", "is_wenli": "理科", "jihua": 59, "luqu": 59, "min_score": 297 },
  { "name": "[8526] 江苏农林职业技术学院", "is_wenli": "理科", "jihua": 98, "luqu": 98, "min_score": 295 },
  { "name": "[8527] 无锡商业职业技术学院", "is_wenli": "理科", "jihua": 93, "luqu": 94, "min_score": 315 },
  { "name": "[8527] 无锡商业职业技术学院[较高收费]", "is_wenli": "理科", "jihua": 2, "luqu": 1, "min_score": 325 },
  { "name": "[8529] 无锡科技职业学院", "is_wenli": "理科", "jihua": 60, "luqu": 69, "min_score": 297 },
  { "name": "[8530] 苏州职业大学", "is_wenli": "理科", "jihua": 143, "luqu": 143, "min_score": 408 },
  { "name": "[8531] 扬州市职业大学", "is_wenli": "理科", "jihua": 17, "luqu": 17, "min_score": 279 },
  { "name": "[8532] 南通职业大学", "is_wenli": "理科", "jihua": 169, "luqu": 169, "min_score": 374 },
  { "name": "[8533] 苏州高博软件技术职业学院", "is_wenli": "理科", "jihua": 36, "luqu": 40, "min_score": 195 },
  { "name": "[8534] 沙洲职业工学院", "is_wenli": "理科", "jihua": 47, "luqu": 18, "min_score": 198 },
  { "name": "[8535] 苏州信息职业技术学院", "is_wenli": "理科", "jihua": 46, "luqu": 46, "min_score": 290 },
  { "name": "[8537] 正德职业技术学院", "is_wenli": "理科", "jihua": 2, "luqu": 2, "min_score": 195 },
  { "name": "[8538] 无锡南洋职业技术学院", "is_wenli": "理科", "jihua": 40, "luqu": 62, "min_score": 185 },
  { "name": "[8540] 炎黄职业技术学院", "is_wenli": "理科", "jihua": 40, "luqu": 5, "min_score": 289 },
  { "name": "[8541] 金肯职业技术学院", "is_wenli": "理科", "jihua": 37, "luqu": 37, "min_score": 180 },
  { "name": "[8543] 九州职业技术学院", "is_wenli": "理科", "jihua": 72, "luqu": 13, "min_score": 182 },
  { "name": "[8544] 硅湖职业技术学院", "is_wenli": "理科", "jihua": 44, "luqu": 44, "min_score": 256 },
  { "name": "[8545] 钟山职业技术学院", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 252 },
  { "name": "[8546] 江苏护理职业学院", "is_wenli": "理科", "jihua": 46, "luqu": 46, "min_score": 269 },
  { "name": "[8550] 南京城市职业学院", "is_wenli": "理科", "jihua": 67, "luqu": 67, "min_score": 353 },
  { "name": "[8558] 浙江同济科技职业学院", "is_wenli": "理科", "jihua": 50, "luqu": 50, "min_score": 301 },
  { "name": "[8561] 浙江邮电职业技术学院", "is_wenli": "理科", "jihua": 25, "luqu": 25, "min_score": 306 },
  { "name": "[8563] 浙江农业商贸职业学院", "is_wenli": "理科", "jihua": 16, "luqu": 5, "min_score": 187 },
  { "name": "[8564] 浙江安防职业技术学院", "is_wenli": "理科", "jihua": 37, "luqu": 6, "min_score": 226 },
  { "name": "[8572] 合肥共达职业技术学院", "is_wenli": "理科", "jihua": 3, "luqu": 2, "min_score": 308 },
  { "name": "[8576] 江苏海事职业技术学院", "is_wenli": "理科", "jihua": 80, "luqu": 80, "min_score": 289 },
  { "name": "[8578] 南通科技职业学院", "is_wenli": "理科", "jihua": 53, "luqu": 42, "min_score": 168 },
  { "name": "[8579] 苏州工业职业技术学院", "is_wenli": "理科", "jihua": 98, "luqu": 98, "min_score": 350 },
  { "name": "[8580] 苏州经贸职业技术学院", "is_wenli": "理科", "jihua": 69, "luqu": 69, "min_score": 390 },
  { "name": "[8581] 杭州职业技术学院", "is_wenli": "理科", "jihua": 101, "luqu": 101, "min_score": 410 },
  { "name": "[8582] 义乌工商职业技术学院", "is_wenli": "理科", "jihua": 68, "luqu": 68, "min_score": 264 },
  { "name": "[8583] 宁波职业技术学院", "is_wenli": "理科", "jihua": 23, "luqu": 23, "min_score": 406 },
  { "name": "[8584] 温州职业技术学院", "is_wenli": "理科", "jihua": 80, "luqu": 80, "min_score": 327 },
  { "name": "[8585] 台州职业技术学院", "is_wenli": "理科", "jihua": 10, "luqu": 4, "min_score": 241 },
  { "name": "[8586] 嘉兴职业技术学院", "is_wenli": "理科", "jihua": 77, "luqu": 76, "min_score": 209 },
  { "name": "[8587] 湖州职业技术学院", "is_wenli": "理科", "jihua": 3, "luqu": 2, "min_score": 249 },
  { "name": "[8589] 丽水职业技术学院", "is_wenli": "理科", "jihua": 10, "luqu": 8, "min_score": 262 },
  { "name": "[8590] 浙江交通职业技术学院", "is_wenli": "理科", "jihua": 52, "luqu": 52, "min_score": 323 },
  { "name": "[8591] 浙江机电职业技术学院", "is_wenli": "理科", "jihua": 69, "luqu": 69, "min_score": 388 },
  { "name": "[8592] 浙江工业职业技术学院", "is_wenli": "理科", "jihua": 81, "luqu": 81, "min_score": 308 },
  { "name": "[8593] 浙江建设职业技术学院", "is_wenli": "理科", "jihua": 57, "luqu": 57, "min_score": 297 },
  { "name": "[8595] 浙江工贸职业技术学院", "is_wenli": "理科", "jihua": 62, "luqu": 62, "min_score": 345 },
  { "name": "[8597] 浙江工商职业技术学院", "is_wenli": "理科", "jihua": 69, "luqu": 69, "min_score": 367 },
  { "name": "[8598] 浙江商业职业技术学院", "is_wenli": "理科", "jihua": 44, "luqu": 44, "min_score": 373 },
  { "name": "[8599] 浙江旅游职业学院", "is_wenli": "理科", "jihua": 8, "luqu": 8, "min_score": 344 },
  { "name": "[8600] 浙江金融职业学院", "is_wenli": "理科", "jihua": 46, "luqu": 46, "min_score": 416 },
  { "name": "[8601] 浙江经济职业技术学院", "is_wenli": "理科", "jihua": 33, "luqu": 33, "min_score": 401 },
  { "name": "[8602] 浙江经贸职业技术学院", "is_wenli": "理科", "jihua": 45, "luqu": 45, "min_score": 388 },
  { "name": "[8604] 浙江艺术职业学院", "is_wenli": "理科", "jihua": 1, "luqu": 1, "min_score": 365 },
  { "name": "[8606] 泉州海洋职业学院", "is_wenli": "理科", "jihua": 22, "luqu": 7, "min_score": 189 },
  { "name": "[8607] 金华职业技术学院", "is_wenli": "理科", "jihua": 100, "luqu": 100, "min_score": 419 },
  { "name": "[8608] 浙江育英职业技术学院", "is_wenli": "理科", "jihua": 2, "luqu": 2, "min_score": 214 },
  { "name": "[8610] 浙江东方职业技术学院", "is_wenli": "理科", "jihua": 9, "luqu": 1, "min_score": 200 },
  { "name": "[8611] 绍兴职业技术学院", "is_wenli": "理科", "jihua": 22, "luqu": 1, "min_score": 184 },
  { "name": "[8613] 江西水利职业学院", "is_wenli": "理科", "jihua": 2, "luqu": 5, "min_score": 331 },
  { "name": "[8615] 烟台黄金职业学院", "is_wenli": "理科", "jihua": 23, "luqu": 3, "min_score": 283 },
  { "name": "[8620] 惠州卫生职业技术学院", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 200 },
  { "name": "[8628] 广州松田职业学院", "is_wenli": "理科", "jihua": 10, "luqu": 1, "min_score": 217 },
  { "name": "[8629] 广州华商职业学院", "is_wenli": "理科", "jihua": 30, "luqu": 3, "min_score": 217 },
  { "name": "[8631] 广东环境保护工程职业学院", "is_wenli": "理科", "jihua": 35, "luqu": 7, "min_score": 211 },
  { "name": "[8632] 广东南方职业学院", "is_wenli": "理科", "jihua": 5, "luqu": 1, "min_score": 221 },
  { "name": "[8633] 广州珠江职业技术学院", "is_wenli": "理科", "jihua": 20, "luqu": 2, "min_score": 202 },
  { "name": "[8634] 广东文理职业学院", "is_wenli": "理科", "jihua": 60, "luqu": 4, "min_score": 224 },
  { "name": "[8635] 广西卫生职业技术学院", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 288 },
  { "name": "[8637] 重庆旅游职业学院", "is_wenli": "理科", "jihua": 5, "luqu": 2, "min_score": 278 },
  { "name": "[8643] 合肥通用职业技术学院", "is_wenli": "理科", "jihua": 8, "luqu": 3, "min_score": 261 },
  { "name": "[8644] 六安职业技术学院", "is_wenli": "理科", "jihua": 23, "luqu": 17, "min_score": 180 },
  { "name": "[8645] 宿州职业技术学院", "is_wenli": "理科", "jihua": 10, "luqu": 4, "min_score": 282 },
  { "name": "[8649] 淮北职业技术学院", "is_wenli": "理科", "jihua": 6, "luqu": 6, "min_score": 227 },
  { "name": "[8653] 安徽电子信息职业技术学院", "is_wenli": "理科", "jihua": 8, "luqu": 8, "min_score": 297 },
  { "name": "[8654] 安徽交通职业技术学院", "is_wenli": "理科", "jihua": 10, "luqu": 9, "min_score": 221 },
  { "name": "[8655] 安徽职业技术学院", "is_wenli": "理科", "jihua": 15, "luqu": 15, "min_score": 343 },
  { "name": "[8662] 淮南联合大学", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 248 },
  { "name": "[8665] 安徽文达信息工程学院", "is_wenli": "理科", "jihua": 50, "luqu": 57, "min_score": 184 },
  { "name": "[8666] 民办合肥滨湖职业技术学院", "is_wenli": "理科", "jihua": 2, "luqu": 1, "min_score": 219 },
  { "name": "[8668] 安徽外国语学院", "is_wenli": "理科", "jihua": 20, "luqu": 15, "min_score": 196 },
  { "name": "[8672] 广西金融职业技术学院", "is_wenli": "理科", "jihua": 4, "luqu": 3, "min_score": 277 },
  { "name": "[8675] 四川科技职业学院", "is_wenli": "理科", "jihua": 4, "luqu": 4, "min_score": 194 },
  { "name": "[8676] 漳州科技职业学院", "is_wenli": "理科", "jihua": 37, "luqu": 1, "min_score": 341 },
  { "name": "[8686] 漳州卫生职业学院", "is_wenli": "理科", "jihua": 60, "luqu": 34, "min_score": 187 },
  { "name": "[8687] 江西传媒职业学院", "is_wenli": "理科", "jihua": 9, "luqu": 8, "min_score": 227 },
  { "name": "[8688] 泉州经贸职业技术学院", "is_wenli": "理科", "jihua": 9, "luqu": 2, "min_score": 293 },
  { "name": "[8690] 山东城市建设职业学院", "is_wenli": "理科", "jihua": 12, "luqu": 8, "min_score": 235 },
  { "name": "[8695] 枣庄职业学院", "is_wenli": "理科", "jihua": 15, "luqu": 15, "min_score": 287 },
  { "name": "[8698] 重庆建筑工程职业学院", "is_wenli": "理科", "jihua": 14, "luqu": 14, "min_score": 279 },
  { "name": "[8699] 四川城市职业学院", "is_wenli": "理科", "jihua": 15, "luqu": 7, "min_score": 210 },
  { "name": "[8703] 九江职业技术学院", "is_wenli": "理科", "jihua": 50, "luqu": 30, "min_score": 189 },
  { "name": "[8705] 江西电力职业技术学院", "is_wenli": "理科", "jihua": 6, "luqu": 6, "min_score": 241 },
  { "name": "[8707] 江西信息应用职业技术学院", "is_wenli": "理科", "jihua": 2, "luqu": 1, "min_score": 355 },
  { "name": "[8708] 江西工业工程职业技术学院", "is_wenli": "理科", "jihua": 80, "luqu": 77, "min_score": 185 },
  { "name": "[8709] 江西交通职业技术学院", "is_wenli": "理科", "jihua": 10, "luqu": 3, "min_score": 255 },
  { "name": "[8710] 江西应用技术职业学院", "is_wenli": "理科", "jihua": 15, "luqu": 8, "min_score": 224 },
  { "name": "[8715] 江西财经职业学院", "is_wenli": "理科", "jihua": 10, "luqu": 9, "min_score": 196 },
  { "name": "[8716] 江西司法警官职业学院", "is_wenli": "理科", "jihua": 4, "luqu": 4, "min_score": 208 },
  { "name": "[8717] 江西陶瓷工艺美术职业技术学院", "is_wenli": "理科", "jihua": 4, "luqu": 3, "min_score": 200 },
  { "name": "[8719] 九江职业大学", "is_wenli": "理科", "jihua": 10, "luqu": 9, "min_score": 215 },
  { "name": "[8720] 江西应用科技学院", "is_wenli": "理科", "jihua": 26, "luqu": 6, "min_score": 187 },
  { "name": "[8721] 武汉城市职业学院", "is_wenli": "理科", "jihua": 56, "luqu": 56, "min_score": 386 },
  { "name": "[8722] 广东科贸职业学院", "is_wenli": "理科", "jihua": 20, "luqu": 5, "min_score": 334 },
  { "name": "[8723] 东莞职业技术学院", "is_wenli": "理科", "jihua": 43, "luqu": 43, "min_score": 297 },
  { "name": "[8731] 宿迁泽达职业技术学院", "is_wenli": "理科", "jihua": 40, "luqu": 19, "min_score": 181 },
  { "name": "[8740] 温州科技职业学院", "is_wenli": "理科", "jihua": 21, "luqu": 17, "min_score": 208 },
  { "name": "[8741] 北京交通运输职业学院", "is_wenli": "理科", "jihua": 6, "luqu": 6, "min_score": 381 },
  { "name": "[8742] 廊坊燕京职业技术学院", "is_wenli": "理科", "jihua": 6, "luqu": 1, "min_score": 220 },
  { "name": "[8743] 石家庄科技职业学院", "is_wenli": "理科", "jihua": 10, "luqu": 3, "min_score": 193 },
  { "name": "[8745] 杭州科技职业技术学院", "is_wenli": "理科", "jihua": 35, "luqu": 35, "min_score": 404 },
  { "name": "[8746] 台州科技职业学院", "is_wenli": "理科", "jihua": 46, "luqu": 19, "min_score": 201 },
  { "name": "[8748] 厦门东海职业技术学院", "is_wenli": "理科", "jihua": 13, "luqu": 1, "min_score": 215 },
  { "name": "[8751] 日照职业技术学院", "is_wenli": "理科", "jihua": 62, "luqu": 62, "min_score": 229 },
  { "name": "[8754] 莱芜职业技术学院", "is_wenli": "理科", "jihua": 10, "luqu": 2, "min_score": 189 },
  { "name": "[8755] 威海职业学院", "is_wenli": "理科", "jihua": 28, "luqu": 25, "min_score": 265 },
  { "name": "[8756] 青岛职业技术学院", "is_wenli": "理科", "jihua": 16, "luqu": 16, "min_score": 362 },
  { "name": "[8758] 山东劳动职业技术学院", "is_wenli": "理科", "jihua": 30, "luqu": 1, "min_score": 318 },
  { "name": "[8761] 潍坊职业学院", "is_wenli": "理科", "jihua": 7, "luqu": 3, "min_score": 206 },
  { "name": "[8763] 淄博职业学院", "is_wenli": "理科", "jihua": 16, "luqu": 16, "min_score": 230 },
  { "name": "[8764] 青岛酒店管理职业技术学院", "is_wenli": "理科", "jihua": 33, "luqu": 30, "min_score": 210 },
  { "name": "[8765] 滨州职业学院", "is_wenli": "理科", "jihua": 28, "luqu": 21, "min_score": 180 },
  { "name": "[8766] 聊城职业技术学院", "is_wenli": "理科", "jihua": 20, "luqu": 15, "min_score": 192 },
  { "name": "[8767] 山东水利职业学院", "is_wenli": "理科", "jihua": 20, "luqu": 20, "min_score": 182 },
  { "name": "[8768] 山东职业学院(原济南铁道职业技术学院)", "is_wenli": "理科", "jihua": 25, "luqu": 25, "min_score": 256 },
  { "name": "[8769] 山东科技职业学院", "is_wenli": "理科", "jihua": 11, "luqu": 11, "min_score": 278 },
  { "name": "[8770] 山东服装职业学院", "is_wenli": "理科", "jihua": 10, "luqu": 5, "min_score": 337 },
  { "name": "[8771] 山东交通职业学院", "is_wenli": "理科", "jihua": 55, "luqu": 15, "min_score": 181 },
  { "name": "[8772] 山东信息职业技术学院", "is_wenli": "理科", "jihua": 42, "luqu": 38, "min_score": 205 },
  { "name": "[8773] 山东畜牧兽医职业学院", "is_wenli": "理科", "jihua": 25, "luqu": 25, "min_score": 226 },
  { "name": "[8774] 山东商业职业技术学院", "is_wenli": "理科", "jihua": 41, "luqu": 41, "min_score": 348 },
  { "name": "[8775] 山东外贸职业学院", "is_wenli": "理科", "jihua": 8, "luqu": 8, "min_score": 330 },
  { "name": "[8777] 曲阜远东职业技术学院", "is_wenli": "理科", "jihua": 3, "luqu": 1, "min_score": 183 },
  { "name": "[8778] 山东英才学院", "is_wenli": "理科", "jihua": 140, "luqu": 6, "min_score": 203 },
  { "name": "[8783] 德州科技职业学院", "is_wenli": "理科", "jihua": 19, "luqu": 2, "min_score": 211 },
  { "name": "[8784] 山东力明科技职业学院", "is_wenli": "理科", "jihua": 10, "luqu": 4, "min_score": 186 },
  { "name": "[8786] 山东圣翰财贸职业学院", "is_wenli": "理科", "jihua": 48, "luqu": 9, "min_score": 186 },
  { "name": "[8786] 山东圣翰财贸职业学院[较高收费]", "is_wenli": "理科", "jihua": 2, "luqu": 1, "min_score": 354 },
  { "name": "[8820] 上海民航职业技术学院", "is_wenli": "理科", "jihua": 37, "luqu": 37, "min_score": 397 },
  { "name": "[8824] 江苏卫生健康职业学院", "is_wenli": "理科", "jihua": 30, "luqu": 30, "min_score": 372 },
  { "name": "[8825] 徐州生物工程职业技术学院", "is_wenli": "理科", "jihua": 96, "luqu": 63, "min_score": 160 },
  { "name": "[8826] 西藏职业技术学院", "is_wenli": "理科", "jihua": 76, "luqu": 3, "min_score": 241 },
  { "name": "[8830] 沈阳北软信息职业技术学院", "is_wenli": "理科", "jihua": 45, "luqu": 1, "min_score": 345 },
  { "name": "[8831] 恩施职业技术学院", "is_wenli": "理科", "jihua": 10, "luqu": 1, "min_score": 229 },
  { "name": "[8832] 辽宁轻工职业学院", "is_wenli": "理科", "jihua": 44, "luqu": 6, "min_score": 187 },
  { "name": "[8833] 湖北铁道运输职业学院", "is_wenli": "理科", "jihua": 99, "luqu": 99, "min_score": 181 },
  { "name": "[8834] 黄冈职业技术学院", "is_wenli": "理科", "jihua": 57, "luqu": 57, "min_score": 263 },
  { "name": "[8835] 安徽粮食工程职业学院", "is_wenli": "理科", "jihua": 10, "luqu": 1, "min_score": 315 },
  { "name": "[8836] 荆州职业技术学院", "is_wenli": "理科", "jihua": 40, "luqu": 40, "min_score": 191 },
  { "name": "[8837] 湖北工业职业技术学院", "is_wenli": "理科", "jihua": 73, "luqu": 73, "min_score": 256 },
  { "name": "[8838] 武汉职业技术学院", "is_wenli": "理科", "jihua": 83, "luqu": 85, "min_score": 422 },
  { "name": "[8839] 仙桃职业学院", "is_wenli": "理科", "jihua": 72, "luqu": 72, "min_score": 251 },
  { "name": "[8840] 襄阳职业技术学院", "is_wenli": "理科", "jihua": 88, "luqu": 88, "min_score": 303 },
  { "name": "[8841] 湖北职业技术学院", "is_wenli": "理科", "jihua": 100, "luqu": 100, "min_score": 302 },
  { "name": "[8842] 武汉软件工程职业学院", "is_wenli": "理科", "jihua": 95, "luqu": 95, "min_score": 408 },
  { "name": "[8843] 武汉电力职业技术学院", "is_wenli": "理科", "jihua": 30, "luqu": 30, "min_score": 319 },
  { "name": "[8844] 湖北城市建设职业技术学院", "is_wenli": "理科", "jihua": 94, "luqu": 72, "min_score": 185 },
  { "name": "[8845] 湖北水利水电职业技术学院", "is_wenli": "理科", "jihua": 28, "luqu": 28, "min_score": 271 },
  { "name": "[8846] 廊坊卫生职业学院", "is_wenli": "理科", "jihua": 7, "luqu": 7, "min_score": 232 },
  { "name": "[8847] 湖北交通职业技术学院", "is_wenli": "理科", "jihua": 43, "luqu": 43, "min_score": 243 },
  { "name": "[8848] 湖北轻工职业技术学院", "is_wenli": "理科", "jihua": 78, "luqu": 75, "min_score": 190 },
  { "name": "[8849] 武汉船舶职业技术学院", "is_wenli": "理科", "jihua": 67, "luqu": 67, "min_score": 278 },
  { "name": "[8849] 武汉船舶职业技术学院[较高收费]", "is_wenli": "理科", "jihua": 5, "luqu": 2, "min_score": 292 },
  { "name": "[8850] 武汉工程职业技术学院", "is_wenli": "理科", "jihua": 70, "luqu": 70, "min_score": 384 },
  { "name": "[8851] 武汉航海职业技术学院", "is_wenli": "理科", "jihua": 15, "luqu": 9, "min_score": 204 },
  { "name": "[8852] 武汉警官职业学院", "is_wenli": "理科", "jihua": 50, "luqu": 52, "min_score": 306 },
  { "name": "[8855] 鄂州职业大学", "is_wenli": "理科", "jihua": 56, "luqu": 43, "min_score": 180 },
  { "name": "[8856] 荆州理工职业学院", "is_wenli": "理科", "jihua": 50, "luqu": 38, "min_score": 184 },
  { "name": "[8858] 湖北开放职业学院", "is_wenli": "理科", "jihua": 40, "luqu": 3, "min_score": 182 },
  { "name": "[8859] 长江职业学院", "is_wenli": "理科", "jihua": 60, "luqu": 53, "min_score": 217 },
  { "name": "[8861] 武昌职业学院", "is_wenli": "理科", "jihua": 175, "luqu": 95, "min_score": 180 },
  { "name": "[8862] 武汉科技职业学院", "is_wenli": "理科", "jihua": 30, "luqu": 8, "min_score": 194 },
  { "name": "[8863] 武汉信息传播职业技术学院", "is_wenli": "理科", "jihua": 40, "luqu": 20, "min_score": 180 },
  { "name": "[8865] 武汉外语外事职业学院", "is_wenli": "理科", "jihua": 33, "luqu": 14, "min_score": 182 },
  { "name": "[8866] 武汉商贸职业学院", "is_wenli": "理科", "jihua": 97, "luqu": 98, "min_score": 180 },
  { "name": "[8867] 荆门职业学院", "is_wenli": "理科", "jihua": 30, "luqu": 11, "min_score": 184 },
  { "name": "[8868] 长江工程职业技术学院", "is_wenli": "理科", "jihua": 47, "luqu": 47, "min_score": 232 },
  { "name": "[8869] 武汉光谷职业学院", "is_wenli": "理科", "jihua": 70, "luqu": 62, "min_score": 180 },
  { "name": "[8872] 湖南三一工业职业技术学院", "is_wenli": "理科", "jihua": 5, "luqu": 2, "min_score": 238 },
  { "name": "[8885] 长沙卫生职业学院", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 380 },
  { "name": "[8888] 三峡旅游职业技术学院", "is_wenli": "理科", "jihua": 17, "luqu": 2, "min_score": 375 },
  { "name": "[8896] 随州职业技术学院", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 180 },
  { "name": "[8897] 湖南汽车工程职业学院", "is_wenli": "理科", "jihua": 45, "luqu": 11, "min_score": 226 },
  { "name": "[8899] 湖南水利水电职业技术学院", "is_wenli": "理科", "jihua": 4, "luqu": 4, "min_score": 209 },
  { "name": "[8900] 湖南现代物流职业技术学院", "is_wenli": "理科", "jihua": 15, "luqu": 4, "min_score": 240 },
  { "name": "[8905] 长沙民政职业技术学院", "is_wenli": "理科", "jihua": 24, "luqu": 24, "min_score": 408 },
  { "name": "[8908] 湖南信息职业技术学院", "is_wenli": "理科", "jihua": 3, "luqu": 3, "min_score": 377 },
  { "name": "[8909] 湖南工程职业技术学院", "is_wenli": "理科", "jihua": 8, "luqu": 6, "min_score": 266 },
  { "name": "[8910] 长沙环境保护职业技术学院", "is_wenli": "理科", "jihua": 10, "luqu": 2, "min_score": 256 },
  { "name": "[8912] 湖南邮电职业技术学院", "is_wenli": "理科", "jihua": 15, "luqu": 15, "min_score": 239 },
  { "name": "[8913] 湖南工业职业技术学院", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 298 },
  { "name": "[8914] 湖南交通职业技术学院", "is_wenli": "理科", "jihua": 21, "luqu": 21, "min_score": 234 },
  { "name": "[8915] 湖南科技职业学院", "is_wenli": "理科", "jihua": 6, "luqu": 4, "min_score": 254 },
  { "name": "[8916] 湖南铁道职业技术学院", "is_wenli": "理科", "jihua": 35, "luqu": 35, "min_score": 266 },
  { "name": "[8918] 张家界航空工业职业技术学院", "is_wenli": "理科", "jihua": 46, "luqu": 46, "min_score": 265 },
  { "name": "[8919] 湖南商务职业技术学院", "is_wenli": "理科", "jihua": 4, "luqu": 2, "min_score": 263 },
  { "name": "[8920] 湖南体育职业学院", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 189 },
  { "name": "[8922] 湖南大众传媒职业技术学院", "is_wenli": "理科", "jihua": 24, "luqu": 24, "min_score": 385 },
  { "name": "[8928] 塔里木职业技术学院", "is_wenli": "理科", "jihua": 7, "luqu": 2, "min_score": 202 },
  { "name": "[8931] 天津体育职业学院", "is_wenli": "理科", "jihua": 57, "luqu": 27, "min_score": 188 },
  { "name": "[8932] 曹妃甸职业技术学院", "is_wenli": "理科", "jihua": 16, "luqu": 2, "min_score": 280 },
  { "name": "[8933] 江苏旅游职业学院", "is_wenli": "理科", "jihua": 20, "luqu": 19, "min_score": 199 },
  { "name": "[8934] 日照航海工程职业学院", "is_wenli": "理科", "jihua": 16, "luqu": 4, "min_score": 194 },
  { "name": "[8935] 上饶幼儿师范高等专科学校", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 278 },
  { "name": "[8956] 保险职业学院", "is_wenli": "理科", "jihua": 17, "luqu": 10, "min_score": 212 },
  { "name": "[8960] 广州现代信息工程职业技术学院", "is_wenli": "理科", "jihua": 10, "luqu": 1, "min_score": 240 },
  { "name": "[8961] 顺德职业技术学院", "is_wenli": "理科", "jihua": 102, "luqu": 102, "min_score": 319 },
  { "name": "[8966] 广东农工商职业技术学院", "is_wenli": "理科", "jihua": 60, "luqu": 20, "min_score": 217 },
  { "name": "[8974] 汕头职业技术学院", "is_wenli": "理科", "jihua": 16, "luqu": 1, "min_score": 319 },
  { "name": "[8975] 清远职业技术学院", "is_wenli": "理科", "jihua": 41, "luqu": 12, "min_score": 187 },
  { "name": "[8976] 广东轻工职业技术学院", "is_wenli": "理科", "jihua": 74, "luqu": 74, "min_score": 410 },
  { "name": "[8977] 广东交通职业技术学院", "is_wenli": "理科", "jihua": 69, "luqu": 66, "min_score": 216 },
  { "name": "[8978] 广东水利电力职业技术学院", "is_wenli": "理科", "jihua": 100, "luqu": 54, "min_score": 194 },
  { "name": "[8980] 广东机电职业技术学院", "is_wenli": "理科", "jihua": 50, "luqu": 48, "min_score": 236 },
  { "name": "[8981] 广东职业技术学院", "is_wenli": "理科", "jihua": 20, "luqu": 12, "min_score": 224 },
  { "name": "[8983] 广东建设职业技术学院", "is_wenli": "理科", "jihua": 80, "luqu": 13, "min_score": 208 },
  { "name": "[8985] 广东工贸职业技术学院", "is_wenli": "理科", "jihua": 63, "luqu": 62, "min_score": 214 },
  { "name": "[8986] 广东司法警官职业学院", "is_wenli": "理科", "jihua": 20, "luqu": 20, "min_score": 215 },
  { "name": "[8989] 广东新安职业技术学院", "is_wenli": "理科", "jihua": 30, "luqu": 5, "min_score": 216 },
  { "name": "[8990] 广东岭南职业技术学院", "is_wenli": "理科", "jihua": 40, "luqu": 3, "min_score": 278 },
  { "name": "[8992] 惠州城市职业学院", "is_wenli": "理科", "jihua": 40, "luqu": 12, "min_score": 191 },
  { "name": "[8994] 广东南华工商职业学院", "is_wenli": "理科", "jihua": 26, "luqu": 15, "min_score": 182 },
  { "name": "[8999] 广东碧桂园职业学院", "is_wenli": "理科", "jihua": 6, "luqu": 4, "min_score": 266 },
  { "name": "[9026] 湛江幼儿师范专科学校", "is_wenli": "理科", "jihua": 5, "luqu": 1, "min_score": 297 },
  { "name": "[9047] 广元中核职业技术学院", "is_wenli": "理科", "jihua": 5, "luqu": 3, "min_score": 213 },
  { "name": "[9052] 柳州职业技术学院", "is_wenli": "理科", "jihua": 13, "luqu": 1, "min_score": 309 },
  { "name": "[9054] 广西建设职业技术学院", "is_wenli": "理科", "jihua": 11, "luqu": 8, "min_score": 186 },
  { "name": "[9074] 湖南高速铁路职业技术学院", "is_wenli": "理科", "jihua": 16, "luqu": 16, "min_score": 330 },
  { "name": "[9075] 湖南铁路科技职业技术学院", "is_wenli": "理科", "jihua": 42, "luqu": 42, "min_score": 277 },
  { "name": "[9076] 广州铁路职业技术学院", "is_wenli": "理科", "jihua": 37, "luqu": 37, "min_score": 253 },
  { "name": "[9079] 咸阳职业技术学院", "is_wenli": "理科", "jihua": 4, "luqu": 4, "min_score": 306 },
  { "name": "[9080] 西安职业技术学院", "is_wenli": "理科", "jihua": 20, "luqu": 20, "min_score": 295 },
  { "name": "[9081] 海南职业技术学院", "is_wenli": "理科", "jihua": 32, "luqu": 22, "min_score": 186 },
  { "name": "[9083] 海南科技职业大学(原海南科技职业学院)", "is_wenli": "理科", "jihua": 73, "luqu": 37, "min_score": 192 },
  { "name": "[9084] 三亚城市职业学院", "is_wenli": "理科", "jihua": 15, "luqu": 1, "min_score": 194 },
  { "name": "[9088] 三亚航空旅游职业学院", "is_wenli": "理科", "jihua": 31, "luqu": 13, "min_score": 221 },
  { "name": "[9089] 商洛职业技术学院", "is_wenli": "理科", "jihua": 14, "luqu": 11, "min_score": 185 },
  { "name": "[9090] 汉中职业技术学院", "is_wenli": "理科", "jihua": 20, "luqu": 20, "min_score": 222 },
  { "name": "[9091] 重庆城市管理职业学院", "is_wenli": "理科", "jihua": 6, "luqu": 6, "min_score": 377 },
  { "name": "[9093] 重庆工业职业技术学院", "is_wenli": "理科", "jihua": 15, "luqu": 15, "min_score": 392 },
  { "name": "[9094] 重庆工程职业技术学院", "is_wenli": "理科", "jihua": 16, "luqu": 16, "min_score": 329 },
  { "name": "[9095] 重庆航天职业技术学院", "is_wenli": "理科", "jihua": 18, "luqu": 18, "min_score": 335 },
  { "name": "[9097] 重庆建筑科技职业学院(原重庆房地产职业学院)", "is_wenli": "理科", "jihua": 8, "luqu": 3, "min_score": 191 },
  { "name": "[9099] 重庆海联职业技术学院", "is_wenli": "理科", "jihua": 20, "luqu": 4, "min_score": 210 },
  { "name": "[9100] 重庆信息技术职业学院", "is_wenli": "理科", "jihua": 60, "luqu": 16, "min_score": 181 },
  { "name": "[9110] 重庆经贸职业学院", "is_wenli": "理科", "jihua": 11, "luqu": 6, "min_score": 211 },
  { "name": "[9112] 重庆艺术工程职业学院", "is_wenli": "理科", "jihua": 12, "luqu": 2, "min_score": 264 },
  { "name": "[9116] 渭南职业技术学院", "is_wenli": "理科", "jihua": 21, "luqu": 21, "min_score": 232 },
  { "name": "[9117] 安康职业技术学院", "is_wenli": "理科", "jihua": 15, "luqu": 15, "min_score": 186 },
  { "name": "[9118] 铜川职业技术学院", "is_wenli": "理科", "jihua": 20, "luqu": 5, "min_score": 227 },
  { "name": "[9122] 绵阳职业技术学院", "is_wenli": "理科", "jihua": 3, "luqu": 3, "min_score": 252 },
  { "name": "[9127] 泸州职业技术学院", "is_wenli": "理科", "jihua": 5, "luqu": 2, "min_score": 302 },
  { "name": "[9132] 四川建筑职业技术学院", "is_wenli": "理科", "jihua": 40, "luqu": 40, "min_score": 283 },
  { "name": "[9133] 四川电影电视学院", "is_wenli": "理科", "jihua": 24, "luqu": 8, "min_score": 183 },
  { "name": "[9139] 四川国际标榜职业学院", "is_wenli": "理科", "jihua": 4, "luqu": 2, "min_score": 223 },
  { "name": "[9163] 云南财经职业学院", "is_wenli": "理科", "jihua": 4, "luqu": 4, "min_score": 354 },
  { "name": "[9182] 广州卫生职业技术学院", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 194 },
  { "name": "[9186] 惠州工程职业学院", "is_wenli": "理科", "jihua": 10, "luqu": 5, "min_score": 237 },
  { "name": "[9187] 广东江门中医药职业学院", "is_wenli": "理科", "jihua": 10, "luqu": 2, "min_score": 237 },
  { "name": "[9194] 贵州交通职业技术学院", "is_wenli": "理科", "jihua": 4, "luqu": 3, "min_score": 334 },
  { "name": "[9198] 贵州电子信息职业技术学院", "is_wenli": "理科", "jihua": 6, "luqu": 6, "min_score": 315 },
  { "name": "[9200] 黔东南民族职业技术学院", "is_wenli": "理科", "jihua": 5, "luqu": 3, "min_score": 226 },
  { "name": "[9201] 黔南民族职业技术学院", "is_wenli": "理科", "jihua": 5, "luqu": 2, "min_score": 263 },
  { "name": "[9224] 云南国土资源职业学院", "is_wenli": "理科", "jihua": 4, "luqu": 4, "min_score": 232 },
  { "name": "[9242] 杨凌职业技术学院", "is_wenli": "理科", "jihua": 6, "luqu": 6, "min_score": 379 },
  { "name": "[9243] 陕西交通职业技术学院", "is_wenli": "理科", "jihua": 26, "luqu": 26, "min_score": 319 },
  { "name": "[9244] 陕西工业职业技术学院", "is_wenli": "理科", "jihua": 3, "luqu": 3, "min_score": 418 },
  { "name": "[9245] 陕西国防工业职业技术学院", "is_wenli": "理科", "jihua": 20, "luqu": 20, "min_score": 409 },
  { "name": "[9246] 陕西能源职业技术学院", "is_wenli": "理科", "jihua": 21, "luqu": 21, "min_score": 181 },
  { "name": "[9247] 西安航空职业技术学院", "is_wenli": "理科", "jihua": 55, "luqu": 55, "min_score": 374 },
  { "name": "[9248] 陕西财经职业技术学院", "is_wenli": "理科", "jihua": 31, "luqu": 31, "min_score": 353 },
  { "name": "[9251] 西安城市建设职业学院", "is_wenli": "理科", "jihua": 13, "luqu": 6, "min_score": 181 },
  { "name": "[9257] 西安高新科技职业学院", "is_wenli": "理科", "jihua": 12, "luqu": 3, "min_score": 209 },
  { "name": "[9259] 陕西服装工程学院", "is_wenli": "理科", "jihua": 11, "luqu": 6, "min_score": 204 },
  { "name": "[9265] 陕西机电职业技术学院", "is_wenli": "理科", "jihua": 14, "luqu": 14, "min_score": 231 },
  { "name": "[9269] 西安铁路职业技术学院", "is_wenli": "理科", "jihua": 160, "luqu": 160, "min_score": 341 },
  { "name": "[9293] 甘肃工业职业技术学院", "is_wenli": "理科", "jihua": 2, "luqu": 1, "min_score": 279 },
  { "name": "[9294] 甘肃建筑职业技术学院", "is_wenli": "理科", "jihua": 6, "luqu": 1, "min_score": 217 },
  { "name": "[9295] 兰州石化职业技术学院", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 269 },
  { "name": "[9297] 甘肃警察职业学院", "is_wenli": "理科", "jihua": 25, "luqu": 17, "min_score": 184 },
  { "name": "[9334] 宁夏建设职业技术学院", "is_wenli": "理科", "jihua": 7, "luqu": 3, "min_score": 319 },
  { "name": "[9335] 宁夏工商职业技术学院", "is_wenli": "理科", "jihua": 15, "luqu": 8, "min_score": 241 },
  { "name": "[9343] 克拉玛依职业技术学院", "is_wenli": "理科", "jihua": 12, "luqu": 5, "min_score": 218 },
  { "name": "[9345] 新疆轻工职业技术学院", "is_wenli": "理科", "jihua": 8, "luqu": 1, "min_score": 426 },
  { "name": "[9346] 新疆农业职业技术学院", "is_wenli": "理科", "jihua": 12, "luqu": 8, "min_score": 179 },
  { "name": "[9347] 乌鲁木齐职业大学", "is_wenli": "理科", "jihua": 25, "luqu": 22, "min_score": 197 },
  { "name": "[9351] 哈密职业技术学院", "is_wenli": "理科", "jihua": 22, "luqu": 2, "min_score": 216 },
  { "name": "[9352] 新疆工业职业技术学院", "is_wenli": "理科", "jihua": 9, "luqu": 1, "min_score": 181 },
  { "name": "[9370] 福州职业技术学院", "is_wenli": "理科", "jihua": 105, "luqu": 37, "min_score": 194 },
  { "name": "[9371] 漳州职业技术学院", "is_wenli": "理科", "jihua": 82, "luqu": 38, "min_score": 209 },
  { "name": "[9372] 福建船政交通职业学院", "is_wenli": "理科", "jihua": 130, "luqu": 28, "min_score": 233 },
  { "name": "[9375] 黎明职业大学", "is_wenli": "理科", "jihua": 75, "luqu": 62, "min_score": 182 },
  { "name": "[9416] 北京经济技术职业学院", "is_wenli": "理科", "jihua": 7, "luqu": 9, "min_score": 295 },
  { "name": "[9417] 北京汇佳职业学院", "is_wenli": "理科", "jihua": 6, "luqu": 6, "min_score": 192 },
  { "name": "[9418] 北京社会管理职业学院", "is_wenli": "理科", "jihua": 2, "luqu": 2, "min_score": 306 },
  { "name": "[9419] 北京艺术传媒职业学院", "is_wenli": "理科", "jihua": 4, "luqu": 1, "min_score": 331 },
  { "name": "[9422] 保定电力职业技术学院", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 286 },
  { "name": "[9424] 石家庄科技工程职业学院", "is_wenli": "理科", "jihua": 5, "luqu": 2, "min_score": 274 },
  { "name": "[9435] 石家庄工商职业学院", "is_wenli": "理科", "jihua": 11, "luqu": 1, "min_score": 241 },
  { "name": "[9436] 河北外国语学院", "is_wenli": "理科", "jihua": 12, "luqu": 11, "min_score": 224 },
  { "name": "[9444] 山西职业技术学院", "is_wenli": "理科", "jihua": 12, "luqu": 7, "min_score": 215 },
  { "name": "[9449] 满洲里俄语职业学院", "is_wenli": "理科", "jihua": 6, "luqu": 2, "min_score": 255 },
  { "name": "[9456] 内蒙古机电职业技术学院", "is_wenli": "理科", "jihua": 8, "luqu": 2, "min_score": 245 },
  { "name": "[9457] 内蒙古电子信息职业技术学院", "is_wenli": "理科", "jihua": 13, "luqu": 1, "min_score": 354 },
  { "name": "[9459] 辽宁城市建设职业技术学院", "is_wenli": "理科", "jihua": 50, "luqu": 4, "min_score": 182 },
  { "name": "[9460] 辽宁工程职业学院", "is_wenli": "理科", "jihua": 40, "luqu": 1, "min_score": 433 },
  { "name": "[9462] 辽宁职业学院", "is_wenli": "理科", "jihua": 31, "luqu": 9, "min_score": 165 },
  { "name": "[9465] 沈阳职业技术学院", "is_wenli": "理科", "jihua": 78, "luqu": 8, "min_score": 210 },
  { "name": "[9466] 辽宁铁道职业技术学院", "is_wenli": "理科", "jihua": 70, "luqu": 65, "min_score": 181 },
  { "name": "[9467] 辽宁医药职业学院", "is_wenli": "理科", "jihua": 61, "luqu": 46, "min_score": 211 },
  { "name": "[9469] 辽宁装备制造职业技术学院", "is_wenli": "理科", "jihua": 70, "luqu": 3, "min_score": 212 },
  { "name": "[9471] 黑龙江职业学院", "is_wenli": "理科", "jihua": 39, "luqu": 12, "min_score": 232 },
  { "name": "[9473] 黑龙江农垦科技职业学院", "is_wenli": "理科", "jihua": 56, "luqu": 2, "min_score": 315 },
  { "name": "[9476] 黑龙江生物科技职业学院", "is_wenli": "理科", "jihua": 70, "luqu": 3, "min_score": 336 },
  { "name": "[9478] 长白山职业技术学院", "is_wenli": "理科", "jihua": 38, "luqu": 8, "min_score": 180 },
  { "name": "[9481] 上海工艺美术职业学院", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 381 },
  { "name": "[9488] 江苏经贸职业技术学院", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 407 },
  { "name": "[9491] 浙江纺织服装职业技术学院", "is_wenli": "理科", "jihua": 20, "luqu": 13, "min_score": 223 },
  { "name": "[9492] 宁波城市职业技术学院", "is_wenli": "理科", "jihua": 40, "luqu": 40, "min_score": 280 },
  { "name": "[9493] 浙江广厦建设职业技术大学(原浙江广厦建设职业技术学院)", "is_wenli": "理科", "jihua": 24, "luqu": 7, "min_score": 257 },
  { "name": "[9494] 杭州万向职业技术学院", "is_wenli": "理科", "jihua": 8, "luqu": 3, "min_score": 216 },
  { "name": "[9508] 安徽国防科技职业学院", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 224 },
  { "name": "[9516] 亳州职业技术学院", "is_wenli": "理科", "jihua": 15, "luqu": 15, "min_score": 189 },
  { "name": "[9517] 阜阳科技职业学院", "is_wenli": "理科", "jihua": 15, "luqu": 1, "min_score": 234 },
  { "name": "[9521] 福建信息职业技术学院", "is_wenli": "理科", "jihua": 20, "luqu": 20, "min_score": 279 },
  { "name": "[9522] 福建水利电力职业技术学院", "is_wenli": "理科", "jihua": 10, "luqu": 2, "min_score": 212 },
  { "name": "[9523] 福建电力职业技术学院", "is_wenli": "理科", "jihua": 4, "luqu": 3, "min_score": 247 },
  { "name": "[9524] 福建林业职业技术学院", "is_wenli": "理科", "jihua": 12, "luqu": 1, "min_score": 213 },
  { "name": "[9525] 福建农业职业技术学院", "is_wenli": "理科", "jihua": 68, "luqu": 9, "min_score": 186 },
  { "name": "[9526] 厦门海洋职业技术学院", "is_wenli": "理科", "jihua": 81, "luqu": 70, "min_score": 187 },
  { "name": "[9531] 江西生物科技职业学院", "is_wenli": "理科", "jihua": 6, "luqu": 3, "min_score": 160 },
  { "name": "[9538] 南昌工学院", "is_wenli": "理科", "jihua": 29, "luqu": 29, "min_score": 200 },
  { "name": "[9539] 南昌职业大学(原南昌职业学院)", "is_wenli": "理科", "jihua": 50, "luqu": 10, "min_score": 193 },
  { "name": "[9540] 江西服装学院", "is_wenli": "理科", "jihua": 23, "luqu": 7, "min_score": 182 },
  { "name": "[9541] 江西科技职业学院", "is_wenli": "理科", "jihua": 4, "luqu": 1, "min_score": 192 },
  { "name": "[9543] 泰山护理职业学院", "is_wenli": "理科", "jihua": 11, "luqu": 9, "min_score": 195 },
  { "name": "[9544] 济南护理职业学院", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 261 },
  { "name": "[9545] 山东化工职业学院", "is_wenli": "理科", "jihua": 3, "luqu": 1, "min_score": 193 },
  { "name": "[9548] 山东海事职业学院", "is_wenli": "理科", "jihua": 22, "luqu": 3, "min_score": 209 },
  { "name": "[9551] 青岛恒星科技学院", "is_wenli": "理科", "jihua": 65, "luqu": 45, "min_score": 181 },
  { "name": "[9552] 青岛求实职业技术学院", "is_wenli": "理科", "jihua": 17, "luqu": 1, "min_score": 223 },
  { "name": "[9553] 山东现代学院", "is_wenli": "理科", "jihua": 100, "luqu": 100, "min_score": 207 },
  { "name": "[9554] 青岛黄海学院", "is_wenli": "理科", "jihua": 16, "luqu": 20, "min_score": 190 },
  { "name": "[9555] 潍坊工程职业学院(原潍坊教育学院)", "is_wenli": "理科", "jihua": 22, "luqu": 3, "min_score": 187 },
  { "name": "[9562] 襄阳汽车职业技术学院", "is_wenli": "理科", "jihua": 180, "luqu": 171, "min_score": 183 },
  { "name": "[9564] 武汉交通职业学院", "is_wenli": "理科", "jihua": 34, "luqu": 36, "min_score": 361 },
  { "name": "[9565] 咸宁职业技术学院", "is_wenli": "理科", "jihua": 16, "luqu": 12, "min_score": 185 },
  { "name": "[9568] 长沙职业技术学院", "is_wenli": "理科", "jihua": 2, "luqu": 2, "min_score": 195 },
  { "name": "[9572] 湖南城建职业技术学院", "is_wenli": "理科", "jihua": 2, "luqu": 2, "min_score": 207 },
  { "name": "[9573] 湖南化工职业技术学院", "is_wenli": "理科", "jihua": 10, "luqu": 9, "min_score": 185 },
  { "name": "[9576] 湖南司法警官职业学院", "is_wenli": "理科", "jihua": 5, "luqu": 4, "min_score": 192 },
  { "name": "[9577] 长沙商贸旅游职业技术学院", "is_wenli": "理科", "jihua": 20, "luqu": 10, "min_score": 228 },
  { "name": "[9585] 广东科学技术职业学院", "is_wenli": "理科", "jihua": 86, "luqu": 86, "min_score": 352 },
  { "name": "[9585] 广东科学技术职业学院[较高收费]", "is_wenli": "理科", "jihua": 6, "luqu": 5, "min_score": 239 },
  { "name": "[9586] 广东食品药品职业学院", "is_wenli": "理科", "jihua": 48, "luqu": 29, "min_score": 212 },
  { "name": "[9590] 广东创新科技职业学院", "is_wenli": "理科", "jihua": 45, "luqu": 1, "min_score": 257 },
  { "name": "[9603] 三亚中瑞酒店管理职业学院", "is_wenli": "理科", "jihua": 75, "luqu": 6, "min_score": 232 },
  { "name": "[9608] 广西工业职业技术学院", "is_wenli": "理科", "jihua": 7, "luqu": 2, "min_score": 260 },
  { "name": "[9609] 海南软件职业技术学院", "is_wenli": "理科", "jihua": 19, "luqu": 19, "min_score": 263 },
  { "name": "[9616] 哈尔滨北方航空职业技术学院", "is_wenli": "理科", "jihua": 47, "luqu": 1, "min_score": 190 },
  { "name": "[9617] 广东茂名农林科技职业学院", "is_wenli": "理科", "jihua": 10, "luqu": 2, "min_score": 206 },
  { "name": "[9628] 云南医药健康职业学院", "is_wenli": "理科", "jihua": 17, "luqu": 17, "min_score": 183 },
  { "name": "[9633] 江西婺源茶业职业学院", "is_wenli": "理科", "jihua": 15, "luqu": 1, "min_score": 198 },
  { "name": "[9635] 石河子工程职业技术学院", "is_wenli": "理科", "jihua": 35, "luqu": 1, "min_score": 325 },
  { "name": "[9636] 桂林生命与健康职业技术学院", "is_wenli": "理科", "jihua": 18, "luqu": 2, "min_score": 243 },
  { "name": "[9663] 湖北健康职业学院", "is_wenli": "理科", "jihua": 84, "luqu": 26, "min_score": 183 },
  { "name": "[9670] 海南政法职业学院", "is_wenli": "理科", "jihua": 10, "luqu": 9, "min_score": 272 },
  { "name": "[9671] 海南外国语职业学院", "is_wenli": "理科", "jihua": 8, "luqu": 7, "min_score": 233 },
  { "name": "[9672] 四川西南航空职业学院", "is_wenli": "理科", "jihua": 40, "luqu": 17, "min_score": 198 },
  { "name": "[9673] 新疆铁道职业技术学院", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 221 },
  { "name": "[9674] 广西培贤国际职业学院", "is_wenli": "理科", "jihua": 32, "luqu": 1, "min_score": 213 },
  { "name": "[9675] 重庆三峡职业学院", "is_wenli": "理科", "jihua": 13, "luqu": 13, "min_score": 214 },
  { "name": "[9677] 重庆机电职业技术大学(原重庆机电职业技术学院)", "is_wenli": "理科", "jihua": 8, "luqu": 8, "min_score": 250 },
  { "name": "[9678] 重庆电子工程职业学院", "is_wenli": "理科", "jihua": 60, "luqu": 60, "min_score": 397 },
  { "name": "[9700] 成都东软学院", "is_wenli": "理科", "jihua": 8, "luqu": 8, "min_score": 305 },
  { "name": "[9702] 铜仁职业技术学院", "is_wenli": "理科", "jihua": 8, "luqu": 8, "min_score": 366 },
  { "name": "[9705] 宝鸡职业技术学院", "is_wenli": "理科", "jihua": 15, "luqu": 12, "min_score": 214 },
  { "name": "[9706] 陕西航空职业技术学院", "is_wenli": "理科", "jihua": 29, "luqu": 29, "min_score": 203 },
  { "name": "[9707] 湖南劳动人事职业学院", "is_wenli": "理科", "jihua": 14, "luqu": 1, "min_score": 236 },
  { "name": "[9708] 陕西铁路工程职业技术学院", "is_wenli": "理科", "jihua": 50, "luqu": 50, "min_score": 319 },
  { "name": "[9710] 西安交通工程学院", "is_wenli": "理科", "jihua": 68, "luqu": 68, "min_score": 186 },
  { "name": "[9715] 阿克苏职业技术学院", "is_wenli": "理科", "jihua": 20, "luqu": 2, "min_score": 303 },
  { "name": "[9716] 天津滨海汽车工程职业学院", "is_wenli": "理科", "jihua": 120, "luqu": 2, "min_score": 241 },
  { "name": "[9717] 新疆职业大学", "is_wenli": "理科", "jihua": 28, "luqu": 13, "min_score": 202 },
  { "name": "[9718] 新疆建设职业技术学院", "is_wenli": "理科", "jihua": 20, "luqu": 5, "min_score": 240 },
  { "name": "[9719] 苏州托普信息职业技术学院", "is_wenli": "理科", "jihua": 46, "luqu": 15, "min_score": 229 },
  { "name": "[9721] 江西软件职业技术大学(原江西先锋软件职业技术学院)", "is_wenli": "理科", "jihua": 10, "luqu": 3, "min_score": 236 },
  { "name": "[9722] 共青科技职业学院", "is_wenli": "理科", "jihua": 61, "luqu": 64, "min_score": 186 },
  { "name": "[9731] 北京电子科技职业学院", "is_wenli": "理科", "jihua": 6, "luqu": 6, "min_score": 440 },
  { "name": "[9732] 河北司法警官职业学院", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 290 },
  { "name": "[9736] 太原旅游职业学院", "is_wenli": "理科", "jihua": 10, "luqu": 1, "min_score": 378 },
  { "name": "[9740] 潞安职业技术学院", "is_wenli": "理科", "jihua": 20, "luqu": 1, "min_score": 248 },
  { "name": "[9747] 黑龙江能源职业学院", "is_wenli": "理科", "jihua": 50, "luqu": 1, "min_score": 182 },
  { "name": "[9748] 黑龙江生态工程职业学院", "is_wenli": "理科", "jihua": 64, "luqu": 7, "min_score": 203 },
  { "name": "[9750] 南京铁道职业技术学院", "is_wenli": "理科", "jihua": 42, "luqu": 42, "min_score": 411 },
  { "name": "[9751] 无锡城市职业技术学院", "is_wenli": "理科", "jihua": 110, "luqu": 110, "min_score": 252 },
  { "name": "[9752] 无锡工艺职业技术学院", "is_wenli": "理科", "jihua": 10, "luqu": 7, "min_score": 205 },
  { "name": "[9753] 苏州健雄职业技术学院", "is_wenli": "理科", "jihua": 92, "luqu": 92, "min_score": 233 },
  { "name": "[9755] 江苏财经职业技术学院", "is_wenli": "理科", "jihua": 114, "luqu": 108, "min_score": 180 },
  { "name": "[9756] 扬州工业职业技术学院", "is_wenli": "理科", "jihua": 15, "luqu": 15, "min_score": 247 },
  { "name": "[9757] 宁波卫生职业技术学院", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 301 },
  { "name": "[9766] 闽西职业技术学院", "is_wenli": "理科", "jihua": 66, "luqu": 17, "min_score": 211 },
  { "name": "[9771] 江西工程职业学院", "is_wenli": "理科", "jihua": 20, "luqu": 13, "min_score": 266 },
  { "name": "[9774] 江西航空职业技术学院", "is_wenli": "理科", "jihua": 15, "luqu": 4, "min_score": 202 },
  { "name": "[9775] 江西农业工程职业学院", "is_wenli": "理科", "jihua": 25, "luqu": 24, "min_score": 160 },
  { "name": "[9776] 济南职业学院", "is_wenli": "理科", "jihua": 10, "luqu": 4, "min_score": 230 },
  { "name": "[9782] 湖北国土资源职业学院", "is_wenli": "理科", "jihua": 25, "luqu": 27, "min_score": 227 },
  { "name": "[9784] 湖北生态工程职业技术学院", "is_wenli": "理科", "jihua": 89, "luqu": 89, "min_score": 160 },
  { "name": "[9785] 湖北生物科技职业学院", "is_wenli": "理科", "jihua": 15, "luqu": 15, "min_score": 242 },
  { "name": "[9786] 湖北财税职业学院", "is_wenli": "理科", "jihua": 27, "luqu": 26, "min_score": 253 },
  { "name": "[9788] 武汉民政职业学院", "is_wenli": "理科", "jihua": 11, "luqu": 11, "min_score": 284 },
  { "name": "[9791] 武汉铁路职业技术学院", "is_wenli": "理科", "jihua": 186, "luqu": 186, "min_score": 386 },
  { "name": "[9792] 湖北三峡职业技术学院", "is_wenli": "理科", "jihua": 60, "luqu": 60, "min_score": 216 },
  { "name": "[9801] 中山火炬职业技术学院", "is_wenli": "理科", "jihua": 58, "luqu": 6, "min_score": 208 },
  { "name": "[9802] 江门职业技术学院", "is_wenli": "理科", "jihua": 31, "luqu": 3, "min_score": 303 },
  { "name": "[9803] 茂名职业技术学院", "is_wenli": "理科", "jihua": 25, "luqu": 1, "min_score": 330 },
  { "name": "[9805] 珠海城市职业技术学院", "is_wenli": "理科", "jihua": 30, "luqu": 17, "min_score": 183 },
  { "name": "[9808] 广西电力职业技术学院", "is_wenli": "理科", "jihua": 6, "luqu": 4, "min_score": 306 },
  { "name": "[9809] 海南经贸职业技术学院", "is_wenli": "理科", "jihua": 34, "luqu": 34, "min_score": 234 },
  { "name": "[9810] 重庆水利电力职业技术学院", "is_wenli": "理科", "jihua": 11, "luqu": 11, "min_score": 306 },
  { "name": "[9814] 四川司法警官职业学院", "is_wenli": "理科", "jihua": 2, "luqu": 2, "min_score": 372 },
  { "name": "[9821] 云南林业职业技术学院", "is_wenli": "理科", "jihua": 3, "luqu": 3, "min_score": 302 },
  { "name": "[9822] 陕西邮电职业技术学院", "is_wenli": "理科", "jihua": 25, "luqu": 24, "min_score": 182 },
  { "name": "[9824] 北京培黎职业学院", "is_wenli": "理科", "jihua": 20, "luqu": 21, "min_score": 231 },
  { "name": "[9830] 辽宁理工职业大学(原辽宁理工职业学院)", "is_wenli": "理科", "jihua": 71, "luqu": 2, "min_score": 419 },
  { "name": "[9832] 上海震旦职业学院", "is_wenli": "理科", "jihua": 37, "luqu": 20, "min_score": 196 },
  { "name": "[9833] 上海民远职业技术学院", "is_wenli": "理科", "jihua": 9, "luqu": 5, "min_score": 253 },
  { "name": "[9835] 上海立达学院", "is_wenli": "理科", "jihua": 13, "luqu": 13, "min_score": 356 },
  { "name": "[9836] 上海思博职业技术学院", "is_wenli": "理科", "jihua": 52, "luqu": 51, "min_score": 201 },
  { "name": "[9837] 江海职业技术学院", "is_wenli": "理科", "jihua": 84, "luqu": 54, "min_score": 181 },
  { "name": "[9838] 金山职业技术学院", "is_wenli": "理科", "jihua": 35, "luqu": 1, "min_score": 266 },
  { "name": "[9839] 浙江长征职业技术学院", "is_wenli": "理科", "jihua": 21, "luqu": 8, "min_score": 187 },
  { "name": "[9840] 江西工程学院", "is_wenli": "理科", "jihua": 20, "luqu": 20, "min_score": 205 },
  { "name": "[9841] 威海海洋职业学院", "is_wenli": "理科", "jihua": 9, "luqu": 2, "min_score": 236 },
  { "name": "[9842] 赣西科技职业学院", "is_wenli": "理科", "jihua": 15, "luqu": 1, "min_score": 190 },
  { "name": "[9844] 山东协和学院", "is_wenli": "理科", "jihua": 74, "luqu": 66, "min_score": 181 },
  { "name": "[9844] 山东协和学院[较高收费]", "is_wenli": "理科", "jihua": 26, "luqu": 26, "min_score": 183 },
  { "name": "[9845] 山东华宇工学院", "is_wenli": "理科", "jihua": 78, "luqu": 78, "min_score": 186 },
  { "name": "[9846] 山东外事职业大学", "is_wenli": "理科", "jihua": 55, "luqu": 33, "min_score": 182 },
  { "name": "[9847] 黄冈科技职业学院", "is_wenli": "理科", "jihua": 77, "luqu": 54, "min_score": 187 },
  { "name": "[9848] 湖南应用技术学院", "is_wenli": "理科", "jihua": 6, "luqu": 6, "min_score": 216 },
  { "name": "[9849] 惠州经济职业技术学院", "is_wenli": "理科", "jihua": 50, "luqu": 3, "min_score": 247 },
  { "name": "[9850] 广东工商职业技术大学", "is_wenli": "理科", "jihua": 30, "luqu": 3, "min_score": 190 },
  { "name": "[9854] 广州南洋理工职业学院", "is_wenli": "理科", "jihua": 18, "luqu": 1, "min_score": 255 },
  { "name": "[9855] 广州科技职业技术大学(原广州科技职业技术学院)", "is_wenli": "理科", "jihua": 30, "luqu": 21, "min_score": 188 },
  { "name": "[9858] 广西外国语学院", "is_wenli": "理科", "jihua": 23, "luqu": 4, "min_score": 263 },
  { "name": "[9859] 海南工商职业学院", "is_wenli": "理科", "jihua": 20, "luqu": 2, "min_score": 268 },
  { "name": "[9862] 云南经济管理学院", "is_wenli": "理科", "jihua": 7, "luqu": 6, "min_score": 213 },
  { "name": "[9864] 云南工程职业学院", "is_wenli": "理科", "jihua": 5, "luqu": 1, "min_score": 206 },
  { "name": "[9866] 西安汽车职业大学(原西安汽车科技职业学院)", "is_wenli": "理科", "jihua": 35, "luqu": 7, "min_score": 206 },
  { "name": "[9867] 西安海棠职业学院", "is_wenli": "理科", "jihua": 18, "luqu": 8, "min_score": 197 },
  { "name": "[9869] 新疆现代职业技术学院", "is_wenli": "理科", "jihua": 33, "luqu": 3, "min_score": 185 },
  { "name": "[9874] 南京旅游职业学院", "is_wenli": "理科", "jihua": 15, "luqu": 15, "min_score": 329 },
  { "name": "[9875] 扬州中瑞酒店职业学院", "is_wenli": "理科", "jihua": 108, "luqu": 4, "min_score": 215 },
  { "name": "[9876] 吉安职业技术学院", "is_wenli": "理科", "jihua": 4, "luqu": 1, "min_score": 315 },
  { "name": "[9877] 重庆幼儿师范高等专科学校", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 336 },
  { "name": "[9878] 北京交通职业技术学院", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 401 },
  { "name": "[9882] 松原职业技术学院", "is_wenli": "理科", "jihua": 46, "luqu": 1, "min_score": 205 },
  { "name": "[9884] 湖南工艺美术职业学院", "is_wenli": "理科", "jihua": 10, "luqu": 2, "min_score": 210 },
  { "name": "[9886] 广东理工职业学院", "is_wenli": "理科", "jihua": 53, "luqu": 33, "min_score": 186 },
  { "name": "[9887] 广州华南商贸职业学院", "is_wenli": "理科", "jihua": 30, "luqu": 2, "min_score": 320 },
  { "name": "[9888] 广州华立科技职业学院", "is_wenli": "理科", "jihua": 49, "luqu": 6, "min_score": 245 },
  { "name": "[9889] 广州城市职业学院", "is_wenli": "理科", "jihua": 15, "luqu": 15, "min_score": 331 },
  { "name": "[9890] 广东工程职业技术学院", "is_wenli": "理科", "jihua": 50, "luqu": 14, "min_score": 202 },
  { "name": "[9892] 兰州资源环境职业技术学院", "is_wenli": "理科", "jihua": 18, "luqu": 9, "min_score": 187 },
  { "name": "[9899] 嘉兴南洋职业技术学院", "is_wenli": "理科", "jihua": 20, "luqu": 2, "min_score": 249 },

]