module.exports = {
  "本科专业名称": "毕业半年后的平均月收入",
  "信息安全": "7579",
  "信息工程": "7157",
  "数据科学与大数据技术": "7074",
  "软件工程": "7056",
  "电子科学与技术": "6971",
  "微电子科学与工程": "6889",
  "网络工程": "6878",
  "物联网工程": "6870",
  "电子信息科学与技术": "6761",
  "自动化": "6698",
  "计算机科学与技术": "6686",
  "信息管理与信息系统": "6667",
  "测控技术与仪器": "6631",
  "光电信息科学与工程": "6583",
  // "工业工程": "6559",
  // "道路桥梁与渡河工程": "6543",
  // "电子信息工程": "6514",
  // "材料成型及控制工程": "6493",
  // "机械工程": "6481",
  // "机械电子工程": "6447",
  // "生物医学工程": "6408",
  // "机械设计制造及其自动化": "6407",
  // "金融学": "6394",
  // "通信工程": "6391",
  // "材料科学与工程": "6383",
  // "电气工程及其自动化": "6376",
  // "交通运输": "6373",
  // "能源与动力工程": "6311",
  // "过程装备与控制工程": "6309",
  // "安全工程": "6294",
  // "信息与计算科学": "6270",
  // "统计学": "6260",
  // "数字媒体技术": "6255",
  // "高分子材料与工程": "6248",
  // "水利水电工程": "6233",
  // "建筑电气与智能化": "6225",
  // "土木工程": "6222",
  // "工业设计": "6221",
  // "地质工程": "6178",
  // "物流工程": "6178",
  // "测绘工程": "6177",
  // "金融工程": "6169",
  // "税收学": "6156",
  // "建筑学": "6130",
  // "车辆工程": "6094",
  // "物流管理": "6084",
  // "给排水科学与工程": "6081",
  // "应用物理学": "6077",
  // "建筑环境与能源应用工程": "6073",
  // "产品设计": "6070",
  // "全国本科": "5990",
}