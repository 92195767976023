module.exports = {
  "职业名称": "就业比例",
  "口腔医学": "97",
  "铁道机车": "93",
  "临床医学": "91",
  "医学检验技术": "88",
  "眼视光技术": "87",
  "针灸推拿": "87",
  "护理": "86",
  "助产": "85",
  "学前教育": "82",
  "铁道工程技术": "82",
  "发电厂及电力系统": "82",
  "动物医学": "81",
  "道路桥梁工程技术": "81",
  "小学教育": "80",
  // "畜牧兽医": "80",
  // "中医学": "80",
  // "电力系统自动化技术": "79",
  // "语文教育": "79",
  // "工程测量技术": "79",
  // "铁道供电技术": "78",
  // "动车组检修技术": "78",
  // "市政工程技术": "78",
  // "中药学": "77",
  // "建设工程监理": "77",
  // "石油化工技术": "77",
  // "体育教育": "77",
  // "城市轨道交通车辆技术": "77",
  // "药学": "76",
  // "口腔医学技术": "76",
  // "康复治疗技术": "76",
  // "全国高职": "63",

}