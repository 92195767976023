module.exports = [
  {
    "specialty_code": "080901",
    "name": "计算机科学与技术",
    "is_wenli": "理科",
    "jihua": "8478",
    "baokao": "19634",
    "luqu": "9629",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080902",
    "name": "软件工程",
    "is_wenli": "理科",
    "jihua": "9671",
    "baokao": "15661",
    "luqu": "9985",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080601",
    "name": "电气工程及其自动化",
    "is_wenli": "理科",
    "jihua": "3603",
    "baokao": "10138",
    "luqu": "4085",
    "pici": "本科二批"
  },
  {
    "specialty_code": "070101",
    "name": "数学与应用数学",
    "is_wenli": "理科",
    "jihua": "2012",
    "baokao": "5693",
    "luqu": "2194",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080910",
    "name": "数据科学与大数据技术",
    "is_wenli": "理科",
    "jihua": "4019",
    "baokao": "5445",
    "luqu": "4191",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080202",
    "name": "机械设计制造及其自动化",
    "is_wenli": "理科",
    "jihua": "3648",
    "baokao": "4882",
    "luqu": "3859",
    "pici": "本科二批"
  },
  {
    "specialty_code": "050201",
    "name": "英语",
    "is_wenli": "理科",
    "jihua": "2253",
    "baokao": "4785",
    "luqu": "2604",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080701",
    "name": "电子信息工程",
    "is_wenli": "理科",
    "jihua": "2730",
    "baokao": "4578",
    "luqu": "2809",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120203",
    "name": "会计学",
    "is_wenli": "理科",
    "jihua": "3665",
    "baokao": "4025",
    "luqu": "3776",
    "pici": "本科二批"
  },
  {
    "specialty_code": "100201K",
    "name": "临床医学",
    "is_wenli": "理科",
    "jihua": "988",
    "baokao": "3780",
    "luqu": "1088",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080905",
    "name": "物联网工程",
    "is_wenli": "理科",
    "jihua": "2700",
    "baokao": "3471",
    "luqu": "2676",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080703",
    "name": "通信工程",
    "is_wenli": "理科",
    "jihua": "1785",
    "baokao": "2540",
    "luqu": "1731",
    "pici": "本科二批"
  },
  {
    "specialty_code": "101101",
    "name": "护理学",
    "is_wenli": "理科",
    "jihua": "1641",
    "baokao": "2346",
    "luqu": "1722",
    "pici": "本科二批"
  },
  {
    "specialty_code": "070301",
    "name": "化学",
    "is_wenli": "理科",
    "jihua": "992",
    "baokao": "2222",
    "luqu": "1013",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0809",
    "name": "计算机类",
    "is_wenli": "理科",
    "jihua": "817",
    "baokao": "2184",
    "luqu": "936",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080717",
    "name": "人工智能",
    "is_wenli": "理科",
    "jihua": "1616",
    "baokao": "2142",
    "luqu": "1631",
    "pici": "本科二批"
  },
  {
    "specialty_code": "030101",
    "name": "法学",
    "is_wenli": "理科",
    "jihua": "795",
    "baokao": "2040",
    "luqu": "858",
    "pici": "本科二批"
  },
  {
    "specialty_code": "040107",
    "name": "小学教育",
    "is_wenli": "理科",
    "jihua": "901",
    "baokao": "1945",
    "luqu": "1231",
    "pici": "本科二批"
  },
  {
    "specialty_code": "070302",
    "name": "应用化学",
    "is_wenli": "理科",
    "jihua": "1216",
    "baokao": "1820",
    "luqu": "1232",
    "pici": "本科二批"
  },
  {
    "specialty_code": "071001",
    "name": "生物科学",
    "is_wenli": "理科",
    "jihua": "649",
    "baokao": "1764",
    "luqu": "657",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120204",
    "name": "财务管理",
    "is_wenli": "理科",
    "jihua": "2690",
    "baokao": "1690",
    "luqu": "2440",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080801",
    "name": "自动化",
    "is_wenli": "理科",
    "jihua": "1251",
    "baokao": "1683",
    "luqu": "1248",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081001",
    "name": "土木工程",
    "is_wenli": "理科",
    "jihua": "4436",
    "baokao": "1598",
    "luqu": "4223",
    "pici": "本科二批"
  },
  {
    "specialty_code": "070102",
    "name": "信息与计算科学",
    "is_wenli": "理科",
    "jihua": "566",
    "baokao": "1561",
    "luqu": "601",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080906",
    "name": "数字媒体技术",
    "is_wenli": "理科",
    "jihua": "843",
    "baokao": "1496",
    "luqu": "893",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080903",
    "name": "网络工程",
    "is_wenli": "理科",
    "jihua": "1462",
    "baokao": "1493",
    "luqu": "1495",
    "pici": "本科二批"
  },
  {
    "specialty_code": "090401",
    "name": "动物医学",
    "is_wenli": "理科",
    "jihua": "783",
    "baokao": "1486",
    "luqu": "801",
    "pici": "本科二批"
  },
  {
    "specialty_code": "050101",
    "name": "汉语言文学",
    "is_wenli": "理科",
    "jihua": "498",
    "baokao": "1468",
    "luqu": "1045",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080213",
    "name": "智能制造工程",
    "is_wenli": "理科",
    "jihua": "798",
    "baokao": "1419",
    "luqu": "737",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081302",
    "name": "制药工程",
    "is_wenli": "理科",
    "jihua": "885",
    "baokao": "1278",
    "luqu": "876",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081301",
    "name": "化学工程与工艺",
    "is_wenli": "理科",
    "jihua": "1371",
    "baokao": "1276",
    "luqu": "1369",
    "pici": "本科二批"
  },
  {
    "specialty_code": "050306",
    "name": "网络与新媒体",
    "is_wenli": "理科",
    "jihua": "630",
    "baokao": "1269",
    "luqu": "735",
    "pici": "本科二批"
  },
  {
    "specialty_code": "070201",
    "name": "物理学",
    "is_wenli": "理科",
    "jihua": "693",
    "baokao": "1262",
    "luqu": "721",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080501",
    "name": "能源与动力工程",
    "is_wenli": "理科",
    "jihua": "502",
    "baokao": "1114",
    "luqu": "510",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082502",
    "name": "环境工程",
    "is_wenli": "理科",
    "jihua": "770",
    "baokao": "1102",
    "luqu": "755",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082701",
    "name": "食品科学与工程",
    "is_wenli": "理科",
    "jihua": "1146",
    "baokao": "1077",
    "luqu": "1138",
    "pici": "本科二批"
  },
  {
    "specialty_code": "071002",
    "name": "生物技术",
    "is_wenli": "理科",
    "jihua": "797",
    "baokao": "1062",
    "luqu": "812",
    "pici": "本科二批"
  },
  {
    "specialty_code": "100203",
    "name": "医学影像学",
    "is_wenli": "理科",
    "jihua": "340",
    "baokao": "1021",
    "luqu": "349",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080204",
    "name": "机械电子工程",
    "is_wenli": "理科",
    "jihua": "1704",
    "baokao": "1011",
    "luqu": "1692",
    "pici": "本科二批"
  },
  {
    "specialty_code": "083001",
    "name": "生物工程",
    "is_wenli": "理科",
    "jihua": "886",
    "baokao": "1004",
    "luqu": "882",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080706",
    "name": "信息工程",
    "is_wenli": "理科",
    "jihua": "214",
    "baokao": "997",
    "luqu": "213",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080803",
    "name": "机器人工程",
    "is_wenli": "理科",
    "jihua": "1635",
    "baokao": "986",
    "luqu": "1548",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120207",
    "name": "审计学",
    "is_wenli": "理科",
    "jihua": "928",
    "baokao": "986",
    "luqu": "953",
    "pici": "本科二批"
  },
  {
    "specialty_code": "050262",
    "name": "商务英语",
    "is_wenli": "理科",
    "jihua": "1366",
    "baokao": "978",
    "luqu": "1291",
    "pici": "本科二批"
  },
  {
    "specialty_code": "100301",
    "name": "口腔医学",
    "is_wenli": "理科",
    "jihua": "138",
    "baokao": "948",
    "luqu": "142",
    "pici": "本科二批"
  },
  {
    "specialty_code": "101001",
    "name": "医学检验技术",
    "is_wenli": "理科",
    "jihua": "818",
    "baokao": "935",
    "luqu": "860",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120102",
    "name": "信息管理与信息系统",
    "is_wenli": "理科",
    "jihua": "635",
    "baokao": "878",
    "luqu": "619",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120105",
    "name": "工程造价",
    "is_wenli": "理科",
    "jihua": "1949",
    "baokao": "842",
    "luqu": "1799",
    "pici": "本科二批"
  },
  {
    "specialty_code": "020301",
    "name": "金融学",
    "is_wenli": "理科",
    "jihua": "1223",
    "baokao": "807",
    "luqu": "1165",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082702",
    "name": "食品质量与安全",
    "is_wenli": "理科",
    "jihua": "1033",
    "baokao": "791",
    "luqu": "1037",
    "pici": "本科二批"
  },
  {
    "specialty_code": "040106",
    "name": "学前教育",
    "is_wenli": "理科",
    "jihua": "1232",
    "baokao": "789",
    "luqu": "1223",
    "pici": "本科二批"
  },
  {
    "specialty_code": "071202",
    "name": "应用统计学",
    "is_wenli": "理科",
    "jihua": "498",
    "baokao": "781",
    "luqu": "500",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0807",
    "name": "电子信息类",
    "is_wenli": "理科",
    "jihua": "408",
    "baokao": "768",
    "luqu": "411",
    "pici": "本科二批"
  },
  {
    "specialty_code": "100501",
    "name": "中医学",
    "is_wenli": "理科",
    "jihua": "182",
    "baokao": "751",
    "luqu": "206",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080205",
    "name": "工业设计",
    "is_wenli": "理科",
    "jihua": "265",
    "baokao": "726",
    "luqu": "250",
    "pici": "本科二批"
  },
  {
    "specialty_code": "083002",
    "name": "生物制药",
    "is_wenli": "理科",
    "jihua": "465",
    "baokao": "721",
    "luqu": "470",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0802",
    "name": "机械类",
    "is_wenli": "理科",
    "jihua": "818",
    "baokao": "710",
    "luqu": "821",
    "pici": "本科二批"
  },
  {
    "specialty_code": "020302",
    "name": "金融工程",
    "is_wenli": "理科",
    "jihua": "777",
    "baokao": "709",
    "luqu": "786",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120801",
    "name": "电子商务",
    "is_wenli": "理科",
    "jihua": "1246",
    "baokao": "699",
    "luqu": "1240",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120601",
    "name": "物流管理",
    "is_wenli": "理科",
    "jihua": "1564",
    "baokao": "694",
    "luqu": "1424",
    "pici": "本科二批"
  },
  {
    "specialty_code": "020310",
    "name": "金融科技",
    "is_wenli": "理科",
    "jihua": "356",
    "baokao": "692",
    "luqu": "374",
    "pici": "本科二批"
  },
  {
    "specialty_code": "040104",
    "name": "教育技术学",
    "is_wenli": "理科",
    "jihua": "244",
    "baokao": "657",
    "luqu": "244",
    "pici": "本科二批"
  },
  {
    "specialty_code": "020401",
    "name": "国际经济与贸易",
    "is_wenli": "理科",
    "jihua": "1660",
    "baokao": "651",
    "luqu": "1531",
    "pici": "本科二批"
  },
  {
    "specialty_code": "100701",
    "name": "药学",
    "is_wenli": "理科",
    "jihua": "683",
    "baokao": "567",
    "luqu": "715",
    "pici": "本科二批"
  },
  {
    "specialty_code": "100202",
    "name": "麻醉学",
    "is_wenli": "理科",
    "jihua": "208",
    "baokao": "561",
    "luqu": "219",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08020G",
    "name": "机械设计制造及其自动化(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "539",
    "baokao": "549",
    "luqu": "552",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080503",
    "name": "新能源科学与工程",
    "is_wenli": "理科",
    "jihua": "301",
    "baokao": "537",
    "luqu": "310",
    "pici": "本科二批"
  },
  {
    "specialty_code": "020101",
    "name": "经济学",
    "is_wenli": "理科",
    "jihua": "850",
    "baokao": "518",
    "luqu": "822",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08060F",
    "name": "电气工程及其自动化(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "481",
    "baokao": "503",
    "luqu": "486",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0710",
    "name": "生物科学类",
    "is_wenli": "理科",
    "jihua": "71",
    "baokao": "502",
    "luqu": "75",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081101",
    "name": "水利水电工程",
    "is_wenli": "理科",
    "jihua": "149",
    "baokao": "498",
    "luqu": "161",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080407",
    "name": "高分子材料与工程",
    "is_wenli": "理科",
    "jihua": "371",
    "baokao": "489",
    "luqu": "372",
    "pici": "本科二批"
  },
  {
    "specialty_code": "260101",
    "name": "机械设计制造及自动化",
    "is_wenli": "理科",
    "jihua": "244",
    "baokao": "484",
    "luqu": "291",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080414",
    "name": "新能源材料与器件",
    "is_wenli": "理科",
    "jihua": "355",
    "baokao": "481",
    "luqu": "356",
    "pici": "本科二批"
  },
  {
    "specialty_code": "101003",
    "name": "医学影像技术",
    "is_wenli": "理科",
    "jihua": "365",
    "baokao": "479",
    "luqu": "396",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0701",
    "name": "数学类",
    "is_wenli": "理科",
    "jihua": "167",
    "baokao": "471",
    "luqu": "167",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080301",
    "name": "测控技术与仪器",
    "is_wenli": "理科",
    "jihua": "652",
    "baokao": "471",
    "luqu": "670",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080907",
    "name": "智能科学与技术",
    "is_wenli": "理科",
    "jihua": "821",
    "baokao": "467",
    "luqu": "820",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080702",
    "name": "电子科学与技术",
    "is_wenli": "理科",
    "jihua": "335",
    "baokao": "452",
    "luqu": "339",
    "pici": "本科二批"
  },
  {
    "specialty_code": "071201",
    "name": "统计学",
    "is_wenli": "理科",
    "jihua": "485",
    "baokao": "445",
    "luqu": "484",
    "pici": "本科二批"
  },
  {
    "specialty_code": "070202",
    "name": "应用物理学",
    "is_wenli": "理科",
    "jihua": "197",
    "baokao": "443",
    "luqu": "200",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0810",
    "name": "土木类",
    "is_wenli": "理科",
    "jihua": "549",
    "baokao": "442",
    "luqu": "540",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0830",
    "name": "生物工程类",
    "is_wenli": "理科",
    "jihua": "43",
    "baokao": "435",
    "luqu": "43",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080207",
    "name": "车辆工程",
    "is_wenli": "理科",
    "jihua": "1325",
    "baokao": "425",
    "luqu": "1254",
    "pici": "本科二批"
  },
  {
    "specialty_code": "050261",
    "name": "翻译",
    "is_wenli": "理科",
    "jihua": "371",
    "baokao": "423",
    "luqu": "374",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120201",
    "name": "工商管理",
    "is_wenli": "理科",
    "jihua": "1030",
    "baokao": "420",
    "luqu": "1026",
    "pici": "本科二批"
  },
  {
    "specialty_code": "310203",
    "name": "软件工程技术",
    "is_wenli": "理科",
    "jihua": "177",
    "baokao": "416",
    "luqu": "222",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080403",
    "name": "材料化学",
    "is_wenli": "理科",
    "jihua": "347",
    "baokao": "398",
    "luqu": "355",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120103",
    "name": "工程管理",
    "is_wenli": "理科",
    "jihua": "1343",
    "baokao": "395",
    "luqu": "1231",
    "pici": "本科二批"
  },
  {
    "specialty_code": "310101",
    "name": "电子信息工程技术",
    "is_wenli": "理科",
    "jihua": "216",
    "baokao": "379",
    "luqu": "191",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0401",
    "name": "教育学类",
    "is_wenli": "理科",
    "jihua": "259",
    "baokao": "369",
    "luqu": "259",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08250H",
    "name": "环境科学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "120",
    "baokao": "367",
    "luqu": "120",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120202",
    "name": "市场营销",
    "is_wenli": "理科",
    "jihua": "1408",
    "baokao": "362",
    "luqu": "1221",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082901",
    "name": "安全工程",
    "is_wenli": "理科",
    "jihua": "246",
    "baokao": "337",
    "luqu": "247",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082802",
    "name": "城乡规划",
    "is_wenli": "理科",
    "jihua": "562",
    "baokao": "330",
    "luqu": "438",
    "pici": "本科二批"
  },
  {
    "specialty_code": "101005",
    "name": "康复治疗学",
    "is_wenli": "理科",
    "jihua": "508",
    "baokao": "328",
    "luqu": "507",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080216",
    "name": "新能源汽车工程",
    "is_wenli": "理科",
    "jihua": "239",
    "baokao": "321",
    "luqu": "248",
    "pici": "本科二批"
  },
  {
    "specialty_code": "090101",
    "name": "农学",
    "is_wenli": "理科",
    "jihua": "216",
    "baokao": "317",
    "luqu": "219",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080203",
    "name": "材料成型及控制工程",
    "is_wenli": "理科",
    "jihua": "810",
    "baokao": "311",
    "luqu": "816",
    "pici": "本科二批"
  },
  {
    "specialty_code": "071102",
    "name": "应用心理学",
    "is_wenli": "理科",
    "jihua": "275",
    "baokao": "307",
    "luqu": "277",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080904",
    "name": "信息安全",
    "is_wenli": "理科",
    "jihua": "201",
    "baokao": "304",
    "luqu": "194",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08270F",
    "name": "食品科学与工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "197",
    "baokao": "302",
    "luqu": "203",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080411",
    "name": "焊接技术与工程",
    "is_wenli": "理科",
    "jihua": "203",
    "baokao": "299",
    "luqu": "199",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120206",
    "name": "人力资源管理",
    "is_wenli": "理科",
    "jihua": "575",
    "baokao": "299",
    "luqu": "522",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081003",
    "name": "给排水科学与工程",
    "is_wenli": "理科",
    "jihua": "289",
    "baokao": "297",
    "luqu": "280",
    "pici": "本科二批"
  },
  {
    "specialty_code": "02030F",
    "name": "金融学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "132",
    "baokao": "292",
    "luqu": "132",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081304",
    "name": "能源化学工程",
    "is_wenli": "理科",
    "jihua": "61",
    "baokao": "290",
    "luqu": "58",
    "pici": "本科二批"
  },
  {
    "specialty_code": "09030F",
    "name": "动物科学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "210",
    "baokao": "289",
    "luqu": "210",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08090G",
    "name": "软件工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "346",
    "baokao": "279",
    "luqu": "347",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120108",
    "name": "大数据管理与应用",
    "is_wenli": "理科",
    "jihua": "374",
    "baokao": "278",
    "luqu": "344",
    "pici": "本科二批"
  },
  {
    "specialty_code": "070501",
    "name": "地理科学",
    "is_wenli": "理科",
    "jihua": "283",
    "baokao": "277",
    "luqu": "286",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0201",
    "name": "经济学类",
    "is_wenli": "理科",
    "jihua": "20",
    "baokao": "271",
    "luqu": "19",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080714",
    "name": "电子信息科学与技术",
    "is_wenli": "理科",
    "jihua": "286",
    "baokao": "271",
    "luqu": "283",
    "pici": "本科二批"
  },
  {
    "specialty_code": "040101",
    "name": "教育学",
    "is_wenli": "理科",
    "jihua": "44",
    "baokao": "266",
    "luqu": "52",
    "pici": "本科二批"
  },
  {
    "specialty_code": "071101",
    "name": "心理学",
    "is_wenli": "理科",
    "jihua": "246",
    "baokao": "263",
    "luqu": "248",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080406",
    "name": "无机非金属材料工程",
    "is_wenli": "理科",
    "jihua": "72",
    "baokao": "257",
    "luqu": "71",
    "pici": "本科二批"
  },
  {
    "specialty_code": "10100H",
    "name": "医学影像技术(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "220",
    "baokao": "252",
    "luqu": "220",
    "pici": "本科二批"
  },
  {
    "specialty_code": "020305",
    "name": "金融数学",
    "is_wenli": "理科",
    "jihua": "347",
    "baokao": "248",
    "luqu": "341",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0813",
    "name": "化工与制药类",
    "is_wenli": "理科",
    "jihua": "74",
    "baokao": "245",
    "luqu": "84",
    "pici": "本科二批"
  },
  {
    "specialty_code": "090102",
    "name": "园艺",
    "is_wenli": "理科",
    "jihua": "432",
    "baokao": "238",
    "luqu": "427",
    "pici": "本科二批"
  },
  {
    "specialty_code": "310201",
    "name": "计算机应用工程",
    "is_wenli": "理科",
    "jihua": "407",
    "baokao": "236",
    "luqu": "675",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082803",
    "name": "风景园林",
    "is_wenli": "理科",
    "jihua": "295",
    "baokao": "235",
    "luqu": "283",
    "pici": "本科二批"
  },
  {
    "specialty_code": "090307",
    "name": "智慧牧业科学与工程",
    "is_wenli": "理科",
    "jihua": "110",
    "baokao": "234",
    "luqu": "119",
    "pici": "本科二批"
  },
  {
    "specialty_code": "101006",
    "name": "口腔医学技术",
    "is_wenli": "理科",
    "jihua": "166",
    "baokao": "231",
    "luqu": "180",
    "pici": "本科二批"
  },
  {
    "specialty_code": "020202",
    "name": "税收学",
    "is_wenli": "理科",
    "jihua": "279",
    "baokao": "229",
    "luqu": "268",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082801",
    "name": "建筑学",
    "is_wenli": "理科",
    "jihua": "829",
    "baokao": "227",
    "luqu": "758",
    "pici": "本科二批"
  },
  {
    "specialty_code": "100806",
    "name": "中草药栽培与鉴定",
    "is_wenli": "理科",
    "jihua": "43",
    "baokao": "226",
    "luqu": "43",
    "pici": "本科二批"
  },
  {
    "specialty_code": "090301",
    "name": "动物科学",
    "is_wenli": "理科",
    "jihua": "403",
    "baokao": "223",
    "luqu": "397",
    "pici": "本科二批"
  },
  {
    "specialty_code": "10110F",
    "name": "护理学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "315",
    "baokao": "216",
    "luqu": "336",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08080F",
    "name": "自动化(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "69",
    "baokao": "211",
    "luqu": "69",
    "pici": "本科二批"
  },
  {
    "specialty_code": "020102",
    "name": "经济统计学",
    "is_wenli": "理科",
    "jihua": "436",
    "baokao": "204",
    "luqu": "444",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120404",
    "name": "土地资源管理",
    "is_wenli": "理科",
    "jihua": "34",
    "baokao": "201",
    "luqu": "34",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080201",
    "name": "机械工程",
    "is_wenli": "理科",
    "jihua": "318",
    "baokao": "199",
    "luqu": "291",
    "pici": "本科二批"
  },
  {
    "specialty_code": "12020H",
    "name": "会计学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "97",
    "baokao": "198",
    "luqu": "97",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081504",
    "name": "油气储运工程",
    "is_wenli": "理科",
    "jihua": "45",
    "baokao": "197",
    "luqu": "45",
    "pici": "本科二批"
  },
  {
    "specialty_code": "050103",
    "name": "汉语国际教育",
    "is_wenli": "理科",
    "jihua": "224",
    "baokao": "196",
    "luqu": "222",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081602",
    "name": "服装设计与工程",
    "is_wenli": "理科",
    "jihua": "223",
    "baokao": "195",
    "luqu": "221",
    "pici": "本科二批"
  },
  {
    "specialty_code": "090502",
    "name": "园林",
    "is_wenli": "理科",
    "jihua": "522",
    "baokao": "193",
    "luqu": "496",
    "pici": "本科二批"
  },
  {
    "specialty_code": "050301",
    "name": "新闻学",
    "is_wenli": "理科",
    "jihua": "231",
    "baokao": "186",
    "luqu": "235",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080209",
    "name": "机械工艺技术",
    "is_wenli": "理科",
    "jihua": "8",
    "baokao": "186",
    "luqu": "8",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080604",
    "name": "电气工程与智能控制",
    "is_wenli": "理科",
    "jihua": "98",
    "baokao": "185",
    "luqu": "118",
    "pici": "本科二批"
  },
  {
    "specialty_code": "040206",
    "name": "运动康复",
    "is_wenli": "理科",
    "jihua": "239",
    "baokao": "182",
    "luqu": "269",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120301",
    "name": "农林经济管理",
    "is_wenli": "理科",
    "jihua": "66",
    "baokao": "182",
    "luqu": "65",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081306",
    "name": "化工安全工程",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "181",
    "luqu": "4",
    "pici": "本科二批"
  },
  {
    "specialty_code": "100801",
    "name": "中药学",
    "is_wenli": "理科",
    "jihua": "165",
    "baokao": "181",
    "luqu": "164",
    "pici": "本科二批"
  },
  {
    "specialty_code": "100601",
    "name": "中西医临床医学",
    "is_wenli": "理科",
    "jihua": "53",
    "baokao": "178",
    "luqu": "74",
    "pici": "本科二批"
  },
  {
    "specialty_code": "02040F",
    "name": "国际经济与贸易(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "74",
    "baokao": "177",
    "luqu": "76",
    "pici": "本科二批"
  },
  {
    "specialty_code": "050207",
    "name": "日语",
    "is_wenli": "理科",
    "jihua": "362",
    "baokao": "175",
    "luqu": "355",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081802",
    "name": "交通工程",
    "is_wenli": "理科",
    "jihua": "286",
    "baokao": "175",
    "luqu": "287",
    "pici": "本科二批"
  },
  {
    "specialty_code": "050303",
    "name": "广告学",
    "is_wenli": "理科",
    "jihua": "376",
    "baokao": "174",
    "luqu": "402",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081202",
    "name": "遥感科学与技术",
    "is_wenli": "理科",
    "jihua": "105",
    "baokao": "174",
    "luqu": "105",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080705",
    "name": "光电信息科学与工程",
    "is_wenli": "理科",
    "jihua": "353",
    "baokao": "171",
    "luqu": "340",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0815",
    "name": "矿业类",
    "is_wenli": "理科",
    "jihua": "23",
    "baokao": "171",
    "luqu": "23",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081002",
    "name": "建筑环境与能源应用工程",
    "is_wenli": "理科",
    "jihua": "514",
    "baokao": "170",
    "luqu": "503",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08070F",
    "name": "电子信息工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "170",
    "baokao": "165",
    "luqu": "170",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080401",
    "name": "材料科学与工程",
    "is_wenli": "理科",
    "jihua": "508",
    "baokao": "164",
    "luqu": "460",
    "pici": "本科二批"
  },
  {
    "specialty_code": "100401",
    "name": "预防医学",
    "is_wenli": "理科",
    "jihua": "129",
    "baokao": "164",
    "luqu": "130",
    "pici": "本科二批"
  },
  {
    "specialty_code": "230301",
    "name": "新能源发电工程技术",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "160",
    "luqu": "2",
    "pici": "本科二批"
  },
  {
    "specialty_code": "10070G",
    "name": "药物制剂(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "15",
    "baokao": "157",
    "luqu": "15",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08100F",
    "name": "土木工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "299",
    "baokao": "155",
    "luqu": "304",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082503",
    "name": "环境科学",
    "is_wenli": "理科",
    "jihua": "322",
    "baokao": "154",
    "luqu": "322",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081201",
    "name": "测绘工程",
    "is_wenli": "理科",
    "jihua": "520",
    "baokao": "151",
    "luqu": "531",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081303",
    "name": "资源循环科学与工程",
    "is_wenli": "理科",
    "jihua": "102",
    "baokao": "144",
    "luqu": "119",
    "pici": "本科二批"
  },
  {
    "specialty_code": "260702",
    "name": "新能源汽车工程技术",
    "is_wenli": "理科",
    "jihua": "43",
    "baokao": "143",
    "luqu": "64",
    "pici": "本科二批"
  },
  {
    "specialty_code": "12010G",
    "name": "信息管理与信息系统(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "242",
    "baokao": "140",
    "luqu": "252",
    "pici": "本科二批"
  },
  {
    "specialty_code": "09040F",
    "name": "动物医学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "98",
    "baokao": "139",
    "luqu": "98",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120602",
    "name": "物流工程",
    "is_wenli": "理科",
    "jihua": "328",
    "baokao": "138",
    "luqu": "319",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0806",
    "name": "电气类",
    "is_wenli": "理科",
    "jihua": "57",
    "baokao": "137",
    "luqu": "57",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081006",
    "name": "道路桥梁与渡河工程",
    "is_wenli": "理科",
    "jihua": "538",
    "baokao": "137",
    "luqu": "444",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0827",
    "name": "食品科学与工程类",
    "is_wenli": "理科",
    "jihua": "168",
    "baokao": "137",
    "luqu": "168",
    "pici": "本科二批"
  },
  {
    "specialty_code": "320201",
    "name": "护理",
    "is_wenli": "理科",
    "jihua": "157",
    "baokao": "137",
    "luqu": "379",
    "pici": "本科二批"
  },
  {
    "specialty_code": "070504",
    "name": "地理信息科学",
    "is_wenli": "理科",
    "jihua": "208",
    "baokao": "136",
    "luqu": "220",
    "pici": "本科二批"
  },
  {
    "specialty_code": "090103",
    "name": "植物保护",
    "is_wenli": "理科",
    "jihua": "209",
    "baokao": "135",
    "luqu": "214",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08090F",
    "name": "计算机科学与技术(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "48",
    "baokao": "134",
    "luqu": "48",
    "pici": "本科二批"
  },
  {
    "specialty_code": "260102",
    "name": "智能制造工程技术",
    "is_wenli": "理科",
    "jihua": "178",
    "baokao": "133",
    "luqu": "142",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0804",
    "name": "材料类",
    "is_wenli": "理科",
    "jihua": "293",
    "baokao": "132",
    "luqu": "308",
    "pici": "本科二批"
  },
  {
    "specialty_code": "310102",
    "name": "物联网工程技术",
    "is_wenli": "理科",
    "jihua": "44",
    "baokao": "130",
    "luqu": "45",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080405",
    "name": "金属材料工程",
    "is_wenli": "理科",
    "jihua": "226",
    "baokao": "128",
    "luqu": "224",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120901",
    "name": "旅游管理",
    "is_wenli": "理科",
    "jihua": "691",
    "baokao": "127",
    "luqu": "628",
    "pici": "本科二批"
  },
  {
    "specialty_code": "090402",
    "name": "动物药学",
    "is_wenli": "理科",
    "jihua": "188",
    "baokao": "126",
    "luqu": "193",
    "pici": "本科二批"
  },
  {
    "specialty_code": "101004",
    "name": "眼视光学",
    "is_wenli": "理科",
    "jihua": "236",
    "baokao": "125",
    "luqu": "254",
    "pici": "本科二批"
  },
  {
    "specialty_code": "090501",
    "name": "林学",
    "is_wenli": "理科",
    "jihua": "202",
    "baokao": "122",
    "luqu": "202",
    "pici": "本科二批"
  },
  {
    "specialty_code": "07100G",
    "name": "生物技术(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "281",
    "baokao": "119",
    "luqu": "281",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080602",
    "name": "智能电网信息工程",
    "is_wenli": "理科",
    "jihua": "223",
    "baokao": "118",
    "luqu": "223",
    "pici": "本科二批"
  },
  {
    "specialty_code": "100702",
    "name": "药物制剂",
    "is_wenli": "理科",
    "jihua": "248",
    "baokao": "113",
    "luqu": "229",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120109",
    "name": "工程审计",
    "is_wenli": "理科",
    "jihua": "19",
    "baokao": "113",
    "luqu": "19",
    "pici": "本科二批"
  },
  {
    "specialty_code": "1202",
    "name": "工商管理类",
    "is_wenli": "理科",
    "jihua": "208",
    "baokao": "113",
    "luqu": "208",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08090J",
    "name": "物联网工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "68",
    "baokao": "112",
    "luqu": "68",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0203",
    "name": "金融学类",
    "is_wenli": "理科",
    "jihua": "38",
    "baokao": "109",
    "luqu": "43",
    "pici": "本科二批"
  },
  {
    "specialty_code": "02030G",
    "name": "金融工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "53",
    "baokao": "108",
    "luqu": "53",
    "pici": "本科二批"
  },
  {
    "specialty_code": "07030F",
    "name": "化学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "108",
    "baokao": "108",
    "luqu": "108",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080711",
    "name": "医学信息工程",
    "is_wenli": "理科",
    "jihua": "143",
    "baokao": "107",
    "luqu": "142",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120701",
    "name": "工业工程",
    "is_wenli": "理科",
    "jihua": "147",
    "baokao": "106",
    "luqu": "153",
    "pici": "本科二批"
  },
  {
    "specialty_code": "090105",
    "name": "种子科学与工程",
    "is_wenli": "理科",
    "jihua": "92",
    "baokao": "105",
    "luqu": "92",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08090H",
    "name": "网络工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "252",
    "baokao": "102",
    "luqu": "261",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080911",
    "name": "网络空间安全",
    "is_wenli": "理科",
    "jihua": "103",
    "baokao": "101",
    "luqu": "106",
    "pici": "本科二批"
  },
  {
    "specialty_code": "310202",
    "name": "网络工程技术",
    "is_wenli": "理科",
    "jihua": "88",
    "baokao": "101",
    "luqu": "93",
    "pici": "本科二批"
  },
  {
    "specialty_code": "020304",
    "name": "投资学",
    "is_wenli": "理科",
    "jihua": "535",
    "baokao": "97",
    "luqu": "529",
    "pici": "本科二批"
  },
  {
    "specialty_code": "100502",
    "name": "针灸推拿学",
    "is_wenli": "理科",
    "jihua": "49",
    "baokao": "94",
    "luqu": "51",
    "pici": "本科二批"
  },
  {
    "specialty_code": "1201",
    "name": "管理科学与工程类",
    "is_wenli": "理科",
    "jihua": "59",
    "baokao": "94",
    "luqu": "55",
    "pici": "本科二批"
  },
  {
    "specialty_code": "030302",
    "name": "社会工作",
    "is_wenli": "理科",
    "jihua": "116",
    "baokao": "91",
    "luqu": "115",
    "pici": "本科二批"
  },
  {
    "specialty_code": "260302",
    "name": "电气工程及自动化",
    "is_wenli": "理科",
    "jihua": "11",
    "baokao": "91",
    "luqu": "11",
    "pici": "本科二批"
  },
  {
    "specialty_code": "330302",
    "name": "大数据与会计",
    "is_wenli": "理科",
    "jihua": "97",
    "baokao": "90",
    "luqu": "117",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082601",
    "name": "生物医学工程",
    "is_wenli": "理科",
    "jihua": "169",
    "baokao": "89",
    "luqu": "168",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081004",
    "name": "建筑电气与智能化",
    "is_wenli": "理科",
    "jihua": "242",
    "baokao": "87",
    "luqu": "243",
    "pici": "本科二批"
  },
  {
    "specialty_code": "030102",
    "name": "知识产权",
    "is_wenli": "理科",
    "jihua": "88",
    "baokao": "86",
    "luqu": "88",
    "pici": "本科二批"
  },
  {
    "specialty_code": "320301",
    "name": "药学",
    "is_wenli": "理科",
    "jihua": "11",
    "baokao": "85",
    "luqu": "13",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08020H",
    "name": "材料成型及控制工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "61",
    "baokao": "83",
    "luqu": "61",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081008",
    "name": "智能建造",
    "is_wenli": "理科",
    "jihua": "140",
    "baokao": "83",
    "luqu": "133",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082708",
    "name": "烹饪与营养教育",
    "is_wenli": "理科",
    "jihua": "144",
    "baokao": "82",
    "luqu": "144",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08070H",
    "name": "通信工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "230",
    "baokao": "79",
    "luqu": "230",
    "pici": "本科二批"
  },
  {
    "specialty_code": "020309",
    "name": "互联网金融",
    "is_wenli": "理科",
    "jihua": "242",
    "baokao": "77",
    "luqu": "252",
    "pici": "本科二批"
  },
  {
    "specialty_code": "310209",
    "name": "人工智能工程技术",
    "is_wenli": "理科",
    "jihua": "23",
    "baokao": "77",
    "luqu": "17",
    "pici": "本科二批"
  },
  {
    "specialty_code": "020105",
    "name": "商务经济学",
    "is_wenli": "理科",
    "jihua": "113",
    "baokao": "76",
    "luqu": "113",
    "pici": "本科二批"
  },
  {
    "specialty_code": "020201",
    "name": "财政学",
    "is_wenli": "理科",
    "jihua": "56",
    "baokao": "75",
    "luqu": "53",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082507",
    "name": "水质科学与技术",
    "is_wenli": "理科",
    "jihua": "55",
    "baokao": "75",
    "luqu": "55",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08020I",
    "name": "机械电子工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "338",
    "baokao": "74",
    "luqu": "338",
    "pici": "本科二批"
  },
  {
    "specialty_code": "07050H",
    "name": "人文地理与城乡规划(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "110",
    "baokao": "73",
    "luqu": "110",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081801",
    "name": "交通运输",
    "is_wenli": "理科",
    "jihua": "414",
    "baokao": "73",
    "luqu": "392",
    "pici": "本科二批"
  },
  {
    "specialty_code": "100703",
    "name": "临床药学",
    "is_wenli": "理科",
    "jihua": "32",
    "baokao": "73",
    "luqu": "32",
    "pici": "本科二批"
  },
  {
    "specialty_code": "370101",
    "name": "学前教育",
    "is_wenli": "理科",
    "jihua": "158",
    "baokao": "72",
    "luqu": "163",
    "pici": "本科二批"
  },
  {
    "specialty_code": "100204",
    "name": "眼视光医学",
    "is_wenli": "理科",
    "jihua": "15",
    "baokao": "71",
    "luqu": "15",
    "pici": "本科二批"
  },
  {
    "specialty_code": "310301",
    "name": "现代通信工程",
    "is_wenli": "理科",
    "jihua": "21",
    "baokao": "69",
    "luqu": "15",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08090K",
    "name": "数字媒体技术(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "90",
    "baokao": "66",
    "luqu": "90",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081007",
    "name": "铁道工程",
    "is_wenli": "理科",
    "jihua": "16",
    "baokao": "66",
    "luqu": "14",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080206",
    "name": "过程装备与控制工程",
    "is_wenli": "理科",
    "jihua": "92",
    "baokao": "65",
    "luqu": "79",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080704",
    "name": "微电子科学与工程",
    "is_wenli": "理科",
    "jihua": "70",
    "baokao": "64",
    "luqu": "59",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082003",
    "name": "飞行器制造工程",
    "is_wenli": "理科",
    "jihua": "196",
    "baokao": "64",
    "luqu": "182",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080606",
    "name": "电缆工程",
    "is_wenli": "理科",
    "jihua": "99",
    "baokao": "63",
    "luqu": "99",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080802",
    "name": "轨道交通信号与控制",
    "is_wenli": "理科",
    "jihua": "161",
    "baokao": "63",
    "luqu": "163",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120410",
    "name": "健康服务与管理",
    "is_wenli": "理科",
    "jihua": "322",
    "baokao": "63",
    "luqu": "317",
    "pici": "本科二批"
  },
  {
    "specialty_code": "070601",
    "name": "大气科学",
    "is_wenli": "理科",
    "jihua": "14",
    "baokao": "60",
    "luqu": "14",
    "pici": "本科二批"
  },
  {
    "specialty_code": "05020I",
    "name": "法语(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "48",
    "baokao": "59",
    "luqu": "48",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080208",
    "name": "汽车服务工程",
    "is_wenli": "理科",
    "jihua": "591",
    "baokao": "59",
    "luqu": "580",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0828",
    "name": "建筑类",
    "is_wenli": "理科",
    "jihua": "100",
    "baokao": "59",
    "luqu": "101",
    "pici": "本科二批"
  },
  {
    "specialty_code": "090403",
    "name": "动植物检疫",
    "is_wenli": "理科",
    "jihua": "89",
    "baokao": "59",
    "luqu": "87",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081103",
    "name": "港口航道与海岸工程",
    "is_wenli": "理科",
    "jihua": "39",
    "baokao": "58",
    "luqu": "36",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08091E",
    "name": "数据科学与大数据技术(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "84",
    "baokao": "57",
    "luqu": "84",
    "pici": "本科二批"
  },
  {
    "specialty_code": "100513",
    "name": "中医骨伤科学",
    "is_wenli": "理科",
    "jihua": "14",
    "baokao": "57",
    "luqu": "14",
    "pici": "本科二批"
  },
  {
    "specialty_code": "320501",
    "name": "医学检验技术",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "57",
    "luqu": "2",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0705",
    "name": "地理科学类",
    "is_wenli": "理科",
    "jihua": "56",
    "baokao": "56",
    "luqu": "56",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081403",
    "name": "资源勘查工程",
    "is_wenli": "理科",
    "jihua": "44",
    "baokao": "55",
    "luqu": "44",
    "pici": "本科二批"
  },
  {
    "specialty_code": "09010F",
    "name": "农学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "60",
    "baokao": "55",
    "luqu": "60",
    "pici": "本科二批"
  },
  {
    "specialty_code": "040102",
    "name": "科学教育",
    "is_wenli": "理科",
    "jihua": "71",
    "baokao": "54",
    "luqu": "71",
    "pici": "本科二批"
  },
  {
    "specialty_code": "090112",
    "name": "智慧农业",
    "is_wenli": "理科",
    "jihua": "20",
    "baokao": "54",
    "luqu": "18",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120208",
    "name": "资产评估",
    "is_wenli": "理科",
    "jihua": "119",
    "baokao": "54",
    "luqu": "116",
    "pici": "本科二批"
  },
  {
    "specialty_code": "070503",
    "name": "人文地理与城乡规划",
    "is_wenli": "理科",
    "jihua": "103",
    "baokao": "52",
    "luqu": "108",
    "pici": "本科二批"
  },
  {
    "specialty_code": "090107",
    "name": "茶学",
    "is_wenli": "理科",
    "jihua": "214",
    "baokao": "52",
    "luqu": "219",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120401",
    "name": "公共事业管理",
    "is_wenli": "理科",
    "jihua": "241",
    "baokao": "52",
    "luqu": "240",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08200G",
    "name": "飞行器设计与工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "64",
    "baokao": "51",
    "luqu": "64",
    "pici": "本科二批"
  },
  {
    "specialty_code": "02010F",
    "name": "经济学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "55",
    "baokao": "50",
    "luqu": "55",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081701",
    "name": "轻化工程",
    "is_wenli": "理科",
    "jihua": "89",
    "baokao": "50",
    "luqu": "96",
    "pici": "本科二批"
  },
  {
    "specialty_code": "12060F",
    "name": "物流管理(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "163",
    "baokao": "50",
    "luqu": "165",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0703",
    "name": "化学类",
    "is_wenli": "理科",
    "jihua": "43",
    "baokao": "49",
    "luqu": "43",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082501",
    "name": "环境科学与工程",
    "is_wenli": "理科",
    "jihua": "128",
    "baokao": "49",
    "luqu": "128",
    "pici": "本科二批"
  },
  {
    "specialty_code": "090201",
    "name": "农业资源与环境",
    "is_wenli": "理科",
    "jihua": "93",
    "baokao": "49",
    "luqu": "93",
    "pici": "本科二批"
  },
  {
    "specialty_code": "040108",
    "name": "特殊教育",
    "is_wenli": "理科",
    "jihua": "56",
    "baokao": "48",
    "luqu": "56",
    "pici": "本科二批"
  },
  {
    "specialty_code": "090601",
    "name": "水产养殖学",
    "is_wenli": "理科",
    "jihua": "223",
    "baokao": "48",
    "luqu": "223",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081501",
    "name": "采矿工程",
    "is_wenli": "理科",
    "jihua": "49",
    "baokao": "47",
    "luqu": "48",
    "pici": "本科二批"
  },
  {
    "specialty_code": "101102",
    "name": "助产学",
    "is_wenli": "理科",
    "jihua": "222",
    "baokao": "47",
    "luqu": "219",
    "pici": "本科二批"
  },
  {
    "specialty_code": "020109",
    "name": "数字经济",
    "is_wenli": "理科",
    "jihua": "89",
    "baokao": "46",
    "luqu": "83",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08250I",
    "name": "环境生态工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "46",
    "luqu": "6",
    "pici": "本科二批"
  },
  {
    "specialty_code": "220801",
    "name": "生态环境工程技术",
    "is_wenli": "理科",
    "jihua": "7",
    "baokao": "46",
    "luqu": "7",
    "pici": "本科二批"
  },
  {
    "specialty_code": "320601",
    "name": "康复治疗",
    "is_wenli": "理科",
    "jihua": "98",
    "baokao": "46",
    "luqu": "89",
    "pici": "本科二批"
  },
  {
    "specialty_code": "090108",
    "name": "烟草",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "45",
    "luqu": "2",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0805",
    "name": "能源动力类",
    "is_wenli": "理科",
    "jihua": "16",
    "baokao": "44",
    "luqu": "16",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080909",
    "name": "电子与计算机工程",
    "is_wenli": "理科",
    "jihua": "12",
    "baokao": "44",
    "luqu": "11",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081305",
    "name": "化学工程与工业生物工程",
    "is_wenli": "理科",
    "jihua": "20",
    "baokao": "44",
    "luqu": "20",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120902",
    "name": "酒店管理",
    "is_wenli": "理科",
    "jihua": "397",
    "baokao": "44",
    "luqu": "342",
    "pici": "本科二批"
  },
  {
    "specialty_code": "240301",
    "name": "建筑工程",
    "is_wenli": "理科",
    "jihua": "205",
    "baokao": "44",
    "luqu": "103",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08250G",
    "name": "环境工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "110",
    "baokao": "43",
    "luqu": "110",
    "pici": "本科二批"
  },
  {
    "specialty_code": "240502",
    "name": "建设工程管理",
    "is_wenli": "理科",
    "jihua": "14",
    "baokao": "43",
    "luqu": "14",
    "pici": "本科二批"
  },
  {
    "specialty_code": "280101",
    "name": "化妆品工程技术",
    "is_wenli": "理科",
    "jihua": "5",
    "baokao": "43",
    "luqu": "5",
    "pici": "本科二批"
  },
  {
    "specialty_code": "300602",
    "name": "城市轨道交通设备与控制技术",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "43",
    "luqu": "2",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082307",
    "name": "农业智能装备工程",
    "is_wenli": "理科",
    "jihua": "14",
    "baokao": "42",
    "luqu": "14",
    "pici": "本科二批"
  },
  {
    "specialty_code": "100205",
    "name": "精神医学",
    "is_wenli": "理科",
    "jihua": "23",
    "baokao": "42",
    "luqu": "24",
    "pici": "本科二批"
  },
  {
    "specialty_code": "100901",
    "name": "法医学",
    "is_wenli": "理科",
    "jihua": "7",
    "baokao": "42",
    "luqu": "7",
    "pici": "本科二批"
  },
  {
    "specialty_code": "100802",
    "name": "中药资源与开发",
    "is_wenli": "理科",
    "jihua": "131",
    "baokao": "41",
    "luqu": "131",
    "pici": "本科二批"
  },
  {
    "specialty_code": "12070H",
    "name": "质量管理工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "57",
    "baokao": "41",
    "luqu": "57",
    "pici": "本科二批"
  },
  {
    "specialty_code": "260301",
    "name": "机械电子工程技术",
    "is_wenli": "理科",
    "jihua": "75",
    "baokao": "41",
    "luqu": "63",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081005",
    "name": "城市地下空间工程",
    "is_wenli": "理科",
    "jihua": "195",
    "baokao": "40",
    "luqu": "195",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08140F",
    "name": "地质工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "97",
    "baokao": "39",
    "luqu": "97",
    "pici": "本科二批"
  },
  {
    "specialty_code": "290201",
    "name": "制药工程技术",
    "is_wenli": "理科",
    "jihua": "13",
    "baokao": "39",
    "luqu": "9",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120205",
    "name": "国际商务",
    "is_wenli": "理科",
    "jihua": "208",
    "baokao": "38",
    "luqu": "189",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08300F",
    "name": "生物工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "86",
    "baokao": "37",
    "luqu": "89",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120402",
    "name": "行政管理",
    "is_wenli": "理科",
    "jihua": "87",
    "baokao": "37",
    "luqu": "79",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080917",
    "name": "区块链工程",
    "is_wenli": "理科",
    "jihua": "103",
    "baokao": "36",
    "luqu": "102",
    "pici": "本科二批"
  },
  {
    "specialty_code": "240501",
    "name": "工程造价",
    "is_wenli": "理科",
    "jihua": "203",
    "baokao": "35",
    "luqu": "102",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081102",
    "name": "水文与水资源工程",
    "is_wenli": "理科",
    "jihua": "39",
    "baokao": "34",
    "luqu": "39",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081601",
    "name": "纺织工程",
    "is_wenli": "理科",
    "jihua": "194",
    "baokao": "34",
    "luqu": "194",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082009",
    "name": "无人驾驶航空器系统工程",
    "is_wenli": "理科",
    "jihua": "36",
    "baokao": "34",
    "luqu": "27",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0825",
    "name": "环境科学与工程类",
    "is_wenli": "理科",
    "jihua": "22",
    "baokao": "34",
    "luqu": "22",
    "pici": "本科二批"
  },
  {
    "specialty_code": "090405",
    "name": "中兽医学",
    "is_wenli": "理科",
    "jihua": "60",
    "baokao": "34",
    "luqu": "60",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120903",
    "name": "会展经济与管理",
    "is_wenli": "理科",
    "jihua": "180",
    "baokao": "34",
    "luqu": "202",
    "pici": "本科二批"
  },
  {
    "specialty_code": "04010K",
    "name": "学前教育(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "19",
    "baokao": "33",
    "luqu": "19",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0502",
    "name": "外国语言文学类",
    "is_wenli": "理科",
    "jihua": "28",
    "baokao": "33",
    "luqu": "27",
    "pici": "本科二批"
  },
  {
    "specialty_code": "07030G",
    "name": "应用化学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "90",
    "baokao": "33",
    "luqu": "90",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082504",
    "name": "环境生态工程",
    "is_wenli": "理科",
    "jihua": "86",
    "baokao": "33",
    "luqu": "88",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082902",
    "name": "应急技术与管理",
    "is_wenli": "理科",
    "jihua": "23",
    "baokao": "33",
    "luqu": "23",
    "pici": "本科二批"
  },
  {
    "specialty_code": "270102",
    "name": "合成生物技术",
    "is_wenli": "理科",
    "jihua": "5",
    "baokao": "33",
    "luqu": "5",
    "pici": "本科二批"
  },
  {
    "specialty_code": "070703",
    "name": "海洋资源与环境",
    "is_wenli": "理科",
    "jihua": "21",
    "baokao": "32",
    "luqu": "21",
    "pici": "本科二批"
  },
  {
    "specialty_code": "071004",
    "name": "生态学",
    "is_wenli": "理科",
    "jihua": "26",
    "baokao": "32",
    "luqu": "26",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120111",
    "name": "应急管理",
    "is_wenli": "理科",
    "jihua": "24",
    "baokao": "32",
    "luqu": "24",
    "pici": "本科二批"
  },
  {
    "specialty_code": "070104",
    "name": "数据计算及应用",
    "is_wenli": "理科",
    "jihua": "19",
    "baokao": "31",
    "luqu": "19",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081803",
    "name": "航海技术",
    "is_wenli": "理科",
    "jihua": "14",
    "baokao": "31",
    "luqu": "15",
    "pici": "本科二批"
  },
  {
    "specialty_code": "090602",
    "name": "海洋渔业科学与技术",
    "is_wenli": "理科",
    "jihua": "15",
    "baokao": "31",
    "luqu": "16",
    "pici": "本科二批"
  },
  {
    "specialty_code": "090701",
    "name": "草业科学",
    "is_wenli": "理科",
    "jihua": "50",
    "baokao": "31",
    "luqu": "50",
    "pici": "本科二批"
  },
  {
    "specialty_code": "100805",
    "name": "中药制药",
    "is_wenli": "理科",
    "jihua": "38",
    "baokao": "31",
    "luqu": "30",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081604",
    "name": "服装设计与工艺教育",
    "is_wenli": "理科",
    "jihua": "20",
    "baokao": "30",
    "luqu": "22",
    "pici": "本科二批"
  },
  {
    "specialty_code": "100101",
    "name": "基础医学",
    "is_wenli": "理科",
    "jihua": "3",
    "baokao": "30",
    "luqu": "3",
    "pici": "本科二批"
  },
  {
    "specialty_code": "1007",
    "name": "药学类",
    "is_wenli": "理科",
    "jihua": "31",
    "baokao": "30",
    "luqu": "31",
    "pici": "本科二批"
  },
  {
    "specialty_code": "030503",
    "name": "思想政治教育",
    "is_wenli": "理科",
    "jihua": "53",
    "baokao": "29",
    "luqu": "56",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120405",
    "name": "城市管理",
    "is_wenli": "理科",
    "jihua": "19",
    "baokao": "29",
    "luqu": "18",
    "pici": "本科二批"
  },
  {
    "specialty_code": "050202",
    "name": "俄语",
    "is_wenli": "理科",
    "jihua": "28",
    "baokao": "28",
    "luqu": "37",
    "pici": "本科二批"
  },
  {
    "specialty_code": "1011",
    "name": "护理学类",
    "is_wenli": "理科",
    "jihua": "15",
    "baokao": "28",
    "luqu": "15",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120703",
    "name": "质量管理工程",
    "is_wenli": "理科",
    "jihua": "42",
    "baokao": "28",
    "luqu": "42",
    "pici": "本科二批"
  },
  {
    "specialty_code": "370201",
    "name": "应用英语",
    "is_wenli": "理科",
    "jihua": "7",
    "baokao": "28",
    "luqu": "7",
    "pici": "本科二批"
  },
  {
    "specialty_code": "071003",
    "name": "生物信息学",
    "is_wenli": "理科",
    "jihua": "63",
    "baokao": "27",
    "luqu": "63",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0808",
    "name": "自动化类",
    "is_wenli": "理科",
    "jihua": "22",
    "baokao": "27",
    "luqu": "22",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082004",
    "name": "飞行器动力工程",
    "is_wenli": "理科",
    "jihua": "14",
    "baokao": "27",
    "luqu": "10",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08280H",
    "name": "风景园林(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "15",
    "baokao": "27",
    "luqu": "15",
    "pici": "本科二批"
  },
  {
    "specialty_code": "101007",
    "name": "卫生检验与检疫",
    "is_wenli": "理科",
    "jihua": "95",
    "baokao": "26",
    "luqu": "96",
    "pici": "本科二批"
  },
  {
    "specialty_code": "050302",
    "name": "广播电视学",
    "is_wenli": "理科",
    "jihua": "66",
    "baokao": "25",
    "luqu": "67",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081702",
    "name": "包装工程",
    "is_wenli": "理科",
    "jihua": "105",
    "baokao": "25",
    "luqu": "109",
    "pici": "本科二批"
  },
  {
    "specialty_code": "090104",
    "name": "植物科学与技术",
    "is_wenli": "理科",
    "jihua": "19",
    "baokao": "25",
    "luqu": "19",
    "pici": "本科二批"
  },
  {
    "specialty_code": "100207",
    "name": "儿科学",
    "is_wenli": "理科",
    "jihua": "21",
    "baokao": "25",
    "luqu": "22",
    "pici": "本科二批"
  },
  {
    "specialty_code": "12020I",
    "name": "财务管理(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "16",
    "baokao": "25",
    "luqu": "16",
    "pici": "本科二批"
  },
  {
    "specialty_code": "260602",
    "name": "飞行器维修工程技术",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "25",
    "luqu": "4",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0503",
    "name": "新闻传播学类",
    "is_wenli": "理科",
    "jihua": "20",
    "baokao": "24",
    "luqu": "21",
    "pici": "本科二批"
  },
  {
    "specialty_code": "070502",
    "name": "自然地理与资源环境",
    "is_wenli": "理科",
    "jihua": "89",
    "baokao": "24",
    "luqu": "89",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0707",
    "name": "海洋科学类",
    "is_wenli": "理科",
    "jihua": "22",
    "baokao": "24",
    "luqu": "22",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080402",
    "name": "材料物理",
    "is_wenli": "理科",
    "jihua": "103",
    "baokao": "24",
    "luqu": "103",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082302",
    "name": "农业机械化及其自动化",
    "is_wenli": "理科",
    "jihua": "33",
    "baokao": "24",
    "luqu": "33",
    "pici": "本科二批"
  },
  {
    "specialty_code": "101002",
    "name": "医学实验技术",
    "is_wenli": "理科",
    "jihua": "23",
    "baokao": "24",
    "luqu": "23",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081402",
    "name": "勘查技术与工程",
    "is_wenli": "理科",
    "jihua": "55",
    "baokao": "23",
    "luqu": "55",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120403",
    "name": "劳动与社会保障",
    "is_wenli": "理科",
    "jihua": "37",
    "baokao": "23",
    "luqu": "32",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120803",
    "name": "跨境电子商务",
    "is_wenli": "理科",
    "jihua": "295",
    "baokao": "23",
    "luqu": "262",
    "pici": "本科二批"
  },
  {
    "specialty_code": "260305",
    "name": "自动化技术与应用",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "23",
    "luqu": "4",
    "pici": "本科二批"
  },
  {
    "specialty_code": "05020F",
    "name": "英语(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "87",
    "baokao": "22",
    "luqu": "107",
    "pici": "本科二批"
  },
  {
    "specialty_code": "070803",
    "name": "防灾减灾科学与工程",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "22",
    "luqu": "4",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082305",
    "name": "农业水利工程",
    "is_wenli": "理科",
    "jihua": "41",
    "baokao": "22",
    "luqu": "41",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0905",
    "name": "林学类",
    "is_wenli": "理科",
    "jihua": "25",
    "baokao": "22",
    "luqu": "25",
    "pici": "本科二批"
  },
  {
    "specialty_code": "101011",
    "name": "智能医学工程",
    "is_wenli": "理科",
    "jihua": "101",
    "baokao": "22",
    "luqu": "97",
    "pici": "本科二批"
  },
  {
    "specialty_code": "1204",
    "name": "公共管理类",
    "is_wenli": "理科",
    "jihua": "40",
    "baokao": "22",
    "luqu": "40",
    "pici": "本科二批"
  },
  {
    "specialty_code": "260203",
    "name": "电梯工程技术",
    "is_wenli": "理科",
    "jihua": "13",
    "baokao": "22",
    "luqu": "13",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080101",
    "name": "理论与应用力学",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "21",
    "luqu": "2",
    "pici": "本科二批"
  },
  {
    "specialty_code": "090106",
    "name": "设施农业科学与工程",
    "is_wenli": "理科",
    "jihua": "115",
    "baokao": "21",
    "luqu": "116",
    "pici": "本科二批"
  },
  {
    "specialty_code": "220402",
    "name": "石油工程技术",
    "is_wenli": "理科",
    "jihua": "11",
    "baokao": "21",
    "luqu": "11",
    "pici": "本科二批"
  },
  {
    "specialty_code": "220901",
    "name": "安全工程技术",
    "is_wenli": "理科",
    "jihua": "5",
    "baokao": "21",
    "luqu": "5",
    "pici": "本科二批"
  },
  {
    "specialty_code": "310204",
    "name": "数字媒体技术",
    "is_wenli": "理科",
    "jihua": "33",
    "baokao": "21",
    "luqu": "44",
    "pici": "本科二批"
  },
  {
    "specialty_code": "310205",
    "name": "大数据工程技术",
    "is_wenli": "理科",
    "jihua": "51",
    "baokao": "21",
    "luqu": "62",
    "pici": "本科二批"
  },
  {
    "specialty_code": "330301",
    "name": "大数据与财务管理",
    "is_wenli": "理科",
    "jihua": "120",
    "baokao": "21",
    "luqu": "86",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080214",
    "name": "智能车辆工程",
    "is_wenli": "理科",
    "jihua": "23",
    "baokao": "20",
    "luqu": "24",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08100H",
    "name": "给排水科学与工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "100",
    "baokao": "20",
    "luqu": "100",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081401",
    "name": "地质工程",
    "is_wenli": "理科",
    "jihua": "40",
    "baokao": "20",
    "luqu": "40",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081502",
    "name": "石油工程",
    "is_wenli": "理科",
    "jihua": "18",
    "baokao": "20",
    "luqu": "24",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081804",
    "name": "轮机工程",
    "is_wenli": "理科",
    "jihua": "14",
    "baokao": "20",
    "luqu": "17",
    "pici": "本科二批"
  },
  {
    "specialty_code": "12020K",
    "name": "人力资源管理(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "62",
    "baokao": "20",
    "luqu": "62",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120407",
    "name": "交通管理",
    "is_wenli": "理科",
    "jihua": "72",
    "baokao": "20",
    "luqu": "72",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08020F",
    "name": "机械工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "31",
    "baokao": "19",
    "luqu": "31",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080408",
    "name": "复合材料与工程",
    "is_wenli": "理科",
    "jihua": "35",
    "baokao": "19",
    "luqu": "32",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0818",
    "name": "交通运输类",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "19",
    "luqu": "6",
    "pici": "本科二批"
  },
  {
    "specialty_code": "100510",
    "name": "中医康复学",
    "is_wenli": "理科",
    "jihua": "9",
    "baokao": "19",
    "luqu": "9",
    "pici": "本科二批"
  },
  {
    "specialty_code": "020303",
    "name": "保险学",
    "is_wenli": "理科",
    "jihua": "134",
    "baokao": "18",
    "luqu": "131",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080916",
    "name": "虚拟现实技术",
    "is_wenli": "理科",
    "jihua": "39",
    "baokao": "18",
    "luqu": "39",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081808",
    "name": "船舶电子电气工程",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "18",
    "luqu": "4",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082604",
    "name": "康复工程",
    "is_wenli": "理科",
    "jihua": "73",
    "baokao": "18",
    "luqu": "74",
    "pici": "本科二批"
  },
  {
    "specialty_code": "220401",
    "name": "油气储运工程",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "18",
    "luqu": "2",
    "pici": "本科二批"
  },
  {
    "specialty_code": "030104",
    "name": "信用风险管理与法律防控",
    "is_wenli": "理科",
    "jihua": "70",
    "baokao": "17",
    "luqu": "70",
    "pici": "本科二批"
  },
  {
    "specialty_code": "070801",
    "name": "地球物理学",
    "is_wenli": "理科",
    "jihua": "14",
    "baokao": "17",
    "luqu": "14",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080412",
    "name": "功能材料",
    "is_wenli": "理科",
    "jihua": "71",
    "baokao": "17",
    "luqu": "70",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081705",
    "name": "化妆品技术与工程",
    "is_wenli": "理科",
    "jihua": "21",
    "baokao": "17",
    "luqu": "19",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082106",
    "name": "装甲车辆工程",
    "is_wenli": "理科",
    "jihua": "3",
    "baokao": "17",
    "luqu": "3",
    "pici": "本科二批"
  },
  {
    "specialty_code": "290102",
    "name": "食品质量与安全",
    "is_wenli": "理科",
    "jihua": "7",
    "baokao": "17",
    "luqu": "7",
    "pici": "本科二批"
  },
  {
    "specialty_code": "020307",
    "name": "经济与金融",
    "is_wenli": "理科",
    "jihua": "25",
    "baokao": "16",
    "luqu": "22",
    "pici": "本科二批"
  },
  {
    "specialty_code": "050304",
    "name": "传播学",
    "is_wenli": "理科",
    "jihua": "11",
    "baokao": "16",
    "luqu": "11",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080808",
    "name": "智能工程与创意设计",
    "is_wenli": "理科",
    "jihua": "112",
    "baokao": "16",
    "luqu": "112",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081901",
    "name": "船舶与海洋工程",
    "is_wenli": "理科",
    "jihua": "10",
    "baokao": "16",
    "luqu": "10",
    "pici": "本科二批"
  },
  {
    "specialty_code": "12020F",
    "name": "工商管理(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "86",
    "baokao": "16",
    "luqu": "86",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080102",
    "name": "工程力学",
    "is_wenli": "理科",
    "jihua": "13",
    "baokao": "15",
    "luqu": "13",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082002",
    "name": "飞行器设计与工程",
    "is_wenli": "理科",
    "jihua": "26",
    "baokao": "15",
    "luqu": "14",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082104",
    "name": "弹药工程与爆炸技术",
    "is_wenli": "理科",
    "jihua": "1",
    "baokao": "15",
    "luqu": "1",
    "pici": "本科二批"
  },
  {
    "specialty_code": "260701",
    "name": "汽车工程技术",
    "is_wenli": "理科",
    "jihua": "159",
    "baokao": "15",
    "luqu": "38",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080413",
    "name": "纳米材料与技术",
    "is_wenli": "理科",
    "jihua": "108",
    "baokao": "14",
    "luqu": "122",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080707",
    "name": "广播电视工程",
    "is_wenli": "理科",
    "jihua": "12",
    "baokao": "14",
    "luqu": "12",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082107",
    "name": "信息对抗技术",
    "is_wenli": "理科",
    "jihua": "1",
    "baokao": "14",
    "luqu": "1",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0901",
    "name": "植物生产类",
    "is_wenli": "理科",
    "jihua": "23",
    "baokao": "14",
    "luqu": "23",
    "pici": "本科二批"
  },
  {
    "specialty_code": "1008",
    "name": "中药学类",
    "is_wenli": "理科",
    "jihua": "20",
    "baokao": "14",
    "luqu": "20",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120503",
    "name": "信息资源管理",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "14",
    "luqu": "2",
    "pici": "本科二批"
  },
  {
    "specialty_code": "330601",
    "name": "企业数字化管理",
    "is_wenli": "理科",
    "jihua": "13",
    "baokao": "14",
    "luqu": "8",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080710",
    "name": "集成电路设计与集成系统",
    "is_wenli": "理科",
    "jihua": "27",
    "baokao": "13",
    "luqu": "27",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082102",
    "name": "武器发射工程",
    "is_wenli": "理科",
    "jihua": "1",
    "baokao": "13",
    "luqu": "1",
    "pici": "本科二批"
  },
  {
    "specialty_code": "090109",
    "name": "应用生物科学",
    "is_wenli": "理科",
    "jihua": "12",
    "baokao": "13",
    "luqu": "12",
    "pici": "本科二批"
  },
  {
    "specialty_code": "12070F",
    "name": "工业工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "18",
    "baokao": "13",
    "luqu": "18",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120802",
    "name": "电子商务及法律",
    "is_wenli": "理科",
    "jihua": "33",
    "baokao": "13",
    "luqu": "33",
    "pici": "本科二批"
  },
  {
    "specialty_code": "330801",
    "name": "物流工程技术",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "13",
    "luqu": "4",
    "pici": "本科二批"
  },
  {
    "specialty_code": "02030J",
    "name": "金融数学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "12",
    "luqu": "6",
    "pici": "本科二批"
  },
  {
    "specialty_code": "030103",
    "name": "监狱学",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "12",
    "luqu": "4",
    "pici": "本科二批"
  },
  {
    "specialty_code": "070701",
    "name": "海洋科学",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "12",
    "luqu": "6",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081503",
    "name": "矿物加工工程",
    "is_wenli": "理科",
    "jihua": "18",
    "baokao": "12",
    "luqu": "18",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082403",
    "name": "林产化工",
    "is_wenli": "理科",
    "jihua": "7",
    "baokao": "12",
    "luqu": "7",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082506",
    "name": "资源环境科学",
    "is_wenli": "理科",
    "jihua": "40",
    "baokao": "12",
    "luqu": "40",
    "pici": "本科二批"
  },
  {
    "specialty_code": "090604",
    "name": "水生动物医学",
    "is_wenli": "理科",
    "jihua": "7",
    "baokao": "12",
    "luqu": "7",
    "pici": "本科二批"
  },
  {
    "specialty_code": "100511",
    "name": "中医养生学",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "12",
    "luqu": "6",
    "pici": "本科二批"
  },
  {
    "specialty_code": "290202",
    "name": "药品质量管理",
    "is_wenli": "理科",
    "jihua": "5",
    "baokao": "12",
    "luqu": "5",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0202",
    "name": "财政学类",
    "is_wenli": "理科",
    "jihua": "5",
    "baokao": "11",
    "luqu": "5",
    "pici": "本科二批"
  },
  {
    "specialty_code": "040205",
    "name": "运动人体科学",
    "is_wenli": "理科",
    "jihua": "20",
    "baokao": "11",
    "luqu": "20",
    "pici": "本科二批"
  },
  {
    "specialty_code": "050204",
    "name": "法语",
    "is_wenli": "理科",
    "jihua": "29",
    "baokao": "11",
    "luqu": "41",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080502",
    "name": "能源与环境系统工程",
    "is_wenli": "理科",
    "jihua": "8",
    "baokao": "11",
    "luqu": "8",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082008",
    "name": "飞行器控制与信息工程",
    "is_wenli": "理科",
    "jihua": "57",
    "baokao": "11",
    "luqu": "61",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082108",
    "name": "智能无人系统技术",
    "is_wenli": "理科",
    "jihua": "1",
    "baokao": "11",
    "luqu": "1",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08300G",
    "name": "生物制药(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "7",
    "baokao": "11",
    "luqu": "7",
    "pici": "本科二批"
  },
  {
    "specialty_code": "12020J",
    "name": "国际商务(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "10",
    "baokao": "11",
    "luqu": "10",
    "pici": "本科二批"
  },
  {
    "specialty_code": "12030F",
    "name": "农林经济管理(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "42",
    "baokao": "11",
    "luqu": "42",
    "pici": "本科二批"
  },
  {
    "specialty_code": "130508",
    "name": "数字媒体艺术",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "11",
    "luqu": "4",
    "pici": "本科二批"
  },
  {
    "specialty_code": "270201",
    "name": "应用化工技术",
    "is_wenli": "理科",
    "jihua": "20",
    "baokao": "11",
    "luqu": "16",
    "pici": "本科二批"
  },
  {
    "specialty_code": "290101",
    "name": "食品工程技术",
    "is_wenli": "理科",
    "jihua": "5",
    "baokao": "11",
    "luqu": "5",
    "pici": "本科二批"
  },
  {
    "specialty_code": "300203",
    "name": "汽车服务工程技术",
    "is_wenli": "理科",
    "jihua": "98",
    "baokao": "11",
    "luqu": "20",
    "pici": "本科二批"
  },
  {
    "specialty_code": "020106",
    "name": "能源经济",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "10",
    "luqu": "4",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08020L",
    "name": "车辆工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "15",
    "baokao": "10",
    "luqu": "15",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0811",
    "name": "水利类",
    "is_wenli": "理科",
    "jihua": "8",
    "baokao": "10",
    "luqu": "8",
    "pici": "本科二批"
  },
  {
    "specialty_code": "1208",
    "name": "电子商务类",
    "is_wenli": "理科",
    "jihua": "83",
    "baokao": "10",
    "luqu": "83",
    "pici": "本科二批"
  },
  {
    "specialty_code": "230601",
    "name": "高分子材料工程技术",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "10",
    "luqu": "2",
    "pici": "本科二批"
  },
  {
    "specialty_code": "320604",
    "name": "儿童康复治疗",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "10",
    "luqu": "2",
    "pici": "本科二批"
  },
  {
    "specialty_code": "330802",
    "name": "现代物流管理",
    "is_wenli": "理科",
    "jihua": "75",
    "baokao": "10",
    "luqu": "30",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0303",
    "name": "社会学类",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "9",
    "luqu": "6",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082006",
    "name": "飞行器质量与可靠性",
    "is_wenli": "理科",
    "jihua": "20",
    "baokao": "9",
    "luqu": "30",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082201",
    "name": "核工程与核技术",
    "is_wenli": "理科",
    "jihua": "8",
    "baokao": "9",
    "luqu": "8",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082804",
    "name": "历史建筑保护工程",
    "is_wenli": "理科",
    "jihua": "22",
    "baokao": "9",
    "luqu": "22",
    "pici": "本科二批"
  },
  {
    "specialty_code": "10070F",
    "name": "药学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "9",
    "luqu": "2",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120211",
    "name": "劳动关系",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "9",
    "luqu": "2",
    "pici": "本科二批"
  },
  {
    "specialty_code": "12060G",
    "name": "物流工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "8",
    "baokao": "9",
    "luqu": "8",
    "pici": "本科二批"
  },
  {
    "specialty_code": "12090F",
    "name": "旅游管理(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "16",
    "baokao": "9",
    "luqu": "16",
    "pici": "本科二批"
  },
  {
    "specialty_code": "130305",
    "name": "广播电视编导",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "9",
    "luqu": "6",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0301",
    "name": "法学类",
    "is_wenli": "理科",
    "jihua": "5",
    "baokao": "8",
    "luqu": "5",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0712",
    "name": "统计学类",
    "is_wenli": "理科",
    "jihua": "3",
    "baokao": "8",
    "luqu": "4",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0814",
    "name": "地质类",
    "is_wenli": "理科",
    "jihua": "13",
    "baokao": "8",
    "luqu": "12",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082105",
    "name": "特种能源技术与工程",
    "is_wenli": "理科",
    "jihua": "1",
    "baokao": "8",
    "luqu": "1",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082707",
    "name": "食品营养与检验教育",
    "is_wenli": "理科",
    "jihua": "22",
    "baokao": "8",
    "luqu": "22",
    "pici": "本科二批"
  },
  {
    "specialty_code": "083102",
    "name": "消防工程",
    "is_wenli": "理科",
    "jihua": "10",
    "baokao": "8",
    "luqu": "10",
    "pici": "本科二批"
  },
  {
    "specialty_code": "100705",
    "name": "药物分析",
    "is_wenli": "理科",
    "jihua": "11",
    "baokao": "8",
    "luqu": "11",
    "pici": "本科二批"
  },
  {
    "specialty_code": "010101",
    "name": "哲学",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "7",
    "luqu": "2",
    "pici": "本科二批"
  },
  {
    "specialty_code": "030201",
    "name": "政治学与行政学",
    "is_wenli": "理科",
    "jihua": "5",
    "baokao": "7",
    "luqu": "5",
    "pici": "本科二批"
  },
  {
    "specialty_code": "060105",
    "name": "文物保护技术",
    "is_wenli": "理科",
    "jihua": "8",
    "baokao": "7",
    "luqu": "8",
    "pici": "本科二批"
  },
  {
    "specialty_code": "070702",
    "name": "海洋技术",
    "is_wenli": "理科",
    "jihua": "8",
    "baokao": "7",
    "luqu": "8",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080505",
    "name": "能源服务工程",
    "is_wenli": "理科",
    "jihua": "20",
    "baokao": "7",
    "luqu": "20",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0816",
    "name": "纺织类",
    "is_wenli": "理科",
    "jihua": "17",
    "baokao": "7",
    "luqu": "17",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081811",
    "name": "智慧交通",
    "is_wenli": "理科",
    "jihua": "16",
    "baokao": "7",
    "luqu": "17",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082806",
    "name": "城市设计",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "7",
    "luqu": "2",
    "pici": "本科二批"
  },
  {
    "specialty_code": "100706",
    "name": "药物化学",
    "is_wenli": "理科",
    "jihua": "10",
    "baokao": "7",
    "luqu": "10",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120101",
    "name": "管理科学",
    "is_wenli": "理科",
    "jihua": "41",
    "baokao": "7",
    "luqu": "41",
    "pici": "本科二批"
  },
  {
    "specialty_code": "12010H",
    "name": "工程管理(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "12",
    "baokao": "7",
    "luqu": "12",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120209",
    "name": "物业管理",
    "is_wenli": "理科",
    "jihua": "45",
    "baokao": "7",
    "luqu": "45",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120604",
    "name": "供应链管理",
    "is_wenli": "理科",
    "jihua": "112",
    "baokao": "7",
    "luqu": "109",
    "pici": "本科二批"
  },
  {
    "specialty_code": "1209",
    "name": "旅游管理类",
    "is_wenli": "理科",
    "jihua": "34",
    "baokao": "7",
    "luqu": "32",
    "pici": "本科二批"
  },
  {
    "specialty_code": "130311",
    "name": "影视摄影与制作",
    "is_wenli": "理科",
    "jihua": "3",
    "baokao": "7",
    "luqu": "3",
    "pici": "本科二批"
  },
  {
    "specialty_code": "230401",
    "name": "钢铁智能冶金技术",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "7",
    "luqu": "4",
    "pici": "本科二批"
  },
  {
    "specialty_code": "030301",
    "name": "社会学",
    "is_wenli": "理科",
    "jihua": "31",
    "baokao": "6",
    "luqu": "31",
    "pici": "本科二批"
  },
  {
    "specialty_code": "050203",
    "name": "德语",
    "is_wenli": "理科",
    "jihua": "37",
    "baokao": "6",
    "luqu": "35",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080303",
    "name": "智能感知工程",
    "is_wenli": "理科",
    "jihua": "27",
    "baokao": "6",
    "luqu": "24",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080410",
    "name": "宝石及材料工艺学",
    "is_wenli": "理科",
    "jihua": "85",
    "baokao": "6",
    "luqu": "74",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080806",
    "name": "智能装备与系统",
    "is_wenli": "理科",
    "jihua": "9",
    "baokao": "6",
    "luqu": "9",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080908",
    "name": "空间信息与数字技术",
    "is_wenli": "理科",
    "jihua": "7",
    "baokao": "6",
    "luqu": "7",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08130F",
    "name": "化学工程与工艺(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "20",
    "baokao": "6",
    "luqu": "20",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081507",
    "name": "智能采矿工程",
    "is_wenli": "理科",
    "jihua": "38",
    "baokao": "6",
    "luqu": "38",
    "pici": "本科二批"
  },
  {
    "specialty_code": "090202",
    "name": "野生动物与自然保护区管理",
    "is_wenli": "理科",
    "jihua": "5",
    "baokao": "6",
    "luqu": "5",
    "pici": "本科二批"
  },
  {
    "specialty_code": "09020F",
    "name": "农业资源与环境(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "9",
    "baokao": "6",
    "luqu": "9",
    "pici": "本科二批"
  },
  {
    "specialty_code": "100512",
    "name": "中医儿科学",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "6",
    "luqu": "4",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120210",
    "name": "文化产业管理",
    "is_wenli": "理科",
    "jihua": "42",
    "baokao": "6",
    "luqu": "35",
    "pici": "本科二批"
  },
  {
    "specialty_code": "130304",
    "name": "戏剧影视文学",
    "is_wenli": "理科",
    "jihua": "13",
    "baokao": "6",
    "luqu": "13",
    "pici": "本科二批"
  },
  {
    "specialty_code": "260304",
    "name": "机器人技术",
    "is_wenli": "理科",
    "jihua": "17",
    "baokao": "6",
    "luqu": "8",
    "pici": "本科二批"
  },
  {
    "specialty_code": "030305",
    "name": "家政学",
    "is_wenli": "理科",
    "jihua": "47",
    "baokao": "5",
    "luqu": "45",
    "pici": "本科二批"
  },
  {
    "specialty_code": "050205",
    "name": "西班牙语",
    "is_wenli": "理科",
    "jihua": "45",
    "baokao": "5",
    "luqu": "46",
    "pici": "本科二批"
  },
  {
    "specialty_code": "070901",
    "name": "地质学",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "5",
    "luqu": "4",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080219",
    "name": "应急装备技术与工程",
    "is_wenli": "理科",
    "jihua": "10",
    "baokao": "5",
    "luqu": "10",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08030F",
    "name": "测控技术与仪器(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "10",
    "baokao": "5",
    "luqu": "10",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08180F",
    "name": "交通运输(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "46",
    "baokao": "5",
    "luqu": "46",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0823",
    "name": "农业工程类",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "5",
    "luqu": "2",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082705",
    "name": "酿酒工程",
    "is_wenli": "理科",
    "jihua": "50",
    "baokao": "5",
    "luqu": "50",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082709",
    "name": "食品安全与检测",
    "is_wenli": "理科",
    "jihua": "10",
    "baokao": "5",
    "luqu": "10",
    "pici": "本科二批"
  },
  {
    "specialty_code": "083101",
    "name": "刑事科学技术",
    "is_wenli": "理科",
    "jihua": "1",
    "baokao": "5",
    "luqu": "1",
    "pici": "本科二批"
  },
  {
    "specialty_code": "090305",
    "name": "马业科学",
    "is_wenli": "理科",
    "jihua": "7",
    "baokao": "5",
    "luqu": "7",
    "pici": "本科二批"
  },
  {
    "specialty_code": "090603",
    "name": "水族科学与技术",
    "is_wenli": "理科",
    "jihua": "49",
    "baokao": "5",
    "luqu": "49",
    "pici": "本科二批"
  },
  {
    "specialty_code": "12020G",
    "name": "市场营销(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "22",
    "baokao": "5",
    "luqu": "22",
    "pici": "本科二批"
  },
  {
    "specialty_code": "1206",
    "name": "物流管理与工程类",
    "is_wenli": "理科",
    "jihua": "8",
    "baokao": "5",
    "luqu": "8",
    "pici": "本科二批"
  },
  {
    "specialty_code": "310207",
    "name": "信息安全与管理",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "5",
    "luqu": "3",
    "pici": "本科二批"
  },
  {
    "specialty_code": "050228",
    "name": "波兰语",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "4",
    "luqu": "4",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080217",
    "name": "增材制造工程",
    "is_wenli": "理科",
    "jihua": "85",
    "baokao": "4",
    "luqu": "83",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08080G",
    "name": "轨道交通信号与控制(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "4",
    "luqu": "6",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080912",
    "name": "新媒体技术",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "4",
    "luqu": "2",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081506",
    "name": "海洋油气工程",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "4",
    "luqu": "2",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081806",
    "name": "交通设备与控制工程",
    "is_wenli": "理科",
    "jihua": "3",
    "baokao": "4",
    "luqu": "3",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081809",
    "name": "轨道交通电气与控制",
    "is_wenli": "理科",
    "jihua": "7",
    "baokao": "4",
    "luqu": "7",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081902",
    "name": "海洋工程与技术",
    "is_wenli": "理科",
    "jihua": "1",
    "baokao": "4",
    "luqu": "1",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082007",
    "name": "飞行器适航技术",
    "is_wenli": "理科",
    "jihua": "12",
    "baokao": "4",
    "luqu": "12",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08270J",
    "name": "酿酒工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "80",
    "baokao": "4",
    "luqu": "80",
    "pici": "本科二批"
  },
  {
    "specialty_code": "090206",
    "name": "湿地保护与恢复",
    "is_wenli": "理科",
    "jihua": "1",
    "baokao": "4",
    "luqu": "1",
    "pici": "本科二批"
  },
  {
    "specialty_code": "1004",
    "name": "公共卫生与预防医学类",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "4",
    "luqu": "2",
    "pici": "本科二批"
  },
  {
    "specialty_code": "1010",
    "name": "医学技术类",
    "is_wenli": "理科",
    "jihua": "10",
    "baokao": "4",
    "luqu": "15",
    "pici": "本科二批"
  },
  {
    "specialty_code": "101009",
    "name": "康复物理治疗",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "4",
    "luqu": "6",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120104",
    "name": "房地产开发与管理",
    "is_wenli": "理科",
    "jihua": "77",
    "baokao": "4",
    "luqu": "77",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120212",
    "name": "体育经济与管理",
    "is_wenli": "理科",
    "jihua": "9",
    "baokao": "4",
    "luqu": "9",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120412",
    "name": "医疗产品管理",
    "is_wenli": "理科",
    "jihua": "45",
    "baokao": "4",
    "luqu": "45",
    "pici": "本科二批"
  },
  {
    "specialty_code": "320504",
    "name": "口腔医学技术",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "4",
    "luqu": "17",
    "pici": "本科二批"
  },
  {
    "specialty_code": "020104",
    "name": "资源与环境经济学",
    "is_wenli": "理科",
    "jihua": "7",
    "baokao": "3",
    "luqu": "7",
    "pici": "本科二批"
  },
  {
    "specialty_code": "040105",
    "name": "艺术教育",
    "is_wenli": "理科",
    "jihua": "7",
    "baokao": "3",
    "luqu": "4",
    "pici": "本科二批"
  },
  {
    "specialty_code": "040110",
    "name": "教育康复学",
    "is_wenli": "理科",
    "jihua": "7",
    "baokao": "3",
    "luqu": "7",
    "pici": "本科二批"
  },
  {
    "specialty_code": "04020K",
    "name": "运动康复(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "3",
    "luqu": "4",
    "pici": "本科二批"
  },
  {
    "specialty_code": "05021A",
    "name": "语言学",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "3",
    "luqu": "2",
    "pici": "本科二批"
  },
  {
    "specialty_code": "050307",
    "name": "数字出版",
    "is_wenli": "理科",
    "jihua": "13",
    "baokao": "3",
    "luqu": "13",
    "pici": "本科二批"
  },
  {
    "specialty_code": "060104",
    "name": "文物与博物馆学",
    "is_wenli": "理科",
    "jihua": "7",
    "baokao": "3",
    "luqu": "5",
    "pici": "本科二批"
  },
  {
    "specialty_code": "070904",
    "name": "古生物学",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "3",
    "luqu": "4",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080211",
    "name": "机电技术教育",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "3",
    "luqu": "2",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08070G",
    "name": "电子科学与技术(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "3",
    "baokao": "3",
    "luqu": "3",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08280F",
    "name": "建筑学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "10",
    "baokao": "3",
    "luqu": "10",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08280G",
    "name": "城乡规划(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "55",
    "baokao": "3",
    "luqu": "55",
    "pici": "本科二批"
  },
  {
    "specialty_code": "090113",
    "name": "菌物科学与工程",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "3",
    "luqu": "6",
    "pici": "本科二批"
  },
  {
    "specialty_code": "090203",
    "name": "水土保持与荒漠化防治",
    "is_wenli": "理科",
    "jihua": "23",
    "baokao": "3",
    "luqu": "23",
    "pici": "本科二批"
  },
  {
    "specialty_code": "10100I",
    "name": "眼视光学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "1",
    "baokao": "3",
    "luqu": "1",
    "pici": "本科二批"
  },
  {
    "specialty_code": "260604",
    "name": "无人机系统应用技术",
    "is_wenli": "理科",
    "jihua": "9",
    "baokao": "3",
    "luqu": "9",
    "pici": "本科二批"
  },
  {
    "specialty_code": "290204",
    "name": "药事服务与管理",
    "is_wenli": "理科",
    "jihua": "5",
    "baokao": "3",
    "luqu": "5",
    "pici": "本科二批"
  },
  {
    "specialty_code": "300601",
    "name": "城市轨道交通信号与控制技术",
    "is_wenli": "理科",
    "jihua": "15",
    "baokao": "3",
    "luqu": "6",
    "pici": "本科二批"
  },
  {
    "specialty_code": "330701",
    "name": "电子商务",
    "is_wenli": "理科",
    "jihua": "8",
    "baokao": "3",
    "luqu": "7",
    "pici": "本科二批"
  },
  {
    "specialty_code": "020308",
    "name": "精算学",
    "is_wenli": "理科",
    "jihua": "18",
    "baokao": "2",
    "luqu": "18",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0204",
    "name": "经济与贸易类",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "2",
    "luqu": "2",
    "pici": "本科二批"
  },
  {
    "specialty_code": "040114",
    "name": "劳动教育",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "2",
    "luqu": "2",
    "pici": "本科二批"
  },
  {
    "specialty_code": "050104",
    "name": "中国少数民族语言文学",
    "is_wenli": "理科",
    "jihua": "5",
    "baokao": "2",
    "luqu": "5",
    "pici": "本科二批"
  },
  {
    "specialty_code": "05020G",
    "name": "俄语(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "2",
    "luqu": "2",
    "pici": "本科二批"
  },
  {
    "specialty_code": "05020J",
    "name": "西班牙语(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "2",
    "luqu": "2",
    "pici": "本科二批"
  },
  {
    "specialty_code": "050232",
    "name": "葡萄牙语",
    "is_wenli": "理科",
    "jihua": "8",
    "baokao": "2",
    "luqu": "6",
    "pici": "本科二批"
  },
  {
    "specialty_code": "05030K",
    "name": "网络与新媒体(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "2",
    "luqu": "2",
    "pici": "本科二批"
  },
  {
    "specialty_code": "070103",
    "name": "数理基础科学",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "2",
    "luqu": "2",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0702",
    "name": "物理学类",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "2",
    "luqu": "4",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080404",
    "name": "冶金工程",
    "is_wenli": "理科",
    "jihua": "30",
    "baokao": "2",
    "luqu": "30",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080416",
    "name": "复合材料成型工程",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "2",
    "luqu": "2",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080709",
    "name": "电子封装技术",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "2",
    "luqu": "2",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081308",
    "name": "精细化工",
    "is_wenli": "理科",
    "jihua": "3",
    "baokao": "2",
    "luqu": "3",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08190F",
    "name": "船舶与海洋工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "3",
    "baokao": "2",
    "luqu": "3",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082301",
    "name": "农业工程",
    "is_wenli": "理科",
    "jihua": "22",
    "baokao": "2",
    "luqu": "22",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082303",
    "name": "农业电气化",
    "is_wenli": "理科",
    "jihua": "11",
    "baokao": "2",
    "luqu": "11",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082505",
    "name": "环保设备工程",
    "is_wenli": "理科",
    "jihua": "20",
    "baokao": "2",
    "luqu": "20",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082602",
    "name": "假肢矫形工程",
    "is_wenli": "理科",
    "jihua": "142",
    "baokao": "2",
    "luqu": "142",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082703",
    "name": "粮食工程",
    "is_wenli": "理科",
    "jihua": "14",
    "baokao": "2",
    "luqu": "14",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08270G",
    "name": "食品质量与安全(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "5",
    "baokao": "2",
    "luqu": "5",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082711",
    "name": "食用菌科学与工程",
    "is_wenli": "理科",
    "jihua": "1",
    "baokao": "2",
    "luqu": "1",
    "pici": "本科二批"
  },
  {
    "specialty_code": "100707",
    "name": "海洋药学",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "2",
    "luqu": "2",
    "pici": "本科二批"
  },
  {
    "specialty_code": "100708",
    "name": "化妆品科学与技术",
    "is_wenli": "理科",
    "jihua": "1",
    "baokao": "2",
    "luqu": "1",
    "pici": "本科二批"
  },
  {
    "specialty_code": "101008",
    "name": "听力与言语康复学",
    "is_wenli": "理科",
    "jihua": "5",
    "baokao": "2",
    "luqu": "3",
    "pici": "本科二批"
  },
  {
    "specialty_code": "12010J",
    "name": "工程造价(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "2",
    "luqu": "2",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120213",
    "name": "财务会计教育",
    "is_wenli": "理科",
    "jihua": "49",
    "baokao": "2",
    "luqu": "49",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120302",
    "name": "农村区域发展",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "2",
    "luqu": "4",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120413",
    "name": "医疗保险",
    "is_wenli": "理科",
    "jihua": "7",
    "baokao": "2",
    "luqu": "7",
    "pici": "本科二批"
  },
  {
    "specialty_code": "120414",
    "name": "养老服务管理",
    "is_wenli": "理科",
    "jihua": "45",
    "baokao": "2",
    "luqu": "24",
    "pici": "本科二批"
  },
  {
    "specialty_code": "12090G",
    "name": "酒店管理(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "20",
    "baokao": "2",
    "luqu": "20",
    "pici": "本科二批"
  },
  {
    "specialty_code": "230503",
    "name": "储能材料工程技术",
    "is_wenli": "理科",
    "jihua": "5",
    "baokao": "2",
    "luqu": "5",
    "pici": "本科二批"
  },
  {
    "specialty_code": "240401",
    "name": "建筑环境与能源工程",
    "is_wenli": "理科",
    "jihua": "5",
    "baokao": "2",
    "luqu": "5",
    "pici": "本科二批"
  },
  {
    "specialty_code": "260106",
    "name": "材料成型及控制工程",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "2",
    "luqu": "6",
    "pici": "本科二批"
  },
  {
    "specialty_code": "300603",
    "name": "城市轨道交通智能运营",
    "is_wenli": "理科",
    "jihua": "14",
    "baokao": "2",
    "luqu": "4",
    "pici": "本科二批"
  },
  {
    "specialty_code": "330202",
    "name": "金融科技应用",
    "is_wenli": "理科",
    "jihua": "5",
    "baokao": "2",
    "luqu": "5",
    "pici": "本科二批"
  },
  {
    "specialty_code": "360206",
    "name": "数字动画",
    "is_wenli": "理科",
    "jihua": "1",
    "baokao": "2",
    "luqu": "2",
    "pici": "本科二批"
  },
  {
    "specialty_code": "020306",
    "name": "信用管理",
    "is_wenli": "理科",
    "jihua": "43",
    "baokao": "1",
    "luqu": "43",
    "pici": "本科二批"
  },
  {
    "specialty_code": "020402",
    "name": "贸易经济",
    "is_wenli": "理科",
    "jihua": "15",
    "baokao": "1",
    "luqu": "15",
    "pici": "本科二批"
  },
  {
    "specialty_code": "030107",
    "name": "社区矫正",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "1",
    "luqu": "5",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0501",
    "name": "中国语言文学类",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "1",
    "luqu": "1",
    "pici": "本科二批"
  },
  {
    "specialty_code": "050206",
    "name": "阿拉伯语",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "1",
    "luqu": "4",
    "pici": "本科二批"
  },
  {
    "specialty_code": "050209",
    "name": "朝鲜语",
    "is_wenli": "理科",
    "jihua": "16",
    "baokao": "1",
    "luqu": "20",
    "pici": "本科二批"
  },
  {
    "specialty_code": "050223",
    "name": "越南语",
    "is_wenli": "理科",
    "jihua": "1",
    "baokao": "1",
    "luqu": "1",
    "pici": "本科二批"
  },
  {
    "specialty_code": "050237",
    "name": "匈牙利语",
    "is_wenli": "理科",
    "jihua": "1",
    "baokao": "1",
    "luqu": "1",
    "pici": "本科二批"
  },
  {
    "specialty_code": "050308",
    "name": "时尚传播",
    "is_wenli": "理科",
    "jihua": "9",
    "baokao": "1",
    "luqu": "6",
    "pici": "本科二批"
  },
  {
    "specialty_code": "07010F",
    "name": "数学与应用数学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "1",
    "luqu": "6",
    "pici": "本科二批"
  },
  {
    "specialty_code": "070306",
    "name": "化学测量学与技术",
    "is_wenli": "理科",
    "jihua": "5",
    "baokao": "1",
    "luqu": "5",
    "pici": "本科二批"
  },
  {
    "specialty_code": "070903",
    "name": "地球信息科学与技术",
    "is_wenli": "理科",
    "jihua": "9",
    "baokao": "1",
    "luqu": "9",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080218",
    "name": "智能交互设计",
    "is_wenli": "理科",
    "jihua": "7",
    "baokao": "1",
    "luqu": "7",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080409",
    "name": "粉体材料科学与工程",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "1",
    "luqu": "6",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08040J",
    "name": "金属材料工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "10",
    "baokao": "1",
    "luqu": "10",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080504",
    "name": "储能科学与工程",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "1",
    "luqu": "6",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080607",
    "name": "能源互联网工程",
    "is_wenli": "理科",
    "jihua": "1",
    "baokao": "1",
    "luqu": "1",
    "pici": "本科二批"
  },
  {
    "specialty_code": "080718",
    "name": "海洋信息工程",
    "is_wenli": "理科",
    "jihua": "1",
    "baokao": "1",
    "luqu": "1",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081104",
    "name": "水务工程",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "1",
    "luqu": "2",
    "pici": "本科二批"
  },
  {
    "specialty_code": "081603",
    "name": "非织造材料与工程",
    "is_wenli": "理科",
    "jihua": "45",
    "baokao": "1",
    "luqu": "45",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08180G",
    "name": "交通工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "7",
    "baokao": "1",
    "luqu": "7",
    "pici": "本科二批"
  },
  {
    "specialty_code": "0821",
    "name": "兵器类",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "1",
    "luqu": "4",
    "pici": "本科二批"
  },
  {
    "specialty_code": "08270M",
    "name": "烹饪与营养教育(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "1",
    "luqu": "2",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082710",
    "name": "食品营养与健康",
    "is_wenli": "理科",
    "jihua": "8",
    "baokao": "1",
    "luqu": "8",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082712",
    "name": "白酒酿造工程",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "1",
    "luqu": "4",
    "pici": "本科二批"
  },
  {
    "specialty_code": "082903",
    "name": "职业卫生工程",
    "is_wenli": "理科",
    "jihua": "8",
    "baokao": "1",
    "luqu": "8",
    "pici": "本科二批"
  },
  {
    "specialty_code": "09010G",
    "name": "园艺(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "1",
    "luqu": "4",
    "pici": "本科二批"
  },
  {
    "specialty_code": "090114",
    "name": "农药化肥",
    "is_wenli": "理科",
    "jihua": "13",
    "baokao": "1",
    "luqu": "13",
    "pici": "本科二批"
  }]