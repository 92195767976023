module.exports = [
  { "name": "河南理工大学", "is_wenli": "理科", "jihua": 4975, "luqu": 4985, "min_score": 559 },
  { "name": "河南农业大学", "is_wenli": "理科", "jihua": 4096, "luqu": 4118, "min_score": 552 },
  { "name": "河南科技大学", "is_wenli": "理科", "jihua": 3236, "luqu": 3247, "min_score": 576 },
  { "name": "郑州大学", "is_wenli": "理科", "jihua": 3067, "luqu": 3069, "min_score": 624 },
  { "name": "河南工业大学", "is_wenli": "理科", "jihua": 2745, "luqu": 2769, "min_score": 570 },
  { "name": "郑州航空工业管理学院", "is_wenli": "理科", "jihua": 2738, "luqu": 2741, "min_score": 549 },
  { "name": "华北水利水电大学", "is_wenli": "理科", "jihua": 2543, "luqu": 2570, "min_score": 571 },
  { "name": "河南中医药大学", "is_wenli": "理科", "jihua": 2526, "luqu": 2526, "min_score": 559 },
  { "name": "河南师范大学", "is_wenli": "理科", "jihua": 2181, "luqu": 2196, "min_score": 576 },
  { "name": "新乡医学院", "is_wenli": "理科", "jihua": 2082, "luqu": 2088, "min_score": 577 },
  { "name": "郑州轻工业大学", "is_wenli": "理科", "jihua": 1885, "luqu": 1923, "min_score": 561 },
  { "name": "中原工学院", "is_wenli": "理科", "jihua": 1571, "luqu": 1581, "min_score": 552 },
  { "name": "河南大学", "is_wenli": "理科", "jihua": 1544, "luqu": 1556, "min_score": 607 },
  { "name": "河南财经政法大学", "is_wenli": "理科", "jihua": 1515, "luqu": 1529, "min_score": 585 },
  { "name": "郑州大学(中外合作办学)", "is_wenli": "理科", "jihua": 1095, "luqu": 1102, "min_score": 606 },
  { "name": "河南大学(中外合作办学)", "is_wenli": "理科", "jihua": 780, "luqu": 786, "min_score": 564 },
  { "name": "河南科技大学(农林类)", "is_wenli": "理科", "jihua": 751, "luqu": 781, "min_score": 524 },
  { "name": "河南财经政法大学[较高收费]", "is_wenli": "理科", "jihua": 760, "luqu": 768, "min_score": 555 },
  { "name": "河南科技学院", "is_wenli": "理科", "jihua": 711, "luqu": 725, "min_score": 547 },
  { "name": "河南大学(软件类)", "is_wenli": "理科", "jihua": 646, "luqu": 656, "min_score": 581 },
  { "name": "河南城建学院", "is_wenli": "理科", "jihua": 617, "luqu": 617, "min_score": 544 },
  { "name": "中原工学院(软件类)", "is_wenli": "理科", "jihua": 630, "luqu": 604, "min_score": 524 },
  { "name": "吉林大学", "is_wenli": "理科", "jihua": 516, "luqu": 517, "min_score": 646 },
  { "name": "信阳师范学院", "is_wenli": "理科", "jihua": 410, "luqu": 417, "min_score": 565 },
  { "name": "武汉理工大学", "is_wenli": "理科", "jihua": 410, "luqu": 412, "min_score": 638 },
  { "name": "武汉大学", "is_wenli": "理科", "jihua": 407, "luqu": 407, "min_score": 666 },
  { "name": "华中科技大学", "is_wenli": "理科", "jihua": 386, "luqu": 389, "min_score": 669 },
  { "name": "新疆大学", "is_wenli": "理科", "jihua": 380, "luqu": 382, "min_score": 585 },
  { "name": "天津商业大学", "is_wenli": "理科", "jihua": 359, "luqu": 359, "min_score": 578 },
  { "name": "中南大学", "is_wenli": "理科", "jihua": 352, "luqu": 352, "min_score": 611 },
  { "name": "西北农林科技大学", "is_wenli": "理科", "jihua": 341, "luqu": 343, "min_score": 622 },
  { "name": "海南大学", "is_wenli": "理科", "jihua": 335, "luqu": 341, "min_score": 595 },
  { "name": "中国矿业大学", "is_wenli": "理科", "jihua": 322, "luqu": 322, "min_score": 617 },
  { "name": "中国矿业大学", "is_wenli": "理科", "jihua": 322, "luqu": 322, "min_score": 617 },
  { "name": "哈尔滨理工大学", "is_wenli": "理科", "jihua": 318, "luqu": 318, "min_score": 594 },
  { "name": "石河子大学", "is_wenli": "理科", "jihua": 307, "luqu": 316, "min_score": 585 },
  { "name": "华中农业大学", "is_wenli": "理科", "jihua": 286, "luqu": 286, "min_score": 615 },
  { "name": "东北林业大学", "is_wenli": "理科", "jihua": 269, "luqu": 275, "min_score": 612 },
  { "name": "天津科技大学", "is_wenli": "理科", "jihua": 272, "luqu": 272, "min_score": 592 },
  { "name": "山东大学", "is_wenli": "理科", "jihua": 250, "luqu": 256, "min_score": 653 },
  { "name": "山东大学", "is_wenli": "理科", "jihua": 250, "luqu": 256, "min_score": 653 },
  { "name": "湖南大学", "is_wenli": "理科", "jihua": 247, "luqu": 249, "min_score": 650 },
  { "name": "合肥工业大学", "is_wenli": "理科", "jihua": 245, "luqu": 245, "min_score": 628 },
  { "name": "上海应用技术大学", "is_wenli": "理科", "jihua": 244, "luqu": 244, "min_score": 585 },
  { "name": "长安大学", "is_wenli": "理科", "jihua": 235, "luqu": 239, "min_score": 622 },
  { "name": "南京农业大学", "is_wenli": "理科", "jihua": 233, "luqu": 233, "min_score": 619 },
  { "name": "河南科技大学[护理]", "is_wenli": "理科", "jihua": 227, "luqu": 227, "min_score": 544 },
  { "name": "哈尔滨工程大学", "is_wenli": "理科", "jihua": 224, "luqu": 224, "min_score": 635 },
  { "name": "新疆医科大学", "is_wenli": "理科", "jihua": 220, "luqu": 220, "min_score": 564 },
  { "name": "中国地质大学(武汉)", "is_wenli": "理科", "jihua": 208, "luqu": 218, "min_score": 628 },
  { "name": "大连理工大学", "is_wenli": "理科", "jihua": 214, "luqu": 216, "min_score": 651 },
  { "name": "长江大学", "is_wenli": "理科", "jihua": 206, "luqu": 214, "min_score": 590 },
  { "name": "南京工程学院", "is_wenli": "理科", "jihua": 214, "luqu": 214, "min_score": 581 },
  { "name": "天津理工大学", "is_wenli": "理科", "jihua": 213, "luqu": 213, "min_score": 600 },
  { "name": "华北水利水电大学(乌拉尔学院)", "is_wenli": "理科", "jihua": 225, "luqu": 213, "min_score": 544 },
  { "name": "西北工业大学", "is_wenli": "理科", "jihua": 210, "luqu": 210, "min_score": 659 },
  { "name": "西安电子科技大学", "is_wenli": "理科", "jihua": 205, "luqu": 205, "min_score": 653 },
  { "name": "天津工业大学", "is_wenli": "理科", "jihua": 205, "luqu": 205, "min_score": 616 },
  { "name": "郑州大学[护理]", "is_wenli": "理科", "jihua": 200, "luqu": 202, "min_score": 583 },
  { "name": "郑州大学(中外合作办学)[护理]", "is_wenli": "理科", "jihua": 200, "luqu": 200, "min_score": 566 },
  { "name": "上海大学", "is_wenli": "理科", "jihua": 197, "luqu": 197, "min_score": 643 },
  { "name": "湖南工业大学", "is_wenli": "理科", "jihua": 140, "luqu": 197, "min_score": 586 },
  { "name": "河海大学", "is_wenli": "理科", "jihua": 197, "luqu": 197, "min_score": 631 },
  { "name": "安徽理工大学", "is_wenli": "理科", "jihua": 196, "luqu": 196, "min_score": 583 },
  { "name": "中国民航大学", "is_wenli": "理科", "jihua": 190, "luqu": 194, "min_score": 584 },
  { "name": "东北农业大学", "is_wenli": "理科", "jihua": 181, "luqu": 192, "min_score": 592 },
  { "name": "南通大学", "is_wenli": "理科", "jihua": 191, "luqu": 191, "min_score": 598 },
  { "name": "南阳师范学院", "is_wenli": "理科", "jihua": 187, "luqu": 189, "min_score": 556 },
  { "name": "东北石油大学", "is_wenli": "理科", "jihua": 187, "luqu": 187, "min_score": 570 },
  { "name": "南京航空航天大学", "is_wenli": "理科", "jihua": 182, "luqu": 183, "min_score": 643 },
  { "name": "辽宁工程技术大学", "is_wenli": "理科", "jihua": 183, "luqu": 183, "min_score": 569 },
  { "name": "沈阳农业大学", "is_wenli": "理科", "jihua": 166, "luqu": 182, "min_score": 559 },
  { "name": "东北大学", "is_wenli": "理科", "jihua": 173, "luqu": 179, "min_score": 645 },
  { "name": "南京工业大学", "is_wenli": "理科", "jihua": 178, "luqu": 178, "min_score": 614 },
  { "name": "四川大学", "is_wenli": "理科", "jihua": 174, "luqu": 174, "min_score": 659 },
  { "name": "河南大学(迈阿密学院)", "is_wenli": "理科", "jihua": 170, "luqu": 174, "min_score": 562 },
  { "name": "集美大学", "is_wenli": "理科", "jihua": 171, "luqu": 171, "min_score": 596 },
  { "name": "东北电力大学", "is_wenli": "理科", "jihua": 166, "luqu": 168, "min_score": 596 },
  { "name": "四川农业大学", "is_wenli": "理科", "jihua": 165, "luqu": 167, "min_score": 608 },
  { "name": "浙大宁波理工学院", "is_wenli": "理科", "jihua": 165, "luqu": 165, "min_score": 575 },
  { "name": "合肥工业大学(宣城校区)", "is_wenli": "理科", "jihua": 165, "luqu": 165, "min_score": 621 },
  { "name": "电子科技大学", "is_wenli": "理科", "jihua": 163, "luqu": 164, "min_score": 665 },
  { "name": "西安工程大学", "is_wenli": "理科", "jihua": 154, "luqu": 163, "min_score": 594 },
  { "name": "上海工程技术大学", "is_wenli": "理科", "jihua": 155, "luqu": 158, "min_score": 585 },
  { "name": "西南石油大学", "is_wenli": "理科", "jihua": 154, "luqu": 154, "min_score": 605 },
  { "name": "东南大学", "is_wenli": "理科", "jihua": 151, "luqu": 153, "min_score": 666 },
  { "name": "北京科技大学", "is_wenli": "理科", "jihua": 150, "luqu": 153, "min_score": 646 },
  { "name": "江苏科技大学", "is_wenli": "理科", "jihua": 152, "luqu": 152, "min_score": 590 },
  { "name": "江苏大学", "is_wenli": "理科", "jihua": 150, "luqu": 150, "min_score": 595 },
  { "name": "天津中医药大学", "is_wenli": "理科", "jihua": 149, "luqu": 149, "min_score": 576 },
  { "name": "江南大学", "is_wenli": "理科", "jihua": 148, "luqu": 149, "min_score": 626 },
  { "name": "哈尔滨工业大学(威海)", "is_wenli": "理科", "jihua": 138, "luqu": 145, "min_score": 656 },
  { "name": "重庆大学", "is_wenli": "理科", "jihua": 136, "luqu": 143, "min_score": 654 },
  { "name": "天津师范大学", "is_wenli": "理科", "jihua": 142, "luqu": 142, "min_score": 602 },
  { "name": "上海师范大学", "is_wenli": "理科", "jihua": 142, "luqu": 142, "min_score": 609 },
  { "name": "西南交通大学", "is_wenli": "理科", "jihua": 139, "luqu": 141, "min_score": 639 },
  { "name": "南京信息工程大学", "is_wenli": "理科", "jihua": 141, "luqu": 141, "min_score": 611 },
  { "name": "大连海事大学", "is_wenli": "理科", "jihua": 139, "luqu": 141, "min_score": 617 },
  { "name": "中国民用航空飞行学院", "is_wenli": "理科", "jihua": 140, "luqu": 140, "min_score": 544 },
  { "name": "武汉工程大学", "is_wenli": "理科", "jihua": 126, "luqu": 139, "min_score": 604 },
  { "name": "武汉科技大学", "is_wenli": "理科", "jihua": 135, "luqu": 137, "min_score": 611 },
  { "name": "三峡大学", "is_wenli": "理科", "jihua": 127, "luqu": 137, "min_score": 596 },
  { "name": "南京理工大学", "is_wenli": "理科", "jihua": 137, "luqu": 137, "min_score": 646 },
  { "name": "辽宁石油化工大学", "is_wenli": "理科", "jihua": 137, "luqu": 137, "min_score": 566 },
  { "name": "中南财经政法大学", "is_wenli": "理科", "jihua": 136, "luqu": 136, "min_score": 637 },
  { "name": "华北理工大学", "is_wenli": "理科", "jihua": 136, "luqu": 136, "min_score": 589 },
  { "name": "南昌大学", "is_wenli": "理科", "jihua": 128, "luqu": 134, "min_score": 622 },
  { "name": "浙大城市学院(原浙江大学城市学院)", "is_wenli": "理科", "jihua": 133, "luqu": 133, "min_score": 585 },
  { "name": "华北电力大学(保定)", "is_wenli": "理科", "jihua": 130, "luqu": 133, "min_score": 631 },
  { "name": "兰州理工大学", "is_wenli": "理科", "jihua": 129, "luqu": 132, "min_score": 579 },
  { "name": "兰州理工大学", "is_wenli": "理科", "jihua": 129, "luqu": 132, "min_score": 579 },
  { "name": "江西理工大学", "is_wenli": "理科", "jihua": 132, "luqu": 132, "min_score": 579 },
  { "name": "苏州大学", "is_wenli": "理科", "jihua": 124, "luqu": 131, "min_score": 640 },
  { "name": "西交利物浦大学", "is_wenli": "理科", "jihua": 115, "luqu": 130, "min_score": 547 },
  { "name": "华南农业大学", "is_wenli": "理科", "jihua": 128, "luqu": 130, "min_score": 599 },
  { "name": "上海电力大学", "is_wenli": "理科", "jihua": 129, "luqu": 129, "min_score": 612 },
  { "name": "中山大学", "is_wenli": "理科", "jihua": 126, "luqu": 127, "min_score": 657 },
  { "name": "中北大学", "is_wenli": "理科", "jihua": 127, "luqu": 127, "min_score": 600 },
  { "name": "沈阳航空航天大学", "is_wenli": "理科", "jihua": 127, "luqu": 127, "min_score": 594 },
  { "name": "北京化工大学", "is_wenli": "理科", "jihua": 127, "luqu": 127, "min_score": 637 },
  { "name": "陕西师范大学", "is_wenli": "理科", "jihua": 124, "luqu": 126, "min_score": 626 },
  { "name": "青海大学", "is_wenli": "理科", "jihua": 120, "luqu": 126, "min_score": 601 },
  { "name": "北京交通大学", "is_wenli": "理科", "jihua": 125, "luqu": 125, "min_score": 644 },
  { "name": "哈尔滨商业大学", "is_wenli": "理科", "jihua": 124, "luqu": 124, "min_score": 572 },
  { "name": "东北大学秦皇岛分校", "is_wenli": "理科", "jihua": 121, "luqu": 123, "min_score": 635 },
  { "name": "北京邮电大学", "is_wenli": "理科", "jihua": 121, "luqu": 121, "min_score": 662 },
  { "name": "长沙理工大学", "is_wenli": "理科", "jihua": 115, "luqu": 120, "min_score": 609 },
  { "name": "华北水利水电大学[较高收费]", "is_wenli": "理科", "jihua": 120, "luqu": 120, "min_score": 551 },
  { "name": "常州大学", "is_wenli": "理科", "jihua": 120, "luqu": 120, "min_score": 595 },
  { "name": "中国矿业大学(北京)", "is_wenli": "理科", "jihua": 115, "luqu": 117, "min_score": 626 },
  { "name": "大连工业大学", "is_wenli": "理科", "jihua": 102, "luqu": 116, "min_score": 582 },
  { "name": "湖北大学", "is_wenli": "理科", "jihua": 115, "luqu": 115, "min_score": 607 },
  { "name": "西安交通大学", "is_wenli": "理科", "jihua": 105, "luqu": 114, "min_score": 674 },
  { "name": "北京航空航天大学", "is_wenli": "理科", "jihua": 114, "luqu": 114, "min_score": 684 },
  { "name": "中国农业大学", "is_wenli": "理科", "jihua": 107, "luqu": 112, "min_score": 644 },
  { "name": "上海第二工业大学", "is_wenli": "理科", "jihua": 112, "luqu": 112, "min_score": 598 },
  { "name": "东北师范大学", "is_wenli": "理科", "jihua": 112, "luqu": 112, "min_score": 605 },
  { "name": "兰州大学", "is_wenli": "理科", "jihua": 110, "luqu": 111, "min_score": 643 },
  { "name": "中南民族大学[仅招少数民族]", "is_wenli": "理科", "jihua": 110, "luqu": 110, "min_score": 571 },
  { "name": "上海理工大学", "is_wenli": "理科", "jihua": 109, "luqu": 110, "min_score": 620 },
  { "name": "上海理工大学", "is_wenli": "理科", "jihua": 109, "luqu": 110, "min_score": 620 },
  { "name": "浙江农林大学", "is_wenli": "理科", "jihua": 109, "luqu": 109, "min_score": 583 },
  { "name": "华侨大学", "is_wenli": "理科", "jihua": 109, "luqu": 109, "min_score": 593 },
  { "name": "兰州交通大学", "is_wenli": "理科", "jihua": 105, "luqu": 108, "min_score": 584 },
  { "name": "华东理工大学", "is_wenli": "理科", "jihua": 107, "luqu": 108, "min_score": 643 },
  { "name": "北京林业大学", "is_wenli": "理科", "jihua": 108, "luqu": 108, "min_score": 604 },
  { "name": "中南林业科技大学", "is_wenli": "理科", "jihua": 105, "luqu": 107, "min_score": 578 },
  { "name": "石家庄铁道大学", "is_wenli": "理科", "jihua": 98, "luqu": 106, "min_score": 594 },
  { "name": "华南理工大学", "is_wenli": "理科", "jihua": 106, "luqu": 106, "min_score": 657 },
  { "name": "华北电力大学(北京)", "is_wenli": "理科", "jihua": 105, "luqu": 105, "min_score": 641 },
  { "name": "浙江科技学院", "is_wenli": "理科", "jihua": 103, "luqu": 103, "min_score": 579 },
  { "name": "北京理工大学", "is_wenli": "理科", "jihua": 103, "luqu": 103, "min_score": 677 },
  { "name": "重庆邮电大学", "is_wenli": "理科", "jihua": 98, "luqu": 102, "min_score": 618 },
  { "name": "重庆交通大学", "is_wenli": "理科", "jihua": 101, "luqu": 102, "min_score": 598 },
  { "name": "南京林业大学", "is_wenli": "理科", "jihua": 102, "luqu": 102, "min_score": 607 },
  { "name": "南华大学", "is_wenli": "理科", "jihua": 102, "luqu": 102, "min_score": 589 },
  { "name": "中国药科大学", "is_wenli": "理科", "jihua": 98, "luqu": 100, "min_score": 626 },
  { "name": "浙江理工大学", "is_wenli": "理科", "jihua": 98, "luqu": 100, "min_score": 613 },
  { "name": "齐鲁工业大学", "is_wenli": "理科", "jihua": 100, "luqu": 100, "min_score": 574 },
  { "name": "河南农业大学(中外合作办学)", "is_wenli": "理科", "jihua": 100, "luqu": 100, "min_score": 530 },
  { "name": "桂林电子科技大学", "is_wenli": "理科", "jihua": 95, "luqu": 100, "min_score": 603 },
  { "name": "东华大学", "is_wenli": "理科", "jihua": 100, "luqu": 100, "min_score": 636 },
  { "name": "中国石油大学(北京)", "is_wenli": "理科", "jihua": 95, "luqu": 99, "min_score": 631 },
  { "name": "上海海洋大学", "is_wenli": "理科", "jihua": 99, "luqu": 99, "min_score": 606 },
  { "name": "华东交通大学", "is_wenli": "理科", "jihua": 94, "luqu": 98, "min_score": 593 },
  { "name": "海南师范大学", "is_wenli": "理科", "jihua": 98, "luqu": 98, "min_score": 573 },
  { "name": "燕山大学", "is_wenli": "理科", "jihua": 95, "luqu": 97, "min_score": 607 },
  { "name": "湘潭大学", "is_wenli": "理科", "jihua": 92, "luqu": 96, "min_score": 602 },
  { "name": "重庆理工大学", "is_wenli": "理科", "jihua": 94, "luqu": 95, "min_score": 602 },
  { "name": "西南科技大学", "is_wenli": "理科", "jihua": 89, "luqu": 93, "min_score": 594 },
  { "name": "武汉纺织大学", "is_wenli": "理科", "jihua": 80, "luqu": 93, "min_score": 589 },
  { "name": "西南大学", "is_wenli": "理科", "jihua": 86, "luqu": 91, "min_score": 631 },
  { "name": "上海电机学院", "is_wenli": "理科", "jihua": 91, "luqu": 91, "min_score": 589 },
  { "name": "太原理工大学", "is_wenli": "理科", "jihua": 90, "luqu": 90, "min_score": 623 },
  { "name": "山东大学威海分校", "is_wenli": "理科", "jihua": 90, "luqu": 90, "min_score": 645 },
  { "name": "南京邮电大学", "is_wenli": "理科", "jihua": 89, "luqu": 89, "min_score": 634 },
  { "name": "江汉大学", "is_wenli": "理科", "jihua": 75, "luqu": 88, "min_score": 564 },
  { "name": "湖南科技大学", "is_wenli": "理科", "jihua": 77, "luqu": 88, "min_score": 600 },
  { "name": "浙江工业大学", "is_wenli": "理科", "jihua": 87, "luqu": 87, "min_score": 612 },
  { "name": "河南大学[护理]", "is_wenli": "理科", "jihua": 87, "luqu": 87, "min_score": 544 },
  { "name": "黑龙江中医药大学", "is_wenli": "理科", "jihua": 86, "luqu": 86, "min_score": 567 },
  { "name": "河北科技大学", "is_wenli": "理科", "jihua": 86, "luqu": 86, "min_score": 590 },
  { "name": "西安石油大学", "is_wenli": "理科", "jihua": 78, "luqu": 85, "min_score": 597 },
  { "name": "南京审计大学", "is_wenli": "理科", "jihua": 84, "luqu": 84, "min_score": 612 },
  { "name": "中国海洋大学", "is_wenli": "理科", "jihua": 82, "luqu": 83, "min_score": 644 },
  { "name": "福建医科大学", "is_wenli": "理科", "jihua": 83, "luqu": 83, "min_score": 568 },
  { "name": "扬州大学", "is_wenli": "理科", "jihua": 78, "luqu": 82, "min_score": 572 },
  { "name": "天津大学", "is_wenli": "理科", "jihua": 82, "luqu": 82, "min_score": 665 },
  { "name": "太原科技大学", "is_wenli": "理科", "jihua": 82, "luqu": 82, "min_score": 572 },
  { "name": "厦门大学", "is_wenli": "理科", "jihua": 82, "luqu": 82, "min_score": 662 },
  { "name": "南昌工程学院", "is_wenli": "理科", "jihua": 62, "luqu": 82, "min_score": 578 },
  { "name": "重庆工商大学", "is_wenli": "理科", "jihua": 80, "luqu": 81, "min_score": 596 },
  { "name": "中南民族大学", "is_wenli": "理科", "jihua": 81, "luqu": 81, "min_score": 601 },
  { "name": "西安理工大学", "is_wenli": "理科", "jihua": 79, "luqu": 81, "min_score": 613 },
  { "name": "桂林理工大学", "is_wenli": "理科", "jihua": 81, "luqu": 81, "min_score": 558 },
  { "name": "上海健康医学院", "is_wenli": "理科", "jihua": 80, "luqu": 80, "min_score": 571 },
  { "name": "杭州电子科技大学", "is_wenli": "理科", "jihua": 80, "luqu": 80, "min_score": 626 },
  { "name": "沈阳建筑大学", "is_wenli": "理科", "jihua": 79, "luqu": 79, "min_score": 571 },
  { "name": "宁波大学", "is_wenli": "理科", "jihua": 77, "luqu": 79, "min_score": 603 },
  { "name": "河北大学", "is_wenli": "理科", "jihua": 79, "luqu": 79, "min_score": 596 },
  { "name": "温州医科大学", "is_wenli": "理科", "jihua": 78, "luqu": 78, "min_score": 579 },
  { "name": "天津财经大学", "is_wenli": "理科", "jihua": 77, "luqu": 78, "min_score": 610 },
  { "name": "上海海事大学", "is_wenli": "理科", "jihua": 78, "luqu": 78, "min_score": 607 },
  { "name": "济南大学", "is_wenli": "理科", "jihua": 78, "luqu": 78, "min_score": 601 },
  { "name": "昆明理工大学", "is_wenli": "理科", "jihua": 77, "luqu": 77, "min_score": 602 },
  { "name": "安徽工业大学", "is_wenli": "理科", "jihua": 73, "luqu": 77, "min_score": 581 },
  { "name": "安徽财经大学", "is_wenli": "理科", "jihua": 76, "luqu": 76, "min_score": 588 },
  { "name": "中国地质大学(北京)", "is_wenli": "理科", "jihua": 75, "luqu": 75, "min_score": 621 },
  { "name": "东华理工大学", "is_wenli": "理科", "jihua": 69, "luqu": 74, "min_score": 591 },
  { "name": "青岛理工大学", "is_wenli": "理科", "jihua": 73, "luqu": 73, "min_score": 601 },
  { "name": "湖北工业大学", "is_wenli": "理科", "jihua": 59, "luqu": 73, "min_score": 604 },
  { "name": "广东工业大学", "is_wenli": "理科", "jihua": 65, "luqu": 73, "min_score": 604 },
  { "name": "中国计量大学", "is_wenli": "理科", "jihua": 72, "luqu": 72, "min_score": 613 },
  { "name": "沈阳理工大学", "is_wenli": "理科", "jihua": 72, "luqu": 72, "min_score": 587 },
  { "name": "山东农业大学", "is_wenli": "理科", "jihua": 72, "luqu": 72, "min_score": 549 },
  { "name": "山东科技大学", "is_wenli": "理科", "jihua": 71, "luqu": 71, "min_score": 597 },
  { "name": "青岛大学", "is_wenli": "理科", "jihua": 71, "luqu": 71, "min_score": 599 },
  { "name": "浙江中医药大学", "is_wenli": "理科", "jihua": 70, "luqu": 70, "min_score": 578 },
  { "name": "哈尔滨工业大学", "is_wenli": "理科", "jihua": 70, "luqu": 70, "min_score": 670 },
  { "name": "沈阳工业大学", "is_wenli": "理科", "jihua": 63, "luqu": 69, "min_score": 591 },
  { "name": "华东政法大学", "is_wenli": "理科", "jihua": 69, "luqu": 69, "min_score": 623 },
  { "name": "福州大学", "is_wenli": "理科", "jihua": 69, "luqu": 69, "min_score": 627 },
  { "name": "南开大学", "is_wenli": "理科", "jihua": 68, "luqu": 68, "min_score": 678 },
  { "name": "电子科技大学(沙河校区)", "is_wenli": "理科", "jihua": 68, "luqu": 68, "min_score": 661 },
  { "name": "电子科技大学(沙河校区)", "is_wenli": "理科", "jihua": 68, "luqu": 68, "min_score": 661 },
  { "name": "上海政法学院", "is_wenli": "理科", "jihua": 66, "luqu": 67, "min_score": 605 },
  { "name": "辽宁大学", "is_wenli": "理科", "jihua": 67, "luqu": 67, "min_score": 621 },
  { "name": "华东师范大学", "is_wenli": "理科", "jihua": 63, "luqu": 67, "min_score": 660 },
  { "name": "中央财经大学", "is_wenli": "理科", "jihua": 66, "luqu": 66, "min_score": 659 },
  { "name": "同济大学", "is_wenli": "理科", "jihua": 66, "luqu": 66, "min_score": 681 },
  { "name": "西安科技大学", "is_wenli": "理科", "jihua": 60, "luqu": 64, "min_score": 609 },
  { "name": "福建师范大学", "is_wenli": "理科", "jihua": 64, "luqu": 64, "min_score": 598 },
  { "name": "西南交通大学[异地校区]", "is_wenli": "理科", "jihua": 63, "luqu": 63, "min_score": 615 },
  { "name": "西安工业大学", "is_wenli": "理科", "jihua": 61, "luqu": 63, "min_score": 608 },
  { "name": "山东财经大学", "is_wenli": "理科", "jihua": 63, "luqu": 63, "min_score": 603 },
  { "name": "陕西科技大学", "is_wenli": "理科", "jihua": 62, "luqu": 62, "min_score": 599 },
  { "name": "山东理工大学", "is_wenli": "理科", "jihua": 62, "luqu": 62, "min_score": 592 },
  { "name": "湖北中医药大学", "is_wenli": "理科", "jihua": 62, "luqu": 62, "min_score": 581 },
  { "name": "清华大学", "is_wenli": "理科", "jihua": 57, "luqu": 61, "min_score": 704 },
  { "name": "青岛科技大学", "is_wenli": "理科", "jihua": 61, "luqu": 61, "min_score": 592 },
  { "name": "北京中医药大学", "is_wenli": "理科", "jihua": 61, "luqu": 61, "min_score": 633 },
  { "name": "西安邮电大学", "is_wenli": "理科", "jihua": 60, "luqu": 60, "min_score": 617 },
  { "name": "河南大学(与开封大学联合办学)(就读地点：开封大学)", "is_wenli": "理科", "jihua": 60, "luqu": 60, "min_score": 555 },
  { "name": "东北林业大学[较高收费]", "is_wenli": "理科", "jihua": 60, "luqu": 60, "min_score": 547 },
  { "name": "新疆大学[较高收费]", "is_wenli": "理科", "jihua": 59, "luqu": 59, "min_score": 587 },
  { "name": "江西财经大学", "is_wenli": "理科", "jihua": 56, "luqu": 59, "min_score": 613 },
  { "name": "上海对外经贸大学", "is_wenli": "理科", "jihua": 58, "luqu": 58, "min_score": 617 },
  { "name": "山东建筑大学", "is_wenli": "理科", "jihua": 58, "luqu": 58, "min_score": 583 },
  { "name": "暨南大学", "is_wenli": "理科", "jihua": 57, "luqu": 58, "min_score": 637 },
  { "name": "贵州大学", "is_wenli": "理科", "jihua": 56, "luqu": 58, "min_score": 619 },
  { "name": "成都理工大学[异地校区]", "is_wenli": "理科", "jihua": 58, "luqu": 58, "min_score": 598 },
  { "name": "西安交通大学[护理]", "is_wenli": "理科", "jihua": 57, "luqu": 57, "min_score": 618 },
  { "name": "北京信息科技大学", "is_wenli": "理科", "jihua": 54, "luqu": 57, "min_score": 615 },
  { "name": "安徽建筑大学", "is_wenli": "理科", "jihua": 52, "luqu": 57, "min_score": 574 },
  { "name": "西华大学", "is_wenli": "理科", "jihua": 56, "luqu": 56, "min_score": 598 },
  { "name": "沈阳药科大学", "is_wenli": "理科", "jihua": 56, "luqu": 56, "min_score": 581 },
  { "name": "西安建筑科技大学", "is_wenli": "理科", "jihua": 51, "luqu": 55, "min_score": 599 },
  { "name": "南昌航空大学", "is_wenli": "理科", "jihua": 47, "luqu": 55, "min_score": 586 },
  { "name": "杭州师范大学", "is_wenli": "理科", "jihua": 55, "luqu": 55, "min_score": 611 },
  { "name": "广西大学", "is_wenli": "理科", "jihua": 55, "luqu": 55, "min_score": 618 },
  { "name": "成都信息工程大学(原成都信息工程学院)", "is_wenli": "理科", "jihua": 53, "luqu": 55, "min_score": 606 },
  { "name": "苏州科技大学", "is_wenli": "理科", "jihua": 54, "luqu": 54, "min_score": 604 },
  { "name": "吉林财经大学", "is_wenli": "理科", "jihua": 54, "luqu": 54, "min_score": 584 },
  { "name": "湖南农业大学", "is_wenli": "理科", "jihua": 54, "luqu": 54, "min_score": 571 },
  { "name": "海南医学院", "is_wenli": "理科", "jihua": 54, "luqu": 54, "min_score": 578 },
  { "name": "西南大学[较高收费]", "is_wenli": "理科", "jihua": 53, "luqu": 53, "min_score": 546 },
  { "name": "哈尔滨工业大学(深圳)", "is_wenli": "理科", "jihua": 53, "luqu": 53, "min_score": 678 },
  { "name": "大连理工大学(盘锦校区)", "is_wenli": "理科", "jihua": 52, "luqu": 53, "min_score": 635 },
  { "name": "江西师范大学", "is_wenli": "理科", "jihua": 40, "luqu": 52, "min_score": 594 },
  { "name": "湖南中医药大学", "is_wenli": "理科", "jihua": 52, "luqu": 52, "min_score": 596 },
  { "name": "南京中医药大学", "is_wenli": "理科", "jihua": 52, "luqu": 51, "min_score": 607 },
  { "name": "北京工商大学", "is_wenli": "理科", "jihua": 50, "luqu": 51, "min_score": 604 },
  { "name": "宁夏大学", "is_wenli": "理科", "jihua": 50, "luqu": 50, "min_score": 604 },
  { "name": "黑龙江大学", "is_wenli": "理科", "jihua": 50, "luqu": 50, "min_score": 589 },
  { "name": "哈尔滨医科大学", "is_wenli": "理科", "jihua": 50, "luqu": 50, "min_score": 577 },
  { "name": "国防科技大学", "is_wenli": "理科", "jihua": 50, "luqu": 50, "min_score": 664 },
  { "name": "安徽大学", "is_wenli": "理科", "jihua": 42, "luqu": 50, "min_score": 626 },
  { "name": "西南财经大学", "is_wenli": "理科", "jihua": 48, "luqu": 49, "min_score": 640 },
  { "name": "南京师范大学", "is_wenli": "理科", "jihua": 49, "luqu": 49, "min_score": 633 },
  { "name": "中国政法大学", "is_wenli": "理科", "jihua": 48, "luqu": 48, "min_score": 655 },
  { "name": "四川师范大学", "is_wenli": "理科", "jihua": 48, "luqu": 48, "min_score": 600 },
  { "name": "山西大学", "is_wenli": "理科", "jihua": 47, "luqu": 47, "min_score": 601 },
  { "name": "大连交通大学", "is_wenli": "理科", "jihua": 47, "luqu": 47, "min_score": 591 },
  { "name": "天津医科大学", "is_wenli": "理科", "jihua": 46, "luqu": 46, "min_score": 612 },
  { "name": "上海财经大学", "is_wenli": "理科", "jihua": 45, "luqu": 46, "min_score": 661 },
  { "name": "北方工业大学", "is_wenli": "理科", "jihua": 44, "luqu": 46, "min_score": 609 },
  { "name": "云南大学", "is_wenli": "理科", "jihua": 45, "luqu": 45, "min_score": 592 },
  { "name": "山东大学威海分校[较高收费]", "is_wenli": "理科", "jihua": 45, "luqu": 45, "min_score": 628 },
  { "name": "南方医科大学", "is_wenli": "理科", "jihua": 45, "luqu": 45, "min_score": 630 },
  { "name": "中国传媒大学", "is_wenli": "理科", "jihua": 44, "luqu": 44, "min_score": 637 },
  { "name": "南京财经大学", "is_wenli": "理科", "jihua": 44, "luqu": 44, "min_score": 612 },
  { "name": "长春中医药大学", "is_wenli": "理科", "jihua": 43, "luqu": 43, "min_score": 582 },
  { "name": "吉林大学[单列专业]", "is_wenli": "理科", "jihua": 43, "luqu": 43, "min_score": 617 },
  { "name": "成都理工大学", "is_wenli": "理科", "jihua": 42, "luqu": 42, "min_score": 617 },
  { "name": "安徽师范大学", "is_wenli": "理科", "jihua": 40, "luqu": 42, "min_score": 592 },
  { "name": "长春理工大学[较高收费]", "is_wenli": "理科", "jihua": 41, "luqu": 41, "min_score": 545 },
  { "name": "上海体育学院", "is_wenli": "理科", "jihua": 41, "luqu": 41, "min_score": 562 },
  { "name": "中原工学院(中原彼得堡航空学院)", "is_wenli": "理科", "jihua": 60, "luqu": 40, "min_score": 524 },
  { "name": "浙江大学", "is_wenli": "理科", "jihua": 40, "luqu": 40, "min_score": 691 },
  { "name": "沈阳航空航天大学[较高收费]", "is_wenli": "理科", "jihua": 40, "luqu": 40, "min_score": 564 },
  { "name": "厦门大学[异地校区]", "is_wenli": "理科", "jihua": 40, "luqu": 40, "min_score": 596 },
  { "name": "内蒙古大学", "is_wenli": "理科", "jihua": 40, "luqu": 40, "min_score": 602 },
  { "name": "南阳理工学院", "is_wenli": "理科", "jihua": 40, "luqu": 40, "min_score": 557 },
  { "name": "河北工程大学", "is_wenli": "理科", "jihua": 40, "luqu": 40, "min_score": 585 },
  { "name": "曲阜师范大学", "is_wenli": "理科", "jihua": 39, "luqu": 39, "min_score": 581 },
  { "name": "湖南工商大学(原湖南商学院)", "is_wenli": "理科", "jihua": 39, "luqu": 39, "min_score": 586 },
  { "name": "大连医科大学", "is_wenli": "理科", "jihua": 39, "luqu": 39, "min_score": 574 },
  { "name": "中国人民大学", "is_wenli": "理科", "jihua": 38, "luqu": 38, "min_score": 687 },
  { "name": "上海立信会计金融学院", "is_wenli": "理科", "jihua": 38, "luqu": 38, "min_score": 611 },
  { "name": "南京大学", "is_wenli": "理科", "jihua": 38, "luqu": 38, "min_score": 689 },
  { "name": "成都大学(原成都学院)", "is_wenli": "理科", "jihua": 38, "luqu": 38, "min_score": 598 },
  { "name": "长春理工大学", "is_wenli": "理科", "jihua": 33, "luqu": 37, "min_score": 601 },
  { "name": "延边大学", "is_wenli": "理科", "jihua": 37, "luqu": 37, "min_score": 604 },
  { "name": "天津外国语大学", "is_wenli": "理科", "jihua": 37, "luqu": 37, "min_score": 606 },
  { "name": "上海师范大学[较高收费]", "is_wenli": "理科", "jihua": 37, "luqu": 37, "min_score": 596 },
  { "name": "上海大学[较高收费]", "is_wenli": "理科", "jihua": 37, "luqu": 37, "min_score": 628 },
  { "name": "汕头大学", "is_wenli": "理科", "jihua": 37, "luqu": 37, "min_score": 589 },
  { "name": "湖南师范大学", "is_wenli": "理科", "jihua": 37, "luqu": 37, "min_score": 623 },
  { "name": "东莞理工学院", "is_wenli": "理科", "jihua": 37, "luqu": 37, "min_score": 594 },
  { "name": "电子科技大学(沙河校区)[较高收费]", "is_wenli": "理科", "jihua": 37, "luqu": 37, "min_score": 649 },
  { "name": "大连外国语大学", "is_wenli": "理科", "jihua": 37, "luqu": 37, "min_score": 594 },
  { "name": "北京师范大学-香港浸会大学联合国际学院", "is_wenli": "理科", "jihua": 37, "luqu": 37, "min_score": 546 },
  { "name": "西南政法大学", "is_wenli": "理科", "jihua": 36, "luqu": 36, "min_score": 626 },
  { "name": "深圳大学", "is_wenli": "理科", "jihua": 36, "luqu": 36, "min_score": 634 },
  { "name": "宁波诺丁汉大学", "is_wenli": "理科", "jihua": 36, "luqu": 36, "min_score": 551 },
  { "name": "对外经济贸易大学", "is_wenli": "理科", "jihua": 36, "luqu": 36, "min_score": 652 },
  { "name": "北京印刷学院", "is_wenli": "理科", "jihua": 36, "luqu": 36, "min_score": 587 },
  { "name": "重庆师范大学", "is_wenli": "理科", "jihua": 35, "luqu": 35, "min_score": 600 },
  { "name": "天津工业大学[较高收费]", "is_wenli": "理科", "jihua": 35, "luqu": 35, "min_score": 594 },
  { "name": "南昌大学[较高收费]", "is_wenli": "理科", "jihua": 35, "luqu": 35, "min_score": 617 },
  { "name": "江西科技师范大学", "is_wenli": "理科", "jihua": 21, "luqu": 35, "min_score": 562 },
  { "name": "河北师范大学", "is_wenli": "理科", "jihua": 35, "luqu": 35, "min_score": 585 },
  { "name": "西安财经大学(原西安财经学院)", "is_wenli": "理科", "jihua": 34, "luqu": 34, "min_score": 577 },
  { "name": "辽宁科技大学", "is_wenli": "理科", "jihua": 34, "luqu": 34, "min_score": 580 },
  { "name": "赣南师范大学", "is_wenli": "理科", "jihua": 34, "luqu": 34, "min_score": 566 },
  { "name": "中国医科大学", "is_wenli": "理科", "jihua": 33, "luqu": 33, "min_score": 632 },
  { "name": "西北师范大学", "is_wenli": "理科", "jihua": 32, "luqu": 33, "min_score": 584 },
  { "name": "上海理工大学[较高收费]", "is_wenli": "理科", "jihua": 33, "luqu": 33, "min_score": 575 },
  { "name": "川北医学院", "is_wenli": "理科", "jihua": 30, "luqu": 33, "min_score": 591 },
  { "name": "北京邮电大学(宏福校区)", "is_wenli": "理科", "jihua": 33, "luqu": 33, "min_score": 640 },
  { "name": "北京大学医学部", "is_wenli": "理科", "jihua": 33, "luqu": 33, "min_score": 696 },
  { "name": "中国海洋大学[较高收费]", "is_wenli": "理科", "jihua": 32, "luqu": 32, "min_score": 598 },
  { "name": "深圳技术大学", "is_wenli": "理科", "jihua": 32, "luqu": 32, "min_score": 612 },
  { "name": "空军军医大学", "is_wenli": "理科", "jihua": 32, "luqu": 32, "min_score": 627 },
  { "name": "北京交通大学(威海校区)", "is_wenli": "理科", "jihua": 32, "luqu": 32, "min_score": 611 },
  { "name": "中国科学院大学", "is_wenli": "理科", "jihua": 31, "luqu": 31, "min_score": 687 },
  { "name": "徐州医科大学", "is_wenli": "理科", "jihua": 30, "luqu": 31, "min_score": 595 },
  { "name": "西南大学(荣昌校区)", "is_wenli": "理科", "jihua": 31, "luqu": 31, "min_score": 572 },
  { "name": "中国科学技术大学", "is_wenli": "理科", "jihua": 30, "luqu": 30, "min_score": 690 },
  { "name": "长安大学[较高收费]", "is_wenli": "理科", "jihua": 30, "luqu": 30, "min_score": 586 },
  { "name": "新乡学院", "is_wenli": "理科", "jihua": 30, "luqu": 30, "min_score": 544 },
  { "name": "西安电子科技大学[较高收费]", "is_wenli": "理科", "jihua": 30, "luqu": 30, "min_score": 633 },
  { "name": "天津师范大学[较高收费]", "is_wenli": "理科", "jihua": 30, "luqu": 30, "min_score": 593 },
  { "name": "湖北汽车工业学院", "is_wenli": "理科", "jihua": 20, "luqu": 30, "min_score": 544 },
  { "name": "广州大学", "is_wenli": "理科", "jihua": 30, "luqu": 30, "min_score": 609 },
  { "name": "上海海洋大学[较高收费]", "is_wenli": "理科", "jihua": 29, "luqu": 29, "min_score": 592 },
  { "name": "北京建筑大学", "is_wenli": "理科", "jihua": 29, "luqu": 29, "min_score": 603 },
  { "name": "北京大学", "is_wenli": "理科", "jihua": 29, "luqu": 29, "min_score": 703 },
  { "name": "中国人民警察大学", "is_wenli": "理科", "jihua": 28, "luqu": 28, "min_score": 567 },
  { "name": "西安医学院", "is_wenli": "理科", "jihua": 22, "luqu": 27, "min_score": 590 },
  { "name": "赣南医学院", "is_wenli": "理科", "jihua": 27, "luqu": 27, "min_score": 588 },
  { "name": "北京语言大学", "is_wenli": "理科", "jihua": 27, "luqu": 27, "min_score": 613 },
  { "name": "安徽农业大学", "is_wenli": "理科", "jihua": 27, "luqu": 27, "min_score": 550 },
  { "name": "西北大学", "is_wenli": "理科", "jihua": 26, "luqu": 26, "min_score": 636 },
  { "name": "福建农林大学", "is_wenli": "理科", "jihua": 26, "luqu": 26, "min_score": 562 },
  { "name": "东北大学秦皇岛分校[较高收费]", "is_wenli": "理科", "jihua": 25, "luqu": 26, "min_score": 617 },
  { "name": "首都医科大学", "is_wenli": "理科", "jihua": 25, "luqu": 25, "min_score": 650 },
  { "name": "首都经济贸易大学", "is_wenli": "理科", "jihua": 24, "luqu": 25, "min_score": 606 },
  { "name": "上海中医药大学", "is_wenli": "理科", "jihua": 25, "luqu": 25, "min_score": 602 },
  { "name": "北方民族大学", "is_wenli": "理科", "jihua": 25, "luqu": 25, "min_score": 545 },
  { "name": "西南医科大学", "is_wenli": "理科", "jihua": 24, "luqu": 24, "min_score": 611 },
  { "name": "西华师范大学", "is_wenli": "理科", "jihua": 24, "luqu": 24, "min_score": 563 },
  { "name": "上海应用技术大学[较高收费]", "is_wenli": "理科", "jihua": 23, "luqu": 23, "min_score": 570 },
  { "name": "陆军军医大学", "is_wenli": "理科", "jihua": 23, "luqu": 23, "min_score": 607 },
  { "name": "兰州大学[较高收费]", "is_wenli": "理科", "jihua": 23, "luqu": 23, "min_score": 624 },
  { "name": "暨南大学[其他单列专业]", "is_wenli": "理科", "jihua": 20, "luqu": 23, "min_score": 612 },
  { "name": "上海海事大学[较高收费]", "is_wenli": "理科", "jihua": 22, "luqu": 22, "min_score": 600 },
  { "name": "广西医科大学", "is_wenli": "理科", "jihua": 22, "luqu": 22, "min_score": 598 },
  { "name": "北京物资学院", "is_wenli": "理科", "jihua": 22, "luqu": 22, "min_score": 599 },
  { "name": "北京理工大学珠海学院", "is_wenli": "理科", "jihua": 26, "luqu": 22, "min_score": 545 },
  { "name": "北京第二外国语学院", "is_wenli": "理科", "jihua": 22, "luqu": 22, "min_score": 592 },
  { "name": "中央民族大学", "is_wenli": "理科", "jihua": 21, "luqu": 21, "min_score": 644 },
  { "name": "湖北工业大学[较高收费]", "is_wenli": "理科", "jihua": 35, "luqu": 21, "min_score": 545 },
  { "name": "广州中医药大学", "is_wenli": "理科", "jihua": 21, "luqu": 21, "min_score": 593 },
  { "name": "广州医科大学", "is_wenli": "理科", "jihua": 21, "luqu": 21, "min_score": 580 },
  { "name": "东北电力大学[较高收费]", "is_wenli": "理科", "jihua": 21, "luqu": 21, "min_score": 568 },
  { "name": "安徽大学[较高收费]", "is_wenli": "理科", "jihua": 21, "luqu": 21, "min_score": 592 },
  { "name": "浙江工商大学[较高收费]", "is_wenli": "理科", "jihua": 20, "luqu": 20, "min_score": 579 },
  { "name": "西南财经大学[较高收费]", "is_wenli": "理科", "jihua": 20, "luqu": 20, "min_score": 609 },
  { "name": "西北工业大学[较高收费]", "is_wenli": "理科", "jihua": 20, "luqu": 20, "min_score": 629 },
  { "name": "陕西科技大学[较高收费]", "is_wenli": "理科", "jihua": 15, "luqu": 20, "min_score": 557 },
  { "name": "山西医科大学", "is_wenli": "理科", "jihua": 20, "luqu": 20, "min_score": 622 },
  { "name": "宁夏医科大学", "is_wenli": "理科", "jihua": 20, "luqu": 20, "min_score": 593 },
  { "name": "内蒙古科技大学", "is_wenli": "理科", "jihua": 20, "luqu": 20, "min_score": 558 },
  { "name": "吉林大学[其他单列专业]", "is_wenli": "理科", "jihua": 20, "luqu": 20, "min_score": 650 },
  { "name": "河北工业大学", "is_wenli": "理科", "jihua": 20, "luqu": 20, "min_score": 632 },
  { "name": "大连理工大学(盘锦校区)[较高收费]", "is_wenli": "理科", "jihua": 20, "luqu": 20, "min_score": 621 },
  { "name": "成都医学院", "is_wenli": "理科", "jihua": 20, "luqu": 20, "min_score": 606 },
  { "name": "北京化工大学[较高收费]", "is_wenli": "理科", "jihua": 20, "luqu": 20, "min_score": 614 },
  { "name": "西南民族大学", "is_wenli": "理科", "jihua": 19, "luqu": 19, "min_score": 595 },
  { "name": "山西财经大学", "is_wenli": "理科", "jihua": 19, "luqu": 19, "min_score": 596 },
  { "name": "青岛科技大学[较高收费]", "is_wenli": "理科", "jihua": 19, "luqu": 19, "min_score": 561 },
  { "name": "海南大学[较高收费]", "is_wenli": "理科", "jihua": 17, "luqu": 19, "min_score": 588 },
  { "name": "重庆医科大学", "is_wenli": "理科", "jihua": 18, "luqu": 18, "min_score": 623 },
  { "name": "温州肯恩大学", "is_wenli": "理科", "jihua": 31, "luqu": 18, "min_score": 546 },
  { "name": "天津科技大学[较高收费]", "is_wenli": "理科", "jihua": 18, "luqu": 18, "min_score": 584 },
  { "name": "南京审计大学[较高收费]", "is_wenli": "理科", "jihua": 18, "luqu": 18, "min_score": 599 },
  { "name": "福建师范大学[较高收费]", "is_wenli": "理科", "jihua": 18, "luqu": 18, "min_score": 569 },
  { "name": "成都中医药大学", "is_wenli": "理科", "jihua": 18, "luqu": 18, "min_score": 571 },
  { "name": "浙江工商大学", "is_wenli": "理科", "jihua": 17, "luqu": 17, "min_score": 606 },
  { "name": "西南交通大学[较高收费]", "is_wenli": "理科", "jihua": 15, "luqu": 17, "min_score": 624 },
  { "name": "武汉理工大学[其他单列专业]", "is_wenli": "理科", "jihua": 17, "luqu": 17, "min_score": 613 },
  { "name": "湖州师范学院", "is_wenli": "理科", "jihua": 17, "luqu": 17, "min_score": 574 },
  { "name": "湖南工业大学[其他单列专业]", "is_wenli": "理科", "jihua": 10, "luqu": 17, "min_score": 556 },
  { "name": "东北财经大学", "is_wenli": "理科", "jihua": 17, "luqu": 17, "min_score": 614 },
  { "name": "大连民族大学[仅招少数民族]", "is_wenli": "理科", "jihua": 17, "luqu": 17, "min_score": 559 },
  { "name": "中南大学[较高收费]", "is_wenli": "理科", "jihua": 16, "luqu": 16, "min_score": 637 },
  { "name": "浙江财经大学", "is_wenli": "理科", "jihua": 16, "luqu": 16, "min_score": 611 },
  { "name": "浙江财经大学", "is_wenli": "理科", "jihua": 16, "luqu": 16, "min_score": 611 },
  { "name": "西北政法大学", "is_wenli": "理科", "jihua": 16, "luqu": 16, "min_score": 623 },
  { "name": "武汉理工大学[较高收费]", "is_wenli": "理科", "jihua": 16, "luqu": 16, "min_score": 630 },
  { "name": "四川轻化工大学(原四川理工学院)", "is_wenli": "理科", "jihua": 16, "luqu": 16, "min_score": 569 },
  { "name": "上海外国语大学", "is_wenli": "理科", "jihua": 16, "luqu": 16, "min_score": 645 },
  { "name": "广东外语外贸大学", "is_wenli": "理科", "jihua": 16, "luqu": 16, "min_score": 609 },
  { "name": "北京外国语大学", "is_wenli": "理科", "jihua": 16, "luqu": 16, "min_score": 650 },
  { "name": "遵义医科大学(原遵义医学院)", "is_wenli": "理科", "jihua": 15, "luqu": 15, "min_score": 606 },
  { "name": "武汉工程大学[较高收费]", "is_wenli": "理科", "jihua": 15, "luqu": 15, "min_score": 549 },
  { "name": "四川外国语大学", "is_wenli": "理科", "jihua": 15, "luqu": 15, "min_score": 591 },
  { "name": "厦门大学[护理]", "is_wenli": "理科", "jihua": 15, "luqu": 15, "min_score": 647 },
  { "name": "江西中医药大学", "is_wenli": "理科", "jihua": 14, "luqu": 15, "min_score": 583 },
  { "name": "海南大学[其他单列专业]", "is_wenli": "理科", "jihua": 15, "luqu": 15, "min_score": 611 },
  { "name": "广东医科大学", "is_wenli": "理科", "jihua": 15, "luqu": 15, "min_score": 622 },
  { "name": "大连理工大学[较高收费]", "is_wenli": "理科", "jihua": 15, "luqu": 15, "min_score": 638 },
  { "name": "大连海事大学[较高收费]", "is_wenli": "理科", "jihua": 15, "luqu": 15, "min_score": 602 },
  { "name": "北京交通大学[较高收费]", "is_wenli": "理科", "jihua": 15, "luqu": 15, "min_score": 635 },
  { "name": "四川大学[较高收费]", "is_wenli": "理科", "jihua": 14, "luqu": 14, "min_score": 638 },
  { "name": "南京信息工程大学[较高收费]", "is_wenli": "理科", "jihua": 14, "luqu": 14, "min_score": 604 },
  { "name": "昆明医科大学", "is_wenli": "理科", "jihua": 14, "luqu": 14, "min_score": 621 },
  { "name": "哈尔滨工程大学[其他单列专业]", "is_wenli": "理科", "jihua": 14, "luqu": 14, "min_score": 586 },
  { "name": "哈尔滨工程大学[较高收费]", "is_wenli": "理科", "jihua": 14, "luqu": 14, "min_score": 572 },
  { "name": "东北财经大学[较高收费]", "is_wenli": "理科", "jihua": 14, "luqu": 14, "min_score": 593 },
  { "name": "浙江师范大学", "is_wenli": "理科", "jihua": 13, "luqu": 13, "min_score": 547 },
  { "name": "西北农林科技大学[较高收费]", "is_wenli": "理科", "jihua": 13, "luqu": 13, "min_score": 600 },
  { "name": "吉林大学[护理]", "is_wenli": "理科", "jihua": 13, "luqu": 13, "min_score": 589 },
  { "name": "甘肃政法大学(原甘肃政法学院)", "is_wenli": "理科", "jihua": 13, "luqu": 13, "min_score": 565 },
  { "name": "四川大学[护理]", "is_wenli": "理科", "jihua": 12, "luqu": 12, "min_score": 601 },
  { "name": "首都师范大学", "is_wenli": "理科", "jihua": 12, "luqu": 12, "min_score": 627 },
  { "name": "上海交通大学", "is_wenli": "理科", "jihua": 8, "luqu": 12, "min_score": 699 },
  { "name": "南京工程学院[较高收费]", "is_wenli": "理科", "jihua": 12, "luqu": 12, "min_score": 576 },
  { "name": "吉首大学", "is_wenli": "理科", "jihua": 12, "luqu": 12, "min_score": 576 },
  { "name": "华南师范大学", "is_wenli": "理科", "jihua": 12, "luqu": 12, "min_score": 630 },
  { "name": "大连民族大学", "is_wenli": "理科", "jihua": 7, "luqu": 12, "min_score": 544 },
  { "name": "浙江大学医学院", "is_wenli": "理科", "jihua": 11, "luqu": 11, "min_score": 681 },
  { "name": "南京医科大学", "is_wenli": "理科", "jihua": 10, "luqu": 11, "min_score": 606 },
  { "name": "河北医科大学", "is_wenli": "理科", "jihua": 11, "luqu": 11, "min_score": 635 },
  { "name": "甘肃中医药大学", "is_wenli": "理科", "jihua": 11, "luqu": 11, "min_score": 578 },
  { "name": "北京工业大学", "is_wenli": "理科", "jihua": 9, "luqu": 11, "min_score": 643 },
  { "name": "重庆邮电大学[较高收费]", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 609 },
  { "name": "中央财经大学[较高收费]", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 555 },
  { "name": "中国矿业大学[较高收费]", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 605 },
  { "name": "长江大学[较高收费]", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 564 },
  { "name": "西北大学[较高收费]", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 611 },
  { "name": "西安邮电大学[较高收费]", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 602 },
  { "name": "西安理工大学[较高收费]", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 582 },
  { "name": "石家庄铁道大学[较高收费]", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 583 },
  { "name": "上海对外经贸大学[较高收费]", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 604 },
  { "name": "上海电力大学[较高收费]", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 614 },
  { "name": "兰州理工大学[较高收费]", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 576 },
  { "name": "河海大学[其他单列专业]", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 590 },
  { "name": "杭州电子科技大学[较高收费]", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 621 },
  { "name": "广东以色列理工学院", "is_wenli": "理科", "jihua": 8, "luqu": 10, "min_score": 547 },
  { "name": "福州大学[较高收费]", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 615 },
  { "name": "东南大学[护理]", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 631 },
  { "name": "大连理工大学[其他单列专业]", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 639 },
  { "name": "蚌埠医学院", "is_wenli": "理科", "jihua": 10, "luqu": 10, "min_score": 601 },
  { "name": "中国农业大学[较高收费]", "is_wenli": "理科", "jihua": 9, "luqu": 9, "min_score": 617 },
  { "name": "贵州大学[较高收费]", "is_wenli": "理科", "jihua": 9, "luqu": 9, "min_score": 571 },
  { "name": "北京联合大学", "is_wenli": "理科", "jihua": 7, "luqu": 9, "min_score": 597 },
  { "name": "重庆交通大学[较高收费]", "is_wenli": "理科", "jihua": 8, "luqu": 8, "min_score": 590 },
  { "name": "长沙理工大学[较高收费]", "is_wenli": "理科", "jihua": 8, "luqu": 8, "min_score": 589 },
  { "name": "西安外国语大学", "is_wenli": "理科", "jihua": 7, "luqu": 8, "min_score": 612 },
  { "name": "西安科技大学[较高收费]", "is_wenli": "理科", "jihua": 8, "luqu": 8, "min_score": 591 },
  { "name": "同济大学[较高收费]", "is_wenli": "理科", "jihua": 8, "luqu": 8, "min_score": 671 },
  { "name": "山东大学[护理]", "is_wenli": "理科", "jihua": 8, "luqu": 8, "min_score": 613 },
  { "name": "吉林大学[较高收费]", "is_wenli": "理科", "jihua": 8, "luqu": 8, "min_score": 629 },
  { "name": "华中农业大学[较高收费]", "is_wenli": "理科", "jihua": 8, "luqu": 8, "min_score": 599 },
  { "name": "复旦大学", "is_wenli": "理科", "jihua": 8, "luqu": 8, "min_score": 696 },
  { "name": "电子科技大学(沙河校区)[护理]", "is_wenli": "理科", "jihua": 8, "luqu": 8, "min_score": 638 },
  { "name": "遵义医科大学(原遵义医学院)[异地校区]", "is_wenli": "理科", "jihua": 7, "luqu": 7, "min_score": 612 },
  { "name": "重庆大学[较高收费]", "is_wenli": "理科", "jihua": 7, "luqu": 7, "min_score": 641 },
  { "name": "苏州科技大学[较高收费]", "is_wenli": "理科", "jihua": 7, "luqu": 7, "min_score": 576 },
  { "name": "上海海关学院", "is_wenli": "理科", "jihua": 7, "luqu": 7, "min_score": 612 },
  { "name": "中国社会科学院大学", "is_wenli": "理科", "jihua": 6, "luqu": 6, "min_score": 660 },
  { "name": "中国地质大学(武汉)[其他单列专业]", "is_wenli": "理科", "jihua": 6, "luqu": 6, "min_score": 575 },
  { "name": "中国地质大学(武汉)[较高收费]", "is_wenli": "理科", "jihua": 6, "luqu": 6, "min_score": 615 },
  { "name": "浙江师范大学[较高收费]", "is_wenli": "理科", "jihua": 6, "luqu": 6, "min_score": 589 },
  { "name": "西安建筑科技大学[较高收费]", "is_wenli": "理科", "jihua": 6, "luqu": 6, "min_score": 596 },
  { "name": "上海交通大学医学院", "is_wenli": "理科", "jihua": 5, "luqu": 6, "min_score": 695 },
  { "name": "贵州大学[其他单列专业]", "is_wenli": "理科", "jihua": 6, "luqu": 6, "min_score": 575 },
  { "name": "福州大学[其他单列专业]", "is_wenli": "理科", "jihua": 6, "luqu": 6, "min_score": 599 },
  { "name": "北京工业大学[较高收费]", "is_wenli": "理科", "jihua": 6, "luqu": 6, "min_score": 627 },
  { "name": "安徽医科大学", "is_wenli": "理科", "jihua": 6, "luqu": 6, "min_score": 637 },
  { "name": "重庆医科大学[较高收费]", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 627 },
  { "name": "中山大学[护理]", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 609 },
  { "name": "浙江理工大学[较高收费]", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 587 },
  { "name": "浙江科技学院[较高收费]", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 561 },
  { "name": "湘潭大学[较高收费]", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 589 },
  { "name": "武汉大学[其他单列专业]", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 653 },
  { "name": "武汉大学[护理]", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 622 },
  { "name": "天津外国语大学[较高收费]", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 575 },
  { "name": "天津财经大学[较高收费]", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 604 },
  { "name": "南开大学[较高收费]", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 650 },
  { "name": "南方医科大学[护理]", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 578 },
  { "name": "湖南理工学院", "is_wenli": "理科", "jihua": 3, "luqu": 5, "min_score": 563 },
  { "name": "河海大学[较高收费]", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 620 },
  { "name": "东北师范大学[较高收费]", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 614 },
  { "name": "北京理工大学[较高收费]", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 597 },
  { "name": "北京工商大学[较高收费]", "is_wenli": "理科", "jihua": 5, "luqu": 5, "min_score": 573 },
  { "name": "中央民族大学[较高收费]", "is_wenli": "理科", "jihua": 4, "luqu": 4, "min_score": 616 },
  { "name": "中国医科大学[较高收费]", "is_wenli": "理科", "jihua": 4, "luqu": 4, "min_score": 595 },
  { "name": "西北民族大学", "is_wenli": "理科", "jihua": 4, "luqu": 4, "min_score": 581 },
  { "name": "天津中医药大学[较高收费]", "is_wenli": "理科", "jihua": 4, "luqu": 4, "min_score": 585 },
  { "name": "山东科技大学[较高收费]", "is_wenli": "理科", "jihua": 4, "luqu": 4, "min_score": 587 },
  { "name": "南京林业大学[较高收费]", "is_wenli": "理科", "jihua": 4, "luqu": 4, "min_score": 590 },
  { "name": "吉林财经大学[较高收费]", "is_wenli": "理科", "jihua": 4, "luqu": 4, "min_score": 581 },
  { "name": "合肥工业大学[较高收费]", "is_wenli": "理科", "jihua": 4, "luqu": 4, "min_score": 614 },
  { "name": "北京外国语大学[较高收费]", "is_wenli": "理科", "jihua": 3, "luqu": 4, "min_score": 639 },
  { "name": "北京林业大学[较高收费]", "is_wenli": "理科", "jihua": 4, "luqu": 4, "min_score": 555 },
  { "name": "北方工业大学[较高收费]", "is_wenli": "理科", "jihua": 3, "luqu": 4, "min_score": 592 },
  { "name": "安徽理工大学[较高收费]", "is_wenli": "理科", "jihua": 4, "luqu": 4, "min_score": 571 },
  { "name": "重庆师范大学[较高收费]", "is_wenli": "理科", "jihua": 3, "luqu": 3, "min_score": 573 },
  { "name": "中山大学[单列专业]", "is_wenli": "理科", "jihua": 3, "luqu": 3, "min_score": 639 },
  { "name": "中国人民大学(苏州校区)", "is_wenli": "理科", "jihua": 3, "luqu": 3, "min_score": 667 },
  { "name": "浙江财经大学[较高收费]", "is_wenli": "理科", "jihua": 3, "luqu": 3, "min_score": 603 },
  { "name": "西南政法大学[较高收费]", "is_wenli": "理科", "jihua": 3, "luqu": 3, "min_score": 626 },
  { "name": "苏州大学[较高收费]", "is_wenli": "理科", "jihua": 3, "luqu": 3, "min_score": 625 },
  { "name": "上海中医药大学[较高收费]", "is_wenli": "理科", "jihua": 3, "luqu": 3, "min_score": 610 },
  { "name": "三峡大学[较高收费]", "is_wenli": "理科", "jihua": 3, "luqu": 3, "min_score": 600 },
  { "name": "江南大学[较高收费]", "is_wenli": "理科", "jihua": 3, "luqu": 3, "min_score": 613 },
  { "name": "暨南大学[较高收费]", "is_wenli": "理科", "jihua": 3, "luqu": 3, "min_score": 630 },
  { "name": "华东理工大学[较高收费]", "is_wenli": "理科", "jihua": 3, "luqu": 3, "min_score": 634 },
  { "name": "河北师范大学[较高收费]", "is_wenli": "理科", "jihua": 3, "luqu": 3, "min_score": 573 },
  { "name": "复旦大学医学院", "is_wenli": "理科", "jihua": 3, "luqu": 3, "min_score": 693 },
  { "name": "北京大学医学部[其他单列专业]", "is_wenli": "理科", "jihua": 3, "luqu": 3, "min_score": 693 },
  { "name": "浙江农林大学[较高收费]", "is_wenli": "理科", "jihua": 2, "luqu": 2, "min_score": 570 },
  { "name": "延边大学[较高收费]", "is_wenli": "理科", "jihua": 2, "luqu": 2, "min_score": 590 },
  { "name": "西南交通大学[其他单列专业]", "is_wenli": "理科", "jihua": 2, "luqu": 2, "min_score": 550 },
  { "name": "西安外国语大学[较高收费]", "is_wenli": "理科", "jihua": 2, "luqu": 2, "min_score": 578 },
  { "name": "四川外国语大学[较高收费]", "is_wenli": "理科", "jihua": 2, "luqu": 2, "min_score": 602 },
  { "name": "山西中医药大学", "is_wenli": "理科", "jihua": 2, "luqu": 2, "min_score": 597 },
  { "name": "宁波大学[较高收费]", "is_wenli": "理科", "jihua": 2, "luqu": 2, "min_score": 579 },
  { "name": "湖南农业大学[较高收费]", "is_wenli": "理科", "jihua": 2, "luqu": 2, "min_score": 574 },
  { "name": "常州大学[较高收费]", "is_wenli": "理科", "jihua": 2, "luqu": 2, "min_score": 573 },
]