module.exports = {
  "高职专业类名称": '就业率',
  "化工技术类": "93",
  "船舶与海洋工程装备类": "92.7",
  "电力技术类": "92.5",
  "机电设备类": "92.3",
  "道路运输类": "92.3",
  "临床医学类": "92.3",
  "市政工程类": "92.1",
  "机械设计制造类": "92",
  "土建施工类": "91.4",
  "自动化类": "90.9",
  "汽车制造类": "90.7",
  "环境保护类": "90.5",
  "药品制造类": "90.5",
  "食品药品管理类": "90.5",
  "建设工程管理类": "90.3",
  // "城市轨道交通类": "90.2",
  // "畜牧业类": "90.1",
  // "通信类": "89.6",
  // "建筑设计类": "89.5",
  // "林业类": "89.4",
  // "医学技术类": "89.4",
  // "公共管理类": "89.4",
  // "电子商务类": "89.1",
  // "测绘地理信息类": "89.1",
  // "公共事业类": "89.1",
  // "物流类": "88.8",
  // "建筑设备类": "88.8",
  // "经济贸易类": "88.7",
  // "农业类": "88.7",
  // "电子信息类": "88.6",
  // "房地产类": "88.5",
  // "铁道运输类": "88.5",
  // "药学类": "88.5",
  // "食品工业类": "88.4",
  // "广播影视类": "88.3",
  // "语言类": "88.1",
  // "康复治疗类": "88.1",
  // "公共服务类": "88",
  // "餐饮类": "87.9",
  // "市场营销类": "87.9",
  // "艺术设计类": "87.8",
  // "航空运输类": "87.7",
  // "金融类": "87.6",
  // "旅游类": "87.2",
  // "教育类": "87.1",
  // "财务会计类": "86.9",
  // "工商管理类": "86.9",
  // "计算机类": "86.6",
  // "水上运输类": "86.3",
  // "护理类": "84.5",
  // "表演艺术类": "83.6",
  // "全国高职": "87.7",
}