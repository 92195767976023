module.exports = {
  "行业名称": "就业比例",
  "中小学教育机构": "7.1",
  "软件开发业": "3.6",
  "综合医院": "2.8",
  "发电、输电业": "2.6",
  "铁路、道路、隧道和桥梁工程建筑业": "2.3",
  "其他金融投资业": "2.1",
  "半导体和其他电子元件制造业": "1.9",
  "药品和医药制造业": "1.9",
  "其他制造业": "1.8",
  "储蓄信用中介": "1.7",
  "教育辅助服务业": "1.7",
  "其他各级党政机关": "1.6",
  // "会计、审计与税务服务业": "1.5",
  // "建筑基础、结构、楼房外观承建业": "1.4",
  // "司法、执法部门（公检法）": "1.4",
  // "其他培训学校和机构": "1.4",
  // "其他文体娱乐和休闲产业": "1.3",
  // "通信设备制造业": "1.3",
  // "其他信息服务业": "1.2",
  // "幼儿园与学前教育机构": "1.2",
  // "互联网平台服务业（工业互联网平台、电商平台等）": "1.2",
  // "中国人民银行、保监会和证监会": "1.1",
  // "其他公共管理服务组织": "1.1",
  // "计算机及外围设备制造业": "1",
  // "住宅建筑施工业": "1",
  // "非住宅建筑施工业": "0.9",
  // "电气设备制造业": "0.9",
  // "汽车整车制造业": "0.9",
  // "居民服务业": "0.9",
  // "保险代理、经销、其他保险相关业": "0.8",
  // "数据处理、存储、计算、加工等相关服务业": "0.8",
  // "医疗设备及用品制造业": "0.8",
  // "其他化工产品制造业": "0.8",
  // "基层医疗卫生服务机构": "0.7",
  // "各级党政领导机构及人大、政协": "0.7",
  // "工业成套设备制造业": "0.7",
  // "建筑装修业": "0.7",
  // "互联网信息服务业（搜索、网游、音视频、新闻服务等）": "0.7",
  // "物流仓储业": "0.7",
  // "基层群众自治组织（含村委会、居委会等）": "0.6",
  // "互联网零售业": "0.6",
  // "百货零售业": "0.6",
  // "其他通用机械设备制造业": "0.6",
  // "法律、知识产权服务业": "0.6",
  // "广告及相关服务业": "0.6",
  // "中等职业教育机构": "0.6",
  // "本科院校": "0.6",
  // "办公室行政服务业": "0.6",
  // "汽车零件制造业": "0.6",
  // "建筑、工程及相关咨询服务业": "0.6",
}