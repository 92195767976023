module.exports = {
  "行业名称": "就业比例",
  "文员": "4.8",
  "会计": "3.6",
  "护士": "3",
  "电子商务专员": "2.1",
  "客服专员": "2.1",
  "幼儿教师": "2",
  "营业员": "1.7",
  "餐饮服务生": "1.5",
  "医生助理": "1.4",
  "各类销售服务人员": "1.3",
  "建筑技术人员": "1.3",
  "室内设计师": "1.2",
  "化工厂系统操作人员": "1.1",
  "小学教师": "1",
  // "新媒体策划、编辑、运营人员": "0.9",
  // "施工工程技术人员": "0.9",
  // "行政秘书和行政助理": "0.8",
  // "测量技术人员": "0.8",
  // "推销员": "0.8",
  // "教育培训人员": "0.7",
  // "互联网开发人员": "0.7",
  // "旅店服务人员": "0.7",
  // "电厂操作人员": "0.7",
  // "房地产经纪人": "0.7",
  // "其他社区和村镇工作人员": "0.7",
  // "信息支持与服务人员": "0.6",
  // "电气技术人员": "0.6",
  // "档案管理员": "0.6",
  // "化学技术人员": "0.6",
  // "辅警": "0.6",
  // "银行柜员": "0.6",
  // "计算机程序员": "0.6",
  // "地图制图与印刷工程技术人员": "0.6",
  // "工业工程技术人员": "0.6",
  // "收银员": "0.6",
  // "包装设计师": "0.5",
  // "餐饮服务主管": "0.5",
  // "网上商家": "0.5",
  // "招聘专职人员": "0.5",
  // "医学和临床实验室技术人员": "0.5",
  // "土木建筑工程技术人员": "0.5",
  // "销售经理": "0.5",
  // "电气工程技术人员": "0.5",
  // "平面设计人员": "0.5",
  // "车身修理技术人员": "0.5",
  // "工程造价人员": "0.5",
  // "运营经理": "0.5",
  // "数据录入员": "0.5",
  // "半导体加工人员": "0.5",
  // "出纳员": "0.5",

}