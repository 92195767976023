<template>
  <div class="form">
    <div class="titel">{{ title }}</div>
    <div class="content">
      <div :id="title" :style="{
      width: '100%',
      height: '140px',
      border: '1px solid #f4f4f4',
    }" />
    </div>

  </div>
</template>

<script>
export default {
  name: '',
  props: [
    "title",
    "dataLists"
  ],
  watch: {
    dataLists() {
      let nameList = []
      let moneyList = []

      for (let key in this.dataLists) {
        nameList.push(key);
        moneyList.push(Number(this.dataLists[key]));
      }
      nameList = nameList.splice(1, 5)
      moneyList = moneyList.splice(1, 5)

      this.toEcharts(nameList, moneyList)
    }
  },
  data() {
    return {
      // title: 'rtests',
      nameList: [],
      percentageList: []

    };
  },
  methods: {
    toEcharts(nameList, moneyList) {
      var option = {
        color: ["#3398DB"],
        xAxis: {
          type: "category",
          data: nameList
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          top: '5%',
          left: '3%', //默认10%
          right: '4%', //默认10%
          bottom: '3%', //默认60
          containLabel: true,
          show: false,
        },

        yAxis: [{
          type: "value",
          splitLine: false
        }],
        series: [
          {
            name: '金额',
            barWidth: "40%",
            type: 'bar',
            data: moneyList
          }
        ]
      };

      this.drawChart(option);
    },

    drawChart(option) {
      //引入echarts
      let echarts = require("echarts");
      let myChart = echarts.init(document.getElementById(this.title));
      myChart.setOption(option);
    },
  }
};
</script>

<style scoped lang='less'>
.form {
  width: 350px;
  background: white;
  border-radius: 5px;
  height: 212px;
}

.titel {
  text-align: center;
  padding: 25px 0;
}

.content {
  margin: 0 auto;
}
</style>
