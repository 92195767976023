module.exports = {
  "专业就业率排行": '就业率',
  "能源动力类": "94.2",
  "电气类": "93.5",
  "土木类": "92.3",
  "仪器类": "91.9",
  "交通运输类": "91.9",
  "公共卫生与预防医学类": "91.9",
  "安全科学与工程类": "91.8",
  "物流管理与工程类": "91.4",
  "材料类": "91.1",
  "管理科学与工程类": "91.1",
  "机械类": "91",
  "护理学类": "90.7",
  "轻工类": "90.5",
  // "自动化类": "90.4",
  // "地理科学类": "90.3",
  // "矿业类": "90.3",
  // "化学类": "88",
  // "马克思主义理论类": "87.9",
  // "体育学类": "87.9",
  // "生物工程类": "87.2",
  // "林学类": "87.2",
  // "计算机类": "86.6",
  // "教育学类": "86.5",
  // "财政学类": "85.9",
  // "设计学类": "85.9",
  // "数学类": "85.1",
  // "新闻传播学类": "84.8",
  // "经济与贸易类": "84.7",
  // "临床医学类": "84.6",
  // "旅游管理类": "84.6",
  // "历史学类": "84.2",
  // "口腔医学类": "84.1"
}