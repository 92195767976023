module.exports = {
  "高职专业名称": '工作与专业相关度',
  "铁道机车": "87",
  "铁道供电技术": "83",
  "动车组检修技术": "82",
  "园艺技术": "80",
  "国际贸易实务": "80",
  "铁道工程技术": "80",
  "供用电技术": "80",
  "铁道交通运营管理": "80",
  "物业管理": "79",
  "服装设计与工艺": "78",
  "动物医学": "78",
  "园林工程技术": "78",
  "工业过程自动化技术": "78",
  // "市场营销": "78",
  // "畜牧兽医": "78",
  // "工业分析技术": "78",
  // "石油化工技术": "78",
  // "信息安全与管理": "77",
  // "药品经营与管理": "77",
  // "城市轨道交通工程技术": "77",
  // "建筑智能化工程技术": "77",
  // "酒店管理": "77",
  // "空中乘务": "77",
  // "发电厂及电力系统": "77",
  // "口腔医学": "77",
  // "视觉传播设计与制作": "77",
  // "体育教育": "76",
  // "投资与理财": "76",
  // "数控技术": "76",
  // "电力系统自动化技术": "76",
  // "全国高职": "75",
}
