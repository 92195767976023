module.exports = [
  {
    "specialty_code": "0809",
    "name": "计算机类",
    "is_wenli": "理科",
    "jihua": "2236",
    "baokao": "8832",
    "luqu": "2304",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080901",
    "name": "计算机科学与技术",
    "is_wenli": "理科",
    "jihua": "2140",
    "baokao": "8616",
    "luqu": "2157",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080902",
    "name": "软件工程",
    "is_wenli": "理科",
    "jihua": "2384",
    "baokao": "5720",
    "luqu": "2388",
    "pici": "本科一批"
  },
  {
    "specialty_code": "100201K",
    "name": "临床医学",
    "is_wenli": "理科",
    "jihua": "2208",
    "baokao": "5712",
    "luqu": "2207",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080601",
    "name": "电气工程及其自动化",
    "is_wenli": "理科",
    "jihua": "1390",
    "baokao": "4911",
    "luqu": "1410",
    "pici": "本科一批"
  },
  {
    "specialty_code": "00600",
    "name": "工科试验班",
    "is_wenli": "理科",
    "jihua": "1503",
    "baokao": "3641",
    "luqu": "1511",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0807",
    "name": "电子信息类",
    "is_wenli": "理科",
    "jihua": "1806",
    "baokao": "3473",
    "luqu": "1857",
    "pici": "本科一批"
  },
  {
    "specialty_code": "070101",
    "name": "数学与应用数学",
    "is_wenli": "理科",
    "jihua": "1045",
    "baokao": "2750",
    "luqu": "1048",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0802",
    "name": "机械类",
    "is_wenli": "理科",
    "jihua": "2244",
    "baokao": "1712",
    "luqu": "2254",
    "pici": "本科一批"
  },
  {
    "specialty_code": "100901",
    "name": "法医学",
    "is_wenli": "理科",
    "jihua": "106",
    "baokao": "1710",
    "luqu": "107",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080717",
    "name": "人工智能",
    "is_wenli": "理科",
    "jihua": "1071",
    "baokao": "1636",
    "luqu": "1076",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080701",
    "name": "电子信息工程",
    "is_wenli": "理科",
    "jihua": "773",
    "baokao": "1550",
    "luqu": "781",
    "pici": "本科一批"
  },
  {
    "specialty_code": "050201",
    "name": "英语",
    "is_wenli": "理科",
    "jihua": "559",
    "baokao": "1533",
    "luqu": "561",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080801",
    "name": "自动化",
    "is_wenli": "理科",
    "jihua": "998",
    "baokao": "1407",
    "luqu": "1000",
    "pici": "本科一批"
  },
  {
    "specialty_code": "100301",
    "name": "口腔医学",
    "is_wenli": "理科",
    "jihua": "239",
    "baokao": "1407",
    "luqu": "241",
    "pici": "本科一批"
  },
  {
    "specialty_code": "030101",
    "name": "法学",
    "is_wenli": "理科",
    "jihua": "553",
    "baokao": "1385",
    "luqu": "556",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0808",
    "name": "自动化类",
    "is_wenli": "理科",
    "jihua": "985",
    "baokao": "1360",
    "luqu": "1019",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080910",
    "name": "数据科学与大数据技术",
    "is_wenli": "理科",
    "jihua": "1606",
    "baokao": "1339",
    "luqu": "1613",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0701",
    "name": "数学类",
    "is_wenli": "理科",
    "jihua": "847",
    "baokao": "1332",
    "luqu": "868",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080202",
    "name": "机械设计制造及其自动化",
    "is_wenli": "理科",
    "jihua": "1072",
    "baokao": "1306",
    "luqu": "1085",
    "pici": "本科一批"
  },
  {
    "specialty_code": "101101",
    "name": "护理学",
    "is_wenli": "理科",
    "jihua": "899",
    "baokao": "1254",
    "luqu": "893",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120203",
    "name": "会计学",
    "is_wenli": "理科",
    "jihua": "823",
    "baokao": "1203",
    "luqu": "823",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08090F",
    "name": "计算机科学与技术(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "532",
    "baokao": "1082",
    "luqu": "534",
    "pici": "本科一批"
  },
  {
    "specialty_code": "1002",
    "name": "临床医学类",
    "is_wenli": "理科",
    "jihua": "613",
    "baokao": "1018",
    "luqu": "607",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0710",
    "name": "生物科学类",
    "is_wenli": "理科",
    "jihua": "721",
    "baokao": "1012",
    "luqu": "730",
    "pici": "本科一批"
  },
  {
    "specialty_code": "071001",
    "name": "生物科学",
    "is_wenli": "理科",
    "jihua": "784",
    "baokao": "924",
    "luqu": "795",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0201",
    "name": "经济学类",
    "is_wenli": "理科",
    "jihua": "678",
    "baokao": "909",
    "luqu": "680",
    "pici": "本科一批"
  },
  {
    "specialty_code": "070301",
    "name": "化学",
    "is_wenli": "理科",
    "jihua": "717",
    "baokao": "820",
    "luqu": "726",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080703",
    "name": "通信工程",
    "is_wenli": "理科",
    "jihua": "503",
    "baokao": "807",
    "luqu": "504",
    "pici": "本科一批"
  },
  {
    "specialty_code": "070201",
    "name": "物理学",
    "is_wenli": "理科",
    "jihua": "652",
    "baokao": "757",
    "luqu": "661",
    "pici": "本科一批"
  },
  {
    "specialty_code": "100501",
    "name": "中医学",
    "is_wenli": "理科",
    "jihua": "374",
    "baokao": "737",
    "luqu": "374",
    "pici": "本科一批"
  },
  {
    "specialty_code": "070102",
    "name": "信息与计算科学",
    "is_wenli": "理科",
    "jihua": "444",
    "baokao": "714",
    "luqu": "447",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080501",
    "name": "能源与动力工程",
    "is_wenli": "理科",
    "jihua": "849",
    "baokao": "712",
    "luqu": "852",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080702",
    "name": "电子科学与技术",
    "is_wenli": "理科",
    "jihua": "306",
    "baokao": "662",
    "luqu": "308",
    "pici": "本科一批"
  },
  {
    "specialty_code": "1202",
    "name": "工商管理类",
    "is_wenli": "理科",
    "jihua": "1478",
    "baokao": "623",
    "luqu": "1466",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080905",
    "name": "物联网工程",
    "is_wenli": "理科",
    "jihua": "590",
    "baokao": "593",
    "luqu": "594",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0060",
    "name": "工科试验班类",
    "is_wenli": "理科",
    "jihua": "205",
    "baokao": "555",
    "luqu": "195",
    "pici": "本科一批"
  },
  {
    "specialty_code": "00700",
    "name": "理科试验班",
    "is_wenli": "理科",
    "jihua": "245",
    "baokao": "540",
    "luqu": "259",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0806",
    "name": "电气类",
    "is_wenli": "理科",
    "jihua": "272",
    "baokao": "530",
    "luqu": "277",
    "pici": "本科一批"
  },
  {
    "specialty_code": "020301",
    "name": "金融学",
    "is_wenli": "理科",
    "jihua": "434",
    "baokao": "519",
    "luqu": "438",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080705",
    "name": "光电信息科学与工程",
    "is_wenli": "理科",
    "jihua": "341",
    "baokao": "507",
    "luqu": "367",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080714",
    "name": "电子信息科学与技术",
    "is_wenli": "理科",
    "jihua": "261",
    "baokao": "499",
    "luqu": "262",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0203",
    "name": "金融学类",
    "is_wenli": "理科",
    "jihua": "323",
    "baokao": "490",
    "luqu": "322",
    "pici": "本科一批"
  },
  {
    "specialty_code": "090108",
    "name": "烟草",
    "is_wenli": "理科",
    "jihua": "63",
    "baokao": "468",
    "luqu": "63",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081101",
    "name": "水利水电工程",
    "is_wenli": "理科",
    "jihua": "117",
    "baokao": "456",
    "luqu": "117",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0804",
    "name": "材料类",
    "is_wenli": "理科",
    "jihua": "1584",
    "baokao": "428",
    "luqu": "1586",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0703",
    "name": "化学类",
    "is_wenli": "理科",
    "jihua": "586",
    "baokao": "414",
    "luqu": "588",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0070",
    "name": "理科试验班类",
    "is_wenli": "理科",
    "jihua": "157",
    "baokao": "409",
    "luqu": "164",
    "pici": "本科一批"
  },
  {
    "specialty_code": "020101",
    "name": "经济学",
    "is_wenli": "理科",
    "jihua": "192",
    "baokao": "390",
    "luqu": "192",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08060F",
    "name": "电气工程及其自动化(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "258",
    "baokao": "390",
    "luqu": "259",
    "pici": "本科一批"
  },
  {
    "specialty_code": "00800",
    "name": "医学试验班",
    "is_wenli": "理科",
    "jihua": "87",
    "baokao": "382",
    "luqu": "87",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0904",
    "name": "动物医学类",
    "is_wenli": "理科",
    "jihua": "398",
    "baokao": "382",
    "luqu": "404",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080906",
    "name": "数字媒体技术",
    "is_wenli": "理科",
    "jihua": "126",
    "baokao": "379",
    "luqu": "131",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0827",
    "name": "食品科学与工程类",
    "is_wenli": "理科",
    "jihua": "746",
    "baokao": "373",
    "luqu": "749",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0820",
    "name": "航空航天类",
    "is_wenli": "理科",
    "jihua": "164",
    "baokao": "368",
    "luqu": "166",
    "pici": "本科一批"
  },
  {
    "specialty_code": "070302",
    "name": "应用化学",
    "is_wenli": "理科",
    "jihua": "659",
    "baokao": "363",
    "luqu": "665",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08070F",
    "name": "电子信息工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "336",
    "baokao": "343",
    "luqu": "336",
    "pici": "本科一批"
  },
  {
    "specialty_code": "100401",
    "name": "预防医学",
    "is_wenli": "理科",
    "jihua": "422",
    "baokao": "340",
    "luqu": "416",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120102",
    "name": "信息管理与信息系统",
    "is_wenli": "理科",
    "jihua": "336",
    "baokao": "325",
    "luqu": "340",
    "pici": "本科一批"
  },
  {
    "specialty_code": "100101",
    "name": "基础医学",
    "is_wenli": "理科",
    "jihua": "128",
    "baokao": "320",
    "luqu": "138",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080213",
    "name": "智能制造工程",
    "is_wenli": "理科",
    "jihua": "460",
    "baokao": "312",
    "luqu": "463",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081001",
    "name": "土木工程",
    "is_wenli": "理科",
    "jihua": "1034",
    "baokao": "312",
    "luqu": "1037",
    "pici": "本科一批"
  },
  {
    "specialty_code": "100701",
    "name": "药学",
    "is_wenli": "理科",
    "jihua": "528",
    "baokao": "312",
    "luqu": "536",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081301",
    "name": "化学工程与工艺",
    "is_wenli": "理科",
    "jihua": "689",
    "baokao": "287",
    "luqu": "697",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080401",
    "name": "材料科学与工程",
    "is_wenli": "理科",
    "jihua": "324",
    "baokao": "285",
    "luqu": "325",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0301",
    "name": "法学类",
    "is_wenli": "理科",
    "jihua": "147",
    "baokao": "277",
    "luqu": "146",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080904",
    "name": "信息安全",
    "is_wenli": "理科",
    "jihua": "207",
    "baokao": "277",
    "luqu": "208",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080207",
    "name": "车辆工程",
    "is_wenli": "理科",
    "jihua": "343",
    "baokao": "264",
    "luqu": "361",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080803",
    "name": "机器人工程",
    "is_wenli": "理科",
    "jihua": "315",
    "baokao": "253",
    "luqu": "315",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0702",
    "name": "物理学类",
    "is_wenli": "理科",
    "jihua": "184",
    "baokao": "251",
    "luqu": "188",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080903",
    "name": "网络工程",
    "is_wenli": "理科",
    "jihua": "400",
    "baokao": "251",
    "luqu": "408",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0807H",
    "name": "电子信息类(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "93",
    "baokao": "250",
    "luqu": "101",
    "pici": "本科一批"
  },
  {
    "specialty_code": "100204",
    "name": "眼视光医学",
    "is_wenli": "理科",
    "jihua": "88",
    "baokao": "249",
    "luqu": "93",
    "pici": "本科一批"
  },
  {
    "specialty_code": "100203",
    "name": "医学影像学",
    "is_wenli": "理科",
    "jihua": "196",
    "baokao": "243",
    "luqu": "196",
    "pici": "本科一批"
  },
  {
    "specialty_code": "100202",
    "name": "麻醉学",
    "is_wenli": "理科",
    "jihua": "168",
    "baokao": "238",
    "luqu": "168",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080503",
    "name": "新能源科学与工程",
    "is_wenli": "理科",
    "jihua": "173",
    "baokao": "235",
    "luqu": "175",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120701",
    "name": "工业工程",
    "is_wenli": "理科",
    "jihua": "232",
    "baokao": "233",
    "luqu": "232",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080301",
    "name": "测控技术与仪器",
    "is_wenli": "理科",
    "jihua": "526",
    "baokao": "229",
    "luqu": "519",
    "pici": "本科一批"
  },
  {
    "specialty_code": "070202",
    "name": "应用物理学",
    "is_wenli": "理科",
    "jihua": "337",
    "baokao": "219",
    "luqu": "342",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080414",
    "name": "新能源材料与器件",
    "is_wenli": "理科",
    "jihua": "234",
    "baokao": "212",
    "luqu": "235",
    "pici": "本科一批"
  },
  {
    "specialty_code": "083001",
    "name": "生物工程",
    "is_wenli": "理科",
    "jihua": "500",
    "baokao": "212",
    "luqu": "500",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080704",
    "name": "微电子科学与工程",
    "is_wenli": "理科",
    "jihua": "188",
    "baokao": "210",
    "luqu": "195",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0502",
    "name": "外国语言文学类",
    "is_wenli": "理科",
    "jihua": "271",
    "baokao": "204",
    "luqu": "271",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0813",
    "name": "化工与制药类",
    "is_wenli": "理科",
    "jihua": "326",
    "baokao": "201",
    "luqu": "329",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0805",
    "name": "能源动力类",
    "is_wenli": "理科",
    "jihua": "229",
    "baokao": "200",
    "luqu": "225",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08020G",
    "name": "机械设计制造及其自动化(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "139",
    "baokao": "199",
    "luqu": "143",
    "pici": "本科一批"
  },
  {
    "specialty_code": "071201",
    "name": "统计学",
    "is_wenli": "理科",
    "jihua": "133",
    "baokao": "197",
    "luqu": "134",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081302",
    "name": "制药工程",
    "is_wenli": "理科",
    "jihua": "373",
    "baokao": "191",
    "luqu": "385",
    "pici": "本科一批"
  },
  {
    "specialty_code": "1008",
    "name": "中药学类",
    "is_wenli": "理科",
    "jihua": "610",
    "baokao": "191",
    "luqu": "610",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082701",
    "name": "食品科学与工程",
    "is_wenli": "理科",
    "jihua": "318",
    "baokao": "190",
    "luqu": "324",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08071I",
    "name": "电子信息科学与技术(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "62",
    "baokao": "189",
    "luqu": "62",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0810",
    "name": "土木类",
    "is_wenli": "理科",
    "jihua": "914",
    "baokao": "186",
    "luqu": "907",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082901",
    "name": "安全工程",
    "is_wenli": "理科",
    "jihua": "415",
    "baokao": "184",
    "luqu": "419",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0503",
    "name": "新闻传播学类",
    "is_wenli": "理科",
    "jihua": "170",
    "baokao": "181",
    "luqu": "172",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0828",
    "name": "建筑类",
    "is_wenli": "理科",
    "jihua": "520",
    "baokao": "177",
    "luqu": "505",
    "pici": "本科一批"
  },
  {
    "specialty_code": "100601",
    "name": "中西医临床医学",
    "is_wenli": "理科",
    "jihua": "222",
    "baokao": "170",
    "luqu": "223",
    "pici": "本科一批"
  },
  {
    "specialty_code": "071002",
    "name": "生物技术",
    "is_wenli": "理科",
    "jihua": "288",
    "baokao": "166",
    "luqu": "288",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0901",
    "name": "植物生产类",
    "is_wenli": "理科",
    "jihua": "748",
    "baokao": "166",
    "luqu": "743",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080710",
    "name": "集成电路设计与集成系统",
    "is_wenli": "理科",
    "jihua": "69",
    "baokao": "163",
    "luqu": "69",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0401",
    "name": "教育学类",
    "is_wenli": "理科",
    "jihua": "103",
    "baokao": "162",
    "luqu": "101",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080203",
    "name": "材料成型及控制工程",
    "is_wenli": "理科",
    "jihua": "621",
    "baokao": "160",
    "luqu": "623",
    "pici": "本科一批"
  },
  {
    "specialty_code": "101001",
    "name": "医学检验技术",
    "is_wenli": "理科",
    "jihua": "209",
    "baokao": "156",
    "luqu": "209",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080201",
    "name": "机械工程",
    "is_wenli": "理科",
    "jihua": "142",
    "baokao": "155",
    "luqu": "147",
    "pici": "本科一批"
  },
  {
    "specialty_code": "101003",
    "name": "医学影像技术",
    "is_wenli": "理科",
    "jihua": "177",
    "baokao": "155",
    "luqu": "179",
    "pici": "本科一批"
  },
  {
    "specialty_code": "071202",
    "name": "应用统计学",
    "is_wenli": "理科",
    "jihua": "167",
    "baokao": "150",
    "luqu": "172",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0202",
    "name": "财政学类",
    "is_wenli": "理科",
    "jihua": "64",
    "baokao": "147",
    "luqu": "66",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081502",
    "name": "石油工程",
    "is_wenli": "理科",
    "jihua": "93",
    "baokao": "147",
    "luqu": "93",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081801",
    "name": "交通运输",
    "is_wenli": "理科",
    "jihua": "144",
    "baokao": "147",
    "luqu": "145",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120204",
    "name": "财务管理",
    "is_wenli": "理科",
    "jihua": "214",
    "baokao": "142",
    "luqu": "214",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080204",
    "name": "机械电子工程",
    "is_wenli": "理科",
    "jihua": "230",
    "baokao": "141",
    "luqu": "231",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080911",
    "name": "网络空间安全",
    "is_wenli": "理科",
    "jihua": "100",
    "baokao": "137",
    "luqu": "100",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120207",
    "name": "审计学",
    "is_wenli": "理科",
    "jihua": "50",
    "baokao": "136",
    "luqu": "50",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0712",
    "name": "统计学类",
    "is_wenli": "理科",
    "jihua": "113",
    "baokao": "130",
    "luqu": "113",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120108",
    "name": "大数据管理与应用",
    "is_wenli": "理科",
    "jihua": "101",
    "baokao": "130",
    "luqu": "104",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080205",
    "name": "工业设计",
    "is_wenli": "理科",
    "jihua": "252",
    "baokao": "123",
    "luqu": "257",
    "pici": "本科一批"
  },
  {
    "specialty_code": "090401",
    "name": "动物医学",
    "is_wenli": "理科",
    "jihua": "87",
    "baokao": "123",
    "luqu": "90",
    "pici": "本科一批"
  },
  {
    "specialty_code": "00201",
    "name": "经济管理试验班",
    "is_wenli": "理科",
    "jihua": "108",
    "baokao": "122",
    "luqu": "95",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120103",
    "name": "工程管理",
    "is_wenli": "理科",
    "jihua": "701",
    "baokao": "122",
    "luqu": "760",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080102",
    "name": "工程力学",
    "is_wenli": "理科",
    "jihua": "224",
    "baokao": "121",
    "luqu": "224",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08070H",
    "name": "通信工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "191",
    "baokao": "121",
    "luqu": "191",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081003",
    "name": "给排水科学与工程",
    "is_wenli": "理科",
    "jihua": "259",
    "baokao": "119",
    "luqu": "264",
    "pici": "本科一批"
  },
  {
    "specialty_code": "02030F",
    "name": "金融学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "33",
    "baokao": "118",
    "luqu": "35",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0825",
    "name": "环境科学与工程类",
    "is_wenli": "理科",
    "jihua": "552",
    "baokao": "118",
    "luqu": "550",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08090G",
    "name": "软件工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "110",
    "baokao": "116",
    "luqu": "110",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08040F",
    "name": "材料科学与工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "157",
    "baokao": "115",
    "luqu": "158",
    "pici": "本科一批"
  },
  {
    "specialty_code": "020401",
    "name": "国际经济与贸易",
    "is_wenli": "理科",
    "jihua": "313",
    "baokao": "114",
    "luqu": "317",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082801",
    "name": "建筑学",
    "is_wenli": "理科",
    "jihua": "229",
    "baokao": "113",
    "luqu": "228",
    "pici": "本科一批"
  },
  {
    "specialty_code": "1007",
    "name": "药学类",
    "is_wenli": "理科",
    "jihua": "197",
    "baokao": "112",
    "luqu": "195",
    "pici": "本科一批"
  },
  {
    "specialty_code": "070501",
    "name": "地理科学",
    "is_wenli": "理科",
    "jihua": "232",
    "baokao": "111",
    "luqu": "241",
    "pici": "本科一批"
  },
  {
    "specialty_code": "100207",
    "name": "儿科学",
    "is_wenli": "理科",
    "jihua": "119",
    "baokao": "111",
    "luqu": "119",
    "pici": "本科一批"
  },
  {
    "specialty_code": "050306",
    "name": "网络与新媒体",
    "is_wenli": "理科",
    "jihua": "40",
    "baokao": "110",
    "luqu": "40",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0811",
    "name": "水利类",
    "is_wenli": "理科",
    "jihua": "182",
    "baokao": "110",
    "luqu": "184",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0818",
    "name": "交通运输类",
    "is_wenli": "理科",
    "jihua": "294",
    "baokao": "110",
    "luqu": "296",
    "pici": "本科一批"
  },
  {
    "specialty_code": "100502",
    "name": "针灸推拿学",
    "is_wenli": "理科",
    "jihua": "251",
    "baokao": "109",
    "luqu": "251",
    "pici": "本科一批"
  },
  {
    "specialty_code": "071102",
    "name": "应用心理学",
    "is_wenli": "理科",
    "jihua": "181",
    "baokao": "108",
    "luqu": "181",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082502",
    "name": "环境工程",
    "is_wenli": "理科",
    "jihua": "631",
    "baokao": "107",
    "luqu": "634",
    "pici": "本科一批"
  },
  {
    "specialty_code": "1201",
    "name": "管理科学与工程类",
    "is_wenli": "理科",
    "jihua": "295",
    "baokao": "107",
    "luqu": "300",
    "pici": "本科一批"
  },
  {
    "specialty_code": "020305",
    "name": "金融数学",
    "is_wenli": "理科",
    "jihua": "199",
    "baokao": "105",
    "luqu": "211",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08080F",
    "name": "自动化(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "195",
    "baokao": "104",
    "luqu": "195",
    "pici": "本科一批"
  },
  {
    "specialty_code": "12020H",
    "name": "会计学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "60",
    "baokao": "103",
    "luqu": "60",
    "pici": "本科一批"
  },
  {
    "specialty_code": "100205",
    "name": "精神医学",
    "is_wenli": "理科",
    "jihua": "112",
    "baokao": "101",
    "luqu": "112",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08100F",
    "name": "土木工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "529",
    "baokao": "98",
    "luqu": "511",
    "pici": "本科一批"
  },
  {
    "specialty_code": "100513",
    "name": "中医骨伤科学",
    "is_wenli": "理科",
    "jihua": "202",
    "baokao": "98",
    "luqu": "202",
    "pici": "本科一批"
  },
  {
    "specialty_code": "050101",
    "name": "汉语言文学",
    "is_wenli": "理科",
    "jihua": "35",
    "baokao": "97",
    "luqu": "35",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081507",
    "name": "智能采矿工程",
    "is_wenli": "理科",
    "jihua": "60",
    "baokao": "96",
    "luqu": "64",
    "pici": "本科一批"
  },
  {
    "specialty_code": "00701",
    "name": "自然科学试验班",
    "is_wenli": "理科",
    "jihua": "34",
    "baokao": "94",
    "luqu": "27",
    "pici": "本科一批"
  },
  {
    "specialty_code": "02040F",
    "name": "国际经济与贸易(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "112",
    "baokao": "92",
    "luqu": "113",
    "pici": "本科一批"
  },
  {
    "specialty_code": "071101",
    "name": "心理学",
    "is_wenli": "理科",
    "jihua": "128",
    "baokao": "92",
    "luqu": "128",
    "pici": "本科一批"
  },
  {
    "specialty_code": "090102",
    "name": "园艺",
    "is_wenli": "理科",
    "jihua": "165",
    "baokao": "92",
    "luqu": "175",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081201",
    "name": "测绘工程",
    "is_wenli": "理科",
    "jihua": "339",
    "baokao": "91",
    "luqu": "342",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080407",
    "name": "高分子材料与工程",
    "is_wenli": "理科",
    "jihua": "199",
    "baokao": "88",
    "luqu": "200",
    "pici": "本科一批"
  },
  {
    "specialty_code": "02010F",
    "name": "经济学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "85",
    "baokao": "86",
    "luqu": "84",
    "pici": "本科一批"
  },
  {
    "specialty_code": "100703",
    "name": "临床药学",
    "is_wenli": "理科",
    "jihua": "151",
    "baokao": "85",
    "luqu": "151",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082601",
    "name": "生物医学工程",
    "is_wenli": "理科",
    "jihua": "185",
    "baokao": "84",
    "luqu": "185",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081002",
    "name": "建筑环境与能源应用工程",
    "is_wenli": "理科",
    "jihua": "386",
    "baokao": "83",
    "luqu": "389",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081202",
    "name": "遥感科学与技术",
    "is_wenli": "理科",
    "jihua": "154",
    "baokao": "80",
    "luqu": "154",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08020J",
    "name": "工业设计(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "25",
    "baokao": "79",
    "luqu": "26",
    "pici": "本科一批"
  },
  {
    "specialty_code": "090112",
    "name": "智慧农业",
    "is_wenli": "理科",
    "jihua": "96",
    "baokao": "79",
    "luqu": "98",
    "pici": "本科一批"
  },
  {
    "specialty_code": "020202",
    "name": "税收学",
    "is_wenli": "理科",
    "jihua": "143",
    "baokao": "78",
    "luqu": "143",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0706",
    "name": "大气科学类",
    "is_wenli": "理科",
    "jihua": "59",
    "baokao": "78",
    "luqu": "59",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081102",
    "name": "水文与水资源工程",
    "is_wenli": "理科",
    "jihua": "71",
    "baokao": "77",
    "luqu": "71",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080405",
    "name": "金属材料工程",
    "is_wenli": "理科",
    "jihua": "72",
    "baokao": "76",
    "luqu": "71",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081802",
    "name": "交通工程",
    "is_wenli": "理科",
    "jihua": "297",
    "baokao": "76",
    "luqu": "298",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0826",
    "name": "生物医学工程类",
    "is_wenli": "理科",
    "jihua": "35",
    "baokao": "75",
    "luqu": "35",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082803",
    "name": "风景园林",
    "is_wenli": "理科",
    "jihua": "160",
    "baokao": "75",
    "luqu": "160",
    "pici": "本科一批"
  },
  {
    "specialty_code": "020102",
    "name": "经济统计学",
    "is_wenli": "理科",
    "jihua": "51",
    "baokao": "74",
    "luqu": "51",
    "pici": "本科一批"
  },
  {
    "specialty_code": "1010",
    "name": "医学技术类",
    "is_wenli": "理科",
    "jihua": "126",
    "baokao": "74",
    "luqu": "130",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120105",
    "name": "工程造价",
    "is_wenli": "理科",
    "jihua": "171",
    "baokao": "74",
    "luqu": "175",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082001",
    "name": "航空航天工程",
    "is_wenli": "理科",
    "jihua": "15",
    "baokao": "73",
    "luqu": "15",
    "pici": "本科一批"
  },
  {
    "specialty_code": "00150",
    "name": "社会科学试验班",
    "is_wenli": "理科",
    "jihua": "17",
    "baokao": "72",
    "luqu": "15",
    "pici": "本科一批"
  },
  {
    "specialty_code": "020302",
    "name": "金融工程",
    "is_wenli": "理科",
    "jihua": "59",
    "baokao": "72",
    "luqu": "59",
    "pici": "本科一批"
  },
  {
    "specialty_code": "071004",
    "name": "生态学",
    "is_wenli": "理科",
    "jihua": "164",
    "baokao": "71",
    "luqu": "159",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0817",
    "name": "轻工类",
    "is_wenli": "理科",
    "jihua": "71",
    "baokao": "71",
    "luqu": "71",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120801",
    "name": "电子商务",
    "is_wenli": "理科",
    "jihua": "322",
    "baokao": "71",
    "luqu": "386",
    "pici": "本科一批"
  },
  {
    "specialty_code": "040104",
    "name": "教育技术学",
    "is_wenli": "理科",
    "jihua": "145",
    "baokao": "69",
    "luqu": "152",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080403",
    "name": "材料化学",
    "is_wenli": "理科",
    "jihua": "120",
    "baokao": "69",
    "luqu": "120",
    "pici": "本科一批"
  },
  {
    "specialty_code": "100801",
    "name": "中药学",
    "is_wenli": "理科",
    "jihua": "178",
    "baokao": "68",
    "luqu": "178",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0705",
    "name": "地理科学类",
    "is_wenli": "理科",
    "jihua": "190",
    "baokao": "64",
    "luqu": "195",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080406",
    "name": "无机非金属材料工程",
    "is_wenli": "理科",
    "jihua": "313",
    "baokao": "64",
    "luqu": "318",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08020F",
    "name": "机械工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "145",
    "baokao": "63",
    "luqu": "146",
    "pici": "本科一批"
  },
  {
    "specialty_code": "1002H",
    "name": "临床医学类(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "30",
    "baokao": "62",
    "luqu": "30",
    "pici": "本科一批"
  },
  {
    "specialty_code": "100206",
    "name": "放射医学",
    "is_wenli": "理科",
    "jihua": "71",
    "baokao": "61",
    "luqu": "71",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080206",
    "name": "过程装备与控制工程",
    "is_wenli": "理科",
    "jihua": "164",
    "baokao": "60",
    "luqu": "170",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080907",
    "name": "智能科学与技术",
    "is_wenli": "理科",
    "jihua": "109",
    "baokao": "60",
    "luqu": "109",
    "pici": "本科一批"
  },
  {
    "specialty_code": "090502",
    "name": "园林",
    "is_wenli": "理科",
    "jihua": "119",
    "baokao": "60",
    "luqu": "113",
    "pici": "本科一批"
  },
  {
    "specialty_code": "07010G",
    "name": "信息与计算科学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "82",
    "baokao": "59",
    "luqu": "82",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082305",
    "name": "农业水利工程",
    "is_wenli": "理科",
    "jihua": "110",
    "baokao": "59",
    "luqu": "112",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0905",
    "name": "林学类",
    "is_wenli": "理科",
    "jihua": "201",
    "baokao": "58",
    "luqu": "201",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0821",
    "name": "兵器类",
    "is_wenli": "理科",
    "jihua": "19",
    "baokao": "57",
    "luqu": "19",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0902",
    "name": "自然保护与环境生态类",
    "is_wenli": "理科",
    "jihua": "33",
    "baokao": "57",
    "luqu": "33",
    "pici": "本科一批"
  },
  {
    "specialty_code": "090301",
    "name": "动物科学",
    "is_wenli": "理科",
    "jihua": "214",
    "baokao": "57",
    "luqu": "215",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081403",
    "name": "资源勘查工程",
    "is_wenli": "理科",
    "jihua": "175",
    "baokao": "56",
    "luqu": "175",
    "pici": "本科一批"
  },
  {
    "specialty_code": "083002",
    "name": "生物制药",
    "is_wenli": "理科",
    "jihua": "109",
    "baokao": "54",
    "luqu": "110",
    "pici": "本科一批"
  },
  {
    "specialty_code": "090103",
    "name": "植物保护",
    "is_wenli": "理科",
    "jihua": "251",
    "baokao": "53",
    "luqu": "256",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082002",
    "name": "飞行器设计与工程",
    "is_wenli": "理科",
    "jihua": "30",
    "baokao": "52",
    "luqu": "30",
    "pici": "本科一批"
  },
  {
    "specialty_code": "00602",
    "name": "技术科学试验班",
    "is_wenli": "理科",
    "jihua": "37",
    "baokao": "51",
    "luqu": "35",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120601",
    "name": "物流管理",
    "is_wenli": "理科",
    "jihua": "236",
    "baokao": "51",
    "luqu": "243",
    "pici": "本科一批"
  },
  {
    "specialty_code": "101005",
    "name": "康复治疗学",
    "is_wenli": "理科",
    "jihua": "215",
    "baokao": "50",
    "luqu": "215",
    "pici": "本科一批"
  },
  {
    "specialty_code": "050301",
    "name": "新闻学",
    "is_wenli": "理科",
    "jihua": "21",
    "baokao": "49",
    "luqu": "21",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08020I",
    "name": "机械电子工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "143",
    "baokao": "49",
    "luqu": "143",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081504",
    "name": "油气储运工程",
    "is_wenli": "理科",
    "jihua": "67",
    "baokao": "49",
    "luqu": "67",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0823",
    "name": "农业工程类",
    "is_wenli": "理科",
    "jihua": "300",
    "baokao": "49",
    "luqu": "300",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120201",
    "name": "工商管理",
    "is_wenli": "理科",
    "jihua": "164",
    "baokao": "49",
    "luqu": "168",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0710H",
    "name": "生物科学类(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "27",
    "baokao": "48",
    "luqu": "30",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080602",
    "name": "智能电网信息工程",
    "is_wenli": "理科",
    "jihua": "56",
    "baokao": "48",
    "luqu": "60",
    "pici": "本科一批"
  },
  {
    "specialty_code": "070504",
    "name": "地理信息科学",
    "is_wenli": "理科",
    "jihua": "201",
    "baokao": "47",
    "luqu": "201",
    "pici": "本科一批"
  },
  {
    "specialty_code": "071003",
    "name": "生物信息学",
    "is_wenli": "理科",
    "jihua": "41",
    "baokao": "47",
    "luqu": "41",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082004",
    "name": "飞行器动力工程",
    "is_wenli": "理科",
    "jihua": "62",
    "baokao": "47",
    "luqu": "62",
    "pici": "本科一批"
  },
  {
    "specialty_code": "090101",
    "name": "农学",
    "is_wenli": "理科",
    "jihua": "66",
    "baokao": "47",
    "luqu": "68",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0204",
    "name": "经济与贸易类",
    "is_wenli": "理科",
    "jihua": "126",
    "baokao": "46",
    "luqu": "123",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080604",
    "name": "电气工程与智能控制",
    "is_wenli": "理科",
    "jihua": "24",
    "baokao": "45",
    "luqu": "25",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0812",
    "name": "测绘类",
    "is_wenli": "理科",
    "jihua": "104",
    "baokao": "45",
    "luqu": "104",
    "pici": "本科一批"
  },
  {
    "specialty_code": "1004",
    "name": "公共卫生与预防医学类",
    "is_wenli": "理科",
    "jihua": "22",
    "baokao": "44",
    "luqu": "22",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080216",
    "name": "新能源汽车工程",
    "is_wenli": "理科",
    "jihua": "11",
    "baokao": "43",
    "luqu": "11",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080412",
    "name": "功能材料",
    "is_wenli": "理科",
    "jihua": "69",
    "baokao": "43",
    "luqu": "69",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08280F",
    "name": "建筑学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "45",
    "baokao": "42",
    "luqu": "34",
    "pici": "本科一批"
  },
  {
    "specialty_code": "1204",
    "name": "公共管理类",
    "is_wenli": "理科",
    "jihua": "313",
    "baokao": "42",
    "luqu": "326",
    "pici": "本科一批"
  },
  {
    "specialty_code": "020308",
    "name": "精算学",
    "is_wenli": "理科",
    "jihua": "20",
    "baokao": "41",
    "luqu": "20",
    "pici": "本科一批"
  },
  {
    "specialty_code": "090501",
    "name": "林学",
    "is_wenli": "理科",
    "jihua": "94",
    "baokao": "41",
    "luqu": "96",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080706",
    "name": "信息工程",
    "is_wenli": "理科",
    "jihua": "14",
    "baokao": "39",
    "luqu": "14",
    "pici": "本科一批"
  },
  {
    "specialty_code": "100510",
    "name": "中医康复学",
    "is_wenli": "理科",
    "jihua": "136",
    "baokao": "39",
    "luqu": "135",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080504",
    "name": "储能科学与工程",
    "is_wenli": "理科",
    "jihua": "93",
    "baokao": "38",
    "luqu": "93",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080908",
    "name": "空间信息与数字技术",
    "is_wenli": "理科",
    "jihua": "35",
    "baokao": "38",
    "luqu": "36",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082201",
    "name": "核工程与核技术",
    "is_wenli": "理科",
    "jihua": "60",
    "baokao": "38",
    "luqu": "63",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08250H",
    "name": "环境科学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "85",
    "baokao": "38",
    "luqu": "81",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081006",
    "name": "道路桥梁与渡河工程",
    "is_wenli": "理科",
    "jihua": "147",
    "baokao": "37",
    "luqu": "147",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081304",
    "name": "能源化学工程",
    "is_wenli": "理科",
    "jihua": "75",
    "baokao": "37",
    "luqu": "75",
    "pici": "本科一批"
  },
  {
    "specialty_code": "083102",
    "name": "消防工程",
    "is_wenli": "理科",
    "jihua": "124",
    "baokao": "37",
    "luqu": "124",
    "pici": "本科一批"
  },
  {
    "specialty_code": "040206",
    "name": "运动康复",
    "is_wenli": "理科",
    "jihua": "14",
    "baokao": "36",
    "luqu": "14",
    "pici": "本科一批"
  },
  {
    "specialty_code": "050303",
    "name": "广告学",
    "is_wenli": "理科",
    "jihua": "39",
    "baokao": "36",
    "luqu": "44",
    "pici": "本科一批"
  },
  {
    "specialty_code": "07010F",
    "name": "数学与应用数学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "36",
    "baokao": "36",
    "luqu": "35",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081104",
    "name": "水务工程",
    "is_wenli": "理科",
    "jihua": "72",
    "baokao": "36",
    "luqu": "73",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082702",
    "name": "食品质量与安全",
    "is_wenli": "理科",
    "jihua": "210",
    "baokao": "36",
    "luqu": "210",
    "pici": "本科一批"
  },
  {
    "specialty_code": "050261",
    "name": "翻译",
    "is_wenli": "理科",
    "jihua": "47",
    "baokao": "35",
    "luqu": "47",
    "pici": "本科一批"
  },
  {
    "specialty_code": "1202H",
    "name": "工商管理类(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "41",
    "baokao": "35",
    "luqu": "38",
    "pici": "本科一批"
  },
  {
    "specialty_code": "1207",
    "name": "工业工程类",
    "is_wenli": "理科",
    "jihua": "87",
    "baokao": "35",
    "luqu": "87",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08020L",
    "name": "车辆工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "23",
    "baokao": "34",
    "luqu": "23",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0822",
    "name": "核工程类",
    "is_wenli": "理科",
    "jihua": "24",
    "baokao": "33",
    "luqu": "23",
    "pici": "本科一批"
  },
  {
    "specialty_code": "070602",
    "name": "应用气象学",
    "is_wenli": "理科",
    "jihua": "17",
    "baokao": "32",
    "luqu": "17",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081008",
    "name": "智能建造",
    "is_wenli": "理科",
    "jihua": "67",
    "baokao": "32",
    "luqu": "68",
    "pici": "本科一批"
  },
  {
    "specialty_code": "010101",
    "name": "哲学",
    "is_wenli": "理科",
    "jihua": "29",
    "baokao": "31",
    "luqu": "27",
    "pici": "本科一批"
  },
  {
    "specialty_code": "02031E",
    "name": "金融科技(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "3",
    "baokao": "31",
    "luqu": "3",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081103",
    "name": "港口航道与海岸工程",
    "is_wenli": "理科",
    "jihua": "72",
    "baokao": "31",
    "luqu": "72",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0830",
    "name": "生物工程类",
    "is_wenli": "理科",
    "jihua": "127",
    "baokao": "31",
    "luqu": "125",
    "pici": "本科一批"
  },
  {
    "specialty_code": "100512",
    "name": "中医儿科学",
    "is_wenli": "理科",
    "jihua": "51",
    "baokao": "31",
    "luqu": "51",
    "pici": "本科一批"
  },
  {
    "specialty_code": "050103",
    "name": "汉语国际教育",
    "is_wenli": "理科",
    "jihua": "39",
    "baokao": "30",
    "luqu": "39",
    "pici": "本科一批"
  },
  {
    "specialty_code": "050262",
    "name": "商务英语",
    "is_wenli": "理科",
    "jihua": "26",
    "baokao": "30",
    "luqu": "26",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08270F",
    "name": "食品科学与工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "93",
    "baokao": "30",
    "luqu": "93",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08300F",
    "name": "生物工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "124",
    "baokao": "30",
    "luqu": "124",
    "pici": "本科一批"
  },
  {
    "specialty_code": "090201",
    "name": "农业资源与环境",
    "is_wenli": "理科",
    "jihua": "164",
    "baokao": "30",
    "luqu": "165",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081901",
    "name": "船舶与海洋工程",
    "is_wenli": "理科",
    "jihua": "39",
    "baokao": "29",
    "luqu": "39",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120202",
    "name": "市场营销",
    "is_wenli": "理科",
    "jihua": "122",
    "baokao": "29",
    "luqu": "124",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120404",
    "name": "土地资源管理",
    "is_wenli": "理科",
    "jihua": "98",
    "baokao": "29",
    "luqu": "97",
    "pici": "本科一批"
  },
  {
    "specialty_code": "050207",
    "name": "日语",
    "is_wenli": "理科",
    "jihua": "112",
    "baokao": "27",
    "luqu": "109",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08050F",
    "name": "能源与动力工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "34",
    "baokao": "27",
    "luqu": "34",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08070J",
    "name": "光电信息科学与工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "35",
    "baokao": "27",
    "luqu": "35",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0819",
    "name": "海洋工程类",
    "is_wenli": "理科",
    "jihua": "66",
    "baokao": "27",
    "luqu": "66",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082003",
    "name": "飞行器制造工程",
    "is_wenli": "理科",
    "jihua": "41",
    "baokao": "27",
    "luqu": "41",
    "pici": "本科一批"
  },
  {
    "specialty_code": "030102",
    "name": "知识产权",
    "is_wenli": "理科",
    "jihua": "60",
    "baokao": "26",
    "luqu": "60",
    "pici": "本科一批"
  },
  {
    "specialty_code": "020201",
    "name": "财政学",
    "is_wenli": "理科",
    "jihua": "24",
    "baokao": "25",
    "luqu": "24",
    "pici": "本科一批"
  },
  {
    "specialty_code": "02010H",
    "name": "国民经济管理(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "1",
    "baokao": "24",
    "luqu": "1",
    "pici": "本科一批"
  },
  {
    "specialty_code": "050302",
    "name": "广播电视学",
    "is_wenli": "理科",
    "jihua": "17",
    "baokao": "24",
    "luqu": "17",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120206",
    "name": "人力资源管理",
    "is_wenli": "理科",
    "jihua": "171",
    "baokao": "24",
    "luqu": "175",
    "pici": "本科一批"
  },
  {
    "specialty_code": "020310",
    "name": "金融科技",
    "is_wenli": "理科",
    "jihua": "21",
    "baokao": "23",
    "luqu": "21",
    "pici": "本科一批"
  },
  {
    "specialty_code": "05020F",
    "name": "英语(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "15",
    "baokao": "23",
    "luqu": "15",
    "pici": "本科一批"
  },
  {
    "specialty_code": "07030G",
    "name": "应用化学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "34",
    "baokao": "23",
    "luqu": "34",
    "pici": "本科一批"
  },
  {
    "specialty_code": "070503",
    "name": "人文地理与城乡规划",
    "is_wenli": "理科",
    "jihua": "133",
    "baokao": "23",
    "luqu": "133",
    "pici": "本科一批"
  },
  {
    "specialty_code": "07100G",
    "name": "生物技术(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "32",
    "baokao": "23",
    "luqu": "32",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0814",
    "name": "地质类",
    "is_wenli": "理科",
    "jihua": "100",
    "baokao": "23",
    "luqu": "100",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082503",
    "name": "环境科学",
    "is_wenli": "理科",
    "jihua": "103",
    "baokao": "23",
    "luqu": "105",
    "pici": "本科一批"
  },
  {
    "specialty_code": "040205",
    "name": "运动人体科学",
    "is_wenli": "理科",
    "jihua": "59",
    "baokao": "22",
    "luqu": "59",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0711",
    "name": "心理学类",
    "is_wenli": "理科",
    "jihua": "20",
    "baokao": "22",
    "luqu": "19",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0803",
    "name": "仪器类",
    "is_wenli": "理科",
    "jihua": "60",
    "baokao": "22",
    "luqu": "63",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080303",
    "name": "智能感知工程",
    "is_wenli": "理科",
    "jihua": "15",
    "baokao": "22",
    "luqu": "17",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081701",
    "name": "轻化工程",
    "is_wenli": "理科",
    "jihua": "118",
    "baokao": "22",
    "luqu": "116",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0903",
    "name": "动物生产类",
    "is_wenli": "理科",
    "jihua": "270",
    "baokao": "22",
    "luqu": "280",
    "pici": "本科一批"
  },
  {
    "specialty_code": "100511",
    "name": "中医养生学",
    "is_wenli": "理科",
    "jihua": "72",
    "baokao": "22",
    "luqu": "72",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080413",
    "name": "纳米材料与技术",
    "is_wenli": "理科",
    "jihua": "28",
    "baokao": "21",
    "luqu": "28",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0809H",
    "name": "计算机类(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "20",
    "baokao": "21",
    "luqu": "20",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081401",
    "name": "地质工程",
    "is_wenli": "理科",
    "jihua": "155",
    "baokao": "21",
    "luqu": "155",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081602",
    "name": "服装设计与工程",
    "is_wenli": "理科",
    "jihua": "101",
    "baokao": "21",
    "luqu": "109",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082802",
    "name": "城乡规划",
    "is_wenli": "理科",
    "jihua": "135",
    "baokao": "21",
    "luqu": "129",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0906",
    "name": "水产类",
    "is_wenli": "理科",
    "jihua": "101",
    "baokao": "21",
    "luqu": "101",
    "pici": "本科一批"
  },
  {
    "specialty_code": "101011",
    "name": "智能医学工程",
    "is_wenli": "理科",
    "jihua": "79",
    "baokao": "21",
    "luqu": "78",
    "pici": "本科一批"
  },
  {
    "specialty_code": "12020F",
    "name": "工商管理(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "56",
    "baokao": "21",
    "luqu": "56",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120703",
    "name": "质量管理工程",
    "is_wenli": "理科",
    "jihua": "7",
    "baokao": "21",
    "luqu": "7",
    "pici": "本科一批"
  },
  {
    "specialty_code": "020402",
    "name": "贸易经济",
    "is_wenli": "理科",
    "jihua": "60",
    "baokao": "20",
    "luqu": "60",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0501",
    "name": "中国语言文学类",
    "is_wenli": "理科",
    "jihua": "8",
    "baokao": "20",
    "luqu": "7",
    "pici": "本科一批"
  },
  {
    "specialty_code": "070701",
    "name": "海洋科学",
    "is_wenli": "理科",
    "jihua": "39",
    "baokao": "20",
    "luqu": "40",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0708",
    "name": "地球物理学类",
    "is_wenli": "理科",
    "jihua": "35",
    "baokao": "20",
    "luqu": "34",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0801",
    "name": "力学类",
    "is_wenli": "理科",
    "jihua": "66",
    "baokao": "20",
    "luqu": "66",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080708",
    "name": "水声工程",
    "is_wenli": "理科",
    "jihua": "26",
    "baokao": "20",
    "luqu": "28",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080711",
    "name": "医学信息工程",
    "is_wenli": "理科",
    "jihua": "56",
    "baokao": "20",
    "luqu": "56",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080802",
    "name": "轨道交通信号与控制",
    "is_wenli": "理科",
    "jihua": "18",
    "baokao": "20",
    "luqu": "18",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08090L",
    "name": "智能科学与技术(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "9",
    "baokao": "20",
    "luqu": "9",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081506",
    "name": "海洋油气工程",
    "is_wenli": "理科",
    "jihua": "27",
    "baokao": "20",
    "luqu": "28",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082009",
    "name": "无人驾驶航空器系统工程",
    "is_wenli": "理科",
    "jihua": "32",
    "baokao": "20",
    "luqu": "32",
    "pici": "本科一批"
  },
  {
    "specialty_code": "07060F",
    "name": "大气科学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "19",
    "luqu": "2",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0709",
    "name": "地质学类",
    "is_wenli": "理科",
    "jihua": "33",
    "baokao": "19",
    "luqu": "34",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082103",
    "name": "探测制导与控制技术",
    "is_wenli": "理科",
    "jihua": "27",
    "baokao": "19",
    "luqu": "27",
    "pici": "本科一批"
  },
  {
    "specialty_code": "100402",
    "name": "食品卫生与营养学",
    "is_wenli": "理科",
    "jihua": "89",
    "baokao": "19",
    "luqu": "89",
    "pici": "本科一批"
  },
  {
    "specialty_code": "100702",
    "name": "药物制剂",
    "is_wenli": "理科",
    "jihua": "103",
    "baokao": "19",
    "luqu": "103",
    "pici": "本科一批"
  },
  {
    "specialty_code": "1209",
    "name": "旅游管理类",
    "is_wenli": "理科",
    "jihua": "113",
    "baokao": "19",
    "luqu": "115",
    "pici": "本科一批"
  },
  {
    "specialty_code": "03010F",
    "name": "法学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "7",
    "baokao": "18",
    "luqu": "7",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081705",
    "name": "化妆品技术与工程",
    "is_wenli": "理科",
    "jihua": "19",
    "baokao": "18",
    "luqu": "19",
    "pici": "本科一批"
  },
  {
    "specialty_code": "090107",
    "name": "茶学",
    "is_wenli": "理科",
    "jihua": "20",
    "baokao": "18",
    "luqu": "22",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120101",
    "name": "管理科学",
    "is_wenli": "理科",
    "jihua": "109",
    "baokao": "18",
    "luqu": "109",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120901",
    "name": "旅游管理",
    "is_wenli": "理科",
    "jihua": "215",
    "baokao": "18",
    "luqu": "213",
    "pici": "本科一批"
  },
  {
    "specialty_code": "07020G",
    "name": "应用物理学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "19",
    "baokao": "17",
    "luqu": "19",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080404",
    "name": "冶金工程",
    "is_wenli": "理科",
    "jihua": "38",
    "baokao": "17",
    "luqu": "40",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081106",
    "name": "智慧水利",
    "is_wenli": "理科",
    "jihua": "8",
    "baokao": "17",
    "luqu": "8",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08140I",
    "name": "地下水科学与工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "17",
    "luqu": "6",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0824",
    "name": "林业工程类",
    "is_wenli": "理科",
    "jihua": "25",
    "baokao": "17",
    "luqu": "25",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120402",
    "name": "行政管理",
    "is_wenli": "理科",
    "jihua": "50",
    "baokao": "17",
    "luqu": "50",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080402",
    "name": "材料物理",
    "is_wenli": "理科",
    "jihua": "50",
    "baokao": "16",
    "luqu": "51",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081005",
    "name": "城市地下空间工程",
    "is_wenli": "理科",
    "jihua": "127",
    "baokao": "16",
    "luqu": "126",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082902",
    "name": "应急技术与管理",
    "is_wenli": "理科",
    "jihua": "56",
    "baokao": "16",
    "luqu": "59",
    "pici": "本科一批"
  },
  {
    "specialty_code": "100403",
    "name": "妇幼保健医学",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "16",
    "luqu": "7",
    "pici": "本科一批"
  },
  {
    "specialty_code": "12010G",
    "name": "信息管理与信息系统(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "37",
    "baokao": "16",
    "luqu": "37",
    "pici": "本科一批"
  },
  {
    "specialty_code": "020303",
    "name": "保险学",
    "is_wenli": "理科",
    "jihua": "28",
    "baokao": "15",
    "luqu": "28",
    "pici": "本科一批"
  },
  {
    "specialty_code": "020304",
    "name": "投资学",
    "is_wenli": "理科",
    "jihua": "39",
    "baokao": "15",
    "luqu": "39",
    "pici": "本科一批"
  },
  {
    "specialty_code": "050204",
    "name": "法语",
    "is_wenli": "理科",
    "jihua": "39",
    "baokao": "15",
    "luqu": "39",
    "pici": "本科一批"
  },
  {
    "specialty_code": "070104",
    "name": "数据计算及应用",
    "is_wenli": "理科",
    "jihua": "9",
    "baokao": "15",
    "luqu": "9",
    "pici": "本科一批"
  },
  {
    "specialty_code": "07120G",
    "name": "应用统计学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "18",
    "baokao": "15",
    "luqu": "18",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08040L",
    "name": "高分子材料与工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "11",
    "baokao": "15",
    "luqu": "11",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08090K",
    "name": "数字媒体技术(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "15",
    "baokao": "15",
    "luqu": "15",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08100K",
    "name": "道路桥梁与渡河工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "10",
    "baokao": "15",
    "luqu": "10",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08130F",
    "name": "化学工程与工艺(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "19",
    "baokao": "15",
    "luqu": "19",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081402",
    "name": "勘查技术与工程",
    "is_wenli": "理科",
    "jihua": "80",
    "baokao": "15",
    "luqu": "80",
    "pici": "本科一批"
  },
  {
    "specialty_code": "10010G",
    "name": "生物医学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "5",
    "baokao": "15",
    "luqu": "5",
    "pici": "本科一批"
  },
  {
    "specialty_code": "10020F",
    "name": "临床医学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "15",
    "luqu": "2",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120602",
    "name": "物流工程",
    "is_wenli": "理科",
    "jihua": "122",
    "baokao": "15",
    "luqu": "125",
    "pici": "本科一批"
  },
  {
    "specialty_code": "050202",
    "name": "俄语",
    "is_wenli": "理科",
    "jihua": "49",
    "baokao": "14",
    "luqu": "49",
    "pici": "本科一批"
  },
  {
    "specialty_code": "060103",
    "name": "考古学",
    "is_wenli": "理科",
    "jihua": "10",
    "baokao": "14",
    "luqu": "9",
    "pici": "本科一批"
  },
  {
    "specialty_code": "070401",
    "name": "天文学",
    "is_wenli": "理科",
    "jihua": "5",
    "baokao": "14",
    "luqu": "4",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08090N",
    "name": "电子与计算机工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "11",
    "baokao": "14",
    "luqu": "16",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081012",
    "name": "智能建造与智慧交通",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "14",
    "luqu": "6",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082007",
    "name": "飞行器适航技术",
    "is_wenli": "理科",
    "jihua": "28",
    "baokao": "14",
    "luqu": "31",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082302",
    "name": "农业机械化及其自动化",
    "is_wenli": "理科",
    "jihua": "31",
    "baokao": "14",
    "luqu": "31",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120301",
    "name": "农林经济管理",
    "is_wenli": "理科",
    "jihua": "41",
    "baokao": "14",
    "luqu": "43",
    "pici": "本科一批"
  },
  {
    "specialty_code": "030302",
    "name": "社会工作",
    "is_wenli": "理科",
    "jihua": "46",
    "baokao": "13",
    "luqu": "46",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0701H",
    "name": "数学类(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "26",
    "baokao": "13",
    "luqu": "30",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080101",
    "name": "理论与应用力学",
    "is_wenli": "理科",
    "jihua": "5",
    "baokao": "13",
    "luqu": "5",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081004",
    "name": "建筑电气与智能化",
    "is_wenli": "理科",
    "jihua": "69",
    "baokao": "13",
    "luqu": "69",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081601",
    "name": "纺织工程",
    "is_wenli": "理科",
    "jihua": "151",
    "baokao": "13",
    "luqu": "149",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082504",
    "name": "环境生态工程",
    "is_wenli": "理科",
    "jihua": "53",
    "baokao": "13",
    "luqu": "53",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120407",
    "name": "交通管理",
    "is_wenli": "理科",
    "jihua": "28",
    "baokao": "13",
    "luqu": "28",
    "pici": "本科一批"
  },
  {
    "specialty_code": "1205",
    "name": "图书情报与档案管理类",
    "is_wenli": "理科",
    "jihua": "40",
    "baokao": "13",
    "luqu": "40",
    "pici": "本科一批"
  },
  {
    "specialty_code": "040101",
    "name": "教育学",
    "is_wenli": "理科",
    "jihua": "18",
    "baokao": "12",
    "luqu": "18",
    "pici": "本科一批"
  },
  {
    "specialty_code": "07110F",
    "name": "心理学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "3",
    "baokao": "12",
    "luqu": "3",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0802H",
    "name": "机械类(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "8",
    "baokao": "12",
    "luqu": "8",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080917",
    "name": "区块链工程",
    "is_wenli": "理科",
    "jihua": "13",
    "baokao": "12",
    "luqu": "13",
    "pici": "本科一批"
  },
  {
    "specialty_code": "1005",
    "name": "中医学类",
    "is_wenli": "理科",
    "jihua": "25",
    "baokao": "12",
    "luqu": "25",
    "pici": "本科一批"
  },
  {
    "specialty_code": "10070F",
    "name": "药学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "123",
    "baokao": "12",
    "luqu": "97",
    "pici": "本科一批"
  },
  {
    "specialty_code": "101013",
    "name": "智能影像工程",
    "is_wenli": "理科",
    "jihua": "45",
    "baokao": "12",
    "luqu": "45",
    "pici": "本科一批"
  },
  {
    "specialty_code": "020109",
    "name": "数字经济",
    "is_wenli": "理科",
    "jihua": "23",
    "baokao": "11",
    "luqu": "23",
    "pici": "本科一批"
  },
  {
    "specialty_code": "07050H",
    "name": "人文地理与城乡规划(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "10",
    "baokao": "11",
    "luqu": "10",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08070K",
    "name": "信息工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "10",
    "baokao": "11",
    "luqu": "10",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081508",
    "name": "碳储科学与工程",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "11",
    "luqu": "4",
    "pici": "本科一批"
  },
  {
    "specialty_code": "090106",
    "name": "设施农业科学与工程",
    "is_wenli": "理科",
    "jihua": "27",
    "baokao": "11",
    "luqu": "27",
    "pici": "本科一批"
  },
  {
    "specialty_code": "090402",
    "name": "动物药学",
    "is_wenli": "理科",
    "jihua": "75",
    "baokao": "11",
    "luqu": "75",
    "pici": "本科一批"
  },
  {
    "specialty_code": "090701",
    "name": "草业科学",
    "is_wenli": "理科",
    "jihua": "36",
    "baokao": "11",
    "luqu": "37",
    "pici": "本科一批"
  },
  {
    "specialty_code": "101007",
    "name": "卫生检验与检疫",
    "is_wenli": "理科",
    "jihua": "89",
    "baokao": "11",
    "luqu": "90",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120205",
    "name": "国际商务",
    "is_wenli": "理科",
    "jihua": "86",
    "baokao": "11",
    "luqu": "84",
    "pici": "本科一批"
  },
  {
    "specialty_code": "12020L",
    "name": "审计学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "11",
    "luqu": "2",
    "pici": "本科一批"
  },
  {
    "specialty_code": "1206",
    "name": "物流管理与工程类",
    "is_wenli": "理科",
    "jihua": "100",
    "baokao": "11",
    "luqu": "100",
    "pici": "本科一批"
  },
  {
    "specialty_code": "040107",
    "name": "小学教育",
    "is_wenli": "理科",
    "jihua": "20",
    "baokao": "10",
    "luqu": "22",
    "pici": "本科一批"
  },
  {
    "specialty_code": "04010K",
    "name": "学前教育(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "26",
    "baokao": "10",
    "luqu": "26",
    "pici": "本科一批"
  },
  {
    "specialty_code": "050304",
    "name": "传播学",
    "is_wenli": "理科",
    "jihua": "8",
    "baokao": "10",
    "luqu": "8",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080214",
    "name": "智能车辆工程",
    "is_wenli": "理科",
    "jihua": "14",
    "baokao": "10",
    "luqu": "14",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08041H",
    "name": "纳米材料与技术(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "10",
    "luqu": "4",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08070M",
    "name": "水声工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "10",
    "luqu": "2",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08090J",
    "name": "物联网工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "9",
    "baokao": "10",
    "luqu": "9",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082501",
    "name": "环境科学与工程",
    "is_wenli": "理科",
    "jihua": "23",
    "baokao": "10",
    "luqu": "23",
    "pici": "本科一批"
  },
  {
    "specialty_code": "090105",
    "name": "种子科学与工程",
    "is_wenli": "理科",
    "jihua": "19",
    "baokao": "10",
    "luqu": "19",
    "pici": "本科一批"
  },
  {
    "specialty_code": "090403",
    "name": "动植物检疫",
    "is_wenli": "理科",
    "jihua": "63",
    "baokao": "10",
    "luqu": "66",
    "pici": "本科一批"
  },
  {
    "specialty_code": "12020I",
    "name": "财务管理(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "20",
    "baokao": "10",
    "luqu": "20",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0303",
    "name": "社会学类",
    "is_wenli": "理科",
    "jihua": "45",
    "baokao": "9",
    "luqu": "45",
    "pici": "本科一批"
  },
  {
    "specialty_code": "070502",
    "name": "自然地理与资源环境",
    "is_wenli": "理科",
    "jihua": "54",
    "baokao": "9",
    "luqu": "54",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080502",
    "name": "能源与环境系统工程",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "9",
    "luqu": "6",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08060I",
    "name": "电气工程与智能控制(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "12",
    "baokao": "9",
    "luqu": "12",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08090H",
    "name": "网络工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "5",
    "baokao": "9",
    "luqu": "5",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081303",
    "name": "资源循环科学与工程",
    "is_wenli": "理科",
    "jihua": "50",
    "baokao": "9",
    "luqu": "57",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082107",
    "name": "信息对抗技术",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "9",
    "luqu": "9",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082306",
    "name": "土地整治工程",
    "is_wenli": "理科",
    "jihua": "70",
    "baokao": "9",
    "luqu": "71",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08250G",
    "name": "环境工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "15",
    "baokao": "9",
    "luqu": "15",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082705",
    "name": "酿酒工程",
    "is_wenli": "理科",
    "jihua": "19",
    "baokao": "9",
    "luqu": "20",
    "pici": "本科一批"
  },
  {
    "specialty_code": "101102",
    "name": "助产学",
    "is_wenli": "理科",
    "jihua": "51",
    "baokao": "9",
    "luqu": "51",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120401",
    "name": "公共事业管理",
    "is_wenli": "理科",
    "jihua": "108",
    "baokao": "9",
    "luqu": "108",
    "pici": "本科一批"
  },
  {
    "specialty_code": "030608",
    "name": "消防指挥",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "8",
    "luqu": "4",
    "pici": "本科一批"
  },
  {
    "specialty_code": "07070F",
    "name": "海洋科学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "8",
    "luqu": "4",
    "pici": "本科一批"
  },
  {
    "specialty_code": "070901",
    "name": "地质学",
    "is_wenli": "理科",
    "jihua": "18",
    "baokao": "8",
    "luqu": "18",
    "pici": "本科一批"
  },
  {
    "specialty_code": "070903",
    "name": "地球信息科学与技术",
    "is_wenli": "理科",
    "jihua": "74",
    "baokao": "8",
    "luqu": "74",
    "pici": "本科一批"
  },
  {
    "specialty_code": "07100F",
    "name": "生物科学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "15",
    "baokao": "8",
    "luqu": "15",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080916",
    "name": "虚拟现实技术",
    "is_wenli": "理科",
    "jihua": "9",
    "baokao": "8",
    "luqu": "9",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0815",
    "name": "矿业类",
    "is_wenli": "理科",
    "jihua": "65",
    "baokao": "8",
    "luqu": "65",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081501",
    "name": "采矿工程",
    "is_wenli": "理科",
    "jihua": "73",
    "baokao": "8",
    "luqu": "73",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0816",
    "name": "纺织类",
    "is_wenli": "理科",
    "jihua": "39",
    "baokao": "8",
    "luqu": "39",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08200G",
    "name": "飞行器设计与工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "8",
    "luqu": "2",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082505",
    "name": "环保设备工程",
    "is_wenli": "理科",
    "jihua": "10",
    "baokao": "8",
    "luqu": "10",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082706",
    "name": "葡萄与葡萄酒工程",
    "is_wenli": "理科",
    "jihua": "26",
    "baokao": "8",
    "luqu": "27",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082710",
    "name": "食品营养与健康",
    "is_wenli": "理科",
    "jihua": "24",
    "baokao": "8",
    "luqu": "24",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082804",
    "name": "历史建筑保护工程",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "8",
    "luqu": "4",
    "pici": "本科一批"
  },
  {
    "specialty_code": "090203",
    "name": "水土保持与荒漠化防治",
    "is_wenli": "理科",
    "jihua": "13",
    "baokao": "8",
    "luqu": "13",
    "pici": "本科一批"
  },
  {
    "specialty_code": "050217",
    "name": "马来语",
    "is_wenli": "理科",
    "jihua": "1",
    "baokao": "7",
    "luqu": "1",
    "pici": "本科一批"
  },
  {
    "specialty_code": "07020F",
    "name": "物理学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "5",
    "baokao": "7",
    "luqu": "5",
    "pici": "本科一批"
  },
  {
    "specialty_code": "07030F",
    "name": "化学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "26",
    "baokao": "7",
    "luqu": "25",
    "pici": "本科一批"
  },
  {
    "specialty_code": "070601",
    "name": "大气科学",
    "is_wenli": "理科",
    "jihua": "15",
    "baokao": "7",
    "luqu": "15",
    "pici": "本科一批"
  },
  {
    "specialty_code": "070702",
    "name": "海洋技术",
    "is_wenli": "理科",
    "jihua": "16",
    "baokao": "7",
    "luqu": "18",
    "pici": "本科一批"
  },
  {
    "specialty_code": "070804",
    "name": "行星科学",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "7",
    "luqu": "2",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080217",
    "name": "增材制造工程",
    "is_wenli": "理科",
    "jihua": "31",
    "baokao": "7",
    "luqu": "17",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08030F",
    "name": "测控技术与仪器(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "34",
    "baokao": "7",
    "luqu": "24",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0806H",
    "name": "电气类(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "18",
    "baokao": "7",
    "luqu": "18",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08100H",
    "name": "给排水科学与工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "29",
    "baokao": "7",
    "luqu": "29",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0813H",
    "name": "化工与制药类(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "7",
    "baokao": "7",
    "luqu": "7",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08190F",
    "name": "船舶与海洋工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "5",
    "baokao": "7",
    "luqu": "5",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082008",
    "name": "飞行器控制与信息工程",
    "is_wenli": "理科",
    "jihua": "3",
    "baokao": "7",
    "luqu": "3",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08200I",
    "name": "飞行器动力工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "30",
    "baokao": "7",
    "luqu": "30",
    "pici": "本科一批"
  },
  {
    "specialty_code": "090601",
    "name": "水产养殖学",
    "is_wenli": "理科",
    "jihua": "54",
    "baokao": "7",
    "luqu": "54",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120208",
    "name": "资产评估",
    "is_wenli": "理科",
    "jihua": "10",
    "baokao": "7",
    "luqu": "10",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0080",
    "name": "医学试验班类",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "6",
    "luqu": "2",
    "pici": "本科一批"
  },
  {
    "specialty_code": "030205",
    "name": "政治学、经济学与哲学",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "6",
    "luqu": "4",
    "pici": "本科一批"
  },
  {
    "specialty_code": "040211",
    "name": "智能体育工程",
    "is_wenli": "理科",
    "jihua": "1",
    "baokao": "6",
    "luqu": "1",
    "pici": "本科一批"
  },
  {
    "specialty_code": "050203",
    "name": "德语",
    "is_wenli": "理科",
    "jihua": "13",
    "baokao": "6",
    "luqu": "12",
    "pici": "本科一批"
  },
  {
    "specialty_code": "07010H",
    "name": "数理基础科学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "7",
    "baokao": "6",
    "luqu": "7",
    "pici": "本科一批"
  },
  {
    "specialty_code": "070801",
    "name": "地球物理学",
    "is_wenli": "理科",
    "jihua": "13",
    "baokao": "6",
    "luqu": "12",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08020M",
    "name": "汽车服务工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "8",
    "baokao": "6",
    "luqu": "8",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081205",
    "name": "地理空间信息工程",
    "is_wenli": "理科",
    "jihua": "9",
    "baokao": "6",
    "luqu": "9",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081503",
    "name": "矿物加工工程",
    "is_wenli": "理科",
    "jihua": "79",
    "baokao": "6",
    "luqu": "82",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081704",
    "name": "香料香精技术与工程",
    "is_wenli": "理科",
    "jihua": "3",
    "baokao": "6",
    "luqu": "3",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08180F",
    "name": "交通运输(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "10",
    "baokao": "6",
    "luqu": "10",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082204",
    "name": "核化工与核燃料工程",
    "is_wenli": "理科",
    "jihua": "5",
    "baokao": "6",
    "luqu": "5",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0828H",
    "name": "建筑类(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "13",
    "baokao": "6",
    "luqu": "20",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0829",
    "name": "安全科学与工程类",
    "is_wenli": "理科",
    "jihua": "36",
    "baokao": "6",
    "luqu": "36",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08290F",
    "name": "安全工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "3",
    "baokao": "6",
    "luqu": "3",
    "pici": "本科一批"
  },
  {
    "specialty_code": "090505",
    "name": "智慧林业",
    "is_wenli": "理科",
    "jihua": "8",
    "baokao": "6",
    "luqu": "8",
    "pici": "本科一批"
  },
  {
    "specialty_code": "101006",
    "name": "口腔医学技术",
    "is_wenli": "理科",
    "jihua": "5",
    "baokao": "6",
    "luqu": "5",
    "pici": "本科一批"
  },
  {
    "specialty_code": "130304",
    "name": "戏剧影视文学",
    "is_wenli": "理科",
    "jihua": "1",
    "baokao": "6",
    "luqu": "1",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0201H",
    "name": "经济学类(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "3",
    "baokao": "5",
    "luqu": "3",
    "pici": "本科一批"
  },
  {
    "specialty_code": "050220",
    "name": "泰语",
    "is_wenli": "理科",
    "jihua": "1",
    "baokao": "5",
    "luqu": "2",
    "pici": "本科一批"
  },
  {
    "specialty_code": "070303",
    "name": "化学生物学",
    "is_wenli": "理科",
    "jihua": "20",
    "baokao": "5",
    "luqu": "20",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08020K",
    "name": "过程装备与控制工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "7",
    "baokao": "5",
    "luqu": "7",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080408",
    "name": "复合材料与工程",
    "is_wenli": "理科",
    "jihua": "15",
    "baokao": "5",
    "luqu": "15",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080719",
    "name": "柔性电子学",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "5",
    "luqu": "6",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081203",
    "name": "导航工程",
    "is_wenli": "理科",
    "jihua": "26",
    "baokao": "5",
    "luqu": "26",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081702",
    "name": "包装工程",
    "is_wenli": "理科",
    "jihua": "75",
    "baokao": "5",
    "luqu": "75",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081806",
    "name": "交通设备与控制工程",
    "is_wenli": "理科",
    "jihua": "30",
    "baokao": "5",
    "luqu": "30",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082006",
    "name": "飞行器质量与可靠性",
    "is_wenli": "理科",
    "jihua": "25",
    "baokao": "5",
    "luqu": "25",
    "pici": "本科一批"
  },
  {
    "specialty_code": "09030F",
    "name": "动物科学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "7",
    "baokao": "5",
    "luqu": "7",
    "pici": "本科一批"
  },
  {
    "specialty_code": "12010M",
    "name": "大数据管理与应用(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "12",
    "baokao": "5",
    "luqu": "12",
    "pici": "本科一批"
  },
  {
    "specialty_code": "020307",
    "name": "经济与金融",
    "is_wenli": "理科",
    "jihua": "7",
    "baokao": "4",
    "luqu": "7",
    "pici": "本科一批"
  },
  {
    "specialty_code": "020309",
    "name": "互联网金融",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "4",
    "luqu": "2",
    "pici": "本科一批"
  },
  {
    "specialty_code": "020403",
    "name": "国际经济发展合作",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "4",
    "luqu": "2",
    "pici": "本科一批"
  },
  {
    "specialty_code": "030203",
    "name": "外交学",
    "is_wenli": "理科",
    "jihua": "3",
    "baokao": "4",
    "luqu": "4",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0305",
    "name": "马克思主义理论类",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "4",
    "luqu": "2",
    "pici": "本科一批"
  },
  {
    "specialty_code": "030503",
    "name": "思想政治教育",
    "is_wenli": "理科",
    "jihua": "7",
    "baokao": "4",
    "luqu": "6",
    "pici": "本科一批"
  },
  {
    "specialty_code": "040106",
    "name": "学前教育",
    "is_wenli": "理科",
    "jihua": "8",
    "baokao": "4",
    "luqu": "8",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0502H",
    "name": "外国语言文学类(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "12",
    "baokao": "4",
    "luqu": "10",
    "pici": "本科一批"
  },
  {
    "specialty_code": "070703",
    "name": "海洋资源与环境",
    "is_wenli": "理科",
    "jihua": "7",
    "baokao": "4",
    "luqu": "7",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08020H",
    "name": "材料成型及控制工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "70",
    "baokao": "4",
    "luqu": "70",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080417",
    "name": "智能材料与结构",
    "is_wenli": "理科",
    "jihua": "12",
    "baokao": "4",
    "luqu": "12",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080918",
    "name": "密码科学与技术",
    "is_wenli": "理科",
    "jihua": "12",
    "baokao": "4",
    "luqu": "12",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081305",
    "name": "化学工程与工业生物工程",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "4",
    "luqu": "2",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081811",
    "name": "智慧交通",
    "is_wenli": "理科",
    "jihua": "8",
    "baokao": "4",
    "luqu": "8",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081902",
    "name": "海洋工程与技术",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "4",
    "luqu": "6",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08260F",
    "name": "生物医学工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "75",
    "baokao": "4",
    "luqu": "75",
    "pici": "本科一批"
  },
  {
    "specialty_code": "09050F",
    "name": "林学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "4",
    "luqu": "6",
    "pici": "本科一批"
  },
  {
    "specialty_code": "100706",
    "name": "药物化学",
    "is_wenli": "理科",
    "jihua": "8",
    "baokao": "4",
    "luqu": "8",
    "pici": "本科一批"
  },
  {
    "specialty_code": "101002",
    "name": "医学实验技术",
    "is_wenli": "理科",
    "jihua": "38",
    "baokao": "4",
    "luqu": "38",
    "pici": "本科一批"
  },
  {
    "specialty_code": "101009",
    "name": "康复物理治疗",
    "is_wenli": "理科",
    "jihua": "17",
    "baokao": "4",
    "luqu": "17",
    "pici": "本科一批"
  },
  {
    "specialty_code": "12020J",
    "name": "国际商务(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "8",
    "baokao": "4",
    "luqu": "8",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120212",
    "name": "体育经济与管理",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "4",
    "luqu": "4",
    "pici": "本科一批"
  },
  {
    "specialty_code": "12060G",
    "name": "物流工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "10",
    "baokao": "4",
    "luqu": "10",
    "pici": "本科一批"
  },
  {
    "specialty_code": "02010G",
    "name": "经济统计学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "3",
    "luqu": "6",
    "pici": "本科一批"
  },
  {
    "specialty_code": "030201",
    "name": "政治学与行政学",
    "is_wenli": "理科",
    "jihua": "11",
    "baokao": "3",
    "luqu": "11",
    "pici": "本科一批"
  },
  {
    "specialty_code": "030202",
    "name": "国际政治",
    "is_wenli": "理科",
    "jihua": "12",
    "baokao": "3",
    "luqu": "10",
    "pici": "本科一批"
  },
  {
    "specialty_code": "040102",
    "name": "科学教育",
    "is_wenli": "理科",
    "jihua": "12",
    "baokao": "3",
    "luqu": "12",
    "pici": "本科一批"
  },
  {
    "specialty_code": "050205",
    "name": "西班牙语",
    "is_wenli": "理科",
    "jihua": "7",
    "baokao": "3",
    "luqu": "7",
    "pici": "本科一批"
  },
  {
    "specialty_code": "050305",
    "name": "编辑出版学",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "3",
    "luqu": "2",
    "pici": "本科一批"
  },
  {
    "specialty_code": "05030I",
    "name": "传播学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "3",
    "baokao": "3",
    "luqu": "3",
    "pici": "本科一批"
  },
  {
    "specialty_code": "07110G",
    "name": "应用心理学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "3",
    "luqu": "2",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08010G",
    "name": "工程力学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "3",
    "baokao": "3",
    "luqu": "3",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08040G",
    "name": "材料物理(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "3",
    "luqu": "4",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080410",
    "name": "宝石及材料工艺学",
    "is_wenli": "理科",
    "jihua": "20",
    "baokao": "3",
    "luqu": "20",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080709",
    "name": "电子封装技术",
    "is_wenli": "理科",
    "jihua": "10",
    "baokao": "3",
    "luqu": "10",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08070G",
    "name": "电子科学与技术(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "8",
    "baokao": "3",
    "luqu": "8",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08071J",
    "name": "电信工程及管理(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "17",
    "baokao": "3",
    "luqu": "17",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08120F",
    "name": "测绘工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "34",
    "baokao": "3",
    "luqu": "34",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081404",
    "name": "地下水科学与工程",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "3",
    "luqu": "6",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081603",
    "name": "非织造材料与工程",
    "is_wenli": "理科",
    "jihua": "38",
    "baokao": "3",
    "luqu": "39",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081703",
    "name": "印刷工程",
    "is_wenli": "理科",
    "jihua": "19",
    "baokao": "3",
    "luqu": "19",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082101",
    "name": "武器系统与工程",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "3",
    "luqu": "2",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082104",
    "name": "弹药工程与爆炸技术",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "3",
    "luqu": "4",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082401",
    "name": "森林工程",
    "is_wenli": "理科",
    "jihua": "7",
    "baokao": "3",
    "luqu": "7",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082405",
    "name": "木结构建筑与材料",
    "is_wenli": "理科",
    "jihua": "3",
    "baokao": "3",
    "luqu": "3",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082506",
    "name": "资源环境科学",
    "is_wenli": "理科",
    "jihua": "79",
    "baokao": "3",
    "luqu": "78",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082603",
    "name": "临床工程技术",
    "is_wenli": "理科",
    "jihua": "5",
    "baokao": "3",
    "luqu": "5",
    "pici": "本科一批"
  },
  {
    "specialty_code": "083107",
    "name": "火灾勘查",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "3",
    "luqu": "2",
    "pici": "本科一批"
  },
  {
    "specialty_code": "090202",
    "name": "野生动物与自然保护区管理",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "3",
    "luqu": "4",
    "pici": "本科一批"
  },
  {
    "specialty_code": "090603",
    "name": "水族科学与技术",
    "is_wenli": "理科",
    "jihua": "30",
    "baokao": "3",
    "luqu": "30",
    "pici": "本科一批"
  },
  {
    "specialty_code": "101004",
    "name": "眼视光学",
    "is_wenli": "理科",
    "jihua": "5",
    "baokao": "3",
    "luqu": "5",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120111",
    "name": "应急管理",
    "is_wenli": "理科",
    "jihua": "18",
    "baokao": "3",
    "luqu": "18",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120211",
    "name": "劳动关系",
    "is_wenli": "理科",
    "jihua": "1",
    "baokao": "3",
    "luqu": "1",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120302",
    "name": "农村区域发展",
    "is_wenli": "理科",
    "jihua": "10",
    "baokao": "3",
    "luqu": "8",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120403",
    "name": "劳动与社会保障",
    "is_wenli": "理科",
    "jihua": "28",
    "baokao": "3",
    "luqu": "28",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120408",
    "name": "海事管理",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "3",
    "luqu": "7",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120802",
    "name": "电子商务及法律",
    "is_wenli": "理科",
    "jihua": "19",
    "baokao": "3",
    "luqu": "19",
    "pici": "本科一批"
  },
  {
    "specialty_code": "020306",
    "name": "信用管理",
    "is_wenli": "理科",
    "jihua": "17",
    "baokao": "2",
    "luqu": "17",
    "pici": "本科一批"
  },
  {
    "specialty_code": "02030J",
    "name": "金融数学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "2",
    "luqu": "4",
    "pici": "本科一批"
  },
  {
    "specialty_code": "030301",
    "name": "社会学",
    "is_wenli": "理科",
    "jihua": "10",
    "baokao": "2",
    "luqu": "10",
    "pici": "本科一批"
  },
  {
    "specialty_code": "05021A",
    "name": "语言学",
    "is_wenli": "理科",
    "jihua": "1",
    "baokao": "2",
    "luqu": "1",
    "pici": "本科一批"
  },
  {
    "specialty_code": "07050I",
    "name": "地理信息科学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "2",
    "luqu": "4",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080208",
    "name": "汽车服务工程",
    "is_wenli": "理科",
    "jihua": "19",
    "baokao": "2",
    "luqu": "17",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080607",
    "name": "能源互联网工程",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "2",
    "luqu": "4",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080712",
    "name": "电磁场与无线技术",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "2",
    "luqu": "6",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08071L",
    "name": "人工智能(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "2",
    "luqu": "4",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080720",
    "name": "智能测控工程",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "2",
    "luqu": "2",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081007",
    "name": "铁道工程",
    "is_wenli": "理科",
    "jihua": "16",
    "baokao": "2",
    "luqu": "15",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081406",
    "name": "智能地球探测",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "2",
    "luqu": "2",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081505",
    "name": "矿物资源工程",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "2",
    "luqu": "6",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081706",
    "name": "生物质能源与材料",
    "is_wenli": "理科",
    "jihua": "3",
    "baokao": "2",
    "luqu": "3",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081804",
    "name": "轮机工程",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "2",
    "luqu": "6",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081903",
    "name": "海洋资源开发技术",
    "is_wenli": "理科",
    "jihua": "19",
    "baokao": "2",
    "luqu": "20",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08200H",
    "name": "飞行器制造工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "2",
    "luqu": "6",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082202",
    "name": "辐射防护与核安全",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "2",
    "luqu": "4",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082402",
    "name": "木材科学与工程",
    "is_wenli": "理科",
    "jihua": "25",
    "baokao": "2",
    "luqu": "25",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082403",
    "name": "林产化工",
    "is_wenli": "理科",
    "jihua": "9",
    "baokao": "2",
    "luqu": "9",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08240G",
    "name": "木材科学与工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "2",
    "luqu": "4",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08270G",
    "name": "食品质量与安全(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "3",
    "baokao": "2",
    "luqu": "3",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082806",
    "name": "城市设计",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "2",
    "luqu": "4",
    "pici": "本科一批"
  },
  {
    "specialty_code": "083106",
    "name": "抢险救援指挥与技术",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "2",
    "luqu": "2",
    "pici": "本科一批"
  },
  {
    "specialty_code": "100103",
    "name": "生物医学科学",
    "is_wenli": "理科",
    "jihua": "9",
    "baokao": "2",
    "luqu": "9",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120104",
    "name": "房地产开发与管理",
    "is_wenli": "理科",
    "jihua": "20",
    "baokao": "2",
    "luqu": "25",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120109",
    "name": "工程审计",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "2",
    "luqu": "2",
    "pici": "本科一批"
  },
  {
    "specialty_code": "12030F",
    "name": "农林经济管理(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "3",
    "baokao": "2",
    "luqu": "3",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120412",
    "name": "医疗产品管理",
    "is_wenli": "理科",
    "jihua": "20",
    "baokao": "2",
    "luqu": "20",
    "pici": "本科一批"
  },
  {
    "specialty_code": "12060F",
    "name": "物流管理(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "2",
    "luqu": "6",
    "pici": "本科一批"
  },
  {
    "specialty_code": "12080F",
    "name": "电子商务(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "7",
    "baokao": "2",
    "luqu": "7",
    "pici": "本科一批"
  },
  {
    "specialty_code": "12090F",
    "name": "旅游管理(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "16",
    "baokao": "2",
    "luqu": "16",
    "pici": "本科一批"
  },
  {
    "specialty_code": "02030G",
    "name": "金融工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "1",
    "luqu": "2",
    "pici": "本科一批"
  },
  {
    "specialty_code": "050107",
    "name": "秘书学",
    "is_wenli": "理科",
    "jihua": "8",
    "baokao": "1",
    "luqu": "8",
    "pici": "本科一批"
  },
  {
    "specialty_code": "05020G",
    "name": "俄语(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "10",
    "baokao": "1",
    "luqu": "10",
    "pici": "本科一批"
  },
  {
    "specialty_code": "050232",
    "name": "葡萄牙语",
    "is_wenli": "理科",
    "jihua": "3",
    "baokao": "1",
    "luqu": "3",
    "pici": "本科一批"
  },
  {
    "specialty_code": "050234",
    "name": "塞尔维亚语",
    "is_wenli": "理科",
    "jihua": "1",
    "baokao": "1",
    "luqu": "1",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0503H",
    "name": "新闻传播学类(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "3",
    "baokao": "1",
    "luqu": "3",
    "pici": "本科一批"
  },
  {
    "specialty_code": "070603",
    "name": "气象技术与工程",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "1",
    "luqu": "2",
    "pici": "本科一批"
  },
  {
    "specialty_code": "0707",
    "name": "海洋科学类",
    "is_wenli": "理科",
    "jihua": "18",
    "baokao": "1",
    "luqu": "18",
    "pici": "本科一批"
  },
  {
    "specialty_code": "070802",
    "name": "空间科学与技术",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "1",
    "luqu": "2",
    "pici": "本科一批"
  },
  {
    "specialty_code": "070902",
    "name": "地球化学",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "1",
    "luqu": "4",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080215",
    "name": "仿生科学与工程",
    "is_wenli": "理科",
    "jihua": "5",
    "baokao": "1",
    "luqu": "5",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08040I",
    "name": "冶金工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "3",
    "baokao": "1",
    "luqu": "3",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080505",
    "name": "能源服务工程",
    "is_wenli": "理科",
    "jihua": "5",
    "baokao": "1",
    "luqu": "5",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080603",
    "name": "光源与照明",
    "is_wenli": "理科",
    "jihua": "3",
    "baokao": "1",
    "luqu": "5",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080715",
    "name": "电信工程及管理",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "1",
    "luqu": "2",
    "pici": "本科一批"
  },
  {
    "specialty_code": "080805",
    "name": "核电技术与控制工程",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "1",
    "luqu": "4",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08091E",
    "name": "数据科学与大数据技术(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "3",
    "baokao": "1",
    "luqu": "3",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081105",
    "name": "水利科学与工程",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "1",
    "luqu": "2",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081306",
    "name": "化工安全工程",
    "is_wenli": "理科",
    "jihua": "10",
    "baokao": "1",
    "luqu": "10",
    "pici": "本科一批"
  },
  {
    "specialty_code": "081308",
    "name": "精细化工",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "1",
    "luqu": "6",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08160G",
    "name": "服装设计与工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "1",
    "luqu": "4",
    "pici": "本科一批"
  },
  {
    "specialty_code": "08180I",
    "name": "轮机工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "3",
    "baokao": "1",
    "luqu": "3",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082105",
    "name": "特种能源技术与工程",
    "is_wenli": "理科",
    "jihua": "3",
    "baokao": "1",
    "luqu": "3",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082404",
    "name": "家具设计与工程",
    "is_wenli": "理科",
    "jihua": "14",
    "baokao": "1",
    "luqu": "14",
    "pici": "本科一批"
  },
  {
    "specialty_code": "082704",
    "name": "乳品工程",
    "is_wenli": "理科",
    "jihua": "56",
    "baokao": "1",
    "luqu": "56",
    "pici": "本科一批"
  },
  {
    "specialty_code": "090602",
    "name": "海洋渔业科学与技术",
    "is_wenli": "理科",
    "jihua": "16",
    "baokao": "1",
    "luqu": "16",
    "pici": "本科一批"
  },
  {
    "specialty_code": "090604",
    "name": "水生动物医学",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "1",
    "luqu": "6",
    "pici": "本科一批"
  },
  {
    "specialty_code": "090702",
    "name": "草坪科学与工程",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "1",
    "luqu": "6",
    "pici": "本科一批"
  },
  {
    "specialty_code": "100705",
    "name": "药物分析",
    "is_wenli": "理科",
    "jihua": "3",
    "baokao": "1",
    "luqu": "3",
    "pici": "本科一批"
  },
  {
    "specialty_code": "100707",
    "name": "海洋药学",
    "is_wenli": "理科",
    "jihua": "3",
    "baokao": "1",
    "luqu": "3",
    "pici": "本科一批"
  },
  {
    "specialty_code": "100802",
    "name": "中药资源与开发",
    "is_wenli": "理科",
    "jihua": "5",
    "baokao": "1",
    "luqu": "5",
    "pici": "本科一批"
  },
  {
    "specialty_code": "100805",
    "name": "中药制药",
    "is_wenli": "理科",
    "jihua": "5",
    "baokao": "1",
    "luqu": "5",
    "pici": "本科一批"
  },
  {
    "specialty_code": "101012",
    "name": "生物医药数据科学",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "1",
    "luqu": "4",
    "pici": "本科一批"
  },
  {
    "specialty_code": "12010F",
    "name": "管理科学(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "1",
    "luqu": "2",
    "pici": "本科一批"
  },
  {
    "specialty_code": "12010H",
    "name": "工程管理(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "25",
    "baokao": "1",
    "luqu": "25",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120210",
    "name": "文化产业管理",
    "is_wenli": "理科",
    "jihua": "13",
    "baokao": "1",
    "luqu": "13",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120410",
    "name": "健康服务与管理",
    "is_wenli": "理科",
    "jihua": "12",
    "baokao": "1",
    "luqu": "12",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120502",
    "name": "档案学",
    "is_wenli": "理科",
    "jihua": "7",
    "baokao": "1",
    "luqu": "7",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120503",
    "name": "信息资源管理",
    "is_wenli": "理科",
    "jihua": "4",
    "baokao": "1",
    "luqu": "4",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120604",
    "name": "供应链管理",
    "is_wenli": "理科",
    "jihua": "36",
    "baokao": "1",
    "luqu": "36",
    "pici": "本科一批"
  },
  {
    "specialty_code": "12070F",
    "name": "工业工程(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "127",
    "baokao": "1",
    "luqu": "127",
    "pici": "本科一批"
  },
  {
    "specialty_code": "1208",
    "name": "电子商务类",
    "is_wenli": "理科",
    "jihua": "9",
    "baokao": "1",
    "luqu": "7",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120803",
    "name": "跨境电子商务",
    "is_wenli": "理科",
    "jihua": "5",
    "baokao": "1",
    "luqu": "5",
    "pici": "本科一批"
  },
  {
    "specialty_code": "120902",
    "name": "酒店管理",
    "is_wenli": "理科",
    "jihua": "5",
    "baokao": "1",
    "luqu": "5",
    "pici": "本科一批"
  },
  {
    "specialty_code": "12090H",
    "name": "会展经济与管理(中外合作办学)",
    "is_wenli": "理科",
    "jihua": "6",
    "baokao": "1",
    "luqu": "6",
    "pici": "本科一批"
  },
  {
    "specialty_code": "020103",
    "name": "国民经济管理",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "0",
    "luqu": "2",
    "pici": "本科一批"
  },
  {
    "specialty_code": "020106",
    "name": "能源经济",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "0",
    "luqu": "2",
    "pici": "本科一批"
  },
  {
    "specialty_code": "030103",
    "name": "监狱学",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "0",
    "luqu": "2",
    "pici": "本科一批"
  },
  {
    "specialty_code": "030104",
    "name": "信用风险管理与法律防控",
    "is_wenli": "理科",
    "jihua": "2",
    "baokao": "0",
    "luqu": "2",
    "pici": "本科一批"
  }
]