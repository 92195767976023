module.exports = {
  "本科职业名称": "就业比例",
  "会计": "3.4",
  "小学教师": "3.3",
  "互联网开发人员": "2.8",
  "初中教师": "2.4",
  "银行柜员": "2.3",
  "计算机程序员": "1.9",
  "高中教师": "1.9",
  "护士": "1.6",
  "新媒体策划、编辑、运营人员": "1.6",
  "幼儿教师": "1.4",
  "电子商务专员": "1.3",
  "行政秘书和行政助理": "1.3",
  "审计人员": "1.2",
  "出纳员": "1.2",
  // "建筑技术人员": "1",
  // "化学技术人员": "0.9",
  // "各类销售服务人员": "0.9",
  // "教育培训人员": "0.8",
  // "电子工程技术人员": "0.8",
  // "施工工程技术人员": "0.8",
  // "其他社区和村镇工作人员": "0.8",
  // "人民警察": "0.8",
  // "电气工程技术人员": "0.7",
  // "电厂操作人员": "0.7",
  // "信息支持与服务人员": "0.7",
  // "土木工程技术人员": "0.7",
  // "人力资源助理": "0.6",
  // "中等职业教育教师": "0.6",
  // "税务专员": "0.6",
  // "医学和临床实验室技术人员": "0.6",
  // "生物医学工程技术人员": "0.6",
  // "教学辅助人员": "0.6",
  // "采购员": "0.6",
  // "室内设计师": "0.6",
  // "计算机软件应用工程技术人员": "0.6",
  // "软件质量保证和测试工程技术人员": "0.6",
  // "平面设计人员": "0.5",
  // "运营经理": "0.5",
  // "客服专员": "0.5",
  // "数据统计分析人员": "0.5",
  // "计算机技术支持人员": "0.5",
  // "工业工程技术人员": "0.5",
  // "发电站、变电站和中继站的电子和电气修理技术人员": "0.5",
  // "营业员": "0.5",
  // "招聘专职人员": "0.5",
  // "市场专员": "0.5",
  // "电气技术人员": "0.5",
  // "社工": "0.5",
  // "编辑": "0.5"
}