<template>
  <div style="background:#f4f4f4;overflow: hidden;">
    <div class="pages">
      <div class="title">本科招生数据</div>
      <div class="content">
        <div class="d_1 t_2" @click="CollegeDataDialog = true">
          查看在豫招生热门院校
        </div>
        <div class="d_2 t_2" @click="MojarDialog = true">
          查看在豫招生热门专业
        </div>
      </div>
      <div class="title">本科就业数据</div>
      <div class="content">
        <div @click="change(1)" class="c_1">
          <circleData title="学科门类就业率排行" :dataLists="data1"></circleData>
        </div>

        <div @click="change(2)" class="c_1">
          <circleData title="专业就业率排行" :dataLists="data2"></circleData>
        </div>

        <div @click="change(3)" class="c_1">
          <lineData title="毕业生职业就业量排行" :dataLists="data3"></lineData>
        </div>

        <div @click="change(4)" class="c_1">
          <circleData title="专业与工作相关度排行" :dataLists="data4"></circleData>
        </div>

        <div @click="change(5)" class="c_1">
          <circleData title="专业就业满意度排行" :dataLists="data5"></circleData>
        </div>

        <div @click="change(6)" class="c_1">
          <lineData title="毕业生行业就业量排行" :dataLists="data6"></lineData>
        </div>

        <div @click="change1(7)" class="c_1">
          <echartsData title="专业类月收入排行" :dataLists="data7"></echartsData>
        </div>

        <div @click="change1(8)" class="c_1">
          <echartsData title="学科门类收入排行" :dataLists="data8"></echartsData>
        </div>

        <div @click="change(9)" class="c_1">
          <echartsData1 title="主要专业月收入排行" :dataLists="data9"></echartsData1>
        </div>

      </div>
      <div class="title">专科招生数据</div>
      <div class="content">
        <div class="d_3 t_2" @click="CollegeDataDialogs = true">
          查看在豫招生热门院校
        </div>
        <div class="d_4 t_2" @click="MojarDialogs = true">
          查看在豫招生热门专业
        </div>
      </div>
      <div class="title">专科就业数据</div>
      <div class="content">
        <div @click="change(11)" class="c_1">
          <circleData title="学科门类就业率排行" :dataLists="zdata1"></circleData>
        </div>

        <div @click="change(12)" class="c_1">
          <circleData title="专业就业率排行" :dataLists="zdata2"></circleData>
        </div>

        <div @click="change(13)" class="c_1">
          <lineData title="毕业生职业就业量排行" :dataLists="zdata3"></lineData>
        </div>

        <div @click="change(14)" class="c_1">
          <circleData title="专业与工作相关度排行" :dataLists="zdata4"></circleData>
        </div>

        <div @click="change(15)" class="c_1">
          <circleData title="专业就业满意度排行" :dataLists="zdata5"></circleData>
        </div>

        <div @click="change(16)" class="c_1">
          <lineData title="毕业生行业就业量排行" :dataLists="zdata6"></lineData>
        </div>

        <div @click="change1(17)" class="c_1">
          <echartsData title="专科专业类月收入排行" :dataLists="zdata7"></echartsData>
        </div>

        <div @click="change1(18)" class="c_1">
          <echartsData title="专科学科门类收入排行" :dataLists="zdata8"></echartsData>
        </div>

        <div @click="change(19)" class="c_1">
          <echartsData1 title="专科主要专业月收入排行" :dataLists="zdata9"></echartsData1>
        </div>
      </div>
    </div>

    <!-- 一列的表 -->
    <el-dialog :visible.sync="dialogVisible" width="820px" class="dia_log_ld">
      <div class="dialog_table" ref="dialog_table">
        <div class="dia_title">
          {{ title }}
        </div>
        <table class="dia_table">
          <tr v-for="(item, i) in tableData" :key="i">
            <td>{{ i }}</td>
            <td>{{ item }}</td>
          </tr>
        </table>
        <div style="margin-top:40px">数据来源：麦可思-中国2022届大学毕业生培养质量跟踪评价。
        </div>
      </div>

    </el-dialog>

    <!-- 3列的表格 -->
    <el-dialog :visible.sync="dialogVisible1" width="820px" class="dia_log_ld">
      <div class="dialog_table" ref="dialog_table">
        <div class="dia_title">
          {{ title }}
        </div>
        <table class="dia_table">
          <tr v-for="(item, i) in tableData" :key="i">
            <td>{{ item['a'] }}</td>
            <td>{{ item['b'] }}</td>
            <td>{{ item['c'] }}</td>
            <td>{{ item['d'] }}</td>
          </tr>
        </table>
        <div style="margin-top:40px">数据来源：麦可思-中国2022届大学毕业生培养质量跟踪评价。
        </div>
      </div>

    </el-dialog>

    <!-- 在豫热门院校 -->
    <el-dialog :visible.sync="CollegeDataDialog" width="820px" class='dia_log_ld'>
      <CollegeData />
    </el-dialog>
    <el-dialog :visible.sync="CollegeDataDialogs" width="820px" class='dia_log_ld'>
      <CollegeDatas />
    </el-dialog>
    <el-dialog :visible.sync="MojarDialog" width="820px" class='dia_log_ld'>
      <MojarData />
    </el-dialog>
    <el-dialog :visible.sync="MojarDialogs" width="820px" class='dia_log_ld'>
      <MojarDatas />
    </el-dialog>
  </div>
</template>
<script>
import circleData from "./components/progress/circle";
import lineData from "./components/progress/line";
import echartsData from "./components/progress/echarts";
import echartsData1 from "./components/progress/echarts1";
import ld1 from "./components/data/ld1";
import ld2 from "./components/data/ld2";
import ld3 from "./components/data/ld3";
import ld4 from "./components/data/ld4";
import ld5 from "./components/data/ld5";
import ld6 from "./components/data/ld6";
import ld7 from "./components/data/ld7";
import ld8 from "./components/data/ld8";
import ld9 from "./components/data/ld9";
import z1 from "./components/data/z1";
import z2 from "./components/data/z2";
import z3 from "./components/data/z3";
import z4 from "./components/data/z4";
import z5 from "./components/data/z5";
import z6 from "./components/data/z6";
import z7 from "./components/data/z7";
import z8 from "./components/data/z8";
import z9 from "./components/data/z9";
import CollegeData from "./components/CollegeData";
import MojarData from "./components/MojarData";
import CollegeDatas from "./components/CollegeDatas";
import MojarDatas from "./components/MojarDatas";
//time: 2020/12/12
export default {
  name: "index",
  data() {
    return {
      dialogVisible: false,
      dialogVisible1: false,
      CollegeDataDialog: false,
      MojarDialog: false,
      CollegeDataDialogs: false,
      MojarDialogs: false,
      tableData: [],
      title: "",
      data1: [],
      data2: [],
      data3: [],
      data4: [],
      data5: [],
      data6: [],
      data7: [],
      data8: [],
      data9: [],
      zdata1: [],
      zdata2: [],
      zdata3: [],
      zdata4: [],
      zdata5: [],
      zdata6: [],
      zdata7: [],
      zdata8: [],
      zdata9: [],
    };
  },
  components: {
    circleData,
    echartsData,
    echartsData1,
    lineData,
    CollegeData,
    MojarData,
    CollegeDatas,
    MojarDatas,
  },
  mounted() {
    this.data1 = ld1
    this.data2 = ld2
    this.data3 = ld3
    this.data4 = ld4
    this.data5 = ld5
    this.data6 = ld6
    this.data7 = ld7
    this.data8 = ld8
    this.data9 = ld9
    this.zdata1 = z1
    this.zdata2 = z2
    this.zdata3 = z3
    this.zdata4 = z4
    this.zdata5 = z5
    this.zdata6 = z6
    this.zdata7 = z7
    this.zdata8 = z8
    this.zdata9 = z9
    // console.log(this.data1)
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  methods: {
    change(e) {
      this.dialogVisible = true;
      switch (e) {

        case 1:
          this.title = "学科门类就业率";
          this.tableData = ld1;
          break;
        case 2:
          this.title = "专业就业率排行";
          this.tableData = ld2;
          break;
        case 3:
          this.title = "毕业生职业就业量排行";
          this.tableData = ld3;
          break;
        case 4:
          this.title = "专业与工作相关度排行";
          this.tableData = ld4;
          break;
        case 5:
          this.title = "专业就业满意度排行";
          this.tableData = ld5;
          break;
        case 6:
          this.title = "毕业生行业就业量排行";
          this.tableData = ld6;
          break;

        case 9:
          this.title = "主要专业月收入排行";
          this.tableData = ld9;
          break;
        case 11:
          this.title = "专业大类就业率排行";
          this.tableData = z1;
          break;
        case 12:
          this.title = "主要专业就业率";
          this.tableData = z2;
          break;
        case 13:
          this.title = "毕业生行业就业量排行";
          this.tableData = z3;
          break;
        case 14:
          this.title = "毕业生职业就业量排行";
          this.tableData = z4;
          break;
        case 15:
          this.title = "主要专业工作相关度排行";
          this.tableData = z5;
          break;
        case 16:
          this.title = "主要专业就业满意度排行";
          this.tableData = z6;
          break;

        case 19:
          this.title = "主要专业月收入排行";
          this.tableData = z9;
          break;
        default:
          this.title = "";
          this.tableData = [];
          break;
      }
    },
    change1(e) {
      this.dialogVisible1 = true;
      switch (e) {
        case 7:
          this.title = "专业类月收入排行";
          this.tableData = ld7;
          break;
        case 8:
          this.title = "学科门类月收入排行";
          this.tableData = ld8;
          break;
        case 17:
          this.title = "专业大类月收入排行";
          this.tableData = z7;
          break;
        case 18:
          this.title = "主要专业类月收入排行";
          this.tableData = z8;
          break;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.pages {
  width: 1090px;
  margin: 0 auto;

  .title {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 20px;
    color: #595959;
  }
}

.content {
  display: -webkit-flex;
  /* Safari */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .t_2 {
    width: 539px;
    height: 90px;
    line-height: 90px;
    text-indent: 2rem;
    font-size: 20px;
    color: white;
  }

  .d_1 {
    background-image: url("./../../assets/img/lookData/ld1.png");
  }

  .d_2 {
    background-image: url("./../../assets/img/lookData/ld2.png");
  }

  .d_3 {
    background-image: url("./../../assets/img/lookData/ld3.png");
  }

  .d_4 {
    background-image: url("./../../assets/img/lookData/ld4.png");
  }

  .c_1 {
    width: 350px;
    padding: 3px;
    margin-bottom: 15px;
  }
}

.dia_title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 30px;
  color: #65a7fe;
}

.dia_table {
  width: 700px;
  font-size: 16px;
  text-align: center;
  border: 1px solid #dfdfdf;
  border-collapse: collapse;

  td {
    width: 260px;
    border: 1px solid #dfdfdf;
    line-height: 30px;
    height: 31px;
  }

  tr:first-child {
    height: 40px;
    background: #f4f7fc;
  }

  margin: 20px auto;
}

.dialog_table {
  height: 630px;
}
</style>

<style lang="less">
.dia_log_ld {
  .el-dialog__header {
    padding: 0;
  }
}
</style>