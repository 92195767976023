module.exports = [
  { "name": "[510101] 作物生产技术", "is_wenli": "文科", "jihua": 44, "baokao": 3, "luqu": 4 },
  { "name": "[510102] 种子生产与经营", "is_wenli": "文科", "jihua": 52, "baokao": 14, "luqu": 19 },
  { "name": "[510104] 现代农业技术", "is_wenli": "文科", "jihua": 76, "baokao": 83, "luqu": 87 },
  { "name": "[510105] 休闲农业", "is_wenli": "文科", "jihua": 21, "baokao": 4, "luqu": 6 },
  { "name": "[510106] 生态农业技术", "is_wenli": "文科", "jihua": 15, "baokao": 4, "luqu": 8 },
  { "name": "[510107] 园艺技术", "is_wenli": "文科", "jihua": 247, "baokao": 123, "luqu": 146 },
  { "name": "[510108] 植物保护与检疫技术", "is_wenli": "文科", "jihua": 31, "baokao": 32, "luqu": 25 },
  { "name": "[510109] 茶树栽培与茶叶加工", "is_wenli": "文科", "jihua": 17, "baokao": 0, "luqu": 1 },
  { "name": "[510110] 中草药栽培技术", "is_wenli": "文科", "jihua": 10, "baokao": 3, "luqu": 4 },
  { "name": "[510113] 农产品加工与质量检测", "is_wenli": "文科", "jihua": 64, "baokao": 13, "luqu": 27 },
  { "name": "[510114] 绿色食品生产与检验", "is_wenli": "文科", "jihua": 68, "baokao": 8, "luqu": 34 },
  { "name": "[510116] 农产品流通与管理", "is_wenli": "文科", "jihua": 15, "baokao": 4, "luqu": 6 },
  { "name": "[510117] 农业装备应用技术", "is_wenli": "文科", "jihua": 21, "baokao": 3, "luqu": 15 },
  { "name": "[510118] 农业经济管理", "is_wenli": "文科", "jihua": 8, "baokao": 0, "luqu": 1 },
  { "name": "[510201] 林业技术", "is_wenli": "文科", "jihua": 33, "baokao": 23, "luqu": 20 },
  { "name": "[510202] 园林技术", "is_wenli": "文科", "jihua": 339, "baokao": 262, "luqu": 214 },
  { "name": "[510206] 野生动物资源保护与利用", "is_wenli": "文科", "jihua": 1, "baokao": 1, "luqu": 3 },
  { "name": "[510207] 森林生态旅游", "is_wenli": "文科", "jihua": 5, "baokao": 1, "luqu": 1 },
  { "name": "[510209] 自然保护区建设与管理", "is_wenli": "文科", "jihua": 30, "baokao": 19, "luqu": 21 },
  { "name": "[510213] 林业信息技术与管理", "is_wenli": "文科", "jihua": 1, "baokao": 0, "luqu": 1 },
  { "name": "[510301] 畜牧兽医", "is_wenli": "文科", "jihua": 187, "baokao": 219, "luqu": 226 },
  { "name": "[510302] 动物医学", "is_wenli": "文科", "jihua": 133, "baokao": 269, "luqu": 219 },
  { "name": "[510303] 动物药学", "is_wenli": "文科", "jihua": 11, "baokao": 4, "luqu": 7 },
  { "name": "[510304] 动物防疫与检疫", "is_wenli": "文科", "jihua": 6, "baokao": 2, "luqu": 3 },
  { "name": "[510306] 宠物养护与驯导", "is_wenli": "文科", "jihua": 92, "baokao": 75, "luqu": 84 },
  { "name": "[510315] 宠物临床诊疗技术", "is_wenli": "文科", "jihua": 97, "baokao": 120, "luqu": 93 },
  { "name": "[510401] 水产养殖技术", "is_wenli": "文科", "jihua": 8, "baokao": 2, "luqu": 3 },
  { "name": "[510403] 水族科学与技术", "is_wenli": "文科", "jihua": 5, "baokao": 2, "luqu": 3 },
  { "name": "[520101] 国土资源调查与管理", "is_wenli": "文科", "jihua": 24, "baokao": 26, "luqu": 38 },
  { "name": "[520102] 地质调查与矿产普查", "is_wenli": "文科", "jihua": 25, "baokao": 11, "luqu": 40 },
  { "name": "[520103] 矿产地质与勘查", "is_wenli": "文科", "jihua": 3, "baokao": 1, "luqu": 2 },
  { "name": "[520105] 宝玉石鉴定与加工", "is_wenli": "文科", "jihua": 139, "baokao": 50, "luqu": 135 },
  { "name": "[520106] 煤田地质与勘查技术", "is_wenli": "文科", "jihua": 2, "baokao": 1, "luqu": 2 },
  { "name": "[520201] 工程地质勘查", "is_wenli": "文科", "jihua": 5, "baokao": 3, "luqu": 2 },
  { "name": "[520202] 水文与工程地质", "is_wenli": "文科", "jihua": 44, "baokao": 130, "luqu": 59 },
  { "name": "[520205] 地球物理勘探技术", "is_wenli": "文科", "jihua": 2, "baokao": 2, "luqu": 2 },
  { "name": "[520206] 地质灾害调查与防治", "is_wenli": "文科", "jihua": 4, "baokao": 1, "luqu": 2 },
  { "name": "[5203 ] 测绘地理信息类", "is_wenli": "文科", "jihua": 2, "baokao": 5, "luqu": 2 },
  { "name": "[520301] 工程测量技术", "is_wenli": "文科", "jihua": 431, "baokao": 365, "luqu": 447 },
  { "name": "[520302] 摄影测量与遥感技术", "is_wenli": "文科", "jihua": 122, "baokao": 138, "luqu": 98 },
  { "name": "[520303] 测绘工程技术", "is_wenli": "文科", "jihua": 63, "baokao": 102, "luqu": 77 },
  { "name": "[520304] 测绘地理信息技术", "is_wenli": "文科", "jihua": 87, "baokao": 77, "luqu": 68 },
  { "name": "[520305] 地籍测绘与土地管理", "is_wenli": "文科", "jihua": 42, "baokao": 26, "luqu": 38 },
  { "name": "[520307] 测绘与地质工程技术", "is_wenli": "文科", "jihua": 2, "baokao": 1, "luqu": 2 },
  { "name": "[520308] 导航与位置服务", "is_wenli": "文科", "jihua": 10, "baokao": 1, "luqu": 5 },
  { "name": "[520309] 地图制图与数字传播技术", "is_wenli": "文科", "jihua": 15, "baokao": 6, "luqu": 6 },
  { "name": "[52030F] 工程测量技术(中外合作办学)", "is_wenli": "文科", "jihua": 20, "baokao": 32, "luqu": 20 },
  { "name": "[520311] 国土测绘与规划", "is_wenli": "文科", "jihua": 52, "baokao": 24, "luqu": 48 },
  { "name": "[520401] 钻井技术", "is_wenli": "文科", "jihua": 2, "baokao": 0, "luqu": 2 },
  { "name": "[520402] 油气开采技术", "is_wenli": "文科", "jihua": 42, "baokao": 7, "luqu": 25 },
  { "name": "[520403] 油气储运技术", "is_wenli": "文科", "jihua": 6, "baokao": 0, "luqu": 4 },
  { "name": "[520404] 油气地质勘探技术", "is_wenli": "文科", "jihua": 2, "baokao": 1, "luqu": 2 },
  { "name": "[520406] 石油工程技术", "is_wenli": "文科", "jihua": 57, "baokao": 10, "luqu": 24 },
  { "name": "[520501] 煤矿开采技术", "is_wenli": "文科", "jihua": 26, "baokao": 2, "luqu": 7 },
  { "name": "[520503] 矿山机电技术", "is_wenli": "文科", "jihua": 1, "baokao": 0, "luqu": 1 },
  { "name": "[520702] 大气探测技术", "is_wenli": "文科", "jihua": 1, "baokao": 0, "luqu": 2 },
  { "name": "[520801] 环境监测与控制技术", "is_wenli": "文科", "jihua": 174, "baokao": 109, "luqu": 140 },
  { "name": "[520803] 室内环境检测与控制技术", "is_wenli": "文科", "jihua": 5, "baokao": 6, "luqu": 5 },
  { "name": "[520804] 环境工程技术", "is_wenli": "文科", "jihua": 271, "baokao": 213, "luqu": 239 },
  { "name": "[520807] 环境规划与管理", "is_wenli": "文科", "jihua": 12, "baokao": 10, "luqu": 18 },
  { "name": "[520808] 环境评价与咨询服务", "is_wenli": "文科", "jihua": 14, "baokao": 3, "luqu": 7 },
  { "name": "[520809] 污染修复与生态工程技术", "is_wenli": "文科", "jihua": 54, "baokao": 17, "luqu": 54 },
  { "name": "[520812] 水净化与安全技术", "is_wenli": "文科", "jihua": 20, "baokao": 1, "luqu": 3 },
  { "name": "[520901] 安全健康与环保", "is_wenli": "文科", "jihua": 5, "baokao": 3, "luqu": 5 },
  { "name": "[520904] 安全技术与管理", "is_wenli": "文科", "jihua": 104, "baokao": 69, "luqu": 99 },
  { "name": "[520905] 工程安全评价与监理", "is_wenli": "文科", "jihua": 3, "baokao": 1, "luqu": 4 },
  { "name": "[520906] 安全生产监测监控", "is_wenli": "文科", "jihua": 2, "baokao": 0, "luqu": 1 },
  { "name": "[5301 ] 电力技术类", "is_wenli": "文科", "jihua": 5, "baokao": 6, "luqu": 7 },
  { "name": "[530101] 发电厂及电力系统", "is_wenli": "文科", "jihua": 52, "baokao": 73, "luqu": 66 },
  { "name": "[530102] 供用电技术", "is_wenli": "文科", "jihua": 114, "baokao": 97, "luqu": 121 },
  { "name": "[530103] 电力系统自动化技术", "is_wenli": "文科", "jihua": 65, "baokao": 55, "luqu": 58 },
  { "name": "[530105] 电力系统继电保护与自动化技术", "is_wenli": "文科", "jihua": 35, "baokao": 31, "luqu": 41 },
  { "name": "[530106] 水电站机电设备与自动化", "is_wenli": "文科", "jihua": 1, "baokao": 0, "luqu": 1 },
  { "name": "[530108] 电力客户服务与管理", "is_wenli": "文科", "jihua": 5, "baokao": 3, "luqu": 5 },
  { "name": "[53010G] 供用电技术(中外合作办学)", "is_wenli": "文科", "jihua": 25, "baokao": 104, "luqu": 25 },
  { "name": "[53010H] 电力系统自动化技术(中外合作办学)", "is_wenli": "文科", "jihua": 25, "baokao": 174, "luqu": 25 },
  { "name": "[530112] 分布式发电与微电网技术", "is_wenli": "文科", "jihua": 26, "baokao": 9, "luqu": 10 },
  { "name": "[530113] 机场电工技术", "is_wenli": "文科", "jihua": 20, "baokao": 6, "luqu": 15 },
  { "name": "[530201] 电厂热能动力装置", "is_wenli": "文科", "jihua": 13, "baokao": 7, "luqu": 11 },
  { "name": "[530202] 城市热能应用技术", "is_wenli": "文科", "jihua": 10, "baokao": 10, "luqu": 6 },
  { "name": "[530203] 核电站动力设备运行与维护", "is_wenli": "文科", "jihua": 5, "baokao": 1, "luqu": 3 },
  { "name": "[530204] 火电厂集控运行", "is_wenli": "文科", "jihua": 3, "baokao": 1, "luqu": 3 },
  { "name": "[530205] 电厂化学与环保技术", "is_wenli": "文科", "jihua": 6, "baokao": 3, "luqu": 6 },
  { "name": "[5303 ] 新能源发电工程类", "is_wenli": "文科", "jihua": 2, "baokao": 0, "luqu": 2 },
  { "name": "[530301] 风力发电工程技术", "is_wenli": "文科", "jihua": 9, "baokao": 1, "luqu": 4 },
  { "name": "[530302] 风电系统运行与维护", "is_wenli": "文科", "jihua": 1, "baokao": 1, "luqu": 1 },
  { "name": "[530304] 光伏发电技术与应用", "is_wenli": "文科", "jihua": 53, "baokao": 13, "luqu": 19 },
  { "name": "[530305] 工业节能技术", "is_wenli": "文科", "jihua": 5, "baokao": 0, "luqu": 1 },
  { "name": "[530306] 节电技术与管理", "is_wenli": "文科", "jihua": 10, "baokao": 1, "luqu": 1 },
  { "name": "[530401] 黑色冶金技术", "is_wenli": "文科", "jihua": 14, "baokao": 1, "luqu": 2 },
  { "name": "[530501] 有色冶金技术", "is_wenli": "文科", "jihua": 24, "baokao": 8, "luqu": 8 },
  { "name": "[530601] 材料工程技术", "is_wenli": "文科", "jihua": 76, "baokao": 11, "luqu": 13 },
  { "name": "[530602] 高分子材料工程技术", "is_wenli": "文科", "jihua": 33, "baokao": 8, "luqu": 12 },
  { "name": "[530605] 光伏材料制备技术", "is_wenli": "文科", "jihua": 3, "baokao": 1, "luqu": 1 },
  { "name": "[530608] 橡胶工程技术", "is_wenli": "文科", "jihua": 6, "baokao": 3, "luqu": 2 },
  { "name": "[530702] 建筑材料检测技术", "is_wenli": "文科", "jihua": 15, "baokao": 0, "luqu": 8 },
  { "name": "[530703] 建筑装饰材料技术", "is_wenli": "文科", "jihua": 8, "baokao": 9, "luqu": 9 },
  { "name": "[5401 ] 建筑设计类", "is_wenli": "文科", "jihua": 60, "baokao": 6, "luqu": 26 },
  { "name": "[540101] 建筑设计", "is_wenli": "文科", "jihua": 207, "baokao": 237, "luqu": 184 },
  { "name": "[540102] 建筑装饰工程技术", "is_wenli": "文科", "jihua": 1214, "baokao": 986, "luqu": 1162 },
  { "name": "[540103] 古建筑工程技术", "is_wenli": "文科", "jihua": 36, "baokao": 14, "luqu": 35 },
  { "name": "[540104] 建筑室内设计", "is_wenli": "文科", "jihua": 1140, "baokao": 1907, "luqu": 1582 },
  { "name": "[540105] 风景园林设计", "is_wenli": "文科", "jihua": 181, "baokao": 84, "luqu": 121 },
  { "name": "[540106] 园林工程技术", "is_wenli": "文科", "jihua": 236, "baokao": 109, "luqu": 179 },
  { "name": "[540107] 建筑动画与模型制作", "is_wenli": "文科", "jihua": 53, "baokao": 23, "luqu": 47 },
  { "name": "[54010F] 建筑设计(中外合作办学)", "is_wenli": "文科", "jihua": 9, "baokao": 1, "luqu": 1 },
  { "name": "[540201] 城乡规划", "is_wenli": "文科", "jihua": 72, "baokao": 102, "luqu": 65 },
  { "name": "[540203] 城市信息化管理", "is_wenli": "文科", "jihua": 13, "baokao": 58, "luqu": 69 },
  { "name": "[540301] 建筑工程技术", "is_wenli": "文科", "jihua": 2247, "baokao": 2172, "luqu": 2144 },
  { "name": "[540302] 地下与隧道工程技术", "is_wenli": "文科", "jihua": 70, "baokao": 18, "luqu": 64 },
  { "name": "[540303] 土木工程检测技术", "is_wenli": "文科", "jihua": 86, "baokao": 50, "luqu": 52 },
  { "name": "[540304] 建筑钢结构工程技术", "is_wenli": "文科", "jihua": 23, "baokao": 2, "luqu": 21 },
  { "name": "[54030F] 建筑工程技术(中外合作办学)", "is_wenli": "文科", "jihua": 121, "baokao": 73, "luqu": 136 },
  { "name": "[54030H] 土木工程检测技术(中外合作办学)", "is_wenli": "文科", "jihua": 30, "baokao": 31, "luqu": 30 },
  { "name": "[540401] 建筑设备工程技术", "is_wenli": "文科", "jihua": 89, "baokao": 29, "luqu": 42 },
  { "name": "[540402] 供热通风与空调工程技术", "is_wenli": "文科", "jihua": 40, "baokao": 6, "luqu": 38 },
  { "name": "[540403] 建筑电气工程技术", "is_wenli": "文科", "jihua": 81, "baokao": 34, "luqu": 55 },
  { "name": "[540404] 建筑智能化工程技术", "is_wenli": "文科", "jihua": 398, "baokao": 339, "luqu": 354 },
  { "name": "[540406] 消防工程技术", "is_wenli": "文科", "jihua": 102, "baokao": 69, "luqu": 79 },
  { "name": "[5405 ] 建设工程管理类", "is_wenli": "文科", "jihua": 13, "baokao": 17, "luqu": 12 },
  { "name": "[540501] 建设工程管理", "is_wenli": "文科", "jihua": 528, "baokao": 306, "luqu": 390 },
  { "name": "[540502] 工程造价", "is_wenli": "文科", "jihua": 2584, "baokao": 3476, "luqu": 3013 },
  { "name": "[540503] 建筑经济管理", "is_wenli": "文科", "jihua": 99, "baokao": 55, "luqu": 83 },
  { "name": "[540504] 建设项目信息化管理", "is_wenli": "文科", "jihua": 114, "baokao": 25, "luqu": 100 },
  { "name": "[540505] 建设工程监理", "is_wenli": "文科", "jihua": 200, "baokao": 91, "luqu": 144 },
  { "name": "[54050F] 建设工程管理(中外合作办学)", "is_wenli": "文科", "jihua": 50, "baokao": 39, "luqu": 50 },
  { "name": "[54050G] 工程造价(中外合作办学)", "is_wenli": "文科", "jihua": 10, "baokao": 13, "luqu": 10 },
  { "name": "[540601] 市政工程技术", "is_wenli": "文科", "jihua": 196, "baokao": 81, "luqu": 129 },
  { "name": "[540602] 城市燃气工程技术", "is_wenli": "文科", "jihua": 30, "baokao": 6, "luqu": 23 },
  { "name": "[540603] 给排水工程技术", "is_wenli": "文科", "jihua": 64, "baokao": 21, "luqu": 65 },
  { "name": "[540701] 房地产经营与管理", "is_wenli": "文科", "jihua": 123, "baokao": 26, "luqu": 86 },
  { "name": "[540702] 房地产检测与估价", "is_wenli": "文科", "jihua": 26, "baokao": 2, "luqu": 4 },
  { "name": "[540703] 物业管理", "is_wenli": "文科", "jihua": 311, "baokao": 116, "luqu": 230 },
  { "name": "[550101] 水文与水资源工程", "is_wenli": "文科", "jihua": 62, "baokao": 65, "luqu": 62 },
  { "name": "[550103] 水政水资源管理", "is_wenli": "文科", "jihua": 16, "baokao": 5, "luqu": 16 },
  { "name": "[5502 ] 水利工程与管理类", "is_wenli": "文科", "jihua": 25, "baokao": 232, "luqu": 25 },
  { "name": "[550201] 水利工程", "is_wenli": "文科", "jihua": 57, "baokao": 42, "luqu": 56 },
  { "name": "[550202] 水利水电工程技术", "is_wenli": "文科", "jihua": 3, "baokao": 3, "luqu": 2 },
  { "name": "[550203] 水利水电工程管理", "is_wenli": "文科", "jihua": 30, "baokao": 8, "luqu": 24 },
  { "name": "[550204] 水利水电建筑工程", "is_wenli": "文科", "jihua": 82, "baokao": 43, "luqu": 78 },
  { "name": "[550401] 水土保持技术", "is_wenli": "文科", "jihua": 20, "baokao": 8, "luqu": 20 },
  { "name": "[5601 ] 机械设计制造类", "is_wenli": "文科", "jihua": 37, "baokao": 5, "luqu": 14 },
  { "name": "[560101] 机械设计与制造", "is_wenli": "文科", "jihua": 299, "baokao": 203, "luqu": 199 },
  { "name": "[560102] 机械制造与自动化", "is_wenli": "文科", "jihua": 636, "baokao": 488, "luqu": 445 },
  { "name": "[560103] 数控技术", "is_wenli": "文科", "jihua": 872, "baokao": 510, "luqu": 664 },
  { "name": "[560105] 特种加工技术", "is_wenli": "文科", "jihua": 57, "baokao": 1, "luqu": 13 },
  { "name": "[560106] 材料成型与控制技术", "is_wenli": "文科", "jihua": 29, "baokao": 7, "luqu": 4 },
  { "name": "[56010F] 机械设计与制造(中外合作办学)", "is_wenli": "文科", "jihua": 35, "baokao": 11, "luqu": 52 },
  { "name": "[56010G] 机械制造与自动化(中外合作办学)", "is_wenli": "文科", "jihua": 35, "baokao": 13, "luqu": 15 },
  { "name": "[560110] 焊接技术与自动化", "is_wenli": "文科", "jihua": 112, "baokao": 35, "luqu": 37 },
  { "name": "[560111] 机械产品检测检验技术", "is_wenli": "文科", "jihua": 15, "baokao": 4, "luqu": 6 },
  { "name": "[560112] 理化测试与质检技术", "is_wenli": "文科", "jihua": 8, "baokao": 1, "luqu": 8 },
  { "name": "[560113] 模具设计与制造", "is_wenli": "文科", "jihua": 434, "baokao": 175, "luqu": 291 },
  { "name": "[560115] 电线电缆制造技术", "is_wenli": "文科", "jihua": 31, "baokao": 4, "luqu": 2 },
  { "name": "[560117] 机械装备制造技术", "is_wenli": "文科", "jihua": 5, "baokao": 1, "luqu": 1 },
  { "name": "[560118] 工业设计", "is_wenli": "文科", "jihua": 97, "baokao": 69, "luqu": 78 },
  { "name": "[560119] 工业工程技术", "is_wenli": "文科", "jihua": 5, "baokao": 1, "luqu": 1 },
  { "name": "[560201] 自动化生产设备应用", "is_wenli": "文科", "jihua": 105, "baokao": 30, "luqu": 106 },
  { "name": "[560203] 机电设备维修与管理", "is_wenli": "文科", "jihua": 107, "baokao": 46, "luqu": 57 },
  { "name": "[560204] 数控设备应用与维护", "is_wenli": "文科", "jihua": 117, "baokao": 12, "luqu": 43 },
  { "name": "[560205] 制冷与空调技术", "is_wenli": "文科", "jihua": 81, "baokao": 6, "luqu": 34 },
  { "name": "[560206] 光电制造与应用技术", "is_wenli": "文科", "jihua": 15, "baokao": 3, "luqu": 2 },
  { "name": "[560207] 新能源装备技术", "is_wenli": "文科", "jihua": 13, "baokao": 2, "luqu": 2 },
  { "name": "[5603 ] 自动化类", "is_wenli": "文科", "jihua": 6, "baokao": 0, "luqu": 1 },
  { "name": "[560301] 机电一体化技术", "is_wenli": "文科", "jihua": 2064, "baokao": 2092, "luqu": 2255 },
  { "name": "[560302] 电气自动化技术", "is_wenli": "文科", "jihua": 1150, "baokao": 785, "luqu": 828 },
  { "name": "[560303] 工业过程自动化技术", "is_wenli": "文科", "jihua": 32, "baokao": 9, "luqu": 18 },
  { "name": "[560304] 智能控制技术", "is_wenli": "文科", "jihua": 604, "baokao": 316, "luqu": 431 },
  { "name": "[560305] 工业网络技术", "is_wenli": "文科", "jihua": 32, "baokao": 19, "luqu": 35 },
  { "name": "[560308] 电梯工程技术", "is_wenli": "文科", "jihua": 330, "baokao": 63, "luqu": 129 },
  { "name": "[560309] 工业机器人技术", "is_wenli": "文科", "jihua": 1269, "baokao": 511, "luqu": 725 },
  { "name": "[56030F] 机电一体化技术(中外合作办学)", "is_wenli": "文科", "jihua": 110, "baokao": 52, "luqu": 77 },
  { "name": "[56030G] 电气自动化技术(中外合作办学)", "is_wenli": "文科", "jihua": 58, "baokao": 45, "luqu": 54 },
  { "name": "[560501] 船舶工程技术", "is_wenli": "文科", "jihua": 14, "baokao": 27, "luqu": 19 },
  { "name": "[560502] 船舶机械工程技术", "is_wenli": "文科", "jihua": 4, "baokao": 1, "luqu": 7 },
  { "name": "[560503] 船舶电气工程技术", "is_wenli": "文科", "jihua": 9, "baokao": 1, "luqu": 4 },
  { "name": "[560506] 游艇设计与制造", "is_wenli": "文科", "jihua": 3, "baokao": 1, "luqu": 2 },
  { "name": "[560507] 海洋工程技术", "is_wenli": "文科", "jihua": 3, "baokao": 0, "luqu": 3 },
  { "name": "[560509] 船舶动力工程技术", "is_wenli": "文科", "jihua": 5, "baokao": 2, "luqu": 3 },
  { "name": "[560601] 飞行器制造技术", "is_wenli": "文科", "jihua": 8, "baokao": 8, "luqu": 11 },
  { "name": "[560602] 飞行器维修技术", "is_wenli": "文科", "jihua": 5, "baokao": 6, "luqu": 11 },
  { "name": "[560605] 航空发动机维修技术", "is_wenli": "文科", "jihua": 3, "baokao": 3, "luqu": 3 },
  { "name": "[560608] 航空电子电气技术", "is_wenli": "文科", "jihua": 3, "baokao": 0, "luqu": 4 },
  { "name": "[560609] 航空材料精密成型技术", "is_wenli": "文科", "jihua": 3, "baokao": 0, "luqu": 1 },
  { "name": "[560610] 无人机应用技术", "is_wenli": "文科", "jihua": 555, "baokao": 250, "luqu": 259 },
  { "name": "[5607 ] 汽车制造类", "is_wenli": "文科", "jihua": 4, "baokao": 6, "luqu": 2 },
  { "name": "[560701] 汽车制造与装配技术", "is_wenli": "文科", "jihua": 308, "baokao": 132, "luqu": 145 },
  { "name": "[560702] 汽车检测与维修技术", "is_wenli": "文科", "jihua": 1756, "baokao": 848, "luqu": 1047 },
  { "name": "[560703] 汽车电子技术", "is_wenli": "文科", "jihua": 217, "baokao": 33, "luqu": 58 },
  { "name": "[560706] 汽车改装技术", "is_wenli": "文科", "jihua": 5, "baokao": 5, "luqu": 5 },
  { "name": "[560707] 新能源汽车技术", "is_wenli": "文科", "jihua": 1454, "baokao": 1035, "luqu": 1057 },
  { "name": "[56070G] 汽车检测与维修技术(中外合作办学)", "is_wenli": "文科", "jihua": 75, "baokao": 1, "luqu": 25 },
  { "name": "[570101] 食品生物技术", "is_wenli": "文科", "jihua": 172, "baokao": 41, "luqu": 108 },
  { "name": "[570102] 化工生物技术", "is_wenli": "文科", "jihua": 5, "baokao": 6, "luqu": 5 },
  { "name": "[570103] 药品生物技术", "is_wenli": "文科", "jihua": 86, "baokao": 33, "luqu": 74 },
  { "name": "[570201] 应用化工技术", "is_wenli": "文科", "jihua": 149, "baokao": 76, "luqu": 96 },
  { "name": "[570203] 石油化工技术", "is_wenli": "文科", "jihua": 54, "baokao": 8, "luqu": 11 },
  { "name": "[570205] 精细化工技术", "is_wenli": "文科", "jihua": 19, "baokao": 8, "luqu": 23 },
  { "name": "[570207] 工业分析技术", "is_wenli": "文科", "jihua": 38, "baokao": 3, "luqu": 20 },
  { "name": "[570212] 煤化工技术", "is_wenli": "文科", "jihua": 23, "baokao": 0, "luqu": 1 },
  { "name": "[580101] 高分子材料加工技术", "is_wenli": "文科", "jihua": 20, "baokao": 2, "luqu": 20 },
  { "name": "[580105] 家具设计与制造", "is_wenli": "文科", "jihua": 15, "baokao": 1, "luqu": 1 },
  { "name": "[580106] 化妆品技术", "is_wenli": "文科", "jihua": 10, "baokao": 3, "luqu": 4 },
  { "name": "[580108] 皮具制作与工艺", "is_wenli": "文科", "jihua": 27, "baokao": 4, "luqu": 27 },
  { "name": "[580109] 鞋类设计与工艺", "is_wenli": "文科", "jihua": 7, "baokao": 1, "luqu": 1 },
  { "name": "[580110] 乐器制造与维护", "is_wenli": "文科", "jihua": 40, "baokao": 55, "luqu": 66 },
  { "name": "[580111] 陶瓷制造工艺", "is_wenli": "文科", "jihua": 10, "baokao": 6, "luqu": 9 },
  { "name": "[580201] 包装工程技术", "is_wenli": "文科", "jihua": 20, "baokao": 6, "luqu": 7 },
  { "name": "[580202] 包装策划与设计", "is_wenli": "文科", "jihua": 83, "baokao": 105, "luqu": 64 },
  { "name": "[580204] 食品包装技术", "is_wenli": "文科", "jihua": 18, "baokao": 0, "luqu": 3 },
  { "name": "[580301] 数字图文信息技术", "is_wenli": "文科", "jihua": 29, "baokao": 55, "luqu": 9 },
  { "name": "[580405] 纺织品检验与贸易", "is_wenli": "文科", "jihua": 11, "baokao": 1, "luqu": 4 },
  { "name": "[580406] 纺织品设计", "is_wenli": "文科", "jihua": 26, "baokao": 11, "luqu": 10 },
  { "name": "[580410] 服装设计与工艺", "is_wenli": "文科", "jihua": 143, "baokao": 138, "luqu": 128 },
  { "name": "[580412] 服装陈列与展示设计", "is_wenli": "文科", "jihua": 67, "baokao": 11, "luqu": 24 },
  { "name": "[58041G] 服装陈列与展示设计(中外合作办学)", "is_wenli": "文科", "jihua": 3, "baokao": 1, "luqu": 2 },
  { "name": "[590101] 食品加工技术", "is_wenli": "文科", "jihua": 501, "baokao": 144, "luqu": 172 },
  { "name": "[590102] 酿酒技术", "is_wenli": "文科", "jihua": 40, "baokao": 7, "luqu": 40 },
  { "name": "[590103] 食品质量与安全", "is_wenli": "文科", "jihua": 198, "baokao": 83, "luqu": 82 },
  { "name": "[590104] 食品贮运与营销", "is_wenli": "文科", "jihua": 65, "baokao": 3, "luqu": 21 },
  { "name": "[590105] 食品检测技术", "is_wenli": "文科", "jihua": 104, "baokao": 36, "luqu": 24 },
  { "name": "[590106] 食品营养与卫生", "is_wenli": "文科", "jihua": 5, "baokao": 13, "luqu": 5 },
  { "name": "[590107] 食品营养与检测", "is_wenli": "文科", "jihua": 638, "baokao": 481, "luqu": 462 },
  { "name": "[590201] 中药生产与加工", "is_wenli": "文科", "jihua": 12, "baokao": 1, "luqu": 3 },
  { "name": "[590202] 药品生产技术", "is_wenli": "文科", "jihua": 201, "baokao": 108, "luqu": 115 },
  { "name": "[590204] 药品质量与安全", "is_wenli": "文科", "jihua": 56, "baokao": 27, "luqu": 46 },
  { "name": "[590205] 制药设备应用技术", "is_wenli": "文科", "jihua": 4, "baokao": 0, "luqu": 1 },
  { "name": "[590206] 化学制药技术", "is_wenli": "文科", "jihua": 10, "baokao": 4, "luqu": 10 },
  { "name": "[590207] 生物制药技术", "is_wenli": "文科", "jihua": 27, "baokao": 6, "luqu": 10 },
  { "name": "[590208] 中药制药技术", "is_wenli": "文科", "jihua": 51, "baokao": 21, "luqu": 37 },
  { "name": "[590209] 药物制剂技术", "is_wenli": "文科", "jihua": 53, "baokao": 5, "luqu": 44 },
  { "name": "[590301] 药品经营与管理", "is_wenli": "文科", "jihua": 299, "baokao": 164, "luqu": 220 },
  { "name": "[590302] 药品服务与管理", "is_wenli": "文科", "jihua": 7, "baokao": 0, "luqu": 1 },
  { "name": "[590303] 保健品开发与管理", "is_wenli": "文科", "jihua": 4, "baokao": 1, "luqu": 4 },
  { "name": "[590304] 化妆品经营与管理", "is_wenli": "文科", "jihua": 48, "baokao": 35, "luqu": 47 },
  { "name": "[590305] 食品药品监督管理", "is_wenli": "文科", "jihua": 184, "baokao": 84, "luqu": 125 },
  { "name": "[590401] 粮食工程技术", "is_wenli": "文科", "jihua": 75, "baokao": 2, "luqu": 5 },
  { "name": "[590501] 粮油储藏与检测技术", "is_wenli": "文科", "jihua": 65, "baokao": 2, "luqu": 4 },
  { "name": "[600101] 铁道机车", "is_wenli": "文科", "jihua": 52, "baokao": 160, "luqu": 70 },
  { "name": "[600102] 铁道车辆", "is_wenli": "文科", "jihua": 40, "baokao": 20, "luqu": 37 },
  { "name": "[600103] 铁道供电技术", "is_wenli": "文科", "jihua": 143, "baokao": 36, "luqu": 78 },
  { "name": "[600104] 铁道工程技术", "is_wenli": "文科", "jihua": 35, "baokao": 49, "luqu": 35 },
  { "name": "[600105] 铁道机械化维修技术", "is_wenli": "文科", "jihua": 6, "baokao": 2, "luqu": 7 },
  { "name": "[600106] 铁道信号自动控制", "is_wenli": "文科", "jihua": 16, "baokao": 32, "luqu": 17 },
  { "name": "[600107] 铁道通信与信息化技术", "is_wenli": "文科", "jihua": 153, "baokao": 43, "luqu": 69 },
  { "name": "[600108] 铁道交通运营管理", "is_wenli": "文科", "jihua": 117, "baokao": 812, "luqu": 120 },
  { "name": "[600109] 铁路物流管理", "is_wenli": "文科", "jihua": 11, "baokao": 6, "luqu": 11 },
  { "name": "[60010F] 铁道机车(中外合作办学)", "is_wenli": "文科", "jihua": 73, "baokao": 217, "luqu": 73 },
  { "name": "[60010G] 铁道车辆(中外合作办学)", "is_wenli": "文科", "jihua": 28, "baokao": 48, "luqu": 28 },
  { "name": "[60010H] 铁道供电技术(中外合作办学)", "is_wenli": "文科", "jihua": 73, "baokao": 86, "luqu": 73 },
  { "name": "[60010I] 铁道工程技术(中外合作办学)", "is_wenli": "文科", "jihua": 48, "baokao": 27, "luqu": 48 },
  { "name": "[60010J] 铁道机械化维修技术(中外合作办学)", "is_wenli": "文科", "jihua": 53, "baokao": 32, "luqu": 53 },
  { "name": "[60010K] 铁道信号自动控制(中外合作办学)", "is_wenli": "文科", "jihua": 53, "baokao": 65, "luqu": 53 },
  { "name": "[60010L] 铁道通信与信息化技术(中外合作办学)", "is_wenli": "文科", "jihua": 28, "baokao": 14, "luqu": 28 },
  { "name": "[60010M] 铁道交通运营管理(中外合作办学)", "is_wenli": "文科", "jihua": 53, "baokao": 116, "luqu": 53 },
  { "name": "[600110] 铁路桥梁与隧道工程技术", "is_wenli": "文科", "jihua": 26, "baokao": 24, "luqu": 21 },
  { "name": "[600111] 高速铁道工程技术", "is_wenli": "文科", "jihua": 52, "baokao": 18, "luqu": 42 },
  { "name": "[600112] 高速铁路客运乘务", "is_wenli": "文科", "jihua": 1126, "baokao": 976, "luqu": 911 },
  { "name": "[600113] 动车组检修技术", "is_wenli": "文科", "jihua": 27, "baokao": 31, "luqu": 27 },
  { "name": "[600114] 高铁综合维修技术", "is_wenli": "文科", "jihua": 12, "baokao": 4, "luqu": 12 },
  { "name": "[60011F] 高速铁道工程技术(中外合作办学)", "is_wenli": "文科", "jihua": 25, "baokao": 13, "luqu": 25 },
  { "name": "[6002 ] 道路运输类", "is_wenli": "文科", "jihua": 2, "baokao": 2, "luqu": 2 },
  { "name": "[600201] 智能交通技术运用", "is_wenli": "文科", "jihua": 29, "baokao": 38, "luqu": 28 },
  { "name": "[600202] 道路桥梁工程技术", "is_wenli": "文科", "jihua": 531, "baokao": 344, "luqu": 367 },
  { "name": "[600203] 道路运输与路政管理", "is_wenli": "文科", "jihua": 5, "baokao": 9, "luqu": 5 },
  { "name": "[600204] 道路养护与管理", "is_wenli": "文科", "jihua": 17, "baokao": 13, "luqu": 13 },
  { "name": "[600206] 工程机械运用技术", "is_wenli": "文科", "jihua": 50, "baokao": 20, "luqu": 42 },
  { "name": "[600207] 交通运营管理", "is_wenli": "文科", "jihua": 31, "baokao": 7, "luqu": 10 },
  { "name": "[600209] 汽车运用与维修技术", "is_wenli": "文科", "jihua": 500, "baokao": 62, "luqu": 145 },
  { "name": "[60020G] 道路桥梁工程技术(中外合作办学)", "is_wenli": "文科", "jihua": 30, "baokao": 27, "luqu": 30 },
  { "name": "[600210] 汽车车身维修技术", "is_wenli": "文科", "jihua": 47, "baokao": 9, "luqu": 19 },
  { "name": "[600212] 新能源汽车运用与维修", "is_wenli": "文科", "jihua": 74, "baokao": 44, "luqu": 68 },
  { "name": "[6003 ] 水上运输类", "is_wenli": "文科", "jihua": 3, "baokao": 4, "luqu": 3 },
  { "name": "[600301] 航海技术", "is_wenli": "文科", "jihua": 76, "baokao": 132, "luqu": 86 },
  { "name": "[600302] 国际邮轮乘务管理", "is_wenli": "文科", "jihua": 228, "baokao": 101, "luqu": 148 },
  { "name": "[600303] 船舶电子电气技术", "is_wenli": "文科", "jihua": 7, "baokao": 2, "luqu": 6 },
  { "name": "[600305] 港口机械与自动控制", "is_wenli": "文科", "jihua": 8, "baokao": 1, "luqu": 4 },
  { "name": "[600308] 港口与航运管理", "is_wenli": "文科", "jihua": 44, "baokao": 32, "luqu": 43 },
  { "name": "[600309] 港口物流管理", "is_wenli": "文科", "jihua": 2, "baokao": 0, "luqu": 2 },
  { "name": "[600310] 轮机工程技术", "is_wenli": "文科", "jihua": 68, "baokao": 50, "luqu": 62 },
  { "name": "[600312] 水路运输与海事管理", "is_wenli": "文科", "jihua": 18, "baokao": 4, "luqu": 10 },
  { "name": "[600313] 集装箱运输管理", "is_wenli": "文科", "jihua": 16, "baokao": 14, "luqu": 15 },
  { "name": "[6004 ] 航空运输类", "is_wenli": "文科", "jihua": 2, "baokao": 1, "luqu": 1 },
  { "name": "[600401] 民航运输", "is_wenli": "文科", "jihua": 147, "baokao": 230, "luqu": 79 },
  { "name": "[600403] 定翼机驾驶技术", "is_wenli": "文科", "jihua": 16, "baokao": 3, "luqu": 3 },
  { "name": "[600404] 直升机驾驶技术", "is_wenli": "文科", "jihua": 18, "baokao": 4, "luqu": 3 },
  { "name": "[600405] 空中乘务", "is_wenli": "文科", "jihua": 1383, "baokao": 686, "luqu": 962 },
  { "name": "[600406] 民航安全技术管理", "is_wenli": "文科", "jihua": 121, "baokao": 53, "luqu": 70 },
  { "name": "[600407] 民航空中安全保卫", "is_wenli": "文科", "jihua": 72, "baokao": 10, "luqu": 16 },
  { "name": "[600408] 机场运行", "is_wenli": "文科", "jihua": 75, "baokao": 31, "luqu": 32 },
  { "name": "[600409] 飞机机电设备维修", "is_wenli": "文科", "jihua": 207, "baokao": 76, "luqu": 89 },
  { "name": "[600410] 飞机电子设备维修", "is_wenli": "文科", "jihua": 52, "baokao": 8, "luqu": 16 },
  { "name": "[600411] 飞机部件修理", "is_wenli": "文科", "jihua": 20, "baokao": 3, "luqu": 4 },
  { "name": "[600413] 机场场务技术与管理", "is_wenli": "文科", "jihua": 1, "baokao": 3, "luqu": 1 },
  { "name": "[600415] 航空物流", "is_wenli": "文科", "jihua": 162, "baokao": 74, "luqu": 77 },
  { "name": "[600416] 通用航空器维修", "is_wenli": "文科", "jihua": 8, "baokao": 1, "luqu": 1 },
  { "name": "[600417] 通用航空航务技术", "is_wenli": "文科", "jihua": 21, "baokao": 1, "luqu": 2 },
  { "name": "[600418] 飞机结构修理", "is_wenli": "文科", "jihua": 32, "baokao": 4, "luqu": 5 },
  { "name": "[60041J] 航空物流(中外合作办学)", "is_wenli": "文科", "jihua": 20, "baokao": 0, "luqu": 20 },
  { "name": "[600601] 城市轨道交通车辆技术", "is_wenli": "文科", "jihua": 310, "baokao": 103, "luqu": 160 },
  { "name": "[600602] 城市轨道交通机电技术", "is_wenli": "文科", "jihua": 159, "baokao": 104, "luqu": 91 },
  { "name": "[600603] 城市轨道交通通信信号技术", "is_wenli": "文科", "jihua": 57, "baokao": 55, "luqu": 47 },
  { "name": "[600604] 城市轨道交通供配电技术", "is_wenli": "文科", "jihua": 23, "baokao": 8, "luqu": 23 },
  { "name": "[600605] 城市轨道交通工程技术", "is_wenli": "文科", "jihua": 273, "baokao": 221, "luqu": 198 },
  { "name": "[600606] 城市轨道交通运营管理", "is_wenli": "文科", "jihua": 1502, "baokao": 1237, "luqu": 1069 },
  { "name": "[600701] 邮政通信管理", "is_wenli": "文科", "jihua": 13, "baokao": 67, "luqu": 13 },
  { "name": "[600702] 快递运营管理", "is_wenli": "文科", "jihua": 7, "baokao": 1, "luqu": 2 },
  { "name": "[6101 ] 电子信息类", "is_wenli": "文科", "jihua": 10, "baokao": 58, "luqu": 17 },
  { "name": "[610101] 电子信息工程技术", "is_wenli": "文科", "jihua": 614, "baokao": 481, "luqu": 513 },
  { "name": "[610102] 应用电子技术", "is_wenli": "文科", "jihua": 445, "baokao": 291, "luqu": 310 },
  { "name": "[610103] 微电子技术", "is_wenli": "文科", "jihua": 16, "baokao": 21, "luqu": 14 },
  { "name": "[610104] 智能产品开发", "is_wenli": "文科", "jihua": 41, "baokao": 9, "luqu": 13 },
  { "name": "[610105] 智能终端技术与应用", "is_wenli": "文科", "jihua": 20, "baokao": 15, "luqu": 19 },
  { "name": "[610107] 汽车智能技术", "is_wenli": "文科", "jihua": 219, "baokao": 91, "luqu": 124 },
  { "name": "[610115] 移动互联应用技术", "is_wenli": "文科", "jihua": 207, "baokao": 209, "luqu": 304 },
  { "name": "[610119] 物联网应用技术", "is_wenli": "文科", "jihua": 1219, "baokao": 1035, "luqu": 1161 },
  { "name": "[6102 ] 计算机类", "is_wenli": "文科", "jihua": 333, "baokao": 470, "luqu": 307 },
  { "name": "[610201] 计算机应用技术", "is_wenli": "文科", "jihua": 4866, "baokao": 7658, "luqu": 6481 },
  { "name": "[610202] 计算机网络技术", "is_wenli": "文科", "jihua": 2885, "baokao": 3822, "luqu": 3283 },
  { "name": "[610203] 计算机信息管理", "is_wenli": "文科", "jihua": 571, "baokao": 441, "luqu": 416 },
  { "name": "[610204] 计算机系统与维护", "is_wenli": "文科", "jihua": 55, "baokao": 12, "luqu": 24 },
  { "name": "[610205] 软件技术", "is_wenli": "文科", "jihua": 2446, "baokao": 2805, "luqu": 2831 },
  { "name": "[610206] 软件与信息服务", "is_wenli": "文科", "jihua": 91, "baokao": 128, "luqu": 86 },
  { "name": "[610207] 动漫制作技术", "is_wenli": "文科", "jihua": 1064, "baokao": 972, "luqu": 1090 },
  { "name": "[610208] 嵌入式技术与应用", "is_wenli": "文科", "jihua": 4, "baokao": 2, "luqu": 4 },
  { "name": "[610209] 数字展示技术", "is_wenli": "文科", "jihua": 49, "baokao": 24, "luqu": 39 },
  { "name": "[61020F] 计算机应用技术(中外合作办学)", "is_wenli": "文科", "jihua": 121, "baokao": 75, "luqu": 85 },
  { "name": "[61020G] 计算机网络技术(中外合作办学)", "is_wenli": "文科", "jihua": 138, "baokao": 183, "luqu": 142 },
  { "name": "[61020H] 计算机信息管理(中外合作办学)", "is_wenli": "文科", "jihua": 145, "baokao": 134, "luqu": 108 },
  { "name": "[61020J] 软件技术(中外合作办学)", "is_wenli": "文科", "jihua": 5, "baokao": 0, "luqu": 2 },
  { "name": "[61020L] 动漫制作技术(中外合作办学)", "is_wenli": "文科", "jihua": 39, "baokao": 2, "luqu": 4 },
  { "name": "[610210] 数字媒体应用技术", "is_wenli": "文科", "jihua": 1416, "baokao": 2249, "luqu": 1708 },
  { "name": "[610211] 信息安全与管理", "is_wenli": "文科", "jihua": 394, "baokao": 324, "luqu": 388 },
  { "name": "[610212] 移动应用开发", "is_wenli": "文科", "jihua": 541, "baokao": 406, "luqu": 587 },
  { "name": "[610213] 云计算技术与应用", "is_wenli": "文科", "jihua": 371, "baokao": 196, "luqu": 261 },
  { "name": "[610214] 电子商务技术", "is_wenli": "文科", "jihua": 574, "baokao": 772, "luqu": 733 },
  { "name": "[610215] 大数据技术与应用", "is_wenli": "文科", "jihua": 1793, "baokao": 2715, "luqu": 2134 },
  { "name": "[610216] 虚拟现实应用技术", "is_wenli": "文科", "jihua": 351, "baokao": 216, "luqu": 264 },
  { "name": "[610217] 人工智能技术服务", "is_wenli": "文科", "jihua": 496, "baokao": 188, "luqu": 266 },
  { "name": "[61021E] 数字媒体应用技术(中外合作办学)", "is_wenli": "文科", "jihua": 18, "baokao": 38, "luqu": 18 },
  { "name": "[61021J] 大数据技术与应用(中外合作办学)", "is_wenli": "文科", "jihua": 1, "baokao": 2, "luqu": 1 },
  { "name": "[610301] 通信技术", "is_wenli": "文科", "jihua": 400, "baokao": 154, "luqu": 197 },
  { "name": "[610302] 移动通信技术", "is_wenli": "文科", "jihua": 230, "baokao": 83, "luqu": 142 },
  { "name": "[610304] 通信工程设计与监理", "is_wenli": "文科", "jihua": 35, "baokao": 3, "luqu": 2 },
  { "name": "[610305] 电信服务与管理", "is_wenli": "文科", "jihua": 18, "baokao": 1, "luqu": 3 },
  { "name": "[610307] 物联网工程技术", "is_wenli": "文科", "jihua": 58, "baokao": 43, "luqu": 40 },
  { "name": "[620101] 临床医学", "is_wenli": "文科", "jihua": 254, "baokao": 360, "luqu": 246 },
  { "name": "[620102] 口腔医学", "is_wenli": "文科", "jihua": 166, "baokao": 867, "luqu": 278 },
  { "name": "[620103] 中医学", "is_wenli": "文科", "jihua": 66, "baokao": 238, "luqu": 102 },
  { "name": "[620104] 中医骨伤", "is_wenli": "文科", "jihua": 11, "baokao": 6, "luqu": 5 },
  { "name": "[620105] 针灸推拿", "is_wenli": "文科", "jihua": 59, "baokao": 99, "luqu": 48 },
  { "name": "[62010F] 临床医学(中外合作办学)", "is_wenli": "文科", "jihua": 180, "baokao": 507, "luqu": 180 },
  { "name": "[62010G] 口腔医学(中外合作办学)", "is_wenli": "文科", "jihua": 65, "baokao": 803, "luqu": 65 },
  { "name": "[6202 ] 护理类", "is_wenli": "文科", "jihua": 131, "baokao": 229, "luqu": 130 },
  { "name": "[620201] 护理", "is_wenli": "文科", "jihua": 8874, "baokao": 11505, "luqu": 10055 },
  { "name": "[620202] 助产", "is_wenli": "文科", "jihua": 1104, "baokao": 697, "luqu": 809 },
  { "name": "[62020F] 护理(中外合作办学)", "is_wenli": "文科", "jihua": 596, "baokao": 431, "luqu": 684 },
  { "name": "[620301] 药学", "is_wenli": "文科", "jihua": 1317, "baokao": 1341, "luqu": 1341 },
  { "name": "[620302] 中药学", "is_wenli": "文科", "jihua": 781, "baokao": 1001, "luqu": 814 },
  { "name": "[62030F] 药学(中外合作办学)", "is_wenli": "文科", "jihua": 30, "baokao": 18, "luqu": 30 },
  { "name": "[6204 ] 医学技术类", "is_wenli": "文科", "jihua": 6, "baokao": 0, "luqu": 3 },
  { "name": "[620401] 医学检验技术", "is_wenli": "文科", "jihua": 1185, "baokao": 1721, "luqu": 1432 },
  { "name": "[620403] 医学影像技术", "is_wenli": "文科", "jihua": 1319, "baokao": 2430, "luqu": 1767 },
  { "name": "[620404] 医学美容技术", "is_wenli": "文科", "jihua": 93, "baokao": 61, "luqu": 62 },
  { "name": "[620405] 口腔医学技术", "is_wenli": "文科", "jihua": 767, "baokao": 1206, "luqu": 960 },
  { "name": "[620406] 卫生检验与检疫技术", "is_wenli": "文科", "jihua": 36, "baokao": 8, "luqu": 18 },
  { "name": "[620407] 眼视光技术", "is_wenli": "文科", "jihua": 269, "baokao": 414, "luqu": 320 },
  { "name": "[62040F] 医学检验技术(中外合作办学)", "is_wenli": "文科", "jihua": 60, "baokao": 43, "luqu": 60 },
  { "name": "[62040H] 医学影像技术(中外合作办学)", "is_wenli": "文科", "jihua": 66, "baokao": 68, "luqu": 66 },
  { "name": "[6205 ] 康复治疗类", "is_wenli": "文科", "jihua": 25, "baokao": 22, "luqu": 30 },
  { "name": "[620501] 康复治疗技术", "is_wenli": "文科", "jihua": 1636, "baokao": 1424, "luqu": 1153 },
  { "name": "[620503] 中医康复技术", "is_wenli": "文科", "jihua": 143, "baokao": 60, "luqu": 48 },
  { "name": "[62050F] 康复治疗技术(中外合作办学)", "is_wenli": "文科", "jihua": 82, "baokao": 28, "luqu": 75 },
  { "name": "[620601] 预防医学", "is_wenli": "文科", "jihua": 143, "baokao": 85, "luqu": 45 },
  { "name": "[620602] 公共卫生管理", "is_wenli": "文科", "jihua": 4, "baokao": 0, "luqu": 1 },
  { "name": "[620603] 卫生监督", "is_wenli": "文科", "jihua": 15, "baokao": 12, "luqu": 13 },
  { "name": "[620604] 卫生信息管理", "is_wenli": "文科", "jihua": 66, "baokao": 57, "luqu": 50 },
  { "name": "[620801] 健康管理", "is_wenli": "文科", "jihua": 452, "baokao": 188, "luqu": 280 },
  { "name": "[620802] 医学营养", "is_wenli": "文科", "jihua": 148, "baokao": 89, "luqu": 95 },
  { "name": "[620803] 中医养生保健", "is_wenli": "文科", "jihua": 260, "baokao": 86, "luqu": 167 },
  { "name": "[620804] 心理咨询", "is_wenli": "文科", "jihua": 126, "baokao": 260, "luqu": 133 },
  { "name": "[620805] 医疗设备应用技术", "is_wenli": "文科", "jihua": 30, "baokao": 13, "luqu": 12 },
  { "name": "[620807] 医疗器械维护与管理", "is_wenli": "文科", "jihua": 53, "baokao": 24, "luqu": 37 },
  { "name": "[620809] 康复辅助器具技术", "is_wenli": "文科", "jihua": 15, "baokao": 2, "luqu": 15 },
  { "name": "[620811] 老年保健与管理", "is_wenli": "文科", "jihua": 140, "baokao": 15, "luqu": 69 },
  { "name": "[620812] 医疗器械经营与管理", "is_wenli": "文科", "jihua": 1, "baokao": 2, "luqu": 1 },
  { "name": "[630101] 财政", "is_wenli": "文科", "jihua": 1, "baokao": 0, "luqu": 1 },
  { "name": "[630102] 税务", "is_wenli": "文科", "jihua": 145, "baokao": 91, "luqu": 131 },
  { "name": "[630103] 资产评估与管理", "is_wenli": "文科", "jihua": 78, "baokao": 21, "luqu": 74 },
  { "name": "[630104] 政府采购管理", "is_wenli": "文科", "jihua": 8, "baokao": 4, "luqu": 7 },
  { "name": "[6302 ] 金融类", "is_wenli": "文科", "jihua": 15, "baokao": 85, "luqu": 15 },
  { "name": "[630201] 金融管理", "is_wenli": "文科", "jihua": 1200, "baokao": 928, "luqu": 1101 },
  { "name": "[630202] 国际金融", "is_wenli": "文科", "jihua": 42, "baokao": 17, "luqu": 23 },
  { "name": "[630203] 证券与期货", "is_wenli": "文科", "jihua": 149, "baokao": 52, "luqu": 123 },
  { "name": "[630205] 保险", "is_wenli": "文科", "jihua": 33, "baokao": 18, "luqu": 22 },
  { "name": "[630206] 投资与理财", "is_wenli": "文科", "jihua": 555, "baokao": 263, "luqu": 403 },
  { "name": "[630207] 信用管理", "is_wenli": "文科", "jihua": 2, "baokao": 2, "luqu": 2 },
  { "name": "[630209] 互联网金融", "is_wenli": "文科", "jihua": 587, "baokao": 290, "luqu": 403 },
  { "name": "[63020F] 金融管理(中外合作办学)", "is_wenli": "文科", "jihua": 105, "baokao": 104, "luqu": 105 },
  { "name": "[63020G] 国际金融(中外合作办学)", "is_wenli": "文科", "jihua": 97, "baokao": 139, "luqu": 80 },
  { "name": "[6303 ] 财务会计类", "is_wenli": "文科", "jihua": 11, "baokao": 37, "luqu": 13 },
  { "name": "[630301] 财务管理", "is_wenli": "文科", "jihua": 1872, "baokao": 1492, "luqu": 1651 },
  { "name": "[630302] 会计", "is_wenli": "文科", "jihua": 7590, "baokao": 12074, "luqu": 9330 },
  { "name": "[630303] 审计", "is_wenli": "文科", "jihua": 397, "baokao": 319, "luqu": 299 },
  { "name": "[630304] 会计信息管理", "is_wenli": "文科", "jihua": 230, "baokao": 140, "luqu": 189 },
  { "name": "[63030F] 财务管理(中外合作办学)", "is_wenli": "文科", "jihua": 258, "baokao": 135, "luqu": 258 },
  { "name": "[63030G] 会计(中外合作办学)", "is_wenli": "文科", "jihua": 835, "baokao": 947, "luqu": 900 },
  { "name": "[630401] 信息统计与分析", "is_wenli": "文科", "jihua": 33, "baokao": 7, "luqu": 33 },
  { "name": "[630402] 统计与会计核算", "is_wenli": "文科", "jihua": 103, "baokao": 40, "luqu": 80 },
  { "name": "[6305 ] 经济贸易类", "is_wenli": "文科", "jihua": 2, "baokao": 1, "luqu": 2 },
  { "name": "[630501] 国际贸易实务", "is_wenli": "文科", "jihua": 182, "baokao": 92, "luqu": 158 },
  { "name": "[630502] 国际经济与贸易", "is_wenli": "文科", "jihua": 606, "baokao": 307, "luqu": 441 },
  { "name": "[630503] 国际商务", "is_wenli": "文科", "jihua": 321, "baokao": 242, "luqu": 217 },
  { "name": "[630505] 经济信息管理", "is_wenli": "文科", "jihua": 127, "baokao": 80, "luqu": 84 },
  { "name": "[630506] 报关与国际货运", "is_wenli": "文科", "jihua": 200, "baokao": 65, "luqu": 93 },
  { "name": "[63050G] 国际经济与贸易(中外合作办学)", "is_wenli": "文科", "jihua": 4, "baokao": 1, "luqu": 1 },
  { "name": "[63050H] 国际商务(中外合作办学)", "is_wenli": "文科", "jihua": 2, "baokao": 1, "luqu": 1 },
  { "name": "[6306 ] 工商管理类", "is_wenli": "文科", "jihua": 7, "baokao": 14, "luqu": 6 },
  { "name": "[630601] 工商企业管理", "is_wenli": "文科", "jihua": 1357, "baokao": 958, "luqu": 1026 },
  { "name": "[630602] 商务管理", "is_wenli": "文科", "jihua": 133, "baokao": 45, "luqu": 54 },
  { "name": "[630603] 商检技术", "is_wenli": "文科", "jihua": 60, "baokao": 7, "luqu": 16 },
  { "name": "[630604] 连锁经营管理", "is_wenli": "文科", "jihua": 303, "baokao": 99, "luqu": 266 },
  { "name": "[630607] 中小企业创业与经营", "is_wenli": "文科", "jihua": 34, "baokao": 3, "luqu": 11 },
  { "name": "[63060F] 工商企业管理(中外合作办学)", "is_wenli": "文科", "jihua": 123, "baokao": 98, "luqu": 70 },
  { "name": "[6307 ] 市场营销类", "is_wenli": "文科", "jihua": 45, "baokao": 7, "luqu": 16 },
  { "name": "[630701] 市场营销", "is_wenli": "文科", "jihua": 3259, "baokao": 2079, "luqu": 2478 },
  { "name": "[630702] 汽车营销与服务", "is_wenli": "文科", "jihua": 677, "baokao": 60, "luqu": 212 },
  { "name": "[630703] 广告策划与营销", "is_wenli": "文科", "jihua": 21, "baokao": 9, "luqu": 15 },
  { "name": "[630704] 茶艺与茶叶营销", "is_wenli": "文科", "jihua": 16, "baokao": 0, "luqu": 3 },
  { "name": "[63070F] 市场营销(中外合作办学)", "is_wenli": "文科", "jihua": 219, "baokao": 89, "luqu": 202 },
  { "name": "[6308 ] 电子商务类", "is_wenli": "文科", "jihua": 45, "baokao": 33, "luqu": 7 },
  { "name": "[630801] 电子商务", "is_wenli": "文科", "jihua": 5723, "baokao": 5851, "luqu": 5828 },
  { "name": "[630802] 移动商务", "is_wenli": "文科", "jihua": 55, "baokao": 49, "luqu": 66 },
  { "name": "[630803] 网络营销", "is_wenli": "文科", "jihua": 691, "baokao": 1040, "luqu": 690 },
  { "name": "[630804] 商务数据分析与应用", "is_wenli": "文科", "jihua": 225, "baokao": 68, "luqu": 129 },
  { "name": "[630805] 跨境电子商务", "is_wenli": "文科", "jihua": 831, "baokao": 230, "luqu": 539 },
  { "name": "[63080F] 电子商务(中外合作办学)", "is_wenli": "文科", "jihua": 359, "baokao": 187, "luqu": 400 },
  { "name": "[63080J] 跨境电子商务(中外合作办学)", "is_wenli": "文科", "jihua": 1, "baokao": 0, "luqu": 1 },
  { "name": "[6309 ] 物流类", "is_wenli": "文科", "jihua": 6, "baokao": 7, "luqu": 6 },
  { "name": "[630901] 物流工程技术", "is_wenli": "文科", "jihua": 5, "baokao": 1, "luqu": 1 },
  { "name": "[630902] 物流信息技术", "is_wenli": "文科", "jihua": 30, "baokao": 6, "luqu": 30 },
  { "name": "[630903] 物流管理", "is_wenli": "文科", "jihua": 2748, "baokao": 1676, "luqu": 2168 },
  { "name": "[630904] 物流金融管理", "is_wenli": "文科", "jihua": 25, "baokao": 1, "luqu": 5 },
  { "name": "[630906] 冷链物流技术与管理", "is_wenli": "文科", "jihua": 26, "baokao": 0, "luqu": 16 },
  { "name": "[630907] 采购与供应管理", "is_wenli": "文科", "jihua": 13, "baokao": 7, "luqu": 9 },
  { "name": "[63090H] 物流管理(中外合作办学)", "is_wenli": "文科", "jihua": 270, "baokao": 68, "luqu": 243 },
  { "name": "[6401 ] 旅游类", "is_wenli": "文科", "jihua": 17, "baokao": 6, "luqu": 14 },
  { "name": "[640101] 旅游管理", "is_wenli": "文科", "jihua": 3116, "baokao": 1365, "luqu": 2222 },
  { "name": "[640102] 导游", "is_wenli": "文科", "jihua": 70, "baokao": 48, "luqu": 66 },
  { "name": "[640103] 旅行社经营管理", "is_wenli": "文科", "jihua": 55, "baokao": 10, "luqu": 55 },
  { "name": "[640104] 景区开发与管理", "is_wenli": "文科", "jihua": 20, "baokao": 6, "luqu": 20 },
  { "name": "[640105] 酒店管理", "is_wenli": "文科", "jihua": 2587, "baokao": 765, "luqu": 1587 },
  { "name": "[640106] 休闲服务与管理", "is_wenli": "文科", "jihua": 59, "baokao": 26, "luqu": 31 },
  { "name": "[640107] 研学旅行管理与服务", "is_wenli": "文科", "jihua": 90, "baokao": 27, "luqu": 39 },
  { "name": "[640108] 葡萄酒营销与服务", "is_wenli": "文科", "jihua": 1, "baokao": 0, "luqu": 1 },
  { "name": "[64010F] 旅游管理(中外合作办学)", "is_wenli": "文科", "jihua": 29, "baokao": 9, "luqu": 13 },
  { "name": "[64010J] 酒店管理(中外合作办学)", "is_wenli": "文科", "jihua": 19, "baokao": 7, "luqu": 7 },
  { "name": "[6402 ] 餐饮类", "is_wenli": "文科", "jihua": 2, "baokao": 2, "luqu": 2 },
  { "name": "[640201] 餐饮管理", "is_wenli": "文科", "jihua": 41, "baokao": 6, "luqu": 22 },
  { "name": "[640202] 烹调工艺与营养", "is_wenli": "文科", "jihua": 421, "baokao": 159, "luqu": 217 },
  { "name": "[640203] 营养配餐", "is_wenli": "文科", "jihua": 97, "baokao": 35, "luqu": 43 },
  { "name": "[640204] 中西面点工艺", "is_wenli": "文科", "jihua": 146, "baokao": 134, "luqu": 114 },
  { "name": "[640205] 西餐工艺", "is_wenli": "文科", "jihua": 147, "baokao": 43, "luqu": 72 },
  { "name": "[640301] 会展策划与管理", "is_wenli": "文科", "jihua": 204, "baokao": 181, "luqu": 157 },
  { "name": "[64030F] 会展策划与管理(中外合作办学)", "is_wenli": "文科", "jihua": 2, "baokao": 1, "luqu": 2 },
  { "name": "[650101] 艺术设计", "is_wenli": "文科", "jihua": 116, "baokao": 53, "luqu": 141 },
  { "name": "[650102] 视觉传播设计与制作", "is_wenli": "文科", "jihua": 215, "baokao": 40, "luqu": 118 },
  { "name": "[650103] 广告设计与制作", "is_wenli": "文科", "jihua": 176, "baokao": 172, "luqu": 169 },
  { "name": "[650104] 数字媒体艺术设计", "is_wenli": "文科", "jihua": 118, "baokao": 31, "luqu": 66 },
  { "name": "[650105] 产品艺术设计", "is_wenli": "文科", "jihua": 3, "baokao": 1, "luqu": 1 },
  { "name": "[650108] 服装与服饰设计", "is_wenli": "文科", "jihua": 26, "baokao": 18, "luqu": 17 },
  { "name": "[650109] 室内艺术设计", "is_wenli": "文科", "jihua": 38, "baokao": 17, "luqu": 46 },
  { "name": "[65010I] 数字媒体艺术设计(中外合作办学)", "is_wenli": "文科", "jihua": 4, "baokao": 9, "luqu": 4 },
  { "name": "[65010M] 服装与服饰设计(中外合作办学)", "is_wenli": "文科", "jihua": 8, "baokao": 2, "luqu": 3 },
  { "name": "[650111] 环境艺术设计", "is_wenli": "文科", "jihua": 209, "baokao": 10, "luqu": 47 },
  { "name": "[650115] 陶瓷设计与工艺", "is_wenli": "文科", "jihua": 105, "baokao": 2, "luqu": 4 },
  { "name": "[650117] 玉器设计与工艺", "is_wenli": "文科", "jihua": 30, "baokao": 0, "luqu": 30 },
  { "name": "[650118] 首饰设计与工艺", "is_wenli": "文科", "jihua": 5, "baokao": 4, "luqu": 4 },
  { "name": "[650119] 工艺美术品设计", "is_wenli": "文科", "jihua": 21, "baokao": 1, "luqu": 15 },
  { "name": "[650120] 动漫设计", "is_wenli": "文科", "jihua": 83, "baokao": 40, "luqu": 49 },
  { "name": "[650121] 游戏设计", "is_wenli": "文科", "jihua": 23, "baokao": 2, "luqu": 12 },
  { "name": "[650122] 人物形象设计", "is_wenli": "文科", "jihua": 83, "baokao": 48, "luqu": 77 },
  { "name": "[650123] 美容美体艺术", "is_wenli": "文科", "jihua": 11, "baokao": 17, "luqu": 5 },
  { "name": "[650125] 美术", "is_wenli": "文科", "jihua": 12, "baokao": 2, "luqu": 1 },
  { "name": "[650201] 表演艺术", "is_wenli": "文科", "jihua": 23, "baokao": 3, "luqu": 6 },
  { "name": "[650202] 戏剧影视表演", "is_wenli": "文科", "jihua": 3, "baokao": 9, "luqu": 5 },
  { "name": "[650207] 舞蹈表演", "is_wenli": "文科", "jihua": 14, "baokao": 0, "luqu": 1 },
  { "name": "[650219] 音乐表演", "is_wenli": "文科", "jihua": 121, "baokao": 31, "luqu": 58 },
  { "name": "[650401] 文化创意与策划", "is_wenli": "文科", "jihua": 118, "baokao": 90, "luqu": 97 },
  { "name": "[650402] 文化市场经营管理", "is_wenli": "文科", "jihua": 155, "baokao": 24, "luqu": 124 },
  { "name": "[650404] 文物修复与保护", "is_wenli": "文科", "jihua": 84, "baokao": 109, "luqu": 98 },
  { "name": "[660101] 图文信息处理", "is_wenli": "文科", "jihua": 3, "baokao": 7, "luqu": 3 },
  { "name": "[660102] 网络新闻与传播", "is_wenli": "文科", "jihua": 35, "baokao": 69, "luqu": 23 },
  { "name": "[660104] 出版商务", "is_wenli": "文科", "jihua": 3, "baokao": 12, "luqu": 3 },
  { "name": "[660107] 数字出版", "is_wenli": "文科", "jihua": 4, "baokao": 2, "luqu": 4 },
  { "name": "[66010I] 出版商务(中外合作办学)", "is_wenli": "文科", "jihua": 4, "baokao": 5, "luqu": 4 },
  { "name": "[6602 ] 广播影视类", "is_wenli": "文科", "jihua": 3, "baokao": 7, "luqu": 3 },
  { "name": "[660201] 新闻采编与制作", "is_wenli": "文科", "jihua": 689, "baokao": 1320, "luqu": 801 },
  { "name": "[660202] 播音与主持", "is_wenli": "文科", "jihua": 13, "baokao": 5, "luqu": 8 },
  { "name": "[660203] 广播影视节目制作", "is_wenli": "文科", "jihua": 23, "baokao": 13, "luqu": 15 },
  { "name": "[660204] 广播电视技术", "is_wenli": "文科", "jihua": 2, "baokao": 1, "luqu": 1 },
  { "name": "[660206] 影视编导", "is_wenli": "文科", "jihua": 12, "baokao": 1, "luqu": 2 },
  { "name": "[660208] 影视多媒体技术", "is_wenli": "文科", "jihua": 105, "baokao": 86, "luqu": 127 },
  { "name": "[660209] 影视动画", "is_wenli": "文科", "jihua": 4, "baokao": 5, "luqu": 2 },
  { "name": "[660213] 摄影摄像技术", "is_wenli": "文科", "jihua": 75, "baokao": 38, "luqu": 62 },
  { "name": "[660214] 传播与策划", "is_wenli": "文科", "jihua": 89, "baokao": 122, "luqu": 96 },
  { "name": "[660215] 媒体营销", "is_wenli": "文科", "jihua": 7, "baokao": 0, "luqu": 2 },
  { "name": "[6701 ] 教育类", "is_wenli": "文科", "jihua": 20, "baokao": 87, "luqu": 20 },
  { "name": "[670101] 早期教育", "is_wenli": "文科", "jihua": 851, "baokao": 503, "luqu": 712 },
  { "name": "[670102] 学前教育", "is_wenli": "文科", "jihua": 6425, "baokao": 8211, "luqu": 8254 },
  { "name": "[670103] 小学教育", "is_wenli": "文科", "jihua": 2241, "baokao": 10602, "luqu": 6900 },
  { "name": "[670104] 语文教育", "is_wenli": "文科", "jihua": 2622, "baokao": 10218, "luqu": 4594 },
  { "name": "[670105] 数学教育", "is_wenli": "文科", "jihua": 196, "baokao": 389, "luqu": 232 },
  { "name": "[670106] 英语教育", "is_wenli": "文科", "jihua": 1689, "baokao": 4797, "luqu": 2099 },
  { "name": "[67010G] 学前教育(中外合作办学)", "is_wenli": "文科", "jihua": 386, "baokao": 386, "luqu": 432 },
  { "name": "[67010H] 小学教育(中外合作办学)", "is_wenli": "文科", "jihua": 120, "baokao": 274, "luqu": 120 },
  { "name": "[67010K] 英语教育(中外合作办学)", "is_wenli": "文科", "jihua": 80, "baokao": 330, "luqu": 80 },
  { "name": "[670110] 历史教育", "is_wenli": "文科", "jihua": 16, "baokao": 41, "luqu": 31 },
  { "name": "[670111] 地理教育", "is_wenli": "文科", "jihua": 5, "baokao": 1, "luqu": 4 },
  { "name": "[670112] 音乐教育", "is_wenli": "文科", "jihua": 553, "baokao": 230, "luqu": 442 },
  { "name": "[670113] 美术教育", "is_wenli": "文科", "jihua": 370, "baokao": 240, "luqu": 351 },
  { "name": "[670114] 体育教育", "is_wenli": "文科", "jihua": 5, "baokao": 4, "luqu": 5 },
  { "name": "[670115] 思想政治教育", "is_wenli": "文科", "jihua": 12, "baokao": 5, "luqu": 6 },
  { "name": "[670116] 舞蹈教育", "is_wenli": "文科", "jihua": 49, "baokao": 26, "luqu": 46 },
  { "name": "[670117] 艺术教育", "is_wenli": "文科", "jihua": 25, "baokao": 28, "luqu": 22 },
  { "name": "[670118] 特殊教育", "is_wenli": "文科", "jihua": 110, "baokao": 47, "luqu": 99 },
  { "name": "[670119] 科学教育", "is_wenli": "文科", "jihua": 22, "baokao": 22, "luqu": 22 },
  { "name": "[67011G] 音乐教育(中外合作办学)", "is_wenli": "文科", "jihua": 70, "baokao": 41, "luqu": 72 },
  { "name": "[670120] 现代教育技术", "is_wenli": "文科", "jihua": 2, "baokao": 0, "luqu": 1 },
  { "name": "[6702 ] 语言类", "is_wenli": "文科", "jihua": 6, "baokao": 20, "luqu": 6 },
  { "name": "[670201] 汉语", "is_wenli": "文科", "jihua": 196, "baokao": 435, "luqu": 225 },
  { "name": "[670202] 商务英语", "is_wenli": "文科", "jihua": 2148, "baokao": 2125, "luqu": 2162 },
  { "name": "[670203] 应用英语", "is_wenli": "文科", "jihua": 1052, "baokao": 919, "luqu": 896 },
  { "name": "[670204] 旅游英语", "is_wenli": "文科", "jihua": 376, "baokao": 154, "luqu": 203 },
  { "name": "[670205] 商务日语", "is_wenli": "文科", "jihua": 141, "baokao": 176, "luqu": 127 },
  { "name": "[670206] 应用日语", "is_wenli": "文科", "jihua": 151, "baokao": 132, "luqu": 143 },
  { "name": "[670207] 旅游日语", "is_wenli": "文科", "jihua": 56, "baokao": 43, "luqu": 55 },
  { "name": "[670208] 应用韩语", "is_wenli": "文科", "jihua": 129, "baokao": 93, "luqu": 108 },
  { "name": "[670209] 应用俄语", "is_wenli": "文科", "jihua": 68, "baokao": 42, "luqu": 38 },
  { "name": "[67020G] 商务英语(中外合作办学)", "is_wenli": "文科", "jihua": 79, "baokao": 24, "luqu": 73 },
  { "name": "[67020M] 应用韩语(中外合作办学)", "is_wenli": "文科", "jihua": 56, "baokao": 14, "luqu": 56 },
  { "name": "[670210] 应用法语", "is_wenli": "文科", "jihua": 61, "baokao": 46, "luqu": 41 },
  { "name": "[670211] 应用德语", "is_wenli": "文科", "jihua": 79, "baokao": 17, "luqu": 56 },
  { "name": "[670212] 应用西班牙语", "is_wenli": "文科", "jihua": 28, "baokao": 16, "luqu": 20 },
  { "name": "[670213] 应用越南语", "is_wenli": "文科", "jihua": 4, "baokao": 0, "luqu": 1 },
  { "name": "[670214] 应用泰语", "is_wenli": "文科", "jihua": 4, "baokao": 4, "luqu": 4 },
  { "name": "[670215] 应用阿拉伯语", "is_wenli": "文科", "jihua": 6, "baokao": 0, "luqu": 2 },
  { "name": "[670216] 应用外语", "is_wenli": "文科", "jihua": 7, "baokao": 0, "luqu": 1 },
  { "name": "[670301] 文秘", "is_wenli": "文科", "jihua": 460, "baokao": 357, "luqu": 417 },
  { "name": "[670302] 文秘速录", "is_wenli": "文科", "jihua": 16, "baokao": 0, "luqu": 16 },
  { "name": "[670401] 运动训练", "is_wenli": "文科", "jihua": 61, "baokao": 34, "luqu": 32 },
  { "name": "[670402] 运动防护", "is_wenli": "文科", "jihua": 2, "baokao": 2, "luqu": 2 },
  { "name": "[670403] 社会体育", "is_wenli": "文科", "jihua": 37, "baokao": 5, "luqu": 20 },
  { "name": "[670404] 休闲体育", "is_wenli": "文科", "jihua": 2, "baokao": 0, "luqu": 1 },
  { "name": "[670405] 高尔夫球运动与管理", "is_wenli": "文科", "jihua": 39, "baokao": 0, "luqu": 2 },
  { "name": "[670406] 民族传统体育", "is_wenli": "文科", "jihua": 255, "baokao": 15, "luqu": 32 },
  { "name": "[670407] 体育艺术表演", "is_wenli": "文科", "jihua": 120, "baokao": 10, "luqu": 10 },
  { "name": "[670408] 体育运营与管理", "is_wenli": "文科", "jihua": 44, "baokao": 11, "luqu": 16 },
  { "name": "[670409] 体育保健与康复", "is_wenli": "文科", "jihua": 134, "baokao": 37, "luqu": 45 },
  { "name": "[670410] 健身指导与管理", "is_wenli": "文科", "jihua": 94, "baokao": 66, "luqu": 60 },
  { "name": "[670411] 电子竞技运动与管理", "is_wenli": "文科", "jihua": 280, "baokao": 66, "luqu": 143 },
  { "name": "[680101] 治安管理", "is_wenli": "文科", "jihua": 1, "baokao": 5, "luqu": 1 },
  { "name": "[680102] 交通管理", "is_wenli": "文科", "jihua": 2, "baokao": 2, "luqu": 2 },
  { "name": "[680402] 国内安全保卫", "is_wenli": "文科", "jihua": 20, "baokao": 1, "luqu": 1 },
  { "name": "[680501] 司法助理", "is_wenli": "文科", "jihua": 78, "baokao": 67, "luqu": 78 },
  { "name": "[680502] 法律文秘", "is_wenli": "文科", "jihua": 263, "baokao": 247, "luqu": 260 },
  { "name": "[680503] 法律事务", "is_wenli": "文科", "jihua": 810, "baokao": 1737, "luqu": 911 },
  { "name": "[680504] 检察事务", "is_wenli": "文科", "jihua": 110, "baokao": 208, "luqu": 110 },
  { "name": "[680601] 刑事执行", "is_wenli": "文科", "jihua": 16, "baokao": 21, "luqu": 16 },
  { "name": "[680603] 行政执行", "is_wenli": "文科", "jihua": 4, "baokao": 3, "luqu": 3 },
  { "name": "[680604] 司法警务", "is_wenli": "文科", "jihua": 144, "baokao": 490, "luqu": 146 },
  { "name": "[680605] 社区矫正", "is_wenli": "文科", "jihua": 48, "baokao": 19, "luqu": 46 },
  { "name": "[680701] 刑事侦查技术", "is_wenli": "文科", "jihua": 12, "baokao": 21, "luqu": 16 },
  { "name": "[680702] 安全防范技术", "is_wenli": "文科", "jihua": 53, "baokao": 36, "luqu": 50 },
  { "name": "[680703] 司法信息技术", "is_wenli": "文科", "jihua": 25, "baokao": 43, "luqu": 26 },
  { "name": "[680704] 司法鉴定技术", "is_wenli": "文科", "jihua": 129, "baokao": 113, "luqu": 129 },
  { "name": "[690101] 社会工作", "is_wenli": "文科", "jihua": 90, "baokao": 8, "luqu": 39 },
  { "name": "[690103] 青少年工作与管理", "is_wenli": "文科", "jihua": 8, "baokao": 0, "luqu": 1 },
  { "name": "[690104] 社区管理与服务", "is_wenli": "文科", "jihua": 127, "baokao": 17, "luqu": 25 },
  { "name": "[690201] 民政管理", "is_wenli": "文科", "jihua": 5, "baokao": 6, "luqu": 3 },
  { "name": "[690202] 人力资源管理", "is_wenli": "文科", "jihua": 872, "baokao": 706, "luqu": 722 },
  { "name": "[690203] 劳动与社会保障", "is_wenli": "文科", "jihua": 5, "baokao": 0, "luqu": 2 },
  { "name": "[690204] 网络舆情监测", "is_wenli": "文科", "jihua": 5, "baokao": 3, "luqu": 5 },
  { "name": "[690205] 公共事务管理", "is_wenli": "文科", "jihua": 25, "baokao": 14, "luqu": 18 },
  { "name": "[690206] 行政管理", "is_wenli": "文科", "jihua": 232, "baokao": 160, "luqu": 233 },
  { "name": "[690207] 质量管理与认证", "is_wenli": "文科", "jihua": 22, "baokao": 10, "luqu": 8 },
  { "name": "[690208] 知识产权管理", "is_wenli": "文科", "jihua": 2, "baokao": 0, "luqu": 1 },
  { "name": "[690209] 公益慈善事业管理", "is_wenli": "文科", "jihua": 12, "baokao": 11, "luqu": 2 },
  { "name": "[69020G] 人力资源管理(中外合作办学)", "is_wenli": "文科", "jihua": 20, "baokao": 18, "luqu": 20 },
  { "name": "[690301] 老年服务与管理", "is_wenli": "文科", "jihua": 353, "baokao": 59, "luqu": 118 },
  { "name": "[690302] 家政服务与管理", "is_wenli": "文科", "jihua": 85, "baokao": 4, "luqu": 7 },
  { "name": "[690303] 婚庆服务与管理", "is_wenli": "文科", "jihua": 58, "baokao": 14, "luqu": 22 },
  { "name": "[690304] 社区康复", "is_wenli": "文科", "jihua": 141, "baokao": 9, "luqu": 41 },
  { "name": "[690305] 现代殡葬技术与管理", "is_wenli": "文科", "jihua": 6, "baokao": 26, "luqu": 15 },
  { "name": "[690306] 幼儿发展与健康管理", "is_wenli": "文科", "jihua": 1264, "baokao": 682, "luqu": 1039 },

]