module.exports = [
  {
      "specialty_code": "050101",
      "name": "汉语言文学",
      "is_wenli": "文科",
      "jihua": "3903",
      "baokao": "11891",
      "luqu": "5357",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050201",
      "name": "英语",
      "is_wenli": "文科",
      "jihua": "4474",
      "baokao": "6473",
      "luqu": "4821",
      "批次": "本科二批"
  },
  {
      "specialty_code": "030101",
      "name": "法学",
      "is_wenli": "文科",
      "jihua": "1945",
      "baokao": "5694",
      "luqu": "2157",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120203",
      "name": "会计学",
      "is_wenli": "文科",
      "jihua": "3148",
      "baokao": "4281",
      "luqu": "3429",
      "批次": "本科二批"
  },
  {
      "specialty_code": "030503",
      "name": "思想政治教育",
      "is_wenli": "文科",
      "jihua": "1058",
      "baokao": "2645",
      "luqu": "1090",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050306",
      "name": "网络与新媒体",
      "is_wenli": "文科",
      "jihua": "1194",
      "baokao": "2546",
      "luqu": "1308",
      "批次": "本科二批"
  },
  {
      "specialty_code": "060101",
      "name": "历史学",
      "is_wenli": "文科",
      "jihua": "780",
      "baokao": "2280",
      "luqu": "792",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120204",
      "name": "财务管理",
      "is_wenli": "文科",
      "jihua": "2514",
      "baokao": "2221",
      "luqu": "2345",
      "批次": "本科二批"
  },
  {
      "specialty_code": "040107",
      "name": "小学教育",
      "is_wenli": "文科",
      "jihua": "1307",
      "baokao": "2123",
      "luqu": "1528",
      "批次": "本科二批"
  },
  {
      "specialty_code": "0501",
      "name": "中国语言文学类",
      "is_wenli": "文科",
      "jihua": "203",
      "baokao": "1568",
      "luqu": "216",
      "批次": "本科二批"
  },
  {
      "specialty_code": "101101",
      "name": "护理学",
      "is_wenli": "文科",
      "jihua": "1205",
      "baokao": "1371",
      "luqu": "1232",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050301",
      "name": "新闻学",
      "is_wenli": "文科",
      "jihua": "729",
      "baokao": "1276",
      "luqu": "740",
      "批次": "本科二批"
  },
  {
      "specialty_code": "040106",
      "name": "学前教育",
      "is_wenli": "文科",
      "jihua": "2489",
      "baokao": "1257",
      "luqu": "2408",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120207",
      "name": "审计学",
      "is_wenli": "文科",
      "jihua": "834",
      "baokao": "1040",
      "luqu": "874",
      "批次": "本科二批"
  },
  {
      "specialty_code": "020401",
      "name": "国际经济与贸易",
      "is_wenli": "文科",
      "jihua": "1761",
      "baokao": "952",
      "luqu": "1625",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050103",
      "name": "汉语国际教育",
      "is_wenli": "文科",
      "jihua": "1016",
      "baokao": "951",
      "luqu": "1049",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050262",
      "name": "商务英语",
      "is_wenli": "文科",
      "jihua": "2094",
      "baokao": "940",
      "luqu": "1985",
      "批次": "本科二批"
  },
  {
      "specialty_code": "020301",
      "name": "金融学",
      "is_wenli": "文科",
      "jihua": "858",
      "baokao": "895",
      "luqu": "811",
      "批次": "本科二批"
  },
  {
      "specialty_code": "020101",
      "name": "经济学",
      "is_wenli": "文科",
      "jihua": "706",
      "baokao": "849",
      "luqu": "747",
      "批次": "本科二批"
  },
  {
      "specialty_code": "020202",
      "name": "税收学",
      "is_wenli": "文科",
      "jihua": "239",
      "baokao": "742",
      "luqu": "206",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120801",
      "name": "电子商务",
      "is_wenli": "文科",
      "jihua": "1270",
      "baokao": "729",
      "luqu": "1206",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120201",
      "name": "工商管理",
      "is_wenli": "文科",
      "jihua": "1144",
      "baokao": "632",
      "luqu": "1156",
      "批次": "本科二批"
  },
  {
      "specialty_code": "030302",
      "name": "社会工作",
      "is_wenli": "文科",
      "jihua": "427",
      "baokao": "609",
      "luqu": "445",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050303",
      "name": "广告学",
      "is_wenli": "文科",
      "jihua": "639",
      "baokao": "543",
      "luqu": "635",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050107",
      "name": "秘书学",
      "is_wenli": "文科",
      "jihua": "120",
      "baokao": "522",
      "luqu": "129",
      "批次": "本科二批"
  },
  {
      "specialty_code": "0401",
      "name": "教育学类",
      "is_wenli": "文科",
      "jihua": "440",
      "baokao": "506",
      "luqu": "440",
      "批次": "本科二批"
  },
  {
      "specialty_code": "020304",
      "name": "投资学",
      "is_wenli": "文科",
      "jihua": "491",
      "baokao": "505",
      "luqu": "491",
      "批次": "本科二批"
  },
  {
      "specialty_code": "070501",
      "name": "地理科学",
      "is_wenli": "文科",
      "jihua": "418",
      "baokao": "502",
      "luqu": "434",
      "批次": "本科二批"
  },
  {
      "specialty_code": "330302",
      "name": "大数据与会计",
      "is_wenli": "文科",
      "jihua": "118",
      "baokao": "445",
      "luqu": "134",
      "批次": "本科二批"
  },
  {
      "specialty_code": "040101",
      "name": "教育学",
      "is_wenli": "文科",
      "jihua": "267",
      "baokao": "429",
      "luqu": "271",
      "批次": "本科二批"
  },
  {
      "specialty_code": "04010L",
      "name": "小学教育(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "100",
      "baokao": "409",
      "luqu": "100",
      "批次": "本科二批"
  },
  {
      "specialty_code": "071102",
      "name": "应用心理学",
      "is_wenli": "文科",
      "jihua": "240",
      "baokao": "399",
      "luqu": "239",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120601",
      "name": "物流管理",
      "is_wenli": "文科",
      "jihua": "1479",
      "baokao": "362",
      "luqu": "1320",
      "批次": "本科二批"
  },
  {
      "specialty_code": "100501",
      "name": "中医学",
      "is_wenli": "文科",
      "jihua": "127",
      "baokao": "360",
      "luqu": "151",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050261",
      "name": "翻译",
      "is_wenli": "文科",
      "jihua": "595",
      "baokao": "318",
      "luqu": "570",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050207",
      "name": "日语",
      "is_wenli": "文科",
      "jihua": "767",
      "baokao": "290",
      "luqu": "736",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120901",
      "name": "旅游管理",
      "is_wenli": "文科",
      "jihua": "1067",
      "baokao": "279",
      "luqu": "928",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120210",
      "name": "文化产业管理",
      "is_wenli": "文科",
      "jihua": "219",
      "baokao": "260",
      "luqu": "249",
      "批次": "本科二批"
  },
  {
      "specialty_code": "1202",
      "name": "工商管理类",
      "is_wenli": "文科",
      "jihua": "269",
      "baokao": "252",
      "luqu": "248",
      "批次": "本科二批"
  },
  {
      "specialty_code": "080901",
      "name": "计算机科学与技术",
      "is_wenli": "文科",
      "jihua": "280",
      "baokao": "249",
      "luqu": "259",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120202",
      "name": "市场营销",
      "is_wenli": "文科",
      "jihua": "1637",
      "baokao": "247",
      "luqu": "1428",
      "批次": "本科二批"
  },
  {
      "specialty_code": "12020H",
      "name": "会计学(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "146",
      "baokao": "239",
      "luqu": "146",
      "批次": "本科二批"
  },
  {
      "specialty_code": "02030F",
      "name": "金融学(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "124",
      "baokao": "229",
      "luqu": "123",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120206",
      "name": "人力资源管理",
      "is_wenli": "文科",
      "jihua": "745",
      "baokao": "227",
      "luqu": "666",
      "批次": "本科二批"
  },
  {
      "specialty_code": "0201",
      "name": "经济学类",
      "is_wenli": "文科",
      "jihua": "19",
      "baokao": "215",
      "luqu": "21",
      "批次": "本科二批"
  },
  {
      "specialty_code": "080902",
      "name": "软件工程",
      "is_wenli": "文科",
      "jihua": "260",
      "baokao": "213",
      "luqu": "260",
      "批次": "本科二批"
  },
  {
      "specialty_code": "080910",
      "name": "数据科学与大数据技术",
      "is_wenli": "文科",
      "jihua": "150",
      "baokao": "212",
      "luqu": "177",
      "批次": "本科二批"
  },
  {
      "specialty_code": "0601",
      "name": "历史学类",
      "is_wenli": "文科",
      "jihua": "206",
      "baokao": "210",
      "luqu": "214",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120402",
      "name": "行政管理",
      "is_wenli": "文科",
      "jihua": "237",
      "baokao": "207",
      "luqu": "231",
      "批次": "本科二批"
  },
  {
      "specialty_code": "030102",
      "name": "知识产权",
      "is_wenli": "文科",
      "jihua": "183",
      "baokao": "202",
      "luqu": "185",
      "批次": "本科二批"
  },
  {
      "specialty_code": "020302",
      "name": "金融工程",
      "is_wenli": "文科",
      "jihua": "351",
      "baokao": "195",
      "luqu": "348",
      "批次": "本科二批"
  },
  {
      "specialty_code": "0203",
      "name": "金融学类",
      "is_wenli": "文科",
      "jihua": "55",
      "baokao": "191",
      "luqu": "51",
      "批次": "本科二批"
  },
  {
      "specialty_code": "101005",
      "name": "康复治疗学",
      "is_wenli": "文科",
      "jihua": "253",
      "baokao": "188",
      "luqu": "245",
      "批次": "本科二批"
  },
  {
      "specialty_code": "101102",
      "name": "助产学",
      "is_wenli": "文科",
      "jihua": "221",
      "baokao": "183",
      "luqu": "231",
      "批次": "本科二批"
  },
  {
      "specialty_code": "020105",
      "name": "商务经济学",
      "is_wenli": "文科",
      "jihua": "152",
      "baokao": "172",
      "luqu": "152",
      "批次": "本科二批"
  },
  {
      "specialty_code": "0503",
      "name": "新闻传播学类",
      "is_wenli": "文科",
      "jihua": "168",
      "baokao": "171",
      "luqu": "183",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120105",
      "name": "工程造价",
      "is_wenli": "文科",
      "jihua": "500",
      "baokao": "170",
      "luqu": "351",
      "批次": "本科二批"
  },
  {
      "specialty_code": "080906",
      "name": "数字媒体技术",
      "is_wenli": "文科",
      "jihua": "145",
      "baokao": "166",
      "luqu": "164",
      "批次": "本科二批"
  },
  {
      "specialty_code": "071101",
      "name": "心理学",
      "is_wenli": "文科",
      "jihua": "172",
      "baokao": "145",
      "luqu": "174",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120103",
      "name": "工程管理",
      "is_wenli": "文科",
      "jihua": "534",
      "baokao": "139",
      "luqu": "415",
      "批次": "本科二批"
  },
  {
      "specialty_code": "330301",
      "name": "大数据与财务管理",
      "is_wenli": "文科",
      "jihua": "156",
      "baokao": "137",
      "luqu": "144",
      "批次": "本科二批"
  },
  {
      "specialty_code": "0502",
      "name": "外国语言文学类",
      "is_wenli": "文科",
      "jihua": "198",
      "baokao": "136",
      "luqu": "199",
      "批次": "本科二批"
  },
  {
      "specialty_code": "04010K",
      "name": "学前教育(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "135",
      "baokao": "123",
      "luqu": "136",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050102",
      "name": "汉语言",
      "is_wenli": "文科",
      "jihua": "88",
      "baokao": "123",
      "luqu": "88",
      "批次": "本科二批"
  },
  {
      "specialty_code": "370101",
      "name": "学前教育",
      "is_wenli": "文科",
      "jihua": "168",
      "baokao": "120",
      "luqu": "170",
      "批次": "本科二批"
  },
  {
      "specialty_code": "10110F",
      "name": "护理学(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "120",
      "baokao": "116",
      "luqu": "120",
      "批次": "本科二批"
  },
  {
      "specialty_code": "040108",
      "name": "特殊教育",
      "is_wenli": "文科",
      "jihua": "99",
      "baokao": "110",
      "luqu": "99",
      "批次": "本科二批"
  },
  {
      "specialty_code": "05020I",
      "name": "法语(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "50",
      "baokao": "108",
      "luqu": "50",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120401",
      "name": "公共事业管理",
      "is_wenli": "文科",
      "jihua": "220",
      "baokao": "108",
      "luqu": "217",
      "批次": "本科二批"
  },
  {
      "specialty_code": "02040F",
      "name": "国际经济与贸易(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "57",
      "baokao": "107",
      "luqu": "55",
      "批次": "本科二批"
  },
  {
      "specialty_code": "020201",
      "name": "财政学",
      "is_wenli": "文科",
      "jihua": "58",
      "baokao": "103",
      "luqu": "61",
      "批次": "本科二批"
  },
  {
      "specialty_code": "320201",
      "name": "护理",
      "is_wenli": "文科",
      "jihua": "87",
      "baokao": "102",
      "luqu": "194",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120301",
      "name": "农林经济管理",
      "is_wenli": "文科",
      "jihua": "98",
      "baokao": "98",
      "luqu": "97",
      "批次": "本科二批"
  },
  {
      "specialty_code": "060104",
      "name": "文物与博物馆学",
      "is_wenli": "文科",
      "jihua": "129",
      "baokao": "96",
      "luqu": "139",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050302",
      "name": "广播电视学",
      "is_wenli": "文科",
      "jihua": "272",
      "baokao": "94",
      "luqu": "277",
      "批次": "本科二批"
  },
  {
      "specialty_code": "082803",
      "name": "风景园林",
      "is_wenli": "文科",
      "jihua": "111",
      "baokao": "94",
      "luqu": "109",
      "批次": "本科二批"
  },
  {
      "specialty_code": "100601",
      "name": "中西医临床医学",
      "is_wenli": "文科",
      "jihua": "25",
      "baokao": "94",
      "luqu": "31",
      "批次": "本科二批"
  },
  {
      "specialty_code": "040206",
      "name": "运动康复",
      "is_wenli": "文科",
      "jihua": "167",
      "baokao": "90",
      "luqu": "173",
      "批次": "本科二批"
  },
  {
      "specialty_code": "020102",
      "name": "经济统计学",
      "is_wenli": "文科",
      "jihua": "218",
      "baokao": "89",
      "luqu": "221",
      "批次": "本科二批"
  },
  {
      "specialty_code": "082801",
      "name": "建筑学",
      "is_wenli": "文科",
      "jihua": "97",
      "baokao": "85",
      "luqu": "30",
      "批次": "本科二批"
  },
  {
      "specialty_code": "330701",
      "name": "电子商务",
      "is_wenli": "文科",
      "jihua": "22",
      "baokao": "85",
      "luqu": "24",
      "批次": "本科二批"
  },
  {
      "specialty_code": "0301",
      "name": "法学类",
      "is_wenli": "文科",
      "jihua": "18",
      "baokao": "84",
      "luqu": "21",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050202",
      "name": "俄语",
      "is_wenli": "文科",
      "jihua": "161",
      "baokao": "80",
      "luqu": "183",
      "批次": "本科二批"
  },
  {
      "specialty_code": "082802",
      "name": "城乡规划",
      "is_wenli": "文科",
      "jihua": "247",
      "baokao": "76",
      "luqu": "141",
      "批次": "本科二批"
  },
  {
      "specialty_code": "090502",
      "name": "园林",
      "is_wenli": "文科",
      "jihua": "97",
      "baokao": "73",
      "luqu": "76",
      "批次": "本科二批"
  },
  {
      "specialty_code": "070504",
      "name": "地理信息科学",
      "is_wenli": "文科",
      "jihua": "101",
      "baokao": "69",
      "luqu": "108",
      "批次": "本科二批"
  },
  {
      "specialty_code": "05030I",
      "name": "传播学(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "4",
      "baokao": "59",
      "luqu": "4",
      "批次": "本科二批"
  },
  {
      "specialty_code": "090101",
      "name": "农学",
      "is_wenli": "文科",
      "jihua": "4",
      "baokao": "59",
      "luqu": "4",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120108",
      "name": "大数据管理与应用",
      "is_wenli": "文科",
      "jihua": "128",
      "baokao": "58",
      "luqu": "127",
      "批次": "本科二批"
  },
  {
      "specialty_code": "0705",
      "name": "地理科学类",
      "is_wenli": "文科",
      "jihua": "38",
      "baokao": "55",
      "luqu": "38",
      "批次": "本科二批"
  },
  {
      "specialty_code": "08090K",
      "name": "数字媒体技术(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "40",
      "baokao": "55",
      "luqu": "40",
      "批次": "本科二批"
  },
  {
      "specialty_code": "100502",
      "name": "针灸推拿学",
      "is_wenli": "文科",
      "jihua": "31",
      "baokao": "54",
      "luqu": "30",
      "批次": "本科二批"
  },
  {
      "specialty_code": "030201",
      "name": "政治学与行政学",
      "is_wenli": "文科",
      "jihua": "61",
      "baokao": "53",
      "luqu": "64",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120803",
      "name": "跨境电子商务",
      "is_wenli": "文科",
      "jihua": "218",
      "baokao": "53",
      "luqu": "213",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120405",
      "name": "城市管理",
      "is_wenli": "文科",
      "jihua": "28",
      "baokao": "52",
      "luqu": "29",
      "批次": "本科二批"
  },
  {
      "specialty_code": "020309",
      "name": "互联网金融",
      "is_wenli": "文科",
      "jihua": "185",
      "baokao": "50",
      "luqu": "189",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120102",
      "name": "信息管理与信息系统",
      "is_wenli": "文科",
      "jihua": "176",
      "baokao": "49",
      "luqu": "173",
      "批次": "本科二批"
  },
  {
      "specialty_code": "1204",
      "name": "公共管理类",
      "is_wenli": "文科",
      "jihua": "30",
      "baokao": "49",
      "luqu": "28",
      "批次": "本科二批"
  },
  {
      "specialty_code": "040105",
      "name": "艺术教育",
      "is_wenli": "文科",
      "jihua": "19",
      "baokao": "48",
      "luqu": "13",
      "批次": "本科二批"
  },
  {
      "specialty_code": "020303",
      "name": "保险学",
      "is_wenli": "文科",
      "jihua": "160",
      "baokao": "47",
      "luqu": "160",
      "批次": "本科二批"
  },
  {
      "specialty_code": "030301",
      "name": "社会学",
      "is_wenli": "文科",
      "jihua": "85",
      "baokao": "46",
      "luqu": "90",
      "批次": "本科二批"
  },
  {
      "specialty_code": "05020F",
      "name": "英语(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "100",
      "baokao": "46",
      "luqu": "100",
      "批次": "本科二批"
  },
  {
      "specialty_code": "080905",
      "name": "物联网工程",
      "is_wenli": "文科",
      "jihua": "170",
      "baokao": "46",
      "luqu": "102",
      "批次": "本科二批"
  },
  {
      "specialty_code": "070502",
      "name": "自然地理与资源环境",
      "is_wenli": "文科",
      "jihua": "62",
      "baokao": "43",
      "luqu": "62",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120410",
      "name": "健康服务与管理",
      "is_wenli": "文科",
      "jihua": "108",
      "baokao": "43",
      "luqu": "96",
      "批次": "本科二批"
  },
  {
      "specialty_code": "02010F",
      "name": "经济学(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "26",
      "baokao": "41",
      "luqu": "26",
      "批次": "本科二批"
  },
  {
      "specialty_code": "100701",
      "name": "药学",
      "is_wenli": "文科",
      "jihua": "36",
      "baokao": "41",
      "luqu": "45",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120602",
      "name": "物流工程",
      "is_wenli": "文科",
      "jihua": "97",
      "baokao": "41",
      "luqu": "95",
      "批次": "本科二批"
  },
  {
      "specialty_code": "130305",
      "name": "广播电视编导",
      "is_wenli": "文科",
      "jihua": "17",
      "baokao": "41",
      "luqu": "17",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050304",
      "name": "传播学",
      "is_wenli": "文科",
      "jihua": "36",
      "baokao": "40",
      "luqu": "40",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120205",
      "name": "国际商务",
      "is_wenli": "文科",
      "jihua": "274",
      "baokao": "40",
      "luqu": "220",
      "批次": "本科二批"
  },
  {
      "specialty_code": "010101",
      "name": "哲学",
      "is_wenli": "文科",
      "jihua": "15",
      "baokao": "37",
      "luqu": "17",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120903",
      "name": "会展经济与管理",
      "is_wenli": "文科",
      "jihua": "289",
      "baokao": "37",
      "luqu": "266",
      "批次": "本科二批"
  },
  {
      "specialty_code": "12020K",
      "name": "人力资源管理(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "106",
      "baokao": "36",
      "luqu": "106",
      "批次": "本科二批"
  },
  {
      "specialty_code": "020307",
      "name": "经济与金融",
      "is_wenli": "文科",
      "jihua": "49",
      "baokao": "35",
      "luqu": "46",
      "批次": "本科二批"
  },
  {
      "specialty_code": "1008",
      "name": "中药学类",
      "is_wenli": "文科",
      "jihua": "4",
      "baokao": "35",
      "luqu": "4",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120902",
      "name": "酒店管理",
      "is_wenli": "文科",
      "jihua": "587",
      "baokao": "35",
      "luqu": "482",
      "批次": "本科二批"
  },
  {
      "specialty_code": "330501",
      "name": "国际经济与贸易",
      "is_wenli": "文科",
      "jihua": "8",
      "baokao": "34",
      "luqu": "8",
      "批次": "本科二批"
  },
  {
      "specialty_code": "070101",
      "name": "数学与应用数学",
      "is_wenli": "文科",
      "jihua": "40",
      "baokao": "32",
      "luqu": "82",
      "批次": "本科二批"
  },
  {
      "specialty_code": "070503",
      "name": "人文地理与城乡规划",
      "is_wenli": "文科",
      "jihua": "62",
      "baokao": "32",
      "luqu": "63",
      "批次": "本科二批"
  },
  {
      "specialty_code": "080601",
      "name": "电气工程及其自动化",
      "is_wenli": "文科",
      "jihua": "105",
      "baokao": "32",
      "luqu": "88",
      "批次": "本科二批"
  },
  {
      "specialty_code": "090102",
      "name": "园艺",
      "is_wenli": "文科",
      "jihua": "4",
      "baokao": "31",
      "luqu": "4",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120109",
      "name": "工程审计",
      "is_wenli": "文科",
      "jihua": "14",
      "baokao": "31",
      "luqu": "15",
      "批次": "本科二批"
  },
  {
      "specialty_code": "030504",
      "name": "马克思主义理论",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "30",
      "luqu": "2",
      "批次": "本科二批"
  },
  {
      "specialty_code": "100301",
      "name": "口腔医学",
      "is_wenli": "文科",
      "jihua": "5",
      "baokao": "30",
      "luqu": "5",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120414",
      "name": "养老服务管理",
      "is_wenli": "文科",
      "jihua": "48",
      "baokao": "30",
      "luqu": "31",
      "批次": "本科二批"
  },
  {
      "specialty_code": "07050H",
      "name": "人文地理与城乡规划(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "55",
      "baokao": "28",
      "luqu": "55",
      "批次": "本科二批"
  },
  {
      "specialty_code": "080701",
      "name": "电子信息工程",
      "is_wenli": "文科",
      "jihua": "112",
      "baokao": "28",
      "luqu": "94",
      "批次": "本科二批"
  },
  {
      "specialty_code": "081001",
      "name": "土木工程",
      "is_wenli": "文科",
      "jihua": "208",
      "baokao": "26",
      "luqu": "125",
      "批次": "本科二批"
  },
  {
      "specialty_code": "12020F",
      "name": "工商管理(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "60",
      "baokao": "26",
      "luqu": "61",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050204",
      "name": "法语",
      "is_wenli": "文科",
      "jihua": "64",
      "baokao": "25",
      "luqu": "59",
      "批次": "本科二批"
  },
  {
      "specialty_code": "08091E",
      "name": "数据科学与大数据技术(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "40",
      "baokao": "25",
      "luqu": "40",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120404",
      "name": "土地资源管理",
      "is_wenli": "文科",
      "jihua": "43",
      "baokao": "24",
      "luqu": "43",
      "批次": "本科二批"
  },
  {
      "specialty_code": "100513",
      "name": "中医骨伤科学",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "23",
      "luqu": "1",
      "批次": "本科二批"
  },
  {
      "specialty_code": "300203",
      "name": "汽车服务工程技术",
      "is_wenli": "文科",
      "jihua": "93",
      "baokao": "23",
      "luqu": "29",
      "批次": "本科二批"
  },
  {
      "specialty_code": "1011",
      "name": "护理学类",
      "is_wenli": "文科",
      "jihua": "6",
      "baokao": "22",
      "luqu": "6",
      "批次": "本科二批"
  },
  {
      "specialty_code": "12020G",
      "name": "市场营销(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "41",
      "baokao": "22",
      "luqu": "41",
      "批次": "本科二批"
  },
  {
      "specialty_code": "320601",
      "name": "康复治疗",
      "is_wenli": "文科",
      "jihua": "43",
      "baokao": "22",
      "luqu": "41",
      "批次": "本科二批"
  },
  {
      "specialty_code": "040104",
      "name": "教育技术学",
      "is_wenli": "文科",
      "jihua": "83",
      "baokao": "21",
      "luqu": "83",
      "批次": "本科二批"
  },
  {
      "specialty_code": "08090G",
      "name": "软件工程(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "15",
      "baokao": "21",
      "luqu": "15",
      "批次": "本科二批"
  },
  {
      "specialty_code": "05030H",
      "name": "广告学(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "20",
      "baokao": "20",
      "luqu": "20",
      "批次": "本科二批"
  },
  {
      "specialty_code": "08280G",
      "name": "城乡规划(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "53",
      "baokao": "20",
      "luqu": "53",
      "批次": "本科二批"
  },
  {
      "specialty_code": "130304",
      "name": "戏剧影视文学",
      "is_wenli": "文科",
      "jihua": "16",
      "baokao": "20",
      "luqu": "16",
      "批次": "本科二批"
  },
  {
      "specialty_code": "330202",
      "name": "金融科技应用",
      "is_wenli": "文科",
      "jihua": "6",
      "baokao": "20",
      "luqu": "6",
      "批次": "本科二批"
  },
  {
      "specialty_code": "060103",
      "name": "考古学",
      "is_wenli": "文科",
      "jihua": "6",
      "baokao": "19",
      "luqu": "6",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120208",
      "name": "资产评估",
      "is_wenli": "文科",
      "jihua": "97",
      "baokao": "19",
      "luqu": "91",
      "批次": "本科二批"
  },
  {
      "specialty_code": "330802",
      "name": "现代物流管理",
      "is_wenli": "文科",
      "jihua": "57",
      "baokao": "19",
      "luqu": "33",
      "批次": "本科二批"
  },
  {
      "specialty_code": "0202",
      "name": "财政学类",
      "is_wenli": "文科",
      "jihua": "6",
      "baokao": "18",
      "luqu": "6",
      "批次": "本科二批"
  },
  {
      "specialty_code": "020310",
      "name": "金融科技",
      "is_wenli": "文科",
      "jihua": "159",
      "baokao": "18",
      "luqu": "170",
      "批次": "本科二批"
  },
  {
      "specialty_code": "0303",
      "name": "社会学类",
      "is_wenli": "文科",
      "jihua": "13",
      "baokao": "18",
      "luqu": "13",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050203",
      "name": "德语",
      "is_wenli": "文科",
      "jihua": "78",
      "baokao": "18",
      "luqu": "67",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050205",
      "name": "西班牙语",
      "is_wenli": "文科",
      "jihua": "130",
      "baokao": "18",
      "luqu": "126",
      "批次": "本科二批"
  },
  {
      "specialty_code": "090107",
      "name": "茶学",
      "is_wenli": "文科",
      "jihua": "15",
      "baokao": "18",
      "luqu": "15",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120211",
      "name": "劳动关系",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "18",
      "luqu": "2",
      "批次": "本科二批"
  },
  {
      "specialty_code": "030305",
      "name": "家政学",
      "is_wenli": "文科",
      "jihua": "86",
      "baokao": "17",
      "luqu": "86",
      "批次": "本科二批"
  },
  {
      "specialty_code": "080703",
      "name": "通信工程",
      "is_wenli": "文科",
      "jihua": "110",
      "baokao": "16",
      "luqu": "72",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120403",
      "name": "劳动与社会保障",
      "is_wenli": "文科",
      "jihua": "33",
      "baokao": "15",
      "luqu": "31",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120502",
      "name": "档案学",
      "is_wenli": "文科",
      "jihua": "10",
      "baokao": "15",
      "luqu": "10",
      "批次": "本科二批"
  },
  {
      "specialty_code": "030104",
      "name": "信用风险管理与法律防控",
      "is_wenli": "文科",
      "jihua": "65",
      "baokao": "14",
      "luqu": "65",
      "批次": "本科二批"
  },
  {
      "specialty_code": "082708",
      "name": "烹饪与营养教育",
      "is_wenli": "文科",
      "jihua": "6",
      "baokao": "14",
      "luqu": "5",
      "批次": "本科二批"
  },
  {
      "specialty_code": "101001",
      "name": "医学检验技术",
      "is_wenli": "文科",
      "jihua": "18",
      "baokao": "14",
      "luqu": "26",
      "批次": "本科二批"
  },
  {
      "specialty_code": "101003",
      "name": "医学影像技术",
      "is_wenli": "文科",
      "jihua": "14",
      "baokao": "14",
      "luqu": "18",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120604",
      "name": "供应链管理",
      "is_wenli": "文科",
      "jihua": "79",
      "baokao": "14",
      "luqu": "74",
      "批次": "本科二批"
  },
  {
      "specialty_code": "020106",
      "name": "能源经济",
      "is_wenli": "文科",
      "jihua": "6",
      "baokao": "13",
      "luqu": "5",
      "批次": "本科二批"
  },
  {
      "specialty_code": "080204",
      "name": "机械电子工程",
      "is_wenli": "文科",
      "jihua": "33",
      "baokao": "13",
      "luqu": "34",
      "批次": "本科二批"
  },
  {
      "specialty_code": "12020I",
      "name": "财务管理(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "13",
      "baokao": "13",
      "luqu": "13",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120407",
      "name": "交通管理",
      "is_wenli": "文科",
      "jihua": "35",
      "baokao": "13",
      "luqu": "32",
      "批次": "本科二批"
  },
  {
      "specialty_code": "280402",
      "name": "服装工程技术",
      "is_wenli": "文科",
      "jihua": "4",
      "baokao": "13",
      "luqu": "4",
      "批次": "本科二批"
  },
  {
      "specialty_code": "300603",
      "name": "城市轨道交通智能运营",
      "is_wenli": "文科",
      "jihua": "6",
      "baokao": "13",
      "luqu": "6",
      "批次": "本科二批"
  },
  {
      "specialty_code": "310204",
      "name": "数字媒体技术",
      "is_wenli": "文科",
      "jihua": "12",
      "baokao": "12",
      "luqu": "16",
      "批次": "本科二批"
  },
  {
      "specialty_code": "040110",
      "name": "教育康复学",
      "is_wenli": "文科",
      "jihua": "7",
      "baokao": "11",
      "luqu": "7",
      "批次": "本科二批"
  },
  {
      "specialty_code": "080501",
      "name": "能源与动力工程",
      "is_wenli": "文科",
      "jihua": "70",
      "baokao": "11",
      "luqu": "70",
      "批次": "本科二批"
  },
  {
      "specialty_code": "080903",
      "name": "网络工程",
      "is_wenli": "文科",
      "jihua": "31",
      "baokao": "11",
      "luqu": "23",
      "批次": "本科二批"
  },
  {
      "specialty_code": "08280H",
      "name": "风景园林(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "6",
      "baokao": "11",
      "luqu": "6",
      "批次": "本科二批"
  },
  {
      "specialty_code": "340101",
      "name": "旅游管理",
      "is_wenli": "文科",
      "jihua": "7",
      "baokao": "11",
      "luqu": "7",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050307",
      "name": "数字出版",
      "is_wenli": "文科",
      "jihua": "15",
      "baokao": "10",
      "luqu": "15",
      "批次": "本科二批"
  },
  {
      "specialty_code": "060107",
      "name": "文化遗产",
      "is_wenli": "文科",
      "jihua": "63",
      "baokao": "10",
      "luqu": "62",
      "批次": "本科二批"
  },
  {
      "specialty_code": "12030F",
      "name": "农林经济管理(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "42",
      "baokao": "10",
      "luqu": "42",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120802",
      "name": "电子商务及法律",
      "is_wenli": "文科",
      "jihua": "64",
      "baokao": "10",
      "luqu": "64",
      "批次": "本科二批"
  },
  {
      "specialty_code": "320701",
      "name": "公共卫生管理",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "10",
      "luqu": "2",
      "批次": "本科二批"
  },
  {
      "specialty_code": "020402",
      "name": "贸易经济",
      "is_wenli": "文科",
      "jihua": "26",
      "baokao": "9",
      "luqu": "26",
      "批次": "本科二批"
  },
  {
      "specialty_code": "030103",
      "name": "监狱学",
      "is_wenli": "文科",
      "jihua": "5",
      "baokao": "9",
      "luqu": "5",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050206",
      "name": "阿拉伯语",
      "is_wenli": "文科",
      "jihua": "20",
      "baokao": "9",
      "luqu": "19",
      "批次": "本科二批"
  },
  {
      "specialty_code": "060105",
      "name": "文物保护技术",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "9",
      "luqu": "3",
      "批次": "本科二批"
  },
  {
      "specialty_code": "080202",
      "name": "机械设计制造及其自动化",
      "is_wenli": "文科",
      "jihua": "77",
      "baokao": "9",
      "luqu": "56",
      "批次": "本科二批"
  },
  {
      "specialty_code": "080907",
      "name": "智能科学与技术",
      "is_wenli": "文科",
      "jihua": "28",
      "baokao": "9",
      "luqu": "28",
      "批次": "本科二批"
  },
  {
      "specialty_code": "081801",
      "name": "交通运输",
      "is_wenli": "文科",
      "jihua": "88",
      "baokao": "9",
      "luqu": "87",
      "批次": "本科二批"
  },
  {
      "specialty_code": "100801",
      "name": "中药学",
      "is_wenli": "文科",
      "jihua": "15",
      "baokao": "9",
      "luqu": "22",
      "批次": "本科二批"
  },
  {
      "specialty_code": "12060F",
      "name": "物流管理(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "73",
      "baokao": "9",
      "luqu": "73",
      "批次": "本科二批"
  },
  {
      "specialty_code": "020109",
      "name": "数字经济",
      "is_wenli": "文科",
      "jihua": "13",
      "baokao": "8",
      "luqu": "14",
      "批次": "本科二批"
  },
  {
      "specialty_code": "040205",
      "name": "运动人体科学",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "8",
      "luqu": "1",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050104",
      "name": "中国少数民族语言文学",
      "is_wenli": "文科",
      "jihua": "17",
      "baokao": "8",
      "luqu": "17",
      "批次": "本科二批"
  },
  {
      "specialty_code": "05020G",
      "name": "俄语(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "7",
      "baokao": "8",
      "luqu": "7",
      "批次": "本科二批"
  },
  {
      "specialty_code": "080207",
      "name": "车辆工程",
      "is_wenli": "文科",
      "jihua": "68",
      "baokao": "8",
      "luqu": "68",
      "批次": "本科二批"
  },
  {
      "specialty_code": "080717",
      "name": "人工智能",
      "is_wenli": "文科",
      "jihua": "31",
      "baokao": "8",
      "luqu": "23",
      "批次": "本科二批"
  },
  {
      "specialty_code": "1208",
      "name": "电子商务类",
      "is_wenli": "文科",
      "jihua": "66",
      "baokao": "8",
      "luqu": "66",
      "批次": "本科二批"
  },
  {
      "specialty_code": "1209",
      "name": "旅游管理类",
      "is_wenli": "文科",
      "jihua": "43",
      "baokao": "8",
      "luqu": "41",
      "批次": "本科二批"
  },
  {
      "specialty_code": "12090F",
      "name": "旅游管理(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "20",
      "baokao": "8",
      "luqu": "20",
      "批次": "本科二批"
  },
  {
      "specialty_code": "130102",
      "name": "艺术管理",
      "is_wenli": "文科",
      "jihua": "5",
      "baokao": "8",
      "luqu": "5",
      "批次": "本科二批"
  },
  {
      "specialty_code": "370201",
      "name": "应用英语",
      "is_wenli": "文科",
      "jihua": "6",
      "baokao": "8",
      "luqu": "6",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050109",
      "name": "手语翻译",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "7",
      "luqu": "2",
      "批次": "本科二批"
  },
  {
      "specialty_code": "0809",
      "name": "计算机类",
      "is_wenli": "文科",
      "jihua": "18",
      "baokao": "7",
      "luqu": "18",
      "批次": "本科二批"
  },
  {
      "specialty_code": "081602",
      "name": "服装设计与工程",
      "is_wenli": "文科",
      "jihua": "53",
      "baokao": "7",
      "luqu": "51",
      "批次": "本科二批"
  },
  {
      "specialty_code": "081802",
      "name": "交通工程",
      "is_wenli": "文科",
      "jihua": "29",
      "baokao": "7",
      "luqu": "31",
      "批次": "本科二批"
  },
  {
      "specialty_code": "130101",
      "name": "艺术史论",
      "is_wenli": "文科",
      "jihua": "7",
      "baokao": "7",
      "luqu": "7",
      "批次": "本科二批"
  },
  {
      "specialty_code": "020306",
      "name": "信用管理",
      "is_wenli": "文科",
      "jihua": "48",
      "baokao": "6",
      "luqu": "48",
      "批次": "本科二批"
  },
  {
      "specialty_code": "030105",
      "name": "国际经贸规则",
      "is_wenli": "文科",
      "jihua": "5",
      "baokao": "6",
      "luqu": "5",
      "批次": "本科二批"
  },
  {
      "specialty_code": "030401",
      "name": "民族学",
      "is_wenli": "文科",
      "jihua": "13",
      "baokao": "6",
      "luqu": "13",
      "批次": "本科二批"
  },
  {
      "specialty_code": "030502",
      "name": "中国共产党历史",
      "is_wenli": "文科",
      "jihua": "9",
      "baokao": "6",
      "luqu": "9",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050209",
      "name": "朝鲜语",
      "is_wenli": "文科",
      "jihua": "47",
      "baokao": "6",
      "luqu": "52",
      "批次": "本科二批"
  },
  {
      "specialty_code": "071201",
      "name": "统计学",
      "is_wenli": "文科",
      "jihua": "8",
      "baokao": "6",
      "luqu": "6",
      "批次": "本科二批"
  },
  {
      "specialty_code": "080208",
      "name": "汽车服务工程",
      "is_wenli": "文科",
      "jihua": "95",
      "baokao": "6",
      "luqu": "94",
      "批次": "本科二批"
  },
  {
      "specialty_code": "082701",
      "name": "食品科学与工程",
      "is_wenli": "文科",
      "jihua": "55",
      "baokao": "6",
      "luqu": "30",
      "批次": "本科二批"
  },
  {
      "specialty_code": "082702",
      "name": "食品质量与安全",
      "is_wenli": "文科",
      "jihua": "35",
      "baokao": "6",
      "luqu": "35",
      "批次": "本科二批"
  },
  {
      "specialty_code": "082710",
      "name": "食品营养与健康",
      "is_wenli": "文科",
      "jihua": "6",
      "baokao": "6",
      "luqu": "4",
      "批次": "本科二批"
  },
  {
      "specialty_code": "12020J",
      "name": "国际商务(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "10",
      "baokao": "6",
      "luqu": "10",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120213",
      "name": "财务会计教育",
      "is_wenli": "文科",
      "jihua": "37",
      "baokao": "6",
      "luqu": "37",
      "批次": "本科二批"
  },
  {
      "specialty_code": "130311",
      "name": "影视摄影与制作",
      "is_wenli": "文科",
      "jihua": "6",
      "baokao": "6",
      "luqu": "6",
      "批次": "本科二批"
  },
  {
      "specialty_code": "240102",
      "name": "建筑装饰工程",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "6",
      "luqu": "2",
      "批次": "本科二批"
  },
  {
      "specialty_code": "240501",
      "name": "工程造价",
      "is_wenli": "文科",
      "jihua": "14",
      "baokao": "6",
      "luqu": "10",
      "批次": "本科二批"
  },
  {
      "specialty_code": "020103",
      "name": "国民经济管理",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "5",
      "luqu": "1",
      "批次": "本科二批"
  },
  {
      "specialty_code": "020104",
      "name": "资源与环境经济学",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "5",
      "luqu": "3",
      "批次": "本科二批"
  },
  {
      "specialty_code": "030602",
      "name": "侦查学",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "5",
      "luqu": "1",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050305",
      "name": "编辑出版学",
      "is_wenli": "文科",
      "jihua": "5",
      "baokao": "5",
      "luqu": "5",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050308",
      "name": "时尚传播",
      "is_wenli": "文科",
      "jihua": "14",
      "baokao": "5",
      "luqu": "10",
      "批次": "本科二批"
  },
  {
      "specialty_code": "070102",
      "name": "信息与计算科学",
      "is_wenli": "文科",
      "jihua": "10",
      "baokao": "5",
      "luqu": "3",
      "批次": "本科二批"
  },
  {
      "specialty_code": "080205",
      "name": "工业设计",
      "is_wenli": "文科",
      "jihua": "43",
      "baokao": "5",
      "luqu": "41",
      "批次": "本科二批"
  },
  {
      "specialty_code": "081604",
      "name": "服装设计与工艺教育",
      "is_wenli": "文科",
      "jihua": "20",
      "baokao": "5",
      "luqu": "23",
      "批次": "本科二批"
  },
  {
      "specialty_code": "082003",
      "name": "飞行器制造工程",
      "is_wenli": "文科",
      "jihua": "25",
      "baokao": "5",
      "luqu": "20",
      "批次": "本科二批"
  },
  {
      "specialty_code": "09020F",
      "name": "农业资源与环境(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "5",
      "baokao": "5",
      "luqu": "5",
      "批次": "本科二批"
  },
  {
      "specialty_code": "100702",
      "name": "药物制剂",
      "is_wenli": "文科",
      "jihua": "20",
      "baokao": "5",
      "luqu": "9",
      "批次": "本科二批"
  },
  {
      "specialty_code": "101004",
      "name": "眼视光学",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "5",
      "luqu": "3",
      "批次": "本科二批"
  },
  {
      "specialty_code": "101006",
      "name": "口腔医学技术",
      "is_wenli": "文科",
      "jihua": "6",
      "baokao": "5",
      "luqu": "11",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120209",
      "name": "物业管理",
      "is_wenli": "文科",
      "jihua": "59",
      "baokao": "5",
      "luqu": "61",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120503",
      "name": "信息资源管理",
      "is_wenli": "文科",
      "jihua": "9",
      "baokao": "5",
      "luqu": "6",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120703",
      "name": "质量管理工程",
      "is_wenli": "文科",
      "jihua": "15",
      "baokao": "5",
      "luqu": "12",
      "批次": "本科二批"
  },
  {
      "specialty_code": "300305",
      "name": "水路运输与海事管理",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "5",
      "luqu": "2",
      "批次": "本科二批"
  },
  {
      "specialty_code": "310102",
      "name": "物联网工程技术",
      "is_wenli": "文科",
      "jihua": "14",
      "baokao": "5",
      "luqu": "11",
      "批次": "本科二批"
  },
  {
      "specialty_code": "320504",
      "name": "口腔医学技术",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "5",
      "luqu": "8",
      "批次": "本科二批"
  },
  {
      "specialty_code": "080213",
      "name": "智能制造工程",
      "is_wenli": "文科",
      "jihua": "14",
      "baokao": "4",
      "luqu": "12",
      "批次": "本科二批"
  },
  {
      "specialty_code": "080803",
      "name": "机器人工程",
      "is_wenli": "文科",
      "jihua": "26",
      "baokao": "4",
      "luqu": "24",
      "批次": "本科二批"
  },
  {
      "specialty_code": "081006",
      "name": "道路桥梁与渡河工程",
      "is_wenli": "文科",
      "jihua": "12",
      "baokao": "4",
      "luqu": "12",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120101",
      "name": "管理科学",
      "is_wenli": "文科",
      "jihua": "25",
      "baokao": "4",
      "luqu": "25",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120412",
      "name": "医疗产品管理",
      "is_wenli": "文科",
      "jihua": "45",
      "baokao": "4",
      "luqu": "45",
      "批次": "本科二批"
  },
  {
      "specialty_code": "12090G",
      "name": "酒店管理(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "27",
      "baokao": "4",
      "luqu": "27",
      "批次": "本科二批"
  },
  {
      "specialty_code": "130401",
      "name": "美术学",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "4",
      "luqu": "3",
      "批次": "本科二批"
  },
  {
      "specialty_code": "0204",
      "name": "经济与贸易类",
      "is_wenli": "文科",
      "jihua": "8",
      "baokao": "3",
      "luqu": "8",
      "批次": "本科二批"
  },
  {
      "specialty_code": "030107",
      "name": "社区矫正",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "3",
      "luqu": "3",
      "批次": "本科二批"
  },
  {
      "specialty_code": "030204",
      "name": "国际事务与国际关系",
      "is_wenli": "文科",
      "jihua": "4",
      "baokao": "3",
      "luqu": "4",
      "批次": "本科二批"
  },
  {
      "specialty_code": "040114",
      "name": "劳动教育",
      "is_wenli": "文科",
      "jihua": "4",
      "baokao": "3",
      "luqu": "4",
      "批次": "本科二批"
  },
  {
      "specialty_code": "05020J",
      "name": "西班牙语(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "5",
      "baokao": "3",
      "luqu": "5",
      "批次": "本科二批"
  },
  {
      "specialty_code": "080808",
      "name": "智能工程与创意设计",
      "is_wenli": "文科",
      "jihua": "8",
      "baokao": "3",
      "luqu": "8",
      "批次": "本科二批"
  },
  {
      "specialty_code": "09010G",
      "name": "园艺(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "4",
      "baokao": "3",
      "luqu": "4",
      "批次": "本科二批"
  },
  {
      "specialty_code": "100512",
      "name": "中医儿科学",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "3",
      "luqu": "1",
      "批次": "本科二批"
  },
  {
      "specialty_code": "1206",
      "name": "物流管理与工程类",
      "is_wenli": "文科",
      "jihua": "6",
      "baokao": "3",
      "luqu": "6",
      "批次": "本科二批"
  },
  {
      "specialty_code": "310203",
      "name": "软件工程技术",
      "is_wenli": "文科",
      "jihua": "4",
      "baokao": "3",
      "luqu": "4",
      "批次": "本科二批"
  },
  {
      "specialty_code": "310205",
      "name": "大数据工程技术",
      "is_wenli": "文科",
      "jihua": "10",
      "baokao": "3",
      "luqu": "10",
      "批次": "本科二批"
  },
  {
      "specialty_code": "340102",
      "name": "酒店管理",
      "is_wenli": "文科",
      "jihua": "8",
      "baokao": "3",
      "luqu": "8",
      "批次": "本科二批"
  },
  {
      "specialty_code": "040103",
      "name": "人文教育",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "2",
      "luqu": "2",
      "批次": "本科二批"
  },
  {
      "specialty_code": "05020H",
      "name": "德语(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "2",
      "luqu": "2",
      "批次": "本科二批"
  },
  {
      "specialty_code": "080201",
      "name": "机械工程",
      "is_wenli": "文科",
      "jihua": "29",
      "baokao": "2",
      "luqu": "29",
      "批次": "本科二批"
  },
  {
      "specialty_code": "080216",
      "name": "新能源汽车工程",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "2",
      "luqu": "7",
      "批次": "本科二批"
  },
  {
      "specialty_code": "081201",
      "name": "测绘工程",
      "is_wenli": "文科",
      "jihua": "14",
      "baokao": "2",
      "luqu": "12",
      "批次": "本科二批"
  },
  {
      "specialty_code": "081303",
      "name": "资源循环科学与工程",
      "is_wenli": "文科",
      "jihua": "12",
      "baokao": "2",
      "luqu": "2",
      "批次": "本科二批"
  },
  {
      "specialty_code": "08270M",
      "name": "烹饪与营养教育(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "2",
      "luqu": "3",
      "批次": "本科二批"
  },
  {
      "specialty_code": "100201K",
      "name": "临床医学",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "2",
      "luqu": "2",
      "批次": "本科二批"
  },
  {
      "specialty_code": "100203",
      "name": "医学影像学",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "2",
      "luqu": "4",
      "批次": "本科二批"
  },
  {
      "specialty_code": "100510",
      "name": "中医康复学",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "2",
      "luqu": "1",
      "批次": "本科二批"
  },
  {
      "specialty_code": "100511",
      "name": "中医养生学",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "2",
      "luqu": "3",
      "批次": "本科二批"
  },
  {
      "specialty_code": "100704",
      "name": "药事管理",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "2",
      "luqu": "3",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120212",
      "name": "体育经济与管理",
      "is_wenli": "文科",
      "jihua": "13",
      "baokao": "2",
      "luqu": "13",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120302",
      "name": "农村区域发展",
      "is_wenli": "文科",
      "jihua": "10",
      "baokao": "2",
      "luqu": "10",
      "批次": "本科二批"
  },
  {
      "specialty_code": "240104",
      "name": "园林景观工程",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "2",
      "luqu": "3",
      "批次": "本科二批"
  },
  {
      "specialty_code": "240301",
      "name": "建筑工程",
      "is_wenli": "文科",
      "jihua": "7",
      "baokao": "2",
      "luqu": "2",
      "批次": "本科二批"
  },
  {
      "specialty_code": "260102",
      "name": "智能制造工程技术",
      "is_wenli": "文科",
      "jihua": "6",
      "baokao": "2",
      "luqu": "6",
      "批次": "本科二批"
  },
  {
      "specialty_code": "320301",
      "name": "药学",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "2",
      "luqu": "4",
      "批次": "本科二批"
  },
  {
      "specialty_code": "370202",
      "name": "应用日语",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "2",
      "luqu": "2",
      "批次": "本科二批"
  },
  {
      "specialty_code": "010103",
      "name": "宗教学",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "1",
      "luqu": "1",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050105",
      "name": "古典文献学",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "1",
      "luqu": "1",
      "批次": "本科二批"
  },
  {
      "specialty_code": "05021A",
      "name": "语言学",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "1",
      "luqu": "2",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050220",
      "name": "泰语",
      "is_wenli": "文科",
      "jihua": "12",
      "baokao": "1",
      "luqu": "9",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050238",
      "name": "意大利语",
      "is_wenli": "文科",
      "jihua": "4",
      "baokao": "1",
      "luqu": "4",
      "批次": "本科二批"
  },
  {
      "specialty_code": "071202",
      "name": "应用统计学",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "1",
      "luqu": "3",
      "批次": "本科二批"
  },
  {
      "specialty_code": "080503",
      "name": "新能源科学与工程",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "1",
      "luqu": "2",
      "批次": "本科二批"
  },
  {
      "specialty_code": "080702",
      "name": "电子科学与技术",
      "is_wenli": "文科",
      "jihua": "15",
      "baokao": "1",
      "luqu": "15",
      "批次": "本科二批"
  },
  {
      "specialty_code": "080912",
      "name": "新媒体技术",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "1",
      "luqu": "1",
      "批次": "本科二批"
  },
  {
      "specialty_code": "081002",
      "name": "建筑环境与能源应用工程",
      "is_wenli": "文科",
      "jihua": "10",
      "baokao": "1",
      "luqu": "10",
      "批次": "本科二批"
  },
  {
      "specialty_code": "0816",
      "name": "纺织类",
      "is_wenli": "文科",
      "jihua": "4",
      "baokao": "1",
      "luqu": "4",
      "批次": "本科二批"
  },
  {
      "specialty_code": "090702",
      "name": "草坪科学与工程",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "1",
      "luqu": "2",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120104",
      "name": "房地产开发与管理",
      "is_wenli": "文科",
      "jihua": "64",
      "baokao": "1",
      "luqu": "66",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120413",
      "name": "医疗保险",
      "is_wenli": "文科",
      "jihua": "5",
      "baokao": "1",
      "luqu": "5",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120904",
      "name": "旅游管理与服务教育",
      "is_wenli": "文科",
      "jihua": "25",
      "baokao": "1",
      "luqu": "23",
      "批次": "本科二批"
  },
  {
      "specialty_code": "12090H",
      "name": "会展经济与管理(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "1",
      "luqu": "2",
      "批次": "本科二批"
  },
  {
      "specialty_code": "130302",
      "name": "戏剧学",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "1",
      "luqu": "1",
      "批次": "本科二批"
  },
  {
      "specialty_code": "130306",
      "name": "戏剧影视导演",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "1",
      "luqu": "2",
      "批次": "本科二批"
  },
  {
      "specialty_code": "130409",
      "name": "文物保护与修复",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "1",
      "luqu": "1",
      "批次": "本科二批"
  },
  {
      "specialty_code": "130501",
      "name": "艺术设计学",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "1",
      "luqu": "2",
      "批次": "本科二批"
  },
  {
      "specialty_code": "260106",
      "name": "材料成型及控制工程",
      "is_wenli": "文科",
      "jihua": "5",
      "baokao": "1",
      "luqu": "5",
      "批次": "本科二批"
  },
  {
      "specialty_code": "310209",
      "name": "人工智能工程技术",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "1",
      "luqu": "2",
      "批次": "本科二批"
  },
  {
      "specialty_code": "02030N",
      "name": "互联网金融(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "0",
      "luqu": "1",
      "批次": "本科二批"
  },
  {
      "specialty_code": "030306",
      "name": "老年学",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "0",
      "luqu": "3",
      "批次": "本科二批"
  },
  {
      "specialty_code": "030604",
      "name": "禁毒学",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "0",
      "luqu": "1",
      "批次": "本科二批"
  },
  {
      "specialty_code": "040113",
      "name": "融合教育",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "0",
      "luqu": "1",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050218",
      "name": "蒙古语",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "0",
      "luqu": "2",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050223",
      "name": "越南语",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "0",
      "luqu": "2",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050228",
      "name": "波兰语",
      "is_wenli": "文科",
      "jihua": "4",
      "baokao": "0",
      "luqu": "3",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050229",
      "name": "捷克语",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "0",
      "luqu": "2",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050232",
      "name": "葡萄牙语",
      "is_wenli": "文科",
      "jihua": "10",
      "baokao": "0",
      "luqu": "7",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050237",
      "name": "匈牙利语",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "0",
      "luqu": "1",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050243",
      "name": "尼泊尔语",
      "is_wenli": "文科",
      "jihua": "4",
      "baokao": "0",
      "luqu": "4",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050309",
      "name": "国际新闻与传播",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "0",
      "luqu": "3",
      "批次": "本科二批"
  },
  {
      "specialty_code": "050310",
      "name": "会展",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "0",
      "luqu": "1",
      "批次": "本科二批"
  },
  {
      "specialty_code": "070302",
      "name": "应用化学",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "0",
      "luqu": "2",
      "批次": "本科二批"
  },
  {
      "specialty_code": "080203",
      "name": "材料成型及控制工程",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "0",
      "luqu": "2",
      "批次": "本科二批"
  },
  {
      "specialty_code": "080218",
      "name": "智能交互设计",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "0",
      "luqu": "4",
      "批次": "本科二批"
  },
  {
      "specialty_code": "080410",
      "name": "宝石及材料工艺学",
      "is_wenli": "文科",
      "jihua": "23",
      "baokao": "0",
      "luqu": "23",
      "批次": "本科二批"
  },
  {
      "specialty_code": "080412",
      "name": "功能材料",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "0",
      "luqu": "3",
      "批次": "本科二批"
  },
  {
      "specialty_code": "08060F",
      "name": "电气工程及其自动化(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "0",
      "luqu": "3",
      "批次": "本科二批"
  },
  {
      "specialty_code": "080801",
      "name": "自动化",
      "is_wenli": "文科",
      "jihua": "4",
      "baokao": "0",
      "luqu": "4",
      "批次": "本科二批"
  },
  {
      "specialty_code": "080802",
      "name": "轨道交通信号与控制",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "0",
      "luqu": "6",
      "批次": "本科二批"
  },
  {
      "specialty_code": "080904",
      "name": "信息安全",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "0",
      "luqu": "3",
      "批次": "本科二批"
  },
  {
      "specialty_code": "081003",
      "name": "给排水科学与工程",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "0",
      "luqu": "1",
      "批次": "本科二批"
  },
  {
      "specialty_code": "081004",
      "name": "建筑电气与智能化",
      "is_wenli": "文科",
      "jihua": "13",
      "baokao": "0",
      "luqu": "13",
      "批次": "本科二批"
  },
  {
      "specialty_code": "081008",
      "name": "智能建造",
      "is_wenli": "文科",
      "jihua": "4",
      "baokao": "0",
      "luqu": "2",
      "批次": "本科二批"
  },
  {
      "specialty_code": "0813",
      "name": "化工与制药类",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "0",
      "luqu": "3",
      "批次": "本科二批"
  },
  {
      "specialty_code": "081302",
      "name": "制药工程",
      "is_wenli": "文科",
      "jihua": "8",
      "baokao": "0",
      "luqu": "6",
      "批次": "本科二批"
  },
  {
      "specialty_code": "081601",
      "name": "纺织工程",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "0",
      "luqu": "3",
      "批次": "本科二批"
  },
  {
      "specialty_code": "082502",
      "name": "环境工程",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "0",
      "luqu": "1",
      "批次": "本科二批"
  },
  {
      "specialty_code": "0828",
      "name": "建筑类",
      "is_wenli": "文科",
      "jihua": "6",
      "baokao": "0",
      "luqu": "9",
      "批次": "本科二批"
  },
  {
      "specialty_code": "082804",
      "name": "历史建筑保护工程",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "0",
      "luqu": "2",
      "批次": "本科二批"
  },
  {
      "specialty_code": "082806",
      "name": "城市设计",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "0",
      "luqu": "2",
      "批次": "本科二批"
  },
  {
      "specialty_code": "082901",
      "name": "安全工程",
      "is_wenli": "文科",
      "jihua": "10",
      "baokao": "0",
      "luqu": "10",
      "批次": "本科二批"
  },
  {
      "specialty_code": "100401",
      "name": "预防医学",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "0",
      "luqu": "1",
      "批次": "本科二批"
  },
  {
      "specialty_code": "100708",
      "name": "化妆品科学与技术",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "0",
      "luqu": "1",
      "批次": "本科二批"
  },
  {
      "specialty_code": "12010M",
      "name": "大数据管理与应用(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "0",
      "luqu": "2",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120111",
      "name": "应急管理",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "0",
      "luqu": "1",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120215",
      "name": "零售业管理",
      "is_wenli": "文科",
      "jihua": "4",
      "baokao": "0",
      "luqu": "4",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120216",
      "name": "创业管理",
      "is_wenli": "文科",
      "jihua": "12",
      "baokao": "0",
      "luqu": "1",
      "批次": "本科二批"
  },
  {
      "specialty_code": "120702",
      "name": "标准化工程",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "0",
      "luqu": "2",
      "批次": "本科二批"
  },
  {
      "specialty_code": "12080F",
      "name": "电子商务(中外合作办学)",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "0",
      "luqu": "2",
      "批次": "本科二批"
  },
  {
      "specialty_code": "130303",
      "name": "电影学",
      "is_wenli": "文科",
      "jihua": "5",
      "baokao": "0",
      "luqu": "5",
      "批次": "本科二批"
  },
  {
      "specialty_code": "130509",
      "name": "艺术与科技",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "0",
      "luqu": "3",
      "批次": "本科二批"
  },
  {
      "specialty_code": "240101",
      "name": "建筑设计",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "0",
      "luqu": "3",
      "批次": "本科二批"
  },
  {
      "specialty_code": "260304",
      "name": "机器人技术",
      "is_wenli": "文科",
      "jihua": "5",
      "baokao": "0",
      "luqu": "5",
      "批次": "本科二批"
  },
  {
      "specialty_code": "260702",
      "name": "新能源汽车工程技术",
      "is_wenli": "文科",
      "jihua": "2",
      "baokao": "0",
      "luqu": "2",
      "批次": "本科二批"
  },
  {
      "specialty_code": "290102",
      "name": "食品质量与安全",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "0",
      "luqu": "3",
      "批次": "本科二批"
  },
  {
      "specialty_code": "300401",
      "name": "民航运输服务与管理",
      "is_wenli": "文科",
      "jihua": "3",
      "baokao": "0",
      "luqu": "3",
      "批次": "本科二批"
  },
  {
      "specialty_code": "330201",
      "name": "金融管理",
      "is_wenli": "文科",
      "jihua": "7",
      "baokao": "0",
      "luqu": "4",
      "批次": "本科二批"
  },
  {
      "specialty_code": "330601",
      "name": "企业数字化管理",
      "is_wenli": "文科",
      "jihua": "7",
      "baokao": "0",
      "luqu": "2",
      "批次": "本科二批"
  },
  {
      "specialty_code": "330602",
      "name": "市场营销",
      "is_wenli": "文科",
      "jihua": "6",
      "baokao": "0",
      "luqu": "5",
      "批次": "本科二批"
  },
  {
      "specialty_code": "360206",
      "name": "数字动画",
      "is_wenli": "文科",
      "jihua": "1",
      "baokao": "0",
      "luqu": "2",
      "批次": "本科二批"
  },
  {
      "specialty_code": "370204",
      "name": "应用俄语",
      "is_wenli": "文科",
      "jihua": "6",
      "baokao": "0",
      "luqu": "6",
      "批次": "本科二批"
  }
]