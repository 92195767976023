module.exports = [
  // 专业月收入排行			
  { "a": "本科学科门类名称", "b": "2022届", "c": "2021届", "d": "2020届" },
  { "a": "计算机类", "b": "6863", "c": "6886", "d": "6800" },
  { "a": "电子信息类", "b": "6662", "c": "6429", "d": "6091" },
  { "a": "自动化类", "b": "6658", "c": "6356", "d": "5917" },
  { "a": "仪器类", "b": "6631", "c": "6323", "d": "5984" },
  { "a": "电气类", "b": "6396", "c": "6068", "d": "5619" },
  { "a": "机械类", "b": "6368", "c": "5972", "d": "5536" },
  { "a": "安全科学与工程类", "b": "6294", "c": "5964", "d": "5679" },
  { "a": "管理科学与工程类", "b": "6249", "c": "6104", "d": "5701" },
  { "a": "材料类", "b": "6246", "c": "5843", "d": "5371" },
  { "a": "土木类", "b": "6245", "c": "5931", "d": "5501" },
  { "a": "能源动力类", "b": "6244", "c": "6026", "d": "5597" },
  { "a": "统计学类", "b": "6217", "c": "5957", "d": "5597" },
  { "a": "测绘类", "b": "6207", "c": "6008", "d": "5617" },
  // { "a": "交通运输类", "b": "6126", "c": "5924", "d": "5664" },
  // { "a": "财政学类", "b": "6125", "c": "5897", "d": "5568" },
  // { "a": "金融学类", "b": "6122", "c": "5872", "d": "5587" },
  // { "a": "物流管理与工程类", "b": "6087", "c": "5839", "d": "5450" },
  // { "a": "矿业类", "b": "5969", "c": "5598", "d": "5161" },
  // { "a": "化工与制药类", "b": "5913", "c": "5744", "d": "5364" },
  // { "a": "经济学类", "b": "5911", "c": "5751", "d": "5451" },
  // { "a": "生物工程类", "b": "5843", "c": "5477", "d": "5210" },
  // { "a": "数学类", "b": "5820", "c": "5758", "d": "5632" },
  // { "a": "工商管理类", "b": "5809", "c": "5633", "d": "5305" },
  // { "a": "新闻传播学类", "b": "5792", "c": "5724", "d": "5498" },
  // { "a": "环境科学与工程类", "b": "5776", "c": "5403", "d": "5015" },
  // { "a": "电子商务类", "b": "5738", "c": "5892", "d": "5829" },
  // { "a": "建筑类", "b": "5733", "c": "5854", "d": "5494" },
  // { "a": "药学类", "b": "5702", "c": "5405", "d": "5125" },
  // { "a": "轻工类", "b": "5678", "c": "5491", "d": "5350" },
  // { "a": "物理学类", "b": "5637", "c": "5477", "d": "5203" },
  // { "a": "化学类", "b": "5594", "c": "5408", "d": "5124" },
  // { "a": "外国语言文学类", "b": "5547", "c": "5438", "d": "5238" },
  // { "a": "经济与贸易类", "b": "5545", "c": "5460", "d": "5378" },
  // { "a": "林学类", "b": "5504", "c": "5359", "d": "5051" },
  // { "a": "中药学类", "b": "5461", "c": "5195", "d": "4972" },
  // { "a": "公共卫生与预防医学类", "b": "5459", "c": "5110", "d": "5004" },
  // { "a": "设计学类", "b": "5434", "c": "5482", "d": "5166" },
  // { "a": "公共管理类", "b": "5433", "c": "5217", "d": "5073" },
  // { "a": "地理科学类", "b": "5420", "c": "5330", "d": "5125" },
  // { "a": "戏剧与影视学类", "b": "5407", "c": "5400", "d": "5069" },
  // { "a": "社会学类", "b": "5389", "c": "5188", "d": "4868" },
  // { "a": "生物科学类", "b": "5368", "c": "5210", "d": "5071" },
  // { "a": "护理学类", "b": "5353", "c": "5209", "d": "5164" },
  // { "a": "食品科学与工程类", "b": "5337", "c": "5159", "d": "4732" },
  // { "a": "医学技术类", "b": "5312", "c": "5069", "d": "4865" },
  // { "a": "法学类", "b": "5304", "c": "5125", "d": "4895" },
  // { "a": "中国语言文学类", "b": "5268", "c": "5103", "d": "5025" },
  // { "a": "旅游管理类", "b": "5210", "c": "5149", "d": "4983" },
  // { "a": "植物生产类", "b": "5170", "c": "5120", "d": "4757" },
  // { "a": "心理学类", "b": "5169", "c": "5185", "d": "4896" },
  // { "a": "历史学类", "b": "5134", "c": "4848", "d": "4584" },
  // { "a": "音乐与舞蹈学类", "b": "5067", "c": "5240", "d": "5216" },
  // { "a": "体育学类", "b": "5064", "c": "5127", "d": "5107" },
  // { "a": "临床医学类", "b": "4889", "c": "4908", "d": "4743" },
  // { "a": "口腔医学类", "b": "4821", "c": "4829", "d": "4629" },
  // { "a": "美术学类", "b": "4811", "c": "4912", "d": "4859" },
  // { "a": "马克思主义理论类", "b": "4786", "c": "4754", "d": "4641" },
  // { "a": "教育学类", "b": "4522", "c": "4551", "d": "4460" },
  // { "a": "中医学类", "b": "4469", "c": "4271", "d": "4047" },
  // { "a": "全国本科", "b": "5990", "c": "5833", "d": "5471" },
]